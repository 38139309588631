import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BankInfo, InvoiceStatus, Money, TrabaBilling } from '@traba/types'
import { getMoneyString } from 'src/utils/stringUtils'

type PaymentSectionProps = {
  invoiceStatus: InvoiceStatus
  totalAmountBilled: Money
  bankInfo: BankInfo
  invoiceNumber: string
  trabaBilling: TrabaBilling
  bankTransferInstructions: string
  paperCheckInstructions: string
}

export function PaymentSection({
  invoiceStatus,
  totalAmountBilled,
  bankInfo,
  invoiceNumber,
  trabaBilling,
  bankTransferInstructions,
  paperCheckInstructions,
}: PaymentSectionProps) {
  const totalAmountDue = getMoneyString(totalAmountBilled)

  return (
    <Row gap={theme.space.xxl} style={{ width: '100%', breakInside: 'avoid' }}>
      <Row flexCol gap={theme.space.xxs} style={{ flex: 1 }}>
        <Text
          variant="h6"
          color={theme.colors.Black}
          style={{ marginBottom: theme.space.xs }}
        >
          Pay {totalAmountDue} with a bank transfer
        </Text>
        <Row style={{ width: '100%' }}>
          <Row flexCol gap={theme.space.us} style={{ width: '140px' }}>
            <Text color={theme.colors.Black}>Bank Name</Text>
            <Text color={theme.colors.Black}>Routing Number</Text>
            <Text color={theme.colors.Black}>Account Number</Text>
            <Text color={theme.colors.Black}>Reference</Text>
          </Row>
          <Row flexCol gap={theme.space.us}>
            <Text color={theme.colors.Black}>{bankInfo.bankName}</Text>
            <Text color={theme.colors.Black}>{bankInfo.routingNumber}</Text>
            <Text color={theme.colors.Black}>{bankInfo.accountNumber}</Text>
            <Text color={theme.colors.Black}>{invoiceNumber}</Text>
          </Row>
        </Row>
        <Text
          variant="caption"
          color={theme.colors.Black}
          style={{ marginTop: theme.space.xs }}
        >
          {bankTransferInstructions}
        </Text>
      </Row>
      <Row flexCol gap={theme.space.xxs} style={{ flex: 1 }}>
        <Text
          variant="h6"
          color={theme.colors.Black}
          style={{ marginBottom: theme.space.xs }}
        >
          Pay {totalAmountDue} by check
        </Text>
        <Row style={{ width: '100%' }}>
          <Row flexCol gap={theme.space.us} style={{ width: '140px' }}>
            <Text color={theme.colors.Black}>Make payable to</Text>
            <Text color={theme.colors.Black}>Memo</Text>
            <Text color={theme.colors.Black}>Mail to</Text>
            <Text color={theme.colors.Black} style={{ marginTop: 'auto' }}>
              Reference
            </Text>
          </Row>
          <Row flexCol gap={theme.space.us}>
            <Text color={theme.colors.Black}>{trabaBilling.name}</Text>
            <Text color={theme.colors.Black}>
              {invoiceStatus === InvoiceStatus.DRAFT ? '' : invoiceNumber}
            </Text>
            {trabaBilling.address.map((line, index) => (
              <Text key={index} color={theme.colors.Black}>
                {line}
              </Text>
            ))}
            <Text color={theme.colors.Black}>{trabaBilling.email}</Text>
          </Row>
        </Row>
        <Text
          variant="caption"
          color={theme.colors.Black}
          style={{ marginTop: theme.space.xs }}
        >
          {paperCheckInstructions}
        </Text>
      </Row>
    </Row>
  )
}
