import { Switch } from '@mui/material'
import { InfoTooltip, RadioButton, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Locations, ScheduledBreak } from '@traba/types'
import { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'
import { ScheduleRecurringForm } from './ScheduleRecurringForm'
import { SelectBreakFormWithSchedules } from './SelectBreakFormWithSchedules'
import { ShiftTimePickerWithSchedules } from './ShiftTimePickerWithSchedules'

interface Props {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  timezone: string
  selectedLocation: Locations | undefined
  isInvalidBuffer: boolean
  setIsInvalidBuffer: Dispatch<SetStateAction<boolean>>
  defaultBreaks?: ScheduledBreak | null | undefined
  selectedSingleShiftDates: Date[] | null
  setSelectedSingleShiftDates: Dispatch<SetStateAction<Date[] | null>>
  isForAddSingleShiftToSchedule: boolean
}

export const ScheduleDetailsSection = ({
  createShiftRequests,
  setCreateShiftRequests,
  timezone,
  selectedLocation,
  isInvalidBuffer,
  setIsInvalidBuffer,
  defaultBreaks,
  selectedSingleShiftDates,
  setSelectedSingleShiftDates,
  isForAddSingleShiftToSchedule,
}: Props) => {
  console.log(
    'ScheduleDetailsSection',
    createShiftRequests.map((sr) => ({
      startTime: sr.schedules.map((s) => s.startTime),
      earlyArrivalTimeBufferMinutes: sr.earlyArrivalTimeBufferMinutes,
    })),
  )

  const onClickShiftType = (isRecurringSchedule: boolean) => {
    if (setSelectedSingleShiftDates) {
      setSelectedSingleShiftDates(isRecurringSchedule ? null : [])
    }
    setCreateShiftRequests((prev) => {
      return prev.map((sr) => {
        if (
          sr.schedules.some((s) => s.isRecurringSchedule) ===
          isRecurringSchedule
        ) {
          return sr
        }
        const newSchedule = {
          ...sr.schedules[0],
          isRecurringSchedule,
        }
        if (!isRecurringSchedule) {
          // If we are switching to not recurring we also clear recurring schedule
          delete newSchedule.recurringSchedule
        }
        return {
          ...sr,
          schedule: newSchedule,
          schedules: [newSchedule],
        }
      })
    })
  }
  return (
    <ShiftPostingInputContainer title="Schedule details">
      <Col>
        <Text variant="h6">Shift type</Text>

        <Row
          alignCenter
          style={{
            columnGap: theme.space.xs,
            cursor: 'pointer',
            pointerEvents: isForAddSingleShiftToSchedule ? 'none' : 'auto',
            opacity: isForAddSingleShiftToSchedule ? 0.5 : 1,
          }}
          my={theme.space.xs}
          onClick={() => onClickShiftType(true)}
        >
          <RadioButton
            selected={createShiftRequests[0].schedules[0].isRecurringSchedule}
          />
          <Text variant="body1">Schedule</Text>
        </Row>

        <Row
          alignCenter
          my={theme.space.xs}
          style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
          onClick={() => onClickShiftType(false)}
          fullWidth
        >
          <RadioButton
            selected={!createShiftRequests[0].schedules[0].isRecurringSchedule}
          />
          <Text variant="body1">
            Single shifts (each shift is a separate shift request)
          </Text>
        </Row>

        <ShiftTimePickerWithSchedules
          createShiftRequests={createShiftRequests}
          setCreateShiftRequests={setCreateShiftRequests}
          timezone={timezone}
          isInvalidBuffer={isInvalidBuffer}
          setIsInvalidBuffer={setIsInvalidBuffer}
          selectedSingleShiftDates={selectedSingleShiftDates}
          setSelectedSingleShiftDates={setSelectedSingleShiftDates}
        />
        {createShiftRequests[0].schedules.some(
          (s) => s.isRecurringSchedule,
        ) && (
          <ScheduleRecurringForm
            createShiftRequests={createShiftRequests}
            setCreateShiftRequests={setCreateShiftRequests}
            timezone={timezone}
            selectedLocation={selectedLocation}
          />
        )}
        <Divider />
        <Col mt={theme.space.sm}>
          <ShiftPostingInputContainerSection
            label="Validate Schedule Bypass"
            input={
              <Row>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={createShiftRequests[0].shouldBypassValidateSchedule}
                  onClick={() => {
                    setCreateShiftRequests((prev) => {
                      return prev.map((sr) => {
                        return {
                          ...sr,
                          shouldBypassValidateSchedule:
                            !sr.shouldBypassValidateSchedule,
                        }
                      })
                    })
                  }}
                />
                <InfoTooltip
                  title={
                    'Allow for posting shifts in the past or for further than 1 year'
                  }
                  tooltipProps={{
                    leaveDelay: 500,
                    placement: 'top',
                    arrow: true,
                  }}
                />
              </Row>
            }
            right
          />
          <SelectBreakFormWithSchedules
            createShiftRequests={createShiftRequests}
            setCreateShiftRequests={setCreateShiftRequests}
            defaultBreaks={defaultBreaks || undefined}
          />
        </Col>
      </Col>
    </ShiftPostingInputContainer>
  )
}
