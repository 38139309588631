import { Modal, MODAL_SIZE } from '@traba/react-components'
import {
  AccountStatus,
  TerminationInitiation,
  TerminationReason,
  WorkerUpload,
  Worker,
} from '@traba/types'
import { useState } from 'react'
import { TerminationEmailContent } from './TerminationEmailContent'
import { TerminationReasonContent } from './TerminationReasonContent'

export enum TerminationState {
  REASON = 'REASON',
  UPLOAD_ARTIFACTS = 'UPLOAD_ARTIFACTS',
  EMAIL = 'EMAIL',
  COMPLETED = 'COMPLETED',
}

export const terminationInitiationOptions = [
  { label: 'Voluntary', value: TerminationInitiation.VOLUNTARY },
  { label: 'Involuntary', value: TerminationInitiation.INVOLUNTARY },
]

export const terminationReasonOptions = {
  [TerminationInitiation.VOLUNTARY]: [
    { label: 'Personal Reasons', value: TerminationReason.PERSONAL_REASONS },
    {
      label: 'Schedule Conflicts',
      value: TerminationReason.SCHEDULE_CONFLICTS,
    },
    {
      label: 'Better Opportunities',
      value: TerminationReason.BETTER_OPPORTUNITIES,
    },
    { label: 'Health Concerns', value: TerminationReason.HEALTH_CONCERNS },
    { label: 'Unsafe Conditions', value: TerminationReason.UNSAFE_CONDITIONS },
    {
      label: 'Workplace Dissatisfaction',
      value: TerminationReason.WORKPLACE_DISSATISFACTION,
    },
  ],
  [TerminationInitiation.INVOLUNTARY]: [
    { label: 'Performance', value: TerminationReason.PERFORMANCE },
    { label: 'Attendance', value: TerminationReason.ATTENDANCE },
    { label: 'Misconduct', value: TerminationReason.MISCONDUCT },
    { label: 'Safety Violation', value: TerminationReason.SAFETY_VIOLATION },
    { label: 'Non-Compliance', value: TerminationReason.NON_COMPLIANCE },
    { label: 'Terms Breach', value: TerminationReason.TERMS_BREACH },
    { label: 'No Jobs Available', value: TerminationReason.NO_JOBS_AVAILABLE },
  ],
  '': [],
}

const getUploadMenuItems = (uploads: Array<{ id: string; title: string }>) => {
  return uploads.map((upload) => ({
    label: upload.title,
    value: upload.id,
  }))
}

export const TerminationModal = ({
  handleClose,
  isOpen,
  worker,
  workerId,
  uploads,
  defaultEmailBody,
}: {
  handleClose: () => void
  isOpen: boolean
  worker: Worker & AccountStatus
  workerId: string | undefined
  uploads: WorkerUpload[]
  defaultEmailBody: string
}) => {
  const [state, setState] = useState(TerminationState.REASON)
  const [uploadIds, setUploadIds] = useState<string[]>([])
  const [initiation, setInitiation] = useState<TerminationInitiation | ''>('')
  const [reason, setReason] = useState<TerminationReason | ''>('')
  const [explanation, setExplanation] = useState('')
  const [emailBody, setEmailBody] = useState(defaultEmailBody)
  const [emailRecipient, setEmailRecipient] = useState(worker?.email || '')

  const onClose = () => {
    setState(TerminationState.REASON)
    handleClose()
  }

  const modalContent = () => {
    switch (state) {
      case TerminationState.REASON:
        return (
          <TerminationReasonContent
            uploads={uploads}
            setState={setState}
            setInitiation={setInitiation}
            initiation={initiation}
            setReason={setReason}
            reason={reason}
            setExplanation={setExplanation}
            explanation={explanation}
            setUploadIds={setUploadIds}
            uploadIds={uploadIds}
            uploadMenuItems={getUploadMenuItems(uploads)}
          />
        )
      case TerminationState.EMAIL:
        return (
          <TerminationEmailContent
            workerId={workerId || ''}
            reason={reason}
            initiation={initiation}
            explanation={explanation}
            uploadIds={uploadIds}
            emailBody={emailBody}
            emailRecipient={emailRecipient}
            setEmailBody={setEmailBody}
            setEmailRecipient={setEmailRecipient}
            onClose={onClose}
          />
        )
      default:
        return null
    }
  }

  return (
    <Modal
      handleClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={'Termination Report'}
    >
      {modalContent()}
    </Modal>
  )
}
