import { Button, ButtonVariant, SvgIcon } from '@traba/react-components'
import { ApplicationItemType, IconName } from '@traba/types'

interface ApplicationItemLinkToOpenDetailsProps {
  applicationItemType: ApplicationItemType
  onClickLink: () => void
}

const APPLICATION_ITEM_TYPE_TO_TEXT: Record<ApplicationItemType, string> = {
  [ApplicationItemType.AI_VETTING_CALL]: 'View Call Details',
  [ApplicationItemType.BGC]: 'View Results',
  [ApplicationItemType.DRUG_SCREENING]: 'View Results',
}

const APPLICATION_ITEM_TYPE_TO_ICON: Record<ApplicationItemType, IconName> = {
  [ApplicationItemType.AI_VETTING_CALL]: 'link', // TODO(gail): change to phone icon
  [ApplicationItemType.BGC]: 'link',
  [ApplicationItemType.DRUG_SCREENING]: 'link',
}

export function ApplicationItemLinkToOpenDetails({
  applicationItemType,
  onClickLink,
}: ApplicationItemLinkToOpenDetailsProps) {
  const text =
    APPLICATION_ITEM_TYPE_TO_TEXT[applicationItemType] || 'View Results'
  const iconName = APPLICATION_ITEM_TYPE_TO_ICON[applicationItemType] || 'link'

  return (
    <Button
      iconWidth={16}
      rightIcon={<SvgIcon name={iconName} />}
      variant={ButtonVariant.BRANDLINK}
      onClick={onClickLink}
    >
      {text}
    </Button>
  )
}
