import {
  Button,
  ButtonVariant,
  IMenuItem,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerTimecard, WorkerTimecardStatus } from '@traba/types'
import { formatDateString } from '@traba/utils'
import { useMemo, useState } from 'react'
import { Col, Row } from 'src/components/base'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import { useCompanies } from 'src/hooks/useCompanies'
import { useW2WorkerTimecard } from 'src/hooks/useW2WorkerTimecard'
import { W2WorkerHourDisputeModal } from './W2WorkerHourDisputeModal'

export const W2WorkerHourDispute = () => {
  const { companies = [] } = useCompanies({
    isApproved: true,
  })

  const companyOptions: IMenuItem[] = companies.map((company) => {
    return { label: company.employerName, value: company.id }
  })
  const [selectedCompanyItem, setSelectedCompanyItem] = useState<IMenuItem>()
  const [showNeedsActionOnly, setShowNeedsActionOnly] = useState(true)

  const { workerTimecards, isLoading, refetchTimecards } = useW2WorkerTimecard({
    companyId: selectedCompanyItem?.value,
    statuses: showNeedsActionOnly
      ? [WorkerTimecardStatus.WORKER_DISPUTED]
      : undefined,
  })
  const [selectedWorkerTimecard, setSelectedWorkerTimecard] =
    useState<WorkerTimecard | null>()

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'createdAt',
      label: 'Created at',
    },
    {
      key: 'companyName',
      label: 'Company name',
    },
    {
      key: 'workerName',
      label: 'Worker name',
      sortable: true,
    },
    {
      key: 'shiftRole',
      label: 'Shift role',
    },
    {
      key: 'action',
      label: 'Action',
    },
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workerTimecards?.map((timecard) => ({
        key: timecard.id,
        cells: [
          {
            key: `${timecard.id}-createdAt`,
            renderFn: () => {
              return <Text>{formatDateString(timecard.createdAt)}</Text>
            },
          },
          {
            key: `${timecard.id}-companyName`,
            renderFn: () => {
              return <Text>{timecard.workerShift.employerName}</Text>
            },
          },
          {
            key: `${timecard.id}-workerName`,
            sortKey: `${timecard.worker.firstName}_${timecard.worker.lastName}`,
            renderFn: () => {
              return (
                <Text>{`${timecard.worker.firstName} ${timecard.worker.lastName}`}</Text>
              )
            },
          },
          {
            key: `${timecard.id}-shiftRole`,
            renderFn: () => {
              return <Text>{timecard.workerShift.roleName}</Text>
            },
          },

          {
            key: `${timecard.id}-action`,
            renderFn: () => {
              return timecard.status ===
                WorkerTimecardStatus.WORKER_DISPUTED ? (
                <Button
                  variant={ButtonVariant.FILLED}
                  slim
                  onClick={() => {
                    setSelectedWorkerTimecard(timecard)
                  }}
                >
                  Review
                </Button>
              ) : (
                <Button
                  variant={ButtonVariant.LINK}
                  onClick={() => {
                    setSelectedWorkerTimecard(timecard)
                  }}
                >
                  View details
                </Button>
              )
            },
          },
        ],
      })) || []
    )
  }, [workerTimecards])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.sm}>
        <Toggle
          label="Show needs action only"
          buttonState={showNeedsActionOnly}
          runOnChange={() => setShowNeedsActionOnly(!showNeedsActionOnly)}
          containerStyle={{
            marginBottom: theme.space.sm,
            width: 'fit-content',
          }}
        />
        <SearchSelect
          options={companyOptions}
          selectItem={selectedCompanyItem}
          handleSelect={(item) => {
            setSelectedCompanyItem(item)
          }}
          label="Company"
          width="50%"
        />
      </Row>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={1}
        />
      )}
      {selectedWorkerTimecard && (
        <W2WorkerHourDisputeModal
          isOpen={!!selectedWorkerTimecard}
          onClose={() => setSelectedWorkerTimecard(null)}
          workerTimecard={selectedWorkerTimecard}
          onFinalize={() => {
            refetchTimecards()
            setSelectedWorkerTimecard(null)
          }}
        />
      )}
    </Col>
  )
}
