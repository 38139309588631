import { theme } from '@traba/theme'
import { SVGProps } from 'react'
import iconAssets, { iconSvgAssets, assetNameType } from 'src/assets/svg/icons'
import styled from 'styled-components'

type IconProps = {
  width?: number
  height?: number
  name: assetNameType
  type?: 'img' | 'svg'
  color?: string
  fill?: string
  hoverColor?: string
  size?: number
  style?: React.CSSProperties
  onClick?: () => void
  yOffset?: number
}

export const CircleDiv = styled.div<{
  color?: string
  size?: number
  backgroundColor?: string
  useCursorPointer?: boolean
}>`
  width: ${({ size }) => (size ? `${size}px` : '24px')};
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'transparent'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  &:hover {
    border: 1px solid ${({ color }) => color || theme.colors.Grey60};
    cursor: ${({ useCursorPointer }) =>
      useCursorPointer ? 'pointer' : 'default'};
  }
`

export default function Icon({
  name,
  type = 'img',
  style,
  ...props
}: IconProps) {
  const iconSource = iconAssets[name]

  if (type === 'svg') {
    const Component = iconSvgAssets[name]
    return (
      <Component
        {...props}
        style={{ ...style, ...(props.onClick ? { cursor: 'pointer' } : {}) }}
      />
    )
  }

  return <img src={iconSource} alt={name} {...props} style={style} />
}

// TODO_MIKE: Add this to icon directory
export function Award(
  props: { size?: number; color?: string } & SVGProps<SVGSVGElement>,
) {
  const {
    size = 14,
    color = 'currentColor',
    strokeWidth = 1.5,
    ...rest
  } = props
  return (
    <svg viewBox="0 0 16 20" width={size} height={size} fill="none" {...rest}>
      <path
        d="M10.2025 13.3439C10.9297 13.1787 11.5991 12.874 12.1795 12.4601L13.3264 16.9669C13.6708 18.3201 12.2233 19.4446 10.9341 18.8255L9.0013 17.8972C8.37042 17.5943 7.62958 17.5943 6.99869 17.8972L5.0659 18.8255C3.77673 19.4446 2.32924 18.3201 2.67359 16.9669L3.82046 12.46C4.40085 12.874 5.0703 13.1787 5.79754 13.3439M10.2025 13.3439C8.75382 13.673 7.24619 13.673 5.79754 13.3439M10.2025 13.3439C12.2084 12.8882 13.7746 11.3712 14.2451 9.42848C14.585 8.02544 14.585 6.56527 14.2451 5.16223C13.7746 3.21947 12.2084 1.70254 10.2025 1.24683C8.75382 0.917723 7.24618 0.917723 5.79753 1.24683C3.79162 1.70254 2.22538 3.21947 1.75486 5.16223C1.41505 6.56527 1.41505 8.02544 1.75486 9.42848C2.22538 11.3712 3.79162 12.8882 5.79754 13.3439"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
