import { ShiftTime } from '@traba/types'
import { orderBy } from 'lodash'
import { useState } from 'react'
import Checkbox from '../Checkbox'
import { Table, TableHeader, TableProps, Td, Tr } from './Table'

export interface DataTableHeader extends TableHeader {
  /**
   * Enables sorting by clicking on the header item.
   */
  sortable?: boolean
}

export interface TableCell {
  key?: string
  renderFn: () => React.ReactNode
  sortKey?: string | number | ShiftTime[] | null
}

export interface TableRow {
  key: string
  cells: TableCell[]
}

export interface DataTableProps extends TableProps {
  /**
   * Allows selecting rows
   */
  allowSelect?: boolean
  /**
   * Callback for when rows are selected
   * @param selectedRows
   * @returns
   */
  onSelectRows?: (selectedRows: TableRow[]) => void
  selectedRows?: TableRow[]
  headers: Array<DataTableHeader | string>
  rows: TableRow[]
  initialSortByColumnIndex?: number
  initialSortByAscOrder?: boolean
}

export function DataTable(props: DataTableProps) {
  const [sortByColumnIndex, setSortByColumnIndex] = useState(
    props.initialSortByColumnIndex || 0,
  )

  let sortByAscOrder

  // Sort by ascending if set by parent or as default if not set
  if (
    props.initialSortByAscOrder ||
    props.initialSortByAscOrder === undefined
  ) {
    sortByAscOrder = true
  } else {
    sortByAscOrder = false
  }

  const [ascOrder, setAscOrder] = useState(sortByAscOrder)
  const sortedRows = orderBy(
    props.rows,
    (row) => {
      return row.cells[sortByColumnIndex].sortKey
    },
    [ascOrder ? 'asc' : 'desc'],
  )

  function onToggleRowSelection(
    ev: React.ChangeEvent<HTMLInputElement>,
    row: TableRow,
  ) {
    const { checked } = ev.target
    if (!props.onSelectRows) {
      return
    }

    const updatedSelectedRows = checked
      ? [...(props.selectedRows ?? []), row]
      : props.selectedRows!.filter((r) => r.key !== row.key)

    props.onSelectRows(updatedSelectedRows)
  }

  const selectedRowKeys = props.selectedRows
    ? props.selectedRows.map((row) => row.key)
    : []

  const areAllSelected = props.rows.every((row) => {
    return selectedRowKeys.includes(row.key)
  })

  function onPressSelectAll() {
    if (!props.onSelectRows) {
      return
    }
    if (areAllSelected) {
      const remainingRows = props.selectedRows?.filter(
        (row) => !props.rows.some((r) => r.key === row.key),
      )
      return props.onSelectRows(remainingRows ?? [])
    }
    const newSelectedRows = [
      ...(props?.selectedRows ?? []),
      ...props.rows.filter((row) => {
        if (!props.selectedRows) {
          return false
        }
        return !props.selectedRows.find(
          (selectedRow) => selectedRow.key === row.key,
        )
      }),
    ]
    return props.onSelectRows(newSelectedRows)
  }

  const headers: Array<string | TableHeader> = props.headers.map(
    (header, i) => {
      if (typeof header == 'string') {
        return header
      }
      return {
        ...header,
        onPressHeaderItem: header.sortable
          ? () => {
              if (i === sortByColumnIndex) {
                setAscOrder(!ascOrder)
              } else {
                setAscOrder(true)
              }
              setSortByColumnIndex(i)
            }
          : undefined,
        sorted:
          i === sortByColumnIndex ? (ascOrder ? 'asc' : 'desc') : undefined,
      }
    },
  )

  const activeHeader = sortByColumnIndex > 0 ? headers[sortByColumnIndex] : null

  const tableProps: TableProps = {
    ...props,
    activeHeaderItemKey: activeHeader
      ? typeof activeHeader === 'string'
        ? activeHeader
        : activeHeader.key
      : undefined,
    headers: props.allowSelect
      ? [
          {
            label: (
              <Checkbox
                onChange={onPressSelectAll}
                checked={areAllSelected}
                labelStyle={{ top: -12 }}
              />
            ),
          },
          ...headers,
        ]
      : headers,
  }

  return (
    <Table {...tableProps}>
      {sortedRows.map((row) => {
        return (
          <Tr key={row.key} colorWhenHover={true}>
            {props.allowSelect && (
              <Td>
                <Checkbox
                  onChange={(ev) => onToggleRowSelection(ev, row)}
                  checked={!!props.selectedRows?.find((r) => r.key === row.key)}
                  labelStyle={{ top: -12 }}
                />
              </Td>
            )}
            {row.cells.map((cell, i) => {
              const key =
                typeof cell.sortKey === 'string' ||
                typeof cell.sortKey === 'number'
                  ? cell.sortKey
                  : cell.key || `${row.key}-${i}`
              return <Td key={key}>{cell.renderFn()}</Td>
            })}
          </Tr>
        )
      })}
      {props.children}
    </Table>
  )
}
