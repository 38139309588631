import { theme } from '@traba/theme'
import { Link as BaseLink } from 'src/components/base'
import styled, { css, keyframes } from 'styled-components'

type LinkProps = {
  $isActive?: boolean
}

type SidebarWrapperProps = {
  isCollapsed?: boolean
  isDev?: boolean
}

const gradientAnimation = keyframes`
  0% {
    box-shadow: 10px 0 20px #fb0094,
                10px 0 20px #0000ff,
                10px 0 20px #00ff00,
                10px 0 20px #ffff00,
                10px 0 20px #ff0000;
  }
  25% {
    box-shadow: 10px 0 20px #0000ff,
                10px 0 20px #00ff00,
                10px 0 20px #ffff00,
                10px 0 20px #ff0000,
                10px 0 20px #fb0094;
  }
  50% {
    box-shadow: 10px 0 20px #00ff00,
                10px 0 20px #ffff00,
                10px 0 20px #ff0000,
                10px 0 20px #fb0094,
                10px 0 20px #0000ff;
  }
  75% {
    box-shadow: 10px 0 20px #ffff00,
                10px 0 20px #ff0000,
                10px 0 20px #fb0094,
                10px 0 20px #0000ff,
                10px 0 20px #00ff00;
  }
  100% {
    box-shadow: 10px 0 20px #ff0000,
                10px 0 20px #fb0094,
                10px 0 20px #0000ff,
                10px 0 20px #00ff00,
                10px 0 20px #ffff00;
  }
`

export const SidebarWrapper = styled.div<
  SidebarWrapperProps & { animate?: boolean }
>`
  background-color: #f7f7f8;
  padding: ${theme.space.med}px ${theme.space.xxs}px;
  height: 100%;
  overflow: auto;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${gradientAnimation} 1s linear infinite
        `
      : 'none'};

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      width: 60px;

      .sidebar-link {
        padding: 0 14px;
      }
    `}

  ${({ isDev }) =>
    isDev &&
    css`
      background-color: #f5ebff;
    `}
`

export const SidebarLinks = styled.div`
  margin-top: 40px;
`

export const SideNavWrapper = styled.span<{ $isInverted?: boolean }>`
  cursor: pointer;
  transition: all 0.2s;
  ${({ $isInverted }) =>
    $isInverted &&
    css`
      transform: rotateY(180deg);
    `}
`

export const LinkWrapper = styled.div`
  &:last-child {
    margin-top: ${({ theme }) => theme.space.xs}px;
    padding-top: ${({ theme }) => theme.space.xxs}px;
    border-top: 1px solid #e2e6e9;
  }
`

export const Link = styled(BaseLink)<LinkProps>`
  display: flex;
  align-items: center;
  height: 48px;
  margin: 1px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.004em;
  color: ${({ theme }) => theme.TextButton};
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0);
  padding-top: ${({ theme }) => theme.space.xxs}px;
  padding-bottom: ${({ theme }) => theme.space.xxs}px;
  padding-left: ${({ theme }) => theme.space.xs}px;
  padding-right: ${({ theme }) => theme.space.xs}px;

  img {
    width: 20px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 1px rgba(196, 204, 212, 0.24);
    border-radius: 8px;
    color: #08105e;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 1px rgba(196, 204, 212, 0.24);
      border-radius: 8px;
      color: #08105e;
    `}
`
