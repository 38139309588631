import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button, Input, Row } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import SelectableCard from 'src/components/base/SelectableCard/SelectableCard'
import { DraggableWorkerList } from 'src/components/DraggableWorkerList/DraggableWorkerList'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { useBackfillCall } from '../hooks/useBackfillCall'

interface BackfillCallSectionProps {
  shiftId: string
  selectedWorkerList: PopulatedWorker[]
  setSelectedWorkerList: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
  sentinelNotificationToUserId?: string
}

type Suggestion = {
  label: string
  value: string
  selected: boolean
}

export const BackfillCallSection = (props: BackfillCallSectionProps) => {
  const {
    shiftId,
    selectedWorkerList,
    setSelectedWorkerList,
    sentinelNotificationToUserId,
  } = props
  const { commenceBackfill } = useBackfillCall(shiftId)
  const { trackAnalytics } = useAnalytics()

  const [content, setContent] = useState<string>('')
  const [includeOptions, setIncludeOptions] = useState<boolean>(true)

  const DEFAULT_SUGGESTIONS: Suggestion[] = [
    {
      label: '1 - Receive Link',
      value: ' In order to receive a link to the shift press 1.',
      selected: false,
    },
    {
      label: '2 - Decline',
      value:
        ' If you are not interested in this shift, but are open to more opportunities press 2.',
      selected: false,
    },
    {
      label: '3 - 1 Day Mute',
      value:
        ' If you are busy today and are not available for any opportunities press 3.',
      selected: false,
    },
    {
      label: '4 - Permanent Mute',
      value: ' If you do not want to receive any more calls like this press 4.',
      selected: false,
    },
  ]

  const [selectedSuggestions, setSelectedSuggestions] =
    useState(DEFAULT_SUGGESTIONS)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [batchMultiplier, setBatchMultiplier] = useState<number | undefined>(
    undefined,
  )

  const handleSuggestionClick = (suggestion: Suggestion, idx: number) => {
    if (suggestion.selected) {
      setContent(content.replace(suggestion.value, ''))
    } else {
      setContent(`${content}${suggestion.value}`)
    }

    const newSuggestions = [...selectedSuggestions]
    newSuggestions[idx].selected = !suggestion.selected
    setSelectedSuggestions(newSuggestions)
  }

  const handleSubmitBackfill = async () => {
    setSubmitLoading(true)

    const selectedWorkerIds = selectedWorkerList.map(
      (worker) => worker.id || worker.uid,
    )
    await commenceBackfill(
      selectedWorkerIds,
      content,
      includeOptions,
      batchMultiplier,
      sentinelNotificationToUserId,
    )
    if (sentinelNotificationToUserId) {
      trackAnalytics('Sentinel Action Taken Backfill Calls', {
        selectedWorkerIds,
        content,
        includeOptions,
        batchMultiplier,
        sentinelNotificationToUserId,
      })
    }
    setSubmitLoading(false)
  }
  return (
    <Row flexCol>
      <Text variant="h6">Suggestions</Text>
      <Row justifyBetween alignCenter>
        <Row
          wrap
          style={{ gap: theme.space.med }}
          mb={theme.space.sm}
          mt={theme.space.xxs}
        >
          {selectedSuggestions.map((suggestion, idx) => (
            <SelectableCard
              key={suggestion.label}
              label={suggestion.label}
              onClick={() => handleSuggestionClick(suggestion, idx)}
              selected={suggestion.selected}
            />
          ))}
        </Row>
        <Row alignCenter>
          <Checkbox
            checked={includeOptions}
            label={'Include Options'}
            onChange={() => setIncludeOptions(!includeOptions)}
          />
        </Row>
      </Row>
      <Row mb={theme.space.xs} alignCenter>
        <NumberInput
          value={batchMultiplier}
          setValue={setBatchMultiplier}
          min={1}
          max={20}
          placeholder="e.g. 1"
          label="Batch Multiplier"
          className="batch-multiplier"
          containerStyle={{ width: '170px' }}
        />
        <InfoTooltip
          title={
            'This is an optional multiplier for the amount of calls per slot. E.X. If this value is set to 5 and the number of slots open is 1. It will queue 5 workers at a time.'
          }
        />
      </Row>
      <Input
        rows={5}
        label="Your generated voice message to workers on this shift"
        type="textarea"
        className="xs-12"
        value={content}
        maxLength={500}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setContent(e.target.value)
        }
        full
      />
      <Row fullWidth justifyEnd pb={theme.space.xs}>
        <Button
          style={{ height: '48px', marginTop: theme.space.xxs, width: 220 }}
          loading={submitLoading}
          onClick={handleSubmitBackfill}
          disabled={!selectedWorkerList.length}
        >
          Submit
        </Button>
      </Row>
      <Divider />
      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6" style={{ marginBottom: theme.space.xxs }}>
          Choose Order
        </Text>
        <DraggableWorkerList
          selectedWorkerList={selectedWorkerList}
          setSelectedWorkerList={setSelectedWorkerList}
        />
      </Row>
    </Row>
  )
}
