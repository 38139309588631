import { Tab as BaseTab } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'

export const Tab = styled(BaseTab)`
  padding: ${theme.space.xs}px;
  margin: 0px;
  font-size: ${theme.default.fontSize};
`
export const TabBody = styled.div`
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;
  width: 100%;
`

export const CreateIncentivePage = styled.div`
  display: flex;
  flex: 1;
  height: 90vh;
`

export const CreateIncentiveContainer = styled.form`
  display: flex;
  flex: 1;
  height: 80vh;
`

export const CreateIncentiveSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: ${theme.space.med}px;
  border: 1px solid ${theme.border.color};
  flex-direction: column;
  overflow-y: scroll;
`

export const CreateIncentiveRulesSection = styled.div`
  display: flex;
  flex: 0.7;
  height: 100%;
  padding: ${theme.space.med}px;
  border: 1px solid ${theme.border.color};
  flex-direction: column;
  overflow-y: scroll;
`

export const CreateIncentiveCard = styled.div`
  margin-bottom: ${theme.space.lg}px;
  padding: ${theme.space.xs}px;
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
`

export const CreateIncentiveCardHeader = styled.div`
  display: flex;
  padding: ${theme.space.xs}px;
  border-bottom: 1px solid ${theme.border.color};
  flex-direction: column;
`

export const CreateIncentiveRuleCard = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex: 1;
  height: 100%;
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
  margin: ${theme.space.xs}px;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.Grey10};
  }
  border: ${(props) =>
    props.isSelected
      ? `2px solid ${theme.colors.brand}`
      : `1px solid ${theme.border.color}`};
`

export const CreateIncentiveRuleCardBody = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: ${theme.space.sm}px;
`

export const CreateIncentiveRuleCardHeader = styled.div`
  display: flex;
  padding: ${theme.space.xs}px;
  border-bottom: 1px solid ${theme.border.color};
  flex-direction: column;
`

export const RoundMarker = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.brand};
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.White};
  margin-right: ${theme.space.xs}px;
`
