import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Roster } from '@traba/types'
import React, { useState } from 'react'
import { Icon, Row } from 'src/components/base'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { InvitedWorkers } from './InvitedWorkers'
import { SearchInviteWorkersModal } from './SearchInviteWorkersModal'

interface SearchInviteWorkersSectionProps {
  rosters: Roster[]
  allWorkersToInvite: PopulatedWorker[]
  setAllWorkersToInvite: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
  createShiftRequest?: CreateShiftRequest
}

export const SearchInviteWorkersSection: React.FC<
  SearchInviteWorkersSectionProps
> = (props) => {
  const {
    rosters,
    allWorkersToInvite,
    setAllWorkersToInvite,
    createShiftRequest,
  } = props

  const [showInviteWorkersModal, setShowInviteWorkersModal] =
    useState<boolean>(false)

  return (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
          width: allWorkersToInvite.length > 0 ? 550 : 350,
        }}
        mt={theme.space.sm}
        mb={theme.space.xxs}
      >
        <Text variant="h5">Invite specific workers</Text>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowInviteWorkersModal(true)
          }}
        >
          {allWorkersToInvite.length > 0 ? (
            <>
              <Icon
                type="svg"
                name="edit"
                color={theme.colors.Violet}
                size={18}
              />
              <Text
                variant="h6"
                style={{
                  color: theme.colors.Violet,
                  marginLeft: theme.space.xxxs,
                  userSelect: 'none',
                }}
              >
                Edit workers
              </Text>
            </>
          ) : (
            <>
              <Icon name="workers_active" size={theme.space.xs} />
              <Text
                variant="h6"
                style={{
                  color: theme.colors.Violet,
                  marginLeft: theme.space.xxxs,
                  userSelect: 'none',
                }}
              >
                Invite
              </Text>
            </>
          )}
        </Row>
      </Row>
      {allWorkersToInvite.length > 0 ? (
        <InvitedWorkers workers={allWorkersToInvite} />
      ) : (
        <Row
          style={{ justifyContent: 'flex-start', width: 350 }}
          mt={theme.space.xxs}
          mb={theme.space.xs}
        >
          <Text variant="body2">
            Use this to invite specific workers both from the business's roster
            and from the full worker pool.
          </Text>
        </Row>
      )}
      <SearchInviteWorkersModal
        open={showInviteWorkersModal}
        handleClose={() => setShowInviteWorkersModal(false)}
        rosters={rosters}
        setAllWorkersToInvite={setAllWorkersToInvite}
        schedules={createShiftRequest?.schedules}
      />
    </>
  )
}
