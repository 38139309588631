import { EmploymentType } from './employmentType'

export enum DrugPanelType {
  FIVE_PANEL = 'FIVE_PANEL',
  TEN_PANEL = 'TEN_PANEL',
  THIRTEEN_PANEL = 'THIRTEEN_PANEL',
  FIVE_PANEL_WITHOUT_THC = 'FIVE_PANEL_WITHOUT_THC',
  TEN_PANEL_WITHOUT_THC = 'TEN_PANEL_WITHOUT_THC',
  THIRTEEN_PANEL_WITHOUT_THC = 'THIRTEEN_PANEL_WITHOUT_THC',
  FIVE_PANEL_POST_ACCIDENT = 'FIVE_PANEL_POST_ACCIDENT',
  TEN_PANEL_POST_ACCIDENT = 'TEN_PANEL_POST_ACCIDENT',
  THIRTEEN_PANEL_POST_ACCIDENT = 'THIRTEEN_PANEL_POST_ACCIDENT',
  FIVE_PANEL_WITHOUT_THC_POST_ACCIDENT = 'FIVE_PANEL_WITHOUT_THC_POST_ACCIDENT',
  TEN_PANEL_WITHOUT_THC_POST_ACCIDENT = 'TEN_PANEL_WITHOUT_THC_POST_ACCIDENT',
  THIRTEEN_PANEL_WITHOUT_THC_POST_ACCIDENT = 'THIRTEEN_PANEL_WITHOUT_THC_POST_ACCIDENT',
  FIVE_PANEL_RANDOM = 'FIVE_PANEL_RANDOM',
  TEN_PANEL_RANDOM = 'TEN_PANEL_RANDOM',
  THIRTEEN_PANEL_RANDOM = 'THIRTEEN_PANEL_RANDOM',
  FIVE_PANEL_WITHOUT_THC_RANDOM = 'FIVE_PANEL_WITHOUT_THC_RANDOM',
  TEN_PANEL_WITHOUT_THC_RANDOM = 'TEN_PANEL_WITHOUT_THC_RANDOM',
  THIRTEEN_PANEL_WITHOUT_THC_RANDOM = 'THIRTEEN_PANEL_WITHOUT_THC_RANDOM',
  SIX_PANEL_RAPID = 'SIX_PANEL_RAPID',
  TEN_PANEL_RAPID = 'TEN_PANEL_RAPID',
}

export enum DrugTestType {
  PRE_EMPLOYMENT = 'PRE_EMPLOYMENT',
  POST_ACCIDENT = 'POST_ACCIDENT',
  RANDOM = 'RANDOM',
}

export const DEFAULT_DRUG_TEST_EXPIRATION_DATE_DAYS = 4
export const MIN_DRUG_TEST_EXPIRATION_DATE_DAYS = 2
export const MAX_DRUG_TEST_EXPIRATION_DATE_DAYS = 11
export const DRUG_PANEL_DERIVED_TYPES_SUFFIXES = ['POST_ACCIDENT', 'RANDOM']

export type DrugScreeningCombination = {
  panelType: DrugPanelType
  testType: DrugTestType
}

export const isValidDrugScreeningCombination = (
  panel: DrugPanelType,
  testType: DrugTestType,
): boolean => {
  // Rapid panels are only allowed for pre-employment
  if (
    (panel === DrugPanelType.SIX_PANEL_RAPID ||
      panel === DrugPanelType.TEN_PANEL_RAPID) &&
    testType !== DrugTestType.PRE_EMPLOYMENT
  ) {
    return false
  }
  return true
}

export enum DrugScreeningVerificationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum DrugScreeningOrderStatus {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  WAIVER_SIGNED = 'WAIVER_SIGNED',
  SAMPLE_COLLECTED = 'SAMPLE_COLLECTED',
}

export enum DrugScreeningResultStatus {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  TEMPORARILY_EXEMPTED = 'TEMPORARILY_EXEMPTED',
  UNAVAILABLE = 'UNAVAILABLE',
  REVIEW_REQUIRED_UNKNOWN_STATUS = 'REVIEW_REQUIRED_UNKNOWN_STATUS',
}

export interface DrugScreeningOrder {
  id: string
  workerId: string
  status: DrugScreeningOrderStatus
  drugScreeningVendor: string
  vendorOrderId: string
  drugPanelType: DrugPanelType
  requestingCompanyId?: string
  expiresAt: Date
  labPassportId: string
  lastReminderSentAt?: Date
  applicantPortalUrl: string
  labPassportEmailedAt?: Date
  waiverSignedAt: Date
  scheduledBy: string
  createdAt: Date
  updatedAt: Date
  requestingCompanyName?: string
  additionalInformation?: string
  sampleCollectionTimestamp?: Date
  employmentType: EmploymentType
  shiftRequestId?: string
}

export interface DrugScreeningResult {
  id: string
  createdAt: Date
  updatedAt: Date
  workerId: string
  drugScreeningResultStatus: DrugScreeningResultStatus
  drugScreeningVendor: string
  vendorOrderId: string
  drugPanelType: DrugPanelType
  requestingCompanyId?: string
  expiresAt: Date
  labPassportId: string
  lastReminderSentAt?: Date
  applicantPortalUrl: string
  labPassportEmailedAt?: Date
  waiverSignedAt: Date
  scheduledBy: string
  verificationStatus: DrugScreeningVerificationStatus
  requestingCompanyName?: string
  resultFileUrl?: string
  shiftRequestId?: string
  employmentType: EmploymentType
}

export type CreateDrugScreeningOrderParams = {
  workerId: string
  drugPanelType: DrugPanelType
  shiftRequestId: string
  expirationDays: number
  employmentType: EmploymentType
}
