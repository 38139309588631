import Drawer from '@mui/material/Drawer'
import {
  Col,
  LoadingSpinner,
  Row,
  Text,
  Button,
  ButtonVariant,
  SvgIcon,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon } from 'src/components/base'
import { useApplicationForShiftRequest } from 'src/hooks/useApplications'
import { ViewAndEditExistingApplicationItemsList } from 'src/screens/Applications/ViewAndEditExistingApplicationItemsList'

interface ViewApplicationDrawerProps {
  isOpen: boolean
  onOpenDrawer: () => void
  onCloseDrawer: () => void
  shiftRequestId: string
}

export const ViewApplicationDrawer = ({
  isOpen,
  onOpenDrawer,
  onCloseDrawer,
  shiftRequestId,
}: ViewApplicationDrawerProps) => {
  const { application, isLoading } = useApplicationForShiftRequest({
    shiftRequestId,
  })

  const handleRedirectToCreateApplication = () => {
    // TODO(gail): Add redirect to create application page
    console.log('create application redirect')
  }
  const handleRedirectToEditApplication = () => {
    // TODO(gail): Add redirect to edit application page
    console.log('edit application redirect')
  }
  // TODO(gail): update to true when we support edit application item from endpoint
  const showRedirectToEditApplicationButton = false
  // TODO(gail): update to true when we support edit application item from endpoint
  const allowItemEditing = false

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!application) {
    return (
      <Button
        onClick={handleRedirectToCreateApplication}
        leftIcon={<Icon name="plus" />}
      >
        Create new application
      </Button>
    )
  }

  return (
    <>
      <Button variant={ButtonVariant.OUTLINED} onClick={onOpenDrawer}>
        View application details
      </Button>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            width: '880px',
            maxWidth: '75%',
            boxShadow: `-10px 0 15px -5px ${theme.colors.Violet20}`,
          },
        }}
      >
        <Col p={theme.space.sm} gap={theme.space.xs} style={{ height: '100%' }}>
          <Row justifyBetween alignCenter>
            <Button
              style={{ padding: theme.space.xsmed }}
              variant={ButtonVariant.OUTLINED}
              onClick={onCloseDrawer}
            >
              <Icon name="cancel" />
            </Button>
            {showRedirectToEditApplicationButton && (
              <Button
                slim
                variant={ButtonVariant.OUTLINED}
                onClick={handleRedirectToEditApplication}
              >
                Edit application
              </Button>
            )}
          </Row>

          <Col gap={theme.space.xs} fullWidth>
            <Text variant="h3">Application Details</Text>
            {allowItemEditing && (
              <Row alignCenter gap={theme.space.xxs}>
                <SvgIcon name="alert" size={18} color={theme.colors.brand} />
                <Text variant="body3" color={theme.colors.brand}>
                  Note: any edits here will affect applications for ALL SHIFTS
                  under this shift request
                </Text>
              </Row>
            )}

            <ViewAndEditExistingApplicationItemsList
              applicationItems={application.applicationItems}
              isEditable={allowItemEditing}
            />
          </Col>
        </Col>
      </Drawer>
    </>
  )
}
