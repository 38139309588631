import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  ActionableRequirement,
  ActionType,
  W9FormStatus,
  WorkerRequirementAction,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'

const WORKER_REQUIREMENT_ACTIONS_LIST_QUERY_KEY = 'useWorkerRequirementActions'

async function getWorkerRequirementActions(workerId: string) {
  try {
    const response = await trabaApi.post('worker-requirement-actions/list', {
      workerId,
    })
    return response.data
  } catch (e: unknown) {
    const errorMessage = `useWorkerRequirementActions -> getActiveWorkerRequirementActions() ERROR ${
      e instanceof Error ? e.message : e
    }`
    captureSentryError(e)
    console.error(errorMessage)
  }
}

export const useWorkerRequirementActions = (workerId: string) => {
  const {
    data: workerRequirementActions,
    isLoading: isLoadingWorkerRequirementActions,
    isRefetching: isRefetchingWorkerRequirementActions,
    refetch: refetchWorkerRequirementActions,
  } = useQuery<WorkerRequirementAction[]>({
    queryKey: [WORKER_REQUIREMENT_ACTIONS_LIST_QUERY_KEY, workerId],
    queryFn: () => getWorkerRequirementActions(workerId),
  })

  const activeBackgroundCheckAction = useMemo(
    () =>
      workerRequirementActions?.find(
        (action) =>
          action.requirement === ActionableRequirement.BACKGROUND_CHECK &&
          action.isActive,
      ),
    [workerRequirementActions],
  )

  const activePhoneNumberAction = useMemo(
    () =>
      workerRequirementActions?.find(
        (action) =>
          action.requirement === ActionableRequirement.PHONE_NUMBER &&
          action.isActive,
      ),
    [workerRequirementActions],
  )

  const activeW9FormAction = useMemo(
    () =>
      workerRequirementActions?.find(
        (action) =>
          action.requirement === ActionableRequirement.W9_FORM &&
          action.isActive,
      ),
    [workerRequirementActions],
  )

  const activeArticulate360GmpAction = useMemo(
    () =>
      workerRequirementActions?.find(
        (action) =>
          action.requirement === ActionableRequirement.ARTICULATE_360_GMP &&
          action.isActive,
      ),
    [workerRequirementActions],
  )

  return {
    workerRequirementActions,
    isLoadingWorkerRequirementActions,
    isRefetchingWorkerRequirementActions,
    activePhoneNumberAction,
    activeBackgroundCheckAction,
    activeW9FormAction,
    activeArticulate360GmpAction,
    refetchWorkerRequirementActions,
  }
}

export function useCreateOrUpdateWorkerRequirementAction() {
  const mutation = useMutation({
    mutationFn: async ({
      workerId,
      requirementType,
      actionType,
      expiryTime,
    }: {
      workerId: string
      requirementType: string
      actionType: string
      expiryTime?: Date
    }) => {
      const res = await trabaApi.put(
        `worker-requirement-actions/create-or-update`,
        {
          workerId,
          requirementType,
          actionType,
          ...(expiryTime && { expiryTime: expiryTime.toISOString() }),
        },
      )
      return res.data
    },
  })

  return mutation
}

export function useW9FormAction(onSuccess: () => Promise<void> | void) {
  const [isLoadingW9FormUpdate, setIsLoadingW9FormUpdate] = useState(false)
  const { handleError } = useAlert()
  const createOrUpdateWorkerRequirementAction =
    useCreateOrUpdateWorkerRequirementAction()

  const handleW9Action = useCallback(
    async (
      workerId: string,
      hasActiveAction: boolean,
      w9FormStatus?: W9FormStatus,
    ) => {
      const workerRequirementActionUpdates = {
        workerId,
        requirementType: ActionableRequirement.W9_FORM,
        actionType: ActionType.DISMISSIBLE,
      }

      try {
        if (!hasActiveAction) {
          if (!w9FormStatus) {
            setIsLoadingW9FormUpdate(true)
            try {
              await trabaApi.post(`stripe/w9-form/request/${workerId}`)
            } catch (error) {
              handleError(
                error,
                'useW9FormAction -> stripe/w9-form/request',
                "There was an error setting the request for the worker's W-9 form.",
              )
            } finally {
              setIsLoadingW9FormUpdate(false)
            }
          } else {
            await createOrUpdateWorkerRequirementAction.mutateAsync(
              workerRequirementActionUpdates,
            )
          }
        } else {
          await createOrUpdateWorkerRequirementAction.mutateAsync({
            ...workerRequirementActionUpdates,
            expiryTime: new Date(),
          })
        }
        await onSuccess()
      } catch (error) {
        handleError(
          error,
          'useW9FormAction',
          'There was an error updating the W9 form action.',
        )
      }
    },
    [createOrUpdateWorkerRequirementAction, handleError, onSuccess],
  )

  return {
    handleW9Action,
    isLoadingW9FormUpdate,
  }
}

export function useBackgroundCheckAction(
  onSuccess: () => Promise<void> | void,
) {
  const { handleError } = useAlert()
  const createOrUpdateWorkerRequirementAction =
    useCreateOrUpdateWorkerRequirementAction()

  const handleBackgroundCheckAction = async (
    workerId: string,
    hasActiveAction: boolean,
    actionType: ActionType = ActionType.DISMISSIBLE,
  ) => {
    const workerRequirementActionUpdates = {
      workerId,
      requirementType: ActionableRequirement.BACKGROUND_CHECK,
      actionType,
    }

    try {
      if (!hasActiveAction) {
        await createOrUpdateWorkerRequirementAction.mutateAsync(
          workerRequirementActionUpdates,
        )
      } else {
        await createOrUpdateWorkerRequirementAction.mutateAsync({
          ...workerRequirementActionUpdates,
          expiryTime: new Date(),
        })
      }
      await onSuccess()
    } catch (error) {
      handleError(
        error,
        'useBackgroundCheckAction',
        'There was an error updating the background check action.',
      )
    }
  }

  return {
    handleBackgroundCheckAction,
    isLoading: createOrUpdateWorkerRequirementAction.isPending,
  }
}

export function useArticulate360GmpAction(
  onSuccess: () => Promise<void> | void,
) {
  const { handleError } = useAlert()
  const createOrUpdateWorkerRequirementAction =
    useCreateOrUpdateWorkerRequirementAction()

  const handleArticulate360GmpAction = async (
    workerId: string,
    hasActiveAction: boolean,
  ) => {
    const workerRequirementActionUpdates = {
      workerId,
      requirementType: ActionableRequirement.ARTICULATE_360_GMP,
      actionType: ActionType.BLOCKED_ALL,
    }
    try {
      if (!hasActiveAction) {
        await createOrUpdateWorkerRequirementAction.mutateAsync(
          workerRequirementActionUpdates,
        )
      } else {
        await createOrUpdateWorkerRequirementAction.mutateAsync({
          ...workerRequirementActionUpdates,
          expiryTime: new Date(),
        })
      }
      await onSuccess()
    } catch (error) {
      handleError(
        error,
        'useArticulate360GmpAction',
        'There was an error updating the articulate 360 GMP action.',
      )
    }
  }

  return {
    handleArticulate360GmpAction,
    isLoading: createOrUpdateWorkerRequirementAction.isPending,
  }
}
