import {
  Button,
  ButtonVariant,
  Col,
  Input,
  Row,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, InputStatus } from '@traba/types'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import Divider from 'src/components/base/Divider'
import { useTimesheetSettings } from 'src/hooks/useTimesheetSettings'
import { INVALID_EMAIL_MESSAGE } from 'src/utils/inputUtils'
import * as yup from 'yup'
import { DetailRow } from './DetailRow'

const containerStyle: React.CSSProperties = {
  borderRadius: theme.space.xxs,
  border: `2px solid ${theme.colors.Grey20}`,
  padding: theme.space.sm,
}

const editButtonStyle: React.CSSProperties = {
  width: '200px',
}

export function TimesheetSettingsTab({ company }: { company: Company }) {
  const {
    updateTimesheetEmail,
    configureForTimesheets,
    timesheetSettings,
    isLoadingUpdateTimesheetEmail,
  } = useTimesheetSettings()

  const usesTimesheets = useMemo(
    () => timesheetSettings?.companyIds.includes(company.companyId) ?? false,
    [timesheetSettings, company],
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      timesheetEmail: company?.timesheetEmail ?? '',
    },
    validationSchema: yup.object({
      email: yup.string().email(INVALID_EMAIL_MESSAGE),
    }),
    onSubmit: ({ timesheetEmail }) => {
      updateTimesheetEmail({
        companyId: company.companyId,
        timesheetEmail: timesheetEmail?.trim() || null,
      })
    },
  })

  const SaveChangesButton = () => (
    <Button
      variant={ButtonVariant.FILLED}
      slim
      style={editButtonStyle}
      type="submit"
      loading={isLoadingUpdateTimesheetEmail}
    >
      Save Changes
    </Button>
  )

  return (
    <form style={containerStyle} onSubmit={formik.handleSubmit}>
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h5">Timesheet Settings</Text>
        <Row gap={theme.space.xs}>
          <Toggle
            label={'Enable'}
            buttonState={usesTimesheets}
            runOnChange={() => {
              configureForTimesheets({
                companyId: company.companyId,
                enabled: !usesTimesheets,
              })
            }}
          />
        </Row>
      </Row>
      <Divider />
      <Row flexCol gap={theme.space.sm} mt={theme.space.xs}>
        <DetailRow
          label={'Timesheet Notification Email'}
          value={formik.values.timesheetEmail}
          editing={true}
        >
          <Col width={'100%'}>
            <Input
              label={'Timesheet Notification Email'}
              name={'timesheetEmail'}
              value={formik.values.timesheetEmail}
              onChange={formik.handleChange}
              inputStatus={
                formik.errors.timesheetEmail
                  ? InputStatus.error
                  : InputStatus.default
              }
              errorMessage={formik.errors.timesheetEmail}
              width="100%"
              type={'email'}
              disabled={!usesTimesheets}
            />
          </Col>
        </DetailRow>
        <Row justifyEnd>{usesTimesheets && <SaveChangesButton />}</Row>
      </Row>
    </form>
  )
}
