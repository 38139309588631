import { useAlert } from '@traba/context'
import {
  Dialog,
  MODAL_SIZE,
  Row,
  SearchSelect,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  DEFAULT_DRUG_TEST_EXPIRATION_DATE_DAYS,
  DRUG_PANEL_DERIVED_TYPES_SUFFIXES,
  DrugPanelType,
  DrugTestType,
  EmploymentType,
  IMenuItem,
  isValidDrugScreeningCombination,
  MAX_DRUG_TEST_EXPIRATION_DATE_DAYS,
  MIN_DRUG_TEST_EXPIRATION_DATE_DAYS,
} from '@traba/types'
import { addDays, differenceInDays } from 'date-fns'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { DatePicker, Input } from 'src/components/base'
import { useOrderNewDrugScreeningOrder } from 'src/hooks/useDrugScreening'

interface DrugScreeningOrderModalProps {
  showModal: boolean
  onCloseModal: () => void
  workerId: string
  workerName: string
}

/**
 * Converts enum values to menu items by replacing underscores with spaces
 */

const enumToMenuItems = (enumValues: string[]) => {
  return enumValues.map((value) => ({
    label: value.replace(/_/g, ' '),
    value,
  }))
}

/**
 * Base panel types excluding derived types (POST_ACCIDENT and RANDOM)
 */
const BASE_PANEL_TYPES = Object.values(DrugPanelType).filter(
  (panel) =>
    !DRUG_PANEL_DERIVED_TYPES_SUFFIXES.some((suffix) => panel.endsWith(suffix)),
)

export const DrugScreeningOrderModal = ({
  showModal,
  onCloseModal,
  workerId,
  workerName,
}: DrugScreeningOrderModalProps) => {
  const { showError, showSuccess } = useAlert()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const startOfToday = useMemo(() => new Date().setHours(0, 0, 0, 0), [])
  const DEFAULT_TEST_TYPE_IMENU_ITEM = useMemo(
    () => ({
      label: 'PRE EMPLOYMENT',
      value: DrugTestType.PRE_EMPLOYMENT,
    }),
    [],
  )

  const [selectedPanelType, setSelectedPanelType] = useState<IMenuItem>()
  const [selectedTestType, setSelectedTestType] = useState<IMenuItem>(
    DEFAULT_TEST_TYPE_IMENU_ITEM,
  )
  const [shiftRequestId, setShiftRequestId] = useState<string>()
  const [expirationDate, setExpirationDate] = useState<Date>(() =>
    addDays(new Date(startOfToday), DEFAULT_DRUG_TEST_EXPIRATION_DATE_DAYS),
  )
  const [selectedEmploymentType, setSelectedEmploymentType] =
    useState<IMenuItem>()
  const panelOptions = useMemo<IMenuItem[]>(() => {
    return enumToMenuItems(BASE_PANEL_TYPES)
  }, [])

  const employmentTypeOptions = useMemo(() => {
    return Object.keys(EmploymentType).map((employmentType) => {
      return {
        value: employmentType,
        label: employmentType,
      }
    })
  }, [])

  const getTestTypeOptions = useCallback(() => {
    if (!selectedPanelType) {
      return []
    }

    return enumToMenuItems(
      Object.values(DrugTestType).filter((testType) =>
        isValidDrugScreeningCombination(
          selectedPanelType.value as DrugPanelType,
          testType,
        ),
      ),
    )
  }, [selectedPanelType])

  const resetStates = useCallback(() => {
    setSelectedPanelType(undefined)
    setSelectedTestType(DEFAULT_TEST_TYPE_IMENU_ITEM)
    setShiftRequestId(undefined)
    setExpirationDate(
      addDays(new Date(startOfToday), DEFAULT_DRUG_TEST_EXPIRATION_DATE_DAYS),
    )
  }, [startOfToday, DEFAULT_TEST_TYPE_IMENU_ITEM])

  const handlePanelTypeChange = useCallback(
    (newPanelType: IMenuItem | undefined) => {
      setSelectedPanelType(newPanelType)
      // Reset test type to PRE_EMPLOYMENT as it's always valid
      setSelectedTestType(DEFAULT_TEST_TYPE_IMENU_ITEM)
    },
    [DEFAULT_TEST_TYPE_IMENU_ITEM],
  )

  const handleTestTypeChange = useCallback(
    (newTestType: IMenuItem | undefined) => {
      if (newTestType) {
        setSelectedTestType(newTestType)
      }
    },
    [],
  )

  const { mutateAsync: createDrugScreeningOrder } =
    useOrderNewDrugScreeningOrder()

  const onConfirm = useCallback(async () => {
    if (isSubmitting) {
      return
    }

    if (
      !selectedPanelType ||
      !selectedTestType ||
      !selectedEmploymentType ||
      !shiftRequestId
    ) {
      showError('Please fill all fields')
      return
    }

    const panel = selectedPanelType.value as DrugPanelType
    const testType = selectedTestType.value as DrugTestType

    if (!isValidDrugScreeningCombination(panel, testType)) {
      showError('Invalid panel and test type combination')
      return
    }

    setIsSubmitting(true)

    try {
      const drugPanelType =
        testType === DrugTestType.PRE_EMPLOYMENT
          ? panel
          : (`${panel}_${testType}` as DrugPanelType)

      await createDrugScreeningOrder({
        workerId,
        drugPanelType,
        shiftRequestId,
        expirationDays: differenceInDays(
          expirationDate,
          new Date(startOfToday),
        ),
        employmentType: selectedEmploymentType.value as EmploymentType,
      })

      showSuccess('Drug screening order created successfully')
      resetStates()
      onCloseModal()
    } catch (error: any) {
      showError(`Error creating drug screening order: ${error.message}`)
    } finally {
      setIsSubmitting(false)
    }
  }, [
    isSubmitting,
    selectedPanelType,
    selectedTestType,
    shiftRequestId,
    selectedEmploymentType,
    expirationDate,
    startOfToday,
    workerId,
    createDrugScreeningOrder,
    onCloseModal,
    resetStates,
    showError,
    showSuccess,
  ])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showModal}
      onClose={() => {
        resetStates()
        onCloseModal()
      }}
      onConfirmCTA="Order"
      dialogTitle={`Order New Drug Screen for ${workerName}`}
      formId="create-drug-screen-order"
      confirmDisabled={
        !selectedPanelType ||
        !selectedTestType ||
        !selectedEmploymentType ||
        isSubmitting
      }
      confirming={isSubmitting}
      onConfirm={onConfirm}
      size={MODAL_SIZE.LARGE}
    >
      <Row flexCol gap={theme.space.xs}>
        <Row flexCol gap={theme.space.xs}>
          <Text variant="h6">Drug Panel Type</Text>
          <SearchSelect
            options={panelOptions}
            selectItem={selectedPanelType}
            handleSelect={handlePanelTypeChange}
            label="Select Panel Type"
            required
          />
        </Row>
        <Row flexCol gap={theme.space.xs}>
          <Text variant="h6">Test Type</Text>
          <SearchSelect
            options={getTestTypeOptions()}
            selectItem={selectedTestType}
            handleSelect={handleTestTypeChange}
            label="Select Test Type"
            required
            disabled={!selectedPanelType}
          />
        </Row>

        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6">Shift Request ID</Text>
          <Input
            label="Shift Request ID"
            value={shiftRequestId}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setShiftRequestId(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="100%"
          />
        </Row>

        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6">Employment Type</Text>
          <SearchSelect
            options={employmentTypeOptions}
            selectItem={selectedEmploymentType}
            handleSelect={setSelectedEmploymentType}
            label="Select Employment Type"
            required
          />
        </Row>

        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6">Last Date to finish drug screening</Text>
          <DatePicker
            date={expirationDate}
            setDate={(newDate) => newDate && setExpirationDate(newDate)}
            isClearable={false}
            aria-label="Expiration Date"
            isRequired={true}
            granularity="day"
            defaultDate={expirationDate}
            minDate={addDays(
              new Date(startOfToday),
              MIN_DRUG_TEST_EXPIRATION_DATE_DAYS,
            )}
            maxDate={addDays(
              new Date(startOfToday),
              MAX_DRUG_TEST_EXPIRATION_DATE_DAYS,
            )}
          />
        </Row>
      </Row>
    </Dialog>
  )
}
