import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  BreakType,
  GenderPreference,
  InvoiceChargeCycle,
  RelatedShiftsType,
  RequiredMultiShiftType,
  ShiftInvitationsDto,
  ShiftPayType,
  TierLevel,
  WorkerMedia,
  ForwardFillMax,
  ForwardFillRenotificationMethod,
  InvitedWorkers,
  PaymentType,
  ScheduledBreak,
  ShiftNotificationStatus,
  ShiftSignupStatus,
  ShiftTag,
  ShiftStatus,
  CreateSchedule,
  EmploymentType,
  ApplicationForShiftRequestRequest,
} from '@traba/types'
import { useState } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { getErrorMessage } from 'src/utils/errorUtils'
import { MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB } from 'src/utils/shiftFormUtils'
import { getQueryParams } from './useApi'

export type CreateShiftRequest = {
  schedules: CreateSchedule[]
  roleId: string
  locationId: string
  parkingLocationId?: string
  companyId: string
  supervisorId: string
  favoriteWorkersFirst: boolean
  invitedWorkers: InvitedWorkers
  scheduledBreaks?: ScheduledBreak[]
  slotsRequested: number
  minSlotsRequested: number
  trustAndSafetyFeeHourly: {
    amount: number
    currency: string
  }
  payType: ShiftPayType
  hourlyRate: number
  payRate: number
  numberOfUnits?: number
  workerMedia?: WorkerMedia[]
  hasPaidBreaks?: boolean
  minimumPaidTime?: number
  status: ShiftStatus
  shortLocation?: string
  employerName?: string
  allShiftsRequired?: boolean
  breakType: BreakType
  invoiceChargeCycle: InvoiceChargeCycle
  eventName?: string
  invoiceGroupId?: string
  parentInvoiceGroupId?: string
  additionalEmails?: string[]
  paymentType?: PaymentType
  hasSameDayClockInAndOutCodes?: boolean
  forwardFillMax?: ForwardFillMax
  videoIds?: string[]
  shiftCodesReceiverIds?: string[]
  shiftInvitations?: ShiftInvitationsDto[]
  shouldBypassValidateSchedule?: boolean
  signupStatus?: ShiftSignupStatus
  notificationStatus?: ShiftNotificationStatus
  overbookSlotsRequested?: number
  paidBackupSlotsRequested?: number
  paidBackupPayAmount?: number
  enableDynamicOverbooking?: boolean
  // Fields for overriding default shift acceptance thresholds
  reliabilityThreshold?: number
  unprovenWorkerThreshold?: number
  forceShowInExplore?: boolean
  minimumAcceptedTier: TierLevel
  tags?: ShiftTag[]
  requiredMultiShiftType: RequiredMultiShiftType
  forwardFillRenotificationMethod?: ForwardFillRenotificationMethod
  relatedShiftsType?: RelatedShiftsType
  hasPreShiftConfirmationRobocall?: boolean
  opsLocationDetailsOverride?: string
  genderPreference?: GenderPreference
  requireW9Authorization: boolean
  shiftRequestParentTitle?: string
  shiftEmploymentType: EmploymentType
  application?: ApplicationForShiftRequestRequest
  earlyArrivalTimeBufferMinutes?: number
}

function cleanUpShiftRequestForSend(
  shiftRequest: CreateShiftRequest,
): CreateShiftRequest {
  const cleanedRequest = { ...shiftRequest }

  delete cleanedRequest.shouldBypassValidateSchedule
  delete cleanedRequest.earlyArrivalTimeBufferMinutes

  if (
    cleanedRequest.forwardFillMax !== ForwardFillMax.INVITED_FIRST &&
    cleanedRequest.forwardFillMax !== ForwardFillMax.INVITED_ONLY
  ) {
    cleanedRequest.shiftInvitations = undefined
  }

  // We don't support dynamic overbooking on shifts with less than 5 slots
  // requested.
  if (
    cleanedRequest.slotsRequested < MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
  ) {
    cleanedRequest.enableDynamicOverbooking = false
  }

  // If dynamic overbooking was enabled, make sure any pre-set overbook
  // slots are removed from the request.
  if (cleanedRequest.enableDynamicOverbooking) {
    cleanedRequest.overbookSlotsRequested = undefined
  }

  return cleanedRequest
}

export const useShiftRequests = () => {
  const { showError, showSuccess } = useAlert()

  const [createShiftReqLoading, setCreateShiftReqLoading] =
    useState<boolean>(false)

  const { state } = useUserContext()

  const createShiftRequest = async (createShiftRequest: CreateShiftRequest) => {
    setCreateShiftReqLoading(true)
    try {
      const queryString = getQueryParams([
        [
          'shouldBypassValidateSchedule',
          createShiftRequest.shouldBypassValidateSchedule,
        ],
      ])
      const shiftReqToPost = { ...createShiftRequest }
      const cleanedShiftReq = cleanUpShiftRequestForSend(shiftReqToPost)

      const res = await trabaApi.post(
        `/shift-requests${queryString}`,
        cleanedShiftReq,
      )
      showSuccess(
        `Created shift request for company ${createShiftRequest.employerName}`,
        'Successfully Created Shift Request',
      )
      window.analytics.track('Console Shift Request Created', {
        companyId: createShiftRequest.companyId,
        shiftRequest: createShiftRequest,
        initiatedBy: state.userProfile?.email,
      })
      return res.data
    } catch (e) {
      showError(getErrorMessage(e), 'Error Creating Shift Request')
      throw e
    } finally {
      setCreateShiftReqLoading(false)
    }
  }

  const bulkCreateShiftRequests = async (
    createShiftRequests: CreateShiftRequest[],
  ): Promise<{ shiftRequestParentId: string }> => {
    setCreateShiftReqLoading(true)
    try {
      const queryString = getQueryParams([
        [
          'shouldBypassValidateSchedule',
          createShiftRequests.some((s) => s.shouldBypassValidateSchedule),
        ],
      ])
      const cleanedShiftRequests = createShiftRequests.map((shiftReq) =>
        cleanUpShiftRequestForSend(shiftReq),
      )
      const res = await trabaApi.post(`/shift-requests/bulk${queryString}`, {
        shiftRequests: cleanedShiftRequests,
      })
      showSuccess(
        `Created ${cleanedShiftRequests.length} shift requests`,
        'Successfully Created Shift Requests',
      )
      window.analytics.track('Console Bulk Shift Requests Created', {
        companyId: createShiftRequests[0].companyId,
        shiftRequests: cleanedShiftRequests,
        initiatedBy: state.userProfile?.email,
      })
      return res.data
    } catch (e) {
      showError(getErrorMessage(e), 'Error Creating Shift Requests')
      throw e
    } finally {
      setCreateShiftReqLoading(false)
    }
  }

  return {
    createShiftRequest,
    createShiftReqLoading,
    bulkCreateShiftRequests,
  }
}
