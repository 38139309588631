import {
  AcceptShiftBypassSetting,
  EligibilityCriteria,
  WorkerShiftEligibility,
  AcceptShiftBypasses,
  ActionableRequirement,
} from '@traba/types'
import { uniq } from 'lodash'

export function getUniqueCriteria(eligibilityData: WorkerShiftEligibility[]) {
  const shiftCriteria =
    eligibilityData?.flatMap((e) =>
      Object.entries(e.eligibilityCriteria)
        .filter(([, val]) => !val)
        .map(([key]) => key as keyof EligibilityCriteria),
    ) ?? []

  return uniq(shiftCriteria)
}

export function getMapFromBypassSettings(settings: AcceptShiftBypassSetting[]) {
  return new Map(
    settings.map((setting) => [setting.eligibilityCriteria, setting]),
  )
}

export const criteriaMapper: Record<
  keyof EligibilityCriteria,
  {
    label?: string // human readable error for the eligibility criteria
    bypassKey: keyof AcceptShiftBypasses | null // Mapped bypass key
    bypassLabel?: string // Mapped bypass label
  }
> = {
  shiftStatusOk: {
    label: 'Shift status not ok',
    bypassKey: 'bypassShiftStatus',
    bypassLabel: 'Bypass Shift Status',
  },
  shiftAllowsSignups: {
    label: 'Shift does not allow signup',
    bypassKey: 'bypassSignupStatus',
    bypassLabel: 'Bypass Shift Status',
  },
  workerHasAttributes: {
    label: "Worker doesn't have all attributes",
    bypassKey: 'bypassAttributeRequirements',
    bypassLabel: 'Bypass Attribute Requirement',
  },
  shiftIsNotFull: {
    label: 'Shift is full',
    bypassKey: 'allowOverbook',
    bypassLabel: 'Allow Overbook',
  },
  workerPassesFavoritesOnly: {
    label: 'Shift is favorites only',
    bypassKey: 'bypassFavoritesOnly',
    bypassLabel: 'Bypass Favorite Only',
  },
  workerIsAvailable: {
    label: 'Worker is not available',
    bypassKey: 'bypassWorkerAvailability',
    bypassLabel: 'Bypass Worker Availability',
  },
  workerPassesFavoritesFirst: {
    label: 'Shift is favorites first',
    bypassKey: 'bypassFavoritesFirst',
    bypassLabel: 'Bypass Favorite First',
  },
  workerPassesUnprovenThreshold: {
    label: 'Worker is unproven',
    bypassKey: 'bypassUnprovenThreshold',
    bypassLabel: 'Bypass Unproven Threshold',
  },
  workerPassesUnprovenTodoLimit: {
    label: "Worker doesn't pass unproven to-do limit",
    bypassKey: 'bypassUnprovenTodoLimit',
    bypassLabel: 'Bypass Todo Limit',
  },
  workerPassesExtraBGCRequirement: {
    label: "Worker doesn't bypass extra BGC requirement",
    bypassKey: 'bypassExtraBGCRequirement',
    bypassLabel: 'Bypass Extra BGC Requirement',
  },
  workerPassesAcceptedTier: {
    label: 'Minimum tier not reached',
    bypassKey: 'bypassMinimumAcceptedTier',
    bypassLabel: 'Bypass Accepted Tier',
  },
  workerPassesMinimumAgeRequirement: {
    label: 'Minimum Age Not Passed',
    bypassKey: 'bypassMinimumAgeRequirement',
    bypassLabel: 'Bypass Minimum Age Requirement',
  },
  workerPassesResumeUploadRequired: {
    label: 'Resume not uploaded',
    bypassKey: 'bypassResumeUploadRequired',
    bypassLabel: 'Bypass Resume Upload Required',
  },
  workerPassesForwardFill: {
    label: "Not eligible because hasn't been forward fill notified",
    bypassKey: 'bypassForwardFill',
    bypassLabel: 'Bypass Not Forward Fill Notified',
  },
  accountStatusOk: {
    label: 'Account not approved',
    bypassKey: null,
    bypassLabel:
      'One or more workers can not accept shift as their account is not approved',
  },
  workerIsCertified: {
    label: 'Worker has missing or expired certifications',
    bypassKey: null,
    bypassLabel:
      'Not all workers have a certification that is required for this shift, or some of them might be expired.',
  },
  workerHasNoRevokedAttributes: {
    label: 'Worker has revoked attributes',
    bypassKey: null,
    bypassLabel:
      'One or more workers have a revoked attribute that is required for this shift',
  },
  workerIsNotBlocked: {
    label: 'Worker is blocked',
    bypassKey: null,
    bypassLabel: 'One or more workers can not accept shift as they are blocked',
  },
  workerIsNotIneligible: {
    label: 'Worker is ineligible',
    bypassKey: null,
    bypassLabel:
      'One or more workers can not accept shift as they are ineligible',
  },
  companyIsApproved: {
    label: 'Company is not approved',
    bypassKey: null,
    bypassLabel: 'Workers can not accept shift as company is not approved',
  },
  workerPassesInvitedOnly: {
    label: 'Worker is not invited',
    bypassKey: 'bypassInvitations',
    bypassLabel: 'Bypass Invited Only',
  },
  workerPassesInvitedFirst: {
    label: 'Worker is not invited',
    bypassKey: 'bypassInvitations',
    bypassLabel: 'Bypass Invited First',
  },
  workerPassesRegion: { bypassKey: null, bypassLabel: '' },
  shiftPassesNoBackfill: {
    label: 'Can not add worker during backfill period',
    bypassLabel: 'Bypass Backfill Period',
    bypassKey: 'bypassNoBackfill',
  },
  workerShiftIsNotBizCancelled: {
    label: 'Worker was previously biz cancelled',
    bypassKey: 'bypassWorkerShiftIsNotBizCancelled',
    bypassLabel: 'Bypass Biz Cancellation',
  },
  workerShiftIsNotOpsCancelled: {
    label: 'Worker was previously ops cancelled',
    bypassKey: 'bypassWorkerShiftIsNotOpsCancelled',
    bypassLabel: 'Bypass Ops Cancellation',
  },
  workerPassesBGCRequirement: {
    bypassKey: null, // This requirement can't be bypassed, instead we need to waive it.
  },
  workerPassesOpsBGCRequirement: {
    label: 'Worker has not completed background check',
    bypassKey: null, // This requirement can't be bypassed, instead we need to waive it.
  },
  workerMeetsW9Requirement: {
    label: 'Worker has not completed Stripe/W9',
    bypassKey: null, // This requirement can't be bypassed, instead we need to waive it.
  },
}

export const checkShouldShowRecurringSlotBypass = (
  eligibilityData: WorkerShiftEligibility[] | undefined,
) => {
  return eligibilityData?.some((e) => {
    return e.recurringEligibilityCriteria?.recurringSlotAvailable === false
  })
}

export const checkShouldShowRecurringWorkerBypass = (
  eligibilityData: WorkerShiftEligibility[] | undefined,
) => {
  return eligibilityData?.some((e) => {
    return e.recurringEligibilityCriteria?.workerIsAvailable === false
  })
}

export const hasWaivableEligibilityErrors = (
  eligibilityData?: WorkerShiftEligibility[],
) => {
  if (!eligibilityData) {
    return false
  }

  return eligibilityData.some(
    (e) =>
      e?.eligibilityCriteria &&
      (!e?.eligibilityCriteria?.workerPassesOpsBGCRequirement ||
        !e?.eligibilityCriteria?.workerMeetsW9Requirement),
  )
}

export const eligibilityToActionableRequirementMap: Record<
  keyof Pick<
    EligibilityCriteria,
    'workerPassesOpsBGCRequirement' | 'workerMeetsW9Requirement'
  >,
  ActionableRequirement
> = {
  workerPassesOpsBGCRequirement: ActionableRequirement.BACKGROUND_CHECK,
  workerMeetsW9Requirement: ActionableRequirement.W9_FORM,
}
