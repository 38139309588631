import { Input, Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WorkerTimecard,
  WorkerTimecardBreaksAdjustmentReason,
  WorkerTimecardStatus,
} from '@traba/types'
import { Td } from 'src/components/base'

interface W2WorkerBreakDisputeRowProps {
  timecard: WorkerTimecard
  finalBreakDuration: number | undefined
  setFinalBreakDuration: (finalBreakDuration: number | undefined) => void
}

export function W2WorkerBreakDisputeRow(props: W2WorkerBreakDisputeRowProps) {
  const { finalBreakDuration, setFinalBreakDuration, timecard } = props

  const {
    bizTotalBreakDurationSeconds,
    workerTotalBreakDurationSeconds,
    opsTotalBreakDurationSeconds,
    workerBreaksAdjustmentReason,
    workerBreaksAdjustmentOtherReason,
    finalizedReason,
  } = timecard
  const hasChanged =
    bizTotalBreakDurationSeconds !== workerTotalBreakDurationSeconds
  const noActionAllowed =
    timecard.status === WorkerTimecardStatus.FINALIZED ||
    timecard.status === WorkerTimecardStatus.PENDING_WORKER

  const finalDuration =
    Number(
      opsTotalBreakDurationSeconds ??
        workerTotalBreakDurationSeconds ??
        bizTotalBreakDurationSeconds,
    ) / 60

  const workerReason =
    workerBreaksAdjustmentReason === WorkerTimecardBreaksAdjustmentReason.OTHER
      ? workerBreaksAdjustmentOtherReason
      : workerBreaksAdjustmentReason
  const workerRecord = workerTotalBreakDurationSeconds
    ? `${(Number(workerTotalBreakDurationSeconds) / 60).toFixed(0)}m`
    : finalizedReason
  return (
    <>
      <Td>
        <Text variant={'body1'}>
          {(Number(bizTotalBreakDurationSeconds) / 60).toFixed(0)}m
        </Text>
      </Td>
      <Td>
        <Text variant={hasChanged ? 'error' : 'body1'}>{workerRecord}</Text>
        {workerReason && (
          <Row alignCenter gap={theme.space.ms}>
            <SvgIcon name="info" color={theme.colors.Grey60} size={12} />
            <Text variant="caption">{workerReason}</Text>
          </Row>
        )}
      </Td>
      <Td>
        {hasChanged && !noActionAllowed ? (
          <Input
            label="Final break duration in minutes"
            type="number"
            value={(finalBreakDuration ?? 0) / 60}
            onChange={(e) => {
              setFinalBreakDuration(Number(e.target.value) * 60)
            }}
            style={{ height: 40 }}
            placeholder="Final break duration in minutes"
          />
        ) : (
          <Text variant="body1">{`${finalDuration}m`}</Text>
        )}
      </Td>
    </>
  )
}
