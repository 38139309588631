import { Text } from '@traba/react-components'
import { Td } from 'src/components/base'

interface W2WorkerHourDisputeRowProps {
  businessRecord: string
  workerRecord: string
  finalRecord: string
  hasChanged: boolean
}

export function W2WorkerTotalDisputeRow(props: W2WorkerHourDisputeRowProps) {
  const { businessRecord, workerRecord, finalRecord, hasChanged } = props

  return (
    <>
      <Td>
        <Text variant={'body1'}>{businessRecord}</Text>
      </Td>
      <Td>
        <Text variant={hasChanged ? 'error' : 'body1'}>{workerRecord}</Text>
      </Td>
      <Td>
        <Text variant="body1">{finalRecord}</Text>
      </Td>
    </>
  )
}
