import {
  OpsAttributeLevel,
  RequiredAttributeLevel,
  RoleAttribute,
  RoleAttributeCategory,
} from '@traba/types'
import React, { useCallback, useMemo } from 'react'
import { AttributeLevelsSection } from 'src/components/RequiredAttributeLevelsSections/AttributeLevelsSection'
import {
  getUpdatedRequiredAttributeLevels,
  ATTRIBUTE_LEVEL_SECTIONS,
} from 'src/utils/attributeUtils'

interface RequiredAttributeLevelsSectionProps {
  attributes: RoleAttribute[]
  setRequiredAttributeLevels?: (reqAttrLvls: RequiredAttributeLevel[]) => void
  requiredAttributeLevels?: RequiredAttributeLevel[]
  selectedAttributes?: string[]
  setSelectedAttributes?: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedQuestions?: React.Dispatch<React.SetStateAction<string[]>>
  allowBooleanSelectionOnly?: boolean
  showUnreported?: boolean
  showDeselect?: boolean
  isInSearchFilter?: boolean
}

export function RequiredAttributeLevelsSections(
  props: RequiredAttributeLevelsSectionProps,
) {
  const {
    attributes,
    setRequiredAttributeLevels,
    requiredAttributeLevels,
    selectedAttributes,
    setSelectedAttributes,
    setSelectedQuestions,
    allowBooleanSelectionOnly,
    showUnreported,
    showDeselect,
    isInSearchFilter,
  } = props

  const updateRequiredAttributeLevel = useCallback(
    (
      attrType: string,
      category: RoleAttributeCategory,
      level: OpsAttributeLevel,
    ) => {
      const updatedAttributeLevel: RequiredAttributeLevel = {
        attribute: { type: attrType, category },
        level,
      }

      const updatedLevels = getUpdatedRequiredAttributeLevels(
        requiredAttributeLevels || [],
        updatedAttributeLevel,
      )

      setRequiredAttributeLevels?.(updatedLevels)
    },
    [requiredAttributeLevels, setRequiredAttributeLevels],
  )

  const filteredAndSortedAttributesBySection = useMemo(() => {
    return ATTRIBUTE_LEVEL_SECTIONS.map((section) => {
      const filteredAttributes = attributes.filter(
        (attr) =>
          section.categories.includes(attr.category) &&
          attr.allowOpsLeveling &&
          (!section.filterFn || section.filterFn(attr)),
      )

      const sortedAttributes = filteredAttributes.sort((a, b) => {
        if (section.sortFn) {
          return section.sortFn(a, b)
        }
        const indexA = section.categories.indexOf(a.category)
        const indexB = section.categories.indexOf(b.category)
        return indexA - indexB
      })

      return {
        ...section,
        filteredAndSortedAttributes: sortedAttributes,
      }
    })
  }, [attributes])

  const attributeLevelMap = useMemo(() => {
    if (!requiredAttributeLevels) {
      return {}
    }

    return Object.fromEntries(
      requiredAttributeLevels.map((level) => [
        level.attribute.type,
        level.level,
      ]),
    ) as Record<string, OpsAttributeLevel>
  }, [requiredAttributeLevels])

  return filteredAndSortedAttributesBySection.map((section) => (
    <AttributeLevelsSection
      key={section.title}
      sectionTitle={section.title}
      titleTooltip={section.titleTooltip}
      attributes={section.filteredAndSortedAttributes}
      handleSelectAttributeLevel={updateRequiredAttributeLevel}
      attributeLevelMap={attributeLevelMap}
      selectedAttributes={selectedAttributes}
      setSelectedAttributes={setSelectedAttributes}
      setSelectedQuestions={setSelectedQuestions}
      showRevoke={false}
      showUnreported={showUnreported}
      showDeselect={showDeselect}
      isInSearchFilter={isInSearchFilter}
      allowBooleanSelectionOnly={allowBooleanSelectionOnly}
    />
  ))
}
