import { Button, ButtonVariant, Col, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  RequiredAttributeLevel,
  RequiredAttributeLevelWithLevelType,
} from '@traba/types'
import { useCallback, useMemo } from 'react'
import { useAttributes } from 'src/hooks/useAttributes'
import { RequiredAttributeLevelsModal } from 'src/modals/RequiredAttributeLevelsModal/RequiredAttributeLevelsModal'
import { filterNonOpsLevelingLanguages } from 'src/utils/attributeUtils'
import { AttributeBadge } from '../AttributeBadge/AttributeBadge'
import { useModal } from '../base/Modal/Modal'

interface AttributeLevelSearchFilterProps {
  attributeLevels: RequiredAttributeLevel[]
  onUpdateAttributeLevels: (
    attributeLevels: RequiredAttributeLevelWithLevelType[],
  ) => void
}

export function AttributeLevelSearchFilter(
  props: AttributeLevelSearchFilterProps,
) {
  const { attributeLevels, onUpdateAttributeLevels } = props

  const { attributes: roleAttributes } = useAttributes()
  const {
    isOpen: isRoleAttrModalOpen,
    open: openRoleAttrModal,
    handleClose: handleCloseRoleAttrModal,
  } = useModal()

  const handleUpdateAttributeLevels = useCallback(
    (attributeLevels: RequiredAttributeLevel[]) => {
      const updatedAttributeLevelsWithLevelType: RequiredAttributeLevelWithLevelType[] =
        attributeLevels
          .map((level) => {
            const matchingAttribute = roleAttributes.find(
              (attr) => attr.type === level.attribute.type,
            )
            if (
              matchingAttribute &&
              matchingAttribute.levelType !== undefined
            ) {
              return {
                ...level,
                levelType: matchingAttribute.levelType,
              }
            }
            return null
          })
          .filter(
            (level): level is RequiredAttributeLevelWithLevelType =>
              level !== null,
          )

      onUpdateAttributeLevels(updatedAttributeLevelsWithLevelType)
    },
    [roleAttributes, onUpdateAttributeLevels],
  )

  const filteredAttributes = useMemo(() => {
    return roleAttributes.filter(filterNonOpsLevelingLanguages)
  }, [roleAttributes])

  return (
    <Col>
      <Button
        onClick={(event: any) => {
          event.preventDefault()
          event.stopPropagation()
          openRoleAttrModal()
        }}
        variant={ButtonVariant.OUTLINED}
        slim
        style={{ marginBottom: theme.space.sm }}
      >
        Attribute Levels
      </Button>
      <Row wrap>
        {attributeLevels?.map((attribute) => {
          const roleAttribute = roleAttributes?.find(
            (sa) => sa.type === attribute.attribute.type,
          )
          if (!roleAttribute) {
            return null
          }
          return (
            <AttributeBadge
              attributeName={
                roleAttribute?.displayName ?? attribute.attribute.type
              }
              attributeLevel={attribute.level}
              attributeType={attribute.attribute.type}
              levelType={roleAttribute?.levelType}
              category={attribute.attribute.category}
              fontSize={10}
              iconSize={10}
              readOnly
              sm
              key={attribute.attribute.type}
            />
          )
        })}
      </Row>
      <RequiredAttributeLevelsModal
        attributes={filteredAttributes ?? []}
        isOpen={isRoleAttrModalOpen}
        handleClose={handleCloseRoleAttrModal}
        handleConfirm={handleCloseRoleAttrModal}
        requiredAttributeLevels={attributeLevels}
        setRequiredAttributeLevels={(
          attributeLevels: RequiredAttributeLevel[],
        ) => {
          handleUpdateAttributeLevels(attributeLevels)
        }}
        title="Attribute Levels"
        subTitle="Select the attribute levels you want to filter by:"
        secondarySubtitle="Note: Attributes that are set to reported or verified will match workers with equal or higher levels. Attributes that are set to unreported will match worker that have strictly not reported them."
        showUnreported={true}
        showDeselect={true}
        isInSearchFilter={true}
      />
    </Col>
  )
}
