import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerSearchParamsRaw } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { format } from 'date-fns'
import { useState } from 'react'
import { Button, Col, Modal } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useUserContext } from '../../../context/user/UserContext'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { extractArrayValues } from '../../../hooks/useWorkers'
import { getErrorMessage } from '../../../utils/errorUtils'
import { formatPhoneNumber } from '../../../utils/stringUtils'
import { MODAL_SIZE } from '../../base/Modal/types'
import { buildBugleSearchParams } from '../hooks/useBugleForm'
import { DEFAULT_MAX_WORKERS } from './ActionsSection'

type ConfirmShiftModalProps = {
  handleClose: () => void
  isOpen: boolean
  bugleType: string
  smsMessage?: string
  pushData?: {
    title: string
    body: string
    screen: string
  }
  targetDate: Date
  parameters: WorkerSearchParamsRaw
  targetShiftId: string
  savedSearchId?: string
  targetCompanyId?: string
  listCount?: number
  maxWorkers?: number
  sentinelNotificationToUserId?: string
}

/**
 * Calculates the potential cost of sending a message to a given number of recipients.
 * The cost is based on the length of the message, the presence of unicode characters,
 * and the number of recipients.
 * Messages with emojis or other unicode characters are charged more.
 *
 * @param {string} message - The message content.
 * @param {number} [listCount] - The estimated number of search results.
 * @param {number} [maxWorkers] - The max number of recipients. Defaults to 1500 if not provided.
 * @returns {number} The potential cost of sending the message.
 */
function calculatePotentialSmsCost(
  message?: string,
  listCount?: number,
  maxWorkers?: number,
) {
  if (!message) {
    return 0
  }
  const containsUnicodeOrEmoji = /[\u0080-\uFFFF]/g.test(message)
  const maxMessageCount =
    listCount ??
    Math.min(
      DEFAULT_MAX_WORKERS,
      maxWorkers || Number.MAX_SAFE_INTEGER,
      listCount || Number.MAX_SAFE_INTEGER,
    )
  const segmentCount = containsUnicodeOrEmoji
    ? Math.ceil(message.length / 70)
    : Math.ceil(message.length / 160)
  const costPerSMS = 0.007376
  return Number((segmentCount * costPerSMS * maxMessageCount).toFixed(2))
}

function buildSMSMessage(message?: string) {
  return message ? `Traba: ${message}` : ''
}

export default function BugleScheduleModal({
  isOpen,
  handleClose,
  smsMessage,
  pushData,
  bugleType,
  targetDate,
  parameters,
  targetShiftId,
  targetCompanyId,
  savedSearchId,
  listCount,
  maxWorkers,
  sentinelNotificationToUserId,
}: ConfirmShiftModalProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const { showError, showSuccess } = useAlert()
  const { trackAnalytics } = useAnalytics()
  const { state } = useUserContext()
  const { internalUsers } = useInternalUsers({
    emails: [state.userProfile?.email ?? ''],
  })

  async function sendTestSms() {
    const phoneNumber = internalUsers?.[0]?.phoneNumber
    if (!phoneNumber) {
      return showError('No phone number found for the current user.')
    }

    try {
      await trabaApi.post(`communication/send-direct-two-way-sms`, {
        message: buildSMSMessage(smsMessage),
        phoneNumber: phoneNumber,
      })
      showSuccess(`Successfully sent a test SMS to ${phoneNumber}`)
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        `Error sending two way sms to ${phoneNumber}`,
      )
    }
    setLoading(false)
  }

  const handleSchedule = async () => {
    try {
      const params = extractArrayValues(
        buildBugleSearchParams(
          parameters,
          targetShiftId,
          savedSearchId,
          targetCompanyId,
        ),
      )
      setLoading(true)
      const url = `communication/bugle/schedule`
      const data = {
        shiftId: targetShiftId,
        customSearch: savedSearchId ? undefined : params,
        scheduledStart: targetDate,
        savedSearchId,
        regionIds: params.regionIds,
        maxWorkers: maxWorkers,
        ...(bugleType === 'SMS' && smsMessage
          ? {
              message: {
                body: buildSMSMessage(smsMessage),
                type: 'SMS',
              },
            }
          : {}),
        ...(bugleType === 'PUSH'
          ? {
              message: {
                body: pushData?.body,
                title: pushData?.title,
                redirectScreen: pushData?.screen,
                type: 'PUSH',
              },
            }
          : {}),
        sentinelNotificationToUserId,
      }
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken Bugle', {
          data,
          sentinelNotificationToUserId,
        })
      }

      await trabaApi.post(url, data)
      setLoading(false)
      showSuccess(
        `Bugle scheduled for ${format(targetDate, 'MMM d yyyy, hh:mm aa')}`,
        'Success!',
      )
      handleClose()
    } catch (error: any) {
      setLoading(false)
      captureSentryError(error)
      showError(JSON.stringify(error), 'Error scheduling Bugle')
    }
  }

  const potentialCost = calculatePotentialSmsCost(
    smsMessage,
    listCount,
    maxWorkers,
  )

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM}
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Schedule Bugle message'}
    >
      <Text variant="h6">Are you sure you want to schedule this message?</Text>

      {bugleType === 'PUSH' && (
        <Text variant="h3" mt={theme.space.xxs}>
          {pushData?.title}
        </Text>
      )}

      <Text variant="body1" mt={theme.space.xxs}>
        "
        {bugleType === 'PUSH'
          ? pushData?.body
          : smsMessage
            ? buildSMSMessage(smsMessage)
            : ''}
        "
      </Text>

      {bugleType === 'PUSH' && (
        <Text variant="body2" mt={theme.space.xxs}>
          Redirect Screen: {pushData?.screen}
        </Text>
      )}

      <Col pt={theme.space.xs}>
        <Text variant="h6">Keep in mind that:</Text>
        <Text variant="body2">
          1. The list of workers that will be notified can be different when the
          blast is sent
        </Text>
        <Text variant="body2">
          2. That some workers who have already been called might also get
          notified
        </Text>

        {bugleType === 'SMS' && (
          <Text variant="body2">
            This message can cost{' '}
            <Text
              variant="brand"
              style={{
                color:
                  potentialCost > 40 ? theme.colors.Red60 : theme.colors.brand,
                fontWeight: 600,
              }}
            >
              approximately ${potentialCost}
            </Text>{' '}
            depending on the number of matches
          </Text>
        )}
      </Col>
      {bugleType === 'SMS' && internalUsers?.[0]?.phoneNumber && (
        <Button
          onClick={sendTestSms}
          loading={loading}
          mb={theme.space.xs}
          variant={ButtonVariant.PURPLEGRADIENT}
        >
          Send a test to my number:{' '}
          {formatPhoneNumber(internalUsers[0].phoneNumber, true)}
        </Button>
      )}
      <Button onClick={handleSchedule} loading={loading}>
        Schedule Bugle for {format(targetDate, 'MMM do h:mma')}
      </Button>
    </Modal>
  )
}
