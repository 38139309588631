import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { ExtendedShift } from '@traba/types'
import { ChangeEvent, useState } from 'react'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Dropdown } from 'src/components/base/Dropdown'
import { IMenuItem } from 'src/components/base/Select/Select'
import { TableRow } from 'src/components/base/Table/DataTable'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import DownloadMenu from 'src/screens/ShiftDetailsScreen/components/DownloadMenu/DownloadMenu'
import { openInNewTab } from 'src/utils/helperUtils'

export type WorkerTableCommandBarProps = {
  shift: ExtendedShift
  workerShifts: WorkerShift[]
  statusCounts: Map<string, number>
  selectedRows?: TableRow[]
  statusOptions: IMenuItem[]
  statusFilters: IMenuItem[]
  setStatusFilters: (value: IMenuItem[]) => void
  handleOpenBulkAdjustModal?: () => void
  handleOpenBulkCancelModal?: () => void
  handleOpenBulkCancelMultiShiftModal?: () => void
  handleOpenBulkRobocallModal?: () => void
  workerSearchString?: string
  setWorkerSearchString?: React.Dispatch<React.SetStateAction<string>>
  allowTableSelect: boolean
  setAllowTableSelect: React.Dispatch<React.SetStateAction<boolean>>
  slim?: boolean
}

export enum SortParameters {
  STATUS = 'Status',
  ALPHA_FIRST_NAME = 'First Name',
  ALPHA_LAST_NAME = 'Last Name',
}

export default function WorkerTableCommandBar({
  shift,
  workerShifts,
  statusCounts,
  selectedRows,
  statusOptions,
  statusFilters,
  setStatusFilters,
  handleOpenBulkAdjustModal,
  handleOpenBulkCancelModal,
  handleOpenBulkCancelMultiShiftModal,
  handleOpenBulkRobocallModal,
  workerSearchString,
  setWorkerSearchString,
  allowTableSelect,
  setAllowTableSelect,
  slim,
}: WorkerTableCommandBarProps) {
  const { refetchLoading, refetchActiveQueries, lastUpdated } =
    useActiveQueries()
  const { showError, showSuccess } = useAlert()
  const [isLoadingWorkerLocations, setIsLoadingWorkerLocations] =
    useState(false)

  async function restartWorkerLocations() {
    const shiftStart = new Date(shift.startTime)
    const now = new Date()
    const diff = shiftStart.getTime() - now.getTime()
    const diffHours = diff / (1000 * 60 * 60)
    const isConfirmed = window.confirm(
      `Do you want to restart tracking for this shift?${
        diffHours > 2
          ? `\nThe shift begins in ${Math.round(
              diffHours,
            )} hours and this will start tracking workers now!`
          : ''
      }`,
    )
    if (!isConfirmed) {
      return
    }
    try {
      setIsLoadingWorkerLocations(true)
      await trabaApi.post(`worker-locations/${shift.shiftId}/start`)
      showSuccess('Worker Locations successfully restarted', 'Success')
    } catch (err: any) {
      showError(err.message || err.response?.message, 'Error')
    } finally {
      setIsLoadingWorkerLocations(false)
    }
  }

  const dropdownOptions = []
  if (handleOpenBulkCancelModal) {
    dropdownOptions.push({
      label: 'Cancel',
      handleClick: handleOpenBulkCancelModal,
      icon: () => <Icon name="cancel" />,
      disabled: selectedRows?.length === 0,
    })
  }
  if (handleOpenBulkCancelMultiShiftModal) {
    dropdownOptions.push({
      label: 'Cancel Multi-Shift',
      handleClick: handleOpenBulkCancelMultiShiftModal,
      icon: () => <Icon name="cancel" />,
      disabled: selectedRows?.length === 0,
    })
  }
  if (handleOpenBulkAdjustModal) {
    dropdownOptions.push({
      label: 'Adjust',
      handleClick: handleOpenBulkAdjustModal,
      icon: () => <Icon name="edit" />,
      disabled: selectedRows?.length === 0,
    })
  }
  if (handleOpenBulkRobocallModal) {
    dropdownOptions.push({
      label: 'Robocall',
      handleClick: handleOpenBulkRobocallModal,
      icon: () => <Icon name="phone" />,
      disabled: selectedRows?.length === 0,
    })
  }

  return (
    <Row flexCol mb={slim ? 0 : theme.space.sm}>
      <Row mt={slim ? 0 : 60} mb={theme.space.xs} justifyBetween>
        <Row alignCenter mx={theme.space.xs}>
          <Text variant="h5" mr={theme.space.xxs}>
            Workers (
            {selectedRows && selectedRows.length > 0
              ? `${selectedRows.length}/`
              : ''}
            {workerShifts.length})
          </Text>
          {Array.from(statusCounts.entries()).map((entry, i) => {
            const [status, count] = entry
            return (
              <Text
                variant="caption"
                mr={theme.space.xxs}
                key={`${status}-${i}`}
              >
                {status} ({count})
              </Text>
            )
          })}
          <Col style={{ minWidth: 200, maxWidth: 400 }}>
            {!slim && (
              <SearchSelect
                multiple
                onlyShowLabel
                options={statusOptions}
                selectedItems={statusFilters}
                handleSelectMultiple={setStatusFilters}
                label="Status"
              />
            )}
          </Col>
        </Row>
        {!slim && (
          <Row alignCenter mr={theme.space.xxs}>
            <Text variant="caption" mr={theme.space.xxs}>
              Last updated: {lastUpdated}
            </Text>
            <Button
              slim
              leftIcon={<Icon name="location" />}
              onClick={restartWorkerLocations}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingWorkerLocations}
              mr={theme.space.xxs}
              tooltipText={
                'Restart HyperTrack location tracking for all workers on the shift'
              }
            >
              Restart Location Tracking
            </Button>
            <Button
              slim
              leftIcon={<Icon name="refresh" />}
              onClick={async () => await refetchActiveQueries()}
              variant={ButtonVariant.OUTLINED}
              loading={refetchLoading}
              mr={theme.space.xxs}
            >
              Refresh
            </Button>

            <Button
              slim
              leftIcon={<Icon name="link" />}
              onClick={() =>
                openInNewTab(
                  `/tools/timesheet?companyId=${shift.companyId}&shifts=${shift.id}`,
                )
              }
              variant={ButtonVariant.OUTLINED}
              style={{ marginRight: theme.space.xxs }}
            >
              Open in Timesheet Automation
            </Button>
            <DownloadMenu shift={shift} />
            {(!!handleOpenBulkCancelModal ||
              !!handleOpenBulkCancelMultiShiftModal ||
              !!handleOpenBulkAdjustModal ||
              !!handleOpenBulkRobocallModal) && (
              <Dropdown
                buttonVariant={ButtonVariant.OUTLINED}
                buttonStyling={{
                  padding: '11px 12px 6px',
                  marginLeft: 8,
                  marginBottom: -8,
                  marginTop: -8,
                }}
                dropdownOptions={dropdownOptions}
                orientation={'left'}
              />
            )}
          </Row>
        )}
      </Row>
      {!slim && setWorkerSearchString && (
        <Row alignCenter mx={theme.space.xs}>
          <Button
            onClick={() => setAllowTableSelect(!allowTableSelect)}
            variant={ButtonVariant.OUTLINED}
          >
            {allowTableSelect ? 'Selecting' : 'Select'} Workers
            {!!allowTableSelect && '...'}
          </Button>
          <Row ml={theme.space.xs}>
            <Input
              label="Worker Search"
              value={workerSearchString}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setWorkerSearchString(ev.target.value)
              }
              style={{ height: 40 }}
              width="260px"
            />
          </Row>
        </Row>
      )}
    </Row>
  )
}
