import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WorkerShiftEligibility,
  ActionableRequirement,
  IMenuItem,
  AcceptShiftWaiver,
} from '@traba/types'
import { useMemo, useState, useCallback } from 'react'
import { Input, Row } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import RadioGroup from 'src/components/base/RadioGroup'
import { eligibilityToActionableRequirementMap } from 'src/utils/workerEligibilityUtils'

const WAIVER_W9_REASONS: IMenuItem[] = [
  { label: 'Last Minute Shift fill', value: 'lastMinuteShiftFill' },
  { label: 'Other, please specify', value: 'other' },
]

const WAIVER_BGC_REASONS: IMenuItem[] = [
  { label: 'Last Minute Shift fill', value: 'lastMinuteShiftFill' },
  { label: "Company doesn't require BGC", value: 'companyDoesntRequireBgc' },
  {
    label: 'Checkr still processing',
    value: 'checkrStillProcessing',
  },
  {
    label: 'Retroactive waiver, worker already completed this shift',
    value: 'retroactive',
  },
  { label: 'Other, please specify', value: 'other' },
]

const getNumberOfShiftsOptions = (requirement: ActionableRequirement) => [
  {
    label:
      requirement === ActionableRequirement.W9_FORM
        ? 'Waive Stripe/W9 for the first shift'
        : 'Waive Background Check for the first shift',
    value: '1',
  },
  {
    label:
      requirement === ActionableRequirement.W9_FORM
        ? 'Waive Stripe/W9 for the first 3 shifts'
        : 'Waive Background Check for the first 3 shifts',
    value: '3',
  },
]

const getSelectedReason = (reasons: IMenuItem[], requestedReason?: string) =>
  reasons.find((r) => r.value === (requestedReason || ''))

type WaiverSectionProps = {
  requirement: ActionableRequirement
  title: string
  description: string
  reasons: IMenuItem[]
  onWaiverChange: (waiver: AcceptShiftWaiver) => void
  currentWaiver?: AcceptShiftWaiver
  allowMultipleShiftWaiver?: boolean
}

const WaiverSection = ({
  requirement,
  title,
  description,
  reasons,
  onWaiverChange,
  currentWaiver,
  allowMultipleShiftWaiver = false,
}: WaiverSectionProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    currentWaiver?.requestedReason || '',
  )
  const [customReason, setCustomReason] = useState(
    currentWaiver?.requestedReason || '',
  )
  const [shiftsToWaiveFor, setShiftsToWaiveFor] = useState(
    currentWaiver?.shiftsToWaiveFor || 1,
  )

  const handleNumberOfShiftsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseInt(e.target.value)
      setShiftsToWaiveFor(newValue)
      onWaiverChange({
        requirement,
        shiftsToWaiveFor: newValue,
        requestedReason:
          selectedOption === 'other' ? customReason : selectedOption,
      })
    },
    [requirement, selectedOption, customReason, onWaiverChange],
  )

  const handleSelect = useCallback(
    (item: IMenuItem | undefined) => {
      if (item) {
        setSelectedOption(item.value)
        if (item.value === 'other') {
          setCustomReason('')
          onWaiverChange({
            requirement,
            shiftsToWaiveFor,
            requestedReason: '',
          })
        } else {
          onWaiverChange({
            requirement,
            shiftsToWaiveFor,
            requestedReason: item.value,
          })
        }
      }
    },
    [requirement, shiftsToWaiveFor, onWaiverChange],
  )

  const handleCustomReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newReason = e.target.value
      setCustomReason(newReason)
      onWaiverChange({
        requirement,
        shiftsToWaiveFor,
        requestedReason: newReason.trim(),
      })
    },
    [requirement, shiftsToWaiveFor, onWaiverChange],
  )

  return (
    <Row flexCol style={{ width: '100%', marginTop: theme.space.xs }}>
      <Text variant="h5" mb={theme.space.xxs}>
        {title}
      </Text>
      <Text variant="body2" mb={theme.space.sm}>
        {description}. After this waive period, the worker will be removed if
        they do not complete the requirements
        {requirement === ActionableRequirement.BACKGROUND_CHECK &&
          ' or are permanently waived to work with this company'}
        .
      </Text>

      {allowMultipleShiftWaiver && (
        <RadioGroup
          options={getNumberOfShiftsOptions(requirement)}
          value={shiftsToWaiveFor.toString()}
          onChange={handleNumberOfShiftsChange}
          row={true}
          style={{ marginBottom: theme.space.med, marginTop: -theme.space.xs }}
          fontColor={theme.colors.Grey60}
          fontSize={14}
        />
      )}

      <SearchSelect
        options={reasons}
        selectItem={getSelectedReason(reasons, selectedOption)}
        handleSelect={handleSelect}
        label="Reason for Waiver"
        required
        style={{ width: '40%' }}
      />
      {selectedOption === 'other' && (
        <Row flexCol mt={theme.space.xs}>
          <Text variant="body2" mb={theme.space.xxs}>
            Please provide a reason for waiving the {title.toLowerCase()}
          </Text>
          <Input
            type="textarea"
            value={customReason}
            placeholder="Reason for Waiver"
            style={{ width: '40%' }}
            onChange={handleCustomReasonChange}
            required
          />
        </Row>
      )}
    </Row>
  )
}

export type WaiverOptionsProps = {
  eligibilityData?: WorkerShiftEligibility[]
  waivers: AcceptShiftWaiver[]
  setWaivers: (waivers: AcceptShiftWaiver[]) => void
  onWaiverActionsComplete: (completed: boolean) => void
  allowMultipleShiftWaiver?: boolean
}

export default function AcceptShiftWaiverOptions({
  eligibilityData,
  waivers,
  setWaivers,
  onWaiverActionsComplete,
  allowMultipleShiftWaiver = false,
}: WaiverOptionsProps) {
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  // Get required waivers based on eligibility data
  const requiredWaivers = useMemo(() => {
    if (!eligibilityData) {
      return []
    }

    return eligibilityData.reduce<ActionableRequirement[]>(
      (acc, eligibility) => {
        if (!eligibility.eligibilityCriteria.workerPassesOpsBGCRequirement) {
          acc.push(
            eligibilityToActionableRequirementMap.workerPassesOpsBGCRequirement,
          )
        }
        if (!eligibility.eligibilityCriteria.workerMeetsW9Requirement) {
          acc.push(
            eligibilityToActionableRequirementMap.workerMeetsW9Requirement,
          )
        }
        return acc
      },
      [],
    )
  }, [eligibilityData])

  const checkWaiversComplete = useCallback(() => {
    if (!requiredWaivers.length) {
      return false
    }

    const hasAllRequiredWaivers = requiredWaivers.every((req) =>
      waivers.some(
        (waiver) =>
          waiver.requirement === req && waiver.requestedReason.trim() !== '',
      ),
    )

    return hasAllRequiredWaivers
  }, [requiredWaivers, waivers])

  const updateCompletionStatus = useCallback(
    (acknowledged: boolean) => {
      const waiversComplete = checkWaiversComplete()
      onWaiverActionsComplete(waiversComplete && acknowledged)
    },
    [checkWaiversComplete, onWaiverActionsComplete],
  )

  const handleWaiverChange = useCallback(
    (waiver: AcceptShiftWaiver) => {
      const newWaivers = waivers.filter(
        (w) => w.requirement !== waiver.requirement,
      )
      if (waiver.requestedReason) {
        newWaivers.push(waiver)
      }
      setWaivers(newWaivers)
      updateCompletionStatus(isAcknowledged)
    },
    [waivers, setWaivers, isAcknowledged, updateCompletionStatus],
  )

  const handleAcknowledgementChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newAcknowledged = e.target.checked
      setIsAcknowledged(newAcknowledged)
      updateCompletionStatus(newAcknowledged)
    },
    [updateCompletionStatus],
  )

  const needsW9Waiver = requiredWaivers.includes(ActionableRequirement.W9_FORM)
  const needsBGCWaiver = requiredWaivers.includes(
    ActionableRequirement.BACKGROUND_CHECK,
  )

  return (
    <Row flexCol alignStart mt={theme.space.xs} mb={theme.space.med}>
      <Text variant="h3" style={{ fontWeight: 'bold' }}>
        Waivers
      </Text>
      <Row
        mt={theme.space.sm}
        style={{
          width: '100%',
          backgroundColor: theme.colors.Grey10,
          padding: theme.space.xs,
        }}
      >
        <Text
          variant="h5"
          style={{ fontWeight: 'bold', color: theme.colors.Yellow80 }}
        >
          Disclaimer: The waiver applies to the first shift(s) only. The worker
          will be removed from future shifts at this company, if their BGC or
          W9/Stripe setup is not completed.
        </Text>
      </Row>

      <Row flexCol alignStart style={{ width: '100%' }}>
        {/* Waive Stripe/W9 Section */}
        {needsW9Waiver && (
          <WaiverSection
            requirement={ActionableRequirement.W9_FORM}
            title="Temporarily Waive Stripe/W9"
            description="Allow these workers to work this shift without completing their Stripe/W9"
            reasons={WAIVER_W9_REASONS}
            onWaiverChange={handleWaiverChange}
            currentWaiver={waivers.find(
              (w) => w.requirement === ActionableRequirement.W9_FORM,
            )}
            allowMultipleShiftWaiver={allowMultipleShiftWaiver}
          />
        )}

        {/* Waive Background Check Section */}
        {needsBGCWaiver && (
          <WaiverSection
            requirement={ActionableRequirement.BACKGROUND_CHECK}
            title="Temporarily Waive Background Check"
            description="Allow these workers to work this shift without completing their background check"
            reasons={WAIVER_BGC_REASONS}
            onWaiverChange={handleWaiverChange}
            currentWaiver={waivers.find(
              (w) => w.requirement === ActionableRequirement.BACKGROUND_CHECK,
            )}
            allowMultipleShiftWaiver={allowMultipleShiftWaiver}
          />
        )}

        {/* Waiver Actions Completion Check */}
        {(needsW9Waiver || needsBGCWaiver) && (
          <Row
            mt={theme.space.sm}
            style={{
              width: '100%',
              backgroundColor: theme.colors.Grey10,
              padding: theme.space.xs,
            }}
          >
            <Checkbox
              label="I understand that I am responsible for waiving these important requirements for all of the workers missing them."
              textvariant="h6"
              checked={isAcknowledged}
              onChange={handleAcknowledgementChange}
            />
          </Row>
        )}
      </Row>
    </Row>
  )
}
