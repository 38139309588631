export enum OpsActionType {
  I9_REQUIRES_REVIEW = 'I9_REQUIRES_REVIEW',
  I9_NOT_SUBMITTED = 'I9_NOT_SUBMITTED',
  I9_NUDGE = 'I9_NUDGE',
  I9_CHANGE_REQUESTED = 'I9_CHANGE_REQUESTED',
  I9_AUTHORIZED_REPRESENTATIVE_NUDGE = 'I9_AUTHORIZED_REPRESENTATIVE_NUDGE',
  E_VERIFY_SUBMISSION_RETURNED_WITH_PROBLEMS = 'E_VERIFY_SUBMISSION_RETURNED_WITH_PROBLEMS',
  E_VERIFY_FAILED = 'E_VERIFY_FAILED',
  WORKER_FAILED_TO_RESPOND_TO_E_VERIFY_ISSUES = 'WORKER_FAILED_TO_RESPOND_TO_E_VERIFY_ISSUES',
  WORKER_REQUESTED_RESIGNATION = 'WORKER_REQUESTED_RESIGNATION',
  POST_ADVERSE_BACKGROUND_CHECK = 'POST_ADVERSE_BACKGROUND_CHECK',
}

export enum OpsActionCategory {
  I9 = 'I9',
  E_VERIFY = 'E_VERIFY',
  BGC = 'BGC',
  TERMINATION = 'TERMINATION',
}

export enum ActionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export type ActionItem = {
  id: string
  actionStatus: ActionStatus
  actionType: OpsActionType
  assignee?: string
  workerId: string
  zealEmployeeId?: string
}

export type WorkerWithActionItems = {
  id: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  actionItems: ActionItem[]
  firstShiftStartTime?: Date
  firstShiftId?: string
  firstShiftCompanyName?: string
  firstShiftCompanyId?: string
}
