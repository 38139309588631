import { Badge, BadgeVariant } from '@traba/react-components'
import {
  WorkerVettingCampaignStatus,
  WorkerVettingSessionStatus,
} from '@traba/types'
import { WorkerVettingPhoneCallStatus } from 'src/hooks/useWorkerVetting'
import { Badge as BadgeDEPRECATED } from './base'

type PhoneStatusConfig = {
  [key in WorkerVettingPhoneCallStatus]: {
    variant: BadgeVariant
    title: string
  }
}

const PHONE_STATUS_CONFIG: PhoneStatusConfig = {
  [WorkerVettingPhoneCallStatus.CALL_COMPLETE]: {
    variant: 'success',
    title: 'Call Complete',
  },
  [WorkerVettingPhoneCallStatus.VETTING_COMPLETE]: {
    variant: 'success',
    title: 'Vetting Complete',
  },
  [WorkerVettingPhoneCallStatus.INITIATED]: {
    variant: 'warning',
    title: 'Inbound In-Progress',
  },
  [WorkerVettingPhoneCallStatus.INITIATED_OUTBOUND]: {
    variant: 'warning',
    title: 'Outbound In-Progress',
  },
  [WorkerVettingPhoneCallStatus.MISSED_OUTBOUND]: {
    variant: 'danger',
    title: 'Missed Outbound',
  },
} satisfies PhoneStatusConfig

type VettingSessionStatusBadgeProps = {
  status: WorkerVettingSessionStatus
}

type VettingSessionPhoneCallsStatusBadgeProps = {
  status?: WorkerVettingPhoneCallStatus
}

const statusToBadgeVariantDict: Record<WorkerVettingSessionStatus, string> = {
  [WorkerVettingSessionStatus.active]: 'warning',
  [WorkerVettingSessionStatus.canceled]: 'opaqueRed',
  [WorkerVettingSessionStatus.complete]: 'success',
  [WorkerVettingSessionStatus.exited]: 'info',
  [WorkerVettingSessionStatus.stale]: 'orange',
}

export function VettingSessionStatusBadge({
  status,
}: VettingSessionStatusBadgeProps) {
  return (
    <BadgeDEPRECATED
      title={status}
      variant={statusToBadgeVariantDict[status]}
    />
  )
}

export function VettingSessionPhoneCallsStatusBadge({
  status,
}: VettingSessionPhoneCallsStatusBadgeProps) {
  if (status === undefined) {
    return <Badge iconName="addChat" variant="darkRed" title="No Calls" />
  }
  const config = PHONE_STATUS_CONFIG[status] ?? 'info'
  return (
    <Badge iconName="addChat" variant={config.variant} title={config.title} />
  )
}

type VettingCampaignStatusBadgeProps = {
  status: WorkerVettingCampaignStatus
}

const campaignStatusToBadgeVariantDict: Record<
  WorkerVettingCampaignStatus,
  string
> = {
  [WorkerVettingCampaignStatus.active]: 'warning',
  [WorkerVettingCampaignStatus.canceled]: 'opaqueRed',
  [WorkerVettingCampaignStatus.completed]: 'success',
}

export function VettingCampaignStatusBadge({
  status,
}: VettingCampaignStatusBadgeProps) {
  return (
    <BadgeDEPRECATED
      title={status}
      variant={campaignStatusToBadgeVariantDict[status] ?? 'info'}
      style={{ height: 28 }}
    />
  )
}
