import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { RecordStatus, User } from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const COMPANY_SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY =
  'companyShiftsSupervisorsForLocation'

interface GetShiftsSupervisorsParams {
  companyId: string | undefined
  locationId: string | undefined
  recordStatus?: RecordStatus
  minShiftStartTime?: string
}

const getCompanyShiftsSupervisorsForLocation = async ({
  companyId,
  ...queryParams
}: GetShiftsSupervisorsParams): Promise<User[] | undefined> => {
  if (!queryParams.locationId || !companyId) {
    return undefined
  }

  try {
    const response = await trabaApi.get(
      `/companies/${companyId}/shifts-supervisors`,
      {
        params: queryParams,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action: `Get Supervisors With Future Shifts For Location of Company`,
      },
      extra: { companyId, locationId: queryParams.locationId },
    })
    throw error
  }
}

export const useCompanyShiftsSupervisorsForLocation = ({
  companyId,
  locationId,
  recordStatus,
  minShiftStartTime,
}: GetShiftsSupervisorsParams) => {
  const {
    isLoading,
    isError,
    data: supervisorsForFutureShiftAtLocation = [],
    error,
    isFetched,
    refetch,
  } = useQuery<User[] | undefined, Error>({
    queryKey: [
      COMPANY_SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY,
      companyId,
      locationId,
      minShiftStartTime,
      recordStatus,
    ],
    queryFn: () =>
      getCompanyShiftsSupervisorsForLocation({
        companyId,
        locationId,
        recordStatus,
        minShiftStartTime,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId && !!locationId,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    supervisorsForFutureShiftAtLocation,
    refetch,
  }
}
