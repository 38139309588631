import { Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { IMenuItem } from '@traba/types'
import { useMobile } from 'src/hooks/useMobile'

export const InfoTable = ({
  cols = 4,
  title,
  data = [],
}: {
  cols?: number
  title: string
  data: IMenuItem[]
}) => {
  const { isMobile } = useMobile()
  const effectiveCols = isMobile ? 1 : cols
  const dataSize = data.length
  const rows = Math.ceil(dataSize / effectiveCols)

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
      gap={theme.space.xxs}
      width={isMobile ? '100%' : `60%`}
    >
      <Text variant="h5">{title}</Text>
      {[...Array(rows)].map((_, rowIndex) => (
        <Row justifyBetween alignCenter key={`InfoTable-${rowIndex}`}>
          {[...Array(effectiveCols)].map((_, colIndex) =>
            dataSize > rowIndex * effectiveCols + colIndex ? (
              <Col
                gap={theme.space.xs}
                key={`InfoTable-${rowIndex}-${colIndex}`}
                width={`${100 / effectiveCols - 2}%`}
              >
                <Row
                  justifyBetween
                  style={{
                    borderBottom: `2px solid ${theme.colors.Grey}`,
                  }}
                  alignCenter
                >
                  <Text variant="body1" color={theme.colors.MidnightBlue}>
                    {data[rowIndex * effectiveCols + colIndex].label}
                  </Text>
                  <Text variant="body2" color={theme.colors.Grey60}>
                    {data[rowIndex * effectiveCols + colIndex].value}
                  </Text>
                </Row>
              </Col>
            ) : null,
          )}
        </Row>
      ))}
    </Col>
  )
}
