import { Col, LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeSkillLevels,
  OpsAttributeLevel,
  RoleAttributeCategory,
} from '@traba/types'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { AttributeLevelsSection } from 'src/components/RequiredAttributeLevelsSections/AttributeLevelsSection'
import { useAttributes } from 'src/hooks/useAttributes'
import {
  useWorkerProfile,
  useWorkerProfileMutations,
} from 'src/hooks/useWorkerProfile'
import {
  ATTRIBUTE_LEVEL_SECTIONS,
  filterNonOpsLevelingLanguages,
  isRoleAttributeTypeStarRating,
} from 'src/utils/attributeUtils'
import { getWorkerProfileValueForOpsLevel } from 'src/utils/attributeUtils'

interface QualificationsTabProps {
  workerId: string
}

export function QualificationsTab(props: QualificationsTabProps) {
  const { workerId } = props
  const {
    attributes: roleAttributes,
    isLoading: isLoadingAttributes,
    isError: isErrorFetchingAttributes,
  } = useAttributes()
  const {
    workerProfileAttributes,
    isLoading: isLoadingWorkerAttributes,
    isError: isErrorFetchingWorkerAttributes,
  } = useWorkerProfile(workerId)

  const attributes = useMemo(
    () => roleAttributes.filter(filterNonOpsLevelingLanguages),
    [roleAttributes],
  )

  const { updateWorkerProfileOpsLevelMutation } = useWorkerProfileMutations()
  const handleUpdateAttributeLevel = useCallback(
    (
      attrType: string,
      category: RoleAttributeCategory,
      level: OpsAttributeLevel,
    ) => {
      updateWorkerProfileOpsLevelMutation.mutate({
        workerId,
        items: [
          {
            category,
            opsLevel: level,
            profileField: attrType,
            value: getWorkerProfileValueForOpsLevel(level),
          },
        ],
      })
    },
    [updateWorkerProfileOpsLevelMutation, workerId],
  )

  const filteredAndSortedAttributesBySection = useMemo(() => {
    return ATTRIBUTE_LEVEL_SECTIONS.map((section) => {
      const filteredAttributes = attributes.filter(
        (attr) =>
          section.categories.includes(attr.category) &&
          attr.allowOpsLeveling &&
          !isRoleAttributeTypeStarRating(attr) &&
          (!section.filterFn || section.filterFn(attr)),
      )
      const sortedAttributes = filteredAttributes.sort((a, b) => {
        if (section.sortFn) {
          return section.sortFn(a, b)
        }
        const indexA = section.categories.indexOf(a.category)
        const indexB = section.categories.indexOf(b.category)
        return indexA - indexB
      })

      return {
        ...section,
        filteredAndSortedAttributes: sortedAttributes,
      }
    })
  }, [attributes])

  const attributeLevelMap = useMemo(() => {
    if (!workerProfileAttributes) {
      return {}
    }

    return Object.fromEntries(
      workerProfileAttributes.map((profile) => [
        profile.profileField,
        profile.opsLevel ?? AttributeSkillLevels.UNREPORTED,
      ]),
    ) as Record<string, OpsAttributeLevel>
  }, [workerProfileAttributes])

  if (isLoadingWorkerAttributes || isLoadingAttributes) {
    return <LoadingSpinner />
  }

  if (
    isErrorFetchingWorkerAttributes ||
    isErrorFetchingAttributes ||
    !workerProfileAttributes?.length
  ) {
    return (
      <Text variant="error">
        {'There was an error fetching worker attributes.'}
      </Text>
    )
  }

  return (
    <Col gap={theme.space.xs} pb={theme.space.xl}>
      {filteredAndSortedAttributesBySection.map((section) => (
        <Row gap={theme.space.xs} key={section.title}>
          <AttributeLevelsSection
            key={section.title}
            sectionTitle={section.title}
            titleTooltip={section.titleTooltip}
            attributes={section.filteredAndSortedAttributes}
            attributeLevelMap={attributeLevelMap}
            handleSelectAttributeLevel={handleUpdateAttributeLevel}
            showRevoke={true}
            showUnreported={true}
          />
        </Row>
      ))}
    </Col>
  )
}
