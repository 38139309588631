import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import {
  CompanyCategory,
  RoleAttribute,
  RoleAttributeStatus,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

const ATTRIBUTES_QUERY_KEY = 'attributesQueryKey'
const TRAITS_QUERY_KEY = 'traitsQueryKey'

async function getAttributes(
  companyCategory?: CompanyCategory,
): Promise<RoleAttribute[] | undefined> {
  try {
    const res = await trabaApi.get(
      `/role-attributes?locale=en${
        companyCategory ? `&companyCategory=${companyCategory}` : ''
      }`,
    )
    return res.data
  } catch (error) {
    console.error('useAttributes -> getAttributes() ERROR', error)
    captureSentryError(error, {
      tags: { action: 'useAttributes -> getAttributes() ERROR' },
    })
    throw error
  }
}

async function getTraitAttributes(): Promise<RoleAttribute[] | undefined> {
  try {
    const res = await trabaApi.get('/role-attributes/traits?locale=en')
    return res.data
  } catch (error) {
    console.error('useAttributes -> getTraitAttributes() ERROR', error)
    captureSentryError(error, {
      tags: { action: 'useAttributes -> getTraitAttributes() ERROR' },
    })
    throw error
  }
}

export function useAttributes(
  statusFilter?: RoleAttributeStatus,
  companyCategory?: CompanyCategory,
) {
  const {
    isLoading,
    isError,
    data: nonTraitAttributes,
    error,
    isFetched,
    refetch,
  } = useQuery<RoleAttribute[] | undefined, Error>({
    queryKey: [ATTRIBUTES_QUERY_KEY, companyCategory],
    queryFn: () => getAttributes(companyCategory),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const {
    isLoading: isLoadingTraitAttributes,
    isError: isTraitAttributesError,
    data: traitAttributes,
    error: traitAttributesError,
    isFetched: isFetchedTraitAttributes,
    refetch: refetchTraits,
  } = useQuery<RoleAttribute[] | undefined, Error>({
    queryKey: [TRAITS_QUERY_KEY],
    queryFn: () => getTraitAttributes(),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const attributes = [...(nonTraitAttributes || []), ...(traitAttributes || [])]

  return {
    isLoading: isLoading || isLoadingTraitAttributes,
    isError: isError || isTraitAttributesError,
    attributes: statusFilter
      ? attributes?.filter((a) => a.status === statusFilter)
      : attributes,
    error: error || traitAttributesError,
    isFetched: isFetched && isFetchedTraitAttributes,
    refetch,
    refetchTraits,
  }
}
