import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ConnectionType } from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useCompanies } from 'src/hooks/useCompanies'
import { ConnectionContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'

type WorkerConnectionModalProps = {
  workerId: string
  handleClose: () => void
  isOpen: boolean
  defaultCompany?: string
  defaultConnectionType?: ConnectionType
}

export const WorkerConnectionModal = (props: WorkerConnectionModalProps) => {
  const {
    isOpen,
    handleClose,
    workerId,
    defaultCompany,
    defaultConnectionType,
  } = props
  const { companies } = useCompanies({ isApproved: true })

  const companyOptions: IMenuItem[] = useMemo(
    () => [
      {
        label: '-',
        value: '',
      },
      ...(companies
        ?.map((company) => ({ label: company.employerName, value: company.id }))
        .filter((c) => !!c.value) || []),
    ],
    [companies],
  )

  const [company, setCompany] = useState<IMenuItem | undefined>(
    companyOptions[0],
  )

  useEffect(() => {
    const dc = companyOptions.find((c) => c.value === defaultCompany)
    if (!dc) {
      return
    }
    setCompany(dc)
  }, [defaultCompany, companyOptions])

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={'Create Connection for Worker'}
    >
      <Row flexCol fullHeight fullWidth>
        <Text variant="h5" style={{ marginRight: theme.space.xs }}>
          Select a company to make the connection to
        </Text>
        <Row alignCenter mb={theme.space.sm} mt={theme.space.xs}>
          <SearchSelect
            options={companyOptions}
            selectItem={company}
            handleSelect={setCompany}
            label="Business"
            width={260}
          />
        </Row>
        {!!company?.value && (
          <ConnectionContent
            companyId={company.value}
            handleClose={handleClose}
            workerId={workerId}
            defaultConnectionType={defaultConnectionType}
          />
        )}
      </Row>
    </Modal>
  )
}
