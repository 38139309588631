import { ShiftPayType } from './shifts'

export enum WorkerTimecardClockInAdjustmentReason {
  CLOCKED_IN_LATE_BY_MISTAKE = 'CLOCKED_IN_LATE_BY_MISTAKE',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  WRONG_CLOCK_IN_CODE = 'WRONG_CLOCK_IN_CODE',
  FORGOT_TO_CLOCK_IN = 'FORGOT_TO_CLOCK_IN',
  OTHER = 'OTHER',
}

export enum WorkerTimecardClockOutAdjustmentReason {
  CLOCKED_OUT_EARLY_BY_MISTAKE = 'CLOCKED_OUT_EARLY_BY_MISTAKE',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  WRONG_CLOCK_OUT_CODE = 'WRONG_CLOCK_OUT_CODE',
  FORGOT_TO_CLOCK_OUT = 'FORGOT_TO_CLOCK_OUT',
  OTHER = 'OTHER',
}

export enum WorkerTimecardBreaksAdjustmentReason {
  TOOK_A_LONGER_BREAK = 'TOOK_A_LONGER_BREAK',
  FORGOT_TO_CLOCK_IN_AFTER_BREAK = 'FORGOT_TO_CLOCK_IN_AFTER_BREAK',
  FORGOT_TO_CLOCK_OUT_FOR_BREAK = 'FORGOT_TO_CLOCK_OUT_FOR_BREAK',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  OTHER = 'OTHER',
}

export enum WorkerTimecardStatus {
  PENDING_WORKER = 'PENDING_WORKER',
  WORKER_DISPUTED = 'WORKER_DISPUTED',
  FINALIZED = 'FINALIZED',
}

export enum WorkerTimecardFinalizedReason {
  WORKER_APPROVED = 'WORKER_APPROVED',
  WORKER_NO_ACTION = 'WORKER_NO_ACTION',
  OPS_REVIEWED = 'OPS_REVIEWED',
}

export enum WorkerShiftAdjustmentSource {
  OPS = 'OPS',
  BUSINESS = 'BUSINESS',
}

export type WorkerTimecard = {
  id: string
  createdAt: Date
  updatedAt: Date
  status: WorkerTimecardStatus
  finalizedReason: WorkerTimecardFinalizedReason
  workerId: string
  shiftId: string
  workerShiftId: string

  workerShift: {
    employerName: string
    roleName: string
    payRate: number
    payType: ShiftPayType
    startTime: Date
    timeZone: string
  }

  workerReviewDeadline: Date
  opsReviewDeadline: Date

  workerClockInTime?: Date
  workerClockOutTime?: Date
  workerTotalBreakDurationSeconds?: number
  workerClockInAdjustmentReason?: WorkerTimecardClockInAdjustmentReason
  workerClockInAdjustmentOtherReason?: string
  workerClockOutAdjustmentReason?: WorkerTimecardClockOutAdjustmentReason
  workerClockOutAdjustmentOtherReason?: string
  workerBreaksAdjustmentReason?: WorkerTimecardBreaksAdjustmentReason
  workerBreaksAdjustmentOtherReason?: string

  bizClockInTime: Date
  bizClockOutTime: Date
  bizTotalBreakDuration?: number
  bizAdjustmentReason?: string
  bizAdjustmentSource?: WorkerShiftAdjustmentSource
  bizTotalBreakDurationSeconds?: number

  opsClockInTime?: Date
  opsClockOutTime?: Date
  opsTotalBreakDurationSeconds?: number
  opsReason?: WorkerTimecardOpsDecisionReason
  opsOtherReason?: string

  worker: {
    firstName: string
    lastName: string
    regionId: string
  }
}

export type WorkerTimecardQueryResponse = {
  timecards: WorkerTimecard[]
}

export enum WorkerTimecardOpsDecisionReason {
  BUSINESS_AS_SOURCE_OF_TRUTH = 'BUSINESS_AS_SOURCE_OF_TRUTH',
  ENOUGH_EVIDENCE_BUSINESS = 'ENOUGH_EVIDENCE_BUSINESS',
  ENOUGH_EVIDENCE_WORKER = 'ENOUGH_EVIDENCE_WORKER',
  ENOUGH_EVIDENCE_OPS = 'ENOUGH_EVIDENCE_OPS',
  OTHER = 'OTHER',
}

export type FinalizeTimecardRequest = {
  timecardId: string
  clockInTime: Date
  clockOutTime: Date
  totalBreakDurationSeconds: number
  reason: WorkerTimecardOpsDecisionReason
  otherReason?: string
}
