import { useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { makePlural } from '@traba/string-utils'
import { captureSentryError } from '@traba/utils'
import { useMutation } from 'react-query'
import { workersOnWatchlistQueryKey } from './useWorkersOnWatchlist'

async function hideWorkersFromWatchlistRequest(
  workerIds: string[],
  permanent: boolean,
): Promise<void> {
  try {
    await trabaApi.post(`/workers/hide-from-watchlist`, {
      workerIds,
      permanent,
    })
  } catch (error) {
    console.error('Failed to hide workers:', error)
    captureSentryError(error)
    throw error
  }
}

export function useHideFromWatchlist() {
  const queryClient = useQueryClient()
  const { showError, showSuccess } = useAlert()

  const hideFromWatchlistMutation = useMutation({
    mutationFn: ({
      workerIds,
      permanent,
    }: {
      workerIds: string[]
      permanent: boolean
    }) => hideWorkersFromWatchlistRequest(workerIds, permanent),
    onError: (_, { workerIds }) => {
      showError(
        `Failed to hide ${workerIds.length} worker${makePlural(workerIds)}`,
      )
    },
    onSuccess: async (_, { workerIds, permanent }) => {
      showSuccess(
        `Successfully ${permanent ? 'marked as done' : 'snoozed'} ${workerIds.length} worker${
          workerIds.length > 1 ? 's' : ''
        }`,
      )
      await queryClient.invalidateQueries({
        queryKey: [workersOnWatchlistQueryKey],
      })
    },
  })

  return {
    hideFromWatchlist: hideFromWatchlistMutation.mutateAsync,
    hideFromWatchlistLoading: hideFromWatchlistMutation.isLoading,
  }
}
