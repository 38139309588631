import { Tooltip } from '@mui/material'
import { Dialog } from '@traba/react-components'
import { Button, ButtonVariant, LoadingSpinner } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Col, Row } from 'src/components/base'

type ArticulateGMPBadgeProps = {
  hasActiveArticulate360GmpAction: boolean
  handleArticulate360GmpActionUpdate: (hasActiveAction: boolean) => void
  isLoading: boolean
}

export function ArticulateGMPButton({
  hasActiveArticulate360GmpAction,
  handleArticulate360GmpActionUpdate,
  isLoading,
}: ArticulateGMPBadgeProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleConfirm = () => {
    handleArticulate360GmpActionUpdate(hasActiveArticulate360GmpAction)
    setIsModalOpen(false)
  }

  return isLoading ? (
    <LoadingSpinner style={{ height: 25, width: 25 }} />
  ) : (
    <>
      <Tooltip
        title={
          hasActiveArticulate360GmpAction
            ? 'This will unblock the worker from the GMP certification requirement.'
            : 'This will lock the worker from working until they have completed the certification.'
        }
        placement="left"
      >
        <Button
          variant={
            hasActiveArticulate360GmpAction
              ? ButtonVariant.DASHED
              : ButtonVariant.REDOUTLINED
          }
          onClick={() => setIsModalOpen(true)}
        >
          {hasActiveArticulate360GmpAction
            ? 'Dismiss Articulate 360 GMP Request'
            : 'Push Articulate 360 GMP Request'}
        </Button>
      </Tooltip>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        dialogTitle={
          hasActiveArticulate360GmpAction
            ? 'Dismiss Articulate 360 GMP Request'
            : 'Push Articulate 360 GMP Request'
        }
        onConfirm={handleConfirm}
        onConfirmCTA="Confirm"
        confirmButtonVariant={
          hasActiveArticulate360GmpAction
            ? ButtonVariant.DASHED
            : ButtonVariant.REDOUTLINED
        }
      >
        <Col style={{ padding: theme.space.xs }}>
          <Row flexCol>
            <Text variant="body1">
              {hasActiveArticulate360GmpAction
                ? 'Are you sure you want to remove the GMP certification requirement? This will unblock the worker.'
                : "Are you sure you want to request GMP certification? This will lock the worker's phone until they complete the certification."}
            </Text>
          </Row>
        </Col>
      </Dialog>
    </>
  )
}
