import { Address, ShiftRequest } from '@traba/types'

export function addressSearchText(address: Address): string {
  const { city, postalCode, state, street1, street2 } = address
  return [street1, street2, city, state, postalCode].filter(Boolean).join(' ')
}

export function shiftRequestSearchText(shiftRequest: ShiftRequest): string {
  const {
    shortLocation,
    address,
    shiftRole,
    supervisorFirstName,
    supervisorLastName,
  } = shiftRequest
  const locationText = address ? addressSearchText(address) : ''
  return [
    shortLocation,
    locationText,
    shiftRole,
    supervisorFirstName,
    supervisorLastName,
  ]
    .filter(Boolean)
    .join(' ')
}
