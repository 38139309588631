import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Step from '@mui/material/Step/Step'
import StepButton from '@mui/material/StepButton/StepButton'
import Stepper from '@mui/material/Stepper/Stepper'
import ToggleButton from '@mui/material/ToggleButton'
import { Text, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CompanyResponse,
  EmploymentType,
  ShiftInvoicedStatus,
  ShiftWithSlimCharges,
} from '@traba/types'
import { endOfDay, startOfDay, subWeeks } from 'date-fns'
import { compact } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Button, Col, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import BackButton from 'src/components/base/BackButton'
import { ButtonVariant } from 'src/components/base/Button/types'
import Pagination from 'src/components/base/Pagination/Pagination'
import { IMenuItem } from 'src/components/base/Select/Select'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import RegionsSelector from 'src/components/selectors/RegionsSelector'
import ToggleButtonGroup from 'src/components/ToggleButtonGroup'
import { useCompanies } from 'src/hooks/useCompanies'
import {
  getShiftsForInvoicing,
  useShiftsForInvoicing,
} from 'src/hooks/useInvoices'
import { useBasicPagination } from 'src/hooks/usePagination'
import useRegionsSelector from 'src/hooks/useRegionsSelector'
import { getEstimatedTotalChargeInCentsFromCharges } from 'src/utils/billingUtils'
import { convertUSDCentsToMoney } from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import InvoiceCreatorShiftsTable from './InvoiceCreatorShiftsTable'
import InvoiceCreatorPreview from './InvoiceGroupSelector/InvoiceCreatorPreview'
import InvoiceGroupSelector from './InvoiceGroupSelector/InvoiceGroupSelector'
import SelectedShifts from './SelectedShifts'

const PAGE_SIZE = 10

enum InvoiceCreatorStepsUngrouped {
  PICK_INITIAL_DETAILS = 0,
  PICK_SHIFTS,
  PREVIEW,
}

enum InvoiceCreatorStepsGrouped {
  PICK_INITIAL_DETAILS = 0,
  PICK_GROUP,
  PICK_SHIFTS,
  PREVIEW,
}

type InvoiceCreatorStepConfigGrouped = {
  key: InvoiceCreatorStepsGrouped
  label: string
}

type InvoiceCreatorStepConfigUngrouped = {
  key: InvoiceCreatorStepsUngrouped
  label: string
}

export default function InvoiceCreator() {
  const { companies, isLoading: isLoadingCompanies } = useCompanies({
    isApproved: true,
  })

  const companyToMenuItem = (company: CompanyResponse) => ({
    label: company.employerName,
    value: company.id,
  })

  const companyMenuOptions: IMenuItem[] = companies
    ? companies.map(companyToMenuItem)
    : []

  const [menuCompany, setMenuCompany] = useState<IMenuItem | undefined>(
    undefined,
  )

  const [isCreatingUngroupedInvoice, setIsCreatingUngroupedInvoice] =
    useState(true)

  const [selectedShifts, setSelectedShifts] = useState<ShiftWithSlimCharges[]>(
    [],
  )

  const [isOnPreviewScreen, setIsOnPreviewScreen] = useState(false)

  const [isLoadingSelectAcrossPages, setIsLoadingSelectAcrossPages] =
    useState(false)

  function confirmAndResetSelection(objectChangeText: string): boolean {
    if (selectedShifts.length === 0) {
      return true
    }

    const confirmationText = `Changing the ${objectChangeText} will reset your shift selections. Are you sure you want to continue?`
    if (window.confirm(confirmationText)) {
      setSelectedShifts([])
      return true
    }

    return false
  }

  function getSearchFormCompletionState():
    | { finished: true }
    | { finished: false; errorMessage: string } {
    if (!menuCompany?.value) {
      return {
        finished: false,
        errorMessage: 'Please select a company',
      }
    }

    if (!isCreatingUngroupedInvoice && selectedInvoiceGroupIds.length === 0) {
      return {
        finished: false,
        errorMessage: 'Please select an invoice group to create an invoice for',
      }
    }

    if (!selectedShifts.length) {
      return {
        finished: false,
        errorMessage: 'Please select at least a single shift to invoice',
      }
    }

    return {
      finished: true,
    }
  }

  const [selectedInvoiceGroupIds, setSelectedInvoiceGroupIds] = useState<
    string[]
  >([])

  const handleSelectInvoiceGroupId = (groupId: string) => {
    setSelectedInvoiceGroupIds((prevSelectedInvoiceGroupIds) => {
      if (prevSelectedInvoiceGroupIds.includes(groupId)) {
        return prevSelectedInvoiceGroupIds.filter((id) => id !== groupId)
      } else {
        return [...prevSelectedInvoiceGroupIds, groupId]
      }
    })
  }

  const searchFormCompletionState = getSearchFormCompletionState()

  const getSelectionContent = () => {
    if (!menuCompany?.value) {
      return (
        <Row style={{ width: '100%', justifyContent: 'center' }}>
          <InlineBanner text="Please select a company" severity="warning" />
        </Row>
      )
    }

    if (isCreatingUngroupedInvoice) {
      return <InvoiceUngroupedSelector />
    } else {
      return (
        <InvoiceGroupSelector
          selectedInvoiceGroupIds={selectedInvoiceGroupIds}
          handleSelectedInvoiceGroupIds={handleSelectInvoiceGroupId}
          isFetchingShifts={isFetching}
          selectedShifts={selectedShifts}
          onSelectShiftIds={setSelectedShiftIds}
          shifts={shifts ?? []}
        />
      )
    }
  }

  const setSelectedShiftIds = (newSelectedShiftIds: string[]) => {
    setSelectedShifts((prevSelectedShifts) => {
      const alreadySelectedShifts = prevSelectedShifts.filter((shift) =>
        newSelectedShiftIds.includes(shift.id),
      )
      const addedShifts = compact(
        newSelectedShiftIds
          .filter((id) => !prevSelectedShifts.find((shift) => shift.id === id))
          .map((id) => shifts?.find((shift) => shift.id === id)),
      )
      return [...alreadySelectedShifts, ...addedShifts]
    })
  }

  useEffect(() => {
    const updatedInvoiceGroupIds: string[] = Array.from(
      new Set(
        selectedShifts.map(
          (shift) => shift.parentInvoiceGroupId ?? 'ungrouped',
        ),
      ),
    )

    setSelectedInvoiceGroupIds(updatedInvoiceGroupIds)
  }, [selectedShifts])

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    currentLocalOffset,
    setCurrentPage,
  } = useBasicPagination(PAGE_SIZE)

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfDay(subWeeks(new Date(), 2)),
    startOfDay(subWeeks(new Date(), 1)),
  ])

  const [regionIds, setRegionIds] = useState<string[]>([])

  /* Regions */
  const { isLoadingRegions, regionMenuOptions } = useRegionsSelector(
    regionIds,
    setRegionIds,
  )

  // Reset pagination when search filters change
  useEffect(() => {
    setCurrentPage(0)
  }, [dateRange, regionIds, isCreatingUngroupedInvoice, setCurrentPage])

  // Reset selected group when group selector changes
  useEffect(() => {
    setSelectedInvoiceGroupIds([])
  }, [isCreatingUngroupedInvoice])

  const shiftParamsWithoutLimits = {
    companyIds: [menuCompany?.value ?? ''],
    ...(regionIds.length > 0 && { regionIds: regionIds }),
    sortBy: 'startTime',
    sortDir: 'asc',
    startAfter: dateRange[0]?.toISOString(),
    startBefore: dateRange[1]
      ? endOfDay(dateRange[1])?.toISOString()
      : undefined,
    // Don't want to paginate for grouped: eventually want a
    // separate query which aggregates and paginates by group
    ...(isCreatingUngroupedInvoice
      ? { hasInvoiceGroup: false }
      : { hasInvoiceGroup: true }),
    invoicedStatus: ShiftInvoicedStatus.NOT_INVOICED,
  }

  const { shifts, totalCount, isFetching } = useShiftsForInvoicing({
    ...shiftParamsWithoutLimits,
    ...(isCreatingUngroupedInvoice && { startAt: currentLocalOffset }),

    // Don't want to paginate for grouped: eventually want a
    // separate query which aggregates and paginates by group
    ...(isCreatingUngroupedInvoice && { limit: PAGE_SIZE }),
  })

  const removeSelectedShiftId = (shiftIdToRemove: string) => {
    setSelectedShifts(
      selectedShifts.filter((shift) => shift.id !== shiftIdToRemove),
    )
  }

  const handleClearSelectedShifts = () => {
    window.confirm('Are you sure you want to clear your shift selections?') &&
      setSelectedShiftIds([])
  }

  const handleSelectAllShiftsAcrossPages = async () => {
    setIsLoadingSelectAcrossPages(true)

    const result = await getShiftsForInvoicing(shiftParamsWithoutLimits)
    const allShifts = Array.isArray(result) ? result : result?.shifts

    allShifts && setSelectedShifts(allShifts)

    setIsLoadingSelectAcrossPages(false)
  }

  const getEstimatedTotalChargeInCents = (
    selectedShifts: ShiftWithSlimCharges[],
  ): number => {
    return selectedShifts.reduce((acc, shift) => {
      return acc + getEstimatedTotalChargeInCentsFromCharges(shift)
    }, 0)
  }

  const SelectedShiftsSection = () => {
    return (
      <Col>
        <Row
          alignEnd
          gap={theme.space.xxs}
          style={{ marginBottom: theme.space.xs }}
        >
          <Text variant="h4" mb={theme.space.xxs} style={{ flex: 1 }}>
            Selected Shifts
            {selectedShifts.length > 0 && ` (${selectedShifts.length})`}
          </Text>
          <Button
            slim
            variant={ButtonVariant.OUTLINED}
            disabled={!selectedShifts.length}
            onClick={handleClearSelectedShifts}
          >
            Clear
          </Button>
        </Row>
        {selectedShifts.length > 0 ? (
          <Col>
            <Col
              style={{ maxHeight: '50em', overflow: 'auto' }}
              mb={theme.space.xs}
            >
              <SelectedShifts
                selectedShifts={selectedShifts}
                removeSelectedShiftId={removeSelectedShiftId}
              />
            </Col>
            <Text variant="h4">
              Estimated Total Charge:{' '}
              {getMoneyString(
                convertUSDCentsToMoney(
                  getEstimatedTotalChargeInCents(selectedShifts),
                ),
              )}
            </Text>
          </Col>
        ) : (
          <InlineBanner text="No Shifts Selected" severity="info" />
        )}
      </Col>
    )
  }

  const invoiceCreatorShiftIdMap = useMemo(() => {
    return !isCreatingUngroupedInvoice
      ? selectedInvoiceGroupIds.reduce((acc, groupId) => {
          acc.set(
            groupId,
            selectedShifts
              .filter((shift) => shift.parentInvoiceGroupId === groupId)
              .map((shift) => shift.id),
          )
          return acc
        }, new Map<string, string[]>())
      : new Map([['ungrouped', selectedShifts.map((shift) => shift.id)]])
  }, [selectedShifts, selectedInvoiceGroupIds, isCreatingUngroupedInvoice])

  function InvoiceUngroupedSelector() {
    return (
      <Col
        style={{
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          flexBasis: 0,
        }}
      >
        <Row>
          <Col>
            <Row mb={theme.space.xs} justifyCenter>
              {isFetching ? (
                <CircularProgress size={20} />
              ) : shifts?.length ? (
                <InvoiceCreatorShiftsTable
                  shifts={shifts}
                  selectedShifts={selectedShifts}
                  onSelectShiftIds={setSelectedShiftIds}
                />
              ) : (
                <InlineBanner
                  text="No uninvoiced ungrouped shifts with a charge found within the search filters"
                  severity="info"
                />
              )}
            </Row>
            <Row justifyEnd gap={theme.space.med} mb={theme.space.xs}>
              <Pagination
                page={currentPage}
                pageSize={PAGE_SIZE}
                onPageLeft={goToPreviousPage}
                onPageRight={goToNextPage}
                dataSize={shifts?.length ?? 0}
                disabled={isFetching}
                totalFound={totalCount}
              />
            </Row>
            <Row fullWidth>
              <Button
                full
                disabled={
                  !shifts ||
                  shifts.length === 0 ||
                  isLoadingSelectAcrossPages ||
                  isFetching
                }
                onClick={handleSelectAllShiftsAcrossPages}
              >
                {isLoadingSelectAcrossPages ? (
                  <CircularProgress size={20} />
                ) : (
                  'Select all shifts across pages'
                )}
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  const ungroupedSteps: InvoiceCreatorStepConfigUngrouped[] = [
    {
      key: InvoiceCreatorStepsUngrouped.PICK_INITIAL_DETAILS,
      label: 'Select Company',
    },
    {
      key: InvoiceCreatorStepsUngrouped.PICK_SHIFTS,
      label: 'Select Shifts',
    },
    {
      key: InvoiceCreatorStepsUngrouped.PICK_SHIFTS,
      label: 'Preview',
    },
  ]

  const groupedSteps: InvoiceCreatorStepConfigGrouped[] = [
    {
      key: InvoiceCreatorStepsGrouped.PICK_INITIAL_DETAILS,
      label: 'Select Company',
    },
    {
      key: InvoiceCreatorStepsGrouped.PICK_GROUP,
      label: 'Pick Group',
    },
    {
      key: InvoiceCreatorStepsGrouped.PICK_SHIFTS,
      label: 'Select Shifts',
    },
    {
      key: InvoiceCreatorStepsGrouped.PREVIEW,
      label: 'Preview',
    },
  ]

  const steps = isCreatingUngroupedInvoice ? ungroupedSteps : groupedSteps

  const hasFinishedStepsUngrouped: Record<
    InvoiceCreatorStepsUngrouped,
    boolean
  > = {
    [InvoiceCreatorStepsUngrouped.PICK_INITIAL_DETAILS]:
      menuCompany !== undefined,
    [InvoiceCreatorStepsUngrouped.PICK_SHIFTS]: isOnPreviewScreen,
    [InvoiceCreatorStepsUngrouped.PREVIEW]: false,
  }

  const hasFinishedStepsGrouped: Record<InvoiceCreatorStepsGrouped, boolean> = {
    [InvoiceCreatorStepsGrouped.PICK_INITIAL_DETAILS]:
      menuCompany !== undefined,
    [InvoiceCreatorStepsGrouped.PICK_GROUP]: selectedInvoiceGroupIds.length > 0,
    [InvoiceCreatorStepsGrouped.PICK_SHIFTS]: isOnPreviewScreen,
    [InvoiceCreatorStepsGrouped.PREVIEW]: false,
  }

  const hasFinishedSteps = isCreatingUngroupedInvoice
    ? hasFinishedStepsUngrouped
    : hasFinishedStepsGrouped

  const finishedSteps = Object.entries(hasFinishedSteps)
    .filter(([_, hasFinished]) => hasFinished)
    .map(([key, _]) => Number(key))

  const activeStep = Math.max(...finishedSteps, -1) + 1

  const isLoading = isLoadingRegions || isLoadingCompanies
  if (isLoading) {
    return (
      <Row justifyCenter>
        <CircularProgress size={20}></CircularProgress>
      </Row>
    )
  }

  // Check that all selected shifts have the same employment type
  const selectedEmploymentTypes = selectedShifts.map(
    (shift) => shift.shiftEmploymentType,
  )
  const uniqueEmploymentTypes = Array.from(new Set(selectedEmploymentTypes))
  const hasMultipleEmploymentTypes = uniqueEmploymentTypes.length > 1

  const ShiftSelection = () => {
    return (
      <Row style={{ alignItems: 'stretch' }}>
        <Col
          style={{
            flex: 0,
            minWidth: '35em',
            padding: theme.space.med,
            alignItems: 'stretch',
            justifyContent: 'stretch',
            backgroundColor: theme.colors.Grey10,
            borderRadius: theme.space.xxs,
          }}
        >
          <Text variant="h4" style={{ marginBottom: theme.space.sm }}>
            Select shifts for invoice
          </Text>
          <SearchSelect
            onlyShowLabel
            options={companyMenuOptions}
            selectItem={menuCompany}
            handleSelect={(item) => {
              confirmAndResetSelection('company') && setMenuCompany(item)
            }}
            label={'Company'}
            width={'100%'}
            showClearButton
            style={{
              marginBottom: theme.space.sm,
            }}
          />

          <Row style={{ marginBottom: theme.space.sm }}>
            <ToggleButtonGroup fullWidth>
              <ToggleButton
                value="ungrouped"
                selected={isCreatingUngroupedInvoice}
                onClick={() => {
                  confirmAndResetSelection('invoice group type') &&
                    setIsCreatingUngroupedInvoice(true)
                }}
              >
                Ungrouped
              </ToggleButton>
              <ToggleButton
                value="grouped"
                selected={!isCreatingUngroupedInvoice}
                onClick={() => {
                  confirmAndResetSelection('invoice group type') &&
                    setIsCreatingUngroupedInvoice(false)
                }}
              >
                Grouped
              </ToggleButton>
            </ToggleButtonGroup>
          </Row>

          <Row style={{ marginBottom: theme.space.sm }}>
            <DateRangePicker
              label="Shift Start Date Range"
              inlineLabel={true}
              dateRange={dateRange}
              setDateRange={setDateRange}
              granularity="day"
              style={{
                width: '100%',
              }}
            />
          </Row>

          <Row style={{ marginBottom: theme.space.sm }}>
            <RegionsSelector
              regionMenuOptions={regionMenuOptions}
              selectedItems={regionIds}
              handleSelectMultiple={setRegionIds}
              width={'100%'}
            />
          </Row>

          <Row style={{ marginBottom: theme.space.sm }} justifyCenter>
            <SelectedShiftsSection />
          </Row>

          <Row fullWidth>
            <Button
              full
              tooltipText={
                !searchFormCompletionState.finished
                  ? searchFormCompletionState.errorMessage
                  : ''
              }
              disabled={
                !searchFormCompletionState.finished ||
                hasMultipleEmploymentTypes
              }
              onClick={() => setIsOnPreviewScreen(true)}
            >
              {hasMultipleEmploymentTypes
                ? 'Cannot have W2 and 1099 shifts in the same invoice'
                : 'Preview Invoice'}
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            flex: 1,
            display: 'flex',
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
            width: '100%',
          }}
        >
          <Row fullWidth>{getSelectionContent()}</Row>
        </Col>
      </Row>
    )
  }

  return (
    <Col>
      <BackButton
        noHistoryUrl="/billing"
        actionOverride={
          isOnPreviewScreen ? () => setIsOnPreviewScreen(false) : undefined
        }
      />
      <Row mb={theme.space.sm}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step.key} disabled>
              <StepButton>{step.label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Row>
      {isOnPreviewScreen && menuCompany?.value ? (
        <InvoiceCreatorPreview
          goBackToShiftSelection={() => setIsOnPreviewScreen(false)}
          companyId={menuCompany?.value}
          shiftIdMap={invoiceCreatorShiftIdMap}
          validateInvoiceGroupIds={
            !isCreatingUngroupedInvoice ? selectedInvoiceGroupIds : undefined
          }
          shiftEmploymentType={
            selectedShifts.length > 0 && selectedShifts[0].shiftEmploymentType
              ? selectedShifts[0].shiftEmploymentType
              : EmploymentType.CONTRACTOR_1099
          }
        />
      ) : (
        <ShiftSelection />
      )}
    </Col>
  )
}
