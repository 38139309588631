import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  TerminationInitiation,
  TerminationReason,
  WorkerUpload,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

export type W2WorkerTerminationRequest = {
  workerId: string
  reason: TerminationReason
  initiation: TerminationInitiation
  emailBody: string
  emailRecipient: string
  explanation: string
  date: Date
  uploadIds: string[]
}

export type TerminationClaim = {
  id: string
  claimId: string
  workerId: string
  reason: TerminationReason
  initiation: TerminationInitiation
  date: Date
  uploads: WorkerUpload[]
  emailBody: string
  emailRecipient: string
  explanation: string
}

export type TerminationClaimResponse = {
  claim: TerminationClaim | undefined
}

export const W2_TERMINATION_QUERY_KEY = 'workerW2Termination'

export function useWorkerW2Termination(workerId: string | undefined) {
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  async function getWorkerW2Termination(workerId: string) {
    const response = await trabaApi.get<TerminationClaimResponse>(
      `workers-w2/termination-claim/${workerId}`,
    )
    return response.data
  }

  async function createWorkerW2Termination(
    termination: W2WorkerTerminationRequest,
  ) {
    try {
      await trabaApi.post('workers-w2/termination-claim', termination)
    } catch (error) {
      captureSentryError(error)
      throw new Error('Failed to create W2 worker termination')
    }
  }

  const { isPending, mutateAsync } = useMutation<
    void,
    Error,
    W2WorkerTerminationRequest
  >({
    mutationFn: async (termination) =>
      await createWorkerW2Termination(termination),
    onSuccess: () => {
      showSuccess('W2 termination created successfully')
      queryClient.invalidateQueries({
        queryKey: [W2_TERMINATION_QUERY_KEY, workerId || ''],
      })
    },
    onError: (error) => {
      showError(error.message)
    },
  })

  const { data: workerW2Termination } = useQuery<
    TerminationClaimResponse | undefined,
    Error
  >({
    queryKey: [W2_TERMINATION_QUERY_KEY, workerId || ''],
    queryFn: async () => {
      const response = workerId
        ? await getWorkerW2Termination(workerId)
        : undefined
      return response
    },
    enabled: !!workerId,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isPending,
    terminateW2Worker: mutateAsync,
    workerW2Termination,
  }
}
