import { ConnectionType } from '@traba/types'
import { intersection } from 'lodash'

const CONNECTION_EXCLUSIVITY_MAP = {
  [ConnectionType.BLOCK]: [
    ConnectionType.FAVORITE,
    ConnectionType.TEMP_TO_HIRE,
  ],
  [ConnectionType.SCHEDULED_BLOCK]: [
    ConnectionType.FAVORITE,
    ConnectionType.TEMP_TO_HIRE,
  ],
  [ConnectionType.INELIGIBLE]: [
    ConnectionType.FAVORITE,
    ConnectionType.TEMP_TO_HIRE,
  ],
  [ConnectionType.FAVORITE]: [
    ConnectionType.BLOCK,
    ConnectionType.SCHEDULED_BLOCK,
    ConnectionType.INELIGIBLE,
  ],
  [ConnectionType.TEMP_TO_HIRE]: [
    ConnectionType.BLOCK,
    ConnectionType.SCHEDULED_BLOCK,
    ConnectionType.INELIGIBLE,
  ],
  [ConnectionType.VETTED]: [],
}

export function isConnectionType(
  connectionType: ConnectionType | string,
): connectionType is ConnectionType {
  return Object.keys(ConnectionType).includes(connectionType)
}

export const isBlockConnection = (
  connectionType: ConnectionType | undefined,
) => {
  return connectionType === ConnectionType.BLOCK
}

export const isVettedConnection = (
  connectionType: ConnectionType | undefined,
) => {
  return connectionType === ConnectionType.VETTED
}

export function checkConnectionConflicts(
  toAdd: ConnectionType,
  connections?: ConnectionType[],
) {
  if (!toAdd) {
    return {
      isValid: true,
    }
  }

  if (connections?.includes(toAdd)) {
    return {
      isValid: false,
      message: `Connection of type ${toAdd} already exists`,
    }
  }

  const conflicting = intersection(
    connections,
    CONNECTION_EXCLUSIVITY_MAP[toAdd],
  )
  if (conflicting.length === 0) {
    return {
      isValid: true,
    }
  }

  const conflictingString = conflicting.join(', ')

  return {
    isValid: false,
    message: `Adding ${toAdd} connection will overwrite existing ${conflictingString} connection${conflicting.length > 1 ? 's' : ''}`,
  }
}
