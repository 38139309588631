import { Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/components/base/Button'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DropdownElement } from 'src/components/base/Dropdown'
import { UserRolePermission } from 'src/context/user/types'
import { useDownloadResume } from 'src/hooks/useDownloadResume'
import { usePermissions } from 'src/hooks/usePermissions'
import { openInNewTab } from 'src/utils/helperUtils'
import {
  getCheckrReportUrl,
  getRetoolUrl,
  getStripeUrl,
  getZealUrl,
} from 'src/utils/workerUtils'
import { Icon } from '../../../components/base'
import { PopulatedWorker } from '../worker-search.types'

type WorkerSearchDropdownProps = {
  worker: PopulatedWorker
  buttonVariant?: ButtonVariant
  showProfileLink?: boolean
}

export default function WorkerSearchDropdown({
  worker,
  buttonVariant,
  showProfileLink = true,
}: WorkerSearchDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { downloadResume, isLoading: resumeIsLoading } = useDownloadResume(
    worker.id || worker.workerId,
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const workerId = worker.id

  const navigate = useNavigate()

  const backgroundCheck = worker?.accountStatus.backgroundCheck
  const isBgcAvailable =
    !!backgroundCheck &&
    !!backgroundCheck?.candidateId &&
    !!backgroundCheck?.reportId
  const isStripeAvailable = !!worker?.stripeAccountId

  const checkrReportUrl = backgroundCheck
    ? getCheckrReportUrl(backgroundCheck)
    : ''
  const stripeUrl = getStripeUrl(worker)
  const retoolUrl = getRetoolUrl(worker)
  const zealUrl = worker.zealEmployeeId ? getZealUrl(worker.zealEmployeeId) : ''

  // Eventually might be based on stripe, bgc, retool etc no differentiator atm
  const dropdownActionAccess = usePermissions([UserRolePermission.RetoolAccess])

  const workerActions: DropdownElement[] = [
    ...(showProfileLink
      ? [
          {
            label: 'Profile',
            handleClick: () => navigate(`/workers/${workerId}`),
          },
        ]
      : []),
    {
      disabled: !isBgcAvailable || !dropdownActionAccess,
      label: 'BGC',
      handleClick: () => isBgcAvailable && openInNewTab(checkrReportUrl),
    },
    {
      disabled: !dropdownActionAccess,
      label: 'Stripe',
      handleClick: () => isStripeAvailable && openInNewTab(stripeUrl),
    },
    {
      disabled: !dropdownActionAccess,
      label: 'Retool',
      handleClick: () => openInNewTab(retoolUrl),
    },
    {
      disabled: false,
      label: resumeIsLoading ? 'Loading...' : 'Download Resume',
      handleClick: () => {
        if (resumeIsLoading) {
          return
        }
        downloadResume()
      },
    },
    {
      disabled: !worker.zealEmployeeId,
      label: 'Zeal',
      handleClick: () => {
        openInNewTab(zealUrl)
      },
    },
  ]

  return (
    <div>
      <Button
        onClick={handleClick}
        variant={buttonVariant || ButtonVariant.TRANSPARENT}
        style={{ backgroundColor: 'transparent' }}
      >
        <Icon name={anchorEl ? 'elipses_active' : 'elipses'} />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {workerActions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={action.handleClick}
            disabled={action.disabled}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
