import { Modal, MODAL_SIZE } from '@traba/react-components'
import { ArtifactUploadContent } from './ArtifactUploadContent'

export const MultiArtifactUploadModal = ({
  handleClose,
  isOpen,
  workerId,
  workerName,
}: {
  handleClose: () => void
  isOpen: boolean
  workerId: string | undefined
  workerName: string
}) => {
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={`Add artifacts for ${workerName}`}
    >
      <ArtifactUploadContent
        workerId={workerId || ''}
        handleClose={handleClose}
      />
    </Modal>
  )
}
