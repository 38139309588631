import { Text, Row, Col, ButtonVariant, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ApplicationItem, UpsertApplicationItemRequestType } from '@traba/types'
import { useCallback, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { ApplicationItemContent } from './ApplicationItemContent/ApplicationItemContent'
import { ApplicationItemNumberAndTitle } from './ApplicationItemNumberAndTitle'
import { CreateAndEditApplicationItemContent } from './CreateAndEditApplicationItemContent/CreateAndEditApplicationItemContent'
import { StyledButton } from './styles'

export interface ViewAndEditExistingApplicationItemProps {
  applicationItem: ApplicationItem
  onDeleteStep?: () => void
  onSaveStep?: (
    upsertApplicationItemRequest: UpsertApplicationItemRequestType,
  ) => void
  isLoading?: boolean
  isEditable?: boolean
  stepIndex: number
}

export function ViewAndEditExistingApplicationItem({
  applicationItem,
  onDeleteStep,
  onSaveStep,
  isLoading,
  isEditable,
  stepIndex,
}: ViewAndEditExistingApplicationItemProps) {
  const [editing, setEditing] = useState(false)
  const onClickEditStep = useCallback(() => {
    setEditing(true)
  }, [])

  const onClickCancelEdit = useCallback(() => {
    setEditing(false)
  }, [])

  const handleDeleteStep = useCallback(() => {
    if (onDeleteStep) {
      onDeleteStep()
    }
  }, [onDeleteStep])

  const allowEdit = isEditable && onSaveStep

  return (
    <Col
      p={theme.space.sm}
      style={{
        borderRadius: theme.space.xxs,
        border: `1px solid ${theme.colors.Grey20}`,
      }}
      gap={theme.space.xs}
    >
      <Row justifyBetween alignCenter>
        <ApplicationItemNumberAndTitle
          stepNumber={stepIndex}
          applicationItemType={applicationItem.applicationItemType}
          titleVariant="h5"
        />

        {allowEdit && (
          <Row gap={theme.space.xs}>
            {editing ? (
              <StyledButton
                variant={ButtonVariant.OUTLINED}
                onClick={onClickCancelEdit}
                disabled={isLoading}
              >
                Cancel
              </StyledButton>
            ) : (
              <>
                {onDeleteStep && (
                  <StyledButton
                    disabled={isLoading}
                    loading={isLoading}
                    variant={ButtonVariant.REDOUTLINED}
                    onClick={handleDeleteStep}
                    iconPadding="0"
                    leftIcon={
                      <SvgIcon
                        name="trash"
                        type="svg"
                        size={theme.space.xs}
                        color={theme.colors.red}
                      />
                    }
                  >
                    <Text
                      ml={theme.space.xxs}
                      variant="h6"
                      style={{ color: theme.colors.red }}
                    >
                      Remove Step
                    </Text>
                  </StyledButton>
                )}

                <StyledButton
                  variant={ButtonVariant.OUTLINED}
                  onClick={onClickEditStep}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <SvgIcon
                    name="edit"
                    style={{ marginRight: theme.space.xxs }}
                    size={16}
                    color={theme.colors.Grey50}
                  />
                  Edit Details
                </StyledButton>
              </>
            )}
          </Row>
        )}
      </Row>

      <Divider />

      <Col>
        {editing && allowEdit ? (
          <CreateAndEditApplicationItemContent
            applicationItemId={applicationItem.id}
            applicationItemType={applicationItem.applicationItemType}
            applicationItemSourceId={applicationItem.sourceId}
            onSave={onSaveStep}
          />
        ) : (
          <ApplicationItemContent applicationItem={applicationItem} />
        )}
      </Col>
    </Col>
  )
}
