import { FormControlLabel, Switch } from '@mui/material'
import { theme } from '@traba/theme'
import {
  ALL_EMAIL_SETTINGS,
  ALL_TEXT_SETTINGS,
  NotificationPreferenceStatus,
  SettingsMap,
  UserNotificationSettingType,
} from '@traba/types'
import {
  getIfAllSettingsAreOff,
  getIfAllSettingsAreOn,
  mapStatusToShiftSelectedStatus,
  notifsHaveShiftsLevelSetting,
} from '@traba/utils'
import { useEffect, useState } from 'react'
import { ChevronDown } from '../base-components/ChevronDown'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Td } from '../base-components/Table/Table'
import { Text } from '../base-components/Text'

interface Props {
  isNotificationsAllowed: boolean
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  setIsLocationFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsShiftsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setEditingSettingTypes: React.Dispatch<
    React.SetStateAction<UserNotificationSettingType[] | null>
  >
  updatedItems: SettingsMap
  setUpdatedItems: React.Dispatch<React.SetStateAction<SettingsMap>>
}

export const NotificationSettingsMainControl = ({
  isNotificationsAllowed,
  setAnchorEl,
  setIsLocationFilterOpen,
  setIsShiftsFilterOpen,
  setEditingSettingTypes,
  setUpdatedItems,
  updatedItems,
}: Props) => {
  const [selectedLocationLength, setSelectedLocationLength] = useState<
    number | undefined
  >(undefined)
  const [shiftsRelatedDisplay, setShiftsRelatedDisplay] = useState<
    string | undefined
  >()
  const [isAllOn, setIsAllOn] = useState(false)
  const textColor = isNotificationsAllowed
    ? theme.colors.Violet
    : theme.colors.MidnightBlue

  useEffect(() => {
    const updatedIsAllOn = getIfAllSettingsAreOn(updatedItems)
    const updatedIsAllOff = getIfAllSettingsAreOff(updatedItems)
    const updatedItemsArray = Object.values(updatedItems)
    const isOneSettingLocationDifferent = updatedItemsArray.find(
      (item) =>
        item.locationIds?.length !== updatedItemsArray[0].locationIds?.length,
    )
    const isOneSettingShiftsDifferent = Array.from(
      notifsHaveShiftsLevelSetting,
    ).find(
      (setting) =>
        !ALL_TEXT_SETTINGS.includes(setting) &&
        updatedItems[setting].status !== updatedItemsArray[0].status,
    )
    setSelectedLocationLength(
      isOneSettingLocationDifferent || updatedIsAllOff
        ? undefined
        : updatedItemsArray[0].locationIds?.length,
    )
    setShiftsRelatedDisplay(
      isOneSettingShiftsDifferent
        ? undefined
        : mapStatusToShiftSelectedStatus(updatedItemsArray[0]),
    )

    if (updatedIsAllOn !== isAllOn) {
      setIsAllOn(updatedIsAllOn)
    }
  }, [updatedItems])

  return (
    <Row alignCenter>
      <Td
        style={{
          width: '66%',
          paddingLeft: theme.space.xs,
          height: 100,
          alignContent: 'center',
        }}
      >
        <Text variant="h4">Email settings</Text>
        <Text variant="body2" mt={theme.space.xxxs}>
          Which emails you want to receive?
        </Text>
      </Td>
      <Row
        alignCenter
        fullWidth
        style={{
          borderWidth: 1,
          borderColor: theme.colors.Grey30,
          borderRadius: 10,
          borderStyle: 'solid',
          backgroundColor: theme.colors.Grey10,
          marginRight: theme.space.xxs,
        }}
      >
        <Td style={{ width: '25%', alignContent: 'center' }}>
          <FormControlLabel
            labelPlacement="start"
            style={{ pointerEvents: isNotificationsAllowed ? 'auto' : 'none' }}
            control={
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: isNotificationsAllowed
                      ? undefined
                      : theme.colors.Grey50,
                  },
                }}
                style={{
                  color: isNotificationsAllowed
                    ? undefined
                    : theme.colors.Grey50,
                  pointerEvents: isNotificationsAllowed ? 'auto' : 'none',
                }}
                checked={isAllOn}
                onChange={() => {
                  if (!isNotificationsAllowed) {
                    return
                  }
                  if (isAllOn) {
                    Object.keys(updatedItems).forEach((key) => {
                      setUpdatedItems((prev) => ({
                        ...prev,
                        [key]: {
                          ...updatedItems[key],
                          status: NotificationPreferenceStatus.NONE,
                        },
                      }))
                    })
                  } else {
                    Object.keys(updatedItems).forEach((key) => {
                      if (
                        updatedItems[key].status ===
                        NotificationPreferenceStatus.NONE
                      ) {
                        setUpdatedItems((prev) => ({
                          ...prev,
                          [key]: {
                            ...updatedItems[key],
                            status: NotificationPreferenceStatus.ALL,
                          },
                        }))
                      }
                    })
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Text variant="h7" color={textColor}>
                Allow all
              </Text>
            }
          />
        </Td>
        <Td style={{ width: '42%', alignContent: 'center' }}>
          <Row
            alignCenter
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (!isNotificationsAllowed) {
                return
              }
              setAnchorEl(event.currentTarget)
              setIsShiftsFilterOpen(true)
              setEditingSettingTypes(ALL_EMAIL_SETTINGS)
            }}
          >
            {<SvgIcon name="briefcase" color={textColor} />}
            <Text
              variant="body2"
              color={textColor}
              mr={theme.space.xxxs}
              ml={theme.space.xxxs}
            >
              {shiftsRelatedDisplay ?? 'Select which shifts'}
            </Text>
            {<ChevronDown color={textColor} />}
          </Row>
        </Td>
        <Td style={{ alignContent: 'center' }}>
          <Row
            alignCenter
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (!isNotificationsAllowed) {
                return
              }
              setAnchorEl(event.currentTarget)
              setIsLocationFilterOpen(true)
              setEditingSettingTypes(Object.values(UserNotificationSettingType))
            }}
          >
            <SvgIcon name="location" color={textColor} />
            <Text mr={theme.space.xxxs} ml={theme.space.xxxs} color={textColor}>
              {selectedLocationLength
                ? `${selectedLocationLength} locations`
                : 'Select locations'}
            </Text>
            <ChevronDown color={textColor} />
          </Row>
        </Td>
      </Row>
    </Row>
  )
}
