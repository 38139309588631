import { ScheduleDays, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  formatScheduleDateRange,
  getAddressString,
  isRecurringShiftRequest,
  isShiftRequestParentInactive,
} from '@traba/utils'
import { useMemo } from 'react'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { SupervisorInfo } from 'src/screens/ShiftDetailsScreen/components/DescriptionSection/SupervisorInfo'
import { Badge, CopyTextIcon, Row } from '../base'
import { ScheduleListItem } from './ScheduleListItem'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}

export const ScheduleDetailsContentSection = ({
  shiftRequestParent,
}: Props) => {
  const { locations } = useLocations(shiftRequestParent.companyId)
  const { companyUsers } = useCompanyUsers(shiftRequestParent?.companyId || '')
  const recurringShiftRequests = useMemo(
    () =>
      shiftRequestParent.shiftRequests.filter((sr) =>
        sr.schedules.some((s) => s.isRecurringSchedule),
      ),
    [shiftRequestParent.shiftRequests],
  )
  const firstRecurringShiftRequest = useMemo(
    () => recurringShiftRequests.find((_sr) => true),
    [recurringShiftRequests],
  )
  const tz = useTimezonedDates(firstRecurringShiftRequest?.timezone)
  const firstSchedule = firstRecurringShiftRequest?.schedules[0]

  const parkingAddress = useMemo(() => {
    const address = locations?.find(
      (location) =>
        location.locationId === firstRecurringShiftRequest?.parkingLocationId,
    )?.address
    return address ? getAddressString(address) : 'Same as shift location'
  }, [locations, firstRecurringShiftRequest?.parkingLocationId])

  const shiftAddress = useMemo(() => {
    const address = locations?.find(
      (location) =>
        location.locationId === firstRecurringShiftRequest?.locationId,
    )?.address
    return address ? getAddressString(address) : 'No location'
  }, [locations, firstRecurringShiftRequest?.locationId])

  const supervisor = useMemo(
    () =>
      companyUsers?.find(
        (user) => user.uid === firstRecurringShiftRequest?.supervisorId,
      ),
    [companyUsers, firstRecurringShiftRequest?.supervisorId],
  )

  const roles = useMemo(
    () =>
      shiftRequestParent.shiftRequests.filter(isRecurringShiftRequest).map(
        (sr) =>
          ({
            shiftRequestText: `${sr.slotsRequested} ${sr.shiftRole}`,
            shiftRequestId: sr.shiftRequestId,
          }) as const,
      ),
    [shiftRequestParent.shiftRequests],
  )

  if (!firstSchedule) {
    return null
  }
  const timeString = tz.getShiftTime(
    firstSchedule.startTime,
    firstSchedule.endTime,
  )

  return (
    <Row mt={theme.space.sm} wrap>
      <Row flexCol fullWidth style={{ width: '30%' }}>
        <ScheduleListItem
          label="SCHEDULE RANGE"
          style={{ marginBottom: theme.space.sm }}
          content={
            <Row alignCenter gap={theme.space.xxs}>
              <Text variant="body1">
                {formatScheduleDateRange(recurringShiftRequests)}
              </Text>
              {isShiftRequestParentInactive(shiftRequestParent) && (
                <Badge title="INACTIVE" variant="warning" />
              )}
            </Row>
          }
        />
        <ScheduleListItem
          label="WEEKLY PATTERN"
          style={{ marginBottom: theme.space.sm }}
          content={
            <Row flexCol gap={theme.space.xxs}>
              {firstRecurringShiftRequest.schedules.map((schedule, index) => (
                <ScheduleDays
                  repeatOn={schedule.recurringSchedule?.repeatOn || []}
                  key={index}
                />
              ))}
            </Row>
          }
        />
        <ScheduleListItem label="SHIFT START - END" content={timeString} />
      </Row>

      <Row flexCol fullWidth style={{ width: '40%' }}>
        <ScheduleListItem
          label="SCHEDULE ID"
          style={{ marginBottom: theme.space.sm }}
          content={
            <Row alignCenter gap={theme.space.med}>
              <Text variant="body2" color={theme.colors.MidnightBlue}>
                <Row alignCenter>
                  <Text variant="caption">
                    {shiftRequestParent.shiftRequestParentId}
                  </Text>
                  <CopyTextIcon
                    textToCopy={shiftRequestParent.shiftRequestParentId}
                  ></CopyTextIcon>
                </Row>
              </Text>
            </Row>
          }
        />
        <ScheduleListItem
          label="ROLES FOR EACH SHIFT (SHIFT REQUESTS)"
          content={
            <Row flexCol>
              {roles.map(({ shiftRequestText, shiftRequestId }) => (
                <Row key={shiftRequestId} alignCenter gap={theme.space.med}>
                  <Text variant="body2" color={theme.colors.MidnightBlue}>
                    <Row>{shiftRequestText}</Row>
                    <Row alignCenter>
                      <Text variant="caption">{shiftRequestId}</Text>
                      <CopyTextIcon textToCopy={shiftRequestId}></CopyTextIcon>
                    </Row>
                  </Text>
                </Row>
              ))}
            </Row>
          }
        />
      </Row>

      <Row flexCol fullWidth style={{ width: '30%' }}>
        <ScheduleListItem
          label="LOCATION"
          style={{ marginBottom: theme.space.sm }}
          content={shiftAddress}
        />
        <ScheduleListItem
          label="PARKING LOCATION"
          style={{ marginBottom: theme.space.sm }}
          content={parkingAddress}
        />
        <ScheduleListItem
          label="SUPERVISOR"
          content={<SupervisorInfo supervisor={supervisor} />}
        />
      </Row>
    </Row>
  )
}
