import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'

const STALE_TIME = FIVE_MINUTES_IN_MS * 3

const checkIfSchedulesEnabled = async (companyId: string | undefined) => {
  if (!companyId) {
    return false
  }
  try {
    const response = await trabaApi.get(`statsig/schedules/${companyId}`)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
  }
}

export const useSchedulesFeatureFlag = (companyId: string | undefined) => {
  const { data: isSchedulesEnabled, isFetched: isSchedulesFeatureFlagFetched } =
    useQuery<boolean, Error>({
      queryKey: ['schedules-feature-flag', companyId],
      queryFn: () => checkIfSchedulesEnabled(companyId),
      staleTime: STALE_TIME,
    })

  return {
    isSchedulesEnabled: isSchedulesFeatureFlagFetched && isSchedulesEnabled,
  }
}
