import { Tooltip } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { EarningsSummary, PaymentStatus } from '@traba/types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from './base'

interface EarningsSummaryPaymentIconProps {
  workerId: string
  shiftId: string
  earningsSummary?: EarningsSummary
}

function getPaymentIcon(earningsSummary: EarningsSummary) {
  switch (earningsSummary.paymentStatus) {
    case PaymentStatus.Paid:
    case PaymentStatus.ManualComplete:
      return <Icon name="greenCheck" />
    case PaymentStatus.PayoutFailed:
    case PaymentStatus.PayoutDisabled:
      return (
        <Tooltip
          title={`${earningsSummary.paymentStatus}${
            earningsSummary.paymentStatusReason
              ? ': ' + earningsSummary.paymentStatusReason
              : ''
          }`}
        >
          <div>
            <Icon name="caution" />
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title={earningsSummary.paymentStatus}>
          <span role="img" aria-label="red x">
            ❌
          </span>
        </Tooltip>
      )
  }
}

const EarningsSummaryPaymentIcon: React.FC<EarningsSummaryPaymentIconProps> = ({
  workerId,
  shiftId,
  earningsSummary,
}) => {
  return (
    <Link
      to={`/action-center/1099/clearing-house?workerId=${workerId}&shiftId=${shiftId}`}
      onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (e.altKey) {
          await trabaApi.post(
            `payments/worker/${workerId}/shift/${shiftId}/resync`,
          )
          e.preventDefault()
        }
      }}
    >
      {earningsSummary && earningsSummary.totalOwed > 0
        ? getPaymentIcon(earningsSummary)
        : '-'}
    </Link>
  )
}

export default EarningsSummaryPaymentIcon
