import { useStatsigClient } from '@statsig/react-bindings'
import { TabsContainer, Tabs, Tab } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { Row } from 'src/components/base'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { W2WorkerHourDispute } from './components/W2WorkerHourDisputes/W2WorkerHourDispute'
import { WorkerOnboardingStatus } from './components/WorkerOnboardingStatus/WorkerOnboardingStatus'
import { WorkerStrikesTable } from './components/WorkerStrikesTable/WorkerStrikesTable'

export function W2ActionCenterScreen() {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState(0)
  const { client: statsigClient } = useStatsigClient()

  enum TabIndex {
    WORKER_ONBOARDING_STATUS = 0,
    WORKER_HOUR_DISPUTES = 1,
    WORKER_STRIKES = 2,
  }

  const navWithParams = (path: string) => {
    navigate({ pathname: path })
  }

  const TABS = [
    {
      key: 'worker-onboarding',
      label: 'Worker onboarding',
      navigateTo: () => navWithParams('worker-onboarding'),
    },

    {
      key: 'worker-hour-disputes',
      label: 'Worker hour disputes',
      navigateTo: () => navWithParams('worker-hour-disputes'),
    },
    ...(statsigClient.checkGate('worker_strikes')
      ? [
          {
            key: 'worker-strikes',
            label: 'Worker strikes',
            navigateTo: () => navWithParams('worker-strikes'),
          },
        ]
      : []),
  ]

  function getTabLabel(index: number, tabLabel: string) {
    const labelComponent = (
      <Text
        variant="h5"
        color={
          currentTab === index ? theme.colors.MidnightBlue : theme.colors.Grey60
        }
      >
        {tabLabel}
      </Text>
    )

    switch (index) {
      case TabIndex.WORKER_ONBOARDING_STATUS:
      default:
        return labelComponent
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/action-center/w2/worker-onboarding':
        setCurrentTab(TabIndex.WORKER_ONBOARDING_STATUS)
        break
      case '/action-center/w2/worker-hour-disputes':
        setCurrentTab(TabIndex.WORKER_HOUR_DISPUTES)
        break
      case '/action-center/w2/worker-strikes':
        setCurrentTab(TabIndex.WORKER_STRIKES)
        break
      default:
        setCurrentTab(TabIndex.WORKER_ONBOARDING_STATUS)
        break
    }
  }, [location.pathname])

  return (
    <MainLayout title="W2 Action Center">
      <>
        <Row justifyBetween>
          <Text variant="h3">W2 Action Center</Text>
        </Row>
        <TabsContainer>
          <Tabs
            variant="scrollable"
            value={currentTab}
            onChange={(_, v) => setCurrentTab(v)}
          >
            {TABS.map((tab, index) => (
              <Tab
                key={tab.key}
                onClick={tab.navigateTo}
                label={getTabLabel(index, tab.label)}
              />
            ))}
          </Tabs>
        </TabsContainer>
      </>
      <Routes>
        <Route path="worker-onboarding" element={<WorkerOnboardingStatus />} />
        <Route path="worker-hour-disputes" element={<W2WorkerHourDispute />} />
        <Route path="worker-strikes" element={<WorkerStrikesTable />} />
        <Route path="*" element={<Navigate to="worker-onboarding" />} />
      </Routes>
    </MainLayout>
  )
}
