import { useAlert } from '@traba/context'
import { Shift } from '@traba/types'
import { useEffect, useState } from 'react'
import { useSearchWorkers } from 'src/hooks/useWorkers'
import { AddWorkerToShiftsModal } from 'src/screens/FieldMonitorScreen/components/AddWorkers/AddWorkerToShiftsModal'

interface AddWorkerToShiftsModalContainerProps {
  isOpen: boolean
  handleClose: () => void
  workerId: string
  shift: Shift
}

export function AddWorkerToShiftsModalContainer({
  isOpen,
  handleClose,
  workerId,
  shift,
}: AddWorkerToShiftsModalContainerProps) {
  const { showError } = useAlert()
  const [searched, setSearched] = useState(false)
  const { isLoading, searchWorkers, workersSearchResult } = useSearchWorkers()

  useEffect(() => {
    if (isOpen && workerId) {
      searchWorkers({ searchData: { workerId }, direction: 'initial' })
      setSearched(true)
    }
  }, [isOpen, workerId])

  useEffect(() => {
    if (searched && !isLoading && workersSearchResult?.length === 0) {
      showError('Worker not found')
      handleClose()
    }
  }, [
    isOpen,
    workerId,
    searched,
    isLoading,
    workersSearchResult,
    showError,
    handleClose,
  ])

  if (!searched || !isOpen || isLoading || workersSearchResult.length === 0) {
    return null
  }

  return (
    <AddWorkerToShiftsModal
      handleClose={handleClose}
      isOpen={isOpen}
      shiftsList={[shift]}
      workers={workersSearchResult}
      hideSearchForMoreWorkers
    />
  )
}
