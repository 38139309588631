import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Section } from '@traba/types'
import {
  MRT_Table as MaterialReactTableNoToolbar,
  useMaterialReactTable,
} from 'material-react-table'
import { useMemo } from 'react'

const NUMERIC_COLUMNS = [
  'REGULAR_HOURS_BILLED',
  'OVERTIME_HOURS_BILLED',
  'BREAK_HOURS_TOTAL',
  'TOTAL_HOURS_BILLED',
  'GROSS_PAY_RATE',
  'REGULAR_MARKUP_RATE',
  'REGULAR_BILL_RATE',
  'REGULAR_AMOUNT_BILLED_TOTAL',
  'OVERTIME_MARKUP_RATE',
  'OVERTIME_BILLED_RATE',
  'OVERTIME_AMOUNT_BILLED_TOTAL',
  'TOTAL_BILL_RATE',
  'TOTAL_AMOUNT_BILLED',
  'OVERTIME_HOURS_WORKED',
  'REGULAR_HOURS_WORKED',
]

const CELL_COMMON_STYLES = {
  padding: '4px',
  fontSize: '12px',
  fontWeight: '300',
  border: '0px',
}

const HEADER_CELL_STYLES = {
  ...CELL_COMMON_STYLES,
  backgroundColor: theme.colors.MidnightBlue,
  color: theme.colors.White,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& *': {
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    fontSize: '12px',
  },
}

const BASE_CHAR_WIDTH = 8
const PADDING = 4

export function TableSection({ section }: { section: Section }) {
  const data = useMemo(
    () => [...section.data, ...(section.footer ? [section.footer] : [])],
    [section.data, section.footer],
  )

  const columnSizes = useMemo(() => {
    return section.headers.reduce<Record<string, number>>((acc, { key }) => {
      const maxLength = Math.max(...data.map((row) => String(row[key]).length))
      const isNumeric = NUMERIC_COLUMNS.includes(key)
      const width = maxLength * BASE_CHAR_WIDTH + PADDING

      acc[key] = isNumeric ? Math.min(width, 120) : Math.min(width, 200)
      return acc
    }, {})
  }, [section.headers, data])

  const columns = useMemo(
    () =>
      section.headers.map(({ value, key }) => {
        const align: 'left' | 'right' = NUMERIC_COLUMNS.includes(key)
          ? 'right'
          : 'left'

        return {
          header: value,
          accessorKey: key,
          size: columnSizes[key],
          muiTableHeadCellProps: {
            align,
          },
          muiTableBodyCellProps: {
            align,
          },
          grow: 1,
        }
      }),
    [section.headers, columnSizes],
  )

  const table = useMaterialReactTable({
    columns,
    data,
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    muiTableContainerProps: {
      sx: { overflowX: 'hidden' },
    },
    muiTableBodyRowProps: ({ row }) => ({
      hover: false,
      sx: {
        backgroundColor:
          row.index === data.length - 1 && section.footer
            ? theme.colors.White
            : row.index % 2 === 0
              ? theme.colors.Blue10
              : theme.colors.White,
        borderTop:
          row.index === data.length - 1 && section.footer
            ? `1px solid ${theme.colors.MidnightBlue}`
            : 'none',
        '& td': {
          fontWeight:
            row.index === data.length - 1 && section.footer ? 'bold' : '300',
        },
      },
    }),
    muiTableProps: {
      sx: {
        border: '0px',
      },
    },
    muiTableHeadCellProps: {
      sx: HEADER_CELL_STYLES,
    },
    muiTableBodyCellProps: {
      sx: CELL_COMMON_STYLES,
    },
    layoutMode: 'grid',
  })

  return (
    <Row flexCol gap={theme.space.xxs}>
      {section.title && (
        <Text variant="h6" color={theme.colors.Black}>
          {section.title}
        </Text>
      )}
      <MaterialReactTableNoToolbar table={table} />
    </Row>
  )
}
