import { SvgIconProps } from '@traba/types'

export default function NotificationFilled({
  size,
  color,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.76461 8.27275C2.56088 4.87812 5.21144 2.22757 8.60606 1.43129C11.0576 0.856236 13.609 0.856235 16.0606 1.43129C19.4552 2.22757 22.1058 4.87812 22.902 8.27275C23.4771 10.7243 23.4771 13.2757 22.902 15.7272C22.1057 19.1219 19.4552 21.7724 16.0606 22.5687C13.609 23.1438 11.0576 23.1438 8.60607 22.5687C5.21144 21.7724 2.56088 19.1219 1.76461 15.7273C1.18955 13.2757 1.18955 10.7243 1.76461 8.27275Z"
        fill={color}
      />
      <path
        d="M12.3333 7V7.89935"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.3266 8.01765C10.2286 8.32979 9.37153 9.11297 9.0685 10.0939L8.74581 11.1384L8.49035 12.1531C8.41166 12.4656 8.30461 12.787 8.24817 13.1033C8.23839 13.1581 8.23331 13.2143 8.23331 13.2716C8.23331 13.7828 8.64197 14.2232 9.21052 14.3248L9.41147 14.3607C11.3397 14.7053 13.3269 14.7053 15.2551 14.3607L15.4561 14.3248C16.0246 14.2232 16.4333 13.7828 16.4333 13.2716C16.4333 13.2143 16.4282 13.1581 16.4184 13.1033C16.362 12.787 16.255 12.4656 16.1763 12.1531L15.9208 11.1384L15.602 10.0726C15.3126 9.10534 14.4871 8.33298 13.4086 8.0206C12.7351 7.8255 12.0013 7.82582 11.3266 8.01765Z"
        fill="white"
      />
      <path
        d="M10.6983 14.5388C11.7849 14.6459 12.8817 14.6459 13.9683 14.5388M9.0685 10.0939C9.37153 9.11297 10.2286 8.32979 11.3266 8.01765C12.0013 7.82582 12.7351 7.8255 13.4086 8.0206C14.4871 8.33298 15.3126 9.10534 15.602 10.0726L15.9208 11.1384L16.1763 12.1531C16.255 12.4656 16.362 12.787 16.4184 13.1033C16.4282 13.1581 16.4333 13.2143 16.4333 13.2716C16.4333 13.7828 16.0246 14.2232 15.4561 14.3248L15.2551 14.3607C13.3269 14.7053 11.3397 14.7053 9.41147 14.3607L9.21052 14.3248C8.64197 14.2232 8.23331 13.7828 8.23331 13.2716C8.23331 13.2143 8.23839 13.1581 8.24817 13.1033C8.30461 12.787 8.41166 12.4656 8.49035 12.1531L8.74581 11.1384L9.0685 10.0939Z"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.3333 16.25C11.9979 16.3041 12.6687 16.3041 13.3333 16.25C13.3286 16.2968 13.3185 16.3433 13.303 16.389L13.2756 16.4697C13.1897 16.7227 12.9169 16.9195 12.5687 16.9799C12.4139 17.0067 12.2527 17.0067 12.098 16.9799C11.7497 16.9195 11.4769 16.7227 11.3911 16.4697L11.3637 16.389C11.3482 16.3433 11.338 16.2968 11.3333 16.25Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  )
}
