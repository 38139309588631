import { useAlert } from '@traba/context'
import { useInternalUsers } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button, Row } from 'src/components/base'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useUserContext } from 'src/context/user/UserContext'
import { useResumeBulkUpload } from 'src/hooks/useResumeBulkUpload'

export default function BulkResumeUploadScreen() {
  const { showError } = useAlert()
  const { state } = useUserContext()
  const { internalUsers } = useInternalUsers({
    emails: [state.userProfile?.email ?? ''],
  })
  const [zipFile, setZipFile] = useState<File>()
  const { uploadZip, isPending } = useResumeBulkUpload(
    internalUsers?.[0]?.id ?? '',
  )

  const handleUploadFiles = async () => {
    if (!zipFile) {
      showError('Please upload a ZIP file')
      return
    }

    await uploadZip({ zipFile })
    setZipFile(undefined)
  }

  return (
    <MainLayout>
      <Card style={{ padding: theme.space.lg }}>
        <h2>Upload Indeed Resumes</h2>

        <div style={{ marginTop: theme.space.lg }}>
          <Row gap={2}>
            <MediaUploader
              label="Upload Resumes (ZIP)"
              file={zipFile}
              onChange={setZipFile}
              onDelete={() => setZipFile(undefined)}
              onError={(error) => showError(error)}
              fileType="zip"
              maxFileSizeMB={50}
            />
          </Row>
        </div>

        <Button
          mt={theme.space.lg}
          disabled={!zipFile || isPending}
          onClick={handleUploadFiles}
          loading={isPending}
        >
          {isPending ? 'Uploading...' : 'Upload Files'}
        </Button>

        <Card
          style={{
            marginTop: theme.space.lg,
            padding: theme.space.med,
            background: theme.colors.Grey10,
          }}
        >
          <h3>Instructions:</h3>
          <ol>
            <li>Export candidates from Indeed as CSV</li>
            <li>Download all resumes as PDFs</li>
            <li>Combine all PDFs and CSV into a single ZIP file</li>
            <li>Upload the ZIP file here</li>
          </ol>
        </Card>
      </Card>
    </MainLayout>
  )
}
