import { EmploymentType } from './employmentType'

export enum BackgroundCheckStatus {
  PENDING = 'PENDING',
  INVITATION_CREATED = 'INVITATION_CREATED',
  REPORT_CREATED = 'REPORT_CREATED',
  CONSIDER = 'CONSIDER',
  CLEAR = 'CLEAR',
  SUSPENDED = 'SUSPENDED',
  DISPUTE = 'DISPUTE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
}

export enum DetailedBackgroundCheckStatus {
  // From BackgroundCheckStatus
  PENDING = 'PENDING',
  INVITATION_CREATED = 'INVITATION_CREATED',
  REPORT_CREATED = 'REPORT_CREATED',
  CONSIDER = 'CONSIDER',
  CLEAR = 'CLEAR',
  SUSPENDED = 'SUSPENDED',
  DISPUTE = 'DISPUTE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',

  // From AdjudicationStatus
  POST_ADVERSE_ACTION = 'POST_ADVERSE_ACTION',
  PRE_ADVERSE_ACTION = 'PRE_ADVERSE_ACTION',

  // From BackgroundCheckAssessment
  ELIGIBLE = 'ELIGIBLE',
  REVIEW = 'REVIEW',
  ESCALATED = 'ESCALATED',

  // Derived status
  ELIGIBLE_AFTER_REVIEW = 'ELIGIBLE_AFTER_REVIEW',
}

export enum ApprovalStatusReason {
  WORKER_COMMITTED_FRAUD = 'Worker Committed Fraud',
  WORKER_COMMITTED_THEFT = 'Worker Committed Theft',
  OFF_PLATFORM_ACTIVITY = 'Off-platform Activity',
  VIOLENCE = 'Violence',
  UNPROFESSIONAL_BEHAVIOR = 'Unprofessional Behavior',
  CRIMINAL_ACTIVITY = 'Criminal Activity',
  UNDER_THE_INFLUENCE = 'Under The Influence',
  ABUSE_OR_HARASSMENT = 'Abuse/Harassment',
  NO_SHOW_AT_SHIFT = 'No Show at Shift',
  LATE_TO_SHIFT = 'Late to shift',
  ABANDONED_SHIFT = 'Abandoned Shift',
  LATE_CANCELLATION = 'Late Shift Cancellation',
  DUPE_SSN_DOB = 'DUPE_SSN_DOB', // New worker reported ssn&dob already exist in db
  DUPE_BGC_SSN_DOB = 'DUPE_BGC_SSN_DOB', // Worker BGC SSN & DoB already exists in DB
  MISMATCH_SSN = 'MISMATCH_SSN', // Worker reported ssn mismatch with BGC results
  MISMATCH_DOB = 'MISMATCH_DOB', // Worker reported dob mismatch with BGC results
  MISMATCH_NAME = 'MISMATCH_NAME', // Worker reported name mismatch with BGC results
  NONE = 'None',

  // Deprecated but still exist in the DB so need to keep
  SSN_DOB_MISMATCH = 'SSN_DOB_MISMATCH', //worker reported ssn&dob mismatch with checkr results
}

export type BgcCandidate = {
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: string
  last4SSN?: string
}

export enum BGCRequirement {
  CLEAN = 'CLEAN',
  STANDARD = 'STANDARD',
}

export type AdjudicationStatus =
  | 'ENGAGED'
  | 'POST_ADVERSE_ACTION'
  | 'PRE_ADVERSE_ACTION'

export type BackgroundCheckAssessment = 'ELIGIBLE' | 'REVIEW' | 'ESCALATED'

export type BackgroundCheck = {
  invitationId?: string | null
  candidateId?: string | null
  reportId?: string | null
  status?: BackgroundCheckStatus | null
  adjudication?: AdjudicationStatus | null
  assessment?: BackgroundCheckAssessment | null
  createdAt?: Date
  completedAt?: Date | null
  updatedAt?: Date | null
  candidate?: BgcCandidate | null
  reportedDob?: string | null
}

export enum InstantPayEligibilityStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
  ELIGIBLE_WITH_WARNING = 'ELIGIBLE_WITH_WARNING',
  PERMANENTLY_INELIGIBLE = 'PERMANENTLY_INELIGIBLE',
}

export enum InstantPayIneligibilityReason {
  NOT_ENOUGH_SHIFTS = 'NOT_ENOUGH_SHIFTS',
  REVOKED_DUE_TO_INCIDENT = 'REVOKED_DUE_TO_INCIDENT',
  BELOW_MIN_RELIABILITY = 'BELOW_MIN_RELIABILITY',
  BELOW_MIN_OTA = 'BELOW_MIN_OTA',
  BELOW_MIN_LOCATION = 'BELOW_MIN_LOCATION',
  BELOW_PERFECT_SHIFT_STREAK_REQUIREMENT = 'BELOW_PERFECT_SHIFT_STREAK_REQUIREMENT',
}

export type InstantPayEligibility = {
  status: InstantPayEligibilityStatus
  reasons?: InstantPayIneligibilityReason[]
}

export type AccountStatus = {
  id?: string
  accountStatus: AccountApprovalStatus
  approvalStatusReason?: ApprovalStatusReason
  activeRegion?: boolean
  approvedAt?: Date
  createdAt: Date
  payment?: {
    setupStatus: string
    createdAt: Date
    instantPayEligible?: boolean
    instantPayEnabled?: boolean
    instantPayOnboardingComplete?: boolean
    instantPayEligibility?: InstantPayEligibility
    payoutsEnabled?: boolean
    payoutsDisabledReason?: string
    payoutsDisabledDeadline?: Date
  }
  backgroundCheck?: BackgroundCheck
  w9Form?: {
    status: W9FormStatus
    updatedAt: Date
  }
  stash?: Stash
  isTrainingShiftComplete?: boolean
  suspensionExpiration?: Date
  activeEmploymentTypes?: EmploymentType[]
  phoneNumberStatus?: PhoneNumberStatus
}

export type WorkerAccountStatusForBusiness = Pick<
  AccountStatus,
  'isTrainingShiftComplete'
>

export type Stash = {
  accountStatus: AccountApprovalStatus
  backgroundCheck: BackgroundCheck
  reinstatedAt?: Date
}

export enum W9FormStatus {
  Unverified = 'UNVERIFIED',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Requested = 'REQUESTED',
}

export enum AccountApprovalStatus {
  Approved = 'APPROVED',
  Unapproved = 'UNAPPROVED',
  Rejected = 'REJECTED',
  Banned = 'BANNED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  TerminationPending = 'TERMINATION_PENDING',
}

export enum PhoneNumberStatus {
  VERIFIED = 'VERIFIED',
  UNREACHABLE = 'UNREACHABLE',
  WRONG_NUMBER = 'WRONG_NUMBER',
  TWILIO_BLOCKED = 'TWILIO_BLOCKED',
}

export enum ZealEVerifyStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_REFERRAL = 'PENDING_REFERRAL',
  REFERRED = 'REFERRED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  FAILURE_NO_SHOW = 'FAILURE_NO_SHOW',
  FAILURE_NO_ACTION = 'FAILURE_NO_ACTION',
}

export enum I9CompletionStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export type DetailedAccountSetupStatusW2 = {
  bgc: (typeof DetailedBackgroundCheckStatus)[keyof typeof DetailedBackgroundCheckStatus]
  i9: I9CompletionStatus | undefined
  eVerify: ZealEVerifyStatus | undefined
}

export type AccountSetupCompletionW2 = {
  employeeProfile: boolean
  bgc: boolean
  i9: boolean
  w4: boolean
  eVerify: boolean
  contracts: boolean
  detailedStatus: DetailedAccountSetupStatusW2
}

export type AccountSetupCompletion1099 = {
  bgc: boolean
  w9: boolean
  stripeAccount: boolean
}
