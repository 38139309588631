import { Tab as BaseTab } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Button } from 'src/components/base'
import { WorkerVettingPhoneCallFeedback } from 'src/hooks/useWorkerVetting'
import styled from 'styled-components'

export const Tab = styled(BaseTab)`
  padding: ${theme.space.xs}px;
  margin: 0px;
  font-size: ${theme.default.fontSize};
`
export const TabBody = styled.div`
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;
  width: 100%;
`

export const CreateVettingContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: flex-start;
  overflow-y: auto;
`

export const CreateVettingWrapper = styled.div`
  display: flex;
  padding: ${theme.space.med}px;
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
  flex-direction: column;
`

export const CreateVettingCard = styled.div`
  display: flex;
  padding: ${theme.space.med}px;
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
  flex-direction: column;
  overflow-y: auto;
  cursor: pointer;
`

export const CreateVettingQuestionCard = styled.div`
  display: flex;
  padding: ${theme.space.xs}px;
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${theme.space.xxs}px;
  min-height: 50px;
  max-height: 100px;
  position: relative;
`

interface RoundMarkerProps {
  active: boolean
}

export const RoundMarker = styled.div<RoundMarkerProps>`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.active ? theme.colors.brand : theme.colors.White};
  border: ${(props) =>
    !props.active ? `1px solid ${theme.colors.brand}` : 'none'};
  font-size: ${theme.space.xs}px;
  font-weight: 600;
  color: ${(props) => (props.active ? theme.colors.White : theme.colors.brand)};
  margin: 0 ${theme.space.med}px;
`

export const VioletCard = styled.section`
  padding: ${theme.space.xxs}px;
  background-color: ${theme.colors.Violet10};
  border-radius: ${theme.border.radius};
  width: 60vw;
  margin-left: ${theme.space.xs}px;
`

export const FeedbackButton = styled(Button)`
  padding: ${theme.space.xxs}px;
`

export const VettingFeedbackReasonButton = styled(Button)<{
  feedback: WorkerVettingPhoneCallFeedback
}>`
  width: 30%;
  margin: ${theme.space.xxs}px;
  padding: ${theme.space.xxs}px;
  background-color: ${(props) =>
    props.feedback === WorkerVettingPhoneCallFeedback.CORRECT
      ? theme.colors.Green70
      : theme.colors.Red50};
`
