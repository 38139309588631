import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerVettingMethod, WorkerVettingSessionMessage } from '@traba/types'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { Row } from 'src/components/base'
import {
  VettingSessionPhoneCallsStatusBadge,
  VettingSessionStatusBadge,
} from 'src/components/VettingCampaignStatusBadge'
import { WorkerVettingSessionWithPhoneCalls } from 'src/hooks/useWorkerVetting'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'
import { formatPhoneNumber } from 'src/utils/stringUtils'

interface WorkerVettingSessionMessagePreviewProps {
  vettingSession: WorkerVettingSessionWithPhoneCalls
  campaignType: WorkerVettingMethod
  isSelected: boolean
  setSelectedSession: Dispatch<
    SetStateAction<WorkerVettingSessionWithPhoneCalls | undefined>
  >
  messageSelected: boolean
}

const formatMessagePreview = (message: WorkerVettingSessionMessage) => {
  return `${message.source}: ${message.message.slice(0, 50)}${
    message.message.length > 50 ? '...' : ''
  }`
}

export default function WorkerVettingSessionMessagePreview(
  props: WorkerVettingSessionMessagePreviewProps,
) {
  const {
    vettingSession,
    isSelected,
    setSelectedSession,
    campaignType,
    messageSelected,
  } = props

  const messages = vettingSession.messages
  const mostRecentPhoneCall = useMemo(() => {
    return vettingSession.phoneCalls.length > 0
      ? vettingSession.phoneCalls.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )[0]
      : undefined
  }, [vettingSession.phoneCalls])
  const mostRecentMessage =
    messages.length > 0 ? messages[messages.length - 1] : undefined

  const badges = (
    <>
      <VettingSessionStatusBadge status={vettingSession.status} />
      {campaignType === WorkerVettingMethod.smsAndCall && (
        <VettingSessionPhoneCallsStatusBadge
          status={mostRecentPhoneCall?.status}
        />
      )}
    </>
  )

  return (
    <Row
      center
      style={{
        padding: theme.space.sm,
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.colors.Grey20}`,
        backgroundColor: isSelected ? theme.colors.Violet10 : 'transparent',
      }}
      onClick={() => setSelectedSession(vettingSession)}
      flexCol
    >
      <Row fullWidth justifyBetween>
        <Row gap={theme.space.xs}>
          <Text variant="h6" style={{ marginRight: theme.space.xxs }}>
            {formatPhoneNumber(vettingSession.phoneNumber, true)}
          </Text>
          {!messageSelected && badges}
        </Row>
        <Row>
          <Text variant="body2" mr={theme.space.xxxs}>
            {formatTimeDistanceToNow(new Date(vettingSession.lastActiveAt))}
          </Text>
        </Row>
      </Row>
      {messageSelected && <Row gap={theme.space.xs}>{badges}</Row>}
      <Text variant="body1">
        {mostRecentMessage
          ? formatMessagePreview(mostRecentMessage)
          : 'No response from Worker yet'}
      </Text>
    </Row>
  )
}
