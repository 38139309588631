import {
  WorkerTimecard,
  WorkerTimecardClockInAdjustmentReason,
  WorkerTimecardStatus,
} from '@traba/types'
import { W2WorkerDisputeRowContent } from './W2WorkerDisputeRowContent'

interface W2WorkerHourDisputeRowProps {
  timecard: WorkerTimecard
  finalTime: Date | undefined
  setFinalTime: (finalTime: Date) => void
}

export function W2WorkerClockInDisputeRow(props: W2WorkerHourDisputeRowProps) {
  const { timecard, finalTime, setFinalTime } = props
  const {
    workerClockInAdjustmentReason,
    bizAdjustmentReason,
    workerClockInAdjustmentOtherReason,
    bizClockInTime,
    workerClockInTime,
    workerShift,
    opsClockInTime,
    status,
    finalizedReason,
  } = timecard
  const workerReason =
    workerClockInAdjustmentReason ===
    WorkerTimecardClockInAdjustmentReason.OTHER
      ? workerClockInAdjustmentOtherReason
      : workerClockInAdjustmentReason
  const hasChanged = bizClockInTime !== workerClockInTime
  const finalizedTime = opsClockInTime ?? workerClockInTime ?? bizClockInTime
  const noActionAllowed =
    status === WorkerTimecardStatus.FINALIZED ||
    status === WorkerTimecardStatus.PENDING_WORKER

  return (
    <W2WorkerDisputeRowContent
      bizTime={bizClockInTime}
      workerTime={workerClockInTime}
      finalizedReason={finalizedReason}
      bizAdjustmentReason={bizAdjustmentReason}
      workerReason={workerReason}
      hasChanged={hasChanged}
      noActionAllowed={noActionAllowed}
      finalTime={finalTime}
      setFinalTime={setFinalTime}
      timeZone={workerShift.timeZone}
      finalizedTime={finalizedTime}
    />
  )
}
