import { Switch } from '@mui/material'

import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import { SetStateAction } from 'react'
import { Row } from 'src/components/base'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'

import {
  MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB,
  validateDynamicOverbookAllowed,
  validateOverbook,
  validateSlots,
} from 'src/utils/shiftFormUtils'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'

interface Props {
  createShiftRequest: CreateShiftRequest
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  index: number
  shiftEmploymentType: EmploymentType
}

export const SlotsAndOverbook = ({
  createShiftRequest,
  setCreateShiftRequests,
  index,
  shiftEmploymentType,
}: Props) => {
  const oldOverbook = (
    <ShiftPostingInputContainerSection
      label="Overbook Slots (optional)"
      input={
        <DeprecatedNumberInput
          value={createShiftRequest.overbookSlotsRequested}
          width={80}
          setter={(numReq) => {
            setCreateShiftRequests((prev) => {
              const newCreateShiftRequests = [...prev]
              newCreateShiftRequests[index] = {
                ...newCreateShiftRequests[index],
                overbookSlotsRequested: numReq > 0 ? numReq : undefined,
              }
              return newCreateShiftRequests
            })
          }}
          placeholder={`e.g. ${createShiftRequest.slotsRequested + 1}`}
          error={
            !validateOverbook(
              createShiftRequest.slotsRequested,
              createShiftRequest.overbookSlotsRequested,
            )
          }
          helperText="Must be ≥ slots requested"
        />
      }
      right
    />
  )
  return (
    <Row
      style={{
        justifyContent: 'space-around',
      }}
      my={theme.space.sm}
      fullWidth
      justifyStart
    >
      <Row style={{ width: '20%' }}>
        <ShiftPostingInputContainerSection
          label="Slots Requested"
          input={
            <DeprecatedNumberInput
              value={createShiftRequest.slotsRequested}
              width={80}
              setter={(numReq) => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    slotsRequested: numReq,
                    minSlotsRequested: numReq,
                  }
                  return newCreateShiftRequests
                })
              }}
              placeholder="Slots Requested"
              error={
                !validateSlots(
                  createShiftRequest.slotsRequested,
                  createShiftRequest.overbookSlotsRequested,
                ) ||
                !validateDynamicOverbookAllowed(
                  createShiftRequest.slotsRequested,
                  !!createShiftRequest.enableDynamicOverbooking,
                )
              }
              helperText={`Must be ${
                createShiftRequest.enableDynamicOverbooking
                  ? '≥ 5 because dynamic overbooking is enabled.'
                  : '> 0 and ≤ overbook slots'
              }`}
            />
          }
        />
      </Row>
      {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 && (
        <Row style={{ width: '40%' }}>
          <ShiftPostingInputContainerSection
            label="Enable dynamic overbooking"
            input={
              <Row alignCenter>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={createShiftRequest.enableDynamicOverbooking}
                  onClick={() => {
                    if (
                      !createShiftRequest.enableDynamicOverbooking &&
                      createShiftRequest.slotsRequested <
                        MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
                    ) {
                      alert(
                        'Dynamic overbooking can only be enabled on shifts with 5 or more slots requested.',
                      )
                      return
                    }
                    setCreateShiftRequests((prev) => {
                      const newCreateShiftRequests = [...prev]
                      newCreateShiftRequests[index] = {
                        ...newCreateShiftRequests[index],
                        enableDynamicOverbooking:
                          !newCreateShiftRequests[index]
                            .enableDynamicOverbooking,
                        // Whenever this switch is toggled, completely reset overbook
                        // slots. This dramatically simplifies state management.
                        overbookSlotsRequested: undefined,
                      }
                      return newCreateShiftRequests
                    })
                  }}
                />
                <InfoTooltip
                  title={`When enabled, this shift's overbook slots will be continuously adjusted (no
                       action needed on your part). If you want to override this and manually set the
                       overbook slots, disable this and add your desired overbook slots in the
                       "Overbook Slots" field that appears.`}
                  tooltipProps={{
                    leaveDelay: 500,
                    placement: 'top',
                    arrow: true,
                  }}
                />
              </Row>
            }
            right
          />
          {!createShiftRequest.enableDynamicOverbooking && oldOverbook}
        </Row>
      )}
    </Row>
  )
}
