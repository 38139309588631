import { DataTableHeader, useModal } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreditNote, InvoiceStatus } from '@traba/types'
import { useMemo, useState } from 'react'
import { Button, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Table, Td, Tr } from 'src/components/base/Table/Table'
import { getMoneyString } from 'src/utils/stringUtils'
import LineItem from '../../components/LineItem'
import VoidCreditNoteModal from './VoidCreditNoteModal'

type CreditNoteTableProps = {
  creditNote: CreditNote
  index: number
  refetch?: () => void
  invoiceStatus?: InvoiceStatus
  isMostRecent: boolean
}

export default function CreditNoteTable(props: CreditNoteTableProps) {
  const { creditNote, index, refetch, invoiceStatus, isMostRecent } = props

  const [selectedCreditNote, setSelectedCreditNote] = useState<
    CreditNote | undefined
  >(undefined)

  const headers: DataTableHeader[] = [
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'totalCharge',
      label: 'Total Charge',
      sortable: false,
    },
  ]

  const voidCreditNoteModal = useModal()
  const { totalCredits, lineItemRows } = useMemo(() => {
    let totalCredits = 0
    const lineItemRows = creditNote.lineItems.map((lineItem, index) => {
      totalCredits += lineItem.chargeToBusiness.amount
      return (
        <LineItem
          key={index.toString()}
          index={index}
          lineItem={lineItem}
          lineItemsEditable={false}
        />
      )
    })

    return { totalCredits, lineItemRows }
  }, [props])

  return (
    <div key={index}>
      <Row alignCenter justifyBetween style={{ margin: theme.space.xxs }}>
        <Text
          style={{
            textAlign: 'left',
            fontWeight: 'bold',
          }}
        >
          Credit Note {index + 1}
        </Text>
        {invoiceStatus === InvoiceStatus.OPEN && (
          <Button
            slim
            onClick={() => {
              setSelectedCreditNote(() => creditNote)
              voidCreditNoteModal.open()
            }}
            disabled={!isMostRecent} // Only the most recently created credit note is voidable
            variant={ButtonVariant.CANCEL}
          >
            Void Credit Note
          </Button>
        )}
      </Row>
      <Table headers={headers}>
        {lineItemRows}
        <Tr>
          <Td
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              paddingRight: theme.space.xs,
            }}
          >
            Total Credit Issued
          </Td>
          <Td style={{ fontWeight: 'bold' }}>
            {getMoneyString(totalCredits / 100)}
          </Td>
        </Tr>
      </Table>

      {selectedCreditNote && (
        <VoidCreditNoteModal
          isOpen={voidCreditNoteModal.isOpen}
          handleClose={() => {
            voidCreditNoteModal.handleClose()
            setSelectedCreditNote(undefined)
          }}
          creditNote={selectedCreditNote}
          refetch={refetch}
        />
      )}
    </div>
  )
}
