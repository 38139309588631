import { Tooltip } from '@mui/material'
import { Dialog, MODAL_SIZE, Text } from '@traba/react-components'
import { Button, ButtonVariant, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { truncateString } from '@traba/utils'
import { useState, useMemo, memo, useCallback } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { Dropdown, DropdownElement } from 'src/components/base/Dropdown'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import {
  useRefreshDrugScreeningOrder,
  useWorkerDrugScreeningOrders,
  useCancelDrugScreeningOrder,
} from 'src/hooks/useDrugScreening'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { DrugScreeningOrderModal } from './DrugScreeningOrderModal'

interface DrugScreeningTabProps {
  workerId: string
  workerName: string
}

const DRUG_SCREENING_ORDER_HEADERS: DataTableHeader[] = [
  {
    key: 'action',
    label: 'Action',
  },
  {
    key: 'createdAt',
    label: 'Created',
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: 'Updated',
    sortable: true,
  },
  {
    key: 'expiresAt',
    label: 'Order Expiry',
    sortable: true,
  },
  {
    key: 'id',
    label: 'Order ID',
  },
  {
    key: 'vendorOrderId',
    label: 'Vendor ID',
  },
  {
    key: 'drugPanelType',
    label: 'Type',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Order Status',
    sortable: true,
  },
  {
    key: 'requestingCompanyName',
    label: 'Company',
    sortable: true,
  },
  {
    key: 'shiftRequestId',
    label: 'Shift Request ID',
    sortable: true,
  },
  {
    key: 'employmentType',
    label: 'Employment Type',
    sortable: true,
  },
  {
    key: 'labPassportId',
    label: 'Lab Passport ID',
    sortable: true,
  },
  {
    key: 'sampleCollectionTimestamp',
    label: 'Sample Collection',
    sortable: true,
  },
  {
    key: 'applicantPortalUrl',
    label: 'Waiver URL',
  },
  {
    key: 'additionalInformation',
    label: 'Additional Information',
  },
]

interface ActionButtonsProps {
  workerId: string
  onOrderNew: () => void
}

const ActionButtons = memo(function ActionButtons({
  onOrderNew,
}: ActionButtonsProps) {
  return (
    <Row
      gap={theme.space.xs}
      style={{ marginBottom: theme.space.xs, justifyContent: 'flex-end' }}
    >
      <Button variant={ButtonVariant.OUTLINED} onClick={onOrderNew}>
        Order New Drug Screen
      </Button>
    </Row>
  )
})

interface InfoDialogProps {
  showInfoModal: boolean
  setShowInfoModal: (show: boolean) => void
  additionalInfoForOrder: string
}

export function InfoDialog({
  showInfoModal,
  setShowInfoModal,
  additionalInfoForOrder,
}: InfoDialogProps) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showInfoModal}
      onClose={() => setShowInfoModal(false)}
      size={additionalInfoForOrder.length > 50 ? MODAL_SIZE.LARGE : undefined}
      dialogTitle="Additional Information for Order"
      hideFooter
    >
      {/* whiteSpace property is used here to maintain the XML formatting of the result */}
      <Text style={{ whiteSpace: 'pre-wrap' }} variant="body1">
        {additionalInfoForOrder}
      </Text>
    </Dialog>
  )
}

export default function WorkerDrugScreeningOrders({
  workerId,
  workerName,
}: DrugScreeningTabProps) {
  const [showOrderModal, setShowOrderModal] = useState(false)
  const { data: orders, isLoading } = useWorkerDrugScreeningOrders(workerId)
  const { mutate: cancelOrder } = useCancelDrugScreeningOrder()
  const { mutate: refreshOrder } = useRefreshDrugScreeningOrder()
  const [additionalInfoForOrder, setAdditionalInfoForOrder] = useState('')
  const [showInfoModal, setShowInfoModal] = useState(false)

  const getDropdownActions = useCallback(
    (orderId: string, status: string) => {
      if (status === 'CANCELED' || status === 'EXPIRED') {
        return []
      }

      const cancelPendingOrder: DropdownElement = {
        label: 'Cancel Pending Order',
        handleClick: () => cancelOrder(orderId),
      }

      const refreshOrderAction: DropdownElement = {
        label: 'Refresh Order',
        handleClick: () => refreshOrder(orderId),
      }

      return status === 'PENDING' || status === 'WAIVER_SIGNED'
        ? [cancelPendingOrder, refreshOrderAction]
        : [refreshOrderAction]
    },
    [cancelOrder, refreshOrder],
  )

  const tableRows = useMemo(
    () =>
      orders?.map((drugScreen) => {
        const {
          id,
          drugPanelType,
          status,
          createdAt,
          expiresAt,
          requestingCompanyName,
          shiftRequestId,
          labPassportId,
          applicantPortalUrl,
          updatedAt,
          vendorOrderId,
          sampleCollectionTimestamp,
          additionalInformation,
          employmentType,
        } = drugScreen

        return {
          key: new Date(createdAt).getTime().toString(),
          cells: [
            {
              renderFn: () => {
                const dropdownOptions = getDropdownActions(id, status)
                return (
                  <Dropdown
                    dropdownOptions={dropdownOptions}
                    orientation={'right'}
                    verticalPosition={'top'}
                    buttonVariant={ButtonVariant.OUTLINED}
                    disabled={dropdownOptions.length === 0}
                    buttonStyling={{
                      padding: `${theme.space.xsmed}px -${theme.space.xsmed}px ${theme.space.xms}px`,
                      marginBottom: -theme.space.xxs,
                      marginTop: -theme.space.xxs,
                    }}
                  />
                )
              },
              sortKey: 'action',
            },
            {
              renderFn: () => formatDateTimeWithTimezone(createdAt),
              sortKey: new Date(createdAt).getTime(),
            },
            {
              renderFn: () => formatDateTimeWithTimezone(updatedAt),
              sortKey: new Date(updatedAt).getTime(),
            },
            {
              renderFn: () => formatDateTimeWithTimezone(expiresAt),
              sortKey: new Date(expiresAt ?? '').getTime(),
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    {truncateString(id ?? '', 3, true)}
                    <CopyTextIcon textToCopy={id} />
                  </Row>
                )
              },
              sortKey: id,
            },
            {
              renderFn: () => vendorOrderId,
              sortKey: vendorOrderId,
            },
            {
              renderFn: () => drugPanelType,
              sortKey: drugPanelType,
            },
            {
              renderFn: () => {
                const text = <Text>{status}</Text>
                return additionalInformation ? (
                  <Tooltip title={additionalInformation}>{text}</Tooltip>
                ) : (
                  text
                )
              },
              sortKey: status,
            },
            {
              renderFn: () => requestingCompanyName,
              sortKey: requestingCompanyName,
            },
            {
              renderFn: () => shiftRequestId,
              sortKey: shiftRequestId,
            },
            {
              renderFn: () => employmentType,
              sortKey: employmentType,
            },
            {
              renderFn: () => labPassportId ?? 'WAIVER_NOT_SIGNED',
              sortKey: labPassportId,
            },
            {
              renderFn: () => {
                return sampleCollectionTimestamp
                  ? formatDateTimeWithTimezone(sampleCollectionTimestamp)
                  : '-'
              },
              sortKey: sampleCollectionTimestamp
                ? new Date(sampleCollectionTimestamp).getTime()
                : 0,
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    <CopyTextIcon textToCopy={applicantPortalUrl} />
                  </Row>
                )
              },
              sortKey: applicantPortalUrl,
            },
            {
              renderFn: () => (
                <div
                  onClick={() => {
                    if (additionalInformation) {
                      setAdditionalInfoForOrder(additionalInformation)
                      setShowInfoModal(true)
                    }
                  }}
                  style={{
                    cursor: additionalInformation ? 'pointer' : 'default',
                  }}
                >
                  {truncateString(additionalInformation || '-', 20, true)}
                </div>
              ),
            },
          ],
        }
      }) ?? [],
    [orders, getDropdownActions],
  )

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  return (
    <>
      <ActionButtons
        workerId={workerId}
        onOrderNew={() => setShowOrderModal(true)}
      />
      <DataTable
        rows={tableRows}
        headers={DRUG_SCREENING_ORDER_HEADERS}
        initialSortByColumnIndex={2}
        initialSortByAscOrder={false}
      />
      <DrugScreeningOrderModal
        showModal={showOrderModal}
        onCloseModal={() => setShowOrderModal(false)}
        workerId={workerId}
        workerName={workerName}
      />
      <InfoDialog
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        additionalInfoForOrder={additionalInfoForOrder}
      />
    </>
  )
}
