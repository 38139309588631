import {
  EmploymentType,
  ForwardFillMax,
  ForwardFillRenotificationMethod,
  PaymentType,
  ScheduledBreak,
  ShiftTag,
} from '../index'
import { BGCRequirement } from './account-status'
import { BackfillSettingsDto } from './backfill'
import {
  GenderPreference,
  RecordStatus,
  RelatedShiftsType,
  RequiredMultiShiftType,
  TestAccountType,
} from './base'
import { FormatLineItemType, InvoiceChargeCycle } from './invoice'
import { Address, Location, LocationResponse } from './locations'
import { ShiftAttribute } from './shift-attributes'
import { IconName } from './svg-types'
import { WorkerCertification } from './worker-certification'
import { RequiredAttributeLevel, RoleAttributeCategory } from './worker-profile'
import { TierLevel } from './worker-tiers'

export enum BreakType {
  PAID = 'PAID',
  AUTO_UNPAID = 'AUTO_UNPAID',
  MANUAL_UNPAID = 'MANUAL_UNPAID',
  NONE = 'NONE',
}

export enum CompanyCategory {
  WAREHOUSE = 'WAREHOUSE',
  EVENTS = 'EVENTS',
  FOOD_PRODUCTION = 'FOOD_PRODUCTION',
  MANUFACTURING = 'MANUFACTURING',
  BEVERAGE_PRODUCTION = 'BEVERAGE_PRODUCTION',
  COSMETICS_PRODUCTION = 'COSMETICS_PRODUCTION',
  CONTRACT_PACKAGING = 'CONTRACT_PACKAGING',
  CONTRACT_MANUFACTURING = 'CONTRACT_MANUFACTURING',
  THREE_PL = 'THREE_PL',
  FULFILLMENT = 'FULFILLMENT',
  FINAL_MILE = 'FINAL_MILE',
  DISTRIBUTION = 'DISTRIBUTION',
  CROSS_DOCK_DISTRIBUTION = 'CROSS_DOCK_DISTRIBUTION',
  FURNITURE_PRODUCTION_AND_DISTRIBUTION = 'FURNITURE_PRODUCTION_AND_DISTRIBUTION',
  TIRE_PRODUCTION_AND_DISTRIBUTION = 'TIRE_PRODUCTION_AND_DISTRIBUTION',
  PRINTING = 'PRINTING',
  FOOD_PREPARATION_AND_SERVICE = 'FOOD_PREPARATION_AND_SERVICE',
}

export const CompanyCategoryValues: { [key in CompanyCategory]: string } = {
  [CompanyCategory.WAREHOUSE]: 'Warehouse',
  [CompanyCategory.EVENTS]: 'Events',
  [CompanyCategory.FOOD_PRODUCTION]: 'Food Production',
  [CompanyCategory.MANUFACTURING]: 'Manufacturing',
  [CompanyCategory.BEVERAGE_PRODUCTION]: 'Beverage Production',
  [CompanyCategory.COSMETICS_PRODUCTION]: 'Cosmetics Production',
  [CompanyCategory.CONTRACT_PACKAGING]: 'Contract Packaging',
  [CompanyCategory.CONTRACT_MANUFACTURING]: 'Contract Manufacturing',
  [CompanyCategory.THREE_PL]: '3PL',
  [CompanyCategory.FULFILLMENT]: 'Fulfillment',
  [CompanyCategory.FINAL_MILE]: 'Final Mile',
  [CompanyCategory.DISTRIBUTION]: 'Distribution',
  [CompanyCategory.CROSS_DOCK_DISTRIBUTION]: 'Cross Dock Distribution',
  [CompanyCategory.FURNITURE_PRODUCTION_AND_DISTRIBUTION]:
    'Furniture Production and Distribution',
  [CompanyCategory.FOOD_PREPARATION_AND_SERVICE]:
    'Food Preparation and Service',
  [CompanyCategory.TIRE_PRODUCTION_AND_DISTRIBUTION]:
    'Tire Production and Distribution',
  [CompanyCategory.PRINTING]: 'Printing',
}

export const CompanyCategoryIcon: { [key in CompanyCategory]: IconName } = {
  [CompanyCategory.WAREHOUSE]: 'packageLight',
  [CompanyCategory.EVENTS]: 'calendarLight',
  [CompanyCategory.FOOD_PRODUCTION]: 'foodProduction',
  [CompanyCategory.MANUFACTURING]: 'manufacturing',
  [CompanyCategory.BEVERAGE_PRODUCTION]: 'drinkLight',
  [CompanyCategory.COSMETICS_PRODUCTION]: 'lipstick',
  [CompanyCategory.CONTRACT_PACKAGING]: 'packageLight',
  [CompanyCategory.CONTRACT_MANUFACTURING]: 'formLight',
  [CompanyCategory.THREE_PL]: 'threePL',
  [CompanyCategory.FULFILLMENT]: 'delivery',
  [CompanyCategory.FINAL_MILE]: 'pinLight',
  [CompanyCategory.DISTRIBUTION]: 'distributionCenters',
  [CompanyCategory.CROSS_DOCK_DISTRIBUTION]: 'crossDock',
  [CompanyCategory.FURNITURE_PRODUCTION_AND_DISTRIBUTION]: 'cabinetLight',
  [CompanyCategory.TIRE_PRODUCTION_AND_DISTRIBUTION]: 'tire',
  [CompanyCategory.PRINTING]: 'formLight',
  [CompanyCategory.FOOD_PREPARATION_AND_SERVICE]: 'foodProduction',
}

export const CompanyCategoryNameToId = new Map(
  Object.entries(CompanyCategoryValues).map((entry) => [
    entry[1],
    entry[0] as CompanyCategory,
  ]),
)

export type Company = {
  companyId: string
  employerName: string
  createdAt: Date
  address: Address
  coords?: Location
  shortLocation?: string
  category?: CompanyCategory
  activeEmploymentTypes?: EmploymentType[]
  referralCode?: string
  companyDescription?: string
  companyPhoneNumber?: string
  rating?: number
  baseMarkup?: number
  calculatedMarkup?: number
  bonusTimeCalculatedMarkup?: number | null
  workerMedia?: WorkerMedia[]
  bannerUrl?: string
  companyLogo?: string
  minHourlyPayRate?: number
  breakType?: BreakType
  isApproved: boolean
  agreements?: {
    acceptedCustomerAgreement?: Date
    acceptedFeesAndTerms?: Date
  }
  isHandpicked: boolean
  salesforceId?: string
  testAccountType?: TestAccountType
  backfillSettings?: BackfillSettingsDto
  minimumAcceptedTier?: TierLevel
  reliabilityThreshold?: number // Shift acceptance override
  unprovenWorkerThreshold?: number // Shift acceptance override
  invoiceChargeCycle?: InvoiceChargeCycle
  hasSameDayClockInAndOutCodes?: boolean
  hasPreShiftConfirmationRobocall?: boolean
  extraBGCRequirement: BGCRequirement
  showAddressToNonWorkersForCompany: boolean
  showNameToNonWorkersForCompany: boolean
  allowPostingPayByUnit?: boolean
  shiftTags?: ShiftTag[]
  billingEmails?: string[]
  allowsDynamicOverbooking?: boolean
  enabledCertifications?: string[]
  allowGenderPreference?: boolean
  showRequiredMultiShiftToggle?: boolean
  showSearch?: boolean
  requiredMultiShiftType?: RequiredMultiShiftType
  kioskModeType?: KioskModeType
  kioskModeLevel?: KioskModeLevel
  kioskPin?: string
  disableLeftFencePromptOverride: boolean
  requireW9Authorization: boolean
  defaultBreaks?: ScheduledBreak | null
  autoDeductManualBreaks?: boolean
  paymentType?: PaymentType
  minutesToClockInEarlyBy?: number | null
  upflowEnabled?: boolean
  minutesAheadForShiftPosting?: number
  timesheetEmail?: string
}

// Potentially add "requirements" to include company specific reqs like drug testing.
export type CompanyResponse = {
  id: string
  createdAt: Date
  updatedAt: Date
  salesforceId: string | null
  isApproved: boolean
  employerName: string
  category: CompanyCategory | null
  calculatedMarkup: number | null
  baseMarkup: number | null
  companyLogo: string | null
  bannerUrl: string
  rating: number
  paymentType: PaymentType | null
  referralCode: string
  breakType: BreakType | null
  acceptedCustomerAgreement: Date | null
  acceptedFeesAndTerms: Date | null
  allowsBackfill: false
  lateTolerance: number
  backfillLocationId: string | null
  backfillLocationStartOffset: number | null
  enabledCertifications: string[]
  reliabilityThreshold: number | null
  minimumAcceptedTier: TierLevel
  unprovenWorkerThreshold: number | null
  allowPostingPayByUnit: boolean | null
  extraBGCRequirement: BGCRequirement
  forwardFillMax: ForwardFillMax | null
  forwardFillRenotificationMethod: ForwardFillRenotificationMethod | null
  workerReportCycleCadence: string | null
  workerReportDestination: string | null
  workerReportEmails: string[]
  relatedShiftsType: RelatedShiftsType | null
  requiredMultiShiftType: RequiredMultiShiftType | null
  invoiceChargeCycle: InvoiceChargeCycle | null
  addresses: Address[]
  showRequiredMultiShiftToggle: boolean | null
  activeEmploymentTypes?: EmploymentType[]
}

export type Companies = {
  [key: number]: Company
}

export type ShiftCompanyResponse = {
  company: {
    companyId: string
    address: Address
    bannerUrl?: string
    createdAt: Date
    employerName: string
    companyLogo?: string
    rating?: number
    workerMedia?: WorkerMedia[]
  }
}

export type Role = {
  roleId: string
  activeRoleId?: string
  companyId: string
  roleName: string
  roleDescription: string
  defaultPayRate: number
  requiredAttire: string
  requiredCertifications?: WorkerCertification['certificationType'][]
  requiredAttributes?: ShiftAttribute[]
  requiredAttributeLevels?: RequiredAttributeLevel[]
  hasPreShiftConfirmationRobocall?: boolean
  extraBGCRequirement: BGCRequirement
  freeformAttributes?: Partial<Record<RoleAttributeCategory, string>>
  videoIds?: string[]
  resumeUploadRequired?: boolean
  requiredTrainingIds?: string[]
  genderPreference?: GenderPreference
  minimumAgeRequirement?: number
  recordStatus?: RecordStatus
  location?: LocationResponse
}

type PaymentSettings = {
  stripeCustomerId: string
  email: string
  phoneNumber: string
}

type Card = {
  brand: string
  expMonth: number
  expYear: number
  last4: string
}

export type PaymentMethod = {
  id: string
  card?: Card
}

export enum InvoiceApproval {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL', // set by ops
  MANUAL_NEW = 'MANUAL_NEW', // by default, new businesses will need their first 3 invoices manually approved
}

export enum InvoiceCycleEnd {
  Daily = 'DAILY',
  WeeklySunday = 'WEEKLY_SUNDAY', // Default
  WeeklyMonday = 'WEEKLY_MONDAY',
  WeeklyTuesday = 'WEEKLY_TUESDAY',
  WeeklyWednesday = 'WEEKLY_WEDNESDAY',
  WeeklyThursday = 'WEEKLY_THURSDAY',
  WeeklyFriday = 'WEEKLY_FRIDAY',
  WeeklySaturday = 'WEEKLY_SATURDAY',
}

export type Billing = {
  billingId: string
  companyId: string
  paymentSettings: PaymentSettings
  automaticChargesEnabled: boolean
  paymentMethods: PaymentMethod[]
  invoiceApproval: InvoiceApproval
  invoiceLineItemType: FormatLineItemType
  additionalEmails?: string[]
  invoiceCycleEnd: InvoiceCycleEnd
  invoiceDaysUntilDue: number
  contactName?: string
  invoiceCompanyName?: string
}

export interface BillingUpdate
  extends Omit<Partial<Billing>, 'paymentSettings'> {
  paymentSettings?: Partial<PaymentSettings>
}

export type WorkerYouTubeVideo = {
  type: WorkerMediaType.YOUTUBE
  videoId: string
}

export type WorkerVideo = {
  type: WorkerMediaType.VIDEO
  videoUrl: string
  thumbnailUrl?: string
}

export type WorkerImage = {
  type: WorkerMediaType.IMAGE
  imageUrl: string
}

export enum WorkerMediaType {
  VIDEO = 'Video',
  IMAGE = 'Image',
  YOUTUBE = 'YouTubeVideo',
}

export type WorkerMedia = { order: number } & (
  | WorkerImage
  | WorkerVideo
  | WorkerYouTubeVideo
)

export enum KioskModeType {
  None = 'NONE',
  QrOnly = 'QR_ONLY',
  PhoneNumberOnly = 'PHONE_NUMBER_ONLY',
  SupervisorOnly = 'SUPERVISOR_ONLY',
  ExternalTimekeepingOnly = 'EXTERNAL_TIMEKEEPING_ONLY',
}

export enum KioskModeLevel {
  COMPANY = 'COMPANY',
  LOCATION = 'LOCATION',
}
