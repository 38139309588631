import { Tooltip } from '@mui/material'
import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { truncateString } from 'src/utils/stringUtils'

interface WorkerNameWithIconsProps {
  name: string
  addMargin?: boolean
  maxLength?: number
  isInvitedWorker?: boolean
  isFavoriteWorker?: boolean
  isFirstShiftWithCompany?: boolean
  isFirstShiftWithTraba?: boolean
  isPaidBackup?: boolean
  isVettedForRole?: boolean
}

export function WorkerNameWithIcons({
  name,
  addMargin = true,
  maxLength,
  isInvitedWorker = false,
  isFavoriteWorker = false,
  isFirstShiftWithCompany = false,
  isFirstShiftWithTraba = false,
  isPaidBackup = false,
  isVettedForRole = false,
}: WorkerNameWithIconsProps) {
  return (
    <Row gap={addMargin ? theme.space.xxxs : 0}>
      {isInvitedWorker && (
        <Tooltip title={'Worker has been invited'}>
          <span role="img" aria-label="Worker invited by business">
            💌
          </span>
        </Tooltip>
      )}
      {isFirstShiftWithTraba && (
        <Tooltip title="Worker's first shift with Traba">
          <span role="img" aria-label="First shift with Traba">
            🐣
          </span>
        </Tooltip>
      )}
      {!isFirstShiftWithTraba && isFirstShiftWithCompany && (
        <Tooltip title="Worker's first shift with this business">
          <span role="img" aria-label="First shift with this business">
            👶
          </span>
        </Tooltip>
      )}
      {isFavoriteWorker && (
        <Tooltip title="Favorite worker for this business">
          <span role="img" aria-label="Favorite worker for this business">
            ❤️
          </span>
        </Tooltip>
      )}
      {isPaidBackup && (
        <Tooltip title="Worker is a paid backup">
          <span role="img" aria-label="Worker is a paid backup">
            💰
          </span>
        </Tooltip>
      )}
      {isVettedForRole && (
        <Tooltip title="Worker has a vetted connection for this role">
          <span
            role="img"
            aria-label="Worker has a vetted connection for this role"
          >
            ✅
          </span>
        </Tooltip>
      )}
      {maxLength ? truncateString(`${name}`, maxLength) : name}
    </Row>
  )
}
