import { useHotSettings } from '@traba/hooks'
import { Text, LocationsCountTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { COMPANY_WIDE_TEXT, RecordStatus, USER_ROLE_NAMES } from '@traba/types'
import { User } from '@traba/types'
import {
  assignedActiveLocationsForMember,
  isBizMemberCompanyWide,
} from '@traba/utils'
import { useCallback, useState } from 'react'
import { CopyTextIcon, Row } from 'src/components/base'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import Toggle from 'src/components/base/Toggle'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { truncateString } from 'src/utils/stringUtils'
import { EditCompanyMemberActionsMenu } from './EditCompanyMemberActionsMenu'

interface CompanyMembersTableProps {
  companyUsers: User[]
}

export default function CompanyMembersTable({
  companyUsers,
}: CompanyMembersTableProps) {
  const { hotSettings } = useHotSettings()
  const manageRoleSettings = usePermissions([
    UserRolePermission.ManageRoleSettings,
  ])

  const [showArchivedUsersFilter, setShowArchivedUsersFilter] = useState(false)
  const toggleShowArchivedUsersFilter = useCallback(() => {
    setShowArchivedUsersFilter((currentSetting) => !currentSetting)
  }, [])

  const membersHeaders: (DataTableHeader | string)[] = [
    {
      key: 'firstName',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'User ID',
      sortable: true,
    },
    {
      key: 'role',
      sortable: true,
      label: 'Role',
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      key: 'email',
      label: 'Email',
    },
    ...(hotSettings?.enableRegionalAccessPhase2
      ? [
          {
            key: 'locations',
            label: 'Locations',
          },
        ]
      : []),
    {
      key: 'smsConsent',
      label: 'Has SMS Consent',
    },
    {
      key: 'receiveShiftCodes',
      label: 'Receive Shift Codes',
    },
    {
      key: 'recordStatus',
      label: 'Status',
    },
    {
      key: 'manage',
      label: 'Manage',
    },
  ]

  // Only non-archived members have accounts and access to things like shift code permissions
  const members = companyUsers.filter(
    (user) =>
      !!user.role &&
      (showArchivedUsersFilter
        ? true
        : user.recordStatus !== RecordStatus.ARCHIVED),
  )
  const membersRows: TableRow[] = members.map((user) => {
    const {
      firstName,
      lastName,
      uid,
      role,
      phoneNumber,
      email,
      smsConsent,
      communicationPermissions,
      recordStatus,
    } = user
    const membersCells: TableCell[] = [
      {
        sortKey: firstName,
        renderFn: () => `${firstName} ${lastName}`,
      },
      {
        sortKey: uid,
        renderFn: () => (
          <>
            {truncateString(uid)}
            <CopyTextIcon textToCopy={uid} />
          </>
        ),
      },
      {
        renderFn: () => USER_ROLE_NAMES[role],
        sortKey: 'role',
      },
      {
        renderFn: () => phoneNumber,
      },
      {
        renderFn: () => email,
      },
      ...(hotSettings?.enableRegionalAccessPhase2
        ? [
            {
              renderFn: () => (
                <LocationsCountTooltip
                  overrideText={
                    isBizMemberCompanyWide(user) ? COMPANY_WIDE_TEXT : undefined
                  }
                  locations={assignedActiveLocationsForMember(user)}
                />
              ),
            },
          ]
        : []),
      {
        renderFn: () => (smsConsent?.agreedToSmsConsent ? 'Yes' : 'No'),
      },
      {
        renderFn: () =>
          communicationPermissions?.receiveAllShiftCodesSms ? 'Yes' : 'No',
      },
      {
        renderFn: () => recordStatus,
      },
      {
        renderFn: () => (
          <EditCompanyMemberActionsMenu
            member={user}
            disabled={!manageRoleSettings}
          />
        ),
      },
    ]
    return {
      key: user.uid,
      cells: membersCells,
    }
  })

  return (
    <>
      <Row justifyBetween fullWidth mb={theme.space.sm}>
        <Text variant="h5" mt={theme.space.xs}>
          Current Members
        </Text>
        <Toggle
          label={'Show Archived'}
          buttonState={showArchivedUsersFilter}
          runOnChange={toggleShowArchivedUsersFilter}
          containerStyle={{ width: 200, justifyContent: 'center' }}
        />
      </Row>
      <DataTable
        headers={membersHeaders}
        rows={membersRows}
        initialSortByColumnIndex={0} // sort by role name column
      />
    </>
  )
}
