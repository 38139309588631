export function ThumbsDown({
  color = '#F20D59',
  size = 16,
  fill = 'none',
}: {
  size: number
  color: string
  fill: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00941 13.0743L4.14049 8.35478C4.04767 8.12037 4 7.87053 4 7.61842L4 4C4 2.89543 4.89543 2 6 2L11.5739 2C12.437 2 13.2027 2.55365 13.4732 3.37325L14.1332 5.37324C14.5602 6.6672 13.5965 8 12.2339 8L10.8369 8C9.73229 8 8.83686 8.89543 8.83686 10L8.83686 12.5348C8.83686 13.344 8.18087 14 7.37167 14C6.77071 14 6.23068 13.633 6.00941 13.0743Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M2 3V7" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
