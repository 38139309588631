import {
  WorkerTimecard,
  WorkerTimecardClockOutAdjustmentReason,
  WorkerTimecardStatus,
} from '@traba/types'
import { W2WorkerDisputeRowContent } from './W2WorkerDisputeRowContent'

interface W2WorkerHourDisputeRowProps {
  timecard: WorkerTimecard
  finalTime: Date | undefined
  setFinalTime: (finalTime: Date) => void
}

export function W2WorkerClockOutDisputeRow(props: W2WorkerHourDisputeRowProps) {
  const { timecard, finalTime, setFinalTime } = props
  const {
    workerClockOutAdjustmentReason,
    bizAdjustmentReason,
    workerClockOutAdjustmentOtherReason,
    bizClockOutTime,
    workerClockOutTime,
    workerShift,
    opsClockOutTime,
    status,
    finalizedReason,
  } = timecard
  const workerReason =
    workerClockOutAdjustmentReason ===
    WorkerTimecardClockOutAdjustmentReason.OTHER
      ? workerClockOutAdjustmentOtherReason
      : workerClockOutAdjustmentReason
  const hasChanged = bizClockOutTime !== workerClockOutTime
  const finalizedTime = opsClockOutTime ?? workerClockOutTime ?? bizClockOutTime
  const noActionAllowed =
    status === WorkerTimecardStatus.FINALIZED ||
    status === WorkerTimecardStatus.PENDING_WORKER

  return (
    <W2WorkerDisputeRowContent
      bizTime={bizClockOutTime}
      workerTime={workerClockOutTime}
      finalizedReason={finalizedReason}
      bizAdjustmentReason={bizAdjustmentReason}
      workerReason={workerReason}
      hasChanged={hasChanged}
      noActionAllowed={noActionAllowed}
      finalTime={finalTime}
      setFinalTime={setFinalTime}
      timeZone={workerShift.timeZone}
      finalizedTime={finalizedTime}
    />
  )
}
