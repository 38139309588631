import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Shift } from '@traba/types'
import { getEarlyArrivalTimeBufferInMinutes } from '@traba/utils'
import { Col } from 'src/components/base'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { EarlyArrivalBufferBadge } from './CollapsibleShiftRow/components/EarlyArrivalBufferBadge'

export function WorkerShiftDateCell({
  workerShift,
}: {
  workerShift: WorkerShift
}) {
  const { timezone, startTime } = workerShift.shiftInfo
  const tz = useTimezonedDates(timezone)
  const timezonedStartDate = tz.getDate(startTime, false)
  return timezonedStartDate
}

export function WorkerShiftStartTimeCell({
  workerShift,
}: {
  workerShift: WorkerShift
}) {
  const { timezone, startTime } = workerShift.shiftInfo
  const tz = useTimezonedDates(timezone)
  const timezonedStartTime = tz.getTime(startTime)
  return timezonedStartTime
}

export function ShiftDateCell({ shift }: { shift: Shift }) {
  const tz = useTimezonedDates(shift.timezone)
  const date = tz.getDate(shift.startTime, false)
  return date
}

export function ShiftTimesCell({ shift }: { shift: Shift }) {
  const tz = useTimezonedDates(shift.timezone)
  const startTime = tz.getTime(shift.startTime, false)
  const endTime = tz.getTime(shift.endTime)
  const earlyArrivalBuffer = getEarlyArrivalTimeBufferInMinutes({
    businessStartTime: shift.businessStartTime,
    shiftStartTime: shift.startTime,
  })
  return (
    <Col>
      <Text>
        {startTime} - {endTime}
      </Text>
      {!!earlyArrivalBuffer && (
        <EarlyArrivalBufferBadge
          buffer={earlyArrivalBuffer}
          style={{ paddingTop: theme.space.xxxs }}
        />
      )}
    </Col>
  )
}
