import { GridColDef } from '@mui/x-data-grid'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Anchor, Row } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { PaidBackup, SentinelNotification, Worker } from '@traba/types'
import { useMemo } from 'react'
import PaidBackupTableJobStatusCell from 'src/components/WorkerTable/components/PaidBackupTableJobStatusCell'
import PaidBackupConfirmationIcon from 'src/screens/ShiftDetailsScreen/components/PaidBackupsTable/PaidBackupConfirmationIcon'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { useCompanyConnections } from '../../../hooks/useConnections'
import { useShiftInvitations } from '../../../hooks/useShiftInvitations'
import { CopyTextIcon } from '../../base'
import WorkerReliabilityScore from '../../WorkerTable/components/WorkerReliabilityScore'
import { SentinelWorkerNameCell } from './SentinelWorkerNameCell'
import { SentinelTableDataGridStyle } from './styles'

const makePaidBackupDetailsColumns = (notification: SentinelNotification) => {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      width: 150,
      minWidth: 150,
      renderCell: (params) => <SentinelWorkerNameCell params={params} />,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        return (
          <PaidBackupTableJobStatusCell
            paidBackup={params.value}
            timezone={notification.data.shift.timezone}
          />
        )
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      width: 130,
      minWidth: 130,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(truncateString(params.value, 8))}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'reliability',
      headerName: 'Rel',
      width: 80,
      renderCell: (params) => (
        <WorkerReliabilityScore workerId={params.row.workerId} />
      ),
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      width: 50,
      renderCell: (params) => (
        <>
          {truncateString(params.value, 4)}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'confirmation',
      headerName: 'Conf',
      width: 80,
      renderCell: (params) => (
        <PaidBackupConfirmationIcon
          paidBackup={params.value}
          timezone={notification.data.shift.timezone}
        />
      ),
    },
  ]

  return columns
}

const makePaidBackupDetailsRow = (
  paidBackup: PaidBackup,
  isInvitedWorker = false,
  isFavoriteWorker = false,
) => {
  return {
    id: paidBackup.id,
    workerId: paidBackup.workerId,
    workerName: `${paidBackup.worker?.firstName} ${paidBackup.worker?.lastName}`,
    phoneNumber: paidBackup.worker?.phoneNumber,
    confirmation: paidBackup,
    status: paidBackup,
    isInvitedWorker,
    isFavoriteWorker,
  }
}

export const SentinelPaidBackupDetailsTable = ({
  paidBackups,
  handleSelectPaidBackups,
  notification,
  slim,
}: {
  paidBackups: PaidBackup[]
  handleSelectPaidBackups: (paidBackupsSelected: GridRowSelectionModel) => void
  notification: SentinelNotification
  slim?: boolean
}) => {
  const { shift } = notification.data
  const { shiftInvitations, isLoading: isLoadingShiftInvitations } =
    useShiftInvitations(shift.shiftId)
  const { favoriteWorkers, isLoading: isLoadingFavorites } =
    useCompanyConnections(shift.shiftId)

  const columns = useMemo(() => {
    return makePaidBackupDetailsColumns(notification)
  }, [notification])
  const rows = useMemo(() => {
    const favoriteWorkerIds = new Set(
      favoriteWorkers.map((worker: Worker) => worker.uid),
    )
    const invitationsIdSet = new Set(
      shiftInvitations?.map((i) => i.worker.id) || [],
    )
    return paidBackups.map((paidBackup) => {
      const targetWorkerId = paidBackup.workerId
      const isInvitedWorker =
        !!targetWorkerId && invitationsIdSet.has(targetWorkerId)
      const isFavoriteWorker =
        !!targetWorkerId && favoriteWorkerIds.has(targetWorkerId)
      return makePaidBackupDetailsRow(
        { ...paidBackup },
        isInvitedWorker,
        isFavoriteWorker,
      )
    })
  }, [paidBackups, shiftInvitations, favoriteWorkers])

  return (
    <Row
      style={{
        height: slim ? 'auto' : 300,
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DataGrid
        {...SentinelTableDataGridStyle}
        rows={rows}
        columns={columns}
        localeText={{
          footerRowSelected: (count) =>
            `${count} paid backup${makePlural(count)} selected`,
        }}
        onRowSelectionModelChange={handleSelectPaidBackups}
        loading={isLoadingShiftInvitations || isLoadingFavorites}
      />
    </Row>
  )
}
