import { Toggle } from '@traba/react-components'
import { ApplicationRecordStatus } from '@traba/types'
import { useCallback } from 'react'
import {
  useApplicationForShiftRequest,
  useApplicationsMutations,
} from 'src/hooks/useApplications'

interface DisableApplicationToggleButtonProps {
  shiftRequestId: string
}

export function DisableApplicationToggleButton({
  shiftRequestId,
}: DisableApplicationToggleButtonProps) {
  const { application, isLoading } = useApplicationForShiftRequest({
    shiftRequestId,
  })

  const {
    updateApplicationRecordStatus,
    isUpdateApplicationRecordStatusLoading,
  } = useApplicationsMutations()

  const handleToggleApplicationEnabled = useCallback(() => {
    if (
      !isLoading &&
      !isUpdateApplicationRecordStatusLoading &&
      application?.id
    ) {
      const isApplicationStatusActive =
        application.recordStatus === ApplicationRecordStatus.ACTIVE
      let userHasConfirmed = false
      if (isApplicationStatusActive) {
        userHasConfirmed = window.confirm(
          'Are you sure you want to disable worker applications for all shifts for this role in this shift schedule?',
        )
      }

      if (!isApplicationStatusActive || userHasConfirmed) {
        const newStatus = isApplicationStatusActive
          ? ApplicationRecordStatus.DISABLED
          : ApplicationRecordStatus.ACTIVE
        updateApplicationRecordStatus({
          applicationId: application.id,
          recordStatus: newStatus,
        })
      }
    }
  }, [
    application?.id,
    application?.recordStatus,
    updateApplicationRecordStatus,
    isLoading,
    isUpdateApplicationRecordStatusLoading,
  ])

  if (!application?.id || isLoading) {
    return null
  }

  return (
    <Toggle
      disabled={isUpdateApplicationRecordStatusLoading}
      runOnChange={handleToggleApplicationEnabled}
      buttonState={application.recordStatus === ApplicationRecordStatus.ACTIVE}
      label={`${
        application.recordStatus === ApplicationRecordStatus.ACTIVE
          ? 'Application is ENABLED'
          : 'Application is DISABLED'
      }`}
    />
  )
}
