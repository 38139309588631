import {
  ApplicationItem,
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { useCallback } from 'react'
import { useApplicationItemsMutations } from 'src/hooks/useApplications'
import { ViewAndEditExistingApplicationItem } from './ViewAndEditExistingApplicationItem'

interface ViewAndEditExistingApplicationItemsListProps {
  applicationItems: ApplicationItem[]
  isEditable?: boolean
}

export const ViewAndEditExistingApplicationItemsList = ({
  applicationItems,
  isEditable,
}: ViewAndEditExistingApplicationItemsListProps) => {
  const { archiveApplicationItem, updateApplicationItemContent } =
    useApplicationItemsMutations()

  const handleArchiveApplicationItem = useCallback(
    (applicationItemId: string) => () => {
      archiveApplicationItem({ applicationItemId })
    },
    [archiveApplicationItem],
  )
  const allowArchiveItem = false // TODO(gail): update to true when we decide to support archive app step

  const handleUpdateApplicationItemContent = useCallback(
    (applicationItemId: string) =>
      (upsertedItem: UpsertApplicationItemRequestType) => {
        switch (upsertedItem.applicationItemType) {
          case ApplicationItemType.AI_VETTING_CALL:
            updateApplicationItemContent({
              applicationItemId,
              itemContent: upsertedItem.itemContent,
            })
            break
          default:
            updateApplicationItemContent({
              applicationItemId,
            })
            break
        }
      },
    [updateApplicationItemContent],
  )
  const allowEditItem = false // TODO(gail): update to true when we decide to support edit app item

  return (
    <>
      {applicationItems.map((applicationItem, index) => (
        <ViewAndEditExistingApplicationItem
          key={applicationItem.id}
          applicationItem={applicationItem}
          stepIndex={index + 1}
          isEditable={isEditable}
          onDeleteStep={
            allowArchiveItem && applicationItem.id
              ? handleArchiveApplicationItem(applicationItem.id)
              : undefined
          }
          onSaveStep={
            allowEditItem && applicationItem.id
              ? handleUpdateApplicationItemContent(applicationItem.id)
              : undefined
          }
        />
      ))}
    </>
  )
}
