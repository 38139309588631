import { Time } from '@internationalized/date'
import { TimeValue } from '@react-types/datepicker'
import { SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IMenuItem,
  JobStatus,
  ShiftTime,
  WorkerShiftAsTimesheetRow,
} from '@traba/types'
import { isAfter, isBefore } from 'date-fns'
import { useState, useEffect, useMemo, Dispatch, useCallback } from 'react'
import {
  Button,
  Col,
  CopyTextIcon,
  DataTable,
  DatePicker,
  Icon,
  Row,
  Select,
} from 'src/components/base'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import Toggle from 'src/components/base/Toggle'
import { parseDateString, parseTimeString } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { TableRow } from '../timesheet.types'
import {
  calculateHoursWorked,
  determineIconName,
  getStyle,
  renderTableCellValue,
  updateParsedData,
} from '../utils'

const headers = (useBreaks: boolean) => [
  '',
  { label: 'Worker Name', sortable: true },
  'Shift Name',
  { label: 'Clock In Date (New)', sortable: true },
  { label: 'Clock In Date (Old)', sortable: true },
  'Clock In Time (New)',
  'Clock In Time (Old)',
  'Clock Out Date (New)',
  'Clock Out Date (Old)',
  'Clock Out Time (New)',
  'Clock Out Time (Old)',
  'Break Time (New)',
  'Break Time (Old)',
  ...(useBreaks ? ['Breaks (New)', 'Breaks (Old)'] : []),
  'Hours Worked',
  'Pay Rate (New)',
  'Pay Rate (Old)',
  'Units Worked (New)',
  'Units Worked (Old)',
  'Min Paid Time (New)',
  'Min Paid Time (Old)',
  'Job Status (New)',
  'Job Status (Old)',
]

const JOB_STATUS_OPTIONS = [
  {
    label: 'Complete',
    value: JobStatus.Complete,
  },
  {
    label: 'No Show',
    value: JobStatus.NoShow,
  },
  {
    label: 'Abandoned',
    value: JobStatus.Abandoned,
  },
  {
    label: 'Canceled',
    value: JobStatus.Canceled,
  },
  {
    label: 'Rejected',
    value: JobStatus.Rejected,
  },
]

function getTableRows(
  tableRows: TableRow[],
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>,
  useBreaks: boolean,
) {
  return tableRows.map((row) => {
    const name = determineIconName(row, useBreaks)
    const clockInTime = parseTimeString(
      row.clockInTime.parsedWorkerShiftValue.toString(),
    )
    const clockOutTime = parseTimeString(
      row.clockOutTime.parsedWorkerShiftValue.toString(),
    )
    return {
      key: `${row.workerId.parsedWorkerShiftValue}_${row.shiftId.parsedWorkerShiftValue}`,
      cells: [
        {
          renderFn: () => {
            return (
              <Row justifyCenter>
                <Icon name={name} />
              </Row>
            )
          },
        },
        {
          sortKey: row.workerName.parsedWorkerShiftValue,
          renderFn: () => (
            <Row style={{ width: 180 }}>
              {renderTableCellValue(row.workerName.parsedWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row style={{ width: 200 }}>
              <Col>
                {`${row.shiftName.parsedWorkerShiftValue} (${truncateString(
                  row.shiftId.originalWorkerShiftValue.toString(),
                  8,
                )})`}
                <CopyTextIcon
                  textToCopy={row.shiftId.originalWorkerShiftValue.toString()}
                />
              </Col>
            </Row>
          ),
        },
        {
          sortKey: parseDateString(
            row.clockInDate.parsedWorkerShiftValue.toString(),
          )?.getTime(),
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInDate), width: 150 }}
            >
              <DatePicker
                showTimeFieldInPopover={false}
                setDate={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockInDate', val),
                  )
                }
                width={150}
                date={parseDateString(
                  row.clockInDate.parsedWorkerShiftValue.toString(),
                )}
                defaultTime={new Time(0, 0)}
                granularity="day"
              />
            </Row>
          ),
        },
        {
          sortKey: parseDateString(
            row.clockInDate.originalWorkerShiftValue.toString(),
          )?.getTime(),
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInDate, true), width: 80 }}
            >
              {renderTableCellValue(row.clockInDate.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInTime), width: 120 }}
            >
              <TimeField
                value={
                  clockInTime
                    ? new Time(clockInTime.getHours(), clockInTime.getMinutes())
                    : null
                }
                onChange={(val: TimeValue) => {
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockInTime', val),
                  )
                }}
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInTime, true), width: 80 }}
            >
              {renderTableCellValue(row.clockInTime.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutDate), width: 150 }}
            >
              <DatePicker
                showTimeFieldInPopover={false}
                setDate={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockOutDate', val),
                  )
                }
                width={150}
                date={parseDateString(
                  row.clockOutDate.parsedWorkerShiftValue.toString(),
                )}
                defaultTime={new Time(0, 0)}
                granularity="day"
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutDate, true), width: 80 }}
            >
              {renderTableCellValue(row.clockOutDate.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutTime), width: 120 }}
            >
              <TimeField
                value={
                  clockOutTime
                    ? new Time(
                        clockOutTime.getHours(),
                        clockOutTime.getMinutes(),
                      )
                    : null
                }
                onChange={(val: TimeValue) => {
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockOutTime', val),
                  )
                }}
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutTime, true), width: 80 }}
            >
              {renderTableCellValue(row.clockOutTime.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.breakTime)}>
              {useBreaks ? (
                renderTableCellValue(row.breakTime.parsedWorkerShiftValue)
              ) : (
                <NumberInput
                  width="80px"
                  value={Number(row.breakTime.parsedWorkerShiftValue)}
                  setValue={(val) =>
                    setParsedData((prev) =>
                      updateParsedData(prev, row, 'breakTime', val),
                    )
                  }
                />
              )}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.breakTime, true)}>
              {renderTableCellValue(row.breakTime.originalWorkerShiftValue)}
            </Row>
          ),
        },
        ...(useBreaks
          ? [
              {
                renderFn: () => (
                  <Col style={getStyle(row.breaks)}>
                    {(row.breaks.parsedWorkerShiftValue as ShiftTime[]).map(
                      ({ startTime, endTime }, index) => {
                        return (
                          <Row
                            key={index}
                            gap={theme.space.xxs}
                            style={{ margin: `${theme.space.xxs}px 0px` }}
                            justifyCenter
                          >
                            <TimeField
                              label={`breakIn_${index}`}
                              value={
                                startTime
                                  ? new Time(
                                      startTime.getHours(),
                                      startTime.getMinutes(),
                                    )
                                  : null
                              }
                              onChange={(val: TimeValue) => {
                                setParsedData((prev) =>
                                  updateParsedData(
                                    prev,
                                    row,
                                    `breakIn_${index}`,
                                    val,
                                    index,
                                  ),
                                )
                              }}
                            />
                            <TimeField
                              label={`breakOut_${index}`}
                              value={
                                endTime
                                  ? new Time(
                                      endTime.getHours(),
                                      endTime.getMinutes(),
                                    )
                                  : null
                              }
                              onChange={(val: TimeValue) => {
                                setParsedData((prev) =>
                                  updateParsedData(
                                    prev,
                                    row,
                                    `breakOut_${index}`,
                                    val,
                                    index,
                                  ),
                                )
                              }}
                            />
                          </Row>
                        )
                      },
                    )}
                  </Col>
                ),
              },
              {
                renderFn: () => (
                  <Col style={getStyle(row.breaks, true)}>
                    {(
                      renderTableCellValue(
                        row.breaks.originalWorkerShiftValue,
                      ) as string[]
                    ).map((range) => (
                      <p key={range}>{range}</p>
                    ))}
                  </Col>
                ),
              },
            ]
          : []),
        {
          renderFn: () => <Row justifyCenter>{calculateHoursWorked(row)}</Row>,
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.payRate)}>
              <NumberInput
                width="80px"
                isMoney
                value={Number(row.payRate.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'payRate', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.payRate, true)}>
              {renderTableCellValue(row.payRate.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.unitsWorked)}>
              <NumberInput
                width="80px"
                value={Number(row.unitsWorked.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'unitsWorked', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.unitsWorked, true)}>
              {renderTableCellValue(row.unitsWorked.originalWorkerShiftValue)}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.minimumPaidTime)}>
              <NumberInput
                width="80px"
                value={Number(row.minimumPaidTime.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'minimumPaidTime', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.minimumPaidTime, true)}>
              {renderTableCellValue(
                row.minimumPaidTime.originalWorkerShiftValue,
              )}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.jobStatus), width: 150 }}
            >
              <Select
                value={row.jobStatus.parsedWorkerShiftValue.toString()}
                menuItems={JOB_STATUS_OPTIONS}
                handleSelect={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'jobStatus', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row style={{ ...getStyle(row.jobStatus, true), width: 150 }}>
              {renderTableCellValue(row.jobStatus.originalWorkerShiftValue)}
            </Row>
          ),
        },
      ],
    }
  })
}

export default function TimesheetAdjustmentsTable({
  allTableRows,
  diffedTableRows,
  openModal,
  setParsedData,
  useBreaks,
  setUseBreaks,
}: {
  allTableRows: TableRow[]
  diffedTableRows: TableRow[]
  openModal: () => void
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
  useBreaks: boolean
  setUseBreaks: Dispatch<React.SetStateAction<boolean>>
}) {
  const [tableRows, setTableRows] = useState<TableRow[]>([])
  const [selectedJobStatuses, setSelectedJobStatuses] = useState<IMenuItem[]>(
    [],
  )
  const [afterDate, setAfterDate] = useState<Date | null>(null)
  const [beforeDate, setBeforeDate] = useState<Date | null>(null)
  const [showOnlyDiscrepancies, setShowOnlyDiscrepancies] =
    useState<boolean>(false)

  const toggleDiscrepancies = () => {
    if (showOnlyDiscrepancies) {
      setTableRows(allTableRows)
      setShowOnlyDiscrepancies(false)
    } else {
      setTableRows(diffedTableRows)
      setShowOnlyDiscrepancies(true)
    }
  }

  useEffect(() => {
    if (allTableRows.length || diffedTableRows.length) {
      setTableRows(showOnlyDiscrepancies ? diffedTableRows : allTableRows)
    }
  }, [showOnlyDiscrepancies, allTableRows, diffedTableRows])

  const filteredRows = useMemo(() => {
    return tableRows.filter((row) => {
      const isStatusSelected =
        selectedJobStatuses.length === 0 ||
        selectedJobStatuses.some(
          (status) =>
            row.jobStatus.parsedWorkerShiftValue === status.value ||
            row.jobStatus.originalWorkerShiftValue === status.value,
        )

      const isAfterDate =
        afterDate === null ||
        isAfter(
          new Date(
            `${row.clockInDate.parsedWorkerShiftValue} ${row.clockInTime.parsedWorkerShiftValue}`,
          ),
          afterDate,
        )

      const isBeforeDate =
        beforeDate === null ||
        isBefore(
          new Date(
            `${row.clockOutDate.parsedWorkerShiftValue} ${row.clockOutTime.parsedWorkerShiftValue}`,
          ),
          beforeDate,
        )

      return isStatusSelected && isAfterDate && isBeforeDate
    })
  }, [tableRows, selectedJobStatuses, afterDate, beforeDate])

  const rows = useMemo(
    () => getTableRows(filteredRows, setParsedData, useBreaks),
    [filteredRows, setParsedData, useBreaks],
  )

  const toggleUseBreaks = useCallback(() => {
    setUseBreaks((prev) => !prev)
  }, [setUseBreaks])

  const jobStatusOptions = useMemo(() => {
    return Object.values(JobStatus).map<IMenuItem>((value) => ({
      value,
      label: value,
    }))
  }, [])

  const handleJobStatusSelect = useCallback(
    (value: IMenuItem[]) => {
      setSelectedJobStatuses(value)
    },
    [setSelectedJobStatuses],
  )

  const onChangeAfterDate = useCallback(
    (date: Date | null) => {
      setAfterDate(date)
    },
    [setAfterDate],
  )

  const onChangeBeforeDate = useCallback(
    (date: Date | null) => {
      setBeforeDate(date)
    },
    [setBeforeDate],
  )

  return (
    <Col>
      <Row alignCenter justifyBetween my={theme.space.xs}>
        <Row gap={theme.space.xxs}>
          <Toggle
            label="Show only rows with discrepancies"
            buttonState={showOnlyDiscrepancies}
            runOnChange={toggleDiscrepancies}
          />
          <Toggle
            label="Use breaks to calculate break time"
            buttonState={useBreaks}
            runOnChange={toggleUseBreaks}
          />
          <SearchSelect
            label="Job Status"
            width="200px"
            options={jobStatusOptions}
            selectedItems={selectedJobStatuses}
            handleSelectMultiple={handleJobStatusSelect}
            multiple
          />
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeAfterDate}
            isClearable={true}
            inlineLabel={true}
            label="After"
            width={280}
            date={afterDate}
          />
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeBeforeDate}
            isClearable={true}
            inlineLabel={true}
            label="Before"
            width={280}
            date={beforeDate}
          />
        </Row>
        <Button disabled={!diffedTableRows.length} onClick={openModal}>
          Continue
        </Button>
      </Row>
      <DataTable headers={headers(useBreaks)} rows={rows} />
    </Col>
  )
}
