import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'

export const FloatingActionBarContainer = styled(Row).attrs({
  justifyCenter: true,
})`
  position: fixed;
  bottom: ${theme.space.xxl}px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  pointer-events: none;
`

export const FloatingActionBarContent = styled(Row).attrs({
  gap: theme.space.sm,
})`
  background-color: ${theme.colors.White};
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;
  border-radius: ${theme.space.xl}px;
  box-shadow: 0px 8px 24px 0px #00000040;
  align-items: center;
  pointer-events: auto;
`
