import { Collapse, List, ListItem, Menu, MenuItem } from '@mui/material'
import { Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarIconName } from 'src/assets/svg/icons'
import { Icon } from 'src/components/base'
import * as S from './Sidebar.styles'

type DropdownOption = {
  onClick?: (event: any) => Promise<void> | void
  label: string
  route: string
  icon?: SidebarIconName
  hidden?: boolean
  isActive?: boolean
}

export const NavigationDropdown = ({
  label,
  items,
  isSidebarCollapsed,
  icon,
  isActive,
}: {
  label: string
  items: DropdownOption[]
  isSidebarCollapsed: boolean
  icon?: SidebarIconName
  isActive?: boolean
}) => {
  const [open, setOpen] = useState(true) // Initially expanded
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleMenu = () => {
    setOpen((prev) => !prev) // Toggle the dropdown menu
  }

  return (
    <div>
      {/* Main Menu Title with Icon */}
      <S.LinkWrapper
        key={label}
        onClick={isSidebarCollapsed ? handleClick : toggleMenu}
      >
        <S.Link
          className="sidebar-link"
          to=""
          $isActive={isActive}
          onClick={(ev) => ev.preventDefault()}
        >
          {icon && (
            <Icon
              name={`${icon}${isActive ? '_active' : ''}`}
              style={{ marginRight: theme.space.xxs }}
              width={12}
            />
          )}
          {!isSidebarCollapsed && (
            <Row alignCenter justifyBetween>
              <Text style={{ color: theme.TextButton, fontWeight: 600 }}>
                {label}
              </Text>
              <SvgIcon
                name={open ? 'chevronUp' : 'chevronDown'}
                size={12}
                style={{
                  marginLeft: theme.space.xxs,
                }}
              />
            </Row>
          )}
        </S.Link>
      </S.LinkWrapper>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items?.map((item) => (
          <MenuItem
            component={Link}
            to={item.route}
            onClick={handleClose}
            key={item.route}
          >
            {item.icon && (
              <Icon
                name={item.icon}
                style={{ marginRight: theme.space.xxs }}
                width={12}
              />
            )}
            <Text
              variant="caption"
              style={{
                fontSize: 14,
                color: location.pathname.startsWith(item.route)
                  ? theme.colors.MidnightBlue
                  : theme.TextButton,
              }}
              my={theme.space.xxs}
            >
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
      {!isSidebarCollapsed && (
        <Collapse in={open}>
          <List>
            {items?.map((item) => {
              const isSelected = location.pathname.startsWith(item.route)

              return (
                <ListItem
                  component={Link}
                  to={item.route}
                  key={item.route}
                  onClick={() => setOpen(false)}
                  style={{
                    marginLeft: theme.space.xs,
                    backgroundColor: isSelected
                      ? theme.colors.White
                      : 'transparent',
                  }}
                >
                  {item.icon && (
                    <Icon
                      name={item.icon}
                      style={{ marginRight: theme.space.xxs }}
                      width={12}
                    />
                  )}
                  <Text
                    variant="h7"
                    style={{
                      color: isSelected
                        ? theme.colors.MidnightBlue
                        : theme.TextButton,
                    }}
                    my={theme.space.xxs}
                  >
                    {item.label}
                  </Text>
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </div>
  )
}
