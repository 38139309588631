import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BackgroundCheckStatus,
  DetailedBackgroundCheckStatus,
  IconName,
} from '@traba/types'
import { Row } from 'src/components/base'
import { Dropdown } from 'src/components/base/Dropdown'

type BackgroundCheckStatusIndicatorProps = {
  backgroundCheckStatus: DetailedBackgroundCheckStatus
  hasActiveAction?: boolean
  onUpdateAction?: (hasActiveAction: boolean) => void
  isLoading?: boolean
}

const statusConfig = {
  [DetailedBackgroundCheckStatus.COMPLETE]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [DetailedBackgroundCheckStatus.CLEAR]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [DetailedBackgroundCheckStatus.CONSIDER]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Consider',
  },
  [DetailedBackgroundCheckStatus.REPORT_CREATED]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [DetailedBackgroundCheckStatus.PENDING]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.INVITATION_CREATED]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.SUSPENDED]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Suspended',
  },
  [DetailedBackgroundCheckStatus.CANCELED]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Canceled',
  },
  [DetailedBackgroundCheckStatus.DISPUTE]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Dispute',
  },
  [DetailedBackgroundCheckStatus.REVIEW]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Review',
  },
  [DetailedBackgroundCheckStatus.ESCALATED]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Escalated',
  },
  [DetailedBackgroundCheckStatus.PRE_ADVERSE_ACTION]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Pre Adverse',
  },
  [DetailedBackgroundCheckStatus.POST_ADVERSE_ACTION]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Post Adverse',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: '',
  },
  ACTIVE_ACTION: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Pushed',
  },
}

export function BackgroundCheckStatusIndicator({
  backgroundCheckStatus,
  hasActiveAction = false,
  onUpdateAction,
  isLoading = false,
}: BackgroundCheckStatusIndicatorProps) {
  const config = hasActiveAction
    ? statusConfig.ACTIVE_ACTION
    : statusConfig[backgroundCheckStatus] ||
      statusConfig[BackgroundCheckStatus.PENDING]

  const isPushDisabled =
    backgroundCheckStatus !== DetailedBackgroundCheckStatus.PENDING &&
    backgroundCheckStatus !== DetailedBackgroundCheckStatus.INVITATION_CREATED

  const dropdownOptions = onUpdateAction
    ? [
        {
          label: hasActiveAction ? 'Lift Action' : 'Push in App',
          handleClick: () => onUpdateAction(hasActiveAction),
          disabled: (!hasActiveAction && isPushDisabled) || isLoading,
          icon: () =>
            hasActiveAction ? (
              <SvgIcon name="unblock" />
            ) : (
              <SvgIcon name="notification" />
            ),
        },
      ]
    : []

  return (
    <Row style={{ gap: theme.space.xxs, alignItems: 'center' }}>
      <SvgIcon name={config.iconName} size={24} color={config.color} />
      {config.text && onUpdateAction ? (
        <Dropdown
          orientation="right"
          dropdownOptions={dropdownOptions}
          listContainerStyling={{ width: '175px' }}
          listItemStyling={{
            alignItems: 'center',
            color: theme.colors.Violet80,
            fontWeight: '500',
            fontSize: 14,
          }}
          buttonStyling={{
            padding: 0,
            color: theme.colors.MidnightBlue,
            fontSize: 14,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            minWidth: 'fit-content',
          }}
          title={config.text}
          containerStyling={{ width: 'auto' }}
        />
      ) : (
        config.text && <Text variant="h6">{config.text}</Text>
      )}
    </Row>
  )
}
