import { trabaApi } from '@traba/api-utils'
import { WorkerScores } from '@traba/types'
import axios from 'axios'
import { useQuery } from 'react-query'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

async function getWorkerScores(
  workerId: string,
): Promise<WorkerScores | undefined> {
  try {
    const res = await trabaApi.get(`workers/${workerId}/scores`)
    return res.data
  } catch (error) {
    console.error('useWorkerMetrics -> getWorkerMetrics() ERROR', error)
  }
}

export async function regenerateWorkerMetrics(workerId: string) {
  try {
    const baseUrl = import.meta.env.VITE_FIREBASE_FUNCTIONS_URL
    if (!baseUrl) {
      throw new Error('Functions URL is not set')
    }
    return axios.post(`${baseUrl}/generateWorkerMetrics`, {
      data: { workerId },
    })
  } catch (error) {
    console.error('useWorkerMetrics -> regenerateWorkerMetrics() ERROR', error)
  }
}

export function useWorkerScores(workerId?: string) {
  const {
    isLoading,
    isError,
    data: workerScores,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerScores | undefined, Error>(
    `workerScores_${workerId}`,
    () => (workerId ? getWorkerScores(workerId) : undefined),
    {
      cacheTime: FIVE_MINUTES_IN_MS,
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    workerScores,
    error,
    isFetched,
    refetch,
  }
}
