import { Anchor, Text } from '@traba/react-components'
import { User } from '@traba/types'
import { CopyTextIcon, Row } from 'src/components/base'
import { formatPhoneNumber } from 'src/utils/stringUtils'

export const SupervisorInfo = ({ supervisor }: { supervisor?: User }) => {
  if (!supervisor) {
    return null
  }
  return (
    <div>
      <Row flexCol>
        <Row>
          <Text>
            {supervisor.firstName} {supervisor.lastName}
          </Text>
        </Row>
        <Row>
          <Anchor
            openInNewTab={false}
            style={{ fontSize: 12 }}
            href={`tel:${supervisor.phoneNumber}`}
          >
            {formatPhoneNumber(supervisor.phoneNumber || '', true)}
          </Anchor>
          <CopyTextIcon textToCopy={supervisor.phoneNumber} />
        </Row>
        <Row>
          <Text variant="caption">{supervisor.uid}</Text>
          <CopyTextIcon textToCopy={supervisor.uid} />
        </Row>
        <Row>
          <Text variant="caption">{supervisor.email}</Text>
          <CopyTextIcon textToCopy={supervisor.email} />
        </Row>
      </Row>
    </div>
  )
}
