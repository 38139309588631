import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftIdRow } from './ShiftIdRow'

type ShiftCategoryProps = {
  title: string
  shiftIds: string[]
}

export function ShiftCategorySection({ title, shiftIds }: ShiftCategoryProps) {
  if (shiftIds.length === 0) {
    return null
  }

  return (
    <Col>
      <Text variant="h6" mb={theme.space.xxs}>
        {title}
      </Text>
      {shiftIds.map((shiftId) => (
        <ShiftIdRow key={shiftId} shiftId={shiftId} />
      ))}
    </Col>
  )
}
