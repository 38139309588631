import {
  ActionableRequirement,
  EmploymentType,
  ForwardFillMax,
  ForwardFillRenotificationMethod,
  InvitedWorkers,
  PaymentType,
  RequiredAttributeLevel,
  RoleAttributeCategory,
  ShiftBadge,
  ShiftNotificationStatus,
  ShiftRequestParent,
  ShiftSignupStatus,
  ShiftStatus,
  ShiftTag,
  SlimCharge,
  WorkerShiftWithWorkerAndCharges,
} from '../index'
import { BGCRequirement } from './account-status'
import { BackfillSettingsDto } from './backfill'
import { GenderPreference, RequiredMultiShiftType } from './base'
import { BreakType, Company, Role, WorkerMedia } from './companies'
import { Incentive } from './incentive'
import { InvoiceChargeCycle, InvoiceStatus } from './invoice'
import { Address, Locations } from './locations'
import { Money } from './money'
import { Schedule } from './schedules'
import { ShiftAttribute } from './shift-attributes'
import {
  ScheduleShiftRequestInvitation,
  ShiftInvitationsDto,
} from './shift-invitations'
import { User } from './users'
import { TierLevel } from './worker-tiers'
import { BasicOpsWorkerDetails } from './workers'

export enum KnownCancellationReasons {
  AccountStatus = 'ACCOUNT_STATUS_CHANGED',
  Blocked = 'BLOCKED_CONNECTION',
  Ineligible = 'INELIGIBLE_CONNECTION',
  Conflicting = 'CONFLICTING',
  InvalidAttributes = 'INVALID_ATTRIBUTES',
}

export enum BusinessCancellationReasons {
  WORKERS_NO_LONGER_NEEDED = 'Workers No Longer Needed',
  CREATED_BY_MISTAKE = 'Created By Mistake',
  WEATHER = 'Weather',
  OTHER = 'Other',
}

export enum CancellationSource {
  Business = 'BUSINESS',
  Ops = 'OPS',
  System = 'SYSTEM',
  Worker = 'WORKER',
}

export enum ShiftRequestEditType {
  SINGLE = 'SINGLE',
  ALL_FUTURE = 'ALL_FUTURE',
}

export interface RemoveWorkersFromShift {
  shouldAutoRemoveWorkers?: boolean
  workerIdsToRemove?: string[]
}

export type WorkerCancellationCost = {
  totalPaidTime?: number
  grossPay?: Money
  totalTrustAndSafetyFee?: Money
  netPay?: Money
  workerId: string
  shiftId: string
  jobStatus: string
  calculatedMarkup: number
  shiftInfo: ShiftInfo
  totalCharge?: Money
  totalChargedTime?: number
}

export enum CancellationBusinessChargeType {
  Percentage = 'PERCENTAGE',
  Hourly = 'HOURLY',
}

export enum ShiftInvoicedStatus {
  INVOICED = 'INVOICED',
  PARTIALLY_INVOICED = 'PARTIALLY_INVOICED',
  NOT_INVOICED = 'NOT_INVOICED',
}

export class CancellationSettings {
  cancellationBusinessTimeWindow?: number
  cancellationBusinessChargeType?: CancellationBusinessChargeType
  shouldWaiveCancellationFees?: boolean
}

export type ShiftCancellationCostResponse = {
  cancellations: WorkerCancellationCost[]
  calculatedMarkup: number
  shiftId: string
  cancellationPercentCharge?: number
  cancellationSettings: CancellationSettings
  cancellationChargeSummary: string
}

export interface DailyShiftStatsResponse {
  totalShifts: number
  scheduledWorkers: number
  clockedInWorkers: number
  clockedOutWorkers: number
}

export const DEFAULT_RELIABILITY_THRESHOLD = 0.75
export const ELEVATED_RELIABILITY_THRESHOLD = 0.9
export const DEFAULT_UNPROVEN_WORKER_THRESHOLD = 0.25
export const DEFAULT_UNPROVEN_TODO_LIMIT = 2
export const DEFAULT_NEW_COMPANY_SHIFT_LIMIT = 8

export interface Shift {
  id: string
  shiftId: string
  shiftRequestId: string
  companyId: string
  roleId: string
  locationId: string
  parkingLocationId?: string
  opsLocationDetailsOverride?: string
  geohash: string
  timezone: string
  regionId: string
  supervisorId: string
  allShiftsRequired?: boolean
  favoriteWorkersFirst?: boolean
  employerName: string
  startTime: Date
  originalStartTime: Date
  businessStartTime?: Date | null
  endTime: Date
  scheduledBreaks: Array<ScheduledBreak>
  slotsFilled: number
  slotsRequested: number
  minSlotsRequested: number
  invitedWorkers: InvitedWorkers
  trustAndSafetyFeeHourly: Money
  canceledLate?: boolean
  confirmationCode: number
  clockOutCode: number
  workerMedia?: WorkerMedia[]
  createdAt: Date
  shiftRole: string
  roleDescription: string
  attireDescription: string
  shortLocation?: string
  tags?: ShiftTag[]
  hasPaidBreaks?: boolean // legacy
  breakType: BreakType
  timesheetApprovalStatus?: {
    isApproved: boolean
    approvedAt: Date
    isPendingEdit: boolean
    timesheetId: string
  }
  companyLogo?: string
  calculatedMarkup: number
  bonusTimeCalculatedMarkup?: number | null
  isHandpicked?: boolean
  isInRequiredMultiShift?: boolean
  minimumPaidTime?: number
  status: ShiftStatus
  canceledAt?: Date
  cancellationSource?: Exclude<CancellationSource, CancellationSource.Worker>
  cancellationReason?: string
  cancellationType?: CancellationType
  cancellationPercentCharge?: number
  requiredCertifications?: Role['requiredCertifications']
  requiredAttributes?: ShiftAttribute[] | string[]
  requiredAttributeLevels?: RequiredAttributeLevel[]
  enableDynamicOverbooking?: boolean
  overbookSlotsRequested?: number
  paidBackupSlotsRequested?: number
  paidBackupPayAmount?: number
  paidBackupSlotsFilled?: number
  // ops only
  signupStatus?: ShiftSignupStatus
  activeRoleId?: string
  // ops only
  notificationStatus?: ShiftNotificationStatus
  invoiceChargeCycle?: InvoiceChargeCycle
  invoiceGroupId?: string
  parentInvoiceGroupId?: string
  invoiceGroupName?: string
  eventName?: string
  backfillSettings: BackfillSettingsDto
  waitlistOnly?: boolean
  reliabilityThreshold?: number // Shift acceptance override
  unprovenWorkerThreshold?: number // Shift acceptance override
  isBackfillActive?: boolean
  reliabilityAverage?: number | null
  unprovenWorkerCount?: number
  invoiceStatus?: InvoiceStatus
  invoiceId?: string
  updatedAt?: Date
  paymentType?: PaymentType
  resumeUploadRequired?: boolean
  minimumAcceptedTier?: TierLevel
  minimumAgeRequirement?: number | null
  additionalEmails?: string[]
  forwardFillMax?: ForwardFillMax
  forwardFillRenotificationMethod?: ForwardFillRenotificationMethod
  invoiceRequiresManualAdjustment?: boolean
  hasPreShiftConfirmationRobocall?: boolean
  shiftDetailsDeeplink?: string
  extraBGCRequirement: BGCRequirement
  showAddressToNonWorkersForShift?: boolean
  showNameToNonWorkersForShift?: boolean
  role?: Role
  company?: Company
  canBypassTrainingShift?: boolean
  videoIds?: string[]
  requiredTrainingIds?: string[]
  payRate: number
  payType: ShiftPayType
  numberOfUnits?: number
  shiftCodesReceiverIds?: string[]
  featuredStatus?: ShiftFeaturedStatus
  genderPreference?: GenderPreference | null
  pluckFromRequiredMultiShift: boolean
  requireW9Authorization: boolean
  shiftBadges?: ShiftBadge[]
  shiftRequestParentId?: string
  schedules?: Schedule[]
  shiftRequestParent?: ShiftRequestParent
  shiftCreatorId?: string
  shiftEmploymentType?: EmploymentType
}

export type ShiftWithWorkerShiftsAndCharges = Shift & {
  workerShifts: WorkerShiftWithWorkerAndCharges[]
}

export interface UpdateShift extends Partial<Shift> {
  editType?: ShiftRequestEditType
  removeWorkersFromShift?: RemoveWorkersFromShift
}

export interface ClientShift extends Shift {
  hideAnnouncements?: boolean
  hideInvitations?: boolean
  editInfinite?: boolean
}

export enum ShiftFeaturedStatus {
  NOT_FEATURED = 'NOT_FEATURED',
  FEATURED = 'FEATURED',
}

export enum ShiftPayType {
  HOURLY = 'HOURLY',
  UNIT = 'UNIT',
}

export interface ShiftInfo {
  companyId: string
  timezone: string
  employerName: string
  startTime: Date
  endTime: Date
  scheduledBreaks: Array<ScheduledBreak>
  trustAndSafetyFeeHourly: Money
  payType: ShiftPayType
  payRate: number
  shiftRole: string
  shortLocation?: string
  breakType: BreakType
  minimumPaidTime?: number
  requiredCertifications?: Role['requiredCertifications']
  requiredAttributes?: ShiftAttribute[]
  minimumPayByUnitPay: number
  slotsRequested: number
  slotsFilled: number
  numberOfUnits: number
  invitedWorkers: InvitedWorkers
  shiftEmploymentType?: EmploymentType
}

export type ShiftWithSlimCharges = Shift & {
  charges: SlimCharge[]
}

/** Extended shift used in GET response for explore shift*/
export type ExtendedShift = Shift & {
  company: Company
  location: Locations
  parkingLocation?: Locations
  incentives?: Incentive[]
  supervisor?: User
}

export interface SlimWorkerDetails {
  email: string
  firstName: string
  id: string
  lastName: string
  phoneNumber: string
  photoUrl: string
  zipCode?: string
}

export interface Waitlist {
  waitlistId: string
  companyId: string
  createdAt: Date
  endTime: Date
  shiftId: string
  startTime: Date
  status: string
  workerDetails: SlimWorkerDetails
  workerETA?: Date
  isBackfillWaitlist?: boolean
}

export interface AcceptShiftWaiver {
  requirement: ActionableRequirement
  expiryTime?: Date
  shiftsToWaiveFor?: number
  requestedReason: string
}

export type GenericAcceptShiftBypasses = Record<string, boolean>

export interface AcceptShiftBypasses {
  allowOverbook: boolean
  bypassSignupStatus: boolean
  bypassFavoritesOnly: boolean
  bypassFavoritesFirst: boolean
  bypassUnprovenThreshold: boolean
  bypassUnprovenTodoLimit: boolean
  bypassExtraBGCRequirement: boolean
  bypassAttributeRequirements: boolean
  bypassCertifications?: boolean
  bypassWorkerAvailability: boolean
  bypassAccountStatus?: boolean
  bypassMinimumAgeRequirement?: boolean
  bypassResumeUploadRequired?: boolean
  bypassMinimumAcceptedTier: boolean
  bypassShiftStatus: boolean
  bypassForwardFill?: boolean
  bypassRequiredMultiShift?: boolean
  bypassInvitations?: boolean
  bypassNoBackfill?: boolean
  bypassWorkerShiftIsNotBizCancelled?: boolean
  bypassWorkerShiftIsNotOpsCancelled?: boolean
  // SDM bypasses
  bypassRecurringWorkerIsAvailable?: boolean
  bypassRecurringSlotAvailable?: boolean
}

export type ScheduledBreak = {
  breakLength: number
  count: number
}

export interface geoQueryShiftsDto {
  radiusInMiles: number
  coords: number[]
}

export enum CancellationType {
  UNDER_TWELVE_HR = 'UNDER_TWELVE_HR',
  UNDER_TWENTY_FOUR_HR = 'UNDER_TWENTY_FOUR_HR',
  OVER_TWENTY_FOUR_HR = 'OVER_TWENTY_FOUR_HR',
  CUSTOM_CHARGE = 'CUSTOM_CHARGE',
}

export enum CancellationSummary {
  UNDER_TWELVE_HR = `This shift was canceled within 12 hours of its start time. In accordance with our terms and conditions, you will be charged 100% of the total cost of the shift for cancellation.`,
  UNDER_TWENTY_FOUR_HR = `This shift was canceled within 24 hours of its start time. In accordance with our terms and conditions, you will be charged 50% of the total cost of the shift for cancellation.`,
  OVER_TWENTY_FOUR_HR = `This shift was canceled more than a day before its start time. In accordance with our terms and conditions, {youWillNotBeChargedText} for the cancellation of this shift. \n\nRemember that canceling a shift 24 hours before it starts may incur additional costs.`,
}

export type ShiftRequestEdit = Omit<
  Partial<ShiftRequest>,
  'backfillSettings' | 'unprovenWorkerCount'
> & {
  shiftRequestEditId: string
  editType: ShiftRequestEditType
  shiftId: string
  originalStartTime: Date
  shiftRequestId: string
  backfillSettings?: Partial<BackfillSettingsDto>
  startTimeHoursUtc?: number
  startTimeMinutes?: number
  endTimeHoursUtc?: number
  endTimeMinutes?: number
  canBypassTrainingShift?: boolean
  cancellationSettings?: CancellationSettings
  cancellationPercentCharge?: number
}

export type ShiftRequest = {
  schedules: Schedule[]
  roleId: string
  locationId: string
  parkingLocationId?: string
  companyId: string
  shiftRequestId: string
  createdAt?: Date
  updatedAt?: Date
  supervisorId: string
  favoriteWorkersFirst: boolean
  allShiftsRequired: boolean
  scheduledBreaks: Array<ScheduledBreak>
  slotsRequested: number
  minSlotsRequested: number
  invitedWorkers: InvitedWorkers
  trustAndSafetyFeeHourly: Money
  workerMedia: WorkerMedia[]
  hasPaidBreaks?: boolean
  shiftRole: string
  roleDescription: string
  attireDescription: string
  requiredCertifications?: string[]
  requiredAttributes?: ShiftAttribute[]
  freeformAttributes?: Partial<Record<RoleAttributeCategory, string>>
  shortLocation: string // legacy
  employerName: string // legacy
  minimumPaidTime?: number
  status: ShiftStatus.CANCELED | ShiftStatus.ACTIVE
  invoiceChargeCycle: InvoiceChargeCycle
  invoiceGroupId?: string
  parentInvoiceGroupId?: string
  additionalEmails: string[]
  eventName?: string
  location?: Locations
  address?: Address
  forwardFillMax?: ForwardFillMax
  shiftInvitations?: ShiftInvitationsDto[]
  scheduleInvitations?: ScheduleShiftRequestInvitation[]
  videoIds?: string[]
  requiredTrainingIds?: string[]
  payRate: number
  payType: ShiftPayType
  numberOfUnits?: number
  shiftCodesReceiverIds?: string[]
  requiredMultiShiftType?: RequiredMultiShiftType
  genderPreference?: GenderPreference | null
  unprovenWorkerThreshold?: number
  timezone?: string
  shiftRequestParentId?: string
  breakType?: BreakType
  minimumAcceptedTier?: TierLevel
  requireW9Authorization?: boolean
  hourlyRate?: number
  unprovenWorkerCount?: number
  regionId?: string
  geohash?: string
  shiftCreatorId?: string
  slotsFilled?: number
  shiftRequestEdits?: ShiftRequestEdit[]
  shiftEmploymentType: EmploymentType
  requiredAttributeLevels?: RequiredAttributeLevel[]
  supervisorFirstName?: string
  supervisorLastName?: string
}

export enum ShiftFillPreset {
  Standard = 'STANDARD',
  RosterFirst = 'ROSTER_FIRST',
  RosterOnly = 'ROSTER_ONLY',
  HandPicked = 'HAND_PICKED',
}

export type WaitlistWithBasicWorkerOpsDetails = Omit<
  Waitlist,
  'workerDetails'
> & {
  workerDetails: BasicOpsWorkerDetails
}

export type ShiftAndAddress = Shift & {
  address: Address
  locationName?: string
}
