import { Badge } from '@traba/react-components'
import { WorkerApplicationItemCompletionStatus } from '@traba/types'

export type WorkerApplicationItemCompletionStatusBadgeProps = {
  itemCompletionStatus: WorkerApplicationItemCompletionStatus
  style?: React.CSSProperties
}

const APPLICATION_ITEM_COMPLETION_STATUS_TO_BADGE_VARIANT: Record<
  WorkerApplicationItemCompletionStatus,
  string
> = {
  [WorkerApplicationItemCompletionStatus.ELIGIBLE]: 'success',
  [WorkerApplicationItemCompletionStatus.INELIGIBLE]: 'disabled',
  [WorkerApplicationItemCompletionStatus.OPS_WAIVED]: 'info',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE]: 'success',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE]: 'danger',
  [WorkerApplicationItemCompletionStatus.INCONCLUSIVE]: 'certification',
  [WorkerApplicationItemCompletionStatus.EXPIRED]: 'darkRed',
}

const CUSTOM_STATUS_TO_TEXT: Record<
  WorkerApplicationItemCompletionStatus,
  string
> = {
  [WorkerApplicationItemCompletionStatus.ELIGIBLE]: 'Eligible',
  [WorkerApplicationItemCompletionStatus.INELIGIBLE]: 'Ineligible',
  [WorkerApplicationItemCompletionStatus.OPS_WAIVED]: 'Ops Waived',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE]: 'Ops Eligible',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE]:
    'Ops Ineligible',
  [WorkerApplicationItemCompletionStatus.INCONCLUSIVE]: 'Inconclusive',
  [WorkerApplicationItemCompletionStatus.EXPIRED]: 'Expired',
}

export default function WorkerApplicationItemCompletionStatusBadge({
  itemCompletionStatus,
  style,
}: WorkerApplicationItemCompletionStatusBadgeProps) {
  const badgeVariant =
    APPLICATION_ITEM_COMPLETION_STATUS_TO_BADGE_VARIANT[itemCompletionStatus]

  return (
    <Badge
      style={style}
      title={CUSTOM_STATUS_TO_TEXT[itemCompletionStatus]}
      variant={badgeVariant}
    />
  )
}
