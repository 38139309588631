import {
  ButtonVariant,
  Checkbox,
  DataTableHeader,
  Row,
  Td,
  Text,
  Tr,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useMemo, useState } from 'react'
import { CreditNote } from 'shared/types/src/lib/invoice'
import { Button, Modal } from 'src/components/base'
import { Table } from 'src/components/base/Table/Table'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useCreditNoteMutations } from 'src/hooks/useCreditNoteMutations'
import { getMoneyString } from 'src/utils/stringUtils'
import LineItem from '../../components/LineItem'

export type VoidCreditNoteModalProps = {
  isOpen: boolean
  handleClose: () => void
  creditNote: CreditNote
  refetch?: () => void
}

export default function VoidCreditNoteModal(props: VoidCreditNoteModalProps) {
  const { isOpen, handleClose, creditNote, refetch } = props
  const { lineItems } = creditNote

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(true)

  const { voidCreditNote, isLoading } = useCreditNoteMutations({
    creditNoteId: creditNote.creditNoteId,
  })

  const headers: DataTableHeader[] = [
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'totalCharge',
      label: 'Total Charge',
      sortable: false,
    },
  ]

  const { totalCredits, lineItemRows } = useMemo(() => {
    let totalCredits = 0
    const lineItemRows = creditNote.lineItems.map((lineItem, index) => {
      totalCredits += lineItem.chargeToBusiness.amount
      return (
        <LineItem
          key={lineItem.shiftId}
          index={index}
          lineItem={lineItem}
          lineItemsEditable={false}
        />
      )
    })

    return { totalCredits, lineItemRows }
  }, [props])

  const handleVoid = async () => {
    await voidCreditNote({ notifyCustomer, invoiceId: creditNote.invoiceId })
    handleClose()
    refetch?.()
  }

  const disabled = !lineItems || lineItems?.length < 1
  return (
    <Modal title={'Void Credit Note'} isOpen={isOpen} handleClose={handleClose}>
      <Table headers={headers}>
        {lineItemRows}
        <Tr>
          <Td
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              paddingRight: theme.space.xs,
            }}
          >
            Total Credits:
          </Td>
          <Td style={{ fontWeight: 'bold' }}>
            {getMoneyString(-(totalCredits / 100))}
          </Td>
        </Tr>
      </Table>
      <Row justifyBetween alignCenter p={theme.space.med}>
        <Checkbox
          checked={notifyCustomer}
          onChange={() => setNotifyCustomer((prev) => !prev)}
          label="Notify Customer"
          disabled={disabled}
        />

        <Button
          slim
          type="submit"
          variant={ButtonVariant.CANCEL}
          style={{ minWidth: '100px' }}
          onClick={() => setShowConfirmation(true)}
          disabled={disabled}
          loading={isLoading}
        >
          Void
        </Button>
      </Row>
      <ConfirmationDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleVoid}
        confirmLoading={isLoading}
        title="Void Credit Note"
        content={
          <Text>{`Are you sure you want to void this credit note of ${getMoneyString(-(totalCredits / 100))}? `}</Text>
        }
      />
    </Modal>
  )
}
