import {
  Col,
  DotMenu,
  Row,
  SvgIcon,
  Text,
  useModal,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BasicOpsWorkerDetails,
  ShiftInvitation,
  ShiftInvitationStatus,
  WorkerApplicationItem,
  WorkerApplicationItemStatus,
  WorkerApplicationStatus,
  WorkerApplicationWithWorkerInfo,
} from '@traba/types'
import {
  MRT_Table as MaterialReactTableNoToolbar,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'
import { AccountStatusBadge } from 'src/components/base'
import { WorkerApplicationStatusBadge } from 'src/components/base/Badge/WorkerApplicationStatusBadge'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatPhoneNumber, toPercentString } from 'src/utils/stringUtils'
import { PhoneLinkAndCopyButton } from '../PhoneLinkAndCopyButton'
import { TruncatedIdWithCopyButton } from '../TruncatedIdWithCopyButton'
import { WorkerNameWithLink } from '../WorkerNameWithLink'
import { AddWorkerToShiftsModalContainer } from './AddWorkerToShiftsModalContainer'
import { ApplicationItemsSectionContainer } from './ApplicationItemsSectionContainer'
import { DisableApplicationToggleButton } from './DisableApplicationToggleButton'
import { InviteToSingleShiftOrScheduleModal } from './InviteToSingleShiftOrScheduleModal'
import { ShiftInvitationStatusOrInviteButton } from './ShiftInvitationStatusOrInviteButton'
import { ViewApplicationDrawer } from './ViewApplicationDrawer'

interface ShiftApplicantsTableProps {
  applications: WorkerApplicationWithWorkerInfo[]
  timezone: string
  shiftInvitations: ShiftInvitation[]
  workerShiftWorkerIdsSet: Set<string>
  onRescindInvitation: (shiftInvitation: ShiftInvitation) => void
  isLoading?: boolean
  shift: OpsExtendedShift
}

const CELL_COMMON_STYLES = {
  padding: '4px',
  fontSize: '12px',
  fontWeight: '300',
  border: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  borderBottom: `1px solid ${theme.colors.Grey20}`,
}

const HEADER_CELL_STYLES = {
  ...CELL_COMMON_STYLES,
  color: theme.colors.MidnightBlue,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& *': {
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    fontSize: '10px',
  },
  paddingTop: '0px',
  paddingBottom: '0px',
  borderBottom: 'none',
}

const HEADER_ROW_STYLES = {
  paddingRight: '12px',
  paddingLeft: '12px',
  paddingTop: '6px',
  paddingBottom: '6px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  backgroundColor: theme.colors.Grey20,
  boxShadow: 'none',
}

export function ShiftApplicantsTable({
  applications,
  timezone,
  shiftInvitations,
  workerShiftWorkerIdsSet,
  onRescindInvitation,
  isLoading,
  shift,
}: ShiftApplicantsTableProps) {
  const data = useMemo(() => [...applications], [applications])
  const tz = useTimezonedDates(timezone)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [workerForInviteModal, setWorkerForInviteModal] = useState<
    { id: string; firstName: string; lastName: string } | undefined
  >(undefined)
  const onInviteModalOpen = useCallback(
    (worker: { id: string; firstName: string; lastName: string }) => () => {
      setWorkerForInviteModal(worker)
      setShowInviteModal(true)
    },
    [],
  )
  const onInviteModalClose = useCallback(() => {
    setWorkerForInviteModal(undefined)
    setShowInviteModal(false)
  }, [])
  const handleRescindInvitation = useCallback(
    (shiftInvitation?: ShiftInvitation) => () => {
      if (shiftInvitation) {
        onRescindInvitation(shiftInvitation)
      }
    },
    [onRescindInvitation],
  )

  const [selectedWorkerApplicationItemId, setSelectedWorkerApplicationItemId] =
    useState<string | null>(null)
  const [isApplicationDetailsDrawerOpen, setIsApplicationDetailsDrawerOpen] =
    useState(false)

  const onOpenApplicationDetailsDrawer = useCallback(() => {
    setSelectedWorkerApplicationItemId(null)
    setIsApplicationDetailsDrawerOpen(true)
  }, [])

  const onCloseApplicationDetailsDrawer = useCallback(() => {
    setIsApplicationDetailsDrawerOpen(false)
  }, [])

  const onSelectWorkerApplicationItem = useCallback(
    (workerApplicationItemId: string | null) => {
      setSelectedWorkerApplicationItemId(workerApplicationItemId)
      setIsApplicationDetailsDrawerOpen(false)
    },
    [],
  )

  const addWorkerToShiftsModal = useModal()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const handleAddToShifts = useCallback(
    (workerId: string) => () => {
      setSelectedRows([workerId])
      addWorkerToShiftsModal.open()
    },
    [addWorkerToShiftsModal],
  )

  const columns = useMemo<MRT_ColumnDef<WorkerApplicationWithWorkerInfo>[]>(
    () => [
      {
        id: 'workerName',
        header: 'WORKER NAME',
        accessorFn: (row) => row.workerInfo,
        size: 100,
        grow: 2,
        Cell: ({ cell }) => {
          const worker = cell.getValue<BasicOpsWorkerDetails>()
          return (
            <WorkerNameWithLink
              workerId={worker.id}
              workerFullName={`${worker.firstName} ${worker.lastName}`.trim()}
            />
          )
        },
      },
      {
        id: 'accountStatus',
        header: 'STATUS',
        accessorFn: (row) => row.workerInfo,
        size: 80,

        Cell: ({ cell }) => {
          const workerInfo = cell.getValue<BasicOpsWorkerDetails>()

          return workerInfo.accountApprovalStatus ? (
            <AccountStatusBadge
              accountStatus={workerInfo.accountApprovalStatus}
              workerId={workerInfo.id}
            />
          ) : null
        },
      },
      {
        id: 'workerId',
        header: 'WORKER ID',
        accessorFn: (row) => row.workerInfo.id,
        size: 64,
        Cell: ({ cell }) => (
          <TruncatedIdWithCopyButton id={cell.getValue<string>()} />
        ),
      },
      {
        id: 'phoneNumber',
        header: 'PHONE #',
        accessorFn: (row) => row.workerInfo.phoneNumber,
        size: 140,
        Cell: ({ cell }) => {
          const phoneNumber = formatPhoneNumber(cell.getValue<string>(), true)
          return <PhoneLinkAndCopyButton phoneNumber={phoneNumber} />
        },
      },
      {
        id: 'lastUpdated',
        header: 'UPDATED AT',
        accessorFn: (row) => row.updatedAt,
        size: 72,
        grow: 1,
        Cell: ({ cell }) => {
          const date = cell.getValue<string>()
          const formattedDate = date ? `${tz.getDateTime(new Date(date))}` : '-'
          return <Text variant="body1">{formattedDate}</Text>
        },
      },
      {
        id: 'tier',
        header: 'TIER',
        accessorFn: (row) => row.workerInfo.tierLevel,
        size: 50,
      },
      {
        id: 'reliability',
        header: 'RELIABILITY',
        accessorFn: (row) =>
          `${toPercentString(row.workerInfo.reliabilityScore || 0)}%`,
        size: 52,
      },
      {
        id: 'shiftsCompleted',
        header: '# SHIFTS',
        accessorFn: (row) => row.workerInfo.completedShifts,
        size: 50,
      },
      {
        id: 'steps',
        header: 'STEPS',
        accessorFn: (row) => row.workerApplicationItems,
        size: 60,
        Cell: ({ cell }) => {
          const items = cell.getValue<WorkerApplicationItem[]>()
          const completed = items.filter(
            (item) => item.status === WorkerApplicationItemStatus.COMPLETED,
          )
          return (
            <Row alignCenter gap={theme.space.xxxs}>
              <Text variant="body1">
                {completed.length} / {items.length}
              </Text>
              {completed.length === items.length && (
                <SvgIcon
                  name="filledCheck"
                  size={12}
                  color={theme.colors.Green70}
                />
              )}
            </Row>
          )
        },
      },
      {
        id: 'applicationStatus',
        header: 'APPLICATION',
        accessorFn: (row) => row.status,
        size: 72,
        Cell: ({ cell }) => (
          <WorkerApplicationStatusBadge
            applicationStatus={cell.getValue<WorkerApplicationStatus>()}
          />
        ),
      },
      {
        id: 'inviteStatus',
        header: 'INVITE STATUS',
        accessorFn: (row) => row.workerInfo,
        size: 64,
        Cell: ({ cell }) => {
          const worker = cell.getValue<BasicOpsWorkerDetails>()
          return (
            <ShiftInvitationStatusOrInviteButton
              disabled={isLoading}
              isOnShift={workerShiftWorkerIdsSet.has(worker.id)}
              worker={worker}
              shiftId={shift.shiftId}
              onClickInvite={onInviteModalOpen(worker)}
            />
          )
        },
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        accessorFn: (row) => row.workerInfo,
        size: 36,
        Cell: ({ cell }) => {
          const worker = cell.getValue<BasicOpsWorkerDetails>()
          const shiftInvitation = shiftInvitations.find(
            (invitation) => invitation.workerId === worker.id,
          )
          const isOnShift = workerShiftWorkerIdsSet.has(worker.id)
          return (
            <DotMenu
              type="worker-application-actions"
              dotMenuKey={worker.id}
              disabled={isOnShift}
              style={{ marginRight: '12px' }}
              menuItems={[
                {
                  hidden: isOnShift,
                  title: 'Add to shifts',
                  onClick: handleAddToShifts(worker.id),
                  color: theme.colors.brand,
                  iconName: 'plus',
                },
                {
                  hidden:
                    !shiftInvitation ||
                    shiftInvitation.status === ShiftInvitationStatus.Rescinded,
                  title: 'Rescind invite',
                  onClick: handleRescindInvitation(shiftInvitation),
                  color: theme.colors.Red60,
                  iconName: 'block',
                },
                {
                  hidden:
                    shiftInvitation?.status !== ShiftInvitationStatus.Rescinded,
                  title: 'Invite again',
                  onClick: onInviteModalOpen(worker),
                  color: theme.colors.brand,
                  iconName: 'rotateLeft',
                },
              ]}
            />
          )
        },
      },
    ],
    [
      isLoading,
      shiftInvitations,
      workerShiftWorkerIdsSet,
      shift.shiftId,
      tz,
      handleAddToShifts,
      handleRescindInvitation,
      onInviteModalOpen,
    ],
  )

  const table = useMaterialReactTable({
    columns,
    data,
    // enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: true,
    // enablePagination: false,
    enableSorting: true,
    enableColumnResizing: false,
    muiTableContainerProps: {
      sx: {
        overflow: 'scroll',
        minWidth: '900px',
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        enableResizing: false, //allow the row numbers column to be resized
        size: 72,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        '& .MuiCollapse-root': {
          width: '100%',
        },
      },
    },
    muiTableProps: {
      sx: {
        border: '0px',
        overflow: 'scroll',
      },
    },
    muiTableHeadRowProps: {
      sx: HEADER_ROW_STYLES,
    },
    muiTableHeadCellProps: {
      sx: HEADER_CELL_STYLES,
    },
    muiExpandAllButtonProps: {
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
        },
      },
    },
    muiExpandButtonProps: {
      sx: {
        marginLeft: '12px',
      },
    },
    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        ...CELL_COMMON_STYLES,
        background: row.getIsExpanded() ? theme.colors.Grey10 : 'transparent',
      },
    }),
    layoutMode: 'grid',
    muiDetailPanelProps: {
      sx: {
        width: '100%',
        borderLeft: `8px solid ${theme.colors.Violet30}`,
        padding: '0px',
        paddingLeft: '16px',
      },
    },
    renderDetailPanel: ({ row }) => (
      <ApplicationItemsSectionContainer
        applicationItemRow={row}
        selectedWorkerApplicationItemId={selectedWorkerApplicationItemId}
        setSelectedWorkerApplicationItemId={onSelectWorkerApplicationItem}
      />
    ),
  })

  return (
    <>
      <Col gap={theme.space.sm}>
        <Row alignCenter justifyBetween>
          <Row alignCenter>
            <Text mr={theme.space.xxs} variant="h4">
              Schedule Applications
            </Text>
            <DisableApplicationToggleButton
              shiftRequestId={shift.shiftRequestId}
            />
          </Row>

          <ViewApplicationDrawer
            isOpen={isApplicationDetailsDrawerOpen}
            onOpenDrawer={onOpenApplicationDetailsDrawer}
            onCloseDrawer={onCloseApplicationDetailsDrawer}
            shiftRequestId={shift.shiftRequestId}
          />
        </Row>
        <MaterialReactTableNoToolbar table={table} />
      </Col>

      {addWorkerToShiftsModal.isOpen && (
        <AddWorkerToShiftsModalContainer
          handleClose={addWorkerToShiftsModal.handleClose}
          isOpen={addWorkerToShiftsModal.isOpen}
          shift={shift} // is this what i want??
          workerId={selectedRows[0]}
        />
      )}

      {workerForInviteModal && showInviteModal && (
        <InviteToSingleShiftOrScheduleModal
          isOpen={showInviteModal}
          onClose={onInviteModalClose}
          shiftId={shift.shiftId}
          shiftRequestId={shift.shiftRequestId}
          shiftRequestParentId={shift.shiftRequestParentId}
          companyId={shift.companyId}
          worker={workerForInviteModal}
        />
      )}
    </>
  )
}
