import { trabaApi } from '@traba/api-utils'
import { CancelRecurringWorkerShiftRequest } from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const cancelRecurringWorkerShift = async (
  request: CancelRecurringWorkerShiftRequest,
): Promise<any> => {
  try {
    return trabaApi.patch(`recurring-worker-shift-edits/cancel`, request)
  } catch (error: any) {
    captureSentryError(error)
    throw error
  }
}
