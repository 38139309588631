import { Button, Col, Input, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { TerminationInitiation } from '@traba/types'
import { TerminationReason } from '@traba/types'
import { useWorkerW2Termination } from 'src/hooks/useW2WorkerTermination'

export const TerminationEmailContent = ({
  workerId,
  reason,
  initiation,
  explanation,
  uploadIds,
  emailRecipient,
  setEmailRecipient,
  emailBody,
  setEmailBody,
  onClose,
}: {
  workerId: string
  reason: TerminationReason | ''
  initiation: TerminationInitiation | ''
  explanation: string
  uploadIds: string[]
  emailRecipient: string
  setEmailRecipient: (emailRecipient: string) => void
  emailBody: string
  setEmailBody: (emailBody: string) => void
  onClose: () => void
}) => {
  const { terminateW2Worker } = useWorkerW2Termination(workerId)
  const ready =
    initiation !== '' &&
    reason !== '' &&
    explanation !== '' &&
    workerId !== '' &&
    emailBody !== '' &&
    emailRecipient !== ''

  const handleSubmit = async () => {
    if (!ready) {
      return
    }
    await terminateW2Worker({
      workerId,
      reason,
      initiation,
      emailBody,
      emailRecipient,
      explanation,
      date: new Date(),
      uploadIds,
    })
    onClose()
  }

  return (
    <>
      <Col width={'40%'}>
        <Text variant="h6" mb={-theme.space.xs}>
          Recipient
        </Text>
        <Input
          value={emailRecipient}
          onChange={(e) => setEmailRecipient(e.target.value)}
        />
      </Col>
      <Col gap={theme.space.xxxs} mt={theme.space.xs} mb={theme.space.xs}>
        <Text variant="h6">Body</Text>
        <Input
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
          style={{ minHeight: '100px', marginTop: 0 }}
          containerStyle={{ marginTop: 0 }}
          type="textarea"
        />
      </Col>
      <Button onClick={handleSubmit} disabled={!ready}>
        Submit Report
      </Button>
    </>
  )
}
