import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useParams } from 'react-router-dom'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import Divider from 'src/components/base/Divider'
import { useInvoicePreview } from 'src/hooks/useInvoicePreview'
import { InvoiceHeader } from './components/InvoiceHeader'
import { PaymentSection } from './components/PaymentSection'
import { TableSection } from './components/TableSection'
import { Watermark } from './components/Watermark'

export function InvoicePreviewScreen() {
  const { invoiceId = '' } = useParams()

  const { invoiceStatementData, isLoading, error } =
    useInvoicePreview(invoiceId)

  if (isLoading) {
    return (
      <Row justifyCenter alignCenter>
        <CircularProgress size="large" />
      </Row>
    )
  }

  if (!invoiceStatementData) {
    return (
      <Row
        justifyCenter
        alignCenter
        flexCol
        mt={theme.space.lg}
        gap={theme.space.sm}
      >
        <Text variant="h6" color="error" size={theme.space.med}>
          Failed to load invoice preview
        </Text>
        {error && (
          <Text variant="body2" color="textSecondary" size={theme.space.sm}>
            {error.message}
          </Text>
        )}
      </Row>
    )
  }

  const {
    companyName,
    invoiceNumber,
    invoiceStatus,
    createdAt,
    regionIds,
    statementStartDate,
    statementEndDate,
    paymentUrl,
    trabaBilling,
    customerBilling,
    totalAmountBilled,
    dueDate,
    customerSupportInstructions,
    sections,
    bankInfo,
    bankTransferInstructions,
    paperCheckInstructions,
  } = invoiceStatementData

  return (
    <>
      <Watermark status={invoiceStatus} />
      <Row flexCol gap={theme.space.xs} p={theme.space.us}>
        <InvoiceHeader
          companyName={companyName}
          invoiceNumber={invoiceNumber}
          createdAt={createdAt}
          regionIds={regionIds}
          statementStartDate={statementStartDate}
          statementEndDate={statementEndDate}
          paymentUrl={paymentUrl}
          trabaBilling={trabaBilling}
          customerBilling={customerBilling}
          totalAmountBilled={totalAmountBilled}
          dueDate={dueDate}
          customerSupportInstructions={customerSupportInstructions}
        />
        <Divider
          dividerColor={theme.colors.MidnightBlue}
          wrapperStyle={{ marginTop: theme.space.xs }}
        />
        {sections.map((section, index) => (
          <Row
            key={section.title}
            flexCol
            mt={theme.space.xs}
            mb={theme.space.xs}
            style={index > 0 ? { breakInside: 'avoid' } : undefined}
          >
            <TableSection section={section} />
          </Row>
        ))}
        <Divider
          dividerColor={theme.colors.MidnightBlue}
          wrapperStyle={{
            marginTop: theme.space.xs,
            marginBottom: theme.space.xs,
          }}
        />
        <PaymentSection
          invoiceStatus={invoiceStatus}
          totalAmountBilled={totalAmountBilled}
          bankInfo={bankInfo}
          invoiceNumber={invoiceNumber}
          trabaBilling={trabaBilling}
          bankTransferInstructions={bankTransferInstructions}
          paperCheckInstructions={paperCheckInstructions}
        />
      </Row>
    </>
  )
}
