import { Badge, Button, LoadingSpinner } from '@traba/react-components'
import { BasicOpsWorkerDetails, ShiftInvitationStatus } from '@traba/types'
import { useMemo } from 'react'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'

interface ShiftInvitationStatusOrInviteButtonProps {
  disabled?: boolean
  isOnShift?: boolean
  shiftId: string
  worker: Pick<BasicOpsWorkerDetails, 'id' | 'firstName' | 'lastName'>
  onClickInvite: () => void
}

const INVITE_STATUS_TO_BADGE_VARIANT: Record<ShiftInvitationStatus, string> = {
  [ShiftInvitationStatus.Pending]: 'yellow',
  [ShiftInvitationStatus.Sent]: 'success',
  [ShiftInvitationStatus.Accepted]: 'brand',
  [ShiftInvitationStatus.Declined]: 'danger',
  [ShiftInvitationStatus.Rescinded]: 'danger',
}

export function ShiftInvitationStatusOrInviteButton({
  disabled,
  isOnShift,
  shiftId,
  worker,
  onClickInvite,
}: ShiftInvitationStatusOrInviteButtonProps) {
  const {
    shiftInvitations,
    sendInvitationsLoading,
    isLoading: shiftInvitationsLoading,
  } = useShiftInvitations(shiftId)

  const singleShiftInvitation = useMemo(
    () => shiftInvitations?.find((si) => si.workerId === worker.id),
    [shiftInvitations, worker.id],
  )

  // TODO: Reconsider adding schedule invitations here -- right now getting shiftRequestParentId is complicated
  // and what's most important to display here is if the person is invited to *this shift*

  const inviteStatus = singleShiftInvitation?.status

  if (sendInvitationsLoading || shiftInvitationsLoading) {
    return <LoadingSpinner />
  }

  if (isOnShift) {
    return (
      <Badge
        title={
          inviteStatus === ShiftInvitationStatus.Accepted ? 'Accepted' : 'Added'
        }
        variant="brand"
      />
    )
  }

  if (inviteStatus) {
    return (
      <Badge
        title={inviteStatus}
        variant={INVITE_STATUS_TO_BADGE_VARIANT[inviteStatus]}
      />
    )
  }

  return (
    <Button disabled={disabled} slim onClick={onClickInvite}>
      Invite
    </Button>
  )
}
