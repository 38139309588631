import { VettingConfig, WorkerVettingCampaign } from '@traba/types'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import {
  VettingContextForm,
  VettingContextFormProps,
} from 'src/components/VettingContextForm/VettingContextForm'
import { usePromptContext } from 'src/hooks/usePromptContext'
import { buildVettingConfigInitialValues } from 'src/utils/vettingCampaignUtils'

type ConfirmVettingCampaignAIVettingModalProps = Omit<
  VettingContextFormProps,
  'initialValues'
> & {
  isOpen: boolean
  handleClose: () => void
  workerVettingCampaign?: WorkerVettingCampaign
  onSubmit: (data: VettingConfig) => void
}

export const ConfirmVettingCampaignAIVettingModal = ({
  isOpen,
  handleClose,
  onSubmit,
  onSave,
  workerVettingCampaign,
  loading,
}: ConfirmVettingCampaignAIVettingModalProps) => {
  const { promptContext } = usePromptContext(
    workerVettingCampaign?.activePromptContextId,
  )

  const initialValues = buildVettingConfigInitialValues({
    workerVettingCampaign,
    promptContext: promptContext?.context,
  })

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.LARGE}
      title="Vetting Campaign Details"
    >
      <VettingContextForm
        onSubmit={(data) => {
          onSubmit(data)
          handleClose()
        }}
        onSave={workerVettingCampaign ? onSave : undefined}
        initialValues={initialValues}
        loading={loading}
      />
    </Modal>
  )
}
