import { Routes, Route } from 'react-router-dom'
import { InvoicePreviewScreen } from 'src/screens/InvoicePreviewScreen/InvoicePreviewScreen'

export default function LoggedInCookie() {
  return (
    <Routes>
      <Route
        path="/invoice/:invoiceId/preview"
        element={<InvoicePreviewScreen />}
      />
    </Routes>
  )
}
