import { Time } from '@internationalized/date'
import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { useDeepEffect, useHotSettings } from '@traba/hooks'
import { useInternalUsers } from '@traba/hooks'
import { Text, SearchSelect } from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import {
  AssigneeDisplay,
  InternalUserRole,
  ShiftStatus,
  InternalUserStatus,
  CompanyResponse,
  Region,
  ForwardFillMax,
  ShiftTag,
} from '@traba/types'
import {
  addDays,
  isAfter,
  isBefore,
  isEqual,
  startOfToday,
  startOfYesterday,
  subDays,
  startOfTomorrow,
} from 'date-fns'
import { compact, debounce } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { Button, Icon, Input, Row, Select } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { CollapsibleShiftTable } from 'src/components/CollapsibleShiftTable'
import { useSentinelContext } from 'src/context/sentinelContext/SentinelContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useAttributes } from 'src/hooks/useAttributes'
import { useSentinelNotificationsForShifts } from 'src/hooks/useSentinelNotifications'
import { useSearchShifts } from 'src/hooks/useShifts'
import { SHIFT_CODE_LEN } from 'src/libs/constants'
import BulkCancelShiftsModal from 'src/modals/CancelShiftModal/BulkCancelShiftsModal'
import { strContainsNonBase64Char } from 'src/utils/shiftCodeUtils'
import { normalizeString } from 'src/utils/stringUtils'
import Pagination from '../../components/base/Pagination/Pagination'
import ShiftAssignmentModal from '../../components/ShiftAssigmentModal/ShiftAssigmentModal'
import { createShiftAssignmentMaps } from '../../hooks/useAssignments'
import { SortOrder } from '../../hooks/usePagination'
import { getExpectedWorkerShifts } from '../../utils/workerShiftUtils'
import { AddWorkerToShiftsModal } from './components/AddWorkers/AddWorkerToShiftsModal'
import { AssignmentsDrawer } from './components/AssignmentsDrawer'
import FieldMonitorFilters, {
  FieldMonitorFiltersType,
} from './components/FieldMonitorFilters/FieldMonitorFilters'
import * as S from './styles'

type UrlHashParams = {
  after: string | null
  before: string | null
  companyNames: string[]
  regionIds: string[]
  firstName?: string
  lastName?: string
  shiftId?: string
  shiftCode?: string
  magicSearchText?: string
  pageSize: number
  ffMax: string[]
  assigneeDisplay: string[]
  fieldMonitorFilters: FieldMonitorFiltersType
  assignedTo: string[]
  datePreset: string
  tags: string[]
}

const defaultSelectFields = {
  shift: [
    'id',
    'status',
    'createdAt',
    'startTime',
    'endTime',
    'regionId',
    'timezone',
    'slotsRequested',
    'slotsFilled',
    'overbookSlotsRequested',
    'paidBackupSlotsRequested',
    'paidBackupSlotsFilled',
    'reliabilityAverage',
    'shiftRequestId',
    'tags',
    'forwardFillMax',
    'companyId',
    'workerShiftTransit',
    'shiftEmploymentType',
  ],
  company: ['id', 'category', 'employerName'],
  role: ['roleName'],
  location: ['shortLocation', 'name'],
  workerShifts: [
    'earningsSummary',
    'workerShiftTimeToDestination',
    'jobStatus',
    'isShiftConfirmed',
    'clockInTime',
    'clockOutTime',
    'reliability',
  ],
  worker: ['id', 'firstName', 'lastName'],
  shiftAssignment: ['id', 'shiftId', 'assignees', 'status'],
}
const shiftLimitOptions: IMenuItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '400', value: '400' },
  { label: '600', value: '600' },
]
const DEFAULT_PAGE_SIZE_FM = 400

interface FieldMonitorProps {
  companies: CompanyResponse[]
  regions: Region[]
  shiftTagMenuItems: IMenuItem[]
}

export type WorkersOnTheWay = {
  count: number
  shiftId: string
}

export type WorkerShiftTimeToDestinationResponse = {
  workersOnTheWay: WorkersOnTheWay[]
}

export default function FieldMonitorPG(props: FieldMonitorProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const { hotSettings } = useHotSettings()
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<IMenuItem[]>([])
  const [assignmentsOpen, setAssignmentsOpen] = useState<boolean>(false)
  // TODO replace with proper search
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [shiftId, setShiftId] = useState<string>('')
  const [shiftCode, setShiftCode] = useState<string>('')
  const [magicSearchText, setMagicSearchText] = useState<string>('')
  const [debouncedMagicSearchText, setDebouncedMagicSearchText] =
    useState<string>('')
  const [tags, setTags] = useState<IMenuItem[]>([])
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_FM)

  const { companies, regions, shiftTagMenuItems } = props

  const { state } = useUserContext()
  const sentinelContext = useSentinelContext()

  /* Formatting for search boxes */
  let companyOptions: IMenuItem[] = companies.map((company) => {
    return { label: company.employerName, value: company.id }
  })
  // `value` in this case is the companyId, and some companies with bogus data might not have that field.
  companyOptions = companyOptions.filter((c) => !!c.value)

  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })

  /*
    FILTERS
  */
  const [fieldMonitorFilters, setFieldMonitorFilters] =
    useState<FieldMonitorFiltersType>({
      showCanceledShiftsFilter: false,
      showLateClockInShiftsFilter: false,
      showLateClockOutShiftsFilter: false,
      showNotConfirmedFilter: false,
      showNotPaidFilter: false,
      showSlotsNotFilledFilter: false,
      showSlotsNotVettedFilter: false,
      showLowReliabilityShifts: false,
      showUnassignedShiftsMarketOps: false,
      showUnassignedShiftsScaleOps: false,
      showAssignedShifts: false,
    })

  const handleToggleFilter = (filter: Partial<FieldMonitorFiltersType>) => {
    setFieldMonitorFilters((prevFilters) => ({ ...prevFilters, ...filter }))
    setSelectedShiftIds([])
    if (hotSettings?.useLegacyFieldMonitorFiltering === false) {
      setCurrentPage(0) // Reset to first page when filter changes
      refetchActiveQueries() // This will trigger a new backend call
    }
  }

  const activeFilters = Object.entries(fieldMonitorFilters).filter(
    (fieldMonitorFilter) => fieldMonitorFilter[1],
  )

  const activeFilterCount = activeFilters.length

  const clearSelectedShiftIds = () => {
    setSelectedShiftIds([])
    return setFieldMonitorFilters({
      ...fieldMonitorFilters,
    })
  }

  /* Input States */
  /** The params here are stored in the URI hash so it can be shared */
  const beginningOfToday = new Date(new Date().setHours(0, 0, 0, 0))
  const beginningOfTomorrow = addDays(beginningOfToday, 1)

  const [afterDate, setAfterDate] = useState<Date>(beginningOfToday)
  const [beforeDate, setBeforeDate] = useState<Date>(beginningOfTomorrow)

  const datePresetConfigurations = useMemo(
    () => [
      {
        label: 'No date preset',
        getRange: () => ({
          after: afterDate,
          before: beforeDate,
        }),
      },
      {
        label: 'Today',
        getRange: () => ({
          after: startOfToday(),
          before: startOfTomorrow(),
        }),
      },
      {
        label: 'Yesterday',
        getRange: () => ({
          after: startOfYesterday(),
          before: startOfToday(),
        }),
      },
      {
        label: 'Tomorrow',
        getRange: () => ({
          after: startOfTomorrow(),
          before: addDays(startOfTomorrow(), 1),
        }),
      },
      {
        label: 'Last 7 Days',
        getRange: () => ({
          after: subDays(startOfToday(), 6),
          before: startOfTomorrow(),
        }),
      },
      {
        label: 'Next 7 Days',
        getRange: () => ({
          after: startOfToday(),
          before: addDays(startOfToday(), 6),
        }),
      },
      {
        label: 'Last 30 Days',
        getRange: () => ({
          after: subDays(startOfToday(), 29),
          before: startOfTomorrow(),
        }),
      },
      {
        label: 'Next 30 Days',
        getRange: () => ({
          after: startOfToday(),
          before: addDays(startOfToday(), 29),
        }),
      },
      {
        label: 'Last 3 Days (Excluding Today)',
        getRange: () => ({
          after: subDays(startOfToday(), 3),
          before: startOfToday(),
        }),
      },
      {
        label: 'Next 3 Days (Excluding Today)',
        getRange: () => ({
          after: startOfTomorrow(),
          before: addDays(startOfTomorrow(), 3),
        }),
      },
    ],
    [],
  ) // Empty dependency array ensures memoization across renders

  /* Debounce when programmatically changing date inputs to avoid changing
      start date or end date while the user is still typing */
  const debouncedAdjustAfterDate = useCallback(
    debounce((newDate) => {
      if (
        newDate &&
        afterDate &&
        (isBefore(newDate, afterDate) || isEqual(newDate, afterDate))
      ) {
        setAfterDate(subDays(newDate, 1))
      }
    }, 750),
    [afterDate, setAfterDate],
  )
  const debouncedAdjustBeforeDate = useCallback(
    debounce((newDate) => {
      if (
        newDate &&
        beforeDate &&
        (isAfter(newDate, beforeDate) || isEqual(newDate, beforeDate))
      ) {
        setBeforeDate(addDays(newDate, 1))
      }
    }, 750),
    [beforeDate, setBeforeDate],
  )

  function onChangeAfterDate(newDate: Date | null, isFourDigitYear?: boolean) {
    if (!newDate) {
      return
    }
    setAfterDate(newDate)
    setDatePreset(datePresetsOptions[0])
    if (isFourDigitYear) {
      debouncedAdjustBeforeDate(newDate)
    }
  }

  function onChangeBeforeDate(newDate: Date | null, isFourDigitYear?: boolean) {
    if (!newDate) {
      return
    }
    setBeforeDate(newDate)
    setDatePreset(datePresetsOptions[0])
    if (isFourDigitYear) {
      debouncedAdjustAfterDate(newDate)
    }
  }

  const [dateRange, setDateRange] = useState([afterDate, beforeDate])
  const debouncedSetDateRange = useCallback(
    debounce((newAfterDate, newBeforeDate) => {
      setDateRange([newAfterDate, newBeforeDate])
    }, 750),
    [],
  )

  useEffect(() => {
    setSelectedShiftIds([])
    // Handle a clear date action immediately, otherwise debounce input
    if (afterDate === null || beforeDate === null) {
      setDateRange([afterDate, beforeDate])
    }
    debouncedSetDateRange(afterDate, beforeDate)
    return () => {
      debouncedSetDateRange.cancel()
    }
  }, [afterDate, beforeDate, debouncedSetDateRange])

  const [companyNames, setCompanyNames] = useState<IMenuItem[]>([])
  const [regionIds, setRegionIds] = useState<string[]>([])

  const ffMaxOptions: IMenuItem[] = Object.values(ForwardFillMax).map(
    (ffMax) => ({
      label: ffMax,
      value: ffMax,
    }),
  )
  const [ffMax, setFfMax] = useState<IMenuItem[]>([])
  const { userOptions, isLoadingInternalUsers } = useInternalUsers({
    statuses: [InternalUserStatus.Active],
  })

  /**
   * The following assignee display options defines what would be shown as "bubbles"
   * next to each row of the "assignments" -- Market Ops (green) and Scaled Ops (purple)
   * This logic is to select the dropdown box on the top of the field monitor to selectively display
   * the market ops and scaled ops.
   */
  const assigneeDisplayOptions: IMenuItem[] = Object.keys(AssigneeDisplay).map(
    (assigneeDisplay) => ({
      label: assigneeDisplay,
      value: assigneeDisplay,
    }),
  )
  const datePresetsOptions: IMenuItem[] = datePresetConfigurations.map(
    (datePreset) => ({
      label: datePreset.label,
      value: datePreset.label,
    }),
  )

  const { state: userState } = useUserContext()
  const [assigneeDisplay, setAssigneeDisplay] = useState<IMenuItem[]>(() => {
    switch (userState.userProfile?.internalUser?.role) {
      case InternalUserRole.MARKET_OPS:
        return [
          {
            value: AssigneeDisplay.MARKET_OPS,
            label: AssigneeDisplay.MARKET_OPS,
          },
        ]
      case InternalUserRole.SCALED_OPS:
        return [
          {
            value: AssigneeDisplay.SCALED_OPS,
            label: AssigneeDisplay.SCALED_OPS,
          },
        ]
      default:
        return []
    }
  })

  const [datePreset, setDatePreset] = useState<IMenuItem | undefined>(() => {
    const defaultPresetLabel = datePresetsOptions[0]?.value
    if (defaultPresetLabel !== datePresetConfigurations[0]?.label) {
      return {
        label: defaultPresetLabel,
        value: defaultPresetLabel,
      }
    }
    return undefined
  })

  useEffect(() => {
    if (datePreset) {
      const selectedPresetLabel = datePreset.value
      const selectedPreset = datePresetConfigurations.find(
        (preset) => preset.label === selectedPresetLabel,
      )
      if (selectedPreset && selectedPreset !== datePresetConfigurations[0]) {
        const { after, before } = selectedPreset.getRange()
        setAfterDate(after)
        setBeforeDate(before)
      }
    }
  }, [datePreset, datePresetConfigurations])

  /** Store the search params in the URI hash */
  const updateUrlHash = useCallback(
    (params: UrlHashParams) => {
      const hashString = btoa(JSON.stringify(params))
      navigate(`#${hashString}`, { replace: true })
    },
    [navigate],
  )

  const { showSuccess } = useAlert()
  const copyShareableUrl = () => {
    const url = window.location.href
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showSuccess('Copied URL to clipboard (except Assignees)')
      })
      .catch((err) => {
        console.error('Failed to copy URL: ', err)
      })
  }

  const prevHashRef = useRef<string | null>(null)

  const readFromUrlHash = useCallback((): UrlHashParams | null => {
    if (!location.hash || location.hash === prevHashRef.current) {
      return null
    }
    try {
      const decodedHash = atob(location.hash.slice(1))
      prevHashRef.current = location.hash
      return JSON.parse(decodedHash) as UrlHashParams
    } catch (error) {
      console.error('Error parsing URL hash:', error)
      prevHashRef.current = location.hash
      return null
    }
  }, [location.hash])

  // Read from URL hash on component mount
  useEffect(() => {
    const params = readFromUrlHash()
    if (params) {
      // Compare each state variable with the parsed parameter before updating
      if (
        params.datePreset &&
        params.datePreset !== datePresetsOptions[0]?.value
      ) {
        setDatePreset({
          label: params.datePreset,
          value: params.datePreset,
        })
      } else {
        if (params.after) {
          const newAfterDate = new Date(params.after)
          if (afterDate?.getTime() !== newAfterDate.getTime()) {
            setAfterDate(newAfterDate)
          }
        }
        if (params.before) {
          const newBeforeDate = new Date(params.before)
          if (beforeDate?.getTime() !== newBeforeDate.getTime()) {
            setBeforeDate(newBeforeDate)
          }
        }
      }
      const newCompanyNames =
        params.companyNames
          ?.map((companyValue: string) =>
            companyOptions.find((c) => c.value === companyValue),
          )
          .filter((item): item is IMenuItem => item !== undefined) || []
      if (JSON.stringify(companyNames) !== JSON.stringify(newCompanyNames)) {
        setCompanyNames(newCompanyNames)
      }

      const regionIdsDefaultSelectedItems = params.regionIds || []
      if (
        JSON.stringify(regionIds) !==
        JSON.stringify(regionIdsDefaultSelectedItems)
      ) {
        setRegionIds(regionIdsDefaultSelectedItems)
      }
      if (firstName !== params.firstName) {
        setFirstName(params.firstName || '')
      }
      if (lastName !== params.lastName) {
        setLastName(params.lastName || '')
      }
      if (shiftId !== params.shiftId) {
        setShiftId(params.shiftId || '')
      }
      if (shiftCode !== params.shiftCode) {
        setShiftCode(params.shiftCode || '')
      }
      if (magicSearchText !== params.magicSearchText) {
        setMagicSearchText(params.magicSearchText || '')
      }
      if (pageSize !== (params.pageSize || DEFAULT_PAGE_SIZE_FM)) {
        setPageSize(params.pageSize || DEFAULT_PAGE_SIZE_FM)
      }

      const allowedFfMaxValues = new Set<string>(
        Object.values(ForwardFillMax) as string[],
      )
      const newFfMax =
        params.ffMax
          ?.filter((ffValue: string) => allowedFfMaxValues.has(ffValue))
          .map((ffValue: string) => ({ label: ffValue, value: ffValue })) || []

      if (JSON.stringify(ffMax) !== JSON.stringify(newFfMax)) {
        setFfMax(newFfMax)
      }

      const allowedShiftTags = new Set<string>(
        Object.values(ShiftTag) as string[],
      )
      const newTags =
        params.tags
          ?.filter((tag: string) => allowedShiftTags.has(tag))
          .map((tag: string) => ({ label: tag, value: tag })) || []

      if (JSON.stringify(tags) !== JSON.stringify(newTags)) {
        setTags(newTags)
      }

      const newAssigneeDisplay =
        params.assigneeDisplay
          ?.map((assigneeId: string) =>
            assigneeDisplayOptions.find((a) => a.value === assigneeId),
          )
          .filter((item): item is IMenuItem => item !== undefined) || []
      if (
        JSON.stringify(assigneeDisplay) !== JSON.stringify(newAssigneeDisplay)
      ) {
        setAssigneeDisplay(newAssigneeDisplay)
      }
      if (
        JSON.stringify(fieldMonitorFilters) !==
        JSON.stringify(params.fieldMonitorFilters || {})
      ) {
        setFieldMonitorFilters(params.fieldMonitorFilters || {})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty dependency array to run only on mount

  // Update URL hash whenever relevant state variables change
  useEffect(() => {
    const searchParams: UrlHashParams = {
      after: afterDate ? afterDate.toISOString() : null,
      before: beforeDate ? beforeDate.toISOString() : null,
      companyNames: companyNames.map((c) => c.value),
      regionIds,
      firstName: firstName || undefined,
      lastName: lastName || undefined,
      shiftId: shiftId || undefined,
      shiftCode: shiftCode || undefined,
      magicSearchText: magicSearchText || undefined,
      pageSize,
      ffMax: ffMax.map((ff) => ff.value),
      assigneeDisplay: assigneeDisplay.map((ad) => ad.value),
      fieldMonitorFilters,
      assignedTo: assignedTo.map((assignee) => assignee.value),
      datePreset: datePreset?.value || datePresetsOptions[0]?.value,
      tags: tags.map((tag) => tag.value),
    }
    // Serialize the current parameters to compare with existing hash
    const currentHash = btoa(JSON.stringify(searchParams))
    const locationHash = location.hash.slice(1)
    if (currentHash !== locationHash) {
      updateUrlHash(searchParams)
    }
  }, [
    afterDate,
    beforeDate,
    companyNames,
    regionIds,
    firstName,
    lastName,
    shiftId,
    shiftCode,
    magicSearchText,
    pageSize,
    ffMax,
    assigneeDisplay,
    fieldMonitorFilters,
    assignedTo,
    location.hash,
    updateUrlHash,
    datePreset?.value,
    datePresetsOptions,
    tags,
  ])

  /** Build the search params for the backend request */
  const activeSearchParams = useMemo(() => {
    if (shiftId) {
      return { id: shiftId }
    }

    const statuses = fieldMonitorFilters.showCanceledShiftsFilter
      ? [ShiftStatus.CANCELED, ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
      : [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]

    const baseParams = {
      startTimeBefore: dateRange[1] && !shiftCode ? dateRange[1] : undefined,
      startTimeAfter: dateRange[0] ? dateRange[0] : undefined,
      companyIds: companyNames?.length
        ? companyNames.map((c) => c.value)
        : undefined,
      regionIds: regionIds.length ? regionIds : undefined,
      isCompanyApproved: true,
      statuses,
    }

    if (hotSettings?.useLegacyFieldMonitorFiltering) {
      return baseParams
    }

    const assignees = fieldMonitorFilters.showAssignedShifts
      ? [state.userProfile?.internalUser?.id]
      : []
    assignees.push(...assignedTo.map((assignee) => assignee.value))

    return {
      ...baseParams,
      notConfirmed: fieldMonitorFilters.showNotConfirmedFilter,
      notPaid: fieldMonitorFilters.showNotPaidFilter,
      slotsNotFilled: fieldMonitorFilters.showSlotsNotFilledFilter,
      slotsNotVetted: fieldMonitorFilters.showSlotsNotVettedFilter,
      notClockedIn: fieldMonitorFilters.showLateClockInShiftsFilter,
      notClockedOut: fieldMonitorFilters.showLateClockOutShiftsFilter,
      scaledOpsUnassigned: fieldMonitorFilters.showUnassignedShiftsScaleOps,
      marketOpsUnassigned: fieldMonitorFilters.showUnassignedShiftsMarketOps,
      workerFirstName: normalizeString(firstName || ''),
      workerLastName: normalizeString(lastName || ''),
      forwardFillMaxes: ffMax.map((ff) => ff.value),
      assigneeIds: assignees.length ? assignees : undefined,
      tags: tags.map((tag) => tag.value),
    }
  }, [
    shiftId,
    fieldMonitorFilters,
    dateRange,
    shiftCode,
    companyNames,
    regionIds,
    hotSettings?.useLegacyFieldMonitorFiltering,
    state.userProfile?.internalUser?.id,
    assignedTo,
    firstName,
    lastName,
    ffMax,
    tags,
  ])

  useDebounce(() => setDebouncedMagicSearchText(magicSearchText), 500, [
    magicSearchText,
  ])

  const {
    shifts,
    totalFound,
    isLoading: isLoadingShifts,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  } = useSearchShifts({
    params: activeSearchParams,
    paginationParams: {
      limit: pageSize,
      offset: 0,
      sortBy: 'startTime',
      sortOrder: SortOrder.asc,
    },
    select: defaultSelectFields,
    magicSearchText: debouncedMagicSearchText,
    internalUserId: state.userProfile?.internalUser?.id,
    shiftCode: shiftCode,
  })

  const { data: sentinelNotifications } = useSentinelNotificationsForShifts({
    shiftIds: shifts?.map((shift) => shift.id) || [],
    limit: shifts.length,
    enabled: !!shifts.length,
    internalUserId: state.userProfile?.internalUser?.id,
  })

  // Set list of shiftIds into sentinel context
  useDeepEffect(() => {
    sentinelContext.dispatch({
      type: 'SET_SHIFT_IDS',
      payload: shifts?.map((shift) => shift.id) || [],
    })
  }, [shifts, sentinelContext])

  const { attributes: roleAttributes, isLoading: isLoadingRoleAttributes } =
    useAttributes()

  const { refetchLoading, lastUpdated, refetchActiveQueries } =
    useActiveQueries()

  const handleRefetch = () => {
    setCurrentPage(0)
    refetchActiveQueries()
  }

  const handleReset = () => {
    setFirstName('')
    setLastName('')
    setMagicSearchText('')
    handleRefetch()
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [magicSearchText, setCurrentPage])

  const addWorkersToShiftsModal = useModal()
  const shiftAssignmentModal = useModal()
  const bulkCancelShiftsModal = useModal()

  const isLoadingAdditionalData =
    isLoadingRoleAttributes || isLoadingInternalUsers
  const isLoadingOrRefetch = isLoadingShifts || refetchLoading
  const disabledShiftActions = isLoadingOrRefetch || !selectedShiftIds.length

  const selectedShiftsList = useMemo(() => {
    return shifts?.filter((s) => selectedShiftIds.includes(s.id)) || []
  }, [shifts, selectedShiftIds])

  const pageCounters = useMemo(() => {
    if (isLoadingShifts) {
      return ''
    }
    const slotsRequested = shifts?.reduce(
      (acc, curr) => acc + curr.slotsRequested,
      0,
    )
    const obSlotsRequested = shifts?.reduce(
      (acc, curr) => acc + (curr.overbookSlotsRequested || curr.slotsRequested),
      0,
    )

    const validWorkerShifts = shifts
      .map((shift) => getExpectedWorkerShifts(shift.workerShifts))
      .flat()

    return `Filled: ${validWorkerShifts.length} | Requested: ${slotsRequested} | OB: ${obSlotsRequested}`
  }, [isLoadingShifts, shifts])

  const { shiftAssignmentsMapByShiftId, shiftAssignmentsMapByInternalUser } =
    useMemo(() => {
      const shiftAssignments =
        compact(shifts?.flatMap((shift) => shift.shiftAssignment)) || []
      return createShiftAssignmentMaps(shiftAssignments)
    }, [shifts])

  const setShiftIdentifier = (shiftIdentifier: string) => {
    if (
      shiftIdentifier.length === SHIFT_CODE_LEN &&
      !strContainsNonBase64Char(shiftIdentifier)
    ) {
      setShiftCode(shiftIdentifier)
      setShiftId('')
    } else {
      setShiftId(shiftIdentifier)
      if (shiftId) {
        setShiftCode('')
      }
    }
  }

  const handleDatePresetSelect = (value: IMenuItem | undefined) => {
    setDatePreset(value)
  }

  const [displayedShiftIds, setDisplayedShiftIds] = useState<string[]>([])

  // Callback to receive displayed shift IDs from CollapsibleShiftTable
  const handleDisplayShiftsChange = useCallback(
    (ids: string[]) => {
      // Prevent refreshing/deselecting shift IDs when the shift assignment modal
      if (!shiftAssignmentModal.isOpen) {
        setDisplayedShiftIds(ids)
        setSelectedShiftIds([])
      }
    },
    [shiftAssignmentModal.isOpen],
  )

  const handleSelectAll = useCallback(() => {
    if (selectedShiftIds.length === displayedShiftIds.length) {
      setSelectedShiftIds([])
    } else {
      setSelectedShiftIds(displayedShiftIds)
    }
  }, [selectedShiftIds, displayedShiftIds])

  const displayTotalAndFilteredShiftCount = useMemo(() => {
    const totalShifts = shifts.length
    const filteredShifts = shifts.filter((shift) =>
      displayedShiftIds.includes(shift.id),
    )
    return filteredShifts.length === totalShifts
      ? `${totalShifts} shifts found`
      : `${totalShifts} shifts found | Filtered: ${filteredShifts.length}`
  }, [shifts, displayedShiftIds])
  return (
    <>
      <Text variant="h4">Ops Field Monitor</Text>
      <Row>
        <S.FieldMonitorInputsWrapper>
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeAfterDate}
            isClearable={true}
            inlineLabel={true}
            label="After"
            width={280}
            date={afterDate}
            defaultTime={new Time(0, 0)}
          />
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeBeforeDate}
            isClearable={true}
            inlineLabel={true}
            label="Before"
            width={280}
            date={beforeDate}
            defaultTime={new Time(0, 0)}
          />
          <SearchSelect
            multiple
            options={companyOptions}
            selectedItems={companyNames}
            handleSelectMultiple={setCompanyNames}
            label={`Business${regionIds.length > 1 ? 'es' : ''}`}
            width={260}
            selectedOnTop
            showClearButton
          />
          <StateSearchSelect
            multiple
            options={regionsOptions}
            selectedItems={regionIds}
            handleSelectMultiple={setRegionIds}
            label={`Region${regionIds.length > 1 ? 's' : ''}`}
            width={260}
          />
          <Input
            label="Shift ID / Full Shift Code"
            value={shiftId || shiftCode}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setShiftIdentifier(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <Input
            label="First Name"
            value={firstName}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setFirstName(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <Input
            label="Last Name"
            value={lastName}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setLastName(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <SearchSelect
            multiple
            options={userOptions}
            selectedItems={assignedTo}
            handleSelectMultiple={setAssignedTo}
            label={`Assignees`}
            width={260}
            isLoading={isLoadingInternalUsers}
          />
          <SearchSelect
            multiple
            options={shiftTagMenuItems}
            selectedItems={tags}
            handleSelectMultiple={setTags}
            label="Tags"
            width={260}
            onlyShowLabel
          />
          <Input
            label="Magic Search"
            value={magicSearchText}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setMagicSearchText(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <SearchSelect
            multiple
            options={ffMaxOptions}
            selectedItems={ffMax}
            handleSelectMultiple={setFfMax}
            label={`FF Max`}
            width={260}
          />
          <SearchSelect
            multiple
            options={assigneeDisplayOptions}
            selectedItems={assigneeDisplay}
            handleSelectMultiple={setAssigneeDisplay}
            label={'Display Assignee'}
          />
          <SearchSelect
            options={datePresetsOptions}
            selectItem={datePreset}
            handleSelect={handleDatePresetSelect}
            label={'Date Presets'}
          />
        </S.FieldMonitorInputsWrapper>
      </Row>
      <Row
        mb={theme.space.med}
        justifyBetween
        wrap
        style={{
          position: 'sticky',
          top: 50,
          backgroundColor: theme.colors.Grey10,
          border: `1px solid ${theme.colors.Grey20}`,
          borderRadius: theme.border.radius,
          zIndex: Z_INDEXES.DROPDOWN_LIST_CONTAINER,
          padding: theme.space.xs,
        }}
      >
        {isLoadingAdditionalData && (
          <Row justifyCenter alignCenter>
            <CircularProgress size={18} />
            <Text ml={theme.space.xs}>Loading additional data</Text>
          </Row>
        )}
        {!isLoadingAdditionalData && (
          <Row>
            {selectedShiftIds.length > 0 && (
              <S.ShiftActionButton
                leftIcon={<Icon name="cancel" />}
                variant={ButtonVariant.OUTLINED}
                onClick={clearSelectedShiftIds}
              >
                {`Clear (${selectedShiftIds.length})`}
              </S.ShiftActionButton>
            )}
            <S.ShiftActionButton
              id="select-all-button"
              variant={ButtonVariant.OUTLINED}
              onClick={handleSelectAll}
              disabled={displayedShiftIds.length === 0}
              loading={isLoadingOrRefetch}
              ml={theme.space.xxs}
              slim
            >
              Select All
            </S.ShiftActionButton>
            <S.ShiftActionButton
              id="add-workers-button"
              onClick={addWorkersToShiftsModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Add Workers
            </S.ShiftActionButton>
            <S.ShiftActionButton
              id="assign-shifts-button"
              onClick={shiftAssignmentModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Assign Shifts
            </S.ShiftActionButton>
            <S.ShiftActionButton
              id="cancel-shifts-button"
              onClick={bulkCancelShiftsModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Cancel Shifts
            </S.ShiftActionButton>
            <Button
              onClick={copyShareableUrl}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              ml={theme.space.xxs}
            >
              Copy Shareable URL
            </Button>
          </Row>
        )}
        <Row alignEnd wrap>
          <Text variant="caption" mb={theme.space.xxs}>
            Last updated: {lastUpdated}
          </Text>
          <Button
            leftIcon={<Icon name="undo" />}
            onClick={() => handleReset()}
            variant={ButtonVariant.OUTLINED}
            loading={isLoadingOrRefetch}
            style={{ width: 120 }}
            ml={theme.space.xxs}
          >
            Reset
          </Button>
          <Button
            leftIcon={<Icon name="search" />}
            onClick={() => handleRefetch()}
            variant={ButtonVariant.FILLED}
            loading={isLoadingOrRefetch}
            style={{ width: 220 }}
            ml={theme.space.xxs}
          >
            Search
          </Button>
        </Row>
      </Row>

      <FieldMonitorFilters // TODO assigned to me
        activeFilterCount={activeFilterCount}
        fieldMonitorFilters={fieldMonitorFilters}
        handleToggleFilter={handleToggleFilter}
      />
      <Row justifyBetween mt={theme.space.med}>
        <Text variant="h6">
          {isLoadingShifts ? '...' : displayTotalAndFilteredShiftCount}
        </Text>
        <Text variant="h6">{pageCounters}</Text>
        <Row alignCenter>
          <Pagination
            dataSize={shifts.length}
            onPageLeft={goToPreviousPage}
            onPageRight={goToNextPage}
            page={currentPage}
            pageSize={pageSize}
            totalFound={totalFound}
          />
          <Select
            label="Limit"
            value={pageSize.toString()}
            handleSelect={(v) => setPageSize(parseInt(v))}
            menuItems={shiftLimitOptions}
            containerStyle={{ marginLeft: theme.space.xs }}
          />
        </Row>
      </Row>
      <Row mt={theme.space.xs}>
        {isLoadingOrRefetch ? (
          <CircularProgress
            size={36}
            sx={{
              left: '50%',
            }}
          />
        ) : (
          <CollapsibleShiftTable
            shifts={shifts}
            sentinelNotifications={sentinelNotifications?.shifts ?? []}
            fieldMonitorFilters={fieldMonitorFilters}
            workerFirstName={firstName}
            workerLastName={lastName}
            roleAttributes={roleAttributes}
            assignedTo={assignedTo}
            activeFilterCount={activeFilterCount}
            selectedShiftIds={selectedShiftIds}
            setSelectedShiftIds={setSelectedShiftIds}
            showSelect
            ffMax={ffMax}
            assigneeDisplay={assigneeDisplay}
            uncollapseRow={shifts?.length === 1 ? true : false}
            onDisplayShiftsChange={handleDisplayShiftsChange}
          />
        )}
      </Row>
      <AddWorkerToShiftsModal
        handleClose={addWorkersToShiftsModal.handleClose}
        isOpen={addWorkersToShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
      <ShiftAssignmentModal
        handleClose={shiftAssignmentModal.handleClose}
        isOpen={shiftAssignmentModal.isOpen}
        shiftsList={selectedShiftsList}
        refetchAssignments={handleRefetch}
        shiftAssignmentsMapByShiftId={shiftAssignmentsMapByShiftId}
        shouldRefetchAssignmentOnEachUpdate={false}
      />
      <BulkCancelShiftsModal
        handleClose={bulkCancelShiftsModal.handleClose}
        isOpen={bulkCancelShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
      <AssignmentsDrawer
        isOpen={assignmentsOpen}
        shiftsList={shifts}
        setIsOpen={setAssignmentsOpen}
        shiftAssignmentsMapByInternalUser={shiftAssignmentsMapByInternalUser}
      />
    </>
  )
}
