import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { containsText, truncateString } from '@traba/utils'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Button, ButtonVariant } from '../Button/Button'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import { MenuItemGroup, IMenuItem } from '../Select/Select'
import { CheckedStatus } from '../Select/StyledSelectCheckbox'
import * as S from './SearchSelect.styles'
import { SearchSelectGroupTitle } from './SearchSelectGroupTitle'
import { SearchSelectItemContent } from './SearchSelectItemContent'
import { SearchSelectPlaceholder } from './SearchSelectPlaceholder'

const getOptionsInGroup = (
  groupId: string,
  options: IMenuItem[],
): IMenuItem[] => {
  return options.filter((option) => option.groupId === groupId)
}

export interface BaseSearchSelectProps<T = string>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  inputStatus?: InputStatus
  options: Array<IMenuItem<T>>
  selectItem?: IMenuItem<T>
  selectedItems?: IMenuItem<T>[]
  preselectedItems?: IMenuItem<T>[]
  hidePreselectedItemsInSelectorText?: boolean
  handleSelect?: (value: IMenuItem<T> | undefined) => void
  handleSelectMultiple?: (value: IMenuItem<T>[]) => void
  errorMessage?: string
  multiple?: boolean
  onlyShowLabel?: boolean
  width?: number | string
  isLoading?: boolean
  disabled?: boolean
  disabledTooltipText?: string
  shouldAlsoSearchSecondaryLabel?: boolean
  multipleNoneSelectedLabel?: string
  showClearButton?: boolean
  'aria-label'?: string
  selectedOnTop?: boolean
  groups?: MenuItemGroup[]
  groupByGroup?: boolean
  isGroupsSelectable?: boolean
  labelStyle?: React.CSSProperties
  selectStyle?: React.CSSProperties
  menuListStyle?: React.CSSProperties
  menuItemStyle?: React.CSSProperties
  placeholder?: string
  maxValueLength?: number
}

interface MultiSearchSelectProps<T = string> extends BaseSearchSelectProps<T> {
  multiple: true
  selectedItems: IMenuItem<T>[]
  handleSelectMultiple: (value: IMenuItem<T>[]) => void
  handleSelect?: never
}

interface StandardSearchSelectProps<T = string>
  extends BaseSearchSelectProps<T> {
  multiple?: false
  selectedItems?: IMenuItem<T>[]
  handleSelect: (value: IMenuItem<T> | undefined) => void
  handleSelectMultiple?: never
}

export type SearchSelectProps =
  | MultiSearchSelectProps
  | StandardSearchSelectProps

interface GroupedOptionsItem {
  title?: string
  groupId: string
  options: IMenuItem[]
  groupCheckedStatus?: CheckedStatus
  isGroupDisabled?: boolean
  onSelectGroup?: (e: SelectChangeEvent<string>) => void
}

type AdditionalPropsForGroupedItem = Pick<
  SearchSelectProps,
  'multiple' | 'onlyShowLabel'
>

/**
 * We are setting up the group title & its grouped options as this ReactNode array
 * because if we wrap the list of MUI MenuItem components in a fragment (as we'd
 * prefer to do in the render function), the items become unselectable. This issue
 * is described here:
 * @see {@link https://stackoverflow.com/questions/75083605/mui-the-menu-component-doesnt-accept-a-fragment-as-a-child-consider-providing}
 */
function createComponentArrayForGroupedOptions({
  groupedOptionItem,
  selectedItemValues,
  preselectedItemValues,
  additionalProps = {},
  disabledItemValues,
  menuItemStyle,
}: {
  groupedOptionItem: GroupedOptionsItem
  selectItem?: IMenuItem
  selectedItemValues?: Set<string>
  preselectedItemValues?: Set<string>
  additionalProps: AdditionalPropsForGroupedItem
  disabledItemValues?: Set<string>
  menuItemStyle?: React.CSSProperties
}): React.ReactNode[] {
  const {
    groupId,
    title,
    options,
    groupCheckedStatus,
    isGroupDisabled,
    onSelectGroup,
  } = groupedOptionItem
  if (options.length === 0) {
    return []
  }

  const componentForTitle = (
    <SearchSelectGroupTitle
      key={groupId}
      groupId={groupId}
      title={title}
      groupCheckedStatus={groupCheckedStatus}
      isGroupDisabled={isGroupDisabled}
      onSelectGroup={onSelectGroup}
    />
  )

  const optionsComponentArray = options.map((option) => {
    const isSelected = selectedItemValues?.has(option.value)
    const isPreselected = preselectedItemValues?.has(option.value)
    const isDisabled = disabledItemValues?.has(option.value)

    return (
      <MenuItem
        key={option.value}
        value={option.value}
        sx={{ fontFamily: 'Poppins' }}
        aria-label={option.label}
        disabled={isPreselected || isDisabled}
        style={{
          border: `1px ${theme.colors.Grey10} solid`,
          borderRight: 'none',
          borderLeft: 'none',
          paddingTop: theme.space.xxs,
          paddingBottom: theme.space.xxs,
          paddingLeft: additionalProps.multiple ? theme.space.xsmed : undefined,
          opacity: isPreselected || (isDisabled && isSelected) ? 1 : undefined,
        }}
      >
        <SearchSelectItemContent
          style={menuItemStyle}
          option={option}
          isSelected={isSelected || isPreselected}
          disabled={isPreselected || isDisabled}
          {...additionalProps}
        />
      </MenuItem>
    )
  })

  return [componentForTitle, ...optionsComponentArray]
}

export function SearchSelect(props: SearchSelectProps) {
  const {
    options,
    multiple,
    selectItem,
    selectedItems = [],
    preselectedItems = [],
    hidePreselectedItemsInSelectorText,
    handleSelect,
    handleSelectMultiple,
    onlyShowLabel,
    width,
    isLoading,
    disabled,
    disabledTooltipText,
    shouldAlsoSearchSecondaryLabel = false,
    multipleNoneSelectedLabel,
    showClearButton = false,
    style,
    labelStyle,
    selectStyle,
    menuListStyle,
    menuItemStyle,
    selectedOnTop,
    groups = [],
    groupByGroup,
    isGroupsSelectable,
    placeholder = '-',
    maxValueLength = 200,
    onBlur,
  } = props

  const selectedItemValues = new Set(selectedItems.map((item) => item.value))
  const preselectedItemValues = new Set(
    preselectedItems.map((item) => item.value),
  )
  const allSelectedItemValues = new Set([
    ...preselectedItemValues,
    ...selectedItemValues,
  ])
  const optionsMap = new Map(options.map((item) => [item.value, item]))
  const disabledItemValues = new Set(
    options.filter((item) => item.disabled).flatMap((item) => item.value),
  )

  const shouldGroupOptions = groupByGroup && groups.length > 0

  const [searchText, setSearchText] = useState('')

  const displayedOptions = useMemo(() => {
    // First, filter options according to the search text
    const filteredOptions = options.filter((option) => {
      const labelHasText = containsText(option.label, searchText)
      let secondaryLabelHasText = false
      if (shouldAlsoSearchSecondaryLabel && option.secondaryLabel) {
        secondaryLabelHasText = containsText(option.secondaryLabel, searchText)
      }
      return labelHasText || secondaryLabelHasText
    })

    // If multiple selection is enabled and there are selected items, sort them to the top
    const shouldSortSelectedToTop =
      multiple &&
      selectedItems.length > 0 &&
      selectedOnTop &&
      !shouldGroupOptions
    if (shouldSortSelectedToTop) {
      filteredOptions.sort((a, b) => {
        const aIsSelected = allSelectedItemValues.has(a.value)
        const bIsSelected = allSelectedItemValues.has(b.value)
        if (aIsSelected && !bIsSelected) {
          return -1
        } else if (!aIsSelected && bIsSelected) {
          return 1
        }
        return 0 // Keep original order if both are selected or not selected
      })
    }

    return filteredOptions
  }, [
    searchText,
    options,
    shouldAlsoSearchSecondaryLabel,
    multiple,
    selectedItems,
    selectedOnTop,
    shouldGroupOptions,
  ])

  const canSelectGroup = isGroupsSelectable && multiple && handleSelectMultiple
  const getGroupCheckedStatus = useCallback(
    (groupId: string): CheckedStatus | undefined => {
      if (!canSelectGroup) {
        return undefined
      }
      const optionsInGroup = getOptionsInGroup(groupId, options)

      const selectedGroupItems = optionsInGroup.filter((o) =>
        selectedItemValues.has(o.value),
      )

      if (selectedGroupItems.length === optionsInGroup.length) {
        return CheckedStatus.CHECKED
      }
      if (selectedGroupItems.length > 0) {
        return CheckedStatus.PARTIAL
      }
      return CheckedStatus.UNCHECKED
    },
    [canSelectGroup, getOptionsInGroup, selectedItemValues, options],
  )
  const getIsGroupDisabled = useCallback(
    (groupId: string) => {
      return (
        canSelectGroup &&
        getOptionsInGroup(groupId, options).every(
          (option) =>
            option.disabled || preselectedItemValues.has(option.value),
        )
      )
    },
    [canSelectGroup, getOptionsInGroup, preselectedItemValues, options],
  )

  const onSelectGroup = useCallback(
    (e: SelectChangeEvent<string>) => {
      const groupId = e.target.value

      if (canSelectGroup && groupId) {
        const optionsInGroup = getOptionsInGroup(groupId, options)
        const optionsInGroupValues = new Set(optionsInGroup.map((o) => o.value))
        const allInteractableGroupOptionsAreChecked = optionsInGroup.every(
          (option) =>
            selectedItemValues.has(option.value) ||
            preselectedItemValues.has(option.value) ||
            option.disabled,
        )

        if (allInteractableGroupOptionsAreChecked) {
          // the group checkbox was selected so deselect all group options (unless pre-selected or disabled)
          const selectedItemsWithoutDeselectedGroupItems = selectedItems.filter(
            (selectedItem) =>
              // keep items that are selected & not in the targeted group
              !optionsInGroupValues.has(selectedItem.value) ||
              // keep items that are preselected within the targeted group
              preselectedItemValues.has(selectedItem.value) ||
              // keep items that are selected & disabled in the targeted group
              disabledItemValues.has(selectedItem.value),
          )

          return handleSelectMultiple(selectedItemsWithoutDeselectedGroupItems)
        } else {
          // the group checkbox was not selected so select all in group (unless disabled)
          const unselectedOptionsInGroup = optionsInGroup.filter(
            (option) =>
              !selectedItemValues.has(option.value) && !option.disabled,
          )
          return handleSelectMultiple([
            ...selectedItems,
            ...unselectedOptionsInGroup,
          ])
        }
      }
    },
    [
      canSelectGroup,
      handleSelectMultiple,
      getGroupCheckedStatus,
      selectedItems,
      options,
    ],
  )

  // group items if we want to group them, else make one group with no group title
  const groupedOptions: GroupedOptionsItem[] = shouldGroupOptions
    ? groups
        .map(({ id: groupId, title, hideTitle }) => ({
          groupId,
          title: hideTitle ? undefined : title,
          onSelectGroup: canSelectGroup ? onSelectGroup : undefined,
          groupCheckedStatus: getGroupCheckedStatus(groupId),
          isGroupDisabled: canSelectGroup && getIsGroupDisabled(groupId),
          options: getOptionsInGroup(groupId, displayedOptions),
        }))
        .filter((g) => g.options.length > 0)
    : [{ title: undefined, groupId: '', options: displayedOptions }]

  const flatComponentListForAllGroupedOptions = groupedOptions.flatMap(
    (groupedOptionItem) => {
      const additionalProps = { multiple, onlyShowLabel }

      return createComponentArrayForGroupedOptions({
        groupedOptionItem,
        selectedItemValues: multiple
          ? selectedItemValues
          : selectItem
            ? new Set<string>([selectItem.value])
            : new Set<string>(),
        preselectedItemValues: preselectedItemValues,
        additionalProps,
        disabledItemValues,
        menuItemStyle,
      })
    },
  )

  const hasError = props.inputStatus === InputStatus.error
  const onSelect = (e: SelectChangeEvent<string | string[]>) => {
    // Logic for multiple selection
    const isResultsMultiple = Array.isArray(e.target.value)
    if (isResultsMultiple && multiple && handleSelectMultiple) {
      const updatedValues = e.target.value as string[]
      // convert to and from set to not duplicate pre-selected items
      const updatedValueSet = new Set([
        ...preselectedItemValues,
        ...updatedValues,
      ])
      const uniqueUpdatedValues = Array.from(updatedValueSet)
      const updatedSelectedItems = uniqueUpdatedValues
        .map((v) => optionsMap.get(v))
        .filter((o) => typeof o !== 'undefined') as IMenuItem[]
      return handleSelectMultiple(updatedSelectedItems)
    }

    // Logic for simple selection
    if (!Array.isArray(e.target.value)) {
      const updatedSelectedItem = optionsMap.get(e.target.value)
      if (handleSelect && updatedSelectedItem) {
        handleSelect(updatedSelectedItem)
      }
    }
  }

  const renderValue = () => {
    if (multiple) {
      const displayedItems = selectedItems.filter(
        (item) =>
          !hidePreselectedItemsInSelectorText ||
          !preselectedItemValues.has(item.value),
      )
      if (displayedItems?.length === 0) {
        return undefined
      }

      // Truncate string length to avoid container overflow
      return truncateString(
        displayedItems?.map((item) => item.label).join(', '),
        maxValueLength,
        true,
      )
    }

    return selectItem?.label || selectItem?.value
  }

  const onClearSelection = useCallback(() => {
    if (multiple && handleSelectMultiple) {
      handleSelectMultiple(preselectedItems)
    } else if (handleSelect) {
      handleSelect(undefined)
    }
  }, [handleSelect, handleSelectMultiple, multiple, preselectedItems])

  useEffect(() => {
    // select pre-selected items on component load if they are missing from selectedItems
    const missingPreselectedItems = preselectedItems.filter(
      (item) => !selectedItemValues.has(item.value),
    )
    if (
      multiple &&
      handleSelectMultiple &&
      missingPreselectedItems.length > 0
    ) {
      handleSelectMultiple([...selectedItems, ...missingPreselectedItems])
    }
  }, [])

  const value = multiple
    ? [...selectedItemValues, ...preselectedItemValues]
    : selectItem?.value || ''

  return (
    <>
      <S.SearchSelectContainer style={{ width, ...style }}>
        <S.SearchSelectBoxStyling />
        <Tooltip title={disabled ? disabledTooltipText : undefined}>
          <FormControl style={{ borderColor: theme.colors.brand }} fullWidth>
            <SearchSelectPlaceholder
              isValuePresent={multiple ? value.length > 0 : !!value}
              placeholderText={
                multiple ? multipleNoneSelectedLabel : placeholder
              }
              selectStyle={selectStyle}
            />
            <Select
              // Disables auto focus on MenuItems and allows TextField to be in focus
              MenuProps={{
                MenuListProps: {
                  style: {
                    paddingBottom: 0,
                    ...menuListStyle,
                  },
                },
                autoFocus: false,
              }}
              labelId="search-select-label"
              id="search-select"
              value={value}
              aria-label={props['aria-label']}
              onChange={onSelect}
              onBlur={(e: any) => (onBlur ? onBlur(e) : undefined)}
              onClose={() => setSearchText('')}
              renderValue={renderValue}
              displayEmpty={!!(multiple && multipleNoneSelectedLabel)}
              multiple={multiple}
              disabled={isLoading || disabled}
              sx={{
                width,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme.colors.Grey50,
                },
              }}
              error={hasError}
              style={selectStyle}
            >
              {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
              <ListSubheader>
                <TextField
                  id="search-text-field"
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: showClearButton ? (
                      <Button
                        style={{ padding: 0, paddingRight: '8px' }}
                        variant={ButtonVariant.TEXT}
                        onClick={onClearSelection}
                      >
                        {multiple ? 'Clear all' : 'Clear'}
                      </Button>
                    ) : undefined,
                  }}
                  sx={{ margin: '8px 0px 16px 0px' }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation()
                    }
                  }}
                />
              </ListSubheader>
              {flatComponentListForAllGroupedOptions}
            </Select>
          </FormControl>
        </Tooltip>
        {!!props.label && (
          <S.SearchSelectLabel style={labelStyle}>
            {props.label}
          </S.SearchSelectLabel>
        )}
      </S.SearchSelectContainer>
      {hasError && (
        <InputErrorMessage>
          <InputErrorIcon />
          {props.errorMessage}
        </InputErrorMessage>
      )}
    </>
  )
}
