import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ChangeEvent, useState } from 'react'
import { Button, Modal, Row, Input } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerVettingPhoneCallFeedback } from 'src/hooks/useWorkerVetting'

interface WorkerVettingPhoneCallFeedbackModalProps {
  isOpen: boolean
  phoneCallId?: string
  initialFeedback?: WorkerVettingPhoneCallFeedback[]
  initialOtherFeedback?: string
  isLoading?: boolean
  handleClose: () => void
  handleSubmit: (
    feedback: WorkerVettingPhoneCallFeedback[],
    callId?: string,
    otherFeedback?: string,
  ) => void
}

export function WorkerVettingPhoneCallFeedbackModal(
  props: WorkerVettingPhoneCallFeedbackModalProps,
) {
  const {
    handleClose,
    handleSubmit,
    isOpen,
    phoneCallId,
    initialFeedback,
    initialOtherFeedback,
    isLoading,
  } = props

  const [selectedFeedback, setSelectedFeedback] = useState<
    WorkerVettingPhoneCallFeedback[]
  >(initialFeedback ?? [])
  const [otherFeedback, setOtherFeedback] = useState<string>(
    initialOtherFeedback ?? '',
  )

  function handleCheck(feedback: WorkerVettingPhoneCallFeedback) {
    setSelectedFeedback((curr) => {
      if (curr.some((fb) => fb === feedback)) {
        return curr.filter((fb) => fb !== feedback)
      }
      return [...curr, feedback]
    })
  }

  return (
    <Modal
      size={MODAL_SIZE.EXTRA_EXTRA_SMALL}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Row fullWidth style={{ flexWrap: 'wrap' }} flexCol>
        <Row justifyBetween fullWidth style={{ marginBottom: theme.space.xs }}>
          <Text variant="h4">Mark Worker as Unqualified</Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.Grey60}
            size={theme.space.sm}
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </Row>

        <Divider />
        <Row flexCol fullWidth style={{ marginTop: theme.space.xs }}>
          <Text variant="h6">
            What about this call led you to disagree with AI qualifying this
            worker?
          </Text>
          <Text variant="body2">Select all reasons that apply:</Text>
        </Row>

        {Object.values(WorkerVettingPhoneCallFeedback)
          .filter((fb) => fb !== WorkerVettingPhoneCallFeedback.CORRECT)
          .map((fb) => {
            return (
              <Checkbox
                key={fb}
                checked={selectedFeedback.some((s) => s === fb)}
                label={fb[0] + fb.slice(1).toLowerCase().replaceAll('_', ' ')}
                onChange={() => handleCheck(fb)}
                style={{ marginTop: theme.space.xs }}
                textvariant="body1"
              />
            )
          })}

        {selectedFeedback.includes(
          WorkerVettingPhoneCallFeedback.OTHER_ISSUE,
        ) && (
          <Input
            value={otherFeedback}
            placeholder="Enter your feedback here"
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setOtherFeedback(ev.target.value)
            }
            style={{
              height: theme.space.xxxl,
              marginTop: theme.space.sm,
              marginBottom: theme.space.sm,
              backgroundColor: theme.colors.Grey10,
            }}
            width="100%"
          />
        )}
        <Row fullWidth justifyEnd>
          <Button
            onClick={() => {
              handleSubmit(selectedFeedback, phoneCallId, otherFeedback)
            }}
            disabled={!!isLoading || !selectedFeedback.length}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}
