import {
  AttributeBaseLevels,
  AttributeLevelType,
  AttributeSkillLevels,
  AttributeStarLevels,
  OpsAttributeLevel,
} from '@traba/types'
import { AttributeBadgeMenuOption } from './types'

export function getMenuOptionFromAttributeLevel(
  attributeLevel: OpsAttributeLevel | null,
): AttributeBadgeMenuOption | null {
  switch (attributeLevel) {
    case AttributeSkillLevels.HIGH:
      return AttributeBadgeMenuOption.EXPERT
    case AttributeSkillLevels.MEDIUM:
      return AttributeBadgeMenuOption.COMPETENT
    case AttributeSkillLevels.LOW:
      return AttributeBadgeMenuOption.BEGINNER
    case AttributeStarLevels.ONE_STAR:
      return AttributeBadgeMenuOption.ONE_STAR
    case AttributeStarLevels.TWO_STAR:
      return AttributeBadgeMenuOption.TWO_STAR
    case AttributeStarLevels.THREE_STAR:
      return AttributeBadgeMenuOption.THREE_STAR
    case AttributeStarLevels.FOUR_STAR:
      return AttributeBadgeMenuOption.FOUR_STAR
    case AttributeStarLevels.FIVE_STAR:
      return AttributeBadgeMenuOption.FIVE_STAR
    case AttributeSkillLevels.UNREPORTED:
    case AttributeBaseLevels.UNREPORTED:
    case AttributeStarLevels.UNREPORTED:
      return AttributeBadgeMenuOption.UNREPORTED
    case AttributeBaseLevels.REVOKED:
    case AttributeSkillLevels.REVOKED:
      return AttributeBadgeMenuOption.REVOKE
    case AttributeBaseLevels.PRESENT:
      return AttributeBadgeMenuOption.CONFIRM
    case AttributeSkillLevels.REPORTED:
      return AttributeBadgeMenuOption.WORKER_REPORTED
    case AttributeSkillLevels.DESELECTED:
    case AttributeBaseLevels.DESELECTED:
      return AttributeBadgeMenuOption.DESELECT
  }
  return null
}

export function getOpsLevelFromAttributeMenuOption(
  option: AttributeBadgeMenuOption,
  levelType: AttributeLevelType,
): OpsAttributeLevel {
  switch (option) {
    case AttributeBadgeMenuOption.CONFIRM:
      return AttributeBaseLevels.PRESENT
    case AttributeBadgeMenuOption.WORKER_REPORTED:
      return AttributeSkillLevels.REPORTED
    case AttributeBadgeMenuOption.UNREPORTED:
      return levelType === AttributeLevelType.BASE_LEVELING
        ? AttributeBaseLevels.UNREPORTED
        : levelType === AttributeLevelType.STAR_LEVELING
          ? AttributeStarLevels.UNREPORTED
          : AttributeSkillLevels.UNREPORTED
    case AttributeBadgeMenuOption.REVOKE:
      return levelType === AttributeLevelType.BASE_LEVELING
        ? AttributeBaseLevels.REVOKED
        : AttributeSkillLevels.REVOKED
    case AttributeBadgeMenuOption.EXPERT:
      return AttributeSkillLevels.HIGH
    case AttributeBadgeMenuOption.COMPETENT:
      return AttributeSkillLevels.MEDIUM
    case AttributeBadgeMenuOption.BEGINNER:
      return AttributeSkillLevels.LOW
    case AttributeBadgeMenuOption.FIVE_STAR:
      return AttributeStarLevels.FIVE_STAR
    case AttributeBadgeMenuOption.FOUR_STAR:
      return AttributeStarLevels.FOUR_STAR
    case AttributeBadgeMenuOption.THREE_STAR:
      return AttributeStarLevels.THREE_STAR
    case AttributeBadgeMenuOption.TWO_STAR:
      return AttributeStarLevels.TWO_STAR
    case AttributeBadgeMenuOption.ONE_STAR:
      return AttributeStarLevels.ONE_STAR
    case AttributeBadgeMenuOption.DESELECT:
      return levelType === AttributeLevelType.BASE_LEVELING
        ? AttributeBaseLevels.DESELECTED
        : AttributeSkillLevels.DESELECTED
    default:
      throw new Error('Unsupported attribute menu option')
  }
}

export function getSubtitleForMenuOption(
  option: AttributeBadgeMenuOption,
  attributeType: string,
): string | undefined {
  const customSubtitle = getCustomMenuItemSubtitleForAttributeType(
    option,
    attributeType,
  )
  if (customSubtitle) {
    return customSubtitle
  }

  switch (option) {
    case AttributeBadgeMenuOption.VERIFY:
      return 'Confirm skill level...'
    case AttributeBadgeMenuOption.CONFIRM:
      return 'Worker has attribute'
    case AttributeBadgeMenuOption.WORKER_REPORTED:
      return 'Skill level is not verified'
    case AttributeBadgeMenuOption.UNREPORTED:
      return 'Attribute is unreported'
    case AttributeBadgeMenuOption.REVOKE:
      return 'Confirmed inept'
    case AttributeBadgeMenuOption.EXPERT:
      return 'Can teach others'
    case AttributeBadgeMenuOption.COMPETENT:
      return 'Can perform without help'
    case AttributeBadgeMenuOption.BEGINNER:
      return 'Requires instruction'
    case AttributeBadgeMenuOption.DESELECT:
      return 'Attribute is deselected'
    case AttributeBadgeMenuOption.FIVE_STAR:
    case AttributeBadgeMenuOption.FOUR_STAR:
    case AttributeBadgeMenuOption.THREE_STAR:
    case AttributeBadgeMenuOption.TWO_STAR:
    case AttributeBadgeMenuOption.ONE_STAR:
      return undefined
    default:
      throw new Error('Unsupported attribute menu option')
  }
}

export function getTitleForMenuOption(
  option: AttributeBadgeMenuOption,
): string {
  switch (option) {
    case AttributeBadgeMenuOption.VERIFY:
      return 'Verify'
    case AttributeBadgeMenuOption.CONFIRM:
      return 'Confirm'
    case AttributeBadgeMenuOption.WORKER_REPORTED:
      return 'Worker Reported'
    case AttributeBadgeMenuOption.UNREPORTED:
      return 'Unreported'
    case AttributeBadgeMenuOption.REVOKE:
      return 'Revoke'
    case AttributeBadgeMenuOption.EXPERT:
      return 'Expert'
    case AttributeBadgeMenuOption.COMPETENT:
      return 'Competent'
    case AttributeBadgeMenuOption.BEGINNER:
      return 'Beginner'
    case AttributeBadgeMenuOption.FIVE_STAR:
      return '5 Star'
    case AttributeBadgeMenuOption.FOUR_STAR:
      return '4 Star'
    case AttributeBadgeMenuOption.THREE_STAR:
      return '3 Star'
    case AttributeBadgeMenuOption.TWO_STAR:
      return '2 Star'
    case AttributeBadgeMenuOption.ONE_STAR:
      return '1 Star'
    case AttributeBadgeMenuOption.DESELECT:
      return 'Deselect'
    default:
      throw new Error('Unsupported attribute menu option')
  }
}

export function getCustomMenuItemSubtitleForAttributeType(
  option: AttributeBadgeMenuOption,
  type: string,
): string | undefined {
  switch (type) {
    case 'thoroughness':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Pays attention to every detail'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Does not pay attention to details'
          : undefined
    case 'presentable':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Is friendly, articulate, and presents well'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Won’t present well for some reason'
          : undefined
    case 'physicality':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Can heavy lift for an extended period'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Has significant physical limitations'
          : undefined
    case 'leadership':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Trustworthy, sets a good example'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Unreliable, sets a bad example'
          : undefined
    case 'speed':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Performs tasks quicker than others'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Performs tasks slower than others'
          : undefined
    case 'gmp':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Complies with GMP'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Does not know or comply with GMP'
          : undefined
    case 'cold_room':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Works in cold room'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Does not work in a cold room'
          : undefined
    case 'hot_room':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Works in hot room'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Does not work in a hot room'
          : undefined
    case 'computer':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Can complete tasks on a computer'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Can’t do computer work'
          : undefined
    case 'basic_math':
      return option === AttributeBadgeMenuOption.CONFIRM
        ? 'Can do basic math calculations'
        : option === AttributeBadgeMenuOption.REVOKE
          ? 'Doesn’t have basic math skills'
          : undefined
    default:
      return undefined
  }
}
