import { useMemo } from 'react'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { useShiftRequestsWithShifts } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useShiftRequestWithShifts'
import { ScheduleInfoCard } from '../Schedule/ScheduleInfoCard'

interface ShiftDetailsScheduleInfoProps {
  shift: OpsExtendedShift
}

export const ShiftDetailsScheduleInfo = (
  props: ShiftDetailsScheduleInfoProps,
) => {
  const { shift } = props
  const { shiftRequestId } = shift
  const { shiftRequests } = useShiftRequestsWithShifts([shiftRequestId])
  const shiftRequestParentId = shiftRequests?.[0]?.shiftRequestParentId
  const { shiftRequestParent } = useShiftRequestParent(
    shiftRequestParentId ?? '',
  )
  const firstRecurringShiftRequest = useMemo(
    () =>
      shiftRequestParent?.shiftRequests?.find((sr) =>
        sr.schedules.some((s) => s.isRecurringSchedule),
      ),
    [shiftRequestParent],
  )
  if (!shiftRequestParent || !firstRecurringShiftRequest) {
    return null
  }

  return <ScheduleInfoCard shiftRequestParent={shiftRequestParent} />
}
