import { Anchor } from '@traba/react-components'
import { CopyTextIcon } from 'src/components/base'

interface PhoneLinkAndCopyButtonProps {
  phoneNumber: string
}

export function PhoneLinkAndCopyButton({
  phoneNumber,
}: PhoneLinkAndCopyButtonProps) {
  return (
    <>
      <Anchor openInNewTab={false} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </Anchor>
      <CopyTextIcon textToCopy={phoneNumber} />
    </>
  )
}
