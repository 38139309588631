import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AccountApprovalStatus,
  ExtendedShift,
  TierLevel,
  Transportation,
  WaitlistWithBasicWorkerOpsDetails,
  WorkerScores,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AccountStatusBadge,
  Button,
  CopyTextIcon,
  DataTable,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { ModalState, useModal } from 'src/components/base/Modal/Modal'
import { DataTableHeader, TableRow } from 'src/components/base/Table/DataTable'
import { PhoneNumberWithStatus } from 'src/components/PhoneNumberWithStatus'
import { WorkerNameWithIcons } from 'src/components/WorkerNameWithIcons/WorkerNameWithIcons'
import WorkerCompletions from 'src/components/WorkerTable/components/WorkerCompletions'
import {
  indexByWorkerId,
  useBulkWorkerScores,
} from 'src/hooks/useBulkWorkerScores'
import {
  useWorkerDistances,
  WorkerZipCodes,
} from 'src/hooks/useWorkerDistances'
import AcceptShiftModal from 'src/modals/AcceptShiftModal/AcceptShiftModal'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getMissingRequirementsTooltipForApprovedWorker } from 'src/utils/helperUtils'
import {
  formatPhoneNumber,
  toPercentString,
  truncateString,
} from 'src/utils/stringUtils'

export type WaitlistTableProps = {
  waitlistWorkers: WaitlistWithBasicWorkerOpsDetails[]
  shift: ExtendedShift
  favoriteWorkerIds?: Set<string>
  removeFromWaitlist: (workerId: string) => void
}

const headers: DataTableHeader[] = [
  { label: 'Worker Name', sortable: true },
  { label: 'Status' },
  { label: 'Worker Id' },
  { label: 'Phone Number' },
  { label: 'Waitlisted At', sortable: true },
  { label: 'Tier', sortable: true },
  { label: 'Reliability', sortable: true },
  { label: 'Distance (mi)', sortable: true },
  { label: '# Shifts Worked', sortable: true },
  { label: 'Transportation', sortable: true },
  { label: 'Add To Shift' },
  { label: 'Remove From Waitlist' },
]

function buildWaitlistRow(
  waitlistWorker: WaitlistWithBasicWorkerOpsDetails,
  shift: ExtendedShift,
  removeFromWaitlist: (workerId: string) => void,
  acceptShiftModal: ModalState,
  showSuccess: (message: string) => void,
  showError: (message: string, title?: string) => void,
  setSelectedWorkerId: React.Dispatch<React.SetStateAction<string>>,
  favoriteWorkerIds?: Set<string>,
  distanceFromShift?: number,
  workerScores?: WorkerScores,
): TableRow {
  const timezone = shift.timezone
  const { workerDetails } = waitlistWorker
  const workerId = workerDetails.id
  const phoneNumber = formatPhoneNumber(workerDetails.phoneNumber, true)
  const workerName = `${workerDetails.firstName} ${workerDetails.lastName}`
  const formattedWaitlistedAt = formatDateTimeWithTimezone(
    waitlistWorker.createdAt,
    timezone,
  )
  const waitlistedAtTime = new Date(waitlistWorker.createdAt).getTime()
  const handleRemoveFromWaitlist = async (
    workerId: string,
    workerName: string,
  ) => {
    try {
      await removeFromWaitlist(workerId)
      showSuccess(`${workerName} removed from waitlist`)
    } catch (error: any) {
      const errorMessage = `useWaitlist -> removeFromWaitlist() ERROR ${
        error.message ?? JSON.stringify(error)
      }`
      console.error('useWaitlist -> removeFromWaitlist() ERROR', error)
      captureSentryError(error)
      showError(errorMessage, `Error Removing ${workerName} from Waitlist`)
    }
  }

  const missingReqsTooltipText = getMissingRequirementsTooltipForApprovedWorker(
    {
      accountStatus:
        workerDetails.accountApprovalStatus as AccountApprovalStatus,
      backgroundCheckStatus: workerDetails.backgroundCheckStatus,
      backgroundCheckAdjudication: workerDetails.backgroundCheckAdjudication,
      backgroundCheckAssessment: workerDetails.backgroundCheckAssessment,
      payoutsEnabled: workerDetails.payoutsEnabled,
      payoutsDisabledDeadline: workerDetails.payoutsDisabledDeadline,
      phoneNumberStatus: workerDetails.phoneNumberStatus,
    },
  )
  const tooltipContent = missingReqsTooltipText ? (
    <span style={{ whiteSpace: 'pre-line' }}>{missingReqsTooltipText}</span>
  ) : null

  return {
    cells: [
      {
        sortKey: workerName,
        renderFn: () => (
          <Link to={`/workers/${workerId}`} target="_blank">
            <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
              <WorkerNameWithIcons
                name={workerName}
                isFavoriteWorker={favoriteWorkerIds?.has(workerId)}
                isFirstShiftWithCompany={
                  workerDetails.isFirstTimeWorkerShiftWithCompany
                }
                isFirstShiftWithTraba={workerScores?.numberOfCompletions === 0}
              />
            </Button>
          </Link>
        ),
      },
      {
        renderFn: () =>
          workerDetails.accountApprovalStatus && (
            <AccountStatusBadge
              accountStatus={workerDetails.accountApprovalStatus}
              extraWarnings={
                tooltipContent
                  ? [<Row key={'account-warnings'}>{tooltipContent}</Row>]
                  : undefined
              }
            />
          ),
      },
      {
        renderFn: () => {
          return (
            <>
              {truncateString(workerId)} <CopyTextIcon textToCopy={workerId} />
            </>
          )
        },
      },
      {
        renderFn: () => (
          <PhoneNumberWithStatus
            phoneNumber={phoneNumber}
            phoneNumberStatus={workerDetails.phoneNumberStatus}
          />
        ),
      },
      {
        sortKey: waitlistedAtTime,
        renderFn: () => formattedWaitlistedAt,
      },
      {
        sortKey:
          workerDetails.tierLevel &&
          Object.values(TierLevel).indexOf(workerDetails.tierLevel),
        renderFn: () => workerDetails.tierLevel || '-',
      },
      {
        sortKey: -1 * (workerScores?.reliabilityScore ?? 0),
        renderFn: () =>
          workerScores?.reliabilityScore != null
            ? `${toPercentString(workerScores.reliabilityScore)}%`
            : '-',
      },
      {
        sortKey: -1 * (distanceFromShift ?? 0),
        renderFn: () => distanceFromShift ?? '-',
      },
      {
        sortKey: -1 * (workerDetails.completedShifts ?? 0),
        renderFn: () => (
          <WorkerCompletions
            workerScores={workerScores}
            companyId={shift.companyId}
            roleId={shift.roleId}
          />
        ),
      },
      {
        sortKey:
          workerDetails.transportation &&
          Object.values(Transportation).indexOf(workerDetails.transportation),
        renderFn: () => workerDetails.transportation ?? '-',
      },
      {
        renderFn: () => (
          <Button
            variant={ButtonVariant.TEXT}
            style={{ color: theme.colors.Violet }}
            onClick={() => {
              setSelectedWorkerId(workerId)
              acceptShiftModal.open()
            }}
          >
            Add
          </Button>
        ),
      },
      {
        renderFn: () => (
          <Button
            variant={ButtonVariant.TEXT}
            style={{
              color: theme.colors.red,
              marginTop: theme.space.xxs,
              marginBottom: theme.space.xxs,
              paddingTop: theme.space.xxs,
              paddingBottom: theme.space.xxs,
            }}
            onClick={() => handleRemoveFromWaitlist(workerId, workerName)}
          >
            Remove
          </Button>
        ),
      },
    ],
    key: workerId,
  }
}

export default function WaitlistTable(props: WaitlistTableProps) {
  const { showError, showSuccess } = useAlert()
  const acceptShiftModal = useModal()
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('')
  const { shift, removeFromWaitlist, waitlistWorkers, favoriteWorkerIds } =
    props

  const workerZipCodes = waitlistWorkers.reduce<WorkerZipCodes>(
    (acc, { workerDetails }) => {
      if (workerDetails.zipCode) {
        acc.push({ workerId: workerDetails.id, zipCode: workerDetails.zipCode })
      }
      return acc
    },
    [],
  )

  const { workerDistances, isLoading: isLoadingCoordinates } =
    useWorkerDistances(workerZipCodes, shift.location.coords)

  const { bulkWorkerScores, isLoading: isLoadingScores } = useBulkWorkerScores(
    waitlistWorkers.map((w) => w.workerDetails.id),
  )
  const workerScoresByWorkerId = indexByWorkerId(bulkWorkerScores)

  const rows = useMemo(() => {
    if (isLoadingCoordinates || isLoadingScores) {
      return []
    }

    return waitlistWorkers.map((w) => {
      const distanceFromShift = workerDistances[w.workerDetails.id]
      const workerScores = workerScoresByWorkerId[w.workerDetails.id]
      return buildWaitlistRow(
        w,
        shift,
        removeFromWaitlist,
        acceptShiftModal,
        showSuccess,
        showError,
        setSelectedWorkerId,
        favoriteWorkerIds,
        distanceFromShift,
        workerScores,
      )
    })
  }, [
    isLoadingCoordinates,
    isLoadingScores,
    waitlistWorkers,
    workerDistances,
    favoriteWorkerIds,
    workerScoresByWorkerId,
    shift,
    removeFromWaitlist,
    acceptShiftModal,
    showSuccess,
    showError,
    setSelectedWorkerId,
  ])

  if (isLoadingCoordinates || isLoadingScores) {
    return <CircularProgress size={16} />
  }
  return (
    <>
      <DataTable headers={headers} rows={rows} initialSortByColumnIndex={4} />
      <AcceptShiftModal
        {...acceptShiftModal}
        workerIds={[selectedWorkerId]}
        shift={shift}
      />
    </>
  )
}
