import { ApplicationEntity, ApplicationItem } from './applications'
import { BasicOpsWorkerDetails } from './workers'

export enum WorkerApplicationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SYSTEM_PROCESSING = 'SYSTEM_PROCESSING',
  REVIEW_REQUIRED = 'REVIEW_REQUIRED',
  COMPLETED_ELIGIBLE = 'COMPLETED_ELIGIBLE',
  COMPLETED_INELIGIBLE = 'COMPLETED_INELIGIBLE',
}

export enum WorkerApplicationItemStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SYSTEM_PROCESSING = 'SYSTEM_PROCESSING',
  REVIEW_REQUIRED = 'REVIEW_REQUIRED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum WorkerApplicationItemCompletionStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
  OPS_WAIVED = 'OPS_WAIVED',
  OPS_MARKED_INELIGIBLE = 'OPS_MARKED_INELIGIBLE',
  OPS_MARKED_ELIGIBLE = 'OPS_MARKED_ELIGIBLE',
  INCONCLUSIVE = 'INCONCLUSIVE',
  EXPIRED = 'EXPIRED',
}

export type WorkerApplicationItem = {
  id: string
  workerApplicationId: string
  sourceId?: string
  status: WorkerApplicationItemStatus
  completionStatus?: WorkerApplicationItemCompletionStatus
  originalCompletionStatus?: WorkerApplicationItemCompletionStatus
  applicationItem: ApplicationItem
  createdAt: Date
  updatedAt: Date
}

export type WorkerApplication = {
  id: string
  applicationId: string
  workerId: string
  status: WorkerApplicationStatus
  workerApplicationItems: WorkerApplicationItem[]
  createdAt: Date
  updatedAt: Date
  applicationEntity: ApplicationEntity
}

export type WorkerApplicationWithWorkerInfo = WorkerApplication & {
  workerInfo: Pick<
    BasicOpsWorkerDetails,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'accountApprovalStatus'
    | 'phoneNumber'
    | 'tierLevel'
    | 'completedShifts'
  > & {
    reliabilityScore: number
  }
}
