import { InternalUser } from '@traba/types'
import { Reducer } from 'react'

export type UserState = {
  userProfile?: UserProfile
  isLoadingUser: boolean
  isUserLoggedIn: boolean
  isUserLoggedInFromCookie: boolean
}

export enum UserRole {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum UserRolePermission {
  StripeAccess = 'STRIPE_ACCESS',
  BackgroundCheckAccess = 'BACKGROUND_CHECK_ACCESS',
  RetoolAccess = 'RETOOL_ACCESS',
  CancelShift = 'CANCEL_SHIFT',
  DownloadWorkerShift = 'DOWNLOAD_WORKER_SHIFT',
  BusinessCancelShift = 'BUSINESS_CANCEL_SHIFT',
  SystemCancelShift = 'SYSTEM_CANCEL_SHIFT',
  ManageConnections = 'MANAGE_CONNECTIONS',
  EditWorker = 'EDIT_WORKER',
  ManageCompanySettings = 'MANAGE_COMPANY_SETTINGS',
  ManageRoleSettings = 'MANAGE_ROLE_SETTINGS',
  ManageShiftSettings = 'MANAGE_SHIFT_SETTINGS',
  RevertPayments = 'REVERT_PAYMENTS',
  ViewInvoices = 'VIEW_INVOICES',
  ViewActionCenter = 'VIEW_ACTION_CENTER',
  FlagShift = 'FLAG_SHIFT',
  SendBugle = 'SEND_BUGLE',
}

export type UserProfile = {
  firstName: string
  lastName: string
  email: string
  token: string
  role: UserRole
  internalUser?: InternalUser
} | null

export type UserAction = {
  type: 'EDIT_USER' | 'USER_LOGIN' | 'USER_LOGIN_COOKIE' | 'USER_LOGOUT'
  value?: any
}

export type UserDispatch = React.Dispatch<UserAction>
export type UserReducer = Reducer<UserState, UserAction>
