import { Role } from './companies'
import { Company } from './companies'
import { EmploymentType } from './employmentType'

export type WorkerVettingCampaign = {
  id: string
  createdAt: Date
  updatedAt: Date
  deadline?: Date
  phoneNumbers: string[]
  regionIds: string[]
  questions: string[]
  initialMessage: string
  title: string
  promptDetails?: string
  targetConversationModel?: string
  targetEvaluationModel?: string
  shiftId?: string
  roleId?: string
  initiatedBy: string
  type: WorkerVettingMethod
  status: WorkerVettingCampaignStatus
  customQualifiedExitMessage?: string
  customUnqualifiedExitMessage?: string
  activePromptContextId?: string
  vettingContext?: VettingPromptContext
}

export type WorkerVettingSession = {
  id: string
  createdAt: Date
  updatedAt: Date
  phoneNumber: string
  properties: any
  lastActiveAt: Date
  lastEvaluatedAt?: Date
  score?: number
  opsScore?: number
  summary?: string
  threadId?: string
  status: WorkerVettingSessionStatus
  workerVettingCampaignId: string
  workerId?: string
}

export type WorkerVettingSessionMessage = {
  id: string
  createdAt: Date
  updatedAt: Date
  source: WorkerVettingMessageSource
  message: string
  reviewCode?: number
  reviewReason?: string
  workerVettingSessionId: string
}

export enum WorkerVettingMessageSource {
  ops = 'OPS',
  system = 'SYSTEM',
  worker = 'WORKER',
}

export enum WorkerVettingSessionStatus {
  active = 'ACTIVE',
  complete = 'COMPLETE',
  exited = 'EXITED',
  canceled = 'CANCELED',
  stale = 'STALE',
}

export enum WorkerVettingMethod {
  call = 'CALL',
  sms = 'SMS',
  smsAndCall = 'SMS_AND_CALL',
}

export enum WorkerVettingCampaignStatus {
  active = 'ACTIVE',
  completed = 'COMPLETED',
  canceled = 'CANCELED',
}

export enum SupportedLanguages {
  english = 'en_US',
  spanish = 'es_US',
}

export type GeneralCampaignQuestion = {
  key: string
  displayName: string
}

export type CreateVettingCampaignRequest = {
  title: string
  companyIds: string[]
  roleIds?: string[]
  regionIds: string[]
  questionKeys?: string[]
  customQuestions?: string[]
}

export enum PromptContextType {
  VETTING_CALL = 'VETTING_CALL',
}

export type VettingPromptContext = {
  locationDetails: string
  scheduleDetails: string
  payRatePlainText: string
  jobTitle: string
  roleDetails?: string
  otherQuestions?: string[]
  language?: string
  employmentType?: EmploymentType
}

export type VettingConfig = {
  vettingLogistics: VettingPromptContext
  questions: string[]
  promptDetails: string
  customUnqualifiedExitMessage?: string
  customQualifiedExitMessage?: string
  initialMessage: string
}

export type StartSmsAndPhoneVettingRequest = VettingConfig & {
  vettingCampaignId: string
}

export type VettingCampaignSearchRequest = {
  title?: string
  companyIds?: string[]
  regionIds?: string[]
  roleIds?: string[]
  id?: string
  workerVettingCampaignId?: string
}

export type VettingCampaignSearchResponse = {
  campaigns: VettingCampaign[]
}

export type VettingCampaign = {
  id: string
  createdBy: string
  createdAt: string
  updatedAt: string
  title: string
  regions: string[]
  companies: Pick<Company, 'employerName' | 'companyId'>[]
  roles: Pick<Role, 'roleName' | 'roleId'>[]
  googleSheetsId: string
  lastGSheetSyncAt?: string
  workerVettingCampaigns: WorkerVettingCampaign[]
}
