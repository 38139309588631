import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { addDays, addMinutes } from 'date-fns'
import React, { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { Button, Col, DatePicker, Row } from '../../base'
import { useModal } from '../../base/Modal/Modal'
import * as S from '../styles'
import { DEFAULT_MAX_WORKERS } from './ActionsSection'
import BugleScheduleModal from './BugleScheduleModal'

interface SMSInputProps {
  parameters: any
  targetShiftId: string
  targetCompanyId: string
  savedSearchId?: string
  disabled?: boolean
  totalFound?: number
  deepLink?: string
  sentinelNotificationToUserId?: string
}

export const MESSAGE_LOCAL_STORAGE_KEY = 'bugle_message_input'

const SMSInput: React.FC<SMSInputProps> = ({
  parameters,
  savedSearchId,
  targetShiftId,
  targetCompanyId,
  disabled,
  totalFound,
  deepLink,
  sentinelNotificationToUserId,
}) => {
  const messageLocalStorageFormat = `${MESSAGE_LOCAL_STORAGE_KEY}_${targetShiftId}`

  const [message, setMessage] = useState<string>(
    localStorage.getItem(messageLocalStorageFormat) || '',
  )
  const [date, setDate] = useState<Date | null>(new Date())
  const confirmationModal = useModal()

  const [maxWorkers, setMaxWorkers] = useState<number | undefined>(
    DEFAULT_MAX_WORKERS,
  )

  const handleEditorChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value
    setMessage(newMessage)
    localStorage.setItem(messageLocalStorageFormat, newMessage)
  }

  const handleSendClick = () => {
    confirmationModal.open()
  }

  const handleAddDeeplink = () => setMessage(`${message}\n${deepLink}`)

  return (
    <div>
      <Text variant="label">
        This is a two-way messaging system. Messages will always have a{' '}
        <span style={{ fontWeight: 'bold' }}>'Traba: '</span> prefix.
      </Text>
      <S.SMSInputContainer>
        <S.TextInput
          type="textarea"
          onChange={handleEditorChange}
          value={message}
        />
      </S.SMSInputContainer>
      <Row fullWidth justifyBetween alignCenter>
        <Col mr={theme.space.xs}>
          {deepLink ? (
            <Button
              variant={ButtonVariant.OUTLINED}
              slim
              onClick={handleAddDeeplink}
            >
              Add deeplink
            </Button>
          ) : (
            <Text variant="error">No deeplink found</Text>
          )}
          <div style={{ marginTop: theme.space.xs }}>
            <DatePicker
              date={date}
              setDate={setDate}
              label="Schedule time"
              showTimeFieldInPopover
              granularity="minute"
              minDate={addMinutes(new Date(), -1)}
              maxDate={addDays(new Date(), 14)}
            />
          </div>
        </Col>

        <Col ml={theme.space.xs}>
          <DeprecatedNumberInput
            label={`Max workers (Default: ${DEFAULT_MAX_WORKERS})`}
            setter={(value) => {
              setMaxWorkers(value || undefined)
            }}
            value={maxWorkers}
          />
          {!!totalFound && (
            <Text variant="caption" ml={theme.space.xxxs}>
              Message
              {maxWorkers
                ? ` up to ${maxWorkers} `
                : ` approximately ${totalFound} `}
              workers
            </Text>
          )}
          <Button
            variant={ButtonVariant.FILLED}
            style={{ width: 220 }}
            onClick={handleSendClick}
            mt={theme.space.xxs}
            disabled={disabled || !message || !date}
          >
            Schedule messages
          </Button>
        </Col>
      </Row>
      {date && (
        <BugleScheduleModal
          {...confirmationModal}
          bugleType="SMS"
          smsMessage={message}
          parameters={parameters}
          savedSearchId={savedSearchId}
          targetShiftId={targetShiftId}
          targetCompanyId={targetCompanyId}
          targetDate={date}
          listCount={totalFound}
          maxWorkers={maxWorkers}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
    </div>
  )
}

export default SMSInput
