import { Text } from '@traba/react-components'
import { CopyTextIcon, Link } from '../../base'
import { WorkerNameWithIcons } from '../../WorkerNameWithIcons/WorkerNameWithIcons'

export function SentinelWorkerNameCell({
  params,
}: {
  params: {
    row: {
      id: string
      workerName: string
      isFirstTimeWorkerShiftWithCompany: boolean
      isFavoriteWorker: boolean
      isInvitedWorker: boolean
    }
  }
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link
        to={`/workers/${params.row.id}`}
        target="_blank"
        style={{ alignSelf: 'center', width: 100 }}
      >
        <Text
          variant="body1"
          style={{
            textAlign: 'left',
            width: 100,
          }}
        >
          <WorkerNameWithIcons
            name={params.row.workerName}
            maxLength={10} // truncate to 10 characters
            isFirstShiftWithCompany={
              params.row.isFirstTimeWorkerShiftWithCompany
            }
            isFavoriteWorker={params.row.isFavoriteWorker}
            isInvitedWorker={params.row.isInvitedWorker}
          />
        </Text>
      </Link>
      {params.row.workerName && (
        <CopyTextIcon textToCopy={params.row.workerName} />
      )}
    </div>
  )
}
