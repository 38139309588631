import {
  AccountApprovalStatus,
  AdjudicationStatus,
  BackgroundCheckAssessment,
  BackgroundCheckStatus,
  CoordinatesDto,
  DetailedBackgroundCheckStatus,
  PhoneNumberStatus,
} from '@traba/types'
import _, { isEqual } from 'lodash'
import { KILOMETER_TO_MILES_CONVERSION_FACTOR } from 'src/libs/constants'
import { calculateDetailedBackgroundCheckStatus } from './backgroundCheckUtils'

export const openInNewTab = (url: string | URL | undefined) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export function downloadBlob(dataBlob: any, filename: string) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.setAttribute('target', '_blank')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(dataBlob),
  )
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function sortByProp<T>(
  prop: keyof T,
  sortOrder: 'ASC' | 'DESC' = 'ASC',
) {
  return (a: T, b: T) => {
    if (a[prop] > b[prop]) {
      return sortOrder === 'ASC' ? 1 : -1
    }
    if (a[prop] < b[prop]) {
      return sortOrder === 'ASC' ? -1 : 1
    }
    return 0
  }
}

export function sortByNestedProp<T>(
  prop: string,
  sortOrder: 'ASC' | 'DESC' = 'ASC',
) {
  return (a: T, b: T) => {
    if (_.get(a, prop) > _.get(b, prop)) {
      return sortOrder === 'ASC' ? 1 : -1
    }
    if (_.get(a, prop) < _.get(b, prop)) {
      return sortOrder === 'ASC' ? -1 : 1
    }
    return 0
  }
}

export function getDistanceBetweenCoords(
  coordsOrigin: CoordinatesDto,
  coordsDestination: CoordinatesDto,
  inMiles = false,
): number {
  const { latitude: lat1, longitude: long1 } = coordsOrigin
  const { latitude: lat2, longitude: long2 } = coordsDestination
  const RADIUS_OF_EARTH_KM = 6371

  const deltaLat = lat2 - lat1
  const deltaLong = long2 - long1
  const radiansLat = toRad(deltaLat)
  const radiansLong = toRad(deltaLong)

  const a =
    Math.sin(radiansLat / 2) * Math.sin(radiansLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(radiansLong / 2) *
      Math.sin(radiansLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = RADIUS_OF_EARTH_KM * c

  if (inMiles) {
    return d / 1.60934
  }
  return d
}

function toRad(num: number) {
  return (num * Math.PI) / 180
}

export function trimParams<T extends Record<string, unknown>>(
  params: T,
): Partial<T> {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([, value]) => value !== undefined && value !== '',
    ),
  ) as Partial<T>
}

export async function chunkRequests<T, R>(
  asyncFn: (input: T[]) => Promise<R>,
  array: T[],
  chunkSize: number,
): Promise<R[]> {
  const results: R[] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize)
    const result = await asyncFn(chunk)
    results.push(result)
  }
  return results
}

export function getInvalidPostalCodes(postalCodes: string[]): string[] {
  const postalCodeRegExp = new RegExp(/^\d{5}$/)
  const invalidCodes: string[] = []
  for (const code of postalCodes) {
    if (!postalCodeRegExp.test(code)) {
      invalidCodes.push(code)
    }
  }
  return invalidCodes
}

export function kmToMiles(kilometers: number) {
  return kilometers * KILOMETER_TO_MILES_CONVERSION_FACTOR
}

export function valueEdited(
  originalValue: string | number | Date | boolean | undefined | any[] | null,
  currentValue: string | number | Date | boolean | undefined | any[] | null,
): boolean {
  if (Array.isArray(originalValue) && Array.isArray(currentValue)) {
    return !isEqual(originalValue, currentValue)
  }
  return originalValue instanceof Date && currentValue instanceof Date
    ? originalValue.getTime() !== currentValue.getTime()
    : originalValue !== currentValue
}

// Returns empty string if no missing requirements, or unapproved worker.
export function getMissingRequirementsTooltipForApprovedWorker({
  accountStatus,
  backgroundCheckStatus,
  backgroundCheckAdjudication,
  backgroundCheckAssessment,
  payoutsEnabled,
  payoutsDisabledDeadline,
  phoneNumberStatus,
}: {
  accountStatus: AccountApprovalStatus
  backgroundCheckStatus?: BackgroundCheckStatus | null
  backgroundCheckAdjudication?: AdjudicationStatus | null
  backgroundCheckAssessment?: BackgroundCheckAssessment | null
  payoutsEnabled?: boolean
  payoutsDisabledDeadline?: Date | null
  phoneNumberStatus?: PhoneNumberStatus
}) {
  if (
    accountStatus !== AccountApprovalStatus.Approved ||
    !backgroundCheckStatus ||
    payoutsEnabled === undefined ||
    phoneNumberStatus === undefined
  ) {
    return ''
  }
  const detailedStatus = calculateDetailedBackgroundCheckStatus(
    backgroundCheckStatus,
    backgroundCheckAdjudication || undefined,
    backgroundCheckAssessment || undefined,
  )

  const missingRequirements = []
  const bgcIncomplete =
    detailedStatus !== DetailedBackgroundCheckStatus.CLEAR &&
    detailedStatus !== DetailedBackgroundCheckStatus.COMPLETE &&
    detailedStatus !== DetailedBackgroundCheckStatus.ELIGIBLE &&
    detailedStatus !== DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW

  if (bgcIncomplete) {
    missingRequirements.push(`BGC status: ${detailedStatus}`)
  }
  if (!payoutsEnabled) {
    missingRequirements.push(`Stripe payouts: Disabled`)
  } else if (payoutsDisabledDeadline && payoutsDisabledDeadline > new Date()) {
    missingRequirements.push(`Stripe payouts: Disabled Soon`)
  }
  if (phoneNumberStatus !== PhoneNumberStatus.VERIFIED) {
    missingRequirements.push(`Phone number: ${phoneNumberStatus}`)
  }

  return missingRequirements.join('\n')
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
