import { Time } from '@internationalized/date'
import { Tooltip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  Badge,
  IMenuItem,
  Row,
  SearchSelect,
  Text,
  Input,
  Toggle,
  DatePicker,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IncentiveRules,
  IncentiveStatus,
  IncentiveTypeIds,
  RuleLine,
  ValueType,
} from '@traba/types'
import { truncateString } from '@traba/utils'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CopyTextIcon } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { useCompanies } from 'src/hooks/useCompanies'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  IncentiveFromSearch,
  IncentiveOrFields,
  IncentivesSearchParams,
  useSearchIncentives,
} from 'src/hooks/useIncentives'
import { SortOrder } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { getMoneyString } from 'src/utils/stringUtils'
import { BaseCell } from './BaseCell'
import { RegionCell } from './RegionCell'

type IncentivesForm = {
  regionIds: string[]
  companyId: IMenuItem | undefined
  incentiveType: IMenuItem | undefined
  tags: IMenuItem[]
}

const SELECT_FIELDS = {
  incentive: [
    'id',
    'status',
    'title',
    'description',
    'internalMemo',
    'typeId',
    'valueType',
    'startTime',
    'endTime',
    'shiftId',
    'totalAmount',
    'totalCurrency',
    'regionIds',
  ],
}

const DEFAULT_PAGE_SIZE = { value: '25', label: '25' }

const PAGE_SIZE_OPTIONS: IMenuItem[] = [
  DEFAULT_PAGE_SIZE,
  { value: '50', label: '50' },
  { value: '100', label: '100' },
]

const ACTIVE_OR_FIELDS: IncentiveOrFields[] = [
  IncentiveOrFields.id,
  IncentiveOrFields.shiftId,
  IncentiveOrFields.title,
  IncentiveOrFields.description,
  IncentiveOrFields.ruleType,
]

const makeIncentivesRow = (incentive: IncentiveFromSearch) => {
  return {
    id: incentive.id,
    title: incentive.title,
    description: incentive.description,
    internalMemo: incentive.internalMemo,
    total:
      incentive.totalAmount &&
      incentive.totalCurrency &&
      getMoneyString({
        amount: incentive.totalAmount,
        currency: incentive.totalCurrency,
      }),
    shiftId: incentive.shiftId,
    rules: incentive.rules,
    status: incentive.status,
    endTime: incentive.endTime,
    incentiveId: incentive.id,
    regionIds: incentive.regionIds,
  }
}

const getBadgeColor = (status: string) => {
  switch (status) {
    case 'EXPIRED':
      return 'warning'
    case 'INACTIVE':
      return 'disabled'
    case 'ACTIVE':
      return 'success'
    default:
      return 'info'
  }
}

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    renderCell: (params) => <BaseCell value={params.value} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 240,
    renderCell: (params) => <BaseCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'internalMemo',
    headerName: 'Internal Memo',
    width: 160,
    renderCell: (params) => <BaseCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'incentiveId',
    headerName: 'Incentive ID',
    width: 160,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{truncateString(params.value, 12)}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'total',
    headerName: 'Amount',
    width: 80,
    renderCell: (params) => params.value,
  },
  {
    field: 'rules',
    headerName: 'Rules count',
    width: 120,
    renderCell: (params) => {
      const rules = params.value
        .map((rule: { ruleLine: RuleLine }) => rule.ruleLine.type)
        .join(', ')
      return (
        <Row justifyBetween>
          <Tooltip title={rules}>
            <div>
              <Text>{params.value.length}</Text>
            </div>
          </Tooltip>
        </Row>
      )
    },
  },
  {
    field: 'regionIds',
    headerName: 'Regions',
    width: 120,
    renderCell: (params) => <RegionCell value={params.value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween>
        <Badge
          variant={getBadgeColor(params.value)}
          title={params.value}
          style={{ minWidth: 80, height: 25, borderRadius: 8 }}
        />
      </Row>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Date',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 200 }}>
        <Text>{format(new Date(params.value), 'MMM dd, yyyy hh:mm a')}</Text>
      </Row>
    ),
    minWidth: 200,
  },
]

/**
 * Incentives are grouped in 4 tabs with specific filtering criteria
 * We need to always show the total found for each tab, but we only fetch and paginate the incentives for the active tab
 * > Referrals: has the rule type 'worker_referral' and the typeId 'dXgY'
 * > Shift Incentives: has the typeId 'shift_cash' or 'shift_hrly'
 * > Appeasements: has the typeId 'appeasement'
 * > Engagement: has the typeId 'engagement'
 * > Misc: all other incentives
 */
const incentivesQueries = {
  referral: {
    ruleTypes: [IncentiveRules.WorkerReferral],
    typeIds: [IncentiveTypeIds.dXgY],
  },
  shift: {
    typeIds: [IncentiveTypeIds.shift_cash, IncentiveTypeIds.shift_hrly],
  },
  appeasement: {
    typeIds: [IncentiveTypeIds.appeasement],
    valueTypes: [ValueType.Voucher],
  },
  engagement: {
    typeIds: [IncentiveTypeIds.engagement],
    valueTypes: [ValueType.Voucher],
  },
  misc: {
    valueTypes: [ValueType.Voucher, ValueType.Cash, ValueType.Rate],
    typeIds: [IncentiveTypeIds.dXgY, IncentiveTypeIds.engagement],
    // All types but worker referral
    ruleTypes: [
      IncentiveRules.CertificationRequired,
      IncentiveRules.CompanyExclusive,
      IncentiveRules.HasCompletedIncentive,
      IncentiveRules.HasCompletedShift,
      IncentiveRules.LifeTimeShiftCompletions,
      IncentiveRules.NewAccount,
      IncentiveRules.ProgressOnly,
      IncentiveRules.ShiftGroup,
      IncentiveRules.ShiftsCompletionRequired,
      IncentiveRules.OnTimeArrival,
    ],
  },
}

const INCENTIVE_TYPE_OPTIONS: IMenuItem[] = Object.keys(incentivesQueries).map(
  (type) => ({
    label: capitalize(type),
    value: type,
  }),
)

export function IncentiveListTab() {
  const navigate = useNavigate()
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const { companies = [], isLoading: isLoadingCompanies } = useCompanies({
    isApproved: true,
  })

  const [searchText, setSearchText] = useState('')
  const [activeOnly, setActiveOnly] = useState(true)
  const [endTimeBefore, setEndTimeBefore] = useState<Date | null>(null)
  const [pageSize, setPageSize] = useState<IMenuItem>(DEFAULT_PAGE_SIZE)
  const [form, setForm] = useState<IncentivesForm>({
    regionIds: [],
    companyId: undefined,
    incentiveType: undefined,
    tags: [],
  })

  const debouncedSearchText = useDebounce(searchText, 500)
  const limit = useMemo(() => parseInt(pageSize.value), [pageSize])
  const companySelectOptions = useMemo(() => {
    return companies.map((c) => ({
      label: c.employerName,
      value: c.id,
    }))
  }, [companies])

  const activeSearchParams: IncentivesSearchParams = {
    statuses: activeOnly
      ? [IncentiveStatus.Active]
      : [
          IncentiveStatus.Active,
          IncentiveStatus.Inactive,
          IncentiveStatus.Expired,
        ],
    regionIds: form.regionIds?.length ? form.regionIds : undefined,
    companyId: form.companyId?.value,
    ...(form.incentiveType?.value
      ? incentivesQueries[
          form.incentiveType.value as keyof typeof incentivesQueries
        ]
      : {}),
    endTimeBefore: endTimeBefore ?? undefined,
  }

  const {
    incentives: incentivesFound,
    isLoading: isLoadingIncentives,
    totalFound,
    goToPreviousPage,
    goToNextPage,
    currentPage,
    setCurrentPage,
  } = useSearchIncentives({
    textSearchValue: debouncedSearchText,
    params: activeSearchParams,
    activeOrFields: ACTIVE_OR_FIELDS,
    paginationParams: {
      sortBy: 'endTime',
      sortOrder: SortOrder.desc,
      limit,
      offset: 0,
    },
    select: SELECT_FIELDS,
    fullTextSearchParam: debouncedSearchText,
  })

  const isLoading =
    isLoadingRegions || isLoadingIncentives || isLoadingCompanies

  const rows = useMemo(() => {
    return incentivesFound.map(makeIncentivesRow)
  }, [incentivesFound])

  const handleInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const text = event.target.value
      setSearchText(text)
      setCurrentPage(0)
    },
    [setCurrentPage],
  )

  function handleBeforeDate(newDate: Date | null) {
    setEndTimeBefore(newDate)
    setCurrentPage(0)
  }

  const handleRegionSelect = useCallback(
    (items: string[]) => {
      setForm((prevForm) => ({ ...prevForm, regionIds: items }))
      setCurrentPage(0)
    },
    [setForm, setCurrentPage],
  )

  const handleTypeSelect = useCallback(
    (value: IMenuItem | undefined) => {
      setForm((prevForm) => ({ ...prevForm, incentiveType: value }))
      setCurrentPage(0)
    },
    [setForm, setCurrentPage],
  )

  const handleCompanySelect = useCallback(
    (value: IMenuItem | undefined) => {
      setForm((prevForm) => ({ ...prevForm, companyId: value }))
      setCurrentPage(0)
    },
    [setForm, setCurrentPage],
  )

  const toggleActiveOnly = useCallback(() => {
    setActiveOnly((prev) => !prev)
    setCurrentPage(0)
  }, [setCurrentPage])

  return (
    <Row flexCol>
      <Row alignCenter mt={theme.space.sm} fullWidth gap={theme.space.xs}>
        <Input
          label={'Search by keyword or shift ID'}
          id="search"
          value={searchText}
          onChange={handleInput}
          onClear={() => setSearchText('')}
          autoComplete="off"
          containerStyle={{ marginTop: 0, flexGrow: 1, maxWidth: 500 }}
          leftIconName="search"
        />
        <SearchSelect
          options={INCENTIVE_TYPE_OPTIONS}
          selectItem={form.incentiveType}
          handleSelect={handleTypeSelect}
          label={'Incentive Type'}
          placeholder="All Types"
          onlyShowLabel
          showClearButton
          width={200}
          disabled={isLoadingRegions}
          style={{ flexGrow: 1, maxWidth: 500 }}
          selectStyle={{ width: '100%' }}
        />
        <DatePicker
          showTimeFieldInPopover={true}
          setDate={handleBeforeDate}
          isClearable={true}
          inlineLabel={true}
          label="End Times Before"
          date={endTimeBefore}
          defaultTime={new Time(0, 0)}
          containerStyle={{ flexGrow: 1, maxWidth: 500 }}
          style={{ width: '100%' }}
        />
        <Pagination
          dataSize={incentivesFound.length}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          page={currentPage}
          pageSize={limit}
          totalFound={totalFound}
        />
        <SearchSelect
          options={PAGE_SIZE_OPTIONS}
          selectItem={pageSize}
          label={'Limit'}
          handleSelect={(value) => setPageSize(value ?? DEFAULT_PAGE_SIZE)}
          onlyShowLabel
        />
      </Row>

      <Row
        alignCenter
        fullWidth
        mt={theme.space.sm}
        mb={theme.space.sm}
        gap={theme.space.xs}
      >
        <SearchSelect
          options={companySelectOptions}
          selectItem={form.companyId}
          handleSelect={handleCompanySelect}
          label={'Company'}
          placeholder="All Companies"
          onlyShowLabel
          showClearButton
          width={200}
          disabled={isLoadingCompanies}
          style={{ flexGrow: 1, maxWidth: 500 }}
          selectStyle={{ width: '100%' }}
        />
        <StateSearchSelect
          options={regions.map((r) => ({
            label: r.displayName,
            value: r.regionId,
          }))}
          selectedItems={form.regionIds}
          handleSelectMultiple={handleRegionSelect}
          label={'Region'}
          multipleNoneSelectedLabel="All Regions"
          onlyShowLabel
          multiple
          showClearButton
          width={'100%'}
          disabled={isLoadingRegions}
          style={{ flexGrow: 1, maxWidth: 500 }}
        />
        <Toggle
          label="Only Active"
          buttonState={activeOnly}
          runOnChange={toggleActiveOnly}
        />
      </Row>

      <DataGrid
        onRowClick={(row) => navigate(`/incentives/${row.id}`)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        autoHeight
        hideFooterPagination
        disableColumnFilter
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </Row>
  )
}
