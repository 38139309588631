export enum CompanyFeatureFlag {
  LOCATIONS_ASSIGNED_USER_ANNOUNCEMENT_BANNER = 'location_assigned_user_announcement_banner',
  SCHEDULES = 'schedules',
}

export enum OpsFeatureFlag {
  WORKER_PERFORMANCE = 'worker_performance',
}

export enum DynamicConfigs {
  ATS_COMPANY_ID_LIST = 'ats_company_id_list',
}
