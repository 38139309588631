import { Switch } from '@mui/material'
import { theme } from '@traba/theme'

import {
  NotificationItem,
  NotificationPreferenceStatus,
  SettingsMap,
  UserNotificationSettingType,
} from '@traba/types'
import { mapStatusToDisplayStatus } from '@traba/utils'
import { useCallback } from 'react'
import Row from '../base-components/Row'
import { Table, Td, Tr } from '../base-components/Table/Table'

import { Text } from '../base-components/Text'
import { NotificationSettingsLocationSelect } from './NotificationSettingsLocationSelect'
import { NotificationSettingsShiftsSelect } from './NotificationSettingsShiftsSelect'

interface Props {
  isAllOff: boolean
  headers: string[]
  settings: UserNotificationSettingType[]
  setIsLocationFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsShiftsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  updatedItems: SettingsMap
  setUpdatedItems: React.Dispatch<React.SetStateAction<SettingsMap>>
  setEditingSettingTypes: React.Dispatch<
    React.SetStateAction<UserNotificationSettingType[] | null>
  >
  handleUserHasNoConsent?: () => void
  hasSMSConsent?: boolean | undefined
}
const getTextColor = (isAllOff: boolean, isSettingOn: boolean) => {
  if (isAllOff) {
    return theme.colors.Grey40
  }

  if (isSettingOn) {
    return theme.colors.Violet
  }
  return theme.colors.Grey40
}

export const NotificationSettingsTable = ({
  headers,
  settings,
  setAnchorEl,
  setIsLocationFilterOpen,
  setIsShiftsFilterOpen,
  updatedItems,
  setUpdatedItems,
  setEditingSettingTypes,
  isAllOff,
  handleUserHasNoConsent,
  hasSMSConsent,
}: Props) => {
  const handleToggleSettingType = useCallback(
    (setting: NotificationItem, isSettingOn: boolean) => () => {
      if (!hasSMSConsent && handleUserHasNoConsent && !isSettingOn) {
        handleUserHasNoConsent()
        return
      }
      setUpdatedItems((prevItems) => ({
        ...prevItems,
        [setting.notificationType]: {
          ...setting,
          status: isSettingOn
            ? NotificationPreferenceStatus.NONE
            : NotificationPreferenceStatus.ALL,
        },
      }))
    },
    [hasSMSConsent, handleUserHasNoConsent, setUpdatedItems],
  )

  return (
    <Table headers={headers}>
      {settings.map((settingType, index) => {
        const setting = updatedItems?.[settingType]
        if (!setting) {
          return null
        }

        // isSettingOn is for one singular setting in a set of settings in the notifications table
        // Note: hasSMSConsent is *only* defined when the setting is an SMS setting ("undefined" otherwise),
        //   so we only need to check if hasSMSConsent is specifically *false* to determine if we're missing
        //   needed consent
        const isSettingOn =
          setting.status !== NotificationPreferenceStatus.NONE &&
          hasSMSConsent !== false
        const textColor = getTextColor(isAllOff, isSettingOn)

        const displayStatus = mapStatusToDisplayStatus(setting.status)
        return (
          <Tr key={index} style={{ height: '62px' }}>
            <Td style={{ width: '45%' }}>{setting.description}</Td>
            <Td style={{ width: '10%' }}>
              <Row alignCenter>
                <Text variant={isSettingOn ? 'h6' : 'body2'}>
                  {displayStatus}
                </Text>

                <Switch
                  checked={isSettingOn}
                  onChange={handleToggleSettingType(setting, isSettingOn)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Row>
            </Td>
            <Td>
              <NotificationSettingsShiftsSelect
                setting={setting}
                textColor={textColor}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget)
                  setIsShiftsFilterOpen(true)
                  setEditingSettingTypes([setting.notificationType])
                }}
              />
            </Td>
            <Td style={{ width: '20%' }}>
              <NotificationSettingsLocationSelect
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget)
                  setIsLocationFilterOpen(true)
                  setEditingSettingTypes([setting.notificationType])
                }}
                textColor={textColor}
                selectedLocationLength={setting.locationIds?.length ?? 0}
                isSettingOn={isSettingOn}
              />
            </Td>
          </Tr>
        )
      })}
    </Table>
  )
}
