import { differenceInMinutes, addMinutes, subMinutes } from 'date-fns'

export function getEarlyArrivalTimeBufferInMinutes({
  businessStartTime,
  shiftStartTime,
}: {
  businessStartTime?: Date | null
  shiftStartTime?: Date
}): number {
  if (!businessStartTime || !shiftStartTime) {
    return 0
  }
  return differenceInMinutes(
    new Date(businessStartTime),
    new Date(shiftStartTime),
  )
}

export function getBusinessStartTime({
  shiftStartTime,
  earlyArrivalTimeBufferMinutes,
}: {
  shiftStartTime: Date
  earlyArrivalTimeBufferMinutes: number
}): Date {
  return addMinutes(shiftStartTime, earlyArrivalTimeBufferMinutes)
}

export function getShiftStartTime({
  businessStartTime,
  earlyArrivalTimeBufferMinutes,
}: {
  businessStartTime: Date
  earlyArrivalTimeBufferMinutes: number
}): Date {
  return subMinutes(businessStartTime, earlyArrivalTimeBufferMinutes)
}
