import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material'
import {
  Row,
  SvgIcon,
  Text,
  WorkerAvailabilityText,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftAvailability } from '@traba/types'
import { convertWorkerMetricsToWorkerScores } from '@traba/utils'
import { memo } from 'react'
import { EmploymentTypeBadge } from 'src/components/base/Badge/EmploymentTypeBadge'
import { PhoneNumberWithStatus } from 'src/components/PhoneNumberWithStatus'
import WorkerCompletions from 'src/components/WorkerTable/components/WorkerCompletions'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getMissingRequirementsTooltipForApprovedWorker } from 'src/utils/helperUtils'
import { AccountStatusBadge } from '../../../../components/base'
import { getAccountStatusColor } from '../../../../utils/workerUtils'
import { WorkerDistanceText } from '../WorkerDistanceText'
import * as S from './styles'

type WorkerListItemProps = {
  worker: PopulatedWorker
  index: number
  onAvatarClick?: (worker: PopulatedWorker) => void
  handleSelection?: (worker: PopulatedWorker) => void
  onWorkerHover?: (worker?: PopulatedWorker) => void
  workerIsSelected?: boolean
  showIndex?: boolean
  actions?: React.ReactNode
  // A custom HTML id for the checkbox to prevent React from confusing two list
  // items with the same id.
  customKey?: string
  workerDistance?: number
  workerAvailability?: WorkerShiftAvailability
  companyId?: string
  roleId?: string
}

const WorkerListItem = memo(
  ({
    worker,
    handleSelection,
    workerIsSelected,
    onAvatarClick,
    onWorkerHover,
    index,
    showIndex,
    actions,
    customKey,
    workerDistance,
    workerAvailability,
    companyId,
    roleId,
  }: WorkerListItemProps) => {
    const missingReqsTooltipText =
      getMissingRequirementsTooltipForApprovedWorker({
        accountStatus: worker.accountStatus.accountStatus,
        backgroundCheckStatus: worker.accountStatus.backgroundCheck?.status,
        backgroundCheckAdjudication:
          worker.accountStatus.backgroundCheck?.adjudication,
        backgroundCheckAssessment:
          worker.accountStatus.backgroundCheck?.assessment,
        payoutsEnabled: worker.accountStatus.payment?.payoutsEnabled,
        payoutsDisabledDeadline:
          worker.accountStatus.payment?.payoutsDisabledDeadline,
        phoneNumberStatus: worker.accountStatus.phoneNumberStatus,
      })
    const tooltipContent = missingReqsTooltipText ? (
      <span style={{ whiteSpace: 'pre-line' }}>{missingReqsTooltipText}</span>
    ) : null

    const allTimeMetrics = worker.workerMetric?.metrics?.find(
      (metric) => metric.type === 'ALL_TIME',
    )
    const workerScores =
      worker.workerMetric && allTimeMetrics !== undefined
        ? convertWorkerMetricsToWorkerScores(
            worker.workerMetric,
            allTimeMetrics,
          )
        : undefined

    return (
      <label htmlFor={customKey || `checkbox-${worker.id}`}>
        <ListItem
          disablePadding
          secondaryAction={
            handleSelection ? (
              <Checkbox
                id={customKey || `checkbox-${worker.id}`}
                checked={Boolean(
                  workerIsSelected ||
                    workerAvailability?.isAlreadySignedUpForShift,
                )}
                disabled={Boolean(
                  workerAvailability?.isAlreadySignedUpForShift,
                )}
                onChange={() => handleSelection(worker)}
              />
            ) : null
          }
          onMouseOver={() => (onWorkerHover ? onWorkerHover(worker) : null)}
          sx={{
            backgroundColor: index % 2 === 0 ? '#f3f3f3' : '#fff',
          }}
        >
          <ListItemButton
            sx={{ justifyContent: 'space-between' }}
            onClick={() =>
              !handleSelection
                ? window.open(
                    `${window.location.origin}/workers/${worker.id}`,
                    '_blank',
                  )
                : undefined
            }
          >
            {showIndex && (
              <S.CounterWrapper>
                <Text variant="body1" pr={theme.space.xs}>
                  {index + 1}
                </Text>
              </S.CounterWrapper>
            )}
            <ListItemAvatar>
              <S.AvatarWrapper>
                <Avatar
                  alt={`worker_${worker.id}_avatar`}
                  src={worker.photoUrl}
                  sizes="40px"
                  onClick={() =>
                    onAvatarClick ? onAvatarClick(worker) : undefined
                  }
                />
              </S.AvatarWrapper>
            </ListItemAvatar>
            <S.WorkerItemCard>
              {worker.firstName} {worker.lastName}
            </S.WorkerItemCard>
            {worker.accountStatus.activeEmploymentTypes && (
              <EmploymentTypeBadge
                employmentTypes={worker.accountStatus.activeEmploymentTypes}
              />
            )}
            <S.WorkerItemCard>
              <PhoneNumberWithStatus
                phoneNumber={worker.phoneNumber}
                phoneNumberStatus={worker.accountStatus.phoneNumberStatus}
              />
            </S.WorkerItemCard>
            {workerDistance ? (
              <S.WorkerItemCard>
                {WorkerDistanceText(workerDistance)}
              </S.WorkerItemCard>
            ) : (
              worker.regionId && (
                <S.WorkerItemCard>{worker.regionId}</S.WorkerItemCard>
              )
            )}
            {worker.workerMetric?.tierLevel && (
              <S.WorkerItemCard>
                {worker.workerMetric?.tierLevel}
              </S.WorkerItemCard>
            )}
            <S.WorkerItemCard
              style={{
                color: getAccountStatusColor(
                  worker.accountStatus.accountStatus,
                ),
              }}
            >
              <AccountStatusBadge
                accountStatus={worker.accountStatus.accountStatus}
                approvalStatusReason={worker.accountStatus.approvalStatusReason}
                accountSuspensionExpiration={
                  worker.accountStatus.suspensionExpiration
                }
                workerId={worker.id}
                activeEmploymentTypes={
                  worker.accountStatus.activeEmploymentTypes ||
                  worker.activeEmploymentTypes
                }
                extraWarnings={
                  tooltipContent
                    ? [
                        <Row key={'account-warnings'} flexCol>
                          <Text variant="h6" mb={theme.space.xxxs}>
                            Warnings
                          </Text>
                          <Text>{tooltipContent}</Text>
                        </Row>,
                      ]
                    : undefined
                }
              />
              {tooltipContent && (
                <SvgIcon
                  name="alert"
                  size={24}
                  color={theme.colors.Red50}
                  style={{ marginLeft: theme.space.xxxs }}
                />
              )}
            </S.WorkerItemCard>
            {workerAvailability && (
              <S.WorkerItemCard>
                {WorkerAvailabilityText({
                  availability: workerAvailability,
                  short: true,
                })}
              </S.WorkerItemCard>
            )}
            {workerScores !== undefined && (
              <S.WorkerItemCard>
                <WorkerCompletions
                  workerScores={workerScores}
                  companyId={companyId}
                  roleId={roleId}
                  short={false}
                />
              </S.WorkerItemCard>
            )}
            {actions && <S.WorkerItemCard>{actions}</S.WorkerItemCard>}
          </ListItemButton>
        </ListItem>
      </label>
    )
  },
)

export { WorkerListItem }
