import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'

async function postShiftCardToClickup(shiftId: string) {
  const { data } = await trabaApi.post('clickup/create/task', { shiftId })
  return data
}

export function useClickup(shiftId: string) {
  const mutation = useMutation({
    mutationFn: () => postShiftCardToClickup(shiftId),
  })

  return {
    postToClickup: mutation.mutateAsync,
    isPending: mutation.isPending,
    clickupResult: mutation.data,
  }
}
