import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { InvoiceStatementData } from '@traba/types'
import { captureSentryError } from '@traba/utils'

const INVOICE_PREVIEW_QUERY_KEY = 'invoice-preview'

async function getInvoiceStatementData(invoiceId: string) {
  try {
    const response = await trabaApi.get<InvoiceStatementData>(
      `custom-reports/${invoiceId}/invoice-statement-data`,
      { timeout: 3000 }, // a little buffer since we set the timeout for rendering the pdf through gotenberg at 5 seconds
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export function useInvoicePreview(invoiceId: string) {
  const { data, isLoading, error } = useQuery({
    queryKey: [INVOICE_PREVIEW_QUERY_KEY, invoiceId],
    queryFn: () => getInvoiceStatementData(invoiceId),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!invoiceId,
  })

  return {
    invoiceStatementData: data,
    isLoading,
    error,
  }
}
