import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { ScheduleShiftRequestInvitation } from '@traba/types'
import { SHIFT_INVITATIONS_QUERY_KEY } from './useShiftInvitations'

interface SendScheduleInvitationParams {
  companyId: string
  shiftRequestId: string
  workerIds: string[]
}
const sendScheduleInvitation = async ({
  companyId,
  shiftRequestId,
  workerIds,
}: SendScheduleInvitationParams) => {
  const response = await trabaApi.post(
    `companies/${companyId}/shift-request/${shiftRequestId}/invitations`,
    { workerIds },
  )
  return response.data
}

const getScheduleInvitations = async (shiftRequestParentId: string) => {
  try {
    const response = await trabaApi.get(
      `shift-request-parents/${shiftRequestParentId}/invitations`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

interface RescindScheduleInvitationParams {
  shiftRequestId: string
  workerId: string
}
const rescindScheduleInvitation = async ({
  shiftRequestId,
  workerId,
}: RescindScheduleInvitationParams) => {
  const response = await trabaApi.patch(
    `/shift-requests/${shiftRequestId}/invitations/${workerId}/rescind`,
  )
  return response.data
}

export const useScheduleInvitations = (shiftRequestParentId: string) => {
  const { showSuccess, handleError } = useAlert()
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: scheduleInvitations,
    error,
    isFetched: isRolesFetched,
    refetch,
  } = useQuery<ScheduleShiftRequestInvitation[], Error>({
    queryKey: ['schedule-invitations', shiftRequestParentId],
    queryFn: () => getScheduleInvitations(shiftRequestParentId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const rescindScheduleInvitationMutation = useMutation<
    ScheduleShiftRequestInvitation,
    Error,
    RescindScheduleInvitationParams
  >({
    mutationFn: rescindScheduleInvitation,
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({
        queryKey: [SHIFT_INVITATIONS_QUERY_KEY],
      })
      showSuccess('Invitation rescinded')
    },
    onError: (error) => {
      handleError(
        error,
        'useScheduleInvitations -> rescindScheduleInvitation',
        'Failed to send invitation',
      )
    },
  })

  const sendScheduleInvitationMutation = useMutation<
    ScheduleShiftRequestInvitation,
    Error,
    SendScheduleInvitationParams
  >({
    mutationFn: sendScheduleInvitation,
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({
        queryKey: [SHIFT_INVITATIONS_QUERY_KEY],
      })
      showSuccess('Invitation for shift schedule successfully sent')
    },
    onError: (error) => {
      handleError(
        error,
        'useScheduleInvitations -> sendScheduleInvitation',
        'Failed to send invitation',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    isRolesFetched,
    scheduleInvitations,
    sendScheduleInvitation: sendScheduleInvitationMutation.mutate,
    isSendingScheduleInvitation: sendScheduleInvitationMutation.isPending,
    refetch,
    rescindScheduleInvitation: rescindScheduleInvitationMutation.mutate,
    isRescindingScheduleInvitation: rescindScheduleInvitationMutation.isPending,
  }
}
