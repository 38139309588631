import { OpsActionCategory, OpsActionType } from '@traba/types'
import { assertUnreachable } from './errorUtils'

// this does not cascade because we want to be explicit
export function mapOpsActionTypeToCategory(
  actionType: OpsActionType,
): OpsActionCategory {
  switch (actionType) {
    case OpsActionType.I9_REQUIRES_REVIEW:
      return OpsActionCategory.I9
    case OpsActionType.I9_NUDGE:
      return OpsActionCategory.I9
    case OpsActionType.I9_AUTHORIZED_REPRESENTATIVE_NUDGE:
      return OpsActionCategory.I9
    case OpsActionType.E_VERIFY_SUBMISSION_RETURNED_WITH_PROBLEMS:
      return OpsActionCategory.E_VERIFY
    case OpsActionType.E_VERIFY_FAILED:
      return OpsActionCategory.TERMINATION
    case OpsActionType.WORKER_FAILED_TO_RESPOND_TO_E_VERIFY_ISSUES:
      return OpsActionCategory.TERMINATION
    case OpsActionType.WORKER_REQUESTED_RESIGNATION:
      return OpsActionCategory.TERMINATION
    case OpsActionType.I9_NOT_SUBMITTED:
      return OpsActionCategory.TERMINATION
    case OpsActionType.POST_ADVERSE_BACKGROUND_CHECK:
      return OpsActionCategory.TERMINATION
    case OpsActionType.I9_CHANGE_REQUESTED:
      return OpsActionCategory.I9
    default:
      return OpsActionCategory.BGC
  }
}

export const mapCategoryToOpsActionType = (
  category: OpsActionCategory,
): OpsActionType[] => {
  return Object.values(OpsActionType).filter((actionType) => {
    return mapOpsActionTypeToCategory(actionType) === category
  })
}

export function mapOpsActionCategoryToReadableText(
  actionCategory: OpsActionCategory,
): string {
  switch (actionCategory) {
    case OpsActionCategory.I9:
      return 'I-9'
    case OpsActionCategory.E_VERIFY:
      return 'E-Verify'
    case OpsActionCategory.BGC:
      return 'BGC'
    case OpsActionCategory.TERMINATION:
      return 'Termination'
    default:
      assertUnreachable(actionCategory)
  }
}

export function mapOpsActionTypeToReadableText(
  actionType: OpsActionType,
): string {
  switch (actionType) {
    case OpsActionType.I9_REQUIRES_REVIEW:
      return 'I-9 requires review'
    case OpsActionType.I9_NOT_SUBMITTED:
      return 'I-9 not submitted'
    case OpsActionType.I9_CHANGE_REQUESTED:
      return 'I-9 change requested'
    case OpsActionType.I9_NUDGE:
      return 'Nudge worker to complete I-9'
    case OpsActionType.I9_AUTHORIZED_REPRESENTATIVE_NUDGE:
      return 'Nudge worker to contact authorized representative'
    case OpsActionType.E_VERIFY_SUBMISSION_RETURNED_WITH_PROBLEMS:
      return 'E-Verify submission has problems'
    case OpsActionType.WORKER_FAILED_TO_RESPOND_TO_E_VERIFY_ISSUES:
      return 'Worker failed to respond to E-Verify issues'
    case OpsActionType.E_VERIFY_FAILED:
      return 'E-Verify failed'
    case OpsActionType.WORKER_REQUESTED_RESIGNATION:
      return 'Worker requested resignation'
    case OpsActionType.POST_ADVERSE_BACKGROUND_CHECK:
      return 'Post adverse background check'
    default:
      return actionType
  }
}

export function getActionText(actionType: OpsActionType): string | undefined {
  switch (actionType) {
    case OpsActionType.I9_NUDGE:
      return 'Nudge'
    case OpsActionType.I9_AUTHORIZED_REPRESENTATIVE_NUDGE:
      return 'Nudge'
    case OpsActionType.I9_CHANGE_REQUESTED:
      return 'Nudge'
    default:
      return undefined
  }
}
