import {
  ArchiveCostCenter,
  Button,
  ButtonVariant,
  Card,
  Col,
  CostCenter,
  CreateOrEditCostCenterModal,
  LoadingSpinner,
  Modal,
  Row,
  Text,
  useModal,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CostCenterType } from '@traba/types'
import React, { useCallback, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import {
  useCostCenters,
  useCostCentersMutations,
} from 'src/hooks/useCostCenters'

export function CostCentersScreen(props: {
  companyId: string
}): React.JSX.Element {
  const { companyId } = props

  const { data: costCenters, isFetchingNextPage: isFetchingMoreCostCenters } =
    useCostCenters(companyId)

  const { createCostCenter, updateCostCenter, deleteCostCenter } =
    useCostCentersMutations(companyId)

  const [selectedCostCenter, setSelectedCostCenter] = useState<
    CostCenterType | undefined
  >(undefined)

  const createOrEditModal = useModal()
  const archiveModal = useModal()

  const openCreateModal = useCallback(() => {
    setSelectedCostCenter(undefined)
    createOrEditModal.open()
  }, [createOrEditModal])

  const openEditModal = useCallback(
    (costCenter: CostCenterType) => {
      setSelectedCostCenter(costCenter)
      createOrEditModal.open()
    },
    [createOrEditModal],
  )

  const openArchiveModal = useCallback(
    (costCenter: CostCenterType) => {
      setSelectedCostCenter(costCenter)
      archiveModal.open()
    },
    [archiveModal],
  )

  return (
    <>
      <Row alignCenter justifyBetween mb={theme.space.xs}>
        <Text mb={theme.space.xxs} variant="h4">
          Cost Centers
        </Text>
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={openCreateModal}
        >
          New Cost Center
        </Button>
      </Row>
      <Col gap={theme.space.xs}>
        {costCenters && costCenters.length > 0 ? (
          costCenters.map((costCenter) => (
            <Card key={costCenter.id}>
              <CostCenter
                costCenter={costCenter}
                edit={openEditModal}
                archive={openArchiveModal}
                userCanEdit={true}
              />
            </Card>
          ))
        ) : isFetchingMoreCostCenters ? (
          <LoadingSpinner />
        ) : (
          <Text variant="body2">No cost centers found</Text>
        )}
      </Col>
      <CreateOrEditCostCenterModal
        {...createOrEditModal}
        companyId={companyId}
        costCenter={selectedCostCenter}
        createCostCenter={createCostCenter}
        updateCostCenter={updateCostCenter}
        handleClose={() => {
          createOrEditModal.handleClose()
          setSelectedCostCenter(undefined)
        }}
      />
      <Modal {...archiveModal}>
        {selectedCostCenter && (
          <ArchiveCostCenter
            costCenter={selectedCostCenter}
            handleClose={() => {
              archiveModal.handleClose()
              setSelectedCostCenter(undefined)
            }}
            deleteCostCenter={deleteCostCenter}
          />
        )}
      </Modal>
    </>
  )
}
