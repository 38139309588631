import {
  Button,
  ButtonVariant,
  DataTable,
  IMenuItem,
  Input,
  Link,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { getStrikeReasonLabel, Strike, StrikeReason } from '@traba/types'
import { useMemo, useState } from 'react'
import { Col, Row } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { DataTableHeader } from 'src/components/base/Table/DataTable'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useSearchWorkersWithStrikes } from 'src/hooks/useSearchStrikes'

const DEFAULT_PAGE_SIZE = 200

const renderStrikeProperty = (strikes: Strike[], property: keyof Strike) => {
  return strikes.map((strike) => {
    let displayValue = strike[property]
    if (displayValue instanceof Date) {
      displayValue = strike.createdAt.toLocaleDateString()
    }
    if (property === 'reason') {
      displayValue = getStrikeReasonLabel(displayValue as StrikeReason)
    }
    return (
      <Row key={`${strike.id}-${property}`}>
        <Text>{displayValue}</Text>
      </Row>
    )
  })
}

export const WorkerStrikesTable = () => {
  const [selectedRequirements, setSelectedRequirements] = useState<
    IMenuItem<string>[]
  >([])
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const { currentPage, goToNextPage, goToPreviousPage } = useBasicPagination()
  const { workersWithStrikes, isFetching } = useSearchWorkersWithStrikes({
    page: currentPage,
  })

  const groupedStatusOptions = Object.values(StrikeReason).map((value) => ({
    value,
    label: getStrikeReasonLabel(value),
  }))

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker name',
    },
    {
      key: 'infractions',
      label: 'Infractions',
    },
    {
      key: 'currentStrikePoints',
      label: 'Current Strikes',
    },
    {
      key: 'eventDate',
      label: 'Event Date',
    },
    {
      key: 'shiftId',
      label: 'Shift ID',
    },
    {
      key: 'eventPoints',
      label: 'Event Points',
    },
    {
      key: 'eventType',
      label: 'Event Type',
    },
    {
      key: 'confirmed',
      label: 'Confirmed',
    },
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workersWithStrikes?.map((workersWithStrikes) => ({
        key: workersWithStrikes.id,
        cells: [
          {
            key: `${workersWithStrikes.id}-name`,
            content: `${workersWithStrikes.firstName} ${workersWithStrikes.lastName}`,
            renderFn: () => {
              const fullName = `${workersWithStrikes.firstName} ${workersWithStrikes.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link to={`/workers/${workersWithStrikes.id}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-infractions`,
            content: workersWithStrikes.strikes.length,
            renderFn: () => {
              return <Text>{workersWithStrikes.strikes.length}</Text>
            },
          },
          {
            key: `${workersWithStrikes.id}-currentStrikePoints`,
            content: workersWithStrikes.currentStrikePoints,
            renderFn: () => {
              return <Text>{workersWithStrikes.currentStrikePoints}</Text>
            },
          },
          {
            key: `${workersWithStrikes.id}-event-date`,
            content: workersWithStrikes.strikes.map(
              (strike) => strike.createdAt,
            ),
            renderFn: () => {
              return renderStrikeProperty(
                workersWithStrikes.strikes,
                'createdAt',
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-shift-id`,
            content: workersWithStrikes.strikes.map((strike) => strike.shiftId),
            renderFn: () => {
              return renderStrikeProperty(workersWithStrikes.strikes, 'shiftId')
            },
          },
          {
            key: `${workersWithStrikes.id}-event-points`,
            content: workersWithStrikes.strikes.map(
              (strike) => strike.strikePoints,
            ),
            renderFn: () => {
              return renderStrikeProperty(
                workersWithStrikes.strikes,
                'strikePoints',
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-event-type`,
            content: workersWithStrikes.strikes.map((strike) => strike.reason),
            renderFn: () => {
              return renderStrikeProperty(workersWithStrikes.strikes, 'reason')
            },
          },
          {
            key: `${workersWithStrikes.id}-confirmed`,
            content: workersWithStrikes.strikes.map((strike) => strike.proven),
            renderFn: () => {
              return renderStrikeProperty(workersWithStrikes.strikes, 'proven')
            },
          },
        ],
      })) || []
    )
  }, [workersWithStrikes])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onClear={() => setFirstName('')}
          />
        </Col>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onClear={() => setLastName('')}
          />
        </Col>
        <Col>
          <Row
            gap={theme.space.xxs}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Pagination
              dataSize={workersWithStrikes.length}
              onPageLeft={goToPreviousPage}
              onPageRight={goToNextPage}
              page={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
            />
          </Row>
        </Col>
      </Row>
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'start',
        }}
        alignCenter
      >
        <SearchSelect
          multiple
          options={groupedStatusOptions}
          selectedItems={selectedRequirements}
          handleSelectMultiple={setSelectedRequirements}
          label={`Category`}
          style={{ width: '20%' }}
        />
      </Row>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={0}
        />
      )}
    </Col>
  )
}
