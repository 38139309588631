import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { IconName, W9FormStatus } from '@traba/types'
import { Row } from 'src/components/base'
import { Dropdown, DropdownElement } from 'src/components/base/Dropdown'

type W9FormStatusIndicatorProps = {
  w9FormStatus: W9FormStatus
  hasActiveAction?: boolean
  onUpdateAction?: (
    hasActiveAction: boolean,
    w9FormStatus?: W9FormStatus,
  ) => void
  isLoading?: boolean
}

const statusConfig = {
  [W9FormStatus.Verified]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [W9FormStatus.Requested]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Requested',
  },
  [W9FormStatus.Unverified]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Unverified',
  },
  [W9FormStatus.Pending]: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Pending',
  },
  ACTIVE_ACTION: {
    iconName: 'notificationFilled' as IconName,
    color: theme.colors.Yellow60,
    text: 'Pushed',
  },
}

const getDropdownOptions = (
  hasActiveAction: boolean,
  onUpdateAction?: (
    hasActiveAction: boolean,
    w9FormStatus?: W9FormStatus,
  ) => void,
  status?: W9FormStatus,
  isLoading?: boolean,
): DropdownElement[] => {
  if (!onUpdateAction) {
    return []
  }

  const isDisabled =
    isLoading ||
    status === W9FormStatus.Verified ||
    status === W9FormStatus.Pending

  let buttonLabel = 'Push In App'
  if (!hasActiveAction && !status) {
    buttonLabel = 'Request Form'
  } else if (hasActiveAction) {
    buttonLabel = 'Lift Action'
  }

  return [
    {
      label: buttonLabel,
      handleClick: () => onUpdateAction(hasActiveAction, status),
      disabled: isDisabled,
      icon: () => (
        <SvgIcon name={hasActiveAction ? 'unblock' : 'notification'} />
      ),
    },
  ]
}

export function W9FormStatusIndicator({
  w9FormStatus,
  hasActiveAction = false,
  onUpdateAction,
  isLoading = false,
}: W9FormStatusIndicatorProps) {
  const config = hasActiveAction
    ? statusConfig.ACTIVE_ACTION
    : statusConfig[w9FormStatus] || statusConfig[W9FormStatus.Unverified]

  const dropdownOptions = getDropdownOptions(
    hasActiveAction,
    onUpdateAction,
    w9FormStatus,
    isLoading,
  )

  return (
    <Row style={{ gap: theme.space.xxs, alignItems: 'center' }}>
      <SvgIcon name={config.iconName} size={24} color={config.color} />
      {config.text && onUpdateAction ? (
        <Dropdown
          orientation="left"
          dropdownOptions={dropdownOptions}
          listContainerStyling={{ width: '175px' }}
          listItemStyling={{
            alignItems: 'center',
            color: theme.colors.Violet80,
            fontWeight: '500',
            fontSize: 14,
          }}
          buttonStyling={{
            padding: 0,
            color: theme.colors.MidnightBlue,
            fontSize: 14,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            minWidth: 'fit-content',
          }}
          title={config.text}
          containerStyling={{ width: 'auto' }}
        />
      ) : (
        config.text && <Text variant="h6">{config.text}</Text>
      )}
    </Row>
  )
}
