import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ActionType,
  AdjudicationStatus,
  BackgroundCheckAssessment,
  BackgroundCheckStatus,
  DetailedBackgroundCheckStatus,
  WorkerRequirementAction,
} from '@traba/types'
import { calculateDetailedBackgroundCheckStatus } from 'src/utils/backgroundCheckUtils'
import { Dropdown, DropdownElement } from '../../../components/base/Dropdown'

export type BackgroundCheckStatusBadgeProps = {
  status: BackgroundCheckStatus
  adjudication?: AdjudicationStatus
  assessment?: BackgroundCheckAssessment
  activeAction?: WorkerRequirementAction
  onActionUpdate: (hasActiveAction: boolean, actionType: ActionType) => void
  isLoading: boolean
}

type StatusBadgeConfig = {
  variant: string
  title: string
}

const bgcStatusToBadgeConfig: Record<
  DetailedBackgroundCheckStatus,
  StatusBadgeConfig
> = {
  [DetailedBackgroundCheckStatus.PENDING]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.SUSPENDED]: {
    variant: 'danger',
    title: 'Suspended',
  },
  [DetailedBackgroundCheckStatus.CANCELED]: {
    variant: 'danger',
    title: 'Canceled',
  },
  [DetailedBackgroundCheckStatus.INVITATION_CREATED]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.DISPUTE]: {
    variant: 'warning',
    title: 'Dispute',
  },
  [DetailedBackgroundCheckStatus.CONSIDER]: {
    variant: 'warning',
    title: 'Consider',
  },
  [DetailedBackgroundCheckStatus.REPORT_CREATED]: {
    variant: 'info',
    title: 'Started',
  },
  [DetailedBackgroundCheckStatus.CLEAR]: { variant: 'success', title: 'Clear' },
  [DetailedBackgroundCheckStatus.COMPLETE]: {
    variant: 'success',
    title: 'Complete',
  },
  [DetailedBackgroundCheckStatus.POST_ADVERSE_ACTION]: {
    variant: 'danger',
    title: 'Post-adverse action',
  },
  [DetailedBackgroundCheckStatus.PRE_ADVERSE_ACTION]: {
    variant: 'warning',
    title: 'Pre-adverse action',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE]: {
    variant: 'success',
    title: 'Eligible',
  },
  [DetailedBackgroundCheckStatus.REVIEW]: {
    variant: 'warning',
    title: 'Review',
  },
  [DetailedBackgroundCheckStatus.ESCALATED]: {
    variant: 'danger',
    title: 'Escalated',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW]: {
    variant: 'success',
    title: 'Eligible After Review',
  },
}

const getDropdownOptions = (
  detailedStatus: DetailedBackgroundCheckStatus,
  activeAction: WorkerRequirementAction | undefined,
  isLoading: boolean,
  onActionUpdate: (hasActiveAction: boolean, actionType: ActionType) => void,
): DropdownElement[] => {
  const isPushDisabled =
    detailedStatus !== DetailedBackgroundCheckStatus.PENDING &&
    detailedStatus !== DetailedBackgroundCheckStatus.INVITATION_CREATED

  const options: DropdownElement[] = []

  if (activeAction) {
    options.push({
      label: 'Lift Action',
      handleClick: () => onActionUpdate(true, activeAction.actionType),
      disabled: isLoading,
      icon: () => <SvgIcon name="unblock" />,
    })
  }

  options.push({
    label: 'Push in App',
    handleClick: () => onActionUpdate(false, ActionType.DISMISSIBLE),
    disabled: !!activeAction || isPushDisabled || isLoading,
    icon: () => <SvgIcon name="notification" />,
  })

  options.push({
    label: 'Push and Lock App',
    handleClick: () => onActionUpdate(false, ActionType.BLOCKED_ALL),
    disabled: !!activeAction || isPushDisabled || isLoading,
    icon: () => <SvgIcon name="block" />,
  })

  return options
}

export default function BackgroundCheckStatusBadge({
  status,
  adjudication,
  assessment,
  activeAction,
  onActionUpdate,
  isLoading,
}: BackgroundCheckStatusBadgeProps) {
  const detailedStatus = calculateDetailedBackgroundCheckStatus(
    status,
    adjudication,
    assessment,
  )
  let badgeConfig = bgcStatusToBadgeConfig[detailedStatus] ?? {
    variant: 'info',
    title: 'Unknown',
  }

  if (activeAction) {
    badgeConfig = {
      variant: 'danger',
      title:
        activeAction.actionType === ActionType.BLOCKED_ALL
          ? 'Locked'
          : 'Pushed',
    }
  }

  const dropdownOptions = getDropdownOptions(
    detailedStatus,
    activeAction,
    isLoading,
    onActionUpdate,
  )

  if (isLoading) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }

  return (
    <Dropdown
      orientation="right"
      dropdownOptions={dropdownOptions}
      buttonStyling={{
        whiteSpace: 'nowrap',
        minWidth: 'fit-content',
      }}
      listContainerStyling={{ width: '200px' }}
      listItemStyling={{
        alignItems: 'center',
        color: theme.colors.Violet80,
        fontWeight: '500',
        fontSize: 14,
      }}
      useBadge
      badgeVariant={badgeConfig.variant}
      title={badgeConfig.title}
    />
  )
}
