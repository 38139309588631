import { CircularProgress, IconButton } from '@mui/material'
import { useAlert } from '@traba/context'
import { InfoTooltip, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Billing,
  BillingUpdate,
  FormatLineItemType,
  InvoiceApproval,
  InvoiceCycleEnd,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { SetStateAction, useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { Button, Col, Icon, Input, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import RadioGroup from 'src/components/base/RadioGroup'
import { getErrorMessage } from 'src/utils/errorUtils'

const invoiceApprovalMenuItems = [
  {
    value: InvoiceApproval.AUTO,
    label: InvoiceApproval.AUTO,
    secondaryLabel: 'Invoices sent to client automatically upon creation',
  },
  {
    value: InvoiceApproval.MANUAL,
    label: InvoiceApproval.MANUAL,
    secondaryLabel: 'Invoices must be finalized by Ops',
  },
  {
    value: InvoiceApproval.MANUAL_NEW,
    label: InvoiceApproval.MANUAL_NEW,
    secondaryLabel:
      'New company that will switch to AUTO after two paid invoices',
  },
]

const invoiceCycleEndMenuItems = [
  {
    value: InvoiceCycleEnd.WeeklySunday,
    label: InvoiceCycleEnd.WeeklySunday,
    secondaryLabel: 'Monday through Sunday cycle. Created on Tuesday.',
  },
  {
    value: InvoiceCycleEnd.WeeklyMonday,
    label: InvoiceCycleEnd.WeeklyMonday,
    secondaryLabel: 'Tuesday through Monday cycle. Created on Wednesday.',
  },
  {
    value: InvoiceCycleEnd.WeeklyTuesday,
    label: InvoiceCycleEnd.WeeklyTuesday,
    secondaryLabel: 'Wednesday through Tuesday cycle. Created on Thursday.',
  },
  {
    value: InvoiceCycleEnd.WeeklyWednesday,
    label: InvoiceCycleEnd.WeeklyWednesday,
    secondaryLabel: 'Thursday through Wednesday cycle. Created on Friday.',
  },
  {
    value: InvoiceCycleEnd.WeeklyThursday,
    label: InvoiceCycleEnd.WeeklyThursday,
    secondaryLabel: 'Friday through Thursday cycle. Created on Monday.',
  },
  {
    value: InvoiceCycleEnd.WeeklyFriday,
    label: InvoiceCycleEnd.WeeklyFriday,
    secondaryLabel: 'Saturday through Friday cycle. Created on Monday.',
  },
  {
    value: InvoiceCycleEnd.WeeklySaturday,
    label: InvoiceCycleEnd.WeeklySaturday,
    secondaryLabel: 'Sunday through Saturday cycle. Created on Monday.',
  },
  {
    value: InvoiceCycleEnd.Daily,
    label: InvoiceCycleEnd.Daily,
    secondaryLabel: 'Daily at 8pm ET (for the previous day).',
  },
]

type BillingSettingsSectionProps = {
  billing?: Billing
  patchBilling: UseMutateAsyncFunction<
    Billing,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any, any>,
    BillingUpdate,
    Billing
  >
}

export default function BillingSettingsSection(
  props: BillingSettingsSectionProps,
) {
  const { billing, patchBilling } = props

  const { showSuccess, showError } = useAlert()

  const [isEditingInvoiceApproval, setEditingInvoiceApproval] = useState(false)
  const [isLoadingInvoiceApproval, setLoadingInvoiceApproval] =
    useState<boolean>(false)

  const [isEditingInvoiceCycleEnd, setEditingInvoiceCycleEnd] = useState(false)
  const [isLoadingInvoiceCycleEnd, setLoadingInvoiceCycleEnd] =
    useState<boolean>(false)

  const [isLoadingInvoiceLineItemType, setLoadingInvoiceLineItemType] =
    useState<boolean>(false)

  const [isLoadingInvoiceDaysUntilDue, setLoadingInvoiceDaysUntilDue] =
    useState<boolean>(false)

  const [invoiceDaysUntilDue, setInvoiceDaysUntilDue] = useState<
    number | undefined
  >(billing?.invoiceDaysUntilDue)

  const [primaryBillingEmail, setPrimaryBillingEmail] = useState<string>(
    billing?.paymentSettings.email || '',
  )
  const [primaryBillingEmailLoading, setPrimaryBillingEmailLoading] =
    useState<boolean>(false)

  const [primaryBillingPhoneNumber, setPrimaryBillingPhoneNumber] =
    useState<string>(billing?.paymentSettings.phoneNumber || '')
  const [
    primaryBillingPhoneNumberLoading,
    setPrimaryBillingPhoneNumberLoading,
  ] = useState<boolean>(false)

  const handleInvoiceApprovalChange = async (newValue: string) => {
    if (newValue === billing?.invoiceApproval.toString()) {
      setEditingInvoiceApproval(false)
      return
    }

    setLoadingInvoiceApproval(true)
    setEditingInvoiceApproval(false)

    try {
      await patchBilling({ invoiceApproval: newValue as InvoiceApproval })
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        'Error updating company invoice approval setting',
      )
    }

    setLoadingInvoiceApproval(false)
  }

  const handleInvoiceCycleEndChange = async (newValue: string) => {
    if (newValue === billing?.invoiceCycleEnd.toString()) {
      setEditingInvoiceCycleEnd(false)
      return
    }

    setLoadingInvoiceCycleEnd(true)
    setEditingInvoiceCycleEnd(false)

    try {
      await patchBilling({ invoiceCycleEnd: newValue as InvoiceCycleEnd })
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        'Error updating company invoice cycle end setting',
      )
    }

    setLoadingInvoiceCycleEnd(false)
  }

  const handleSaveInvoiceDaysUntilDue = async () => {
    setLoadingInvoiceDaysUntilDue(true)

    try {
      await patchBilling({ invoiceDaysUntilDue: invoiceDaysUntilDue })
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        'Error updating company invoice due date setting',
      )
    }

    setLoadingInvoiceDaysUntilDue(false)
  }

  const handleInvoiceLineItemTypeChange = async (newValue: string) => {
    if (newValue === billing?.invoiceLineItemType.toString()) {
      return
    }

    setLoadingInvoiceLineItemType(true)

    try {
      await patchBilling({
        invoiceLineItemType: newValue as FormatLineItemType,
      })
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        'Error updating company invoice line item type setting',
      )
    }

    setLoadingInvoiceLineItemType(false)
  }

  async function handleSave({
    setLoadingState,
    newEmail,
    newPhoneNumber,
  }: {
    setLoadingState: (value: SetStateAction<boolean>) => void
    newEmail?: string
    newPhoneNumber?: string
  }) {
    if (!billing) {
      showError('No billing info to update.')
      return
    }
    const updatedSettings = {
      email: newEmail,
      phoneNumber: newPhoneNumber,
    }

    setLoadingState(true)
    await patchBilling(
      { paymentSettings: updatedSettings },
      {
        onSuccess: () => {
          const successMessage = newEmail
            ? `Successfully updated primary billing email to: ${newEmail}`
            : `Successfully updated primary billing phone number to: ${newPhoneNumber}`
          showSuccess(successMessage, 'Successfully updated billing info')
        },
        onError: (err: unknown) => {
          captureSentryError(err)
          const failureMessage = newEmail
            ? `Error updating primary billing email to: ${newEmail}`
            : `Error updating primary billing phone number to: ${newPhoneNumber}`
          showError(getErrorMessage(err), failureMessage)
        },
        onSettled: () => {
          setLoadingState(false)
        },
      },
    )
  }

  const invoiceApprovalValue = isLoadingInvoiceApproval ? (
    <CircularProgress size={16} />
  ) : isEditingInvoiceApproval ? (
    <>
      <Select
        containerStyle={{ margin: `${theme.space.xs}px 0px` }}
        dropdownStyle={{ height: '48px' }}
        menuItems={invoiceApprovalMenuItems}
        value={billing?.invoiceApproval || ''}
        handleSelect={handleInvoiceApprovalChange}
      />
      <IconButton
        aria-label="cancel"
        onClick={() => setEditingInvoiceApproval(false)}
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        <Icon name="cancel" />
      </IconButton>
    </>
  ) : (
    <>
      <Text variant="body1" mt={theme.space.xxs} mb={theme.space.xs}>
        {billing?.invoiceApproval || '-'}
      </Text>
      <Button
        style={{
          display: 'inline',
          padding: 0,
          marginLeft: `${theme.space.xxxs}px`,
        }}
        variant={ButtonVariant.TRANSPARENT}
        onClick={() => setEditingInvoiceApproval(true)}
      >
        <Icon name="edit_active" width={18} />
      </Button>
    </>
  )

  const invoiceCycleEndValue = isLoadingInvoiceCycleEnd ? (
    <CircularProgress size={16} />
  ) : isEditingInvoiceCycleEnd ? (
    <>
      <Select
        containerStyle={{ margin: `${theme.space.xs}px 0px` }}
        dropdownStyle={{ height: '48px' }}
        menuItems={invoiceCycleEndMenuItems}
        value={billing?.invoiceCycleEnd || ''}
        handleSelect={handleInvoiceCycleEndChange}
      />
      <IconButton
        aria-label="cancel"
        onClick={() => setEditingInvoiceCycleEnd(false)}
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        <Icon name="cancel" />
      </IconButton>
    </>
  ) : (
    <>
      <Text variant="body1" mt={theme.space.xxs} mb={theme.space.xs}>
        {billing?.invoiceCycleEnd || '-'}
      </Text>
      <Button
        style={{
          display: 'inline',
          padding: 0,
          marginLeft: `${theme.space.xxxs}px`,
        }}
        variant={ButtonVariant.TRANSPARENT}
        onClick={() => setEditingInvoiceCycleEnd(true)}
      >
        <Icon name="edit_active" width={18} />
      </Button>
    </>
  )

  const invoiceLineItemsValue = (
    <>
      <RadioGroup
        value={billing?.invoiceLineItemType}
        onChange={(event) =>
          handleInvoiceLineItemTypeChange(event.target.value)
        }
        options={[
          {
            label: <Text variant="body1">SHIFT</Text>,
            value: FormatLineItemType.SHIFT,
          },
          {
            label: <Text variant="body1">WORKER</Text>,
            value: FormatLineItemType.WORKER_SHIFT,
          },
        ]}
      />

      {isLoadingInvoiceLineItemType && <CircularProgress size={16} />}
    </>
  )

  return (
    <>
      <Text variant="h4" mt={theme.space.sm}>
        Billing
      </Text>
      <Row mt={theme.space.sm}>
        <Col>
          <Text variant="h6" mr={theme.space.xs}>
            Primary Billing Email
          </Text>
          <Row alignCenter>
            <Input
              width={'100%'}
              value={primaryBillingEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPrimaryBillingEmail(e.target.value)
              }
            />
            <Button
              style={{ marginRight: theme.space.sm }}
              ml={theme.space.xs}
              loading={primaryBillingEmailLoading}
              disabled={
                !primaryBillingEmail ||
                primaryBillingEmail === billing?.paymentSettings.email
              }
              onClick={() =>
                handleSave({
                  newEmail: primaryBillingEmail,
                  setLoadingState: setPrimaryBillingEmailLoading,
                })
              }
            >
              Save
            </Button>
          </Row>
        </Col>
        <Col>
          <Text variant="h6" mr={theme.space.xs}>
            Primary Billing Phone Number
          </Text>
          <Row alignCenter>
            <Input
              width={'100%'}
              value={primaryBillingPhoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPrimaryBillingPhoneNumber(e.target.value)
              }
            />
            <Button
              ml={theme.space.xs}
              loading={primaryBillingPhoneNumberLoading}
              disabled={
                !primaryBillingPhoneNumber ||
                primaryBillingPhoneNumber ===
                  billing?.paymentSettings.phoneNumber
              }
              onClick={() =>
                handleSave({
                  newPhoneNumber: primaryBillingPhoneNumber,
                  setLoadingState: setPrimaryBillingPhoneNumberLoading,
                })
              }
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
      <Row mt={theme.space.sm}>
        <Col>
          <Row>
            <Text variant="h7" mr={theme.space.xxxs}>
              Invoice Approval
            </Text>
            <InfoTooltip title="Determines how invoices are sent to the client - automatically upon creation, or manually after draft approval by Ops" />
          </Row>
          <Row>{invoiceApprovalValue}</Row>
        </Col>

        <Col>
          <Row>
            <Text variant="h7" mr={theme.space.xxxs}>
              Invoice Cycle End
            </Text>
            <InfoTooltip title="Determines when the invoicing cycle ends, not including the buffer time before cut." />
          </Row>
          <Row>{invoiceCycleEndValue}</Row>
        </Col>

        <Col>
          <Row>
            <Text variant="h7" mr={theme.space.xxxs}>
              Invoice Line Item Type
            </Text>
            <InfoTooltip title="Determines how the charges are listed on the invoice - by shift or by worker." />
          </Row>
          <Row>{invoiceLineItemsValue}</Row>
        </Col>
      </Row>

      <Row alignCenter>
        <Text variant="h6" mr={theme.space.xs}>
          Invoice Due Period Length
          <InfoTooltip title="Determines how many days after invoice finalization it would be due. NOTE: this only applies to any new invoices created for the business and WILL NOT apply to any existing draft and open invoices." />
        </Text>
        <NumberInput
          width={'80px'}
          value={invoiceDaysUntilDue}
          setValue={setInvoiceDaysUntilDue}
          decimals={0}
          placeholder="eg. 30"
          min={1}
          max={60}
        />
        <Button
          ml={theme.space.xs}
          loading={isLoadingInvoiceDaysUntilDue}
          disabled={
            !invoiceDaysUntilDue ||
            isLoadingInvoiceDaysUntilDue ||
            invoiceDaysUntilDue < 1 ||
            invoiceDaysUntilDue > 60 ||
            invoiceDaysUntilDue === billing?.invoiceDaysUntilDue
          }
          onClick={handleSaveInvoiceDaysUntilDue}
        >
          Save
        </Button>
      </Row>
    </>
  )
}
