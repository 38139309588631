import Collapse from '@mui/material/Collapse'
import { Tab as BaseTab } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'
import { Input, Row } from '../base'
type DrawerContainerProps = {
  expanded?: boolean
}

export const DrawerContainer = styled.div<DrawerContainerProps>`
  width: 60vw;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1024px) {
    width: 80vw;
  }

  @media (max-width: 768px) {
    width: 90vw;
  }
`

export const WorkersListWrapper = styled.div`
  padding-bottom: ${theme.space.xs}px;
`

export const FormContainer = styled.form`
  height: 100%;
  padding: ${theme.space.xs}px;
  margin-top: ${theme.space.xs}px;
`

export const CollapseSection = styled(Collapse)`
  width: 100%;
`

export const CollapseWrapper = styled.div`
  width: 100%;
  padding: ${theme.space.xxs}px;
  padding-bottom: ${theme.space.xs}px;
`

export const BackfillPresetSection = styled.div`
  margin-top: ${theme.space.xs}px;
  height: 80px;
  background-color: ${theme.colors.Grey10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.space.xs}px;
  border-radius: ${theme.border.radius};
`

export const RegionSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
`

export const ActionTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.colors.Grey10};
  border-radius: ${theme.border.radius};
  padding: ${theme.space.xs}px;
  width: 100%;
`

export const SMSInputContainer = styled.div`
  margin: ${theme.space.xs}px;
`

export const TextInput = styled(Input)`
  width: 100%;
  min-height: 150px;
`

export const ScheduleSectionContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  padding: ${theme.space.med}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  background-color: ${theme.colors.Grey10};
  overflow: auto;
`

type CardContainerProps = { isSelected: boolean; previewBugleId?: string }

export const ScheduleCardContainer = styled.div<CardContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${theme.space.xs}px;
  margin-bottom: ${theme.space.xs}px;
  border-radius: ${theme.border.radius};
  background-color: #fff;
  opacity: ${({ isSelected, previewBugleId }) =>
    previewBugleId ? (isSelected ? 1 : 0.3) : 1};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
`

export const ShiftBackfillCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${theme.space.xs}px;
  border-radius: ${theme.border.radius};
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
`

export const ParamListItem = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`

export const ParamListGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.space.xxs}px;
  width: 100%;
  border-radius: ${theme.border.radius};
  background-color: ${theme.colors.Grey10};
  padding: ${theme.space.xs}px ${theme.space.med}px;
  margin-top: ${theme.space.xxs}px;
`

export const TabSelector = styled(Row)`
  padding: 0;
  border-bottom: 1px solid ${theme.colors.Grey20};
  padding-right: ${theme.space.xs}px;
  font-size: 24px;
`

export const Tab = styled(BaseTab)`
  padding: ${theme.space.xs}px;
  margin: 0px;
  font-size: 14px;
`

export const TabBody = styled.div`
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;
  width: 100%;
`

export const StatisticsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  padding: ${theme.space.xs}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  background-color: ${theme.colors.White};
  overflow: auto;
`
