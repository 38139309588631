import {
  Button,
  Input,
  Modal,
  Row,
  SearchSelect,
  SelectDropdown,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ActionableRequirement, IMenuItem } from '@traba/types'
import { addYears } from 'date-fns'
import { useState } from 'react'
import { useCompanies } from 'src/hooks/useCompanies'
import { useWaivers } from 'src/hooks/useWaivers'

type PermanentWaiversModalProps = {
  isOpen: boolean
  handleClose: () => void
  workerId: string
}

const PERMANENT_WAIVER_YEARS = 100

export const PermanentWaiversModal = ({
  isOpen,
  handleClose,
  workerId,
}: PermanentWaiversModalProps) => {
  const [requirement, setRequirement] = useState<ActionableRequirement>(
    ActionableRequirement.BACKGROUND_CHECK,
  )
  const { createWaiver } = useWaivers(workerId, [])
  const [requestedReason, setRequestedReason] = useState('')
  const { companies } = useCompanies({ isApproved: true })
  const companyOptions: IMenuItem[] = [
    {
      label: '-',
      value: '',
    },
    ...(companies
      ?.filter((company) => !!company.id)
      .map((company) => ({ label: company.employerName, value: company.id })) ||
      []),
  ]

  const [company, setCompany] = useState<IMenuItem | undefined>(
    companyOptions[0],
  )

  return (
    <Modal
      title="Create permanent waiver"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Input
        label="Waiver Reason"
        value={requestedReason}
        onChange={(e) => setRequestedReason(e.target.value)}
        required={true}
        placeholder="Enter reason to create waiver"
      />

      <Row alignCenter mb={theme.space.sm} mt={theme.space.xs}>
        <SearchSelect
          options={companyOptions}
          selectItem={company}
          handleSelect={setCompany}
          label="Business"
          width={260}
        />
        <SelectDropdown
          menuItems={Object.values(ActionableRequirement).map(
            (requirement) => ({
              value: requirement,
              label: requirement,
            }),
          )}
          value={requirement}
          handleSelect={(value) =>
            setRequirement(value as ActionableRequirement)
          }
        />
        <Button
          disabled={!requirement || !company?.value || !requestedReason}
          onClick={() => {
            if (!company?.value) {
              return
            }
            createWaiver({
              companyId: company.value,
              expiryTime: addYears(new Date(), PERMANENT_WAIVER_YEARS),
              maxShiftsCompleted: null,
              requestedReason,
              requirement,
              workerId,
            })
            handleClose()
          }}
        >
          Create
        </Button>
      </Row>
    </Modal>
  )
}
