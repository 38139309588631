import { CircularProgress } from '@mui/material'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift, Waitlist } from '@traba/types'
import { format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { Button, Row } from '../../../components/base'
import { useModal } from '../../../components/base/Modal/Modal'
import { DataTable, TableCell } from '../../../components/base/Table/DataTable'
import { useWorkersWaitlists } from '../../../hooks/useWorkerWaitlists'
import AcceptShiftModal from '../../../modals/AcceptShiftModal/AcceptShiftModal'

function makeWaitlistRow(
  waitlist: Waitlist,
  shift: OpsExtendedShift,
  onAction: () => void,
) {
  const cells: TableCell[] = [
    {
      renderFn: () => {
        return (
          <Anchor href={`/field-monitor/${waitlist.shiftId}`}>
            {shift.shiftRole}
          </Anchor>
        )
      },
    },
    {
      renderFn: () => <Text>{shift.employerName}</Text>,
    },
    {
      renderFn: () => <Text>{shift.shortLocation}</Text>,
    },
    {
      renderFn: () => (
        <Text>{format(new Date(shift.startTime), 'MMM d, y - hh:mm a')}</Text>
      ),
    },
    {
      renderFn: () => (
        <Text>
          {format(new Date(waitlist.createdAt), 'MMM d, y - hh:mm a')}
        </Text>
      ),
    },
    {
      renderFn: () => (
        <Button
          variant={ButtonVariant.TEXT}
          style={{ color: theme.colors.Violet }}
          onClick={onAction}
        >
          Add
        </Button>
      ),
    },
  ]
  return {
    cells,
    key: waitlist.shiftId,
  }
}

export const WaitlistsTab = ({
  workerId,
  dateRange,
  setDateRange,
}: {
  workerId: string
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
}) => {
  const [selectedShift, setSelectedShift] = useState<Shift>()
  const {
    workerWaitlists: waitlists,
    shifts,
    isLoading,
  } = useWorkersWaitlists(workerId, dateRange)
  const acceptShiftModal = useModal()

  const onAddWorker = useCallback(
    (shift: Shift) => {
      setSelectedShift(shift)
      acceptShiftModal.open()
      return
    },
    [setSelectedShift, acceptShiftModal],
  )

  const shiftsTableRows = useMemo(() => {
    if (!waitlists || !shifts) {
      return []
    }

    return waitlists
      .map((waitlist) => {
        const shift = shifts.find((s) => s.id === waitlist.shiftId)
        return shift ? { waitlist, shift } : null
      })
      .filter(
        (entry): entry is { waitlist: Waitlist; shift: OpsExtendedShift } =>
          entry !== null,
      )
      .sort(
        (a, b) =>
          new Date(a.shift.startTime).getTime() -
          new Date(b.shift.startTime).getTime(),
      )
      .map(
        ({
          waitlist,
          shift,
        }: {
          waitlist: Waitlist
          shift: OpsExtendedShift
        }) => makeWaitlistRow(waitlist, shift, () => onAddWorker(shift)),
      )
  }, [waitlists, shifts, onAddWorker])

  const waitlistsTableHeaders = [
    { label: 'Role', key: 'roleName' },
    { label: 'Company', key: 'employerName' },
    { label: 'Location', key: 'shortLocation' },
    { label: 'Shift start', key: 'startTime' },
    { label: 'Joined on', key: 'createdAt' },
    { label: '', key: 'action' },
  ]

  return (
    <>
      <Row my={theme.space.xxs}>
        <DateRangePicker
          label="Date range"
          inlineLabel={true}
          dateRange={dateRange}
          setDateRange={setDateRange}
          granularity="day"
          style={{ marginLeft: theme.space.xs }}
        />
      </Row>
      <Row mb={theme.space.sm} justifyBetween alignCenter>
        {isLoading && (
          <CircularProgress
            size={36}
            sx={{
              left: '50%',
            }}
          />
        )}
        {!isLoading && (!shiftsTableRows || !shiftsTableRows?.length) && (
          <Text>Worker has no active waitlists</Text>
        )}
        {!isLoading && shiftsTableRows && shiftsTableRows.length > 0 && (
          <DataTable headers={waitlistsTableHeaders} rows={shiftsTableRows} />
        )}
        {selectedShift && (
          <AcceptShiftModal
            {...acceptShiftModal}
            workerIds={[workerId]}
            shift={selectedShift}
          />
        )}
      </Row>
    </>
  )
}
