import { RoleFormMode } from '@traba/consts'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import { useState } from 'react'
import { Icon, Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { RoleForm } from 'src/components/Roles/RoleForm'
import { RoleStepper } from 'src/components/Roles/RoleStepper'
import { CreateRoleData, useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/utils/moneyUtils'
import CompanyRoleShiftBadgeModal from './CompanyRoleShiftBadgeModal'

import * as S from './Modal.styles'

const INITIAL_ROLE_STATE = {
  roleName: '',
  roleDescription: '',
  requiredAttire: '',
  requiredCertifications: [],
  requiredAttributes: [],
  freeformAttributes: {},
  defaultPayRate: PAY_RATE_DEFAULT,
  minimumAgeRequirement: undefined,
  requireResumeUpload: undefined,
}

export type CreateRoleModalProps = {
  onClose: () => void
  isEventCompany: boolean
  fromPrevious: boolean
  company: Company
}

export const CreateRoleModal = (props: CreateRoleModalProps) => {
  const { onClose, isEventCompany, fromPrevious, company } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showBadgeModal, setShowBadgeModal] = useState(false)
  const [createdRoleId, setCreatedRoleId] = useState<string | undefined>(
    undefined,
  )
  const { createRole } = useRoles({ companyId: company.companyId })

  function handleCreate(roleToCreate: CreateRoleData) {
    setSubmitLoading(true)
    createRole(roleToCreate, {
      onSuccess: (role) => {
        setCreatedRoleId(role.roleId)
        setShowBadgeModal(true)
      },
      onSettled: () => {
        setSubmitLoading(false)
      },
    })
  }

  function handleBadgeModalClose() {
    setShowBadgeModal(false)
    setCreatedRoleId(undefined)
    onClose()
  }

  return (
    <>
      {isEventCompany ? (
        <S.ModalPaddingContainer>
          <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
            <Text variant="h2">Create role</Text>
            <Icon
              name="cancel"
              onClick={onClose}
              style={{
                height: theme.space.xs,
                width: theme.space.xs,
                cursor: 'pointer',
              }}
            />
          </Row>
          <RoleForm
            onConfirmCreate={handleCreate}
            onCancel={onClose}
            submitLoading={submitLoading}
            mode={RoleFormMode.CREATE}
            company={company}
            initialRoleState={{
              extraBGCRequirement: company.extraBGCRequirement,
            }}
          />
        </S.ModalPaddingContainer>
      ) : (
        <>
          <Row justifyBetween alignCenter mb={theme.space.sm}>
            <Text variant="h4">
              {!fromPrevious
                ? 'Create a new role'
                : 'Create a role using your previous roles'}
            </Text>
            <Icon
              name="cancel"
              onClick={onClose}
              style={{
                height: theme.space.xs,
                width: theme.space.xs,
                cursor: 'pointer',
              }}
            />
          </Row>
          <Divider />
          <div style={{ marginBottom: theme.space.sm }}></div>
          <RoleStepper
            onConfirmCreate={handleCreate}
            initialRoleState={{
              ...INITIAL_ROLE_STATE,
              extraBGCRequirement: company.extraBGCRequirement,
            }}
            fromPrevious={fromPrevious}
            onCancel={onClose}
            submitLoading={submitLoading}
            mode={RoleFormMode.CREATE}
            company={company}
          />
        </>
      )}
      {showBadgeModal && createdRoleId && (
        <CompanyRoleShiftBadgeModal
          handleClose={handleBadgeModalClose}
          isOpen={showBadgeModal}
          roleId={createdRoleId}
        />
      )}
    </>
  )
}
