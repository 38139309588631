import { InternalUser } from '@traba/types'
import { SentinelShiftNotificationsDropdown } from 'src/components/Sentinel/SentinelShiftDropdown'

type Props = {
  shiftId: string
  companyId: string
  internalUser: InternalUser
  timezone: string
  onClose: () => void
}

export default function SentinelNotificationsSection(props: Props) {
  return <SentinelShiftNotificationsDropdown {...props} />
}
