import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  AccountApprovalStatus,
  ApprovalStatusReason,
  EmploymentType,
  PhoneNumberStatus,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { getErrorMessage } from 'src/utils/errorUtils'

type UpdateAccountStatusProps = {
  workerId: string
  accountStatus: AccountApprovalStatus
  approvalStatusReason?: ApprovalStatusReason
}

type UpdateWorkerEmploymentTypesProps = {
  workerId: string
  employmentTypes: EmploymentType[]
}

type UpdateInstantPayProps = {
  workerId: string
  eligible: boolean
  isPermanent?: boolean
}

type UpdatePhoneStatusProps = {
  workerId: string
  phoneNumberStatus: PhoneNumberStatus
}

type ResyncAccountStatusProps = {
  workerId: string
}

const updateAccountStatus = async (props: UpdateAccountStatusProps) => {
  const { workerId, accountStatus, approvalStatusReason } = props

  try {
    await trabaApi.patch(`/workers/${workerId}/approval-status`, {
      accountStatus,
      ...(approvalStatusReason ? { approvalStatusReason } : {}),
    })
  } catch (e: unknown) {
    console.error(e)
    captureSentryError(e)
    throw e
  }
}

const updateWorkerEmploymentTypes = async (
  props: UpdateWorkerEmploymentTypesProps,
) => {
  const { workerId, employmentTypes } = props
  try {
    await trabaApi.patch(`/workers/${workerId}/employment-types`, {
      employmentTypes,
    })
  } catch (e: unknown) {
    console.error(e)
    captureSentryError(e)
    throw e
  }
}

const updateInstantPay = async (props: UpdateInstantPayProps) => {
  const { workerId, eligible, isPermanent } = props

  try {
    await trabaApi.patch(
      `/workers/${workerId}/instant-pay-eligibility?isEligible=${eligible}${
        !eligible && isPermanent ? '&isPermanent=true' : ''
      }`,
    )
  } catch (e: unknown) {
    console.error(e)
    captureSentryError(e)
    throw e
  }
}

const updatePhoneNumberStatusFunction = async (
  props: UpdatePhoneStatusProps,
) => {
  const { workerId, phoneNumberStatus } = props

  try {
    await trabaApi.patch(`/workers/${workerId}/phone-number-status`, {
      phoneNumberStatus,
    })
  } catch (e: unknown) {
    console.error(e)
    captureSentryError(e)
    throw e
  }
}

const resyncAccountStatus = async ({ workerId }: ResyncAccountStatusProps) => {
  try {
    await trabaApi.post(`/workers/${workerId}/resync-account-status`)
    window.analytics.track('Worker Account Status Resync Success', {
      workerId,
    })
  } catch (error) {
    window.analytics.track('Worker Account Status Resync Failed', {
      workerId,
      error: getErrorMessage(error),
    })
    captureSentryError(error)
    throw error
  }
}

export function useAccountStatus() {
  const { showSuccess, showError } = useAlert()

  const updateAccountStatusMutation = useMutation<
    void,
    AxiosError,
    UpdateAccountStatusProps
  >({
    mutationFn: updateAccountStatus,
    onSuccess: (_res, { accountStatus }) => {
      showSuccess(`Account status updated to ${accountStatus}`)
    },
    onError: (_res, _) => {
      showError(`Failed to update account status`)
    },
  })

  const updateInstantPayMutation = useMutation<
    void,
    AxiosError,
    UpdateInstantPayProps
  >({
    mutationFn: updateInstantPay,
    onSuccess: (_res, _) => {
      showSuccess(`Instant pay updated`)
    },
    onError: (_res, _) => {
      showError(`Failed to update instant pay`)
    },
  })

  const updateWorkerEmploymentTypesMutation = useMutation<
    void,
    AxiosError,
    UpdateWorkerEmploymentTypesProps
  >({
    mutationFn: updateWorkerEmploymentTypes,
    onSuccess: (_res, { employmentTypes }) => {
      showSuccess(`Updated worker employment types: ${employmentTypes}`)
    },
    onError: (_res, _) => {
      showError(`failed to update worker employment types`)
    },
  })

  const {
    mutate: updatePhoneNumberStatus,
    isPending: phoneNumberStatusPending,
  } = useMutation<void, AxiosError, UpdatePhoneStatusProps>({
    mutationFn: updatePhoneNumberStatusFunction,
    onError: (error: unknown) => {
      showError(getErrorMessage(error), 'Error Updating Phone Number Status')
    },
  })

  return {
    accountStatusLoading: updateAccountStatusMutation.isPending,
    updateAccountStatus: updateAccountStatusMutation.mutate,
    instantPayLoading: updateInstantPayMutation.isPending,
    updateInstantPay: updateInstantPayMutation.mutate,
    updateWorkerEmploymentTypes:
      updateWorkerEmploymentTypesMutation.mutateAsync,
    phoneNumberStatusPending,
    updatePhoneNumberStatus,
  }
}

export function useAccountStatusResync() {
  const { showSuccess, showError } = useAlert()

  const resyncAccountStatusMutation = useMutation<
    void,
    AxiosError,
    ResyncAccountStatusProps
  >({
    mutationFn: resyncAccountStatus,
    onSuccess: () => {
      showSuccess('Account status re-synced')
    },
    onError: (error) => {
      showError(`Failed to re-sync account status: ${getErrorMessage(error)}`)
    },
  })

  return {
    resyncAccountStatus: resyncAccountStatusMutation.mutate,
    resyncAccountStatusLoading: resyncAccountStatusMutation.isPending,
  }
}
