import { LoadingSpinner } from '@traba/react-components'
import { ShiftInvitation } from '@traba/types'
import { useCallback, useMemo } from 'react'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { useWorkerApplicationsForShiftRequest } from 'src/hooks/useWorkerApplications'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { ShiftApplicantsTable } from './ShiftApplicantsTable'

export type ShiftApplicantsTableContainerProps = {
  shift: OpsExtendedShift
}

export function ShiftApplicantsTableContainer({
  shift,
}: ShiftApplicantsTableContainerProps) {
  const { shiftId, shiftRequestId } = shift

  const {
    shiftInvitations,
    isLoading: isLoadingShiftInvitations,
    rescindInvitation,
  } = useShiftInvitations(shiftId)

  const { workerApplications, isLoading } =
    useWorkerApplicationsForShiftRequest({
      shiftRequestId,
    })

  const { workerShifts = [] } = useWorkerShifts(shiftId)
  const workerShiftWorkerIdsSet = useMemo(
    () => new Set(workerShifts.map((ws) => ws.workerId)),
    [workerShifts],
  )

  const handleRescindInvitation = useCallback(
    (shiftInvitation: ShiftInvitation) => {
      if (
        !window.confirm('Are you sure you want to rescind this invitation?')
      ) {
        return
      }
      rescindInvitation(shiftInvitation)
    },
    [rescindInvitation],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <ShiftApplicantsTable
      shift={shift}
      applications={workerApplications ?? []}
      timezone={shift.timezone}
      shiftInvitations={shiftInvitations ?? []}
      workerShiftWorkerIdsSet={workerShiftWorkerIdsSet}
      onRescindInvitation={handleRescindInvitation}
      isLoading={isLoadingShiftInvitations}
    />
  )
}
