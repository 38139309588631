import { Col } from '@traba/react-components'
import {
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { CreateAndEditWorkerVettingCallApplicationItem } from './CreateAndEditWorkerVettingCallApplicationItem'

interface CreateAndEditApplicationItemContentProps {
  applicationItemId?: string
  applicationItemType: ApplicationItemType
  applicationItemSourceId?: string
  onSave: (
    upsertApplicationItemRequest: UpsertApplicationItemRequestType,
  ) => void
}

export const CreateAndEditApplicationItemContent = ({
  applicationItemId,
  applicationItemType,
  applicationItemSourceId,
  onSave,
}: CreateAndEditApplicationItemContentProps) => {
  switch (applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      return (
        <CreateAndEditWorkerVettingCallApplicationItem
          applicationItemId={applicationItemId}
          applicationItemSourceId={applicationItemSourceId}
          onSave={onSave}
        />
      )
    default:
      return <Col>Nothing to display</Col>
  }
}
