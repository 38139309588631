import { theme } from '@traba/theme'
import { megabytesToBytes } from '@traba/utils'
import { ChangeEvent, useState } from 'react'
import { ButtonVariant } from '../Button/Button'
import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'
import * as S from './MediaUploader.styles'

type MediaUploaderProps = {
  id?: string
  file: File | undefined
  onChange: (newFile: File | undefined) => void
  onDelete?: () => void
  onError?: (message: string) => void
  label?: string
  maxFileSizeMB?: number
  initialPreviewSrc?: string
  fileType?: 'image' | 'video' | 'csv' | 'pdf' | 'largeImage' | 'zip'
  uploadButtonWidth?: string
  hidePreview?: boolean
  onlyIcon?: boolean
  marginTop?: number
}

const ACCEPTED_MIME_TYPES = {
  image: 'image/*',
  video: 'audio/*,gif/*,video/mp4,video/x-m4v,video/*',
  csv: 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf,image/png,image/jpeg',
  largeImage: 'image/*',
  zip: 'application/zip,application/x-zip-compressed,application/octet-stream,.zip',
}

export const MediaUploader = (props: MediaUploaderProps) => {
  const [previewSrc, setPreviewSrc] = useState(props.initialPreviewSrc ?? '')
  const {
    id = 'fileUpload',
    file,
    onChange,
    onDelete,
    onError,
    label = 'Upload file',
    maxFileSizeMB,
    uploadButtonWidth,
    fileType = 'image',
    hidePreview,
    onlyIcon,
    marginTop = theme.space.xs,
  } = props

  const maxFileSizeInBytes = megabytesToBytes(maxFileSizeMB ?? 0)
  const isVideo = fileType === 'video'
  const isImage = fileType === 'image' || fileType === 'pdf'
  const isCSV = fileType === 'csv'
  const isZip = fileType === 'zip'
  const isLargeImage = fileType === 'largeImage'
  const hasFile = file || previewSrc !== ''

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.target.files && ev.target.files.length > 0) {
      const _file = ev.target.files[0]

      if (maxFileSizeMB && _file.size > maxFileSizeInBytes) {
        if (typeof onError === 'function') {
          onError(`Image file cannot be over ${maxFileSizeMB} MB`)
        }
        return
      }

      onChange(_file)
      if (!hidePreview) {
        setPreviewSrc(URL.createObjectURL(_file))
      }
    }
  }

  function handleDelete() {
    if (typeof onDelete === 'function') {
      onDelete()
    }
    setPreviewSrc('')
  }

  const shouldShowText = hasFile && !hidePreview ? isCSV || isZip : !onlyIcon

  return (
    <div>
      <Row>
        {previewSrc !== '' && isVideo && (
          <video
            key={previewSrc}
            style={{ marginRight: theme.space.sm }}
            width="500"
            controls
          >
            <source src={previewSrc} />
          </video>
        )}
        {previewSrc !== '' && isLargeImage && (
          <S.LargeImagePreviewWrapper>
            <S.LargeImagePreview src={previewSrc} />
          </S.LargeImagePreviewWrapper>
        )}
        <S.FileUploadInput
          id={id}
          type="file"
          key={file?.name}
          onChange={handleChange}
          accept={ACCEPTED_MIME_TYPES[fileType]}
        />
      </Row>
      <Row mt={marginTop}>
        {previewSrc !== '' && !hidePreview && isImage && (
          <S.ImagePreviewWrapper>
            <S.ImagePreview src={previewSrc} />
          </S.ImagePreviewWrapper>
        )}
        <S.MediaUploaderLabel htmlFor={id} style={{ width: uploadButtonWidth }}>
          <SvgIcon
            style={{
              marginRight: shouldShowText ? theme.space.xxs : 0,
            }}
            color={theme.colors.Grey50}
            size={16}
            name={file && !hidePreview ? 'edit' : 'plus'}
          />
          <Text>
            {shouldShowText
              ? isCSV || isZip
                ? label || 'Change File'
                : label
              : ''}
          </Text>
        </S.MediaUploaderLabel>
        {hasFile && !hidePreview && (
          <S.DeleteButton
            variant={ButtonVariant.OUTLINED}
            onClick={handleDelete}
          >
            <SvgIcon name={'trash'} color={theme.colors.Grey50} />
          </S.DeleteButton>
        )}
      </Row>
    </div>
  )
}

export default MediaUploader
