import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { useState } from 'react'
import { Button, Col, Icon, Input, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { BugleActionType } from 'src/components/BugleDrawer/BugleDrawer'
import { InlineMessage } from 'src/components/InlineMessage'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'
import { useAnalytics } from '../../hooks/useAnalytics'

type DirectSMSModalProps = {
  handleClose: () => void
  isOpen: boolean
  shiftId: string
  workerShifts: WorkerShift[]
  deepLink?: string
  sentinelNotificationToUserId?: string
}

export default function DirectSMSModal({
  isOpen,
  handleClose,
  shiftId,
  workerShifts,
  deepLink,
  sentinelNotificationToUserId,
}: DirectSMSModalProps) {
  const [message, setMessage] = useState<string>('')

  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()
  const { trackAnalytics } = useAnalytics()

  const [loading, setLoading] = useState<boolean>(false)

  const onHandleClose = () => {
    setMessage('')
    handleClose()
  }

  const phoneNumbers = workerShifts
    ? workerShifts.map((ws) => ws.worker.phoneNumber)
    : []

  const handleSendDirectSms = async () => {
    setLoading(true)
    try {
      await trabaApi.post(`communication/bugle/schedule`, {
        message: {
          body: message,
          type: BugleActionType.SMS,
        },
        shiftId,
        phoneNumbers: phoneNumbers,
        sentinelNotificationToUserId,
      })
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken Direct SMS', {
          sentinelNotificationToUserId,
          workerIds: workerShifts.map((ws) => ws.workerId),
          phoneNumbers,
          shiftId: shiftId,
        })
      }
      showSuccess(`Successfully sent sms to ${phoneNumbers.length} workers.`)
    } catch (e: unknown) {
      showError(
        getErrorMessage(e),
        `Error sending two way sms to ${phoneNumbers.length} workers.`,
      )
    }
    refetchActiveQueries()
    setLoading(false)
    onHandleClose()
  }

  const handleAddDeeplink = () => setMessage(`${message}\n${deepLink}`)

  const handleEditorChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value
    setMessage(newMessage)
  }

  return (
    <Modal
      handleClose={onHandleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_SMALL}
    >
      <Col
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Row flexCol fullWidth fullHeight>
          <Row
            justifyBetween
            alignCenter
            style={{ marginBottom: theme.space.xs }}
          >
            <Text variant="h4">Send Direct SMS to Worker</Text>
            <Button variant={ButtonVariant.TRANSPARENT} onClick={onHandleClose}>
              <Icon name="cancel" />
            </Button>
          </Row>
          <Input
            type="textarea"
            onChange={handleEditorChange}
            value={message}
            style={{
              width: '100%',
              minHeight: '150px',
            }}
            label="Text"
            aria-label="Direct sms input box"
          />
          <InlineMessage text="This is a two-way messaging system. Messages will always have a 'Traba: ' prefix" />
          <Row>
            <Button
              variant={ButtonVariant.OUTLINED}
              slim
              onClick={handleAddDeeplink}
              leftIcon={
                <Icon
                  name="ellipsesCircle"
                  size={24}
                  type="svg"
                  color={theme.colors.Grey50}
                />
              }
              mt={theme.space.xxs}
              mr={theme.space.xs}
            >
              Add deeplink
            </Button>
          </Row>
        </Row>
        <ModalButtons
          handleClose={onHandleClose}
          handleConfirm={handleSendDirectSms}
          loading={loading}
          confirmText="Send"
          isDisabled={!message || !phoneNumbers.length}
        />
      </Col>
    </Modal>
  )
}
