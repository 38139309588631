export enum EmploymentType {
  W2 = 'W2',
  CONTRACTOR_1099 = 'CONTRACTOR_1099',
}

export enum EmploymentContractStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TERMINATED = 'TERMINATED',
}
