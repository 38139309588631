export enum ShiftEvents {
  ShiftPosted = 'SHIFT_POSTED',
  ShiftCanceled = 'SHIFT_CANCELED',
  ShiftEdited = 'SHIFT_EDITED',
  IncentiveCreated = 'INCENTIVE_CREATED',
  SignupStatusEdited = 'SIGNUP_STATUS_EDITED',
  ForwardFillSettingsEdited = 'FORWARD_FILL_SETTINGS_EDITED',
  ShiftTagsEdited = 'SHIFT_TAGS_EDITED',
  ShiftBackfillStatusChanged = 'SHIFT_BACKFILL_STATUS_CHANGED',
  OverbookSlotsChanged = 'OVERBOOK_SLOTS_CHANGED',
  OverbookSlotsCalculated = 'OVERBOOK_SLOTS_CALCULATED',
  SlotsChanged = 'SLOTS_CHANGED',
  ForwardFillBatchNotified = 'FORWARD_FILL_BATCH_NOTIFIED',
  InvoiceDraftCreated = 'INVOICE_DRAFT_CREATED',
  InvitationSent = 'INVITATION_SENT',
  InvitationAccepted = 'INVITATION_ACCEPTED',
  InvitationsCreated = 'INVITATIONS_CREATED',
  WorkerAcceptedShift = 'WORKER_ACCEPTED_SHIFT',
  WorkerShiftCanceled = 'WORKER_SHIFT_CANCELED',
  WorkerWaitlisted = 'WORKER_WAITLISTED',
  WorkerConfirmed = 'WORKER_SHIFT_CONFIRMED',
  WorkerRejected = 'WORKER_SHIFT_REJECTED',
  WorkerAppeased = 'WORKER_SHIFT_APPEASED',
  WorkerTodo = 'WORKER_SHIFT_SET_TODO',
  WorkerMarkedAsNoShow = 'WORKER_MARKED_AS_NO_SHOW',
  WorkerRemoved = 'WORKER_REMOVED',
  RobocallAnswered = 'ROBOCALL_ANSWERED',
  WorkerShiftAdjusted = 'WORKER_SHIFT_ADJUSTED',
  TimesheetEdited = 'TIMESHEET_EDITED',
  TimesheetCreated = 'TIMESHEET_CREATED',
  WorkerClockedIn = 'WORKER_SHIFT_CLOCKED_IN',
  WorkerClockedOut = 'WORKER_SHIFT_CLOCKED_OUT',
  IncentiveEarned = 'INCENTIVE_EARNED',
  WorkerArrivedOnsite = 'WORKER_ARRIVED_ONSITE',
  RobocallSentOnShift = 'ROBOCALL_SENT_ON_SHIFT',
  TransferSent = 'TRANSFER_SENT',
  InstantPayoutSent = 'INSTANT_PAYOUT_SENT',
  TransferReversed = 'TRANSFER_REVERSED',
  ShiftBackfillCallMade = 'SHIFT_BACKFILL_CALL_MADE',
  WorkerShiftUpdated = 'WORKER_SHIFT_UPDATED',
  WorkerAbandoned = 'WORKER_SHIFT_ABANDONED',
  WorkerShiftBusinessAdjusted = 'WORKER_SHIFT_BUSINESS_ADJUSTMENT',
  LineItemAddedToInvoice = 'LINE_ITEM_ADDED_TO_INVOICE',
  UpdatedLineItemOnInvoice = 'UPDATED_LINE_ITEM_ON_INVOICE',
  DeletedLineItemOnInvoice = 'DELETED_LINE_ITEM_ON_INVOICE',
  WorkerBlockedOnShift = 'WORKER_BLOCKED_ON_SHIFT',
  WorkerFavoritedOnShift = 'WORKER_FAVORITED_ON_SHIFT',
  WorkerIneligibleOnShift = 'WORKER_INELIGIBLE_ON_SHIFT',
  TimesheetApproved = 'TIMESHEET_APPROVED',
  AnnouncementCreated = 'ANNOUNCEMENT_CREATED',
  WardenStoppedShiftPayment = 'WARDEN_STOPPED_SHIFT_PAYMENT',
  EarlyArrivalTimeBufferAdded = 'EARLY_ARRIVAL_TIME_BUFFER_ADDED',
  EarlyArrivalTimeBufferRemoved = 'EARLY_ARRIVAL_TIME_BUFFER_REMOVED',
}

export enum WorkerEvents {
  WorkerPermissionsChanged = 'WORKER_PERMISSIONS_CHANGED',
  WorkerClockedIn = 'WORKER_SHIFT_CLOCKED_IN',
  WorkerClockedOut = 'WORKER_SHIFT_CLOCKED_OUT',
  RobocallAnswered = 'ROBOCALL_ANSWERED',
  RobocallSent = 'ROBOCALL_SENT',
  InvitationAccepted = 'INVITATION_ACCEPTED',
  AccountStatusUpdated = 'ACCOUNT_STATUS_UPDATED',
  BackgroundCheckUpdated = 'BACKGROUND_CHECK_UPDATED',
  TransferSent = 'TRANSFER_SENT',
  InstantPayoutSent = 'INSTANT_PAYOUT_SENT',
  TransferReversed = 'TRANSFER_REVERSED',
  WorkerShiftStatusUpdated = 'WORKER_SHIFT_STATUS_UPDATED',
  BugleSent = 'BUGLE_SENT',
  WorkerBlocked = 'WORKER_BLOCKED',
  WorkerFavorited = 'WORKER_FAVORITED',
  WorkerIneligible = 'WORKER_INELIGIBLE',
  WorkerCertificationAdded = 'WORKER_CERTIFICATION_ADDED',
  WorkerCertificationApproved = 'WORKER_CERTIFICATION_APPROVED',
  WorkerShiftAdjusted = 'WORKER_SHIFT_ADJUSTED',
  WorkerShiftBusinessAdjusted = 'WORKER_SHIFT_BUSINESS_ADJUSTMENT',
  WorkerShiftUpdated = 'WORKER_SHIFT_UPDATED',
  WorkerAttributesUpdated = 'WORKER_ATTRIBUTES_UPDATED',
  AccountCreated = 'ACCOUNT_CREATED',
  EmailUpdated = 'EMAIL_UPDATED',
  PhoneUpdated = 'PHONE_UPDATED',
  ZipCodeUpdated = 'ZIPCODE_UPDATED',
  ShiftBackfillCallMade = 'SHIFT_BACKFILL_CALL_MADE',
  WorkerShiftCanceled = 'WORKER_SHIFT_CANCELED',
  InvitationSent = 'INVITATION_SENT',
  WorkerAcceptedShift = 'WORKER_ACCEPTED_SHIFT',
  WorkerConfirmed = 'WORKER_SHIFT_CONFIRMED',
  WorkerAppeased = 'WORKER_SHIFT_APPEASED',
  WorkerRejected = 'WORKER_SHIFT_REJECTED',
  WorkerAbandoned = 'WORKER_SHIFT_ABANDONED',
  WorkerMarkedAsNoShow = 'WORKER_MARKED_AS_NO_SHOW',
  WorkerWaitlisted = 'WORKER_WAITLISTED',
  WorkerTodo = 'WORKER_SHIFT_SET_TODO',
  WorkerArrivedOnsite = 'WORKER_ARRIVED_ONSITE',
  WorkerSuspended = 'WORKER_SUSPENDED',
  WorkerBanned = 'WORKER_BANNED',
  WorkerReinstated = 'WORKER_REINSTATED',
  WorkerUnsuspended = 'WORKER_UNSUSPENDED',
  WorkerTerminationStaged = 'WORKER_TERMINATION_STAGED',
  PaymentSent = 'PAYMENT_SENT',
  IncentiveEarned = 'INCENTIVE_EARNED',
  InstantPayMadeEligible = 'INSTANT_PAY_MADE_ELIGIBLE',
  InstantPayMadeIneligible = 'INSTANT_PAY_MADE_INELIGIBLE',
  WorkerBlockedOnShift = 'WORKER_BLOCKED_ON_SHIFT',
  WorkerFavoritedOnShift = 'WORKER_FAVORITED_ON_SHIFT',
  WorkerIneligibleOnShift = 'WORKER_INELIGIBLE_ON_SHIFT',
  RobocallSentOnShift = 'ROBOCALL_SENT_ON_SHIFT',
  WardenStoppedShiftPayment = 'WARDEN_STOPPED_SHIFT_PAYMENT',
  WORKER_PHONE_NUMBER_STATUS_UPDATED = 'WORKER_PHONE_NUMBER_STATUS_UPDATED',
  WORKER_REQUIREMENT_ACTION_UPDATED = 'WORKER_REQUIREMENT_ACTION_UPDATED',
  WORKER_REQUIREMENT_ACTION_LIFTED = 'WORKER_REQUIREMENT_ACTION_LIFTED',
  REQUIREMENT_WAIVER_CREATED = 'REQUIREMENT_WAIVER_CREATED',
}

export enum CompanyEvents {
  WorkerBlocked = 'WORKER_BLOCKED',
  WorkerFavorited = 'WORKER_FAVORITED',
  WorkerIneligible = 'WORKER_INELIGIBLE',
  ShiftPosted = 'SHIFT_POSTED',
  ShiftCanceled = 'SHIFT_CANCELED',
  ShiftEdited = 'SHIFT_EDITED',
  TimesheetApproved = 'TIMESHEET_APPROVED',
  InvoiceDraftCreated = 'INVOICE_DRAFT_CREATED',
  InvoiceFinalized = 'INVOICE_FINALIZED',
  InvoiceDraftDeleted = 'INVOICE_DRAFT_DELETED',
  InvoiceVoided = 'INVOICE_VOIDED',
  RoleCreated = 'ROLE_CREATED',
  RoleEdited = 'ROLE_EDITED',
  RoleArchived = 'ROLE_ARCHIVED',
  RoleDeleted = 'ROLE_DELETED',
  LocationCreated = 'LOCATION_CREATED',
  LocationEdited = 'LOCATION_EDITED',
  LocationArchived = 'LOCATION_ARCHIVED',
  RosterCreated = 'ROSTER_CREATED',
  RosterEdited = 'ROSTER_EDITED',
  FeedbackSubmitted = 'FEEDBACK_SUBMITTED',
  FeedbackUpdated = 'FEEDBACK_UPDATED',
  MemberInvitationCreated = 'MEMBER_INVITATION_CREATED',
  MemberInvitationResent = 'MEMBER_INVITATION_RESENT',
  MemberInvitationAccepted = 'MEMBER_INVITATION_ACCEPTED',
  MemberInvitationRescinded = 'MEMBER_INVITATION_RESCINDED',
  BusinessUserCreated = 'BUSINESS_USER_CREATED',
  BusinessUserRequestedCompanyDeletion = 'BUSINESS_USER_REQUESTED_COMPANY_DELETION',
  BusinessUserRoleEdited = 'BUSINESS_USER_ROLE_EDITED',
  BusinessUserArchived = 'BUSINESS_USER_ARCHIVED',
  NotificationPreferenceEdited = 'NOTIFICATION_PREFERENCE_EDITED',
  InvitationsCreated = 'INVITATIONS_CREATED',
  WorkerShiftBusinessAdjusted = 'WORKER_SHIFT_BUSINESS_ADJUSTMENT',
  LineItemAddedToInvoice = 'LINE_ITEM_ADDED_TO_INVOICE',
  UpdatedLineItemOnInvoice = 'UPDATED_LINE_ITEM_ON_INVOICE',
  DeletedLineItemOnInvoice = 'DELETED_LINE_ITEM_ON_INVOICE',
  TimesheetEdited = 'TIMESHEET_EDITED',
  TimesheetCreated = 'TIMESHEET_CREATED',
  WorkerBlockedOnShift = 'WORKER_BLOCKED_ON_SHIFT',
  WorkerFavoritedOnShift = 'WORKER_FAVORITED_ON_SHIFT',
  WorkerIneligibleOnShift = 'WORKER_INELIGIBLE_ON_SHIFT',
}

export type AuditLog = {
  auditLogId: string
  event: ShiftEvents | WorkerEvents | CompanyEvents
  createdAt: Date
  processedAt: Date
  actorId: string
  actionSource: ActionSource
  workerId?: string
  shiftId?: string
  companyId?: string
  properties?: any
}

export enum ActionSource {
  Ops = 'OPS',
  Business = 'BUSINESS',
  Worker = 'WORKER',
  System = 'SYSTEM',
}
