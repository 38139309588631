import { theme } from '@traba/theme'

export function TrabaFull({
  width = 72,
  height = 24,
  color = theme.colors.MidnightBlue,
}: {
  width: number
  height: number
  color: string
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0823 11.6213L10.0771 19.9949H14.3775V11.7753C14.3775 9.62545 16.1191 7.87073 18.2641 7.87073V3.55057C13.8003 3.55057 10.1588 7.15754 10.0823 11.6213Z"
        fill={color}
      />
      <path
        d="M26.6957 3.55057C22.1809 3.55057 18.5088 7.23963 18.5088 11.7753C18.5088 16.3109 22.1809 20 26.6957 20C27.3801 20 28.039 19.9076 28.6723 19.7486V15.1309C28.0901 15.4797 27.4159 15.6798 26.6957 15.6798C24.5559 15.6798 22.8091 13.9302 22.8091 11.7753C22.8091 9.62545 24.5507 7.87072 26.6957 7.87072C28.7029 7.87072 30.3628 9.40996 30.562 11.3802L30.5824 20H34.8828L34.8623 11.3802C34.6529 7.02926 31.0778 3.55057 26.6957 3.55057Z"
        fill={color}
      />
      <path
        d="M71.3897 11.3802C71.1802 7.02926 67.6052 3.55057 63.2231 3.55057C58.7083 3.55057 55.0361 7.23963 55.0361 11.7753C55.0361 16.3109 58.7083 20 63.2231 20C63.9075 20 64.5663 19.9076 65.1996 19.7486V15.1309C64.6173 15.4797 63.9432 15.6798 63.2231 15.6798C61.0831 15.6798 59.3364 13.9302 59.3364 11.7753C59.3364 9.62545 61.078 7.87072 63.2231 7.87072C65.2302 7.87072 66.8901 9.40996 67.0893 11.3802L67.1097 20H71.41L71.3897 11.3802Z"
        fill={color}
      />
      <path
        d="M44.9594 3.55052C44.275 3.55052 43.6162 3.64288 42.9829 3.80193V8.41967C43.5651 8.07078 44.2393 7.87067 44.9594 7.87067C47.0994 7.87067 48.8461 9.62031 48.8461 11.7753C48.8461 13.925 47.1044 15.6798 44.9594 15.6798C42.9523 15.6798 41.2924 14.1406 41.0931 12.1703C41.083 12.0729 41.0728 11.7803 41.0728 11.7753V4.54077V0H36.7725L36.7928 12.1651C37.0023 16.5161 40.5773 19.9948 44.9594 19.9948C49.4742 19.9948 53.1464 16.3057 53.1464 11.7701C53.1464 7.23959 49.4742 3.55052 44.9594 3.55052Z"
        fill={color}
      />
      <path
        d="M4.30033 0H0V3.55052V7.87067L0.00510728 11.9291C0.0817165 16.3878 3.7232 19.9999 8.18697 19.9999V15.6798C6.04191 15.6798 4.30033 13.925 4.30033 11.7753V7.87067H8.18697V3.55052H4.30033V0Z"
        fill={color}
      />
    </svg>
  )
}
