import { InvoiceStatus } from '@traba/types'
import styled from 'styled-components'

const WatermarkContainer = styled.div<{ text: string }>`
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;

  &::before {
    content: '${({ text }) => `${text} `.repeat(100)}';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    display: flex;
    flex-wrap: wrap;
    transform: rotate(-45deg);
    white-space: pre-wrap;
    font-size: 50px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    word-spacing: 8rem;
    line-height: 12rem;
  }
`

export function Watermark({ status }: { status: InvoiceStatus }) {
  if (status === 'open') {
    return null
  }
  return <WatermarkContainer text={status.toUpperCase()} />
}
