import { Anchor } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon } from 'src/components/base'

type ShiftIdRowProps = {
  shiftId: string
}

export function ShiftIdRow({ shiftId }: ShiftIdRowProps) {
  return (
    <Anchor
      href={`/field-monitor/${shiftId}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.space.xxs,
      }}
      openInNewTab={true}
    >
      <Icon name="link" style={{ marginRight: theme.space.xxxs }} />
      Shift ID: {shiftId}
    </Anchor>
  )
}
