import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  WorkerApplication,
  WorkerApplicationItemCompletionStatus,
  WorkerApplicationItemStatus,
  WorkerApplicationWithWorkerInfo,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

const WORKER_APPLICATIONS_QUERY_KEY = 'workerApplications'

type GetWorkerApplicationForShiftRequestParams = {
  shiftRequestId: string | undefined
}

async function getWorkerApplicationsForShiftRequest(
  params: GetWorkerApplicationForShiftRequestParams,
): Promise<WorkerApplicationWithWorkerInfo[] | undefined> {
  try {
    const res = await trabaApi.get(`/worker-applications/search-by-entity`, {
      params,
    })
    return res.data
  } catch (error) {
    console.error(
      'useWorkerApplicationsForShiftRequest -> getWorkerApplicationsForShiftRequest() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action:
          'useWorkerApplicationsForShiftRequest -> getWorkerApplicationsForShiftRequest() ERROR',
      },
    })
    throw error
  }
}

export function useWorkerApplicationsForShiftRequest(
  params: GetWorkerApplicationForShiftRequestParams,
) {
  const {
    isLoading,
    isError,
    data: workerApplications,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerApplicationWithWorkerInfo[] | undefined, Error>({
    queryKey: [WORKER_APPLICATIONS_QUERY_KEY, params.shiftRequestId],
    queryFn: () => getWorkerApplicationsForShiftRequest(params),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!params.shiftRequestId,
  })

  return {
    isLoading,
    isError,
    workerApplications,
    error,
    isFetched,
    refetch,
  }
}

interface UpdateWorkerApplicationItemStatusParams {
  workerApplicationItemId: string
  status?: WorkerApplicationItemStatus
  completionStatus?: WorkerApplicationItemCompletionStatus
}

async function updateWorkerApplicationItemStatus({
  workerApplicationItemId,
  status,
  completionStatus,
}: UpdateWorkerApplicationItemStatusParams): Promise<
  WorkerApplicationWithWorkerInfo | undefined
> {
  if (!workerApplicationItemId) {
    throw new Error('workerApplicationItemId is required')
  }

  if (!status && !completionStatus) {
    throw new Error('status or completionStatus is required')
  }

  try {
    const response = await trabaApi.patch(
      `worker-applications/item/${workerApplicationItemId}`,
      { status, completionStatus },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useWorkerApplicationsMutations -> changeWorkerApplicationItemStatus() ERROR',
      },
    })
    throw error
  }
}

export function useWorkerApplicationsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const updateWorkerApplicationItemStatusMutation = useMutation<
    WorkerApplication | undefined,
    Error,
    UpdateWorkerApplicationItemStatusParams,
    WorkerApplication
  >({
    mutationFn: updateWorkerApplicationItemStatus,
    onSuccess: (data: WorkerApplication | undefined) => {
      queryClient.setQueryData(
        [WORKER_APPLICATIONS_QUERY_KEY, data?.applicationEntity.shiftRequestId],
        (
          workerApplications:
            | WorkerApplicationWithWorkerInfo[]
            | undefined = [],
        ) => {
          return workerApplications.map((wa) =>
            wa.id === data?.id ? { ...data, workerInfo: wa.workerInfo } : wa,
          )
        },
      )
      showSuccess(`Worker application item status was successfully updated`)
    },
    onError: (error) => {
      showError(error?.message, 'Error changing worker application item status')
    },
  })

  return {
    updateWorkerApplicationItemStatus:
      updateWorkerApplicationItemStatusMutation.mutate,
    isUpdateWorkerApplicationItemStatusLoading:
      updateWorkerApplicationItemStatusMutation.isPending,
  }
}
