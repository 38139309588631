import { useMemo, useState } from 'react'
import { FieldType, useSearchInputItems } from 'src/hooks/useSearchInputItems'
import { useFieldsConfig } from '../../../hooks/useFieldsConfig'
import { buildInputsList } from '../../../screens/WorkerSearchScreen/components/ConfigDrawer/helpers'

type UseConfigDrawerProps = {
  form: any
  setForm: (form: any) => void
  handleClearParam: (param: string) => void
  handleClear: () => void
  roleAttributes: any
  preSelectedFields?: string[]
}

type UseConfigDrawerResult = {
  fieldsToShow: any[]
  handleDrawerClose: (fields: string[]) => void
  showDrawer: boolean
  setShowDrawer: (show: boolean) => void
}

const useConfigDrawer = ({
  form,
  setForm,
  handleClearParam,
  roleAttributes,
  preSelectedFields,
}: UseConfigDrawerProps): UseConfigDrawerResult => {
  const [selectedFields, setSelectedFields] = useFieldsConfig(
    'bugle-custom-search-fields',
    preSelectedFields,
  )
  const { bugleInputFields } = useSearchInputItems()

  const [showDrawer, setShowDrawer] = useState(false)

  const fieldsToShow = useMemo(() => {
    return buildInputsList(selectedFields, bugleInputFields, roleAttributes)
  }, [selectedFields, bugleInputFields, roleAttributes])

  const handleDrawerClose = (fields: string[]) => {
    setSelectedFields(fields)
    const newFields = buildInputsList(fields, bugleInputFields)
    newFields.forEach((field) => {
      if (field.type === FieldType.checkbox) {
        form[field.field] = false
      }
    })
    const removedFields = fieldsToShow.filter(
      (field) => !newFields.includes(field),
    )
    const updatedForm = { ...form }
    removedFields.forEach((field) => {
      if (form[field.field] !== undefined && form[field.field] !== null) {
        delete updatedForm[field.field]
        handleClearParam(field.field)
      }
    })
    setForm(updatedForm)
  }

  return {
    fieldsToShow,
    handleDrawerClose,
    showDrawer,
    setShowDrawer,
  }
}

export { useConfigDrawer }
