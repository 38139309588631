import { Row, Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { WorkerBadge } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/WorkerBadge'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

type StaticWorkersListProps = {
  workers: PopulatedWorker[]
}

export const StaticWorkersList = ({ workers }: StaticWorkersListProps) => {
  return (
    <>
      <Text variant="label" pt={theme.space.xxs} mb={0}>
        Add worker{makePlural(workers.length)} to the selected shifts
      </Text>

      <Row
        wrap
        px={theme.space.xxs}
        pb={theme.space.sm}
        fullWidth
        gap={theme.space.xxs}
      >
        {workers.map((worker) => (
          <WorkerBadge key={worker.workerId} worker={worker} />
        ))}
      </Row>
    </>
  )
}
