import {
  SvgIcon,
  Text,
  ButtonVariant,
  Button,
  InlineBanner,
  LinkText,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Modal, Row, Icon } from 'src/components/base'

type ConfirmVettingCampaignSyncModalProps = {
  handleClose: () => void
  isOpen: boolean
  onSync: () => Promise<void>
  onRefresh: () => Promise<void>
  showRefreshBanner?: boolean
  campaignLink?: string
}

export const ConfirmVettingCampaignSyncModal = ({
  handleClose,
  isOpen,
  onSync,
  onRefresh,
  showRefreshBanner = false,
  campaignLink,
}: ConfirmVettingCampaignSyncModalProps) => {
  const [syncLoading, setSyncLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)

  const handleDoneVetting = async () => {
    setSyncLoading(true)
    try {
      await onSync()
    } finally {
      setSyncLoading(false)
      handleClose()
      if (campaignLink) {
        window.open(campaignLink, '_blank')
      }
    }
  }

  const handleVetMoreWorkers = async () => {
    setRefreshLoading(true)
    try {
      await onRefresh()
    } finally {
      setRefreshLoading(false)
      handleClose()
      if (campaignLink) {
        window.open(campaignLink, '_blank')
      }
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title="Sync Worker Data"
      style={{ minHeight: 'auto', width: '886px' }}
    >
      {showRefreshBanner && (
        <InlineBanner
          severity="warning"
          text="This vetting campaign hasn't been synced in a while. We recommend clicking 'Vet More Workers' to update the spreadsheet with the latest data from the ops console."
          style={{ marginBottom: theme.space.sm }}
        />
      )}
      <Row fullWidth>
        <div
          style={{
            flex: 1,
            border: `1px solid ${theme.border.color}`,
            borderRadius: theme.border.radius,
            padding: '20px 16px',
            marginRight: theme.space.sm,
          }}
        >
          <Row flexCol>
            <Row justifyStart mb={theme.space.sm}>
              <Row alignCenter>
                <Icon type="svg" name="trabaSquareLogo" />
                <Row px={theme.space.xxs}>
                  <SvgIcon
                    name="arrowRight"
                    color={theme.colors.Grey60}
                    width={theme.space.lg}
                    height={theme.space.lg}
                    size={theme.space.lg}
                    strokeWidth={1}
                    path="M4 8H12M12 8L8.5 5M12 8L8.5 11"
                  />
                </Row>
                <Icon type="svg" name="googleSheetsLogo" />
              </Row>
            </Row>
            <Text variant="h5" mb={theme.space.xs}>
              I want to vet more of these workers
            </Text>
            <Text variant="body1" color={theme.colors.Grey60}>
              We will update the Google Sheet to include any new data from the
              ops console since the last sync.
            </Text>
            <Button
              variant={ButtonVariant.FILLED}
              onClick={handleVetMoreWorkers}
              loading={refreshLoading}
              style={{ marginTop: theme.space.sm }}
              full
            >
              Vet More Workers
            </Button>
          </Row>
        </div>
        <div
          style={{
            flex: 1,
            border: `1px solid ${theme.border.color}`,
            borderRadius: theme.border.radius,
            padding: '20px 16px',
          }}
        >
          <Row flexCol>
            <Row justifyStart mb={theme.space.sm}>
              <Row alignCenter>
                <Icon type="svg" name="googleSheetsLogo" />
                <Row px={theme.space.xxs}>
                  <SvgIcon
                    name="arrowRight"
                    color={theme.colors.Grey60}
                    width={theme.space.lg}
                    height={theme.space.lg}
                    size={theme.space.lg}
                    strokeWidth={1}
                  />
                </Row>
                <Icon type="svg" name="trabaSquareLogo" />
              </Row>
            </Row>
            <Text variant="h5" mb={theme.space.xs}>
              I am finished vetting these workers
            </Text>
            <Text variant="body1" color={theme.colors.Grey60}>
              We will update all of the attributes you vetted from the Google
              Sheet to the ops console.
            </Text>
            <Button
              variant={ButtonVariant.BRANDNEW}
              onClick={handleDoneVetting}
              loading={syncLoading}
              style={{ marginTop: theme.space.sm }}
              full
            >
              Done Vetting
            </Button>
          </Row>
        </div>
      </Row>
      {campaignLink && (
        <Row justifyCenter mt={theme.space.sm}>
          <LinkText
            variant="link1"
            color={theme.colors.Violet}
            onClick={() => window.open(campaignLink, '_blank')}
          >
            Continue to Google Sheet without syncing
          </LinkText>
        </Row>
      )}
    </Modal>
  )
}
