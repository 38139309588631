import { ShiftAttribute } from './shift-attributes'

export const ROLE_ATTRIBUTE_STAR_RATING_TYPE = 'star_rating'

export enum RoleAttributeCategory {
  Responsibilities = 'RESPONSIBILITIES',
  Forklift = 'FORKLIFT',
  Equipment = 'EQUIPMENT',
  Lifting = 'LIFTING',
  Gear = 'GEAR',
  Languages = 'LANGUAGES',
  MANUFACTURING_AND_PRODUCTION = 'MANUFACTURING_AND_PRODUCTION',
  FOOD_PREPARATION_AND_SERVICE = 'FOOD_PREPARATION_AND_SERVICE',
  TRAITS = 'TRAITS',
  CUSTOM = 'CUSTOM',
}

export enum RoleAttributeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum RoleAttributeVisibility {
  Visible = 'VISIBLE',
  Hidden = 'HIDDEN',
}

export interface RoleAttribute {
  category: RoleAttributeCategory
  type: string
  levelType: AttributeLevelType
  displayName: string
  reportable: boolean
  workerDescription?: string
  companyDescription?: string
  videoId?: string
  status: RoleAttributeStatus
  visibility: RoleAttributeVisibility
  allowOpsLeveling: boolean
}

export interface AdminUsersResponse {
  id: string
  email: string
  firstName: string
  lastName: string
  companyId: string
}

export interface BehaviorAttribute {
  type: string
  displayNameEn: string
  companyDescriptionEn?: string
}

export enum WorkerProfileStatus {
  Reported = 'REPORTED',
  Verified = 'VERIFIED',
  Revoked = 'REVOKED',
}

export enum AttributeBaseLevels {
  DESELECTED = 'DESELECTED',
  REVOKED = 'REVOKED',
  UNREPORTED = 'UNREPORTED',
  PRESENT = 'PRESENT',
}

export enum AttributeStarLevels {
  DESELECTED = 'DESELECTED',
  ONE_STAR = 'ONE_STAR',
  TWO_STAR = 'TWO_STAR',
  THREE_STAR = 'THREE_STAR',
  FOUR_STAR = 'FOUR_STAR',
  FIVE_STAR = 'FIVE_STAR',
  UNREPORTED = 'UNREPORTED',
}

export enum AttributeSkillLevels {
  DESELECTED = 'DESELECTED',
  REVOKED = 'REVOKED',
  UNREPORTED = 'UNREPORTED',
  REPORTED = 'REPORTED',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type OpsAttributeLevel =
  | AttributeBaseLevels
  | AttributeSkillLevels
  | AttributeStarLevels

export enum AttributeLevelType {
  SKILL_LEVELING = 'SKILL_LEVELING',
  BASE_LEVELING = 'BASE_LEVELING',
  STAR_LEVELING = 'STAR_LEVELING',
}

export interface WorkerProfile {
  category: RoleAttributeCategory
  createdAt: Date
  profileField: string
  status: WorkerProfileStatus
  opsLevel?: OpsAttributeLevel
  opsNotes?: string
  opsLevelUpdatedBy?: string
  updatedAt?: Date
  value?: boolean
  workerId: string
  positiveAttributeFeedback?: number
  negativeAttributeFeedback?: number
  isWorkerReported: boolean
}

export type OpsUpdateWorkerProfileItem = {
  category: WorkerProfile['category']
  value?: WorkerProfile['value']
  opsLevel?: WorkerProfile['opsLevel']
  opsNotes?: WorkerProfile['opsNotes']
  profileField: WorkerProfile['profileField']
}

export type RequiredAttributeLevel = {
  attribute: ShiftAttribute
  level: OpsAttributeLevel
}

export type RequiredAttributeLevelWithLevelType = RequiredAttributeLevel & {
  levelType: AttributeLevelType
}

export interface WorkerProfileResponse extends WorkerProfile {
  statusChanged: boolean
  valueChanged: boolean
}
