import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import styled from 'styled-components'

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.Grey10,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: `-7px 6px 17px 0px rgba(0,0,0,0.29)`,
    fontSize: 10,
    padding: theme.space.sm,
  },
}))
