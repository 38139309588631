import { useAlert } from '@traba/context'
import { Button, ButtonVariant, Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CancellationSource,
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  formatDateString,
  getStartTime,
  isRecurringShiftRequest,
} from '@traba/utils'
import { useMemo, useState } from 'react'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { useShiftRequestEditsMutation } from 'src/hooks/useShiftRequestEdits'
import { getPayRateString } from 'src/utils/stringUtils'
import { ScheduleOccurrenceSelector } from '../ScheduleOccurrenceSelector'
import { EditScheduleOption } from './EditScheduleOption'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSuccess: () => void
  onBack: () => void
  shifts: Shift[] | undefined
}

export const EditScheduleCancelShiftRequests = (props: Props) => {
  const { shiftRequestParent, onBack, shifts, onSuccess } = props
  const recurringShiftRequests = shiftRequestParent.shiftRequests.filter(
    isRecurringShiftRequest,
  )
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const [selectedShiftRequest, setSelectedShiftRequest] =
    useState<ShiftRequest>()
  const { showError } = useAlert()

  const { editShiftRequest, isPending } = useShiftRequestEditsMutation(
    shiftRequestParent.shiftRequestParentId,
    shiftRequestParent.companyId,
  )

  const ongoingRecurringShiftRequests = useMemo(() => {
    const activeShiftRequestIds = shifts?.reduce((acc, shift) => {
      if (shift.status === ShiftStatus.ACTIVE) {
        acc.add(shift.shiftRequestId)
      }
      return acc
    }, new Set<string>())

    return recurringShiftRequests.filter((sr) =>
      activeShiftRequestIds?.has(sr.shiftRequestId),
    )
  }, [recurringShiftRequests, shifts])

  const onConfirm = async () => {
    try {
      if (!selectedShiftRequest) {
        return showError('Please select a shift request to cancel')
      }
      if (!selectedDate) {
        return showError('Please select a date to cancel from')
      }
      await editShiftRequest({
        edit: {
          shiftRequestId: selectedShiftRequest?.shiftRequestId,
          editType: ShiftRequestEditType.ALL_FUTURE,
          cancellationSource: CancellationSource.Ops,
          cancellationReason: 'Cancelled by ops user',
          status: ShiftStatus.CANCELED,
          canceledAt: new Date(),
          originalStartTime: combineTwoDatesForDateAndTime(
            selectedDate,
            selectedShiftRequest.schedules[0].startTime,
          ),
        },
      })
      onSuccess()
    } catch (error) {
      showError('Failed to cancel, please try again')
    }
  }

  return (
    <>
      <Text variant="h5" mb={theme.space.xxs}>
        Select which shift request you'd like to cancel from schedule
      </Text>
      {recurringShiftRequests.length === 1 && (
        <InlineBanner
          style={{
            marginTop: theme.space.xs,
            marginBottom: theme.space.xs,
          }}
          severity="warning"
          text="This is the last shift request in the schedule. Are you sure you want to cancel?"
        />
      )}
      <Col gap={theme.space.xs}>
        {ongoingRecurringShiftRequests.map((sr) => (
          <EditScheduleOption
            key={sr.shiftRequestId}
            selected={
              selectedShiftRequest?.shiftRequestId === sr.shiftRequestId
            }
            title={`${sr.shiftRole} (${sr.slotsRequested} slots - ${getPayRateString(sr.payType, sr.payRate)}) - starting ${formatDateString(
              getStartTime(sr.schedules),
              { year: 'numeric' },
            )}`}
            onClick={() => {
              setSelectedShiftRequest(sr)
            }}
          />
        ))}
      </Col>

      {selectedShiftRequest && (
        <>
          <Text variant="h5" mt={theme.space.lg}>
            Select the date after which this shift request should be canceled
          </Text>
          <InlineBanner
            style={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xs,
            }}
            severity="info"
            text="For example, if you want cancel ALL SHIFTS for this shift request on and after Jun.30th, select Jun.30th"
          />
          <ScheduleOccurrenceSelector
            minimal
            selectedDate={selectedDate}
            onSelectDate={setSelectedDate}
            shiftRequest={selectedShiftRequest}
          />
        </>
      )}
      <Row justifyBetween mt={theme.space.lg}>
        <Button onClick={onBack} variant={ButtonVariant.OUTLINED}>
          Back
        </Button>
        <Button
          onClick={onConfirm}
          disabled={!selectedShiftRequest || !selectedDate || isPending}
          loading={isPending}
        >
          Confirm cancel
        </Button>
      </Row>
    </>
  )
}
