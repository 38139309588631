import { Checkbox } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AssigneeDisplay,
  InternalUserRole,
  SentinelNotification,
  RoleAttribute,
} from '@traba/types'
import { getEarlyArrivalTimeBufferInMinutes } from '@traba/utils'
import { addMinutes, isAfter } from 'date-fns'
import { MouseEvent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { FieldMonitorFiltersType as FieldMonitorFilters } from 'src/screens/FieldMonitorScreen/components/FieldMonitorFilters/FieldMonitorFilters'
import {
  getTagEmojiString,
  shouldShowOverbookWarning,
  shouldShowInvitationTag,
  shouldShowRmsaTag,
} from 'src/utils/shiftUtils'
import { toPercentString } from 'src/utils/stringUtils'
import { Badge, Button, Icon, IconButton, Row, Td, Tr } from '../base'
import { EmploymentTypeBadge } from '../base/Badge/EmploymentTypeBadge'
import { ButtonVariant } from '../base/Button/types'
import { IMenuItem } from '../base/Select/Select'
import { shouldShowShift } from '../CollapsibleShiftTable/util'
import { UnreadSentinelNotificationLabel } from '../Sentinel/components/UnreadSentinelNotificationLabel'
import { SentinelShiftNotificationsDropdown } from '../Sentinel/SentinelShiftDropdown'
import { ShiftAndCompanyNoteDrawer } from '../ShiftAndCompanyNoteDrawer'
import { ShiftAssignmentLabel } from '../ShiftAssigneeLabel'
import { ShiftDetails } from '../ShiftDetails/ShiftDetails'
import { EarlyArrivalBufferBadge } from './components/EarlyArrivalBufferBadge'
import { RegionCellContent } from './components/RegionCell'

export interface CollapsibleShiftRowProps {
  shift: OpsExtendedShift
  fieldMonitorFilters?: FieldMonitorFilters
  activeFilterCount?: number
  workerFirstName?: string
  workerLastName?: string
  ffMax?: IMenuItem[]
  assigneeDisplay?: IMenuItem[]
  disableCollapsible?: boolean
  roleAttributes?: RoleAttribute[]
  onSelectShift?: (shiftId: string) => void
  isSelected?: boolean
  showSelect?: boolean
  assignedTo?: IMenuItem[]
  isSentinelNotificationsMuted?: boolean
  sentinelNotifications?: SentinelNotification[]
  uncollapseRow?: boolean
}

// This logic is consistent with the logic in the CompanyShiftsTable/CompanyShiftsTable.tsx populateSlotsFilled(). Please update both if changing this.
const formatStringOrCheck = (
  first: number,
  second: number,
  options?: {
    shouldShowWarning?: boolean
    warningBackground?: boolean
    warningBackgroundColor?: string
    textSuffix?: string
    newLine?: string
  },
) => {
  const shouldShowRedText =
    typeof options?.shouldShowWarning !== 'undefined'
      ? options?.shouldShowWarning
      : first > second
  // If it is ever greater want them to see how much greater
  // e.a. see how much they are overbooking by
  return (
    <Td
      style={{
        ...(options?.warningBackground
          ? {
              backgroundColor:
                options?.warningBackgroundColor ?? theme.colors.Red10,
            }
          : {}),
      }}
    >
      <Row style={{ display: 'inline' }}>
        {first > 0 && first === second && (
          <>
            <Icon name="greenCheck" />{' '}
          </>
        )}
        <Text
          style={{
            display: 'inline',
            color: shouldShowRedText ? theme.colors.Red80 : '',
          }}
        >
          {first}/{second}
        </Text>
      </Row>
      {options && options.textSuffix}
      {options && options.newLine && <Row>{options.newLine}</Row>}
    </Td>
  )
}

export const CollapsibleShiftRow = (props: CollapsibleShiftRowProps) => {
  const {
    shift,
    workerFirstName,
    workerLastName,
    roleAttributes,
    fieldMonitorFilters,
    activeFilterCount,
    onSelectShift,
    isSelected,
    showSelect,
    assignedTo,
    isSentinelNotificationsMuted,
    sentinelNotifications,
    ffMax,
    assigneeDisplay,
    disableCollapsible,
    uncollapseRow,
  } = props

  const [expanded, setExpanded] = useState<boolean>(
    disableCollapsible || uncollapseRow ? true : false,
  )
  const [showSentinelNotifications, setShowSentinelNotifications] =
    useState<boolean>(false)
  const [isLoadingSentinelNotifications, setIsLoadingSentinelNotifications] =
    useState<boolean>(false)
  const [isNoteDrawerOpen, setIsNoteDrawerOpen] = useState<boolean>(false)
  const { showSuccess } = useAlert()

  const isCanceled = !!shift.canceledAt

  const { state } = useUserContext()
  const { hotSettings } = useHotSettings()

  const tz = useTimezonedDates(shift.timezone)

  /* Parse worker shifts for required info */

  /*
   * Determine if shift has a buffer
   */
  const earlyArrivalBuffer = getEarlyArrivalTimeBufferInMinutes({
    shiftStartTime: shift.startTime,
    businessStartTime: shift.businessStartTime,
  })

  const internalUser = state.userProfile?.internalUser
  const showSentinelNotificationLabel = useMemo(
    () =>
      !!internalUser &&
      !disableCollapsible &&
      hotSettings?.enableSentinelNotificationUI &&
      !!sentinelNotifications?.length,
    [
      internalUser,
      disableCollapsible,
      hotSettings?.enableSentinelNotificationUI,
      sentinelNotifications?.length,
    ],
  )

  // bubble icon logic
  const memoizedAssignees = useMemo(() => {
    return shift.shiftAssignment?.assignees.filter((assignee) => {
      const assigneeDisplaySet = new Set(
        assigneeDisplay?.map((item) => item.value),
      )

      if (
        assigneeDisplaySet.has(AssigneeDisplay.MARKET_OPS) &&
        assigneeDisplaySet.has(AssigneeDisplay.SCALED_OPS)
      ) {
        return (
          assignee?.internalUser.role === InternalUserRole.MARKET_OPS ||
          assignee?.internalUser.role === InternalUserRole.SCALED_OPS
        )
      } else if (assigneeDisplaySet.has(AssigneeDisplay.MARKET_OPS)) {
        return assignee?.internalUser.role === InternalUserRole.MARKET_OPS
      } else if (assigneeDisplaySet.has(AssigneeDisplay.SCALED_OPS)) {
        return assignee?.internalUser.role === InternalUserRole.SCALED_OPS
      }
    })
  }, [shift.shiftAssignment?.assignees, assigneeDisplay])

  /*
   * Logic for showing warnings
   */
  const curDate = new Date()
  const thirtyMinsAfterShift = addMinutes(new Date(shift.endTime), 30)

  const {
    showShift,
    warnLowReliability,
    reliabilityAverage,
    confirmedWS,
    expectedToWork,
    warnConfirm,
    clockedInWS,
    warnClockIn,
    clockedOutWS,
    warnSlotsFilled,
    workerShiftsOwed,
    workerShiftsPaid,
    warnOverbooked,
    warnPaidBackupSlotsFilled,
    warnPaid,
    warnClockOut,
  } = useMemo(
    () =>
      shouldShowShift({
        activeFilterCount,
        assignedTo,
        ffMax,
        fieldMonitorFilters,
        internalUser,
        shift,
        workerFirstName,
        workerLastName,
        useLegacyFieldMonitorFiltering:
          hotSettings?.useLegacyFieldMonitorFiltering ?? true,
      }),
    [
      activeFilterCount,
      assignedTo,
      ffMax,
      fieldMonitorFilters,
      internalUser,
      shift,
      workerFirstName,
      workerLastName,
      hotSettings?.useLegacyFieldMonitorFiltering,
    ],
  )

  const handleCheckboxChange = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onSelectShift && onSelectShift(shift.id)
  }

  const handleRowClick = (ev: MouseEvent<HTMLTableRowElement>) => {
    if (ev.altKey) {
      navigator.clipboard.writeText(shift.id)
      showSuccess('', `Shift ID copied to clipboard: ${shift.id}`, 2000)
    } else {
      setExpanded(!expanded)
    }
  }

  const roleName = shift.shiftRole || shift.role?.roleName

  function getSlotsFilledTextSuffixAndNewLine(shift: OpsExtendedShift) {
    const {
      overbookSlotsRequested,
      paidBackupSlotsRequested,
      paidBackupSlotsFilled,
    } = shift

    let textSuffix = ''
    if (overbookSlotsRequested) {
      textSuffix += `/OB${overbookSlotsRequested}`
    }

    let newLine = ''
    if (paidBackupSlotsRequested || paidBackupSlotsFilled) {
      newLine += `${paidBackupSlotsFilled ?? 0}/PB${
        paidBackupSlotsRequested ?? 0
      }`
    }
    return { textSuffix, newLine }
  }

  if (!showShift) {
    return null
  }
  return (
    <>
      <Tr
        onClick={handleRowClick}
        key={`shiftRow_${shift.id}`}
        style={{
          backgroundColor:
            expanded && !disableCollapsible
              ? theme.colors.Grey10
              : theme.colors.White,
          cursor: disableCollapsible ? 'auto' : 'pointer',
        }}
      >
        {!!showSelect && (
          <Td>
            <Row alignCenter justifyCenter>
              <Checkbox checked={isSelected} onClick={handleCheckboxChange} />
            </Row>
          </Td>
        )}

        <Td
          style={{
            borderLeft: !showSelect
              ? `10px solid ${theme.colors.Grey20}`
              : undefined,
          }}
        >
          <Row alignCenter justifyBetween>
            {getTagEmojiString(
              shift.tags || [],
              shouldShowInvitationTag(shift),
              shouldShowRmsaTag(shift),
            )}
            {shift.employerName || shift.company.employerName}
            {shift.shiftEmploymentType && (
              <EmploymentTypeBadge
                employmentTypes={[shift.shiftEmploymentType]}
              />
            )}
            <Row>
              {isCanceled && (
                <Badge
                  style={{ marginLeft: theme.space.xxs }}
                  variant="opaqueRed"
                  title="Canceled"
                />
              )}
              {shift.shiftAssignment &&
                memoizedAssignees?.map((assignee) => {
                  return (
                    <span
                      style={{ marginLeft: theme.space.xxxs }}
                      key={`${assignee.id}_${shift.id}`}
                    >
                      <ShiftAssignmentLabel
                        internalUser={assignee.internalUser}
                      />
                    </span>
                  )
                })}

              {showSentinelNotificationLabel && (
                <UnreadSentinelNotificationLabel
                  sentinelNotificationCount={sentinelNotifications?.length ?? 0}
                  onClickLabel={() =>
                    setShowSentinelNotifications((prevValue) => !prevValue)
                  }
                  isMuted={!!isSentinelNotificationsMuted}
                  isLoading={isLoadingSentinelNotifications}
                />
              )}
            </Row>
          </Row>
        </Td>
        <Td>
          <RegionCellContent
            regionId={shift.regionId}
            locationName={shift.location.name || shift.location.shortLocation}
          />
        </Td>
        <Td>{roleName}</Td>
        <Td>
          {tz.getShiftDate(shift.startTime, shift.endTime, { year: undefined })}
        </Td>
        <Td>
          <Row alignCenter>
            <Text>{tz.getTime(shift.startTime)}</Text>
            {!!earlyArrivalBuffer && (
              <EarlyArrivalBufferBadge
                buffer={earlyArrivalBuffer}
                style={{ marginLeft: theme.space.xxs }}
              />
            )}
          </Row>
        </Td>
        <Td>{tz.getTime(shift.endTime)}</Td>
        {/* RELIABILITY */}
        <Td
          style={{
            ...(warnLowReliability
              ? {
                  backgroundColor: theme.colors.Red10,
                }
              : {}),
          }}
        >
          <Row style={{ display: 'inline' }}>
            <Text
              style={{
                display: 'inline',
              }}
            >
              {reliabilityAverage !== null
                ? `${toPercentString(reliabilityAverage)}%`
                : '-'}
            </Text>
          </Row>
        </Td>
        {/* WORKERS ON THE WAY */}
        <Td>
          {expectedToWork &&
            formatStringOrCheck(
              shift.workersMovingOrArrived,
              expectedToWork.length,
            )}
        </Td>
        {/* WORKERS CONFIRMED */}
        {confirmedWS &&
          expectedToWork &&
          formatStringOrCheck(confirmedWS.length, expectedToWork.length, {
            warningBackground: warnConfirm || warnOverbooked,
            warningBackgroundColor: warnOverbooked
              ? theme.colors.Blue10
              : undefined,
          })}

        {/* WORKERS CLOCKED IN */}
        {clockedInWS &&
          expectedToWork &&
          formatStringOrCheck(clockedInWS.length, expectedToWork.length, {
            warningBackground: warnClockIn,
          })}

        {/* WORKERS CLOCKED OUT */}
        {(clockedOutWS && clockedOutWS.length > 0) ||
        isAfter(curDate, thirtyMinsAfterShift) ? (
          clockedOutWS &&
          expectedToWork &&
          formatStringOrCheck(clockedOutWS.length, expectedToWork.length, {
            warningBackground: warnClockOut,
          })
        ) : (
          <Td>-</Td>
        )}

        {/* WORKER SLOTS FILLED */}
        {formatStringOrCheck(shift.slotsFilled, shift.slotsRequested, {
          shouldShowWarning: shouldShowOverbookWarning(shift),
          warningBackground: warnSlotsFilled || warnPaidBackupSlotsFilled,
          ...(shift.slotsFilled < shift.slotsRequested
            ? { warningBackgroundColor: theme.colors.Red40 }
            : warnPaidBackupSlotsFilled
              ? { warningBackgroundColor: theme.colors.Red10 }
              : {}),
          ...getSlotsFilledTextSuffixAndNewLine(shift),
        })}

        {/* WORKERS PAID */}
        {workerShiftsOwed > 0 ? (
          formatStringOrCheck(workerShiftsPaid, workerShiftsOwed, {
            warningBackground: warnPaid,
          })
        ) : (
          <Td>-</Td>
        )}
        <Td>
          <IconButton
            iconName="message"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setIsNoteDrawerOpen((isOpen) => !isOpen)
            }}
          />
        </Td>
        <Td>
          {!disableCollapsible && (
            <Icon
              name={expanded ? 'chevronUp' : 'chevronDown'}
              style={{
                height: 12,
                width: 12,
                cursor: 'pointer',
              }}
            />
          )}
        </Td>
      </Tr>
      {!!internalUser && showSentinelNotifications && (
        <Tr style={{ width: '100%' }}>
          <Td colSpan={14}>
            <SentinelShiftNotificationsDropdown
              shiftId={shift.id}
              timezone={shift.timezone}
              internalUser={internalUser}
              onClose={() => setShowSentinelNotifications(false)}
              setIsLoadingShiftNotifications={setIsLoadingSentinelNotifications}
              companyId={shift.companyId}
            />
          </Td>
        </Tr>
      )}
      {expanded ? (
        <Tr style={{ width: '100%' }}>
          <Td colSpan={16} style={{ padding: 0, margin: 0 }}>
            <div style={{ marginTop: theme.space.xs }}>
              <ShiftDetails shift={shift} roleAttributes={roleAttributes} />
            </div>

            {!disableCollapsible && (
              <Row justifyEnd gap={theme.space.xs} mb={theme.space.xs}>
                <Link
                  style={{ width: '100%' }}
                  to={`/field-monitor/${shift.id}`}
                >
                  <Button full variant={ButtonVariant.FILLED}>
                    Check shift details
                  </Button>
                </Link>
                {shift.shiftRequestParentId && (
                  <Link
                    style={{ width: '100%' }}
                    to={`/schedule/${shift.shiftRequestParentId}`}
                  >
                    <Button full variant={ButtonVariant.PURPLEGRADIENT}>
                      Go to schedule
                    </Button>
                  </Link>
                )}
              </Row>
            )}
          </Td>
        </Tr>
      ) : null}
      {isNoteDrawerOpen && (
        <ShiftAndCompanyNoteDrawer
          shiftId={shift.id}
          companyId={shift.companyId}
          hideFAB={true}
          isOpen={isNoteDrawerOpen}
          setIsOpen={setIsNoteDrawerOpen}
        />
      )}
    </>
  )
}
