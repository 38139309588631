import { Badge } from 'src/components/base'
import { useSearchInputItems } from 'src/hooks/useSearchInputItems'

type WorkerSearchBadgesProps = {
  searchParams: any
  onClearBadge: (key: string) => void
}

export default function WorkerSearchBadges({
  searchParams,
  onClearBadge,
}: WorkerSearchBadgesProps) {
  const { allInputFields } = useSearchInputItems()
  const filteredInputFields = allInputFields.filter(
    (field) => field.field !== 'activeEmploymentType',
  )
  return (
    <>
      {Object.keys(searchParams).map((key) => {
        let value = searchParams[key]
        if (key === 'regionIds') {
          key = 'regionId'
          value = value[0]
        }
        const inputField = filteredInputFields.find((f) => f.field === key)

        if (
          value === null ||
          value === undefined ||
          value === '' ||
          !inputField
        ) {
          return null
        }

        const label = inputField?.label
        const itemLabelExtractor = inputField?.itemLabelExtractor

        const formattedValue = Array.isArray(value)
          ? value
              .map((v) =>
                itemLabelExtractor ? itemLabelExtractor(v) : v.label,
              )
              .join(', ')
          : value

        return (
          <Badge
            key={key}
            style={{
              textTransform: 'unset',
              marginRight: '8px',
            }}
            variant="brand"
            title={`${label} =  ${formattedValue}`}
            onDismiss={onClearBadge ? () => onClearBadge(key) : undefined}
            dismissible
          />
        )
      })}
    </>
  )
}
