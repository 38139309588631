import { Row, Text } from '@traba/react-components'

interface DetailRowProps {
  label: string
  value?: string
  customResultsComponent?: React.ReactNode
}

export function DetailRow({
  label,
  value,
  customResultsComponent,
}: DetailRowProps) {
  return (
    <Row alignCenter>
      <Text variant="h6" style={{ minWidth: '150px', maxWidth: '150px' }}>
        {label}
      </Text>

      {customResultsComponent ? (
        customResultsComponent
      ) : (
        <Text variant="body1">{value}</Text>
      )}
    </Row>
  )
}
