import {
  Checkbox,
  DataTableHeader,
  InlineBanner,
  Row,
  Td,
  Text,
  Tr,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { LineItemPreviewDto } from '@traba/types'
import { useMemo, useState } from 'react'
import { Button, Modal } from 'src/components/base'
import { Table } from 'src/components/base/Table/Table'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useCreateCreditNote } from 'src/hooks/useCreateCreditNote'
import { getMoneyString } from 'src/utils/stringUtils'
import LineItem from '../../components/LineItem'

export type CreateCreditNoteModalProps = {
  isOpen: boolean
  handleClose: () => void
  invoiceId: string
  refetch?: () => void
  lineItems: LineItemPreviewDto[] | undefined
}

export default function CreateCreditNoteModal(
  props: CreateCreditNoteModalProps,
) {
  // Props
  const { isOpen, handleClose, invoiceId, refetch, lineItems } = props

  // State vars
  const [notifyCustomer, setNotifyCustomer] = useState(true)

  // Hooks
  const { createCreditNote, isLoading } = useCreateCreditNote(invoiceId)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  // Local consts
  const headers: DataTableHeader[] = [
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'totalCharge',
      label: 'Total Charge',
      sortable: false,
    },
  ]

  const { lineItemRows, computedShiftIds, computedTotalCredit } =
    useMemo(() => {
      const computedShiftIds: string[] = []
      let computedTotalCredit = 0
      const lineItemRows = lineItems?.map(
        (lineItem: LineItemPreviewDto, index: number) => {
          const charge = lineItem.chargeToBusiness
          if (lineItem.shiftId) {
            computedShiftIds.push(lineItem.shiftId)
          }
          computedTotalCredit += charge.amount
          return (
            <LineItem
              lineItem={lineItem}
              key={lineItem.shiftId}
              index={index}
              lineItemsEditable={false}
            />
          )
        },
      )
      return {
        lineItemRows,
        computedShiftIds,
        computedTotalCredit,
      }
    }, [lineItems])

  const handleCreate = async () => {
    await createCreditNote({
      shiftIds: computedShiftIds ?? [],
      notifyCustomer,
    })
    handleClose()
    refetch?.()
  }

  const disabled =
    computedTotalCredit > 0 || !lineItems || lineItems?.length < 1
  return (
    <Modal
      title={'Create credit note'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {lineItemRows && lineItemRows?.length > 0 ? (
        <Table headers={headers}>
          {lineItemRows}
          <Tr>
            <Td
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: theme.space.xs,
              }}
            >
              Total Credits:
            </Td>
            <Td style={{ fontWeight: 'bold' }}>
              {getMoneyString(-(computedTotalCredit / 100))}
            </Td>
          </Tr>
        </Table>
      ) : (
        <Text style={{ fontSize: '20px' }}>No adjustments found.</Text>
      )}
      {computedTotalCredit > 0 && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="error"
          text={
            'The sum of adjustments is positive. You must regenerate this invoice to collect all charges associated with this invoice. If a credit note has already been issued, you must manually void and recreate the invoice.'
          }
        />
      )}
      <Row alignCenter justifyBetween p={theme.space.med}>
        <Checkbox
          checked={notifyCustomer}
          onChange={() => setNotifyCustomer((prev) => !prev)}
          label="Notify Customer"
          disabled={disabled}
        />
        <Button
          slim
          type="submit"
          style={{ minWidth: '100px' }}
          loading={isLoading}
          onClick={() => setShowConfirmation(true)}
          disabled={disabled}
        >
          Create
        </Button>
      </Row>
      <ConfirmationDialog
        open={showConfirmation}
        confirmLoading={isLoading}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleCreate}
        title="Confirm Credit Note"
        content={
          <Text>{`Are you sure you want to issue a credit of ${getMoneyString(-(computedTotalCredit / 100))}?`}</Text>
        }
      />
    </Modal>
  )
}
