import { List, LinearProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { ButtonVariant, Col, Dialog, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift, ShiftStatus } from '@traba/types'
import { isPast } from 'date-fns'
import { useState } from 'react'
import { Button, Row } from 'src/components/base'
import { useShiftInvitations } from '../../../hooks/useShiftInvitations'
import { WorkerListItem } from '../../../screens/ShiftDetailsScreen/components/SearchWorkers/WorkerListItem'
import { PopulatedWorker } from '../../../screens/WorkerSearchScreen/worker-search.types'
import { sleep } from '../../../utils/helperUtils'

interface BugleInvitationsSectionProps {
  shiftId: string
  selectedWorkerList: PopulatedWorker[]
  setSelectedWorkerList: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
  shift: Shift
  disabled?: boolean
}

export const BugleInvitationsSection: React.FC<
  BugleInvitationsSectionProps
> = ({ shift, disabled, selectedWorkerList }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [invitationProgress, setInvitationProgress] = useState(0)
  const [isSendingInvitations, setIsSendingInvitations] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { handleError, showSuccess } = useAlert()
  const { sendInvitationsAsync, sendInvitationsLoading } = useShiftInvitations(
    shift.shiftId,
  )

  async function handleInvitations() {
    const batchSize = 10
    const totalWorkers = selectedWorkerList.length
    const totalBatches = Math.ceil(totalWorkers / batchSize)

    setIsSendingInvitations(true)
    for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
      const start = batchIndex * batchSize
      const batchWorkers = selectedWorkerList.slice(start, start + batchSize)
      const workerIds = batchWorkers.map((worker) => worker.id || worker.uid)

      try {
        await sendInvitationsAsync({
          shiftId: shift.shiftId,
          workerIds,
        })
      } catch (error) {
        handleError(
          error,
          'BugleInvitationsSection -> handleInvitations()',
          'Unable to send invitations',
          'Shift invitations error',
        )
        setHasError(true)
        throw error
      }

      setInvitationProgress(Math.round(((batchIndex + 1) / totalBatches) * 100))
      await sleep(500)
    }
    setIsSendingInvitations(false)
    window.analytics.track('Bugle Invitations Sent', {
      shiftId: shift.shiftId,
      workerIds: selectedWorkerList.map((worker) => worker.id || worker.uid),
    })
    showSuccess('Finished processing all invitations')
  }

  const handleConfirm = async () => {
    setInvitationProgress(0)
    handleInvitations()
  }

  const handleClose = () => {
    if (isSendingInvitations && !hasError) {
      return
    }
    setIsDialogOpen(false)
    setInvitationProgress(0)
    setHasError(false)
  }

  if (shift.status !== ShiftStatus.ACTIVE || isPast(new Date(shift.endTime))) {
    return (
      <div>
        <Row>
          <Text variant="body1">
            This shift is no longer active. You cannot invite workers to this
            shift.
          </Text>
        </Row>
      </div>
    )
  }

  return (
    <Col>
      <Text variant="h6" mb={theme.space.xs}>
        Invite workers to this shift
      </Text>
      <Text variant="label" mb={theme.space.xs}>
        Please review the workers selected to be invited to this shift.
      </Text>

      <List sx={{ maxHeight: 500, overflow: 'auto', padding: 0 }}>
        {selectedWorkerList.map((worker: PopulatedWorker, i: number) => (
          <WorkerListItem
            key={`worker-${worker.id || worker.uid}-${i}`}
            customKey={`checkbox-worker-${worker.id || worker.uid}`}
            worker={worker}
            index={i}
            onAvatarClick={({ workerId }) =>
              window.open(`/workers/${workerId}`, '_blank')
            }
          />
        ))}
      </List>

      <Row fullWidth justifyEnd pb={theme.space.xs}>
        <Button
          style={{ height: '48px', marginTop: theme.space.xxs, width: 220 }}
          loading={sendInvitationsLoading || isSendingInvitations}
          onClick={() => setIsDialogOpen(true)}
          disabled={
            !selectedWorkerList.length || disabled || sendInvitationsLoading
          }
        >
          {selectedWorkerList.length ? 'Continue' : 'No workers selected'}
        </Button>
      </Row>

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        dialogTitle={`Invite ${selectedWorkerList.length} workers to this shift?`}
        hideFooter
      >
        <Row
          fullWidth
          my={theme.space.xs}
          flexCol
          style={{ height: 100, width: 550 }}
        >
          <Text variant="body1" mb={theme.space.xxs}>
            {isSendingInvitations
              ? 'Processing invitations'
              : 'Please confirm the workers you have selected and start the invitation flow.'}
          </Text>
          {isSendingInvitations && (
            <>
              <Text variant="body2" mb={theme.space.xxs}>
                Sending invitations... {invitationProgress}%
              </Text>
              <LinearProgress
                variant="determinate"
                value={invitationProgress}
                sx={{ width: '100%', height: 10, borderRadius: 2 }}
              />
            </>
          )}
        </Row>
        <Row fullWidth justifyEnd pb={theme.space.xs}>
          {invitationProgress !== 0 && !isSendingInvitations ? (
            <Button
              style={{
                height: '48px',
                marginTop: theme.space.xxs,
                width: 180,
              }}
              onClick={() => handleClose()}
              disabled={isSendingInvitations}
              variant={ButtonVariant.CANCEL}
            >
              Close
            </Button>
          ) : (
            <Button
              style={{ height: '48px', marginTop: theme.space.xxs, width: 180 }}
              loading={isSendingInvitations}
              onClick={() => handleConfirm()}
              disabled={sendInvitationsLoading}
            >
              {isSendingInvitations
                ? `Inviting ${selectedWorkerList.length} workers`
                : `Invite ${selectedWorkerList.length} workers`}
            </Button>
          )}
        </Row>
      </Dialog>
    </Col>
  )
}

export default BugleInvitationsSection
