import { LoadingSpinner } from '@traba/react-components'
import {
  Role,
  LocationResponse,
  ShiftRequest,
  ApplicationItemAICallRequest,
  ApplicationItemType,
  VettingConfig,
} from '@traba/types'
import { VettingContextForm } from 'src/components/VettingContextForm/VettingContextForm'
import { useActiveVettingPromptContext } from 'src/hooks/useActiveVettingPromptContext'
import { useWorkerVettingCampaign } from 'src/hooks/useWorkerVetting'
import { getMoneyString } from 'src/utils/stringUtils'
import {
  buildVettingConfigInitialValues,
  INITIAL_VETTING_CONTEXT_FORM_VALUES,
} from 'src/utils/vettingCampaignUtils'

interface CreateAndEditWorkerVettingCallApplicationItemProps {
  applicationItemId?: string
  applicationItemSourceId?: string
  role?: Pick<Role, 'roleName' | 'roleDescription'>
  shiftRequest?: Pick<ShiftRequest, 'payRate'>
  location?: LocationResponse
  onSave: (
    upsertApplicationItemRequest: ApplicationItemAICallRequest & {
      applicationItemId?: string
    },
  ) => void
}

function buildInitialMessage({
  role,
  location,
  payRatePlainText,
}: {
  role?: Pick<Role, 'roleName' | 'roleDescription'>
  location?: LocationResponse
  payRatePlainText: string
}) {
  if (!role || !role.roleName) {
    return ''
  }
  const loc = location?.address.city || location?.regionId
  const locString = loc ? `in ${loc}` : ''
  return `Earn ${payRatePlainText}/hr as a ${role.roleName} ${locString}`
}

export const CreateAndEditWorkerVettingCallApplicationItem = ({
  applicationItemId,
  applicationItemSourceId,
  role,
  shiftRequest,
  location,
  onSave,
}: CreateAndEditWorkerVettingCallApplicationItemProps) => {
  const handleSaveVettingConfig = (vettingConfig: VettingConfig) => {
    onSave({
      applicationItemType: ApplicationItemType.AI_VETTING_CALL,
      itemContent: { vettingConfig },
      applicationItemId,
    })
  }

  // TODO(gail): Update this to only have one call based on the campaign id to get the active prompt context --> BE PR HERE: https://github.com/trabapro/traba-server-node/pull/6584#pullrequestreview-2599485597
  const {
    campaign: workerVettingCampaign,
    isLoading: isLoadingWorkerVettingCampaign,
  } = useWorkerVettingCampaign(applicationItemSourceId || '')
  const activePromptContextId = workerVettingCampaign?.activePromptContextId

  const { promptContext, isLoading: isLoadingPromptContext } =
    useActiveVettingPromptContext({
      promptContextId: activePromptContextId || '',
    })

  if (isLoadingWorkerVettingCampaign || isLoadingPromptContext) {
    return <LoadingSpinner />
  }

  const payRatePlainText = getMoneyString(shiftRequest?.payRate)

  const vettingLogistics = promptContext?.context ?? {
    jobTitle: role?.roleName || '',
    roleDetails: role?.roleDescription || '',
    payRatePlainText,
    locationDetails: location?.locationInstructions || '',
    scheduleDetails: '',
  }

  const initialValues = workerVettingCampaign
    ? buildVettingConfigInitialValues({
        workerVettingCampaign,
        promptContext: promptContext?.context,
      })
    : {
        ...INITIAL_VETTING_CONTEXT_FORM_VALUES,
        initialMessage: buildInitialMessage({
          role,
          location,
          payRatePlainText,
        }),
        vettingLogistics,
      }

  return (
    <VettingContextForm
      initialValues={initialValues}
      loading={isLoadingPromptContext}
      onSave={handleSaveVettingConfig}
    />
  )
}
