import { Link, Button } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'

interface WorkerNameWithLinkProps {
  workerId: string
  workerFullName: string
}

export function WorkerNameWithLink({
  workerId,
  workerFullName,
}: WorkerNameWithLinkProps) {
  return (
    <Link to={`/workers/${workerId}`} rel="noopener noreferrer" target="_blank">
      <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
        {workerFullName}
      </Button>
    </Link>
  )
}
