import { trabaApi } from '@traba/api-utils'
import {
  ExtendedShift,
  ShiftAssignmentResponse,
  ShiftRequestParent,
  ShiftSignupNotificationsStatus,
  SlimSentinelNotification,
  WorkerShiftForOps as WorkerShift,
  WorkerShiftTimeToDestination,
  WorkerShiftTransit,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosRequestConfig } from 'axios'
import { useQuery } from 'react-query'
import { FIVE_MINUTES_IN_MS, ONE_MINUTE_IN_MS } from 'src/libs/constants'
import { base64ToHex } from 'src/utils/shiftCodeUtils'
import { trimParams } from '../utils/helperUtils'
import {
  basePaginationParams,
  DEFAULT_PAGE_SIZE,
  PaginationParams,
  useBasicPagination,
} from './usePagination'

export const SHIFTS_QUERY_KEY = 'shiftsCacheKey'
export const SHIFT_QUERY_KEY = 'shiftCacheKey'
export const SEARCH_SHIFTS_QUERY_KEY = 'shiftSearchCacheKey'

export type OpsExtendedShift = ExtendedShift & {
  workersMovingOrArrived: number
  workerShiftTransit?: WorkerShiftTransit[]
  //TODO(aiden): update the workerShift type below
  workerShifts?: (WorkerShift & {
    workerShiftTimeToDestination?: { id: string } | WorkerShiftTimeToDestination
  })[]
  sentinelNotifications?: SlimSentinelNotification[]
  shiftAssignment?: ShiftAssignmentResponse
  shiftCode?: string
  difficultyScore?: number
}

async function getShiftById(
  shiftId: string,
): Promise<OpsExtendedShift | undefined> {
  try {
    const res = await trabaApi.get(`shifts/${shiftId}`)
    return res.data
  } catch (error: any) {
    const errorMessage = `useShift -> getShiftById() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export function useShift(shiftId: string) {
  const {
    isLoading,
    isError,
    data: shift,
    error,
    isFetched,
    refetch,
  } = useQuery<OpsExtendedShift | undefined, Error>(
    [SHIFT_QUERY_KEY, shiftId],
    () => getShiftById(shiftId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    shift,
    error,
    isFetched,
    refetch,
  }
}

export const cancelShiftsById = async (
  shiftIds: string[],
  cancellationReason: string,
  cancellationSource: string,
  canceledAt?: Date | null,
): Promise<string[] | undefined> => {
  try {
    const payload = {
      ids: shiftIds,
      cancellationSource: cancellationSource,
      cancellationReason: cancellationReason,
      ...(canceledAt ? { canceledAt } : {}),
      shouldInstantPay: true,
    }
    const res = await trabaApi.put(`/shifts/cancel`, payload)
    return res.data
  } catch (error: any) {
    const errorMessage = `useShifts -> cancelShiftById() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export async function getShiftsByIds(
  shiftIds: string[],
): Promise<OpsExtendedShift[] | undefined> {
  try {
    const shifts = await Promise.all(
      shiftIds.map((shiftId) => getShiftById(shiftId)),
    )

    return shifts.filter(
      (shift): shift is OpsExtendedShift => shift !== undefined,
    )
  } catch (error: any) {
    const errorMessage = `useShifts -> getShiftsByIds() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export const getShiftNotificationStatusById = async (
  shiftId: string,
): Promise<ShiftSignupNotificationsStatus | undefined> => {
  try {
    const res = await trabaApi.get(
      `/worker-shift-signup-notifications/${shiftId}/status`,
    )

    return res.data
  } catch (error: any) {
    const errorMessage = `useShifts -> getShiftNotificationStatusById() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export function useShiftNotificationStatus(shiftId: string) {
  const {
    isLoading,
    isError,
    data: notificationStatus,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftSignupNotificationsStatus | undefined, Error>(
    `shiftNotifications_${shiftId}`,
    () => getShiftNotificationStatusById(shiftId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    notificationStatus,
    error,
    isFetched,
    refetch,
  }
}

export type ShiftSearchParams = {
  id?: string
  startTimeBefore?: Date
  startTimeAfter?: Date
  companyId?: string
  employerName?: string
  regionIds?: string[]
  isCompanyApproved?: boolean
  shiftCode?: string
  tags?: string[]
}

export type ShiftOrFields = {
  orFields?: {
    id?: string
    regionId?: string
    attireDescription?: string
    featuredStatus?: string
    breakType?: string
    supervisorId?: string
    parkingLocationId?: string
    status?: string
    signupStatus?: string
    cancellationReason?: string
    paymentType?: string
    extraBGCRequirement?: string
    genderPreference?: string
    shiftRole?: string
    roleDescription?: string
    minimumAcceptedTier?: string
    companyId?: string
    shiftRequestId?: string
    locationId?: string
    opsLocationDetailsOverride?: string
    tags?: string[]
    requiredTrainingIds?: string[]
    requiredCertifications?: string[]
    requiredAttributes?: string[]
    additionalEmails?: string[]
    supervisor_email?: string
    supervisor_firstName?: string
    supervisor_lastName?: string
    supervisor_phoneNumber?: string
    company_employerName?: string
    role_roleName?: string
    role_roleDescription?: string
    role_requiredCertifications?: string[]
    role_requiredTrainingIds?: string[]
    role_requiredAttributes?: string[]
    location_locationInstructions?: string
    location_opsLocationDetails?: string
    location_shortLocation?: string
    location_neighborhoodName?: string
    location_name?: string
  }
}

export type ShiftSearchParamsPayload = ShiftSearchParams & ShiftOrFields

export type SelectFields = {
  [key: string]: string[]
}

export type ShiftSearchSelect = {
  company?: string[]
  workerShifts?: string[]
  location?: string[]
  role?: string[]
  shift?: string[]
}

export type ShiftSearchQuery = {
  withCount?: boolean
  parameters: ShiftSearchParams
  select?: SelectFields
  internalUserId?: string
}

function makeShiftSearchParamsPayload(
  parameters: ShiftSearchParams,
  magicSearchText?: string,
): ShiftSearchParamsPayload {
  const trimmedParams = trimParams(parameters)
  if (!magicSearchText) {
    return trimmedParams
  }
  const magicSearchParams = {
    orFields: {
      attireDescription: magicSearchText,
      signupStatus: magicSearchText,
      paymentType: magicSearchText,
      extraBGCRequirement: magicSearchText,
      genderPreference: magicSearchText,
      shiftRole: magicSearchText,
      roleDescription: magicSearchText,
      shiftRequestId: magicSearchText,
      locationId: magicSearchText,
      opsLocationDetailsOverride: magicSearchText,
      requiredCertifications: [magicSearchText],
      requiredAttributes: [magicSearchText],
      supervisor_email: magicSearchText,
      supervisor_firstName: magicSearchText,
      supervisor_lastName: magicSearchText,
      supervisor_phoneNumber: magicSearchText,
      role_roleName: magicSearchText,
      role_roleDescription: magicSearchText,
      role_requiredCertifications: [magicSearchText],
      role_requiredAttributes: [magicSearchText],
      location_locationInstructions: magicSearchText,
      location_opsLocationDetails: magicSearchText,
      location_shortLocation: magicSearchText,
      location_neighborhoodName: magicSearchText,
      location_name: magicSearchText,
    },
  }
  return {
    ...trimmedParams,
    ...magicSearchParams,
  }
}

async function runSearchShifts(
  parameters: ShiftSearchParams,
  paginationParams: PaginationParams,
  select?: ShiftSearchSelect,
  config?: AxiosRequestConfig,
  internalUserId?: string,
): Promise<{
  shifts: OpsExtendedShift[]
  count: number
  shiftRequestParents: ShiftRequestParent[]
}> {
  const payload: ShiftSearchQuery = {
    parameters,
    select,
    withCount: true,
    internalUserId,
  }
  const { limit, offset, sortBy, sortOrder } = paginationParams
  const URL = `shifts/search?startAt=${offset}&limit=${limit}${
    sortBy ? `&orderBy=${String(sortBy)}` : ''
  }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
  const res = await trabaApi.post(URL, payload, config)
  return res.data
}

export const useSearchShifts = ({
  params,
  paginationParams,
  select,
  config,
  magicSearchText,
  internalUserId,
  shiftCode,
}: {
  params: ShiftSearchParams
  paginationParams: PaginationParams
  select?: ShiftSearchSelect
  config?: AxiosRequestConfig
  magicSearchText?: string
  internalUserId?: string
  shiftCode?: string
}) => {
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()
  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  if (shiftCode && !params.id) {
    params.id = base64ToHex(shiftCode)
  }

  const shiftSearchParamsPayload: ShiftSearchParamsPayload =
    makeShiftSearchParamsPayload(params, magicSearchText)

  const {
    data: shiftSearchResults,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [
      SEARCH_SHIFTS_QUERY_KEY,
      shiftSearchParamsPayload,
      pagination,
      internalUserId,
      shiftCode,
    ],
    () =>
      runSearchShifts(
        shiftSearchParamsPayload,
        pagination,
        select,
        config,
        internalUserId,
      ),
    {
      enabled: true,
      refetchOnWindowFocus: 'always',
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    shifts: shiftSearchResults?.shifts || [],
    shiftRequestParents: shiftSearchResults?.shiftRequestParents || [],
    totalFound: shiftSearchResults?.count || 0,
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}
