import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { EmploymentType, Incentive, IncentiveStatus, Shift } from '@traba/types'
import { useEffect, useState } from 'react'
import { useSessionStorage } from 'react-use'
import { Col, Row, Select } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import WorkerDrawer from 'src/components/WorkerDrawer/WorkerDrawer'
import useWorkerShiftsEligibilities from 'src/hooks/useWorkerShiftsEligibilities'
import SearchConfigDrawer from 'src/screens/WorkerSearchScreen/components/ConfigDrawer/SearchConfigDrawer'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { useApi } from '../../../hooks/useApi'
import IncentiveItem from '../../../screens/ShiftDetailsScreen/components/IncentiveItem/IncentiveItem'
import ExportBugleDataDrawer from '../../../screens/WorkerSearchScreen/components/ExportDrawer/ExportBugleDataDrawer'
import { BugleActionType, CustomParams } from '../BugleDrawer'
import useBugleForm from '../hooks/useBugleForm'
import { DEFAULT_PAGE_SIZE } from '../hooks/useBugleForm'
import { useBugleHelpers } from '../hooks/useBugleHelpers'
import { useConfigDrawer } from '../hooks/useConfigDrawer'
import WorkerActionsSection from '../sections/ActionsSection'
import { BackfillCallSection } from '../sections/BackfillCallSection'
import { BugleInvitationsSection } from '../sections/BugleInvitationsSection'
import { NewBugleScreenSection } from '../sections/NewBugleScreenSection'
import ParamsSection from '../sections/ParametersSection'
import PreviewSection from '../sections/PreviewSection'

type NewBugleScreenProps = {
  shift: Shift
  preSelectedFields?: string[]
  defaultActionType?: BugleActionType
  sentinelNotificationToUserId?: string
}

export enum NewBugleSection {
  CommunicationMethod = 'communication_method',
  SearchWorkers = 'search_workers',
  ViewAndEdit = 'view_and_edit',
  Action = 'send',
  ShiftInfo = 'shift_info',
}

export type SectionsOpenState = {
  [key in NewBugleSection]: boolean
}

export const DEFAULT_SECTIONS_OPEN_STATE: SectionsOpenState = {
  [NewBugleSection.CommunicationMethod]: true,
  [NewBugleSection.SearchWorkers]: true,
  [NewBugleSection.ViewAndEdit]: true,
  [NewBugleSection.Action]: true,
  [NewBugleSection.ShiftInfo]: true,
}

const PARAM_SESSION_STORAGE_KEY = 'bugle_params'
const COMM_METHOD_SESSION_STORAGE_KEY = 'bugle_comm_method'

export const NewBugleScreen = (props: NewBugleScreenProps) => {
  const {
    shift,
    preSelectedFields,
    defaultActionType,
    sentinelNotificationToUserId,
  } = props
  const [selectedWorkerList, setSelectedWorkerList] = useState<
    PopulatedWorker[]
  >([])

  const isW2Shift = shift.shiftEmploymentType === EmploymentType.W2
  const initialParamsSet = isW2Shift ? 'custom' : 'backfill'
  const [paramsSet, setParamsSet] = useSessionStorage<CustomParams>(
    PARAM_SESSION_STORAGE_KEY,
    initialParamsSet,
  )
  const [showExport, setShowExport] = useState(false)

  const { data: shiftIncentives, isLoading: areIncentivesLoading } = useApi<
    Incentive[]
  >(
    `incentives/query?shiftId=${shift.shiftId}&status=${IncentiveStatus.Active}`,
  )

  const [selectedBugleActionType, setBugleActionType] =
    useSessionStorage<BugleActionType>(
      COMM_METHOD_SESSION_STORAGE_KEY,
      BugleActionType.SMS,
    )

  useEffect(() => {
    if (defaultActionType) {
      setBugleActionType(defaultActionType)
    }
  }, [defaultActionType, setBugleActionType])

  const handleCommChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBugleActionType(
      (event.target as HTMLInputElement).value as BugleActionType,
    )
  }

  const [sectionsOpenState, setSectionsOpenState] = useState<SectionsOpenState>(
    DEFAULT_SECTIONS_OPEN_STATE,
  )

  useEffect(() => {
    setParamsSet(preSelectedFields?.length || isW2Shift ? 'custom' : 'backfill')
  }, [preSelectedFields, setParamsSet, isW2Shift])

  const [focusedWorker, setFocusedWorker] = useState<
    PopulatedWorker | undefined
  >()

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  const workerLimitOptions: IMenuItem[] = [
    { label: '10', value: '10' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: '250', value: '250' },
    { label: '500', value: '500' },
  ]

  const {
    form,
    setForm,
    isFormValid,
    isFetchingWorkers,
    error,
    workersSearchResult,
    roleAttributes,
    isLoadingAttributes,
    regions,
    isLoadingRegions,
    hotSettings,
    isLoadingHotSettings,
    handleClearParam,
    handleClear,
    handleRegionSelect,
    handleSearch,
    currentPage,
    hasProcessedEligibility,
    setHasProcessedEligibility,
    totalFound,
  } = useBugleForm({
    shiftId: shift.shiftId,
    regionId: shift.regionId,
    companyId: shift.companyId,
    paramsSet,
    selectedBugleActionType,
    pageSize,
    sectionsOpenState,
    setSectionsOpenState,
    initialValueOverrides: {
      activeEmploymentType: shift.shiftEmploymentType,
    },
  })

  const { toggleParamsSet, isFirstSectionComplete, isSecondSectionComplete } =
    useBugleHelpers({
      sectionsOpenState,
      setSectionsOpenState,
      paramsSet,
      setParamsSet,
      isFormValid,
      form,
      workersSearchResult,
    })

  const { fieldsToShow, handleDrawerClose, showDrawer, setShowDrawer } =
    useConfigDrawer({
      form,
      setForm,
      handleClearParam,
      handleClear,
      roleAttributes,
      preSelectedFields,
    })

  const { workersWithEligibility, isFetchingEligibility } =
    useWorkerShiftsEligibilities({
      workersSearchResult,
      shiftId: shift.shiftId,
      hasProcessedEligibility,
      setHasProcessedEligibility,
    })

  if (
    isLoadingRegions ||
    isLoadingAttributes ||
    isLoadingHotSettings ||
    areIncentivesLoading
  ) {
    return <>Loading...</>
  }

  return (
    <>
      <NewBugleScreenSection
        section={NewBugleSection.CommunicationMethod}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Select the action you want to take'}
        description={'This affects how the default search parameters are set'}
        icon={'bell'}
      >
        <RadioGroup
          name="comms-radio-buttons-group"
          value={selectedBugleActionType}
          onChange={handleCommChange}
          row
        >
          <FormControlLabel
            value={BugleActionType.SMS}
            control={<Radio />}
            label="SMS"
          />
          <FormControlLabel
            value={BugleActionType.Push}
            control={<Radio />}
            label="Push"
          />
          <FormControlLabel
            value={BugleActionType.BackfillCall}
            control={<Radio />}
            label="Backfill Call"
          />
          <FormControlLabel
            value={BugleActionType.Invitation}
            control={<Radio />}
            label="Invitation"
          />
          <FormControlLabel
            value={BugleActionType.Export}
            control={<Radio />}
            label="Export"
          />
        </RadioGroup>
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.SearchWorkers}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Select parameters'}
        description={'Select a preset or create a custom search'}
        icon={'timesheet'}
      >
        <ParamsSection
          toggleParamsSet={toggleParamsSet}
          setShowDrawer={setShowDrawer}
          handleClear={handleClear}
          paramsSet={paramsSet}
          setForm={setForm}
          form={form}
          fieldsToShow={fieldsToShow}
          handleRegionSelect={handleRegionSelect}
          regions={regions}
          isFormValid={isFormValid}
          workersSearchResult={workersSearchResult}
          shift={shift}
        />
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.ViewAndEdit}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Preview'}
        description={`Generate a list of workers. ${
          paramsSet === 'backfill'
            ? 'The backfill list uses a dedicated scoring system to sort the workers in the result based on their probability of backfilling the shift'
            : 'When scheduling a message to be sent in the future this list will be used as a preview of the workers that may get notified'
        }`}
        icon={'workers'}
      >
        <Select
          label="Page Limit"
          value={pageSize.toString()}
          handleSelect={(v) => setPageSize(parseInt(v))}
          menuItems={workerLimitOptions}
        />
        <PreviewSection
          isFetchingWorkers={isFetchingWorkers}
          isFetchingEligibility={isFetchingEligibility}
          workersWithEligibility={workersWithEligibility}
          workersSearchResult={workersSearchResult}
          isFirstSectionComplete={isFirstSectionComplete}
          isSecondSectionComplete={isSecondSectionComplete}
          currentPage={currentPage}
          handleSearch={handleSearch}
          paramsSet={paramsSet}
          error={error}
          onAvatarClick={setFocusedWorker}
          totalFound={totalFound}
          selectableList={
            selectedBugleActionType === BugleActionType.BackfillCall ||
            selectedBugleActionType === BugleActionType.Invitation
          }
          selectedWorkerList={selectedWorkerList}
          setSelectedWorkerList={setSelectedWorkerList}
          pageSize={pageSize}
        />
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.Action}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Actions'}
        icon={'send'}
      >
        {selectedBugleActionType === BugleActionType.BackfillCall && (
          <Col>
            <BackfillCallSection
              shiftId={shift.shiftId}
              selectedWorkerList={selectedWorkerList}
              setSelectedWorkerList={setSelectedWorkerList}
              sentinelNotificationToUserId={sentinelNotificationToUserId}
            />
          </Col>
        )}

        {selectedBugleActionType === BugleActionType.Invitation && (
          <Col>
            <BugleInvitationsSection
              shiftId={shift.shiftId}
              selectedWorkerList={selectedWorkerList}
              setSelectedWorkerList={setSelectedWorkerList}
              shift={shift}
            />
          </Col>
        )}

        {(selectedBugleActionType === BugleActionType.SMS ||
          selectedBugleActionType === BugleActionType.Push ||
          selectedBugleActionType === BugleActionType.Export) && (
          <WorkerActionsSection
            isFirstSectionComplete={isFirstSectionComplete}
            isSecondSectionComplete={isSecondSectionComplete}
            isLoading={isFetchingWorkers}
            onExport={() => setShowExport(true)}
            shift={shift}
            parameters={form}
            totalFound={totalFound}
            deepLink={shift.shiftDetailsDeeplink}
            actionType={selectedBugleActionType}
            presetSavedSearchId={
              paramsSet === 'backfill'
                ? hotSettings?.presetSavedSearchIds?.backfill
                : undefined
            }
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        )}
      </NewBugleScreenSection>
      <NewBugleScreenSection
        section={NewBugleSection.ShiftInfo}
        title="Shift Info"
        icon={'info'}
        setSectionsOpenState={setSectionsOpenState}
        sectionsOpenState={sectionsOpenState}
        description="Here you can see the shift details and any incentives that are active for this shift"
      >
        {Array.isArray(shiftIncentives) && shiftIncentives.length > 0 && (
          <Row mb={36} flexCol>
            <Row
              style={{
                flexWrap: 'wrap',
              }}
            >
              {shiftIncentives.map((incentive) => (
                <IncentiveItem incentive={incentive} key={incentive.id} />
              ))}
            </Row>
          </Row>
        )}
      </NewBugleScreenSection>

      <SearchConfigDrawer
        onClose={() => setShowDrawer(false)}
        isOpen={showDrawer}
        onDrawerClose={handleDrawerClose}
        listName="bugle-custom-search-fields"
        showShiftInfo
      />
      <ExportBugleDataDrawer
        bugleParams={{
          parameters: form,
          savedSearchId:
            paramsSet === 'backfill'
              ? hotSettings?.presetSavedSearchIds?.backfill
              : undefined,
          targetShiftId: shift.shiftId,
          targetCompanyId: shift.companyId,
        }}
        isOpen={showExport}
        onClose={() => setShowExport(false)}
      />

      {focusedWorker && (
        <WorkerDrawer
          worker={focusedWorker}
          isOpen={!!focusedWorker}
          onClose={() => setFocusedWorker(undefined)}
        />
      )}
    </>
  )
}
