import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerSearchParams } from '@traba/types'
import { Shift } from '@traba/types'
import React from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Button, Icon, Row } from '../../base'
import { BugleActionType } from '../BugleDrawer'
import * as S from '../styles'
import PushInput from './PushInput'
import SMSInput from './SMSInput'

export const DEFAULT_MAX_WORKERS = 1500

interface WorkerActionsSectionProps {
  isFirstSectionComplete: boolean
  isSecondSectionComplete: boolean
  isLoading: boolean
  onExport: () => void
  parameters: WorkerSearchParams
  shift: Shift
  totalFound?: number
  deepLink?: string
  presetSavedSearchId?: string
  sentinelNotificationToUserId?: string
  actionType: BugleActionType
}

const WorkerActionsSection: React.FC<WorkerActionsSectionProps> = ({
  isFirstSectionComplete,
  isSecondSectionComplete,
  isLoading,
  onExport,
  parameters,
  shift,
  totalFound,
  deepLink,
  presetSavedSearchId,
  sentinelNotificationToUserId,
  actionType,
}) => {
  const disabled = !isFirstSectionComplete || !isSecondSectionComplete

  if (disabled) {
    return (
      <S.ActionsContainer>
        <Text>Please complete the previous sections</Text>
      </S.ActionsContainer>
    )
  }

  return (
    <S.ActionsContainer>
      <div style={{ width: '100%', height: '100%' }}>
        {actionType === BugleActionType.SMS && (
          <SMSInput
            parameters={parameters}
            targetShiftId={shift.shiftId}
            targetCompanyId={shift.companyId}
            disabled={disabled}
            totalFound={totalFound}
            deepLink={deepLink}
            savedSearchId={presetSavedSearchId}
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        )}
        {actionType === BugleActionType.Push && (
          <PushInput
            parameters={parameters}
            targetShiftId={shift.shiftId}
            targetCompanyId={shift.companyId}
            disabled={disabled}
            totalFound={totalFound}
            savedSearchId={presetSavedSearchId}
          />
        )}
        {actionType === BugleActionType.Export && (
          <>
            <Text variant="label">
              Use this to export the list of workers into a CSV
            </Text>
            <Row fullWidth justifyEnd>
              <Button
                leftIcon={<Icon name="download" />}
                variant={ButtonVariant.OUTLINED}
                loading={isLoading}
                style={{ width: 180 }}
                onClick={onExport}
                mr={theme.space.xs}
                disabled={disabled}
              >
                Export
              </Button>
            </Row>
          </>
        )}
      </div>
    </S.ActionsContainer>
  )
}

export default WorkerActionsSection
