import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  GeneralCampaignQuestion,
  VettingCampaign,
  CreateVettingCampaignRequest,
  VettingCampaignSearchRequest,
  StartSmsAndPhoneVettingRequest,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

const VETTING_CAMPAIGN_QUESTIONS_KEY = 'vetting-campaign-questions'

export const FETCH_VETTING_CAMPAIGNS_QUERY_KEY = 'vetting-campaigns'

async function getVettingCampaignQuestions(): Promise<
  GeneralCampaignQuestion[]
> {
  try {
    const res = await trabaApi.get(`/workers/vetting/questions`)
    return res.data.questions
  } catch (error) {
    console.error('useVettingCampaignQuestions -> getQuestions() ERROR', error)
    captureSentryError(error)
    throw error
  }
}

async function getVettingCampaigns(
  vettingCampaignSearchRequest: VettingCampaignSearchRequest,
): Promise<VettingCampaign[]> {
  try {
    const { data } = await trabaApi.post(
      `/workers/vetting/search-campaigns`,
      vettingCampaignSearchRequest,
    )
    return data.campaigns
  } catch (error) {
    console.error('useVettingCampaigns -> getVettingCampaigns() ERROR', error)
    captureSentryError(error)
    throw error
  }
}

export function useVettingCampaignMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()
  const createVettingCampaignMutation = useMutation<
    any,
    AxiosError,
    CreateVettingCampaignRequest
  >({
    mutationFn: createVettingCampaign,
    onSuccess: (_response: any) => {
      showSuccess('Vetting Campaign created')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error creating vetting campaign')
    },
  })

  const syncVettingCampaignMutation = useMutation<any, AxiosError, string>({
    mutationFn: async (campaignId: string) => {
      const response = await trabaApi.patch(
        `workers/vetting/sync-sheet/${campaignId}`,
      )
      return response.data
    },
    onSuccess: (_response: any) => {
      showSuccess('Vetting Campaign synced successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error syncing vetting campaign')
    },
  })

  const refreshVettingCampaignMutation = useMutation<any, AxiosError, string>({
    mutationFn: async (campaignId: string) => {
      const response = await trabaApi.patch(
        `workers/vetting/refresh-sheet/${campaignId}`,
      )
      return response.data
    },
    onSuccess: (_response: any) => {
      showSuccess('Vetting Campaign refreshed successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error refreshing vetting campaign')
    },
  })

  const startSmsAndPhoneVettingMutation = useMutation<
    any,
    AxiosError,
    StartSmsAndPhoneVettingRequest
  >({
    mutationFn: startSmsAndPhoneVetting,
    onSuccess: (_response: any) => {
      showSuccess('SMS and phone vetting started successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error starting SMS and phone vetting')
    },
  })

  return {
    createVettingCampaign: createVettingCampaignMutation.mutateAsync,
    isCampaignCreationPending: createVettingCampaignMutation.isPending,
    syncVettingCampaign: syncVettingCampaignMutation.mutateAsync,
    isSyncPending: syncVettingCampaignMutation.isPending,
    startAIVetting: startSmsAndPhoneVettingMutation.mutateAsync,
    isStartAIVettingPending: startSmsAndPhoneVettingMutation.isPending,
    refreshVettingCampaign: refreshVettingCampaignMutation.mutateAsync,
    isRefreshPending: refreshVettingCampaignMutation.isPending,
  }
}

export async function createVettingCampaign(
  createVettingCampaignRequest: CreateVettingCampaignRequest,
) {
  try {
    const response = await trabaApi.post(
      `workers/vetting/create-campaign`,
      createVettingCampaignRequest,
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export async function startSmsAndPhoneVetting(
  startSmsAndPhoneVettingRequest: StartSmsAndPhoneVettingRequest,
) {
  try {
    const response = await trabaApi.post(
      `workers/vetting/start-sms-and-phone-vetting`,
      startSmsAndPhoneVettingRequest,
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export function useVettingCampaignQuestions() {
  const {
    isLoading,
    isError,
    data: generalQuestions,
    error,
    isFetched,
    refetch,
  } = useQuery<GeneralCampaignQuestion[], Error>({
    queryKey: [VETTING_CAMPAIGN_QUESTIONS_KEY],
    queryFn: () => getVettingCampaignQuestions(),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    generalQuestions,
    error,
    isFetched,
    refetch,
  }
}

export function useVettingCampaigns(
  vettingCampaignSearchRequest: VettingCampaignSearchRequest,
) {
  const {
    isLoading,
    isError,
    data: vettingCampaigns,
    error,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY, vettingCampaignSearchRequest],
    queryFn: () => getVettingCampaigns(vettingCampaignSearchRequest),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    vettingCampaigns,
    error,
    isFetched,
    refetch,
  }
}
