import { Time } from '@internationalized/date'
import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import {
  DataTable,
  DataTableHeader,
  IMenuItem,
  Input,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WatchlistRequirementFilter,
  WorkerDetailsForWatchlist,
  ShiftTag,
} from '@traba/types'
import { addWeeks, formatDistanceToNow, startOfDay } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Col,
  CopyTextIcon,
  Link,
  Row,
  Select,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { FloatingActionBar } from 'src/components/base/FloatingActionBar/FloatingActionBar'
import Pagination from 'src/components/base/Pagination/Pagination'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import Toggle from 'src/components/base/Toggle'
import { PhoneNumberWithStatus } from 'src/components/PhoneNumberWithStatus'
import { useCompanies } from 'src/hooks/useCompanies'
import { useHideFromWatchlist } from 'src/hooks/useHideFromWatchlist'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { useShiftTags } from 'src/hooks/useShiftTags'
import {
  useBackgroundCheckAction,
  useCreateOrUpdateWorkerRequirementAction,
  useW9FormAction,
} from 'src/hooks/useWorkerRequirementActions'
import { useWorkersOnWatchlist } from 'src/hooks/useWorkersOnWatchlist'
import { BackgroundCheckStatusIndicator } from './BackgroundCheckStatusIndicator'
import { StripeStatusIndicator } from './StripeStatusIndicator'
import { W9FormStatusIndicator } from './W9FormStatusIndicator'

const WORKER_WATCHLIST_PAGE_SIZE = 50
const workerWatchlistLimitOptions: IMenuItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
]

export const WorkerWatchlist = () => {
  // Requirement filters
  const requirementOptions: IMenuItem<WatchlistRequirementFilter>[] =
    Object.values(WatchlistRequirementFilter).map((requirement) => ({
      label: requirement
        .split('_')
        .map((word) =>
          word.length > 0
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word,
        )
        .join(' '),
      value: requirement,
    }))
  const [selectedRequirements, setSelectedRequirements] =
    useState<IMenuItem[]>(requirementOptions)
  const selectedRequirementValues = useMemo(
    () =>
      selectedRequirements.map(
        (item) => item.value as WatchlistRequirementFilter,
      ),
    [selectedRequirements],
  )
  // Worker filters
  const [regionIds, setRegionIds] = useState<string[]>([])
  const { regions = [], isLoading: regionsLoading } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })
  const [searchFieldText, setSearchFieldText] = useState<string>('')
  const [excludeApprovedWorkers, setExcludeApprovedWorkers] =
    useState<boolean>(false)
  const [showMarkedAsDone, setShowMarkedAsDone] = useState<boolean>(false)

  // Shift start time filters
  const today = startOfDay(new Date())
  const [until, setUntil] = useState(addWeeks(today, 1))

  // Company filters
  const { companies = [] } = useCompanies({
    isApproved: true,
    identifiersOnly: true,
  })
  const companyOptions = useMemo(() => {
    const options = companies.map((company) => ({
      label: company.employerName,
      value: company.id,
    }))
    return options.filter((c) => !!c.value)
  }, [companies])
  const [companyNames, setCompanyNames] = useState<IMenuItem[]>([])
  const excludedCompanyIds = useMemo(
    () => companyNames.map((company) => company.value as string),
    [companyNames],
  )

  // Pagination options
  const [pageSize, setPageSize] = useState(WORKER_WATCHLIST_PAGE_SIZE)
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  // Shift tag filters
  const { shiftTagMenuItems } = useShiftTags()
  const [selectedShiftTags, setSelectedShiftTags] = useState<IMenuItem[]>([])
  const selectedShiftTagValues = useMemo(
    () => selectedShiftTags.map((item) => item.value as ShiftTag),
    [selectedShiftTags],
  )

  const {
    isLoading,
    isFetching,
    isError,
    workersOnWatchlist,
    refetch: refetchWorkersOnWatchlist,
    error,
  } = useWorkersOnWatchlist({
    excludedCompanyIds,
    regionIds: regionIds,
    nextShiftStartTimeBefore: until,
    requirementFilters: selectedRequirementValues,
    searchText: searchFieldText,
    excludeApprovedWorkers,
    showMarkedAsDone,
    page: currentPage,
    pageSize,
    shiftTags: selectedShiftTagValues,
  })

  const createOrUpdateWorkerRequirementAction =
    useCreateOrUpdateWorkerRequirementAction()
  const { handleW9Action, isLoadingW9FormUpdate } = useW9FormAction(
    async () => {
      await refetchWorkersOnWatchlist()
    },
  )
  const {
    handleBackgroundCheckAction,
    isLoading: isLoadingBackgroundCheckAction,
  } = useBackgroundCheckAction(async () => {
    await refetchWorkersOnWatchlist()
  })

  const [selectedWorkerIds, setSelectedWorkerIds] = useState<Set<string>>(
    new Set(),
  )
  const handleSelectionChange = (selectedRows: TableRow[]) => {
    setSelectedWorkerIds(new Set(selectedRows.map((row) => row.key)))
  }

  const MAX_WORKERS = 5
  const { showError } = useAlert()
  const { hideFromWatchlist } = useHideFromWatchlist()

  const onClearWorkers = useCallback(() => {
    setSelectedWorkerIds(new Set())
  }, [])

  const handleHideWorkers = useCallback(
    (permanent: boolean) => async () => {
      if (selectedWorkerIds.size > MAX_WORKERS) {
        showError(`Cannot process more than ${MAX_WORKERS} workers at once`)
        return
      }

      await hideFromWatchlist({
        workerIds: Array.from(selectedWorkerIds),
        permanent,
      })
      onClearWorkers()
    },
    [hideFromWatchlist, onClearWorkers, selectedWorkerIds, showError],
  )

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker Name',
    },
    {
      key: 'companyName',
      label: 'Company',
    },
    {
      key: 'requiredByTime',
      label: 'Required By',
      sortable: true,
    },
    {
      key: 'urgency',
      label: 'Urgency',
      sortable: true,
    },
    {
      key: 'shiftsAtRisk',
      label: 'Shifts At Risk',
      sortable: true,
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      key: 'workerId',
      label: 'Worker Id',
    },
    {
      key: 'stripeStatus',
      label: 'Stripe',
      sortable: true,
    },
    {
      key: 'backgroundCheckStatus',
      label: 'BGC',
      sortable: true,
    },
    {
      key: 'w9formStatus',
      label: 'W9',
      sortable: true,
    },
  ]

  const renderNextImpactedShiftTime = (
    workerDetails: WorkerDetailsForWatchlist,
  ) => {
    if (!workerDetails.nextShiftId || !workerDetails.nextShiftStartTime) {
      return <Text>N/A</Text>
    }
    const formattedDate = new Date(
      workerDetails.nextShiftStartTime,
    ).toLocaleDateString('en-US')
    const timeToShift = formatDistanceToNow(workerDetails.nextShiftStartTime, {
      addSuffix: true,
    })

    return (
      <Link to={`/field-monitor/${workerDetails.nextShiftId}`} target="_blank">
        <Button
          tooltipText={timeToShift}
          style={{ padding: 0 }}
          variant={ButtonVariant.TEXT}
        >
          {formattedDate}
        </Button>
      </Link>
    )
  }
  const maxScore = Math.max(...(workersOnWatchlist?.map((w) => w.score) || [0]))

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workersOnWatchlist?.map((workerDetails) => ({
        key: workerDetails.workerId,
        cells: [
          {
            key: `${workerDetails.workerId}-name`,
            content: `${workerDetails.firstName} ${workerDetails.lastName}`,
            renderFn: () => {
              const fullName = `${workerDetails.firstName} ${workerDetails.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link to={`/workers/${workerDetails.workerId}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workerDetails.workerId}-company`,
            content: workerDetails.companyName,
            renderFn: () => {
              const truncatedCompanyName =
                workerDetails.companyName.length > 24
                  ? workerDetails.companyName.slice(0, 21) + '...'
                  : workerDetails.companyName
              return (
                <Link
                  to={`/companies/${workerDetails.companyId}`}
                  target="_blank"
                >
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedCompanyName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workerDetails.workerId}-nextImpactedShiftTime`,
            content: workerDetails.nextShiftStartTime,
            renderFn: () => renderNextImpactedShiftTime(workerDetails),
            sortKey: workerDetails.nextShiftStartTime
              ? Date.parse(
                  new Date(workerDetails.nextShiftStartTime).toLocaleDateString(
                    'en-US',
                  ),
                )
              : Number.MAX_VALUE,
          },
          {
            key: `${workerDetails.workerId}-score`,
            content: workerDetails.score,
            renderFn: () => {
              const normalizedScore = maxScore
                ? (workerDetails.score / maxScore) * 100
                : 0

              let urgencyLevel = 'Low'
              let color = theme.colors.Yellow70
              if (normalizedScore >= 66) {
                urgencyLevel = 'High'
                color = theme.colors.Red70
              } else if (normalizedScore >= 33) {
                urgencyLevel = 'Medium'
                color = theme.colors.Orange70
              }

              return (
                <Text
                  style={{
                    color: color,
                    fontWeight: 'bold',
                  }}
                >
                  {urgencyLevel}
                </Text>
              )
            },
            sortKey: workerDetails.score,
          },
          {
            key: `${workerDetails.workerId}-shiftsAtRisk`,
            content: workerDetails.shiftsAtRisk,
            renderFn: () => <Text>{workerDetails.shiftsAtRisk}</Text>,
            sortKey: workerDetails.shiftsAtRisk,
          },
          {
            key: `${workerDetails.workerId}-phone`,
            content: workerDetails.phoneNumber,
            renderFn: () => {
              return (
                <PhoneNumberWithStatus
                  phoneNumber={workerDetails.phoneNumber}
                  phoneNumberStatus={workerDetails.phoneNumberStatus}
                />
              )
            },
          },
          {
            key: `${workerDetails.workerId}-id`,
            content: workerDetails.workerId,
            renderFn: () => (
              <Text>
                {`${workerDetails.workerId.slice(0, 8)}...`}
                <CopyTextIcon textToCopy={workerDetails.workerId} />
              </Text>
            ),
          },
          {
            key: `${workerDetails.workerId}-stripeStatus`,
            content: workerDetails.stripeStatus,
            renderFn: () => (
              <StripeStatusIndicator
                stripeStatus={workerDetails.stripeStatus}
              />
            ),
            sortKey: workerDetails.stripeStatus,
          },
          {
            key: `${workerDetails.workerId}-bgcStatus`,
            content: workerDetails.detailedBackgroundCheckStatus,
            renderFn: () => (
              <BackgroundCheckStatusIndicator
                backgroundCheckStatus={
                  workerDetails.detailedBackgroundCheckStatus
                }
                hasActiveAction={workerDetails.hasActiveBackgroundCheckAction}
                onUpdateAction={(hasActiveAction) =>
                  handleBackgroundCheckAction(
                    workerDetails.workerId,
                    hasActiveAction,
                  )
                }
                isLoading={isLoadingBackgroundCheckAction}
              />
            ),
            sortKey: workerDetails.detailedBackgroundCheckStatus,
          },
          {
            key: `${workerDetails.workerId}-w9formStatus`,
            content: workerDetails.w9formStatus,
            renderFn: () => (
              <W9FormStatusIndicator
                w9FormStatus={workerDetails.w9formStatus}
                hasActiveAction={workerDetails.hasActiveW9FormStatusAction}
                onUpdateAction={(hasActiveAction, w9FormStatus) =>
                  handleW9Action(
                    workerDetails.workerId,
                    hasActiveAction,
                    w9FormStatus,
                  )
                }
                isLoading={
                  createOrUpdateWorkerRequirementAction.isPending ||
                  isLoadingW9FormUpdate
                }
              />
            ),
            sortKey: workerDetails.w9formStatus,
          },
        ],
      })) || []
    )
  }, [
    createOrUpdateWorkerRequirementAction.isPending,
    handleBackgroundCheckAction,
    handleW9Action,
    isLoadingBackgroundCheckAction,
    isLoadingW9FormUpdate,
    maxScore,
    workersOnWatchlist,
  ])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Search workers"
            value={searchFieldText}
            onChange={(e) => setSearchFieldText(e.target.value)}
            onClear={() => setSearchFieldText('')}
          />
        </Col>
        <Col>
          <StateSearchSelect
            multiple
            options={regionsOptions}
            selectedItems={regionIds}
            handleSelectMultiple={setRegionIds}
            label={`Region${regionIds.length > 1 ? 's' : ''}`}
            width={'100%'}
          />
        </Col>
        <Col style={{ display: 'flex' }}>
          <DatePicker
            showTimeFieldInPopover={true}
            minDate={new Date()}
            setDate={(until) => {
              setUntil(until ?? new Date())
              setCurrentPage(0)
            }}
            isClearable={true}
            inlineLabel={true}
            label="Shifts Before"
            date={until}
            defaultTime={new Time(0, 0)}
          />
        </Col>

        <Col>
          <Row
            gap={theme.space.xxs}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Pagination
              dataSize={workersOnWatchlist?.length || 0}
              onPageLeft={goToPreviousPage}
              onPageRight={goToNextPage}
              page={currentPage}
              pageSize={pageSize}
            />
            <Select
              label="Limit"
              value={pageSize.toString()}
              handleSelect={(v) => setPageSize(parseInt(v))}
              menuItems={workerWatchlistLimitOptions}
              containerStyle={{ marginLeft: theme.space.xs }}
            />
          </Row>
        </Col>
      </Row>
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <SearchSelect
          multiple
          options={requirementOptions}
          selectedItems={selectedRequirements}
          handleSelectMultiple={setSelectedRequirements}
          label={`Requirements`}
          width={300}
        />
        <SearchSelect
          multiple
          options={shiftTagMenuItems}
          selectedItems={selectedShiftTags}
          handleSelectMultiple={setSelectedShiftTags}
          label={'Shift Tags'}
          width={260}
          selectedOnTop
          showClearButton
        />
        <SearchSelect
          multiple
          options={companyOptions}
          selectedItems={companyNames}
          handleSelectMultiple={setCompanyNames}
          label={'Excluded Businesses'}
          width={260}
          selectedOnTop
          showClearButton
        />
        <Toggle
          label={'Exclude Approved Workers'}
          buttonState={excludeApprovedWorkers}
          runOnChange={() => {
            setExcludeApprovedWorkers(!excludeApprovedWorkers)
            setCurrentPage(0)
          }}
          containerStyle={{ justifyContent: 'center' }}
        />
        <Toggle
          label={'Show Marked As Done'}
          buttonState={showMarkedAsDone}
          runOnChange={() => {
            setShowMarkedAsDone(!showMarkedAsDone)
            setCurrentPage(0)
          }}
          containerStyle={{ justifyContent: 'center' }}
        />
      </Row>
      {isLoading || regionsLoading || isFetching ? (
        <CircularProgress />
      ) : !workersOnWatchlist || workersOnWatchlist.length === 0 ? (
        <Text>No workers found matching the criteria</Text>
      ) : (
        <DataTable
          allowSelect={true}
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={3}
          initialSortByAscOrder={false}
          selectedRows={tableRows.filter((row) =>
            selectedWorkerIds.has(row.key),
          )}
          onSelectRows={handleSelectionChange}
        />
      )}

      {isError && (
        <Text variant="error">
          Something went wrong: {(error as Error).message}
        </Text>
      )}
      {selectedWorkerIds.size > 0 && (
        <FloatingActionBar
          selectedCount={selectedWorkerIds.size}
          onClear={onClearWorkers}
          actions={[
            {
              label: 'Snooze (1 day)',
              onClick: handleHideWorkers(false),
            },
            {
              label: 'Mark as Done',
              onClick: handleHideWorkers(true),
            },
          ]}
        />
      )}
    </Col>
  )
}
