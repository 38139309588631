import { InlineBanner, Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InvoiceShiftStatus,
  InvoiceStatus,
  ShiftInvoicedStatus,
  ShiftInvoiceIssueReason,
} from '@traba/types'
import { useMemo } from 'react'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { ShiftCategorySection } from './ShiftCategorySection'

type InvoiceShiftStatusSectionProps = {
  invoiceShiftStatuses: InvoiceShiftStatus[]
  invoiceStatus?: InvoiceStatus
}

export default function InvoiceShiftStatusSection(
  props: InvoiceShiftStatusSectionProps,
) {
  const { invoiceShiftStatuses, invoiceStatus } = props

  const shiftsRequiringOpsIntervention = useMemo(
    () =>
      invoiceShiftStatuses.filter(
        (shift) =>
          !(
            shift.invoicedStatus === ShiftInvoicedStatus.INVOICED &&
            !shift.shiftInvoiceIssue
          ),
      ),
    [invoiceShiftStatuses],
  )

  const shiftIdsWithOpenWorkerShifts = useMemo(
    () =>
      shiftsRequiringOpsIntervention
        .filter(
          (shift) =>
            shift.shiftInvoiceIssue ===
            ShiftInvoiceIssueReason.OPEN_WORKER_SHIFTS,
        )
        .map((shift) => shift.shiftId),
    [shiftsRequiringOpsIntervention],
  )

  const shiftIdsThatNeedManualInvoiceAdjustment = useMemo(
    () =>
      shiftsRequiringOpsIntervention
        .filter(
          (shift) =>
            shift.shiftInvoiceIssue ===
            ShiftInvoiceIssueReason.NEEDS_MANUAL_ADJUSTMENT,
        )
        .map((shift) => shift.shiftId),
    [shiftsRequiringOpsIntervention],
  )

  const shiftIdsThatAreInvoiced = useMemo(
    () =>
      shiftsRequiringOpsIntervention
        .filter(
          (shift) =>
            shift.shiftInvoiceIssue === ShiftInvoiceIssueReason.INVOICED,
        )
        .map((shift) => shift.shiftId),
    [shiftsRequiringOpsIntervention],
  )

  const shiftsThatRequireResync = useMemo(
    () =>
      shiftsRequiringOpsIntervention
        .filter((shift) => !shift.shiftInvoiceIssue)
        .map((shift) => shift.shiftId),
    [shiftsRequiringOpsIntervention],
  )

  // Only going to show this section if invoice preview or invoice in draft state so return null
  if (
    shiftsRequiringOpsIntervention.length === 0 ||
    (invoiceStatus && invoiceStatus !== InvoiceStatus.DRAFT)
  ) {
    return null
  }

  return (
    <>
      <Col py={theme.space.xs} px={theme.space.xxs}>
        <InlineBanner
          text={
            invoiceStatus
              ? 'This invoice requires ops attention. After resolving the issues below, resync the invoice.'
              : "Some selected shifts won't be invoiced. Please address the following issues before creating the invoice."
          }
          severity="warning"
          style={{
            alignSelf: 'flex-start',
            padding: theme.space.xsmed,
            marginBottom: theme.space.xxs,
          }}
        />
        <Col
          px={theme.space.xsmed}
          pt={theme.space.xsmed}
          pb={theme.space.xxxs}
          style={{
            backgroundColor: theme.colors.Grey10,
            borderRadius: theme.border.radius,
            alignSelf: 'flex-start',
          }}
        >
          <ShiftCategorySection
            title="Shifts with open worker shifts:"
            shiftIds={shiftIdsWithOpenWorkerShifts}
          />
          <ShiftCategorySection
            title="Shifts that require manual invoice adjustment:"
            shiftIds={shiftIdsThatNeedManualInvoiceAdjustment}
          />
          <ShiftCategorySection
            title="Shifts that require invoice resync:"
            shiftIds={shiftsThatRequireResync}
          />
          {/* This should be only for invoice preview flow */}
          <ShiftCategorySection
            title="Shifts that are already invoiced:"
            shiftIds={shiftIdsThatAreInvoiced}
          />
        </Col>
      </Col>
      <HorizontalRule />
    </>
  )
}
