import * as Sentry from '@sentry/react'

export enum ErrorNames {
  SHIFT_EDIT_WINDOW_CLOSED = 'ShiftEditWindowClosedError',
}

type ErrorWithMessage = {
  message: string
}

export function captureSentryError(
  error: unknown,
  options?: Record<string, unknown>,
) {
  if (error instanceof Error) {
    Sentry.captureException(error, options)
  }
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>)['message'] === 'string'
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) {
    return maybeError
  }

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    return new Error(String(maybeError))
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}

export function assertUnreachable(x: never): never {
  throw new Error(`Unreachable case: ${x}`)
}

interface BatchError {
  response: {
    message: Array<{
      status: 'fulfilled' | 'rejected'
      reason?: {
        message: string
      }
    }>
  }
}

export function isBatchError(error: unknown): error is BatchError {
  return (
    !!error &&
    typeof error === 'object' &&
    'response' in error &&
    !!error.response &&
    typeof error.response === 'object' &&
    'message' in error.response &&
    Array.isArray(error.response.message)
  )
}

export function parseBatchResults(
  messages: Array<{ status: string; reason?: { message: string } }>,
): {
  failedCount: number
  failedMessages: string[]
  successCount: number
} {
  const failedMessages = messages
    .filter((m) => m.status === 'rejected')
    .map((m) => m.reason?.message)
    .filter((msg): msg is string => !!msg)

  return {
    failedCount: failedMessages.length,
    failedMessages,
    successCount: messages.filter((m) => m.status === 'fulfilled').length,
  }
}
