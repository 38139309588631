import { Row, Text } from '@traba/react-components'
import { CopyTextIcon } from 'src/components/base'

interface TruncatedIdWithCopyButtonProps {
  id: string
}

export function TruncatedIdWithCopyButton({
  id,
}: TruncatedIdWithCopyButtonProps) {
  return (
    <Row>
      <Text
        variant="body1"
        style={{
          width: '50px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {id}
      </Text>
      <CopyTextIcon textToCopy={id} />
    </Row>
  )
}
