import { Badge, Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ApplicationItem } from '@traba/types'
import { useActiveVettingPromptContext } from 'src/hooks/useActiveVettingPromptContext'
import { useWorkerVettingCampaign } from 'src/hooks/useWorkerVetting'
import { DetailRow } from './DetailRow'

interface WorkerVettingCallApplicationItemContentProps {
  applicationItem: ApplicationItem
}

export const WorkerVettingCallApplicationItemContent = ({
  applicationItem,
}: WorkerVettingCallApplicationItemContentProps) => {
  // TODO(gail): Update this to only have one call based on the campaign id to get the active prompt context --> BE PR HERE: https://github.com/trabapro/traba-server-node/pull/6584#pullrequestreview-2599485597
  const {
    campaign: workerVettingCampaign,
    isLoading: isLoadingWorkerVettingCampaign,
  } = useWorkerVettingCampaign(applicationItem.sourceId || '')
  const activePromptContextId = workerVettingCampaign?.activePromptContextId

  const { promptContext, isLoading: isLoadingPromptContext } =
    useActiveVettingPromptContext({
      promptContextId: activePromptContextId || '',
    })

  if (isLoadingWorkerVettingCampaign || isLoadingPromptContext) {
    return <LoadingSpinner />
  }

  return (
    <Col gap={theme.space.xs}>
      <DetailRow label="Job Title(s)" value={promptContext?.context.jobTitle} />
      <DetailRow
        label="Pay Rate"
        value={promptContext?.context.payRatePlainText}
      />
      <DetailRow
        label="Location Details"
        value={promptContext?.context.locationDetails}
      />
      <DetailRow
        label="Language"
        customResultsComponent={
          <div>
            <Badge
              sm
              title={
                promptContext?.context.language === 'es' ? 'Spanish' : 'English'
              }
            />
          </div>
        }
      />
      <DetailRow
        label="Schedule details"
        value={promptContext?.context.scheduleDetails}
      />
      <DetailRow
        label="Role details"
        value={promptContext?.context.roleDetails}
      />
      <DetailRow
        label="Employment Type"
        value={promptContext?.context.employmentType || '-'}
      />
      <DetailRow
        label="Other Questions"
        value={promptContext?.context.otherQuestions?.join('\n')}
      />
    </Col>
  )
}
