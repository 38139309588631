import {
  MultiDatePicker,
  Text,
  Col,
  InfoTooltip,
  SvgIcon,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  getEndDateError,
  getFirstShiftDateError,
  getNextStartAndEndTime,
  getBusinessStartTime,
  getEarlyArrivalTimeBufferInMinutes,
  getShiftStartTime,
} from '@traba/utils'
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns'
import { isUndefined } from 'lodash'
import React, { Dispatch, SetStateAction } from 'react'
import { Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'

interface ShiftTimePickerProps {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: React.Dispatch<
    React.SetStateAction<CreateShiftRequest[]>
  >
  timezone: string
  isInvalidBuffer: boolean
  setIsInvalidBuffer: React.Dispatch<React.SetStateAction<boolean>>
  selectedSingleShiftDates: Date[] | null
  setSelectedSingleShiftDates: Dispatch<SetStateAction<Date[] | null>>
}

export const ShiftTimePickerWithSchedules = ({
  createShiftRequests,
  setCreateShiftRequests,
  timezone,
  setIsInvalidBuffer,
  selectedSingleShiftDates,
  setSelectedSingleShiftDates,
}: ShiftTimePickerProps) => {
  console.log(
    'ShiftTimePickerWithSchedules',
    createShiftRequests.map((sr) => ({
      startTime: sr.schedules.map((s) => s.startTime),
      earlyArrivalTimeBufferMinutes: sr.earlyArrivalTimeBufferMinutes,
    })),
  )
  const validateBuffer = (buffer: number): boolean => {
    const isInvalidBuffer = buffer < 0 || buffer > 120
    setIsInvalidBuffer(isInvalidBuffer)
    return isInvalidBuffer
  }

  const handleBufferUpdate = (newBuffer: number) => {
    if (isUndefined(newBuffer)) {
      return
    }

    validateBuffer(newBuffer)
    const currentStartTime = createShiftRequests[0]
      .earlyArrivalTimeBufferMinutes
      ? getBusinessStartTime({
          shiftStartTime: createShiftRequests[0].schedules[0].startTime,
          earlyArrivalTimeBufferMinutes:
            createShiftRequests[0].earlyArrivalTimeBufferMinutes,
        })
      : createShiftRequests[0].schedules[0].startTime

    // Use currentStartTime instead of businessStartTime to handle for rerender dependency when setting state above
    const newStartTime = getShiftStartTime({
      businessStartTime: currentStartTime,
      earlyArrivalTimeBufferMinutes: newBuffer,
    })
    handleStartTimeChange(newStartTime, true) // update startTime to adjust for the buffer

    // Update the earlyArrivalTimeBufferMinutes in all shift requests
    setCreateShiftRequests((prev) => {
      return prev.map((sr) => ({
        ...sr,
        earlyArrivalTimeBufferMinutes: newBuffer,
      }))
    })
  }

  const handleStartTimeChange = (
    newDate: Date | null,
    isFourDigitYear?: boolean,
  ) => {
    if (newDate && isFourDigitYear) {
      let newEndTime = new Date(createShiftRequests[0].schedules[0].endTime)

      if (isBefore(createShiftRequests[0].schedules[0].endTime, newDate)) {
        const shiftLength = differenceInMinutes(
          createShiftRequests[0].schedules[0].endTime,
          createShiftRequests[0].schedules[0].startTime,
        )
        newEndTime = addMinutes(newDate, shiftLength)
      }

      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  startTime: new Date(newDate),
                  endTime: newEndTime,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            startTime: new Date(newDate),
            endTime: newEndTime,
          }
          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })
    }
  }

  const handleEndTimeChange = (newDate: Date | null) => {
    if (newDate) {
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  endTime: newDate,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            endTime: newDate,
          }

          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })

      if (
        createShiftRequests[0].schedules[0].recurringSchedule?.endDate &&
        isBefore(
          createShiftRequests[0].schedules[0].recurringSchedule.endDate,
          newDate,
        )
      ) {
        setCreateShiftRequests((prev) => {
          return prev.map((sr) => ({
            ...sr,
            schedule: {
              ...sr.schedules[0],
              recurringSchedule: {
                repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                freq: 'WEEKLY',
                interval: 1,
                endDate: newDate,
              },
            },
            schedules: [
              {
                ...sr.schedules[0],
                recurringSchedule: {
                  repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                  freq: 'WEEKLY',
                  interval: 1,
                  endDate: newDate,
                },
              },
            ],
          }))
        })
      }
    }
  }

  const handleBusinessStartTimeChange = (newDate: Date | null) => {
    // Business start time change logic
    if (newDate) {
      handleBufferUpdate(
        getEarlyArrivalTimeBufferInMinutes({
          businessStartTime: newDate,
          shiftStartTime: createShiftRequests[0].schedules[0].startTime,
        }),
      )
    }
  }

  //cannot set a buffer for a shift that starts in less than 2 hours
  const twoHoursFromNow = new Date()
  twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)

  const fieldDisabled = isBefore(
    createShiftRequests[0].schedules[0].startTime,
    twoHoursFromNow,
  )
  const firstShiftDateError = getFirstShiftDateError(
    createShiftRequests[0],
  )?.message

  const endDateError = getEndDateError(createShiftRequests[0])?.message

  console.log('shiftStartTime', createShiftRequests[0].schedules[0].startTime)
  console.log('buffer', createShiftRequests[0].earlyArrivalTimeBufferMinutes)
  console.log(
    'businessStartTime',
    getBusinessStartTime({
      shiftStartTime: createShiftRequests[0].schedules[0].startTime,
      earlyArrivalTimeBufferMinutes:
        createShiftRequests[0].earlyArrivalTimeBufferMinutes || 0,
    }),
  )
  return (
    <>
      {selectedSingleShiftDates && (
        <>
          <Row alignCenter gap={theme.space.xxxs}>
            <SvgIcon name="calendar" color={theme.colors.Violet} />
            <Text variant="h6">
              Which dates? ({selectedSingleShiftDates?.length} selected)
            </Text>
          </Row>
          <MultiDatePicker
            selectedDates={
              selectedSingleShiftDates ?? [
                createShiftRequests[0].schedules[0].startTime,
              ]
            }
            onSelectDates={(dates: Date[]) => {
              setSelectedSingleShiftDates(dates)
              setCreateShiftRequests((prev) => {
                const shiftDurationInMinutes = differenceInMinutes(
                  prev[0].schedules[0].endTime,
                  prev[0].schedules[0].startTime,
                )
                const newStartTime = combineTwoDatesForDateAndTime(
                  dates[0],
                  prev[0].schedules[0].startTime,
                )
                const newEndTime = addMinutes(
                  newStartTime,
                  shiftDurationInMinutes,
                )
                return prev.map((sr) => ({
                  ...sr,
                  schedules: [
                    {
                      ...sr.schedules[0],
                      startTime: newStartTime,
                      endTime: newEndTime,
                    },
                  ],
                }))
              })
            }}
          />
        </>
      )}
      <Row justifyBetween my={theme.space.sm} wrap>
        {/* Start Time Input Section */}
        <InfoTooltip
          title={
            'You can only edit the start time when an early arrival buffer is 0. Edit the buffer or the business start time if there is a buffer to modify this value.'
          }
        />
        <ShiftPostingInputContainerSection
          label={'First shift start time'}
          input={
            <Col>
              <DatePicker
                date={createShiftRequests[0].schedules[0].startTime}
                showTimeFieldInPopover={true}
                setDate={handleStartTimeChange}
                isClearable={false}
                timezone={timezone}
                aria-label="Start Time"
              />
              <Text variant="error">{firstShiftDateError}</Text>
            </Col>
          }
        />
        {/* Business Start Time Input Section */}
        {createShiftRequests[0].shiftEmploymentType ===
          EmploymentType.CONTRACTOR_1099 && (
          <>
            {!!createShiftRequests[0].schedules[0].startTime &&
              !!createShiftRequests[0].earlyArrivalTimeBufferMinutes && (
                <>
                  <InfoTooltip
                    title={
                      'The business start time is what is shown to the business on the biz app. They do not see buffers or modified shift times.'
                    }
                  />
                  <ShiftPostingInputContainerSection
                    label="Business Start Time"
                    input={
                      <DatePicker
                        date={getBusinessStartTime({
                          shiftStartTime:
                            createShiftRequests[0].schedules[0].startTime,
                          earlyArrivalTimeBufferMinutes:
                            createShiftRequests[0]
                              .earlyArrivalTimeBufferMinutes,
                        })}
                        showTimeFieldInPopover={true}
                        setDate={handleBusinessStartTimeChange}
                        isClearable={false}
                        timezone={timezone}
                        aria-label="Start Time"
                      />
                    }
                  />
                </>
              )}
            {/* Buffer Input Section */}
            <InfoTooltip
              title={
                'The early arrival buffer can only be <= 2 hours. You can only edit this value for start times <= 2 hours from now. Note that this amount of time is a cost to Traba (cost = buffer/60 * hourlyRate * workers)!'
              }
            />
            <ShiftPostingInputContainerSection
              label="Early Arrival Buffer"
              input={
                <Row alignCenter>
                  <NumberInput
                    value={
                      createShiftRequests[0].earlyArrivalTimeBufferMinutes || 0
                    }
                    setValue={(value) => {
                      if (value) {
                        handleBufferUpdate(value)
                      } else {
                        handleBufferUpdate(0)
                      }
                    }}
                    min={0}
                    max={120}
                    step={1}
                    placeholder={'e.g. 15'}
                    onError={() => setIsInvalidBuffer(true)}
                    disabled={fieldDisabled}
                  />
                </Row>
              }
            />
          </>
        )}
        {/* End Time Input Section */}
        <ShiftPostingInputContainerSection
          label="First shift end Time"
          input={
            <Col>
              <DatePicker
                date={createShiftRequests[0].schedules[0].endTime}
                showTimeFieldInPopover={true}
                setDate={handleEndTimeChange}
                isClearable={false}
                timezone={timezone}
                aria-label="End Time"
              />
              {<Text variant="error">{endDateError}</Text>}
            </Col>
          }
        />
      </Row>
    </>
  )
}
