import { trabaApi } from '@traba/api-utils'
import {
  CompanyResponse,
  Invoice,
  InvoiceStatus,
  LineItemPreviewDto,
  Pagination,
  Region,
  ShiftInvoicedStatus,
  ShiftStatus,
  ShiftTag,
  ShiftWithSlimCharges,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'

export const INVOICEABLE_CREDIT_LINE_ITEMS = 'invoiceableCreditLineItems'

type GetShiftForInvoicingBody = {
  invoicedStatus: ShiftInvoicedStatus
  startBefore?: string
  startAfter?: string
  companyIds?: string[]
  regionIds?: string[]
  limit?: number
  startAt?: number
  sortBy?: string
  sortDir?: string
  statuses?: ShiftStatus[]
  hasInvoiceGroup?: boolean
  excludeCompanies?: boolean
  shiftTags?: ShiftTag[]
}

export async function getShiftsForInvoicing(
  body: GetShiftForInvoicingBody,
): Promise<
  | { shifts: ShiftWithSlimCharges[]; totalCount: number }
  | ShiftWithSlimCharges[]
  | undefined
> {
  try {
    const res = await trabaApi.post(`invoices/query-shifts`, body)
    return res.data
  } catch (error: any) {
    const errorMessage = `useShiftsForInvoicing -> getShiftsForInvoicing() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export function useShiftsForInvoicing(props: GetShiftForInvoicingBody): {
  isFetching: boolean
  isError: boolean
  shifts: ShiftWithSlimCharges[] | undefined
  totalCount: number | undefined
  error: Error | null
  isFetched: boolean
  refetch: () => void
} {
  const { isFetching, isError, data, error, isFetched, refetch } = useQuery<
    | { shifts: ShiftWithSlimCharges[]; totalCount: number }
    | ShiftWithSlimCharges[]
    | undefined,
    Error
  >(['shiftsForInvoicing', props], () => getShiftsForInvoicing(props), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes stale time
  })

  return {
    isFetching,
    isError,
    shifts: Array.isArray(data) ? data : data?.shifts, // using this format for backwards compatibility with endpoint response type
    totalCount: Array.isArray(data) ? undefined : data?.totalCount, // will clean up later. @praneeth-traba
    error,
    isFetched,
    refetch,
  }
}

async function getInvoices(
  filters: {
    statuses?: string[]
    regions?: Region[]
    companies?: CompanyResponse[]
    dueDateMax?: Date
    shiftTags?: ShiftTag[]
    excludeCompanies?: boolean
  },
  pagination?: Pagination,
): Promise<
  { invoices: Invoice[]; totalCount: number } | Invoice[] | undefined
> {
  try {
    const res = await trabaApi.get(`invoices`, {
      params: {
        statuses: filters.statuses,
        regionIds: filters.regions?.map((region) => region.regionId),
        companyIds: filters.companies?.map((company) => company.id),
        excludeCompanies: filters.excludeCompanies,
        shiftTags: filters.shiftTags?.map((tag) => tag),
        startAt: pagination?.startAt,
        orderBy: pagination?.orderBy,
        limit: pagination?.limit,
        dueDateMax: filters.dueDateMax,
      },
    })
    return res.data
  } catch (error: any) {
    console.error('useInvoices -> getInvoices', error.message ?? error)
  }
}

export function useInvoices(
  filters: {
    statuses?: InvoiceStatus[]
    regions?: Region[]
    companies?: CompanyResponse[]
    dueDateMax?: Date
    shiftTags?: ShiftTag[]
    excludeCompanies?: boolean
  },
  pagination?: Pagination,
) {
  const { isFetching, isError, data, error, isFetched, refetch } = useQuery<
    { invoices: Invoice[]; totalCount: number } | Invoice[] | undefined,
    Error
  >([`invoices`, filters, pagination], () => getInvoices(filters, pagination), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes stale time
  })

  return {
    isFetching,
    isError,
    invoices: Array.isArray(data) ? data : data?.invoices, // using this format for backwards compatibility with endpoint response type
    invoiceCount: Array.isArray(data) ? undefined : data?.totalCount, // will clean up later. @praneeth-traba
    error,
    isFetched,
    refetch,
  }
}

export function useInvoiceableCreditLineItems(
  invoiceId: string | undefined,
  invoiceStatus: InvoiceStatus | undefined,
) {
  const {
    isFetching,
    isLoading,
    isError,
    data: lineItems,
    error,
    isFetched,
    refetch,
  } = useQuery<LineItemPreviewDto[], Error>({
    queryKey: [INVOICEABLE_CREDIT_LINE_ITEMS, invoiceId],
    queryFn: async () => {
      if (
        !invoiceId ||
        !invoiceStatus ||
        invoiceStatus !== InvoiceStatus.OPEN
      ) {
        return []
      }

      try {
        const res = await trabaApi.get(
          `invoices/${invoiceId}/uninvoiced-credit-line-items`,
        )
        return res.data
      } catch (error: any) {
        captureSentryError(error)
        console.error(
          'useInvoiceableCreditLineItems -> getInvoiceableCreditLineItems',
          error.message ?? error,
        )
        throw error
      }
    },
  })

  return {
    isFetching,
    isError,
    lineItems,
    error,
    isFetched,
    refetch,
    isLoading,
  }
}
