import { Row, Text, WithTooltip } from '@traba/react-components'
import {
  AccountApprovalStatus,
  ApprovalStatusReason,
  EmploymentType,
} from '@traba/types'
import { ReactNode } from 'react'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { AccountSetupTooltip } from './AccountSetupTooltip'
import Badge, { BadgeProps } from '.'

export type AccountStatusBadgeProps = {
  accountStatus: AccountApprovalStatus
  approvalStatusReason?: ApprovalStatusReason
  accountSuspensionExpiration?: Date
  workerId?: string
  activeEmploymentTypes?: EmploymentType[]
  extraWarnings?: ReactNode[]
}

const accountStatusToBadgeVariantDict: Record<AccountApprovalStatus, string> = {
  [AccountApprovalStatus.Approved]: 'success',
  [AccountApprovalStatus.Banned]: 'danger',
  [AccountApprovalStatus.Rejected]: 'opaqueRed',
  [AccountApprovalStatus.Suspended]: 'warning',
  [AccountApprovalStatus.Unapproved]: 'info',
  [AccountApprovalStatus.Terminated]: 'danger',
  [AccountApprovalStatus.TerminationPending]: 'warning',
}

const BadgeWithTooltip = WithTooltip<BadgeProps>(Badge)

export default function AccountStatusBadge({
  accountStatus,
  approvalStatusReason,
  accountSuspensionExpiration,
  workerId,
  activeEmploymentTypes,
  extraWarnings,
}: AccountStatusBadgeProps) {
  const extraNodeMessage = () => {
    switch (accountStatus) {
      case AccountApprovalStatus.Suspended:
        if (approvalStatusReason && accountSuspensionExpiration) {
          return (
            <Row flexCol>
              <Text variant="h6">Suspension:</Text>
              <Text>Reasons: {approvalStatusReason}</Text>
              <Text>
                Expiration:{' '}
                {formatDateTimeWithTimezone(accountSuspensionExpiration)}
              </Text>
            </Row>
          )
        }
        break
      case AccountApprovalStatus.Rejected:
        if (approvalStatusReason) {
          return (
            <Row flexCol>
              <Text variant="h6">Rejection:</Text>
              <Text>Reasons: {approvalStatusReason}</Text>
            </Row>
          )
        }
        break
      default:
        break
    }
  }

  const activeEmploymentType = activeEmploymentTypes?.includes(
    EmploymentType.W2,
  )
    ? EmploymentType.W2
    : EmploymentType.CONTRACTOR_1099

  return (
    <BadgeWithTooltip
      tooltipContent={
        workerId ? (
          <AccountSetupTooltip
            workerId={workerId}
            employmentType={activeEmploymentType}
            extraInfo={
              extraWarnings
                ? [extraNodeMessage(), ...extraWarnings]
                : [extraNodeMessage()]
            }
          />
        ) : (
          extraNodeMessage()
        )
      }
      placement="right"
      arrow
      title={accountStatus?.replaceAll('_', ' ')}
      variant={accountStatusToBadgeVariantDict[accountStatus]}
    />
  )
}
