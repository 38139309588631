import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { addMinutes, isAfter } from 'date-fns'
import Cookies from 'js-cookie'
import { useQueryClient as useQueryClientV3 } from 'react-query'
import { UserRole } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'

export function useGoogleAuth() {
  const queryClient = useQueryClient()
  const queryClientV3 = useQueryClientV3()
  const userContext = useUserContext()
  const { showError } = useAlert()

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    hosted_domain: 'traba.work',
    onSuccess: (params) => {
      handleLoginWithAuthCode(params.code)
    },
  })

  async function handleAuthStateChanged() {
    const useNodeTokenAuth = Cookies.get('nodeServiceToken') !== undefined
    if (useNodeTokenAuth) {
      userContext.dispatch({
        type: 'USER_LOGIN_COOKIE',
        value: {
          email: 'Node Server',
          role: 'Node Server',
        },
      })
      return
    }

    const useCookieAuth = Cookies.get('opsConsoleIdToken') !== undefined
    if (useCookieAuth) {
      try {
        await verifyCookieIdTokenAndLogin()
        return
      } catch (err: any) {
        const errorMessage = err.message ?? err.response?.message
        if (errorMessage === 'Network Error') {
          return console.log('NETWORK ERROR')
        }
      }
    }

    const opsConsoleIdToken = localStorage.getItem('opsConsoleIdToken')
    if (opsConsoleIdToken) {
      try {
        // Validate token in our server. If it's valid, dispatch login action
        await verifyIdTokenAndLogin()
        return
      } catch (err: any) {
        const errorMessage = err.message ?? err.response?.message
        if (errorMessage === 'Network Error') {
          return console.log('NETWORK ERROR')
        }
      }
    }

    // If there's no auth token or token is invalid, execute logout function
    handleLogout()
  }
  const TOKEN_EXPIRATION_MINUTES = 30
  const tokenExpirationTime = 1000 * 60 * TOKEN_EXPIRATION_MINUTES

  async function handleLoginWithAuthCode(authCode: string) {
    try {
      // Exchange auth token for id and refresh tokens
      const res = await trabaApi.post('google/auth/get-auth-tokens', {
        authCode,
        redirectUrl: window.location.origin,
      })

      const { id_token, refresh_token } = res.data

      // Set up id token, refresh token and expiration date on local storage
      localStorage.setItem(
        'opsConsoleIdTokenExp',
        addMinutes(new Date(), TOKEN_EXPIRATION_MINUTES).toISOString(),
      )
      if (id_token) {
        localStorage.setItem('opsConsoleIdToken', id_token)
      }
      if (refresh_token) {
        localStorage.setItem('opsConsoleRefreshToken', refresh_token)
        setTimeout(() => refreshAuthTokens(), tokenExpirationTime)
      }

      await verifyIdTokenAndLogin()
    } catch (err: any) {
      showError(err.message ?? err.response?.message, 'Error logging in ')
    }
  }

  async function verifyIdTokenAndLogin() {
    // Get expiration date
    const expDateString = localStorage.getItem('opsConsoleIdTokenExp')
    if (!expDateString) {
      throw new Error('Expiration date not found')
    }
    const expDate = new Date(expDateString)

    // Refresh token if it's passed the expiration date
    if (isAfter(new Date(), expDate)) {
      await refreshAuthTokens()
    }

    // Verify ID token and dispatch login action
    const res = await trabaApi.get(`google/auth/get-token-info`)
    const email = res.data.email ?? res.data
    const role = res.data.role ? (res.data.role as UserRole) : UserRole.Internal
    userContext.dispatch({ type: 'USER_LOGIN' })
    userContext.dispatch({
      type: 'EDIT_USER',
      value: {
        email,
        role,
      },
    })
  }

  // This flow doesn't manage the expiration date or refresh token because
  // these routes are not planned to be used directly by human users. Instead,
  // it assumes that the cookie is valid on each request from the node server.
  async function verifyCookieIdTokenAndLogin() {
    const res = await trabaApi.get(`google/auth/get-token-info`)
    const email = res.data.email ?? res.data
    const role = res.data.role ? (res.data.role as UserRole) : UserRole.Internal
    userContext.dispatch({
      type: 'USER_LOGIN_COOKIE',
      value: {
        email,
        role,
      },
    })
  }

  async function refreshAuthTokens() {
    const refreshToken = localStorage.getItem('opsConsoleRefreshToken')
    if (!refreshToken) {
      throw new Error('Refresh token not found')
    }

    try {
      const res = await trabaApi.post(`google/auth/refresh-auth-tokens`, {
        refreshToken,
      })

      const { id_token, refresh_token } = res.data

      localStorage.setItem('opsConsoleIdToken', id_token)

      if (refresh_token) {
        localStorage.setItem('opsConsoleRefreshToken', refresh_token)
      }

      const expirationDate = addMinutes(new Date(), TOKEN_EXPIRATION_MINUTES)
      localStorage.setItem('opsConsoleIdTokenExp', expirationDate.toISOString())

      // Schedule the next token refresh based on the remaining time until expiration
      const currentTime = new Date()
      const remainingTime = expirationDate.getTime() - currentTime.getTime()
      setTimeout(refreshAuthTokens, remainingTime)
    } catch (err: any) {
      showError(err.message ?? err.response?.message, 'Error refreshing tokens')
      handleLogout() // Log out the user if token refresh fails
    }
  }

  function handleLogout() {
    googleLogout()
    localStorage.clear()
    userContext.dispatch({ type: 'USER_LOGOUT' })
    // Clear react query cache after logout
    queryClient.clear()
    queryClientV3.clear()
  }

  return {
    handleLogout,
    handleAuthStateChanged,
    handleLoginWithAuthCode,
    loginWithGoogle,
  }
}
