import { Col, InfoTooltip, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeSkillLevels,
  OpsAttributeLevel,
  RoleAttribute,
  RoleAttributeCategory,
} from '@traba/types'
import { AttributeBadge } from 'src/components/AttributeBadge/AttributeBadge'

interface AttributeLevelsSectionProps {
  sectionTitle: string
  titleTooltip?: string
  attributes: RoleAttribute[]
  handleSelectAttributeLevel: (
    attrType: string,
    category: RoleAttributeCategory,
    level: OpsAttributeLevel,
  ) => void
  attributeLevelMap: Record<string, OpsAttributeLevel>
  showRevoke?: boolean
  showDeselect?: boolean
  showUnreported?: boolean
  isInSearchFilter?: boolean
  allowBooleanSelectionOnly?: boolean
  selectedAttributes?: string[]
  setSelectedAttributes?: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedQuestions?: React.Dispatch<React.SetStateAction<string[]>>
}

export function AttributeLevelsSection(props: AttributeLevelsSectionProps) {
  const {
    sectionTitle,
    titleTooltip,
    attributes,
    attributeLevelMap,
    handleSelectAttributeLevel,
    showRevoke,
    showDeselect,
    showUnreported,
    isInSearchFilter,
    selectedAttributes,
    setSelectedAttributes,
    setSelectedQuestions,
    allowBooleanSelectionOnly,
  } = props

  return (
    <Col mx={theme.space.sm} mb={theme.space.xxs}>
      <Row alignCenter mb={theme.space.xs}>
        <Text
          variant="caption"
          ml={theme.space.xxs}
          style={{ textTransform: 'uppercase', fontSize: 12 }}
        >
          {sectionTitle}
        </Text>
        {titleTooltip && <InfoTooltip title={titleTooltip} />}
      </Row>
      <Row style={{ flexWrap: 'wrap' }} gap={theme.space.xxs}>
        {attributes.map((attribute) => (
          <AttributeBadge
            key={attribute.type}
            attributeType={attribute.type}
            attributeName={attribute.displayName ?? attribute.type}
            attributeLevel={
              attributeLevelMap[attribute.type] ??
              (showDeselect
                ? AttributeSkillLevels.DESELECTED
                : AttributeSkillLevels.UNREPORTED)
            }
            category={attribute.category}
            levelType={attribute.levelType}
            handleSelectAttributeLevel={handleSelectAttributeLevel}
            showRevoke={showRevoke}
            showDeselect={showDeselect}
            showUnreported={showUnreported}
            isInSearchFilter={isInSearchFilter}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
            setSelectedQuestions={setSelectedQuestions}
            allowBooleanSelectionOnly={allowBooleanSelectionOnly}
          />
        ))}
      </Row>
    </Col>
  )
}
