import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { captureSentryError } from '@traba/utils'

const extractTimesheet = async ({
  timesheetUrl,
  fileName,
  shiftIds,
}: {
  timesheetUrl: string
  fileName: string
  shiftIds: string[]
}) => {
  try {
    await trabaApi.post(`/agents/timesheet/extract`, {
      timesheetUrl,
      fileName,
      shiftIds,
      mimeType: 'application/pdf',
    })
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useTimesheetAgent = () => {
  const { showSuccess, showError } = useAlert()

  const { mutateAsync } = useMutation({
    mutationFn: extractTimesheet,
    onSuccess: () => {
      showSuccess(
        'Timesheet uploaded successfully. Look for a Slack message with the processed CSV. It may take a few minutes.',
      )
    },
    onError: (error) => {
      showError(error.message, 'Failed to upload timesheet', 10000)
    },
  })

  return {
    extractTimesheet: mutateAsync,
  }
}
