import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { GetGeoFenceDto, UpdateShiftAreaFenceDto } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQueries, useQuery } from 'react-query'

async function getShiftAreaFence(
  locationId: string,
): Promise<GetGeoFenceDto | undefined> {
  try {
    const res = await trabaApi.get(`locations/${locationId}/shift-area-fence`)
    return res.data
  } catch (error: any) {
    console.error(
      'useLocations -> getLocations() ERROR',
      error.message ?? error,
    )
  }
}

export const updateShiftAreaFence = async ({
  locationId,
  updatedFence,
}: {
  locationId: string
  updatedFence: UpdateShiftAreaFenceDto
}) => {
  const response = await trabaApi.post(
    `locations/${locationId}/shift-area-fence`,
    updatedFence,
  )
  return response.data
}

const deleteShiftAreaFence = async ({
  locationId,
}: {
  companyId: string
  locationId: string
}) => {
  const response = await trabaApi.delete(`locations/${locationId}/archive`)
  return response.data
}
export function useManyGeofences(locationIds: string[]) {
  const results = useQueries(
    locationIds.map((locationId) => ({
      queryKey: geofenceQueryKey(locationId),
      queryFn: () => getShiftAreaFence(locationId),
    })),
  )

  return {
    isLoading: results.map((r) => r.isLoading),
    isError: results.map((r) => r.isError),
    shiftAreaFences: results.map((r) => r.data),
    error: results.map((r) => r.error),
    isFetched: results.map((r) => r.isFetched),
  }
}

function geofenceQueryKey(locationId: string): string {
  return `location_${locationId}`
}

export function useGeofences(locationId: string) {
  const { handleError, showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: shiftAreaFence,
    error,
    isFetched,
    refetch,
  } = useQuery<GetGeoFenceDto | undefined, Error>(
    geofenceQueryKey(locationId),
    () => getShiftAreaFence(locationId),
  )

  const updateShiftAreaFenceMutation = useMutation<
    any,
    AxiosError,
    {
      locationId: string
      updatedFence: UpdateShiftAreaFenceDto
    }
  >(updateShiftAreaFence, {
    onSuccess: (_response: any) => {
      showSuccess('Updated shift area fence')
      refetch()
    },
    onError: (error) => {
      handleError(
        error,
        'useGeofences -> updateShiftAreaFence()',
        JSON.stringify(error),
        'Error updating shift area fence',
      )
    },
  })

  const deleteShiftAreaFenceMutation = useMutation<any, AxiosError, any>(
    deleteShiftAreaFence,
    {
      onSuccess: (_response: any) => {
        refetch()
      },
      onError: (error) => {
        handleError(
          error,
          'useGeofences -> deleteShiftAreaFence()',
          JSON.stringify(error),
          'Error removing shift area fence',
        )
      },
    },
  )

  return {
    isLoading,
    isError,
    shiftAreaFence,
    error,
    isFetched,
    refetch,
    deleteShiftAreaFence: deleteShiftAreaFenceMutation.mutate,
    updateShiftAreaFence: updateShiftAreaFenceMutation.mutate,
  }
}
