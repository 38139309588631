import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { AddressDto, CompanyCategory, CoordinatesDto } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

const DEMO_ACCOUNT_QUERY_KEY = 'demoAccountQueryKey'

interface DemoAccountProps {
  id: string
  email: string
  password: string
  expirationDate: Date
}

export type DemoAccountDataDto = {
  companyName: string
  companyLogo: string
  category: CompanyCategory
  address: AddressDto
  coords: CoordinatesDto
  address2: AddressDto
  coords2: CoordinatesDto
  daysToExpiration: number
  workerCount: number
}

async function queryDemoAccounts() {
  try {
    const response = await trabaApi.get('/demo-account')
    return response.data
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

export function useDemoAccounts() {
  const { data, isLoading, isError, refetch } = useQuery<DemoAccountProps[]>({
    queryKey: [DEMO_ACCOUNT_QUERY_KEY],
    queryFn: () => queryDemoAccounts(),
    staleTime: FIVE_MINUTES_IN_MS,
  })
  return { data, isLoading, isError, refetch }
}

async function createDemoAccount(demoAccountData: DemoAccountDataDto) {
  try {
    await trabaApi.post('/demo-account/build', demoAccountData)
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

async function deleteDemoAccount(id: string) {
  try {
    await trabaApi.delete(`/demo-account/${id}`)
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

export function useDemoAccountsMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()
  const createDemoAccountMutation = useMutation<
    void,
    AxiosError,
    DemoAccountDataDto
  >({
    mutationFn: (demoAccountData) => createDemoAccount(demoAccountData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEMO_ACCOUNT_QUERY_KEY] })
      showSuccess('Demo account created successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to create demo account')
    },
  })

  const deleteDemoAccountMutation = useMutation<void, AxiosError, string>({
    mutationFn: (id) => deleteDemoAccount(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEMO_ACCOUNT_QUERY_KEY] })
      showSuccess('Demo account deleted successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to delete demo account')
    },
  })

  return {
    createDemoAccount: createDemoAccountMutation.mutateAsync,
    deleteDemoAccount: deleteDemoAccountMutation.mutateAsync,
    isDeletingDemo: deleteDemoAccountMutation.isPending,
    isCreatingDemo: createDemoAccountMutation.isPending,
  }
}
