import { VettingConfig } from './worker-vetting'

export enum ApplicationEntityType {
  SHIFT_REQUEST = 'SHIFT_REQUEST',
  ROLE = 'ROLE',
}

export type ApplicationEntity = {
  id: string
  applicationEntityType: ApplicationEntityType
  shiftRequestId?: string
  roleId?: string
}

export enum ApplicationItemType {
  AI_VETTING_CALL = 'AI_VETTING_CALL',
  // placeholders below, not used yet:
  BGC = 'BGC',
  DRUG_SCREENING = 'DRUG_SCREENING',
}

export enum ApplicationRecordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export enum ApplicationItemRecordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ApplicationItem = {
  id: string
  applicationItemType: ApplicationItemType
  sourceId?: string
  applicationId: string
  recordStatus: ApplicationItemRecordStatus
}

export type Application = {
  id: string
  recordStatus: ApplicationRecordStatus
  applicationEntities: ApplicationEntity[]
  applicationItems: ApplicationItem[]
}

export type ApplicationItemAICallItemContent = {
  vettingConfig: VettingConfig
}

export type ApplicationItemAICallRequest = {
  applicationItemType: ApplicationItemType.AI_VETTING_CALL
  itemContent: ApplicationItemAICallItemContent
}

// not supported yet but another as an example
export type ApplicationItemBGCRequest = {
  applicationItemType: ApplicationItemType.BGC
}

// not supported yet but another as an example
export type ApplicationItemDrugScreeningRequest = {
  applicationItemType: ApplicationItemType.DRUG_SCREENING
}

export type ApplicationItemRequestType =
  | ApplicationItemAICallRequest
  | ApplicationItemBGCRequest
  | ApplicationItemDrugScreeningRequest

export type ApplicationItemContent =
  | undefined
  | ApplicationItemAICallItemContent

export type UpsertApplicationItemRequestType = ApplicationItemRequestType & {
  applicationItemId?: string
  applicationItemSourceId?: string
}

export type ApplicationForShiftRequestRequest = {
  applicationItems: ApplicationItemRequestType[]
}
