import { Button, ButtonVariant, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import Divider from '../Divider'
import {
  FloatingActionBarContainer,
  FloatingActionBarContent,
} from './FloatingActionBar.styles'

interface FloatingActionBarProps {
  selectedCount: number
  onClear: () => void
  actions: Array<{
    label: string
    onClick: () => void
  }>
}

export const FloatingActionBar = ({
  selectedCount,
  onClear,
  actions,
}: FloatingActionBarProps) => {
  return (
    <FloatingActionBarContainer>
      <FloatingActionBarContent>
        <Text variant="h6">{selectedCount} selected</Text>
        <Divider
          wrapperStyle={{
            width: 1,
            height: 24,
            backgroundColor: theme.colors.Grey20,
          }}
        />
        {actions.map((action, index) => (
          <React.Fragment key={action.label}>
            <Button variant={ButtonVariant.TEXT} onClick={action.onClick}>
              {action.label}
            </Button>
            {index < actions.length && (
              <Divider
                wrapperStyle={{
                  width: 1,
                  height: 24,
                  backgroundColor: theme.colors.Grey20,
                }}
              />
            )}
          </React.Fragment>
        ))}
        <Button variant={ButtonVariant.TEXT} onClick={onClear}>
          <SvgIcon name="cancel" size={16} color={theme.colors.Grey50} />
        </Button>
      </FloatingActionBarContent>
    </FloatingActionBarContainer>
  )
}
