import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import { InputStatus } from '@traba/types'
import React from 'react'
import {
  InputErrorIcon,
  InputErrorMessage,
} from 'src/components/base/Input/Input.styles'
import * as S from './Select.styles'

export interface IMenuItem {
  value: string
  label: string
  secondaryLabel?: string
  disabled?: boolean
  children?: IMenuItem[]
}

export interface SelectDropdownProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  inputStatus?: InputStatus
  menuItems: Array<IMenuItem>
  value: string
  handleSelect: (value: string) => void
  errorMessage?: string
  fullWidth?: boolean
  showEmptyOption?: boolean
  containerStyle?: React.CSSProperties
  dropdownStyle?: React.CSSProperties
  placeholder?: string
}

export const SelectDropdown: React.FC<SelectDropdownProps> = (
  props: SelectDropdownProps,
) => {
  const hasError = props.inputStatus === InputStatus.error
  return (
    <>
      <S.SelectContainer
        style={{
          width: props.fullWidth ? '100%' : 'auto',
          ...props.containerStyle,
        }}
      >
        {
          <FormControl fullWidth error={hasError}>
            {!!props.label && (
              <S.LabelWrapper>
                <InputLabel
                  id="select-dropdown"
                  style={{ top: !props.value ? 0 : undefined }}
                >
                  {props.label}
                </InputLabel>
              </S.LabelWrapper>
            )}
            <Select
              labelId={props.label ? 'select-dropdown' : undefined}
              label={props.label || ''}
              id={`select-dropdown-${props.label}`}
              value={props.value}
              onChange={(event) => props.handleSelect(event.target.value)}
              defaultValue=""
              sx={{ backgroundColor: 'white', ...props.dropdownStyle }}
              disabled={props.disabled}
            >
              {props.placeholder && (
                <MenuItem disabled value="">
                  {props.placeholder}
                </MenuItem>
              )}
              {props.showEmptyOption && !props.placeholder && (
                <MenuItem value={undefined} key={`item-undefined`}>
                  -
                </MenuItem>
              )}
              {props.menuItems.map((item: IMenuItem, idx: number) => (
                <MenuItem
                  value={item.value || ''}
                  key={`item-${idx}`}
                  disabled={item.disabled}
                >
                  <ListItemText
                    primary={item.label}
                    secondary={
                      props.value !== item.value ? item.secondaryLabel : ''
                    }
                    style={{
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                    primaryTypographyProps={{ fontWeight: 400 }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </S.SelectContainer>
      {hasError && (
        <InputErrorMessage>
          <InputErrorIcon />
          {props.errorMessage}
        </InputErrorMessage>
      )}
    </>
  )
}
