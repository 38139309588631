import { RoleFormMode } from '@traba/consts'
import { Company, COMPANY_WIDE_ID, Role } from '@traba/types'
import { useCallback, useState } from 'react'

import { Modal } from 'src/components/base'
import { ModalProps } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { RoleForm } from 'src/components/Roles/RoleForm'
import { RoleStepper } from 'src/components/Roles/RoleStepper'
import { CreateRoleData, EditRoleData, useRoles } from 'src/hooks/useRoles'
import { parseFreeFormAttributesToRequiredAttributes } from 'src/utils/roleUtils'

export type EditOrDuplicateRoleModalProps = {
  role: Role
  company: Company
  roleEditModal: Omit<ModalProps, 'children'>
  isEventCompany: boolean
  isDuplicate?: boolean
}

export const EditOrDuplicateRoleModal: React.FC<
  EditOrDuplicateRoleModalProps
> = (props) => {
  const { role, company, roleEditModal, isEventCompany, isDuplicate } = props

  const { editRole, createRole } = useRoles({ companyId: company.companyId })
  const [isLoadingEditOrDuplicate, setIsLoadingEditOrDuplicate] =
    useState<boolean>(false)

  const handleEdit = useCallback(
    (editRoleData: EditRoleData) => {
      setIsLoadingEditOrDuplicate(true)
      editRole(
        {
          roleId: role.roleId,
          companyId: company.companyId,
          editRoleData,
        },
        {
          onSettled: () => {
            setIsLoadingEditOrDuplicate(false)
            roleEditModal.handleClose()
          },
        },
      )
    },
    [
      role.roleId,
      company.companyId,
      editRole,
      roleEditModal,
      setIsLoadingEditOrDuplicate,
    ],
  )

  const handleDuplicate = useCallback(
    (duplicateRoleData: CreateRoleData) => {
      setIsLoadingEditOrDuplicate(true)
      createRole(duplicateRoleData, {
        onSettled: () => {
          setIsLoadingEditOrDuplicate(false)
          roleEditModal.handleClose()
        },
      })
    },
    [createRole, roleEditModal, setIsLoadingEditOrDuplicate],
  )

  return (
    <Modal
      size={MODAL_SIZE.EXTRA_LARGE}
      {...roleEditModal}
      title={
        isDuplicate
          ? `Duplicating role: ${role.roleName}`
          : `Editing role: ${role.roleName}`
      }
    >
      {isEventCompany ? (
        <RoleForm
          onCancel={roleEditModal.handleClose}
          mode={isDuplicate ? RoleFormMode.DUPLICATE : RoleFormMode.EDIT}
          onConfirmEdit={handleEdit}
          onConfirmDuplicate={handleDuplicate}
          company={company}
          initialRoleState={{
            ...role,
            requiredCertifications: role.requiredCertifications || [],
            requiredAttributes: role.requiredAttributes || [],
            requiredAttributeLevels: role.requiredAttributeLevels || [], // TODO(Joey) is this necessary?
            videoIds: role.videoIds || [],
            locationId: role.location?.locationId || COMPANY_WIDE_ID,
          }}
          submitLoading={isLoadingEditOrDuplicate}
        />
      ) : (
        <RoleStepper
          onCancel={roleEditModal.handleClose}
          onConfirmEdit={handleEdit}
          onConfirmDuplicate={handleDuplicate}
          mode={isDuplicate ? RoleFormMode.DUPLICATE : RoleFormMode.EDIT}
          currentRoleId={role.roleId}
          initialRoleState={{
            ...role,
            requiredCertifications: role.requiredCertifications || [],
            requiredAttributes: parseFreeFormAttributesToRequiredAttributes(
              role.requiredAttributes,
              role.freeformAttributes,
            ),
            requiredAttributeLevels: role.requiredAttributeLevels || [],
            freeformAttributes: role.freeformAttributes || {},
            videoIds: role.videoIds || [],
            locationId: role.location?.locationId || COMPANY_WIDE_ID,
          }}
          fromPrevious={false}
          submitLoading={isLoadingEditOrDuplicate}
          company={company}
        />
      )}
    </Modal>
  )
}
