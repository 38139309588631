import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  VettingPromptContext,
  PromptContextType,
  StartSmsAndPhoneVettingRequest,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { FETCH_VETTING_CAMPAIGNS_QUERY_KEY } from './useVettingCampaigns'

const PROMPT_CONTEXT_QUERY_KEY = 'PROMPT_CONTEXT_QUERY_KEY'

export type PromptContextResponse = {
  promptContextId: string
  createdAt: string
  updatedAt: string
  activePromptContextId: string
  context: VettingPromptContext
  contextType: PromptContextType
  shiftRequestId?: string
}

async function saveVettingPromptContext(
  saveVettingPromptContextRequest: StartSmsAndPhoneVettingRequest,
): Promise<PromptContextResponse> {
  try {
    const { data } = await trabaApi.post(
      `workers/vetting/save-prompt-context`,
      saveVettingPromptContextRequest,
    )
    return data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function getPromptContext(
  promptContextId?: string,
): Promise<PromptContextResponse | undefined> {
  if (!promptContextId) {
    return undefined
  }
  try {
    const { data } = await trabaApi.get(
      `/workers/vetting/prompt/${promptContextId}`,
    )
    return data
  } catch (error) {
    captureSentryError(error)
  }
}

export function usePromptContext(promptContextId?: string) {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const {
    isLoading,
    isError,
    data: promptContext,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [PROMPT_CONTEXT_QUERY_KEY, promptContextId],
    queryFn: () => getPromptContext(promptContextId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!promptContextId,
  })

  const saveVettingPromptContextMutation = useMutation<
    PromptContextResponse,
    AxiosError,
    StartSmsAndPhoneVettingRequest
  >({
    mutationFn: saveVettingPromptContext,
    onSuccess: (_response: any) => {
      showSuccess('vettingPromptContext saved successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error saving vettingPromptContext')
    },
  })

  return {
    isLoading,
    isError,
    promptContext,
    error,
    isFetching,
    refetch,
    saveVettingPromptContext: saveVettingPromptContextMutation.mutateAsync,
  }
}
