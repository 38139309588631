import { trabaApi } from '@traba/api-utils'
import { Training } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'

const getTrainings = async () => {
  try {
    const response = await trabaApi.get(`/trainings`)
    return response.data
  } catch (error) {
    console.error('useTrainings -> getTrainings() ERROR', error)
    captureSentryError(error, {
      tags: { action: 'useTrainings -> getTrainings()' },
    })
  }
}

export const useTrainings = () => {
  const {
    isLoading,
    isError,
    data: trainings,
    error,
    isFetched,
  } = useQuery<Training[], Error>('trainings', getTrainings)

  return {
    isLoading,
    isError,
    error,
    isFetched,
    trainings,
  }
}
