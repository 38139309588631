import { Col } from '@traba/react-components'
import { ApplicationItem, ApplicationItemType } from '@traba/types'
import { WorkerVettingCallApplicationItemContent } from './WorkerVettingCallApplicationItemContent'

interface ApplicationItemContentProps {
  applicationItem: ApplicationItem
}

export const ApplicationItemContent = ({
  applicationItem,
}: ApplicationItemContentProps) => {
  switch (applicationItem.applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      return (
        <WorkerVettingCallApplicationItemContent
          applicationItem={applicationItem}
        />
      )
    default:
      return <Col>Nothing to display</Col>
  }
}
