import { InputAdornment, TextField } from '@mui/material'
import { useAlert } from '@traba/context'
import {
  Text,
  Button,
  ButtonVariant,
  DatePicker,
  Input,
  Modal,
  MODAL_SIZE,
  Row,
  Col,
  SelectDropdown,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreateLineItemDto, EditLineItemDto, LineItemType } from '@traba/types'
import { useEffect, useState } from 'react'
import RadioGroup from 'src/components/base/RadioGroup'
import { useInvoiceMutations } from 'src/hooks/useInvoiceMutations'
import { getLocalTimezone } from 'src/utils/dateUtils'
import { InvoiceDetailsBodyLineItem } from './InvoiceLineItemsTable'

enum LineItemCategory {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

enum ManualLineItemOption {
  BONUS_PAY = 'BONUS_PAY',
  WORKER_REIMBURSEMENT = 'WORKER_REIMBURSEMENT',
  TRANSPORTATION_FEE = 'TRANSPORTATION_FEE',
  TRABA_REIMBURSEMENT = 'TRABA_REIMBURSEMENT',
  WORKER_QUALITY_CREDIT = 'WORKER_QUALITY_CREDIT',
  WORKER_DAMAGE_CREDIT = 'WORKER_DAMAGE_CREDIT',
  TRAINING_CREDIT = 'TRAINING_CREDIT',
  LATE_CANCEL_CREDIT = 'LATE_CANCEL_CREDIT',
  OVERBOOK_CREDIT = 'OVERBOOK_CREDIT',
  MARKUP_CREDIT = 'MARKUP_CREDIT',
  BUSINESS_REFERRAL_CREDIT = 'BUSINESS_REFERRAL_CREDIT',
  CONVERSION_FEE = 'CONVERSION_FEE',
  DIRECT_HIRE_PLACEMENT_FEE = 'DIRECT_HIRE_PLACEMENT_FEE',
  MISC_CREDIT = 'MISC_CREDIT',
  MISC_FEE = 'MISC_FEE',
}

const LINE_ITEM_MAPPING: Record<
  ManualLineItemOption,
  {
    label: string
    description: string
    lineItemType: LineItemType
    category: LineItemCategory
  }
> = {
  [ManualLineItemOption.BONUS_PAY]: {
    label: 'Bonus Pay',
    description: 'Bonus payments to workers to be paid by the business',
    lineItemType: LineItemType.BONUS_PAY,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.WORKER_REIMBURSEMENT]: {
    label: 'Worker Reimbursement',
    description: 'Non-bonus payments to workers to be paid by the business',
    lineItemType: LineItemType.WORKER_REIMBURSEMENT_FEE,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.TRANSPORTATION_FEE]: {
    label: 'Transportation Fee',
    description: 'Transportation related payment to be paid by the business',
    lineItemType: LineItemType.TRANSPORTATION_FEE,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.TRABA_REIMBURSEMENT]: {
    label: 'Traba Reimbursement',
    description: 'Reimbursement to Traba to be paid by the business',
    lineItemType: LineItemType.TRABA_REIMBURSEMENT_FEE,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.WORKER_QUALITY_CREDIT]: {
    label: 'Worker Quality Credit',
    description: 'Credit due to worker quality issues',
    lineItemType: LineItemType.WORKER_QUALITY_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.WORKER_DAMAGE_CREDIT]: {
    label: 'Worker Damage Credit',
    description: 'Credit for damages caused by worker',
    lineItemType: LineItemType.WORKER_DAMAGE_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.TRAINING_CREDIT]: {
    label: 'Training Credit',
    description: 'Credit for worker training',
    lineItemType: LineItemType.TRAINING_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.LATE_CANCEL_CREDIT]: {
    label: 'Late Cancel Credit',
    description: 'Credit for late cancellation',
    lineItemType: LineItemType.LATE_CANCEL_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.OVERBOOK_CREDIT]: {
    label: 'Overbook Credit',
    description: 'Credit for overbooking',
    lineItemType: LineItemType.OVERBOOK_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.MARKUP_CREDIT]: {
    label: 'Markup Credit',
    description: 'Credit on markup fees',
    lineItemType: LineItemType.MARKUP_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.BUSINESS_REFERRAL_CREDIT]: {
    label: 'Business Referral Credit',
    description: 'Credit for business referral',
    lineItemType: LineItemType.BUSINESS_REFERRAL_CREDIT,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.CONVERSION_FEE]: {
    label: 'Conversion Fee',
    description: 'Fee for early worker conversion',
    lineItemType: LineItemType.CONVERSION_FEE,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.DIRECT_HIRE_PLACEMENT_FEE]: {
    label: 'Direct Hire Placement Fee',
    description: 'Fee for direct hire placement',
    lineItemType: LineItemType.DIRECT_HIRE_PLACEMENT_FEE,
    category: LineItemCategory.DEBIT,
  },
  [ManualLineItemOption.MISC_CREDIT]: {
    label: 'Miscellaneous Credit',
    description: 'Other credit that does not fit into existing categories',
    lineItemType: LineItemType.BONUS,
    category: LineItemCategory.CREDIT,
  },
  [ManualLineItemOption.MISC_FEE]: {
    label: 'Miscellaneous Fee',
    description: 'Other fee that does not fit into existing categories',
    lineItemType: LineItemType.PENALTY,
    category: LineItemCategory.DEBIT,
  },
}

export type AddOrEditLineItemModalProps = {
  isOpen: boolean
  invoiceId: string
  lineItem?: InvoiceDetailsBodyLineItem
  handleClose: () => void
  refetch: () => void
}

export default function AddOrEditLineItemModal(
  props: AddOrEditLineItemModalProps,
) {
  const { invoiceId, isOpen, lineItem, refetch } = props

  const { showError } = useAlert()

  const { addLineItemToInvoice, editLineItemOnInvoice } =
    useInvoiceMutations(invoiceId)

  const creating = !lineItem
  const [description, setDescription] = useState<string | undefined>(
    lineItem?.stripeDescription,
  )
  useEffect(() => {
    setDescription(lineItem?.stripeDescription)
  }, [lineItem?.stripeDescription])

  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [category, setCategory] = useState<LineItemCategory>(
    LineItemCategory.CREDIT,
  )
  const [lineItemType, setLineItemType] = useState<
    ManualLineItemOption | undefined
  >(undefined)
  const [date, setDate] = useState<Date | undefined>()
  const [shiftId, setShiftId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const createDisabled =
    !description || amount === undefined || !lineItemType || !shiftId || !date

  const addLineItem = async (lineItem: CreateLineItemDto) => {
    await addLineItemToInvoice(lineItem, {
      onSettled: async () => {
        await refetch()
      },
      onError: (error) => {
        showError(error.message, 'Failed to add line item to invoice')
      },
    })
  }

  const editLineItem = async (updates: EditLineItemDto) => {
    await editLineItemOnInvoice(
      { updates: [updates] },
      {
        onSettled: async () => {
          await refetch()
        },
        onError: (error) => {
          showError(error.message, 'Failed to edit line item on invoice')
        },
      },
    )
  }

  const handleAdd = async () => {
    if (createDisabled) {
      return
    }

    setIsLoading(true)
    try {
      const chargeAmountCents = Math.round(amount * 100)
      const mapping = LINE_ITEM_MAPPING[lineItemType]

      const { lineItemType: actualLineItemType } = mapping

      const finalAmount =
        mapping.category === LineItemCategory.CREDIT
          ? -chargeAmountCents
          : chargeAmountCents

      await addLineItem({
        description,
        stripeDescription: description,
        date,
        type: actualLineItemType,
        shiftId,
        chargeToBusiness: {
          amount: finalAmount,
          currency: 'USD',
        },
      })

      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  const hasChanges = description !== lineItem?.stripeDescription

  const handleEdit = async () => {
    if (!hasChanges || !lineItem?.id) {
      return
    }

    setIsLoading(true)
    try {
      await editLineItem({
        id: lineItem.id,
        stripeDescription: description,
        description,
      })

      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setDescription(lineItem?.stripeDescription)
    setAmount(undefined)
    setCategory(LineItemCategory.CREDIT)
    setLineItemType(undefined)
    setDate(undefined)
    setShiftId(undefined)
    props.handleClose()
  }

  return (
    <Modal
      title={creating ? 'Add line item' : 'Edit line item'}
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.MEDIUM}
    >
      <Col gap={theme.space.xs}>
        {creating && (
          <>
            <Col gap={theme.space.xxs}>
              <Text variant="h6">Category</Text>
              <RadioGroup
                value={category}
                row={true}
                onChange={(event) => {
                  const newCategory = event.target.value as LineItemCategory
                  if (newCategory !== category) {
                    setLineItemType(undefined)
                  }
                  setCategory(newCategory)
                }}
                options={[
                  {
                    label: <Text variant="body1">Credit</Text>,
                    value: LineItemCategory.CREDIT,
                  },
                  {
                    label: <Text variant="body1">Debit</Text>,
                    value: LineItemCategory.DEBIT,
                  },
                ]}
              />
            </Col>

            <Col gap={theme.space.xxs}>
              <Text variant="h6">Type</Text>
              <SelectDropdown
                value={lineItemType || ''}
                handleSelect={(value) =>
                  setLineItemType(value as ManualLineItemOption)
                }
                menuItems={Object.entries(LINE_ITEM_MAPPING)
                  .filter(
                    ([_, { category: lineItemCategory }]) =>
                      lineItemCategory === category,
                  )
                  .map(([value, { label, description }]) => ({
                    value,
                    label,
                    subtitle: description,
                  }))}
                style={{ width: '100%' }}
              />
            </Col>

            <Col gap={theme.space.xxs}>
              <Text variant="h6">Amount</Text>
              <Text variant="body3" color="textSecondary">
                This value should always be a positive number.
              </Text>
              {/* Intentionally using TextField instead of Input so we can use the inputProps to limit the input to 2 decimal places */}
              <TextField
                value={amount}
                onChange={(ev) => {
                  const value = ev.target.value
                  // Limit to 2 decimal places
                  if (/^\d*\.?\d{0,2}$/.test(value)) {
                    setAmount(value === '' ? undefined : parseFloat(value))
                  }
                }}
                type="number"
                inputProps={{
                  step: '0.01',
                  min: '0',
                  pattern: '^d*.?d{0,2}$',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
                style={{ width: '200px' }}
              />
            </Col>
          </>
        )}

        <Col gap={theme.space.xxs}>
          <Text variant="h6">Description</Text>
          <Input
            full
            rows={2}
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            containerStyle={{ marginTop: '0px' }}
          />
        </Col>

        {creating && (
          <>
            <Col gap={theme.space.xxs}>
              <Text variant="h6">Service Date</Text>
              <Text variant="body3" color="textSecondary">
                Please select the date the service was provided
              </Text>
              <DatePicker
                showTimeFieldInPopover={false}
                granularity={'day'}
                setDate={(date) => date && setDate(date)}
                isClearable={false}
                inlineLabel={false}
                date={date}
                width={200}
                timezone={getLocalTimezone()}
              />
            </Col>

            <Col gap={theme.space.xxs}>
              <Text variant="h6">Shift ID</Text>
              <Input
                width={'350px'}
                value={shiftId}
                onChange={(e) => setShiftId(e.target.value)}
                containerStyle={{ marginTop: '0px' }}
              />
            </Col>
          </>
        )}

        <Row justifyEnd>
          <Button
            slim
            variant={ButtonVariant.OUTLINED}
            style={{ width: '100px', marginRight: theme.space.xs }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            slim
            style={{ width: '100px' }}
            onClick={creating ? handleAdd : handleEdit}
            disabled={(creating ? createDisabled : !hasChanges) || isLoading}
            loading={isLoading}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
