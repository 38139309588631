import { createCalendar } from '@internationalized/date'
import { AriaRangeCalendarProps, useRangeCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useRangeCalendarState } from '@react-stately/calendar'
import { DateValue } from '@react-types/datepicker'
import { useRef } from 'react'

import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'
import { CalendarButton } from './Button'
import { CalendarGrid } from './CalendarGrid'

interface RangeCalendarProps extends AriaRangeCalendarProps<DateValue> {
  timezone: string
}

export function RangeCalendar(props: RangeCalendarProps) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref)

  return (
    <div {...calendarProps} ref={ref}>
      <Row center alignCenter pb={8} justifyBetween>
        <Text variant="h5">{title}</Text>
        <Row gap={8}>
          <CalendarButton {...prevButtonProps}>
            <SvgIcon name="chevronLeft" width={15} height={15} />
          </CalendarButton>
          <CalendarButton {...nextButtonProps}>
            <SvgIcon name="chevronRight" width={15} height={15} />
          </CalendarButton>
        </Row>
      </Row>
      <CalendarGrid state={state} timezone={props.timezone} />
    </div>
  )
}
