import { trabaApi } from '@traba/api-utils'
import { WaitlistWithBasicWorkerOpsDetails } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

async function getWaitlistByShiftId(
  shiftId: string,
): Promise<WaitlistWithBasicWorkerOpsDetails[] | undefined> {
  try {
    const res = await trabaApi.get(`shifts/${shiftId}/waitlist`)
    return res.data
  } catch (error) {
    console.error('useWaitlist -> getWaitlistByShiftId() ERROR', error)
    captureSentryError(error)
  }
}

export function useWaitlist(shiftId: string) {
  const {
    isLoading,
    isError,
    data: waitlistWorkers,
    error,
    isFetched,
    refetch,
  } = useQuery<WaitlistWithBasicWorkerOpsDetails[] | undefined, Error>(
    ['Waitlist', { shiftId }],
    () => getWaitlistByShiftId(shiftId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const queryClient = useQueryClient()

  const removeFromWaitlistMutation = useMutation(
    (workerId: string) =>
      trabaApi.delete(`worker-shifts/${workerId}/waitlist/${shiftId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['Waitlist', { shiftId }])
      },
    },
  )

  return {
    isLoading,
    isError,
    waitlistWorkers,
    error,
    isFetched,
    refetch,
    removeFromWaitlist: removeFromWaitlistMutation.mutate,
  }
}
