import { trabaApi } from '@traba/api-utils'
import { Waitlist } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getQueryParams } from './useApi'
import { getShiftsByIds } from './useShifts'

async function getWaitlistByWorkerId(
  workerId: string,
  minStartTime: string,
  maxStartTime: string,
): Promise<Waitlist[] | undefined> {
  const queryString = getQueryParams([
    ['minStartTime', minStartTime],
    ['maxStartTime', maxStartTime],
    ['workerId', workerId],
  ])
  try {
    const res = await trabaApi.get(`workers/${workerId}/waitlist${queryString}`)
    return res.data
  } catch (error) {
    console.error('useWaitlist -> getWaitlistByWorkerId() ERROR', error)
    captureSentryError(error)
  }
}

export function useWorkersWaitlists(
  workerId: string,
  dateRange: [Date | null, Date | null],
) {
  const {
    isLoading: isWaitlistsLoading,
    isError: isWaitlistsError,
    data: workerWaitlists,
    error: waitlistsError,
  } = useQuery<Waitlist[] | undefined, Error>(
    [
      'worker_waitlists',
      {
        workerId,
        dateRange,
      },
    ],
    () =>
      getWaitlistByWorkerId(
        workerId,
        dateRange[0]?.toISOString() ?? '',
        dateRange[1]?.toISOString() ?? '',
      ),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const {
    data: shifts,
    isLoading: isShiftsLoading,
    error: shiftsError,
  } = useQuery<OpsExtendedShift[] | undefined, Error>(
    ['worker_waitlists_shifts', { workerId, dateRange }],
    async () => {
      if (workerWaitlists) {
        const shiftIds = workerWaitlists.map((waitlist) => waitlist.shiftId)
        const fetchedShifts = await getShiftsByIds(shiftIds)
        return fetchedShifts?.filter((shift) => shift.status === 'ACTIVE')
      }
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: !!workerWaitlists,
    },
  )

  const isLoading = isWaitlistsLoading || isShiftsLoading
  const isError = isWaitlistsError || !!waitlistsError || !!shiftsError
  const error = waitlistsError || shiftsError

  return {
    isLoading,
    isError,
    workerWaitlists,
    shifts,
    error,
  }
}
