import { useLocations } from '@traba/hooks'
import {
  ApplicationItemType,
  LocationResponse,
  RecordStatus,
  Role,
} from '@traba/types'
import { SetStateAction } from 'react'
import { VettingContextForm } from 'src/components/VettingContextForm/VettingContextForm'
import { useActiveVettingPromptContext } from 'src/hooks/useActiveVettingPromptContext'
import { useRoles } from 'src/hooks/useRoles'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { useVettingCampaigns } from 'src/hooks/useVettingCampaigns'
import { getMoneyString } from 'src/utils/stringUtils'
import {
  buildVettingConfigInitialValues,
  getMostRecentWorkerVettingCampaign,
  INITIAL_VETTING_CONTEXT_FORM_VALUES,
} from 'src/utils/vettingCampaignUtils'

type PostShiftVettingContextFormProps = {
  shiftRequest: CreateShiftRequest
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  index: number
}

function buildInitialMessage({
  role,
  location,
  payRatePlainText,
}: {
  role?: Role
  location?: LocationResponse
  payRatePlainText: string
}) {
  if (!role || !role.roleName) {
    return ''
  }
  const loc = location?.address.city || location?.regionId
  const locString = loc ? `in ${loc}` : ''
  return `Earn ${payRatePlainText}/hr as a ${role.roleName} ${locString}`
}

export function PostShiftVettingContextForm(
  props: PostShiftVettingContextFormProps,
) {
  const { shiftRequest, setCreateShiftRequests, index } = props
  // TODO(gavin): fix this to only use the hook once
  const { roles: activeRoles = [], isLoading: isLoadingActiveRoles } = useRoles(
    {
      companyId: shiftRequest.companyId,
      recordStatus: RecordStatus.ACTIVE,
    },
  )
  const { roles: archivedRoles = [], isLoading: isLoadingArchivedRoles } =
    useRoles({
      companyId: shiftRequest.companyId,
      recordStatus: RecordStatus.ARCHIVED,
    })
  const { locations, isLoading: isLoadingLocations } = useLocations(
    shiftRequest.companyId,
  )
  const { promptContext, isLoading: isLoadingPromptContext } =
    useActiveVettingPromptContext({
      roleId: shiftRequest.roleId,
    })
  const { vettingCampaigns, isLoading: isLoadingVettingCampaigns } =
    useVettingCampaigns({
      roleIds: [shiftRequest.roleId],
    })

  if (
    isLoadingActiveRoles ||
    isLoadingArchivedRoles ||
    isLoadingLocations ||
    isLoadingPromptContext ||
    isLoadingVettingCampaigns
  ) {
    return null
  }

  // Server returns vetting campaigns in sorted order. Use the most recent campaign's worker vetting campaigns.
  const workerVettingCampaign = vettingCampaigns?.length
    ? getMostRecentWorkerVettingCampaign(
        vettingCampaigns[0].workerVettingCampaigns,
      )
    : undefined

  const payRatePlainText = getMoneyString(shiftRequest.payRate)
  const roles = [...activeRoles, ...archivedRoles]
  const role = roles.find((r) => r.roleId === shiftRequest.roleId)
  const location = locations.find(
    (l) => l.locationId === shiftRequest.locationId,
  )

  const vettingLogistics = promptContext?.context ?? {
    jobTitle: role?.roleName || '',
    roleDetails: role?.roleDescription || '',
    payRatePlainText,
    locationDetails: location?.locationInstructions || '',
    scheduleDetails: '',
  }

  const initialValues = workerVettingCampaign
    ? buildVettingConfigInitialValues({
        workerVettingCampaign,
        promptContext: promptContext?.context,
      })
    : {
        ...INITIAL_VETTING_CONTEXT_FORM_VALUES,
        initialMessage: buildInitialMessage({
          role,
          location,
          payRatePlainText,
        }),
        vettingLogistics,
      }

  return (
    <VettingContextForm
      onSave={(data) => {
        setCreateShiftRequests((prev) => {
          const newCreateShiftRequests = [...prev]
          newCreateShiftRequests[index] = {
            ...newCreateShiftRequests[index],
            application: {
              applicationItems: [
                {
                  applicationItemType: ApplicationItemType.AI_VETTING_CALL,
                  itemContent: {
                    vettingConfig: data,
                  },
                },
              ],
            },
          }
          return newCreateShiftRequests
        })
      }}
      loading={false}
      includeSMSVetting={false}
      initialValues={initialValues}
    />
  )
}
