import { Row, Text, TextVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ApplicationItemType } from '@traba/types'

interface ApplicationItemNumberAndTitleProps {
  stepNumber: number
  applicationItemType: ApplicationItemType
  titleVariant?: TextVariant
}

export const APPLICATION_ITEM_TYPE_TO_TEXT: Record<
  ApplicationItemType,
  string
> = {
  [ApplicationItemType.AI_VETTING_CALL]: 'AI Vetting Call',
  [ApplicationItemType.BGC]: 'Background Check',
  [ApplicationItemType.DRUG_SCREENING]: 'Drug Screening',
}

export function ApplicationItemNumberAndTitle({
  applicationItemType,
  stepNumber,
  titleVariant,
}: ApplicationItemNumberAndTitleProps) {
  const text =
    APPLICATION_ITEM_TYPE_TO_TEXT[applicationItemType] || 'View Results'

  return (
    <Row gap={theme.space.xsmed}>
      <Row
        alignCenter
        justifyCenter
        style={{
          backgroundColor: theme.colors.brand,
          borderRadius: '100%',
          width: '24px',
          height: '24px',
        }}
      >
        <Text variant="h6" color={theme.colors.White} p={theme.space.sm}>
          {stepNumber}
        </Text>
      </Row>
      <Text variant={titleVariant} p={theme.space.sm}>
        {text}
      </Text>
    </Row>
  )
}
