import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InternalUser,
  NotificationSettings,
  SentinelNotification,
} from '@traba/types'
import React from 'react'
import { sentinelNotificationIncludesNotificationToUser } from 'src/utils/sentinelNotificationUtils'
import { Row } from '../../base'
import { SentinelActions } from './SentinelActions'
import { SentinelNotificationRow } from './SentinelNotificationRow'

interface SentinelNotificationsListProps {
  notifications: SentinelNotification[]
  selectedNotificationId: string
  setSelectedNotificationId: (notificationId: string) => void
  inlineActions?: boolean
  shiftNotificationSettings?: NotificationSettings // TODO(joey): make non optional
  userNotificationSettings: NotificationSettings
  internalUser: InternalUser
  shiftId: string
}

export const SentinelNotificationsList = (
  props: SentinelNotificationsListProps,
) => {
  const {
    notifications,
    selectedNotificationId,
    setSelectedNotificationId,
    inlineActions,
    shiftNotificationSettings,
    userNotificationSettings,
    internalUser,
    shiftId,
  } = props

  // Filter out dismissed notifications for current user
  const filteredNotifications = notifications.filter((notification) =>
    sentinelNotificationIncludesNotificationToUser(
      notification,
      internalUser.id,
    ),
  )

  const onRowClick = (sentinelNotificationId: string) => {
    setSelectedNotificationId(sentinelNotificationId)
    const sentinelNotificationType = notifications.find(
      (notification) => notification.id === sentinelNotificationId,
    )?.eventType
    window.analytics.track('Sentinel Notification Clicked', {
      sentinelNotificationId,
      email: internalUser.email,
      sentinelNotificationType,
    })
  }

  if (notifications.length === 0) {
    return (
      <Row>
        <Text variant="body1" mx={theme.space.sm} my={theme.space.sm}>
          No sentinel notifications
        </Text>
      </Row>
    )
  }
  return (
    <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
      {filteredNotifications.map((notification: SentinelNotification) => {
        return (
          <Row
            style={{ ...(inlineActions && { flexWrap: 'wrap' }) }}
            key={notification.id}
          >
            <SentinelNotificationRow
              sentinelNotification={notification}
              inlineActions={inlineActions}
              onClickRow={() => onRowClick(notification.id)}
              selected={notification.id === selectedNotificationId}
              key={notification.id}
              shiftNotificationSettings={shiftNotificationSettings}
              userNotificationSettings={userNotificationSettings}
              shiftId={shiftId}
              internalUser={internalUser}
            />
            {inlineActions && notification.id === selectedNotificationId && (
              <div key={notification.sentinelNotificationToUser.id}>
                <SentinelActions
                  loading={false}
                  notification={notification}
                  inlineActions
                  key={notification.sentinelNotificationToUser.id}
                />
              </div>
            )}
          </Row>
        )
      })}
    </div>
  )
}
