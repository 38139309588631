import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreditNote, InvoiceStatus, LineItemType } from '@traba/types'
import { Money } from '@traba/types'
import { useMemo } from 'react'
import { Row } from 'src/components/base'
import { DataTableHeader } from 'src/components/base/Table/DataTable'
import { Table, Td, Tr } from 'src/components/base/Table/Table'
import { getMoneyString } from 'src/utils/stringUtils'
import CreditNotes from '../InvoicesDetailsScreen/components/CreditNotes'
import LineItem from './LineItem'

export type InvoiceDetailsBodyLineItem = {
  id?: string
  type: LineItemType
  stripeItemId?: string
  stripeDescription: string
  description: string
  chargeToBusiness: Money
  shiftId?: string
}

export type InvoiceLineItemsTableProps = {
  lineItemsEditable: boolean
  lineItems: InvoiceDetailsBodyLineItem[]
  totalCharge: Money
  handleDeleteLineItem: (id: string) => void
  handleEditLineItem: (index: number) => void
  hideInternalOnlyLineItems: boolean
  creditNotes?: CreditNote[]
  refetch?: () => void
  invoiceStatus?: InvoiceStatus
}

export default function InvoiceLineItemsTable(
  props: InvoiceLineItemsTableProps,
) {
  const headers: DataTableHeader[] = [
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'totalCharge',
      label: 'Total Charge',
      sortable: false,
    },
    {
      key: 'actions',
      sortable: false,
    },
  ]

  const { totalCredits } = useMemo(() => {
    let totalCredits = 0
    props.creditNotes?.forEach((cn) => {
      cn.lineItems.forEach((creditLineItem) => {
        totalCredits += creditLineItem.chargeToBusiness.amount
      })
    })

    return { totalCredits }
  }, [props.creditNotes])

  const lineItemRows = useMemo(() => {
    return props.lineItems
      .filter(
        (lineItem) =>
          !props.hideInternalOnlyLineItems ||
          (lineItem.chargeToBusiness.amount !== 0 && lineItem.stripeItemId),
      )
      .map((lineItem: InvoiceDetailsBodyLineItem, index: number) => {
        // key is index for each line item in constructLineItemRow
        return (
          <LineItem
            key={index.toString()}
            lineItem={lineItem}
            index={index}
            handleDeleteLineItem={props.handleDeleteLineItem}
            handleEditLineItem={props.handleEditLineItem}
            lineItemsEditable={props.lineItemsEditable}
          />
        )
      })
  }, [props])

  return (
    <div style={{ maxWidth: '60em' }}>
      <Table headers={headers}>
        {lineItemRows}
        <Tr>
          <Td
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              paddingRight: theme.space.xs,
            }}
          >
            Invoice Subtotal Amount Due
          </Td>
          <Td style={{ fontWeight: 'bold' }}>
            {getMoneyString((props.totalCharge.amount - totalCredits) / 100)}
          </Td>
        </Tr>
      </Table>
      {/* Credit Notes */}
      <CreditNotes
        creditNotes={props.creditNotes ?? []}
        refetch={props.refetch}
        invoiceStatus={props.invoiceStatus}
      />

      <Row
        p={theme.space.xs}
        justifyEnd
        alignCenter
        style={{
          backgroundColor: theme.colors.Violet10,
          borderRadius: '15px',
        }}
      >
        <Text variant="h5">Final Due: {getMoneyString(props.totalCharge)}</Text>
      </Row>
    </div>
  )
}
