import {
  Input,
  LoadingSpinner,
  Row,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  isShiftRequestParentInactive,
  shiftRequestSearchText,
} from '@traba/utils'
import { useMemo, useState } from 'react'

import { ScheduleInfoCard } from 'src/components/Schedule/ScheduleInfoCard'
import { useSchedulesFeatureFlag } from 'src/hooks/useSchedulesFeatureFlag'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'

interface Props {
  companyId: string
}

export const CompanySchedulesTab = ({ companyId }: Props) => {
  const { isSchedulesEnabled } = useSchedulesFeatureFlag(companyId)
  const [showInactiveSchedules, setShowInactiveSchedules] = useState(false)
  const { isLoading, shiftRequestParents } = useShiftRequestParents({
    companyId,
  })
  const [searchFilter, setSearchFilter] = useState<string>('')

  const filteredSchedules = useMemo(
    () =>
      shiftRequestParents
        ?.filter((srp) => {
          const lowerCaseFilter = searchFilter.toLowerCase()
          const concat = `${srp.title} ${srp.shiftRequests.map(shiftRequestSearchText).join(' ')}`
          return concat.toLowerCase().includes(lowerCaseFilter)
        })
        ?.filter(
          (srp) => showInactiveSchedules || !isShiftRequestParentInactive(srp),
        ),
    [shiftRequestParents, searchFilter, showInactiveSchedules],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h4">Schedules ({filteredSchedules?.length})</Text>
        <Input
          placeholder="Search schedules"
          leftIconName="search"
          name="scheduleSearch"
          type="text"
          defaultValue=""
          containerStyle={{ marginTop: theme.space.zero }}
          width={'65%'}
          value={searchFilter}
          onChange={(e) => {
            e.preventDefault()
            setSearchFilter(e.target.value)
          }}
          onClear={() => setSearchFilter('')}
        />
        <Toggle
          label="Show inactive schedules"
          buttonState={showInactiveSchedules}
          runOnChange={() => setShowInactiveSchedules((s) => !s)}
        />
      </Row>
      {!isSchedulesEnabled && (
        <Text variant="h5" mb={theme.space.xs} color={theme.colors.red}>
          This company cannot view schedules on the biz app, and any existing
          schedules will need to be migrated to the new system before the biz
          can see them. Refer to the onboarding guide in #operations for
          details.
        </Text>
      )}
      {filteredSchedules?.map((srp, index) => (
        <ScheduleInfoCard
          shiftRequestParent={srp}
          key={`${srp.shiftRequestParentId}_${index}`}
        />
      ))}
    </>
  )
}
