import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { IconName, StripeStatusForWatchlist } from '@traba/types'
import { Row } from 'src/components/base'

type StripeStatusIndicatorProps = {
  stripeStatus: StripeStatusForWatchlist
}

const statusConfig = {
  [StripeStatusForWatchlist.UP_TO_DATE]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
  [StripeStatusForWatchlist.NO_ACCOUNT]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'No Account',
  },
  [StripeStatusForWatchlist.PAYOUTS_DISABLED]: {
    iconName: 'cancelFilled' as IconName,
    color: theme.colors.Red60,
    text: 'Disabled',
  },
  [StripeStatusForWatchlist.PAYOUTS_DISABLED_SOON]: {
    iconName: 'check' as IconName,
    color: theme.colors.Green80,
    text: '',
  },
}

export function StripeStatusIndicator({
  stripeStatus,
}: StripeStatusIndicatorProps) {
  const { iconName, color, text } =
    statusConfig[stripeStatus] ||
    statusConfig[StripeStatusForWatchlist.NO_ACCOUNT]

  return (
    <Row style={{ gap: theme.space.xxs }}>
      <SvgIcon name={iconName} size={24} color={color} />
      {text && <Text variant="h6">{text}</Text>}
    </Row>
  )
}
