import { useMutation } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { getErrorMessage } from 'src/utils/errorUtils'

export function useResumeBulkUpload(userId: string) {
  const { handleUpload } = useFileUploader()
  const { showError, showSuccess } = useAlert()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ zipFile }: { zipFile: File }) => {
      const zipUrl = await handleUpload({
        fileType: FileType.RESUMES_ZIP,
        media: zipFile,
        userId,
        skipResize: true,
      })

      if (!zipUrl) {
        throw new Error('ZIP upload failed - no URL returned')
      }

      return { zipUrl }
    },
    onSuccess: () => {
      showSuccess('ZIP file uploaded to storage successfully')
    },
    onError: (error) => showError(getErrorMessage(error), 'Upload Failed'),
  })

  return {
    uploadZip: mutateAsync,
    isPending,
  }
}
