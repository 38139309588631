export enum AttributeBadgeMenuOption {
  VERIFY = 'VERIFY',
  CONFIRM = 'CONFIRM',
  WORKER_REPORTED = 'WORKER_REPORTED',
  UNREPORTED = 'UNREPORTED',
  DESELECT = 'DESELECTED',
  REVOKE = 'REVOKE',
  EXPERT = 'EXPERT',
  COMPETENT = 'COMPETENT',
  BEGINNER = 'BEGINNER',
  FIVE_STAR = 'FIVE_STAR',
  FOUR_STAR = 'FOUR_STAR',
  THREE_STAR = 'THREE_STAR',
  TWO_STAR = 'TWO_STAR',
  ONE_STAR = 'ONE_STAR',
}
