import { Badge } from '@traba/react-components'
import { WorkerApplicationItemStatus } from '@traba/types'

export type WorkerApplicationItemStatusBadgeProps = {
  itemStatus: WorkerApplicationItemStatus
  style?: React.CSSProperties
}

const APPLICATION_ITEM_STATUS_TO_BADGE_VARIANT: Record<
  WorkerApplicationItemStatus,
  string
> = {
  [WorkerApplicationItemStatus.COMPLETED]: 'success',
  [WorkerApplicationItemStatus.IN_PROGRESS]: 'yellow',
  [WorkerApplicationItemStatus.NOT_STARTED]: 'disabled',
  [WorkerApplicationItemStatus.REVIEW_REQUIRED]: 'danger',
  [WorkerApplicationItemStatus.SYSTEM_PROCESSING]: 'info',
  [WorkerApplicationItemStatus.CANCELED]: 'disabled',
}

const CUSTOM_STATUS_TO_TEXT: Record<WorkerApplicationItemStatus, string> = {
  [WorkerApplicationItemStatus.NOT_STARTED]: 'To Do',
  [WorkerApplicationItemStatus.IN_PROGRESS]: 'In Progress',
  [WorkerApplicationItemStatus.SYSTEM_PROCESSING]: 'Processing',
  [WorkerApplicationItemStatus.REVIEW_REQUIRED]: 'Needs Action',
  [WorkerApplicationItemStatus.COMPLETED]: 'Completed',
  [WorkerApplicationItemStatus.CANCELED]: 'Canceled',
}

export default function WorkerApplicationItemStatusBadge({
  itemStatus,

  style,
}: WorkerApplicationItemStatusBadgeProps) {
  const badgeVariant = APPLICATION_ITEM_STATUS_TO_BADGE_VARIANT[itemStatus]

  return (
    <Badge
      style={style}
      title={CUSTOM_STATUS_TO_TEXT[itemStatus]}
      variant={badgeVariant}
    />
  )
}
