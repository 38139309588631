import {
  Modal,
  Button,
  Text,
  Col,
  Row,
  ButtonVariant,
  MODAL_SIZE,
  RoleSearchSelect,
  Table,
  Tr,
  Td,
  LoadingSpinner,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Connection } from '@traba/types'
import { truncateString } from '@traba/utils'
import React, { useCallback, useState } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompanyConnectionsMutations } from 'src/hooks/useConnections'
import { useRoles } from 'src/hooks/useRoles'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'

type EditRolesOnConnectionContentProps = {
  connection: Connection
  isOpen: boolean
  onClose: () => void
}

export function EditRolesOnConnectionContent(
  props: EditRolesOnConnectionContentProps,
) {
  const { connection, onClose, isOpen } = props

  const { state } = useUserContext()
  const { updateConnectionRolesAsync, isUpdatingConnectionRoles } =
    useCompanyConnectionsMutations()
  const { roles = [], isLoading: isLoadingRoles } = useRoles({
    companyId: connection.companyId,
  })

  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>(
    connection.roles?.map(
      (role) => role.role.activeRoleId ?? role.role.roleId,
    ) || [],
  )
  const isAllSelected = selectedRoleIds.length === roles.length

  const handleConfirm = useCallback(async () => {
    await updateConnectionRolesAsync({
      connectionId: connection.id,
      companyId: connection.companyId,
      workerId: connection.workerId,
      roleIds: selectedRoleIds,
      initiatedBy: state.userProfile?.email || 'OPS',
    })
    onClose()
  }, [
    connection,
    selectedRoleIds,
    state.userProfile?.email,
    updateConnectionRolesAsync,
    onClose,
  ])

  if (isLoadingRoles) {
    return <LoadingSpinner />
  }

  return (
    <Modal isOpen={isOpen} handleClose={onClose} size={MODAL_SIZE.LARGE}>
      <Col gap={theme.space.med}>
        <Col>
          <Text variant="h5">{`Edit roles on connection`}</Text>
          <Text variant="body2">{`Worker ID: ${connection.workerId}`}</Text>
          <Text variant="body2">{`Company ID: ${connection.companyId}`}</Text>
          <Text variant="body2">{`Connection Type: ${connection.connectionType}`}</Text>
          <Row my={theme.space.sm}>
            <Table headers={['Role Name', 'Role ID', 'Created At', 'Added By']}>
              {connection.roles?.map((connectionRole) => {
                const { role } = connectionRole
                return (
                  <Tr key={role.roleId}>
                    <Td>
                      <Text variant="body1">{role.roleName}</Text>
                    </Td>
                    <Td>
                      <Row>
                        <Text variant="body1">
                          {truncateString(role.roleId, 6)}
                        </Text>
                        <CopyTextIcon textToCopy={role.roleId} />
                      </Row>
                    </Td>
                    <Td>
                      <Text variant="body1">
                        {formatDateTimeWithTimezone(connectionRole.createdAt)}
                      </Text>
                    </Td>
                    <Td>
                      <Text variant="body1">
                        {connectionRole.initiatedBy || 'OPS'}
                      </Text>
                    </Td>
                  </Tr>
                )
              })}
            </Table>
          </Row>
        </Col>
        <Col>
          <Row alignCenter>
            <RoleSearchSelect
              roles={roles}
              selectedRoleIds={selectedRoleIds}
              multiple={true}
              handleSelectRoleIds={setSelectedRoleIds}
              label="Select Role(s)"
            />
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() =>
                setSelectedRoleIds(
                  isAllSelected ? [] : roles.map((role) => role.roleId),
                )
              }
              slim
            >
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </Button>
          </Row>
          {!selectedRoleIds.length && (
            <Text variant="error">You must select at least one role</Text>
          )}
        </Col>
        <Row justifyBetween gap={theme.space.sm} mt={theme.space.med}>
          <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            loading={isUpdatingConnectionRoles}
            disabled={isLoadingRoles || !selectedRoleIds.length}
          >
            Confirm
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
