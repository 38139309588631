import { trabaApi } from '@traba/api-utils'
import {
  AddRecurringWorkerShiftResponse,
  GenericAcceptShiftBypasses,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const addWorkerToRecurringWorkerShift = async ({
  workerId,
  shiftRequestId,
  originalStartTime,
  bypasses,
}: {
  workerId: string
  shiftRequestId: string
  originalStartTime: Date
  bypasses: GenericAcceptShiftBypasses
}): Promise<AddRecurringWorkerShiftResponse> => {
  try {
    const response = await trabaApi.post(
      `recurring-worker-shifts/worker/${workerId}`,
      {
        shiftRequestId,
        originalStartTime,
        bypasses,
      },
    )
    return { ...response.data, workerId }
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}
