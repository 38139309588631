import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { InvoiceStatus, ReportColumn, ReportGroupBy } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { CUSTOM_REPORTS_ENABLED_DATE } from 'src/libs/constants'

export const CUSTOM_REPORT_QUERY_KEY = 'customReportQueryKey'

export async function getFinalizedCustomReportFromInvoiceId(
  invoiceId: string,
  invoiceStatus?: InvoiceStatus,
): Promise<string | undefined> {
  if (!invoiceStatus) {
    return undefined
  }
  try {
    const res = await trabaApi.get(`custom-reports/${invoiceId}`)
    return res.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

/*
  This hook should be used for non-draft (finalized) invoices that are created after CUSTOM_REPORTS_ENABLED_DATE.
  Invoices created before then may not have finalized reports depending on how the timing lined up with the rollout of the feature.
*/
export function useFinalizedCustomReportFromInvoiceId(
  invoiceId: string,
  invoiceStatus?: InvoiceStatus,
  invoiceCreatedAt?: Date,
) {
  const {
    isLoading,
    isError,
    data: finalizedCustomReport,
    refetch,
  } = useQuery<string | undefined, Error>({
    queryKey: [CUSTOM_REPORT_QUERY_KEY, invoiceId, invoiceStatus],
    queryFn: () =>
      getFinalizedCustomReportFromInvoiceId(invoiceId, invoiceStatus),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled:
      invoiceStatus &&
      invoiceStatus !== InvoiceStatus.DRAFT &&
      invoiceCreatedAt &&
      invoiceCreatedAt > new Date(CUSTOM_REPORTS_ENABLED_DATE),
  })
  return { isLoading, isError, finalizedCustomReport, refetch }
}

export type CreateCustomReportDto = {
  selectedCols: ReportColumn[]
  formatType: ReportGroupBy
  sumColumns: boolean
}

async function createCustomReport(
  invoiceId: string,
  customReportData: CreateCustomReportDto,
) {
  try {
    const response = await trabaApi.post(
      `custom-reports/${invoiceId}/custom-report-csv`,
      customReportData,
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}
export function useCustomReportMutations(invoiceId: string) {
  const queryClient = useQueryClient()

  const createCustomReportMutation = useMutation<
    string,
    Error,
    CreateCustomReportDto
  >({
    mutationFn: (customReportData) =>
      createCustomReport(invoiceId, customReportData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CUSTOM_REPORT_QUERY_KEY, invoiceId],
      })
    },
  })

  return {
    createCustomReport: createCustomReportMutation.mutateAsync,
  }
}
