import { useCallback } from 'react'
import { Badge } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface WorkerBadgeProps {
  worker: PopulatedWorker
  onRemove?: (worker: PopulatedWorker) => void
}

export function WorkerBadge({ worker, onRemove }: WorkerBadgeProps) {
  const handleRemoveWorker = useCallback(
    (worker: PopulatedWorker) => () => {
      if (onRemove) {
        onRemove(worker)
      }
    },
    [onRemove],
  )

  return (
    <Badge
      key={worker.id}
      style={{ textTransform: 'unset' }}
      variant="info"
      title={`${worker.firstName} ${worker.lastName} (${worker.id})`}
      onDismiss={handleRemoveWorker(worker)}
      dismissible={!!onRemove}
    />
  )
}
