import { useQueryClient } from '@tanstack/react-query'
import { THREE_WEEKS_IN_DAYS } from '@traba/consts'
import { Text } from '@traba/react-components'
import { Company, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { isRecurringShiftRequest } from '@traba/utils'
import { addDays, endOfDay, startOfToday } from 'date-fns'
import { useCallback, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { EditScheduleAddShiftRequest } from './EditSchedule/EditScheduleAddShiftRequest'
import { EditScheduleCancel } from './EditSchedule/EditScheduleCancel'
import { EditScheduleCancelShiftRequests } from './EditSchedule/EditScheduleCancelShiftRequest'
import { EditScheduleEndDate } from './EditSchedule/EditScheduleEndDate'
import {
  EDIT_OPTIONS,
  EditScheduleEntry,
} from './EditSchedule/EditScheduleEntry'
import { EditScheduleName } from './EditSchedule/EditScheduleName'
import { EditScheduleShiftRequest } from './EditSchedule/EditScheduleShiftRequest'

interface Props {
  handleClose: () => void
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  company: Company
  isOpen: boolean
}

export const EditScheduleModal = (props: Props) => {
  const { handleClose, shiftRequestParent, isOpen, company } = props
  const { shiftRequestParentId, companyId, shiftRequests } = shiftRequestParent
  const [selectedOption, setSelectedOption] = useState<
    EDIT_OPTIONS | undefined
  >()
  const { refetch: refetchParents } =
    useShiftRequestParent(shiftRequestParentId)

  const searchParams = {
    shiftRequestParentIds: [shiftRequestParentId],
    companyId,
    startAfter: startOfToday(),
    startBefore: endOfDay(addDays(new Date(), THREE_WEEKS_IN_DAYS)),
  }
  const { rawShifts, refetch } = useCompanyShifts({
    searchParams,
    queryRecurringShifts: true,
  })
  const shifts = rawShifts?.pages.map((page) => page?.data).flat()

  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    isRecurringShiftRequest,
  )

  const recurringShiftRequests = shiftRequests.filter(isRecurringShiftRequest)

  const QueryClient = useQueryClient()
  const getContent = useCallback(() => {
    if (!firstRecurringShiftRequest) {
      return <Text>No recurring shift request found in this schedule</Text>
    }

    const onClose = () => {
      setSelectedOption(undefined)
      refetch()
      refetchParents()
      handleClose()
      QueryClient.invalidateQueries({
        queryKey: ['company-shifts', companyId],
      })
    }

    switch (selectedOption) {
      case EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE:
        return (
          <EditScheduleEndDate
            timezone={shiftRequests[0].timezone}
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
          />
        )

      case EDIT_OPTIONS.MODIFY_AN_EXISTING_SHIFT_REQUEST:
        return (
          <EditScheduleShiftRequest
            shiftRequestParent={shiftRequestParent}
            company={company}
            shiftRequests={recurringShiftRequests}
            onBack={() => setSelectedOption(undefined)}
            onSuccess={onClose}
            shifts={shifts}
          />
        )

      case EDIT_OPTIONS.ADD_A_NEW_SHIFT_REQUEST:
        return (
          <EditScheduleAddShiftRequest
            shiftRequestParent={shiftRequestParent}
            company={company}
            firstRecurringShiftRequest={firstRecurringShiftRequest}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
            onSuccess={onClose}
          />
        )

      case EDIT_OPTIONS.CANCEL_SHIFT_REQUESTS:
        return (
          <EditScheduleCancelShiftRequests
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
          />
        )
      case EDIT_OPTIONS.CANCEL_SCHEDULE:
        return (
          <EditScheduleCancel
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
          />
        )
      case EDIT_OPTIONS.EDIT_NAME:
        return (
          <EditScheduleName
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
          />
        )

      default:
        return (
          <EditScheduleEntry
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )
    }
  }, [
    company,
    firstRecurringShiftRequest,
    selectedOption,
    shiftRequestParent,
    shiftRequests,
    shifts,
  ])

  return (
    <Modal
      handleClose={() => {
        setSelectedOption(undefined)
        handleClose()
      }}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={selectedOption ?? 'Edit schedule'}
    >
      {getContent()}
    </Modal>
  )
}
