import { Avatar } from '@mui/material'
import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface InvitedWorkersProps {
  workers: PopulatedWorker[]
}

export const InvitedWorkers: React.FC<InvitedWorkersProps> = (props) => {
  const { workers } = props

  return (
    <Card
      style={{
        marginBottom: theme.space.xs,
        width: 550,
        maxHeight: 410,
        overflowY: 'auto',
      }}
    >
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {workers.map((worker, index) => {
          return (
            <li
              key={`invited-workers-${worker.id}`}
              style={{
                borderBottom:
                  index !== workers.length - 1 ? '1px solid #ccc' : 'none',
                paddingBottom:
                  index === workers.length - 1 ? 0 : theme.space.sm,
                paddingTop: index === 0 ? 0 : theme.space.sm,
              }}
            >
              <Row style={{ alignItems: 'center' }}>
                <Avatar
                  alt={`worker_${worker.id}_avatar`}
                  src={worker.photoUrl}
                  sizes="45px"
                />
                <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
                  {worker.firstName} {worker.lastName}
                </Text>
              </Row>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}
