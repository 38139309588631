import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Dispatch, SetStateAction } from 'react'
import { EditScheduleOption } from './EditScheduleOption'

interface Props {
  selectedOption?: EDIT_OPTIONS
  setSelectedOption: Dispatch<SetStateAction<EDIT_OPTIONS | undefined>>
}

export enum EDIT_OPTIONS {
  EXTEND_SCHEDULE_OR_MODIFY_END_DATE = 'Extend Schedule or Modify End Date',
  MODIFY_AN_EXISTING_SHIFT_REQUEST = 'Modify an Existing Shift Request',
  ADD_A_NEW_SHIFT_REQUEST = 'Add a New Shift Request',
  CANCEL_SHIFT_REQUESTS = 'Cancel Shift Requests',
  CANCEL_SCHEDULE = 'Cancel Schedule',
  EDIT_NAME = 'Edit Schedule Name',
}

export const EditScheduleEntry = (props: Props) => {
  const { selectedOption, setSelectedOption } = props
  const onClickOption = (option: EDIT_OPTIONS) => {
    setSelectedOption(option)
  }
  return (
    <>
      <Text variant="h5" mb={theme.space.xs}>
        What actions you want to take?
      </Text>
      <Col gap={theme.space.sm}>
        <EditScheduleOption
          onClick={() =>
            onClickOption(EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE)
          }
          selected={
            selectedOption === EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE
          }
          title="Extend schedule or modify schedule end date"
        />
        <EditScheduleOption
          onClick={() =>
            onClickOption(EDIT_OPTIONS.MODIFY_AN_EXISTING_SHIFT_REQUEST)
          }
          selected={
            selectedOption === EDIT_OPTIONS.MODIFY_AN_EXISTING_SHIFT_REQUEST
          }
          title="Modify an existing shift request"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.ADD_A_NEW_SHIFT_REQUEST)}
          selected={selectedOption === EDIT_OPTIONS.ADD_A_NEW_SHIFT_REQUEST}
          title="Add a new shift request to this schedule"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.CANCEL_SHIFT_REQUESTS)}
          selected={selectedOption === EDIT_OPTIONS.CANCEL_SHIFT_REQUESTS}
          title="Cancel shift requests from this schedule"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.CANCEL_SCHEDULE)}
          selected={selectedOption === EDIT_OPTIONS.CANCEL_SCHEDULE}
          title="Cancel this schedule"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.EDIT_NAME)}
          selected={selectedOption === EDIT_OPTIONS.EDIT_NAME}
          title="Edit schedule name"
        />
      </Col>
    </>
  )
}
