import { Button, Input, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useUserContext } from '../../context/user/UserContext'
import { isProduction } from '../../utils/envUtils'

const getUrl = (workerId: string, env: string) =>
  `https://cobrowse.io/connect?filter_user_id=${workerId}&token=${import.meta.env.VITE_DD_COBROWSE_TOKEN}&environment=${env}&navigation=none`

function CodeBrowseScreen() {
  const [workerId, setWorkerId] = useState<string>('')
  const [targetUrl, setTargetUrl] = useState<string>('')
  const { state } = useUserContext()

  const handleConnect = () => {
    try {
      if (!workerId || workerId.length < 28) {
        return console.error('Invalid worker ID')
      }
      const url = getUrl(
        workerId,
        isProduction() ? 'production' : 'development',
      )
      setTargetUrl(url)
      window.analytics.track('Worker App Remote View', {
        workerId,
        internalUser: state.userProfile?.internalUser?.email,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <MainLayout title="Worker App Remote View">
      <Row justifyBetween mb={theme.space.xxs}>
        <Text variant="h4" mb={theme.space.med}>
          Connect to a remote App
        </Text>
      </Row>
      <Row justifyBetween mb={theme.space.xxs} flexCol>
        <Text variant="body1" mb={theme.space.med}>
          Use this tool to help a worker remotely. You must let the worker know
          that you can see what they see and help them remotely.
        </Text>
        <Text variant="body1" mb={theme.space.med}>
          Please ask the worker to stay in a single place ideally with decent
          connectivity.
        </Text>
        <Text variant="body1" mb={theme.space.med}>
          The worker will be asked to approve the connection in their App.
        </Text>
        <Row alignCenter>
          <Input
            onChange={(e) => setWorkerId(e.target.value)}
            placeholder="Enter Worker ID"
            width={'400px'}
            style={{ alignSelf: 'center' }}
            containerStyle={{ margin: 0 }}
          />
          <Button
            style={{ marginLeft: theme.space.xs }}
            onClick={handleConnect}
            disabled={!workerId || workerId.length < 28}
          >
            Connect
          </Button>
        </Row>
      </Row>
      <Row fullWidth justifyBetween>
        {!targetUrl && (
          <Text variant="body1" mb={theme.space.med}>
            Enter the worker ID and click connect to start the session.
          </Text>
        )}
        {targetUrl && (
          <iframe
            title="CodeBrowse Viewer"
            src={targetUrl}
            width="100%"
            height="700"
            sandbox="allow-same-origin allow-scripts allow-forms"
            referrerPolicy="no-referrer"
            aria-label="Remote worker app view"
            loading="lazy"
          />
        )}
      </Row>
    </MainLayout>
  )
}

export default CodeBrowseScreen
