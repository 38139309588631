import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { WorkerUpload } from '@traba/types'
import { captureSentryError } from '@traba/utils'

export type WorkerUploadRequest = {
  url: string
  title: string
  description: string
  fileType: string
  mimeType: string
}

export type WorkerUploadResponseWithId = {
  upload: WorkerUploadRequest
  workerId: string
}

export function useUploads(workerId: string | undefined) {
  const { showError, showSuccess } = useAlert()

  async function getUploadsForWorker(
    workerId: string | undefined,
  ): Promise<{ uploads: WorkerUpload[] } | undefined> {
    if (!workerId) {
      return undefined
    }
    try {
      const res = await trabaApi.get(`uploads/worker/${workerId}`)
      return res.data
    } catch (error) {
      console.error('useUploads -> getUploadsForWorker() ERROR', error)
    }
  }

  async function createWorkerUpload(
    upload: WorkerUploadRequest,
    workerId: string,
  ): Promise<void> {
    try {
      const res = await trabaApi.post(`uploads/worker/${workerId}`, upload)
      return res.data
    } catch (error) {
      captureSentryError(error)
      throw error
    }
  }

  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<
    { uploads: WorkerUpload[] } | undefined,
    Error
  >({
    queryKey: ['uploads', workerId || ''],
    queryFn: () => getUploadsForWorker(workerId),
    enabled: !!workerId,
  })

  const { isPending, mutateAsync } = useMutation<
    void,
    Error,
    WorkerUploadResponseWithId
  >({
    mutationFn: ({ upload, workerId }: WorkerUploadResponseWithId) =>
      createWorkerUpload(upload, workerId),
    onSuccess: () => {
      showSuccess('Upload created successfully')
      refetch()
    },
    onError: (error) => {
      showError(error.message, 'Error Creating Worker Upload')
    },
  })

  return {
    isLoading,
    isError,
    uploads: data?.uploads,
    isUploading: isPending,
    createWorkerUpload: mutateAsync,
    error,
    isFetched,
    refetch,
  }
}
