import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ONE_MINUTE_IN_MS } from '@traba/consts'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'

interface InvoiceStatement {
  downloadUrl: string
}

export const INVOICE_STATEMENT_QUERY_KEY = 'invoice_statement'

export async function getInvoiceStatement(
  invoiceId: string,
): Promise<InvoiceStatement> {
  try {
    const res = await trabaApi.get(
      `custom-reports/${invoiceId}/invoice-statement`,
    )
    return res.data
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

async function createInvoiceStatement(
  invoiceId: string,
): Promise<InvoiceStatement> {
  try {
    const res = await trabaApi.post(
      `custom-reports/${invoiceId}/invoice-statement`,
    )
    return res.data
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

export function useInvoiceStatement(invoiceId: string) {
  const { isLoading, isError, data, error, refetch } = useQuery<
    InvoiceStatement,
    AxiosError
  >({
    queryKey: [INVOICE_STATEMENT_QUERY_KEY, invoiceId],
    queryFn: () => getInvoiceStatement(invoiceId),
    staleTime: ONE_MINUTE_IN_MS,
  })

  const createInvoiceStatementMutation = useMutation<
    InvoiceStatement,
    AxiosError,
    void
  >({
    mutationFn: () => createInvoiceStatement(invoiceId),
    onSuccess: () => {
      refetch()
    },
  })

  return {
    isLoading,
    isError,
    downloadUrl: data?.downloadUrl,
    error,
    refetch,
    createInvoiceStatement: createInvoiceStatementMutation.mutateAsync,
    isCreatingInvoiceStatement: createInvoiceStatementMutation.isPending,
  }
}
