import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  COMPANY_CONNECTIONS_QUERY_KEY,
  COMPANY_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
  ELIGIBILITY_CONNECTIONS_QUERY_KEY,
  WORKER_CONNECTIONS_QUERY_KEY,
  WORKER_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
} from '@traba/hooks'
import {
  Company,
  CompanyConnectionResponse,
  Connection,
  ConnectionReason,
  ConnectionType,
  Worker,
  WorkerConnectionResponse,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useCallback } from 'react'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'
function useClearCompanyAndWorkerConnections() {
  const queryClient = useQueryClient()

  const clearCompanyAndWorkerConnections = useCallback(
    ({ companyId, workerId }: { companyId?: string; workerId?: string }) => {
      queryClient.invalidateQueries({
        queryKey: [COMPANY_CONNECTIONS_QUERY_KEY, companyId],
      })
      queryClient.invalidateQueries({
        queryKey: [WORKER_CONNECTIONS_QUERY_KEY, workerId],
      })
    },
    [queryClient],
  )

  return { clearCompanyAndWorkerConnections }
}

async function getWorkerConnections(
  workerId?: string,
  companyId?: string,
): Promise<WorkerConnectionResponse[] | undefined> {
  if (!workerId) {
    return []
  }
  const queryString = getQueryParams([['companyId', companyId]])
  const res = await trabaApi.get(
    `workers/${workerId}/connections${queryString}`,
  )
  return res.data
}

async function getWorkerEligibilityConnections(
  workerId: string,
): Promise<Company[] | undefined> {
  try {
    const res = await trabaApi.get(
      `workers/${workerId}/eligibility-connections`,
    )
    return res.data
  } catch (error) {
    console.error(
      'useWorkerEligibilityConnections -> getWorkerEligibilityConnections() ERROR',
      error,
    )
    captureSentryError(error)
  }
}

interface CreateConnectionParams {
  companyId: string
  workerId: string
  connectionType: ConnectionType
  initiatedBy?: string
  connectionReasons?: ConnectionReason[]
  note?: string
  bypassRestrictions?: boolean
}

export async function createConnection({
  companyId,
  workerId,
  connectionType,
  connectionReasons,
  note,
  initiatedBy = 'OPS',
  bypassRestrictions,
}: CreateConnectionParams): Promise<Connection> {
  try {
    const res = await trabaApi.post(`/connections/company/${companyId}`, {
      workerId,
      connectionType,
      initiatedBy,
      connectionReasons,
      note,
      bypassRestrictions,
    })
    return res.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
    throw error
  }
}

interface DeleteConnectionParams {
  workerId: string
  companyId: string
  connectionType: ConnectionType
}

export async function deleteConnection({
  workerId,
  companyId,
  connectionType,
}: DeleteConnectionParams) {
  try {
    const queryString = getQueryParams([
      ['workerId', workerId],
      ['bypassRestrictions', true],
      ['connectionType', connectionType],
    ])
    const res = await trabaApi.delete(
      `connections/company/${companyId}${queryString}`,
    )
    return res.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
    throw error
  }
}

interface UpdateConnectionRolesParams {
  connectionId: string
  companyId: string
  workerId: string
  initiatedBy?: string
  roleIds: string[]
}

export async function updateConnectionRoles({
  connectionId,
  initiatedBy = 'OPS',
  roleIds,
}: UpdateConnectionRolesParams) {
  try {
    const res = await trabaApi.patch(`/connections/roles/${connectionId}`, {
      initiatedBy,
      roleIds,
    })
    return res.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
    throw error
  }
}

export function useWorkerConnections(workerId?: string, companyId?: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerConnectionResponse[] | undefined, Error>({
    queryKey: [WORKER_CONNECTIONS_QUERY_KEY, workerId, companyId],
    queryFn: () => getWorkerConnections(workerId, companyId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useWorkerConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    captureSentryError(error)
    showError(fullErrorMessage, 'Error Fetching Worker Connections')
  }

  return {
    isLoading,
    isError,
    connections,
    error,
    isFetched,
    refetch,
  }
}

export function useWorkerEligibilityConnections(workerId: string) {
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<Company[] | undefined, Error>({
    queryKey: [
      ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      WORKER_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      workerId,
    ],
    queryFn: () => getWorkerEligibilityConnections(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    connections,
    error,
    isFetched,
    refetch,
  }
}

async function getCompanyConnections(
  companyId?: string,
): Promise<CompanyConnectionResponse[] | undefined> {
  if (!companyId) {
    return []
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}/connections`)
    return res.data
  } catch (error: any) {
    captureSentryError(error)
    throw error
  }
}

export function useCompanyConnections(companyId?: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyConnectionResponse[] | undefined, Error>({
    queryKey: [COMPANY_CONNECTIONS_QUERY_KEY, companyId],
    queryFn: () => getCompanyConnections(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    captureSentryError(error)
    showError(fullErrorMessage, 'Error Fetching Company Connections')
  }

  const favoriteWorkers =
    connections
      ?.filter((c) => c.connectionType === ConnectionType.FAVORITE)
      .map((c) => c.worker) ?? []

  return {
    isLoading,
    isError,
    connections,
    favoriteWorkers,
    error,
    isFetched,
    refetch,
  }
}

export function useCompanyConnectionsMutations() {
  const { showSuccess, showError } = useAlert()
  const { clearCompanyAndWorkerConnections } =
    useClearCompanyAndWorkerConnections()

  const createConnectionMutation = useMutation<
    Connection,
    Error,
    CreateConnectionParams
  >({
    mutationFn: createConnection,
    onSuccess: (response, variables) => {
      clearCompanyAndWorkerConnections({
        companyId: variables.companyId,
        workerId: variables.workerId,
      })

      if (response.connectionType === ConnectionType.SCHEDULED_BLOCK) {
        showSuccess(
          'The block will occur 1 hour after they clock out (or 6 hours after shift end time if they forget to clock out). They will not be informed of the block or removed from future shifts until then.',
          'Worker is currently on a shift, Block Scheduled.',
        )
      } else {
        showSuccess(
          `${response.connectionType} connection created successfully`,
        )
      }
    },
  })

  const deleteConnectionMutation = useMutation<
    Connection,
    Error,
    DeleteConnectionParams
  >({
    mutationFn: deleteConnection,
    onSuccess: (_, variables) => {
      clearCompanyAndWorkerConnections({
        companyId: variables.companyId,
        workerId: variables.workerId,
      })
      showSuccess(`${variables.connectionType} connection deleted successfully`)
    },
  })

  const updateConnectionRolesMutation = useMutation<
    Connection,
    Error,
    UpdateConnectionRolesParams
  >({
    mutationFn: updateConnectionRoles,
    onSuccess: (_, variables) => {
      clearCompanyAndWorkerConnections({
        companyId: variables.companyId,
        workerId: variables.workerId,
      })
      showSuccess('Roles on connection updated successfully')
    },
    onError: (error) => {
      showError(error.message, 'Error updating roles on connection')
    },
  })

  return {
    createConnection: createConnectionMutation.mutateAsync,
    isCreateConnectionLoading: createConnectionMutation.isPending,
    deleteConnection: deleteConnectionMutation.mutate,
    isDeleteConnectionLoading: deleteConnectionMutation.isPending,
    updateConnectionRolesAsync: updateConnectionRolesMutation.mutateAsync,
    isUpdatingConnectionRoles: updateConnectionRolesMutation.isPending,
  }
}

async function getCompanyEligibilityConnections(
  companyId: string,
): Promise<Worker[] | undefined> {
  try {
    const res = await trabaApi.get(
      `companies/${companyId}/eligibility-connections`,
    )
    return res.data
  } catch (error) {
    console.error(
      'useCompanyEligibilityConnections -> getCompanyEligibilityConnections() ERROR',
      error,
    )
    captureSentryError(error)
  }
}

export function useCompanyEligibilityConnections(companyId: string) {
  const {
    isLoading,
    isError,
    data: eligibilityConnections,
    error,
    isFetched,
    refetch,
  } = useQuery<Worker[] | undefined, Error>({
    queryKey: [
      ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      COMPANY_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      companyId,
    ],
    queryFn: () => getCompanyEligibilityConnections(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  return {
    isLoading,
    isError,
    eligibilityConnections,
    error,
    isFetched,
    refetch,
  }
}
