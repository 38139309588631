import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { Shift, ShiftRequest } from '@traba/types'
import { captureSentryError } from '@traba/utils'

const getShiftRequestsWithShifts = async (shiftRequestIds: string[]) => {
  try {
    const results = await Promise.all(
      shiftRequestIds.map(async (shiftRequestId) => {
        const requestResponse = await trabaApi.get<ShiftRequest>(
          `/shift-requests/${shiftRequestId}`,
        )
        const shiftsResponse = await trabaApi.get<Shift[]>(
          `/shift-requests/${shiftRequestId}/shifts`,
        )

        return {
          shiftRequest: requestResponse.data,
          shifts: shiftsResponse.data,
        }
      }),
    )

    const shiftRequestsData = results.map(({ shiftRequest }) => shiftRequest)
    const shiftsData = results.flatMap(({ shifts }) => shifts)
    return { shiftRequests: shiftRequestsData, shifts: shiftsData }
  } catch (error) {
    console.error('Error fetching shift requests with shifts', error)
    captureSentryError(error)
    throw error
  }
}

const SHIFT_REQUESTS_WITH_SHIFTS_QUERY_KEY = 'shiftRequestsWithShifts'

export const useShiftRequestsWithShifts = (shiftRequestIds: string[]) => {
  const { isLoading, data, refetch } = useQuery<
    { shifts: Shift[]; shiftRequests: ShiftRequest[] } | undefined,
    Error
  >({
    queryKey: [SHIFT_REQUESTS_WITH_SHIFTS_QUERY_KEY, shiftRequestIds],
    queryFn: () => getShiftRequestsWithShifts(shiftRequestIds),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: shiftRequestIds.length > 0,
  })

  return {
    isLoading,
    shiftRequests: data?.shiftRequests ?? [],
    shifts: data?.shifts ?? [],
    refetch,
  }
}
