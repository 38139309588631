import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { captureSentryError } from '@traba/utils'

interface TimesheetSettings {
  companyIds: string[]
}

const TIMESHEET_SETTINGS_QUERY_KEY = 'timesheet-settings'

const getTimesheetSettings = async () => {
  try {
    const res = await trabaApi.get<TimesheetSettings>(
      `/statsig/admin/timesheets`,
    )
    return res.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

const updateTimesheetEmail = async ({
  companyId,
  timesheetEmail,
}: {
  companyId: string
  timesheetEmail: string | null
}) => {
  try {
    await trabaApi.patch(`/companies/${companyId}`, {
      timesheetEmail,
    })
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

const configureForTimesheets = async ({
  companyId,
  enabled,
}: {
  companyId: string
  enabled: boolean
}) => {
  try {
    await trabaApi.post(`/statsig/admin/timesheets`, {
      companyId,
      enabled,
    })
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useTimesheetSettings = () => {
  const { showSuccess, showError } = useAlert()

  const { data, error, refetch } = useQuery({
    queryKey: [TIMESHEET_SETTINGS_QUERY_KEY],
    queryFn: getTimesheetSettings,
  })

  if (error) {
    showError(
      'Failed to fetch timesheet settings. Please refresh the page or reach out to engineering.',
    )
  }

  const { mutateAsync: updateTimesheetEmailMutation, isPending } = useMutation({
    mutationFn: updateTimesheetEmail,
    onSuccess: () => {
      showSuccess('Timesheet email updated')
    },
    onError: () => {
      showError(
        'Failed to update timesheet email. Please try again or reach out to engineering.',
      )
    },
  })

  const { mutateAsync: configureForTimesheetsMutation } = useMutation({
    mutationFn: configureForTimesheets,
    onSuccess: (_, { enabled }) => {
      showSuccess(`Company ${enabled ? 'enabled' : 'disabled'} for timesheets`)
      refetch()
    },
    onError: (_, { enabled }) => {
      showError(
        `Failed to ${enabled ? 'enable' : 'disable'} timesheets for company. Please try again or reach out to engineering.`,
      )
    },
  })

  return {
    timesheetSettings: data,
    updateTimesheetEmail: updateTimesheetEmailMutation,
    configureForTimesheets: configureForTimesheetsMutation,
    isLoadingUpdateTimesheetEmail: isPending,
  }
}
