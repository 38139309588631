import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { captureSentryError } from '@traba/utils'
import { ZealEmployeeType } from 'src/types/zeal'

export const ZEAL_PORTAL_BASE_URL = 'https://app.zeal.com'

export async function createOrGetZealAccountLink(
  workerId: string,
): Promise<string | undefined> {
  try {
    const path = `/zeal/create-or-generate-link/${workerId}`
    const response = await trabaApi.post(path)
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
  }
}

async function getZealInformation(workerId: string) {
  try {
    const path = `/zeal/get-employee-info/${workerId}`
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
  }
}

export function useZeal({
  workerId,
  enabled,
}: {
  workerId: string
  enabled?: boolean
}) {
  const {
    isLoading: isZealLoading,
    isError: isZealError,
    data: zealEmployeeData,
    error: zealError,
  } = useQuery<ZealEmployeeType, Error>({
    queryKey: ['zeal', workerId],
    queryFn: () => getZealInformation(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!enabled,
  })

  return {
    zealEmployeeData,
    isZealLoading,
    isZealError,
    zealError,
  }
}
