import {
  AcceptShiftWaiver,
  GenericAcceptShiftBypasses,
  Shift,
  WorkerShiftEligibility,
} from '@traba/types'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { WorkerOnShift } from './types'
import { WorkersRestrictionsReview } from './WorkersRestrictionsReview'

type ReviewStepProps = {
  selectedShifts: Record<string, string[]>
  shifts: Shift[]
  workers: PopulatedWorker[]
  workerShiftsToCancel: WorkerOnShift[]
  setWorkerShiftsToCancel: React.Dispatch<React.SetStateAction<WorkerOnShift[]>>
  bypasses: GenericAcceptShiftBypasses
  setBypasses: React.Dispatch<React.SetStateAction<GenericAcceptShiftBypasses>>
  hasUncheckedBypasses: boolean
  hasRequiredMultiShift: boolean
  waivers: AcceptShiftWaiver[]
  setWaivers: React.Dispatch<React.SetStateAction<AcceptShiftWaiver[]>>
  setHasCompletedWaiverActions: React.Dispatch<React.SetStateAction<boolean>>
  workersEligibilities: WorkerShiftEligibility[]
}

export const ReviewStep = ({
  selectedShifts,
  shifts,
  workers,
  workerShiftsToCancel,
  setWorkerShiftsToCancel,
  bypasses,
  setBypasses,
  hasUncheckedBypasses,
  hasRequiredMultiShift,
  waivers,
  setWaivers,
  setHasCompletedWaiverActions,
  workersEligibilities,
}: ReviewStepProps) => {
  const selectedShiftsIds = Object.values(selectedShifts).flat()
  const selectedShiftsData = shifts.filter((shift) =>
    selectedShiftsIds.includes(shift.id),
  )

  return (
    <WorkersRestrictionsReview
      workers={workers}
      shifts={selectedShiftsData}
      workerShiftsToCancel={workerShiftsToCancel}
      setWorkerShiftsToCancel={setWorkerShiftsToCancel}
      eligibilityData={workersEligibilities}
      setBypasses={setBypasses}
      bypasses={bypasses}
      hasUncheckedBypasses={hasUncheckedBypasses}
      hasRequiredMultiShift={hasRequiredMultiShift}
      waivers={waivers}
      setWaivers={setWaivers}
      setHasCompletedWaiverActions={setHasCompletedWaiverActions}
    />
  )
}
