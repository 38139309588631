export const DEFAULT_FENCE_RADIUS_METERS = 450
export const DEFAULT_TIMEZONE = 'America/New_York'
export const ONE_HUNDRED_DOLLARS_MONEY_DTO = { currency: 'USD', amount: 10000 }
export const BIZ_REFERRAL_AMOUNT = '500'
export const WORKER_DISTANCE_WARNING_LEVEL = 50

// TIME CONSTANTS
export const DAYS_IN_YEAR = 365
export const HALF_MINUTE_IN_MS = 30_000
export const ONE_MINUTE_IN_MS = 60_000
export const FIVE_MINUTES_IN_MS = 5 * ONE_MINUTE_IN_MS
export const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS
export const ONE_HOUR_IN_MINUTES = 60
export const ONE_DAY_IN_MINUTES = 1440
export const THIRTY_DAYS_IN_MINUTES = 43200 // 30 days
export const ONE_YEAR_IN_MINUTES = 525600 // 365 days
export const THREE_WEEKS_IN_DAYS = 21
export const HOURS_IN_A_WEEK = 24 * 7

// PAGINATION CONSTANTS
export const DEFAULT_PAGE_SIZE = 10

export const INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS = 3

// FORM CONSTANTS
export const REQUIRED_FIELD_MESSAGE = 'This field is required'
export const INPUT_PASSWORD_MISMATCH = 'Must match password.'
export const INPUT_INVALID_EMAIL = 'Must be a valid email address.'
export const INPUT_INVALID_PASSWORD = 'Must be a valid password.'
export const INPUT_INVALID_PHONE = 'Must be a valid phone number.'
export const INPUT_INVALID_ZIPCODE = 'Must be a valid ZIP code.'
export const VALID_ADDRESS = 'Please choose an address from the dropdown'

// ERROR MESSAGES
export const AT_LEAST_ONE_ADMIN_REQUIRED_ERROR_MESSAGE = 'user/no-admins'
export const AT_LEAST_ONE_ADMIN_REQUIRED_VERBOSE_RESPONSE =
  'There must be at least one team member with the Admin role at all times.'
export const ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE =
  'Role name must be unique within the same location. Please change the role name or location.'
