import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Input,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { useState } from 'react'
import { useShiftRequestParentMutation } from 'src/hooks/useShiftRequestParent'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSuccess: () => void
  onBack: () => void
  shifts?: Shift[]
}

export const EditScheduleName = (props: Props) => {
  const { shiftRequestParent, onBack, onSuccess } = props
  const [name, setName] = useState<string>(shiftRequestParent.title)
  const { updateShiftRequestParent } = useShiftRequestParentMutation()
  const { showError } = useAlert()

  const onConfirm = async () => {
    const trimmedName = name.trim()
    if (trimmedName === '') {
      showError('Please enter a name for the schedule')
      return
    }
    try {
      updateShiftRequestParent({
        shiftRequestParentId: shiftRequestParent.shiftRequestParentId,
        input: {
          title: trimmedName,
          companyId: shiftRequestParent.companyId,
        },
      })
      onSuccess()
    } catch (error) {
      showError('Failed to rename, please try again')
    }
  }

  return (
    <>
      <Text variant="h5" mt={theme.space.lg}>
        Type in the new name for this schedule
      </Text>

      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter new name"
        width="100%"
        containerStyle={{ marginTop: theme.space.xs }}
      />

      <Row justifyBetween mt={theme.space.lg}>
        <Button onClick={onBack} variant={ButtonVariant.OUTLINED}>
          Back
        </Button>
        <Button onClick={onConfirm} loading={false}>
          Confirm Schedule Name
        </Button>
      </Row>
    </>
  )
}
