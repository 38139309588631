import { useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { captureSentryError } from '@traba/utils'
import { CreateCreditNoteDto } from 'shared/types/src/lib/invoice'
import { CUSTOM_REPORT_QUERY_KEY } from './useCustomReports'
import { INVOICEABLE_CREDIT_LINE_ITEMS } from './useInvoices'
import { INVOICE_STATEMENT_QUERY_KEY } from './useInvoiceStatement'

export function useCreateCreditNote(invoiceId: string) {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const createCreditNoteMutation = useMutation({
    mutationFn: (params: CreateCreditNoteDto) =>
      trabaApi.post(`/invoices/${invoiceId}/credit-note`, params),
    onSuccess: async () => {
      showSuccess('Successfully created a credit note.')
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [CUSTOM_REPORT_QUERY_KEY, invoiceId],
        }),
        queryClient.invalidateQueries({
          queryKey: [INVOICE_STATEMENT_QUERY_KEY, invoiceId],
        }),
        queryClient.invalidateQueries({
          queryKey: [INVOICEABLE_CREDIT_LINE_ITEMS, invoiceId],
        }),
      ])
    },
    onError: (error: any) => {
      captureSentryError(error)
      showError(`Error: ${error.message}`)
    },
  })

  return {
    createCreditNote: createCreditNoteMutation.mutateAsync,
    isLoading: createCreditNoteMutation.isPending,
  }
}
