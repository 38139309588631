import { Row, Text, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Td } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import useTimezonedDates from 'src/hooks/useTimezonedDates'

interface W2WorkerDisputeRowContentProps {
  bizTime?: Date
  workerTime?: Date
  bizAdjustmentReason?: string
  workerReason?: string
  hasChanged: boolean
  noActionAllowed: boolean
  finalTime: Date | undefined
  setFinalTime: (finalTime: Date) => void
  timeZone: string
  finalizedTime?: Date
  finalizedReason?: string
}

export const W2WorkerDisputeRowContent = (
  props: W2WorkerDisputeRowContentProps,
) => {
  const {
    bizTime,
    workerTime,
    bizAdjustmentReason,
    workerReason,
    hasChanged,
    noActionAllowed,
    finalTime,
    setFinalTime,
    timeZone,
    finalizedTime,
    finalizedReason,
  } = props
  const tz = useTimezonedDates(timeZone)
  const businessRecord = `${tz.getDate(bizTime, false)}, ${tz.getTime(bizTime)}`
  const workerRecord = workerTime
    ? `${tz.getDate(workerTime, false)}, ${tz.getTime(workerTime)}`
    : finalizedReason
  return (
    <>
      <Td>
        <Text variant={'body1'}>{businessRecord}</Text>
        {bizAdjustmentReason && (
          <Row alignCenter>
            <SvgIcon name="info" color={theme.colors.Grey60} size={12} />
            <Text variant="caption">{bizAdjustmentReason}</Text>
          </Row>
        )}
      </Td>
      <Td>
        <Text variant={hasChanged ? 'error' : 'body1'}>{workerRecord}</Text>

        {workerReason && (
          <Row alignCenter gap={theme.space.ms}>
            <SvgIcon name="info" color={theme.colors.Grey60} size={12} />
            <Text variant="caption">{workerReason}</Text>
          </Row>
        )}
      </Td>
      <Td>
        {hasChanged && !noActionAllowed ? (
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={(finalTime) => {
              if (finalTime) {
                setFinalTime(finalTime)
              }
            }}
            isClearable={false}
            inlineLabel={true}
            label="Select Time"
            date={finalTime ?? workerTime ?? bizTime}
            timezone={timeZone}
          />
        ) : (
          <Text variant="body1">{`${tz.getDate(finalizedTime, false)}, ${tz.getTime(
            finalizedTime,
          )}`}</Text>
        )}
      </Td>
    </>
  )
}
