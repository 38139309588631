import { DotMenu } from '@traba/react-components'
import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemType,
  WorkerApplicationItem,
  WorkerApplicationItemCompletionStatus,
  WorkerApplicationItemStatus,
  WorkerApplicationWithWorkerInfo,
} from '@traba/types'
import {
  MRT_Table as MaterialReactTableNoToolbar,
  MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useMemo } from 'react'
import WorkerApplicationItemCompletionStatusBadge from 'src/components/base/Badge/WorkerApplicationItemCompletionStatusBadge'
import WorkerApplicationItemStatusBadge from 'src/components/base/Badge/WorkerApplicationItemStatusBadge'
import { useWorkerApplicationsMutations } from 'src/hooks/useWorkerApplications'
import { ApplicationItemNumberAndTitle } from '../../../Applications/ApplicationItemNumberAndTitle'
import { ApplicationItemLinkToOpenDetails } from './ApplicationItemLinkToOpenDetails'
import { WorkerVettingSessionDrawer } from './WorkerVettingSessionDrawer'

interface ApplicationItemsSectionContainerProps {
  applicationItemRow: MRT_Row<WorkerApplicationWithWorkerInfo>
  selectedWorkerApplicationItemId: string | null
  setSelectedWorkerApplicationItemId: (id: string | null) => void
}

const CELL_COMMON_STYLES = {
  padding: '0px',
  fontSize: '12px',
  fontWeight: '300',
  border: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  borderBottom: `1px solid ${theme.colors.Grey20}`,
}

const HEADER_CELL_STYLES = {
  ...CELL_COMMON_STYLES,
  color: theme.colors.Grey60,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& *': {
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    fontSize: '10px',
  },
  paddingTop: '0px',
  paddingBottom: '0px',
  // borderBottom: 'none',
}

const HEADER_ROW_STYLES = {
  paddingTop: '6px',
  paddingBottom: '6px',
  boxShadow: 'none',
}

export function ApplicationItemsSectionContainer({
  applicationItemRow,
  selectedWorkerApplicationItemId,
  setSelectedWorkerApplicationItemId,
}: ApplicationItemsSectionContainerProps) {
  const data = useMemo(
    () => [...applicationItemRow.original.workerApplicationItems],
    [applicationItemRow.original.workerApplicationItems],
  )

  const { updateWorkerApplicationItemStatus } = useWorkerApplicationsMutations()

  const handleMarkEligible = useCallback(
    (workerApplicationItemId: string) => () => {
      updateWorkerApplicationItemStatus({
        workerApplicationItemId,
        status: WorkerApplicationItemStatus.COMPLETED,
        completionStatus:
          WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE,
      })
    },
    [updateWorkerApplicationItemStatus],
  )

  const handleMarkIneligible = useCallback(
    (workerApplicationItemId: string) => () => {
      updateWorkerApplicationItemStatus({
        workerApplicationItemId,
        status: WorkerApplicationItemStatus.COMPLETED,
        completionStatus:
          WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE,
      })
    },
    [updateWorkerApplicationItemStatus],
  )

  const handleMarkWaived = useCallback(
    (workerApplicationItemId: string) => () => {
      updateWorkerApplicationItemStatus({
        workerApplicationItemId,
        status: WorkerApplicationItemStatus.COMPLETED,
        completionStatus: WorkerApplicationItemCompletionStatus.OPS_WAIVED,
      })
    },
    [updateWorkerApplicationItemStatus],
  )

  const selectedWorkerApplicationItem =
    applicationItemRow.original.workerApplicationItems.find(
      (item) => item.id === selectedWorkerApplicationItemId,
    )

  const selectedWorkerApplicationItemType =
    selectedWorkerApplicationItem?.applicationItem.applicationItemType

  const selectedWorkerApplicationItemSourceId =
    selectedWorkerApplicationItem?.sourceId

  const onCloseDrawer = () => {
    setSelectedWorkerApplicationItemId(null)
  }

  const columns = useMemo<MRT_ColumnDef<WorkerApplicationItem>[]>(
    () => [
      {
        id: 'applicationStepOverview',
        header: 'APPLICATION STEPS',
        accessorFn: (row) => row,
        grow: false,
        size: 212,
        Cell: ({ cell }) => {
          const step = cell.getValue<WorkerApplicationItem>()
          const stepIndex = data.indexOf(step) + 1
          const applicationItemType = step.applicationItem.applicationItemType
          return (
            <ApplicationItemNumberAndTitle
              stepNumber={stepIndex}
              applicationItemType={applicationItemType}
            />
          )
        },
      },
      {
        id: 'output',
        header: 'OUTPUT',
        accessorFn: (row) => row,
        grow: true,
        Cell: ({ cell }) => {
          const workerApplicationItem = cell.getValue<WorkerApplicationItem>()
          const applicationItemType =
            workerApplicationItem.applicationItem.applicationItemType
          const onOpenDrawer = () => {
            setSelectedWorkerApplicationItemId(workerApplicationItem.id)
          }
          return (
            <ApplicationItemLinkToOpenDetails
              applicationItemType={applicationItemType}
              onClickLink={onOpenDrawer}
            />
          )
        },
      },
      {
        id: 'progress',
        header: 'PROGRESS',
        accessorFn: (row) => row.status,
        grow: false,
        Cell: ({ cell }) => (
          <WorkerApplicationItemStatusBadge
            itemStatus={cell.getValue<WorkerApplicationItemStatus>()}
          />
        ),
      },
      {
        id: 'result',
        header: 'RESULT',
        accessorFn: (row) => row.completionStatus,
        grow: false,
        Cell: ({ cell }) => {
          const completionStatus =
            cell.getValue<WorkerApplicationItemCompletionStatus>()
          return completionStatus ? (
            <WorkerApplicationItemCompletionStatusBadge
              itemCompletionStatus={completionStatus}
            />
          ) : null
        },
      },
      {
        id: 'actions',
        header: '',
        accessorFn: (row) => row,
        size: 100,
        grow: false,
        Cell: ({ cell }) => {
          const workerApplicationItem = cell.getValue<WorkerApplicationItem>()
          const completionStatus = workerApplicationItem.completionStatus
          return (
            <Row fullWidth justifyEnd>
              <DotMenu
                type="worker-application-actions"
                dotMenuKey={workerApplicationItem.id}
                style={{
                  padding: '0px',
                  paddingRight: '0px',
                  border: 'none',
                  boxShadow: 'none',
                }}
                menuItems={[
                  {
                    hidden:
                      completionStatus ===
                      WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE,
                    title: 'Mark eligible',
                    onClick: handleMarkEligible(workerApplicationItem.id),
                    color: theme.colors.Green70,
                    iconName: 'check',
                  },
                  {
                    hidden:
                      completionStatus ===
                      WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE,
                    title: 'Mark ineligible',
                    onClick: handleMarkIneligible(workerApplicationItem.id),
                    color: theme.colors.Red60,
                    iconName: 'cancel',
                  },
                  {
                    hidden:
                      completionStatus ===
                      WorkerApplicationItemCompletionStatus.OPS_WAIVED,
                    title: 'Waive',
                    onClick: handleMarkWaived(workerApplicationItem.id),
                    color: theme.colors.Grey60,
                    iconName: 'minus',
                  },
                ]}
              />
            </Row>
          )
        },
      },
    ],
    [
      data,
      handleMarkEligible,
      handleMarkIneligible,
      handleMarkWaived,
      setSelectedWorkerApplicationItemId,
    ],
  )

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableColumnResizing: false,
    muiTableContainerProps: {
      sx: {
        width: '100%',
      },
    },
    muiTableBodyRowProps: () => ({
      hover: false,
    }),
    muiTableProps: {
      sx: {
        width: '100%',
      },
    },
    muiTableHeadRowProps: {
      sx: HEADER_ROW_STYLES,
    },
    muiTableHeadCellProps: {
      sx: HEADER_CELL_STYLES,
    },
    muiTableBodyCellProps: {
      sx: CELL_COMMON_STYLES,
    },
    layoutMode: 'grid',
  })

  return (
    <>
      <MaterialReactTableNoToolbar table={table} />
      {selectedWorkerApplicationItemType ===
        ApplicationItemType.AI_VETTING_CALL && (
        <WorkerVettingSessionDrawer
          isOpen={true}
          onClose={onCloseDrawer}
          workerVettingSessionId={selectedWorkerApplicationItemSourceId || ''}
        />
      )}
    </>
  )
}
