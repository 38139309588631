import {
  Button,
  Col,
  Input,
  Row,
  SearchSelect,
  SelectDropdown,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { TerminationInitiation, WorkerUpload } from '@traba/types'
import { TerminationReason } from '@traba/types'
import {
  terminationReasonOptions,
  terminationInitiationOptions,
  TerminationState,
} from './TerminationModal'

export const TerminationReasonContent = ({
  initiation,
  setInitiation,
  reason,
  setReason,
  explanation,
  setExplanation,
  uploadIds,
  setUploadIds,
  uploadMenuItems,
  setState,
  uploads,
}: {
  initiation: TerminationInitiation | ''
  setInitiation: (initiation: TerminationInitiation | '') => void
  reason: TerminationReason | ''
  setReason: (reason: TerminationReason | '') => void
  explanation: string
  setExplanation: (explanation: string) => void
  uploadIds: string[]
  setUploadIds: (uploadIds: string[]) => void
  uploadMenuItems: { label: string; value: string }[]
  setState: (state: TerminationState) => void
  uploads: WorkerUpload[]
}) => {
  return (
    <>
      <Row justifyBetween>
        <Col width={'40%'}>
          <Text variant="h6">Initiation</Text>
          <SelectDropdown
            showEmptyOption={true}
            handleSelect={setInitiation}
            value={initiation}
            menuItems={terminationInitiationOptions}
          />
        </Col>
        <Col width={'40%'}>
          <Text variant="h6">Reason</Text>
          <SelectDropdown
            showEmptyOption={true}
            handleSelect={setReason}
            value={reason}
            menuItems={terminationReasonOptions[initiation] || []}
          />
        </Col>
      </Row>
      <Col gap={theme.space.xxxs} mt={theme.space.xs}>
        <Text variant="h6">Explanation</Text>
        <Input
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
          style={{ minHeight: '100px', marginTop: 0 }}
          containerStyle={{ marginTop: 0 }}
          type="textarea"
        />
      </Col>
      <Col gap={theme.space.xxxs} mt={theme.space.xs} mb={theme.space.xs}>
        <Text variant="h6">Choose Artifacts</Text>
        <SearchSelect
          multiple
          selectedItems={uploadIds.map((id) => ({
            label: uploads.find((u) => u.id === id)?.title || '',
            value: id,
          }))}
          options={uploadMenuItems}
          handleSelectMultiple={(items) =>
            setUploadIds(items.map((item) => item.value))
          }
        />
      </Col>
      <Button
        onClick={() => setState(TerminationState.EMAIL)}
        disabled={
          explanation === '' ||
          uploadIds.length === 0 ||
          reason === '' ||
          initiation === ''
        }
      >
        Write Email
      </Button>
    </>
  )
}
