import { FormControlLabel, Switch } from '@mui/material'
import {
  updateBusinessUserSettings,
  useBusinessUser,
  useBusinessUserSettings,
} from '@traba/hooks'
import { theme } from '@traba/theme'
import {
  ALL_TEXT_SETTINGS,
  INVOICE_RELATED_EMAILS,
  NotificationPreferenceStatus,
  SHIFT_RELATED_EMAILS,
  SHIFT_RELATED_TEXT,
  SettingsMap,
  UserNotificationSettingType,
  UserRole,
} from '@traba/types'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonVariant } from '../base-components/Button/Button'
import { LoadingSpinner } from '../base-components/LoadingSpinner'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'

import { NotificationSettingsLocationSelectPopper } from './NotificationSettingsLocationSelectPopper'
import { NotificationSettingsMainControl } from './NotificationSettingsMainControl'
import { NotificationSettingsShiftsSelectPopper } from './NotificationSettingsShiftsSelectPopper'
import { NotificationSettingsTable } from './NotificationSettingsTable'

export const NotificationSettingsTables = ({
  businessUserId,
  companyId,
  handleUserHasNoConsent,
  hasSMSConsent,
  allowedLocationIds,
}: {
  businessUserId?: string
  companyId?: string
  handleUserHasNoConsent: () => void
  hasSMSConsent?: boolean
  allowedLocationIds?: Set<string>
}) => {
  const {
    emailsSettingsMap,
    textSettingsMap,
    isLoading,
    refetch,
    isAllOff: isSettingsAllOff,
  } = useBusinessUserSettings(businessUserId)
  const { user, isLoading: isLoadingUser } = useBusinessUser(businessUserId)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false)
  const [isShiftsFilterOpen, setIsShiftsFilterOpen] = useState(false)
  const [updatedEmailItems, setUpdatedEmailItems] = useState<SettingsMap>({})
  const [updatedTextItems, setUpdatedTextItems] = useState<SettingsMap>({})
  const [isAllOff, setIsAllOff] = useState(false)
  const [editingSettingTypes, setEditingSettingTypes] = useState<
    UserNotificationSettingType[] | null
  >(null)

  useEffect(() => {
    async function refetchData() {
      if (businessUserId) {
        await refetch()
        setUpdatedEmailItems(emailsSettingsMap)
        setUpdatedTextItems(textSettingsMap)
      }
    }
    refetchData()
  }, [businessUserId])

  useEffect(() => {
    if (Object.keys(setUpdatedEmailItems).length === 0) {
      setUpdatedEmailItems(emailsSettingsMap)
    }
    if (Object.keys(setUpdatedTextItems).length === 0) {
      setUpdatedTextItems(textSettingsMap)
    }
  }, [emailsSettingsMap, textSettingsMap, businessUserId])

  useEffect(() => {
    setIsAllOff(isSettingsAllOff)
  }, [isSettingsAllOff])

  const onSave = useCallback(async () => {
    if (!isAllOff) {
      // Update all selected locations to be selected status, other non-selected locations will be set to none
      const updatedItems = {
        ...updatedEmailItems,
        ...updatedTextItems,
      }

      const settingsMap = { ...emailsSettingsMap, ...textSettingsMap }
      Object.keys(updatedItems).forEach(async (key) => {
        const updatedLocationIds = updatedItems[key].locationIds
        const allLocationIds = settingsMap[key].locationIds
        const statusHasChanged =
          settingsMap[key].status !== updatedItems[key].status
        const newLocationIds = updatedLocationIds?.filter(
          (li) => !allLocationIds?.includes(li),
        )

        const unSelectedLocationIds = allLocationIds?.filter(
          (li) => !updatedLocationIds?.includes(li),
        )
        if (
          updatedLocationIds &&
          (statusHasChanged || (!!newLocationIds && newLocationIds?.length > 0))
        ) {
          await updateBusinessUserSettings(
            {
              [key]: updatedItems[key].status,
              locationIds: updatedLocationIds,
            },
            businessUserId,
          )
        }
        if (unSelectedLocationIds && unSelectedLocationIds.length > 0) {
          await updateBusinessUserSettings(
            {
              [key]: NotificationPreferenceStatus.NONE,
              locationIds: unSelectedLocationIds,
            },
            businessUserId,
          )
        }
        refetch()
      })
    }
  }, [updatedEmailItems, updatedTextItems, isAllOff, businessUserId])

  const onCancel = useCallback(async () => {
    setUpdatedEmailItems(emailsSettingsMap)
    setUpdatedTextItems(textSettingsMap)
  }, [emailsSettingsMap, textSettingsMap])

  const updatedItems = useMemo(
    () => ({ ...updatedEmailItems, ...updatedTextItems }),
    [updatedEmailItems, updatedTextItems],
  )

  const onTurnOffForAll = async () => {
    try {
      await Promise.all(
        Object.values({ ...updatedEmailItems, ...updatedTextItems }).map(
          (setting) =>
            setting.locationIds &&
            updateBusinessUserSettings(
              {
                [setting.notificationType]: NotificationPreferenceStatus.NONE,
                locationIds: setting.locationIds,
              },
              businessUserId,
            ),
        ),
      )
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }

  const onUpdateSelectedLocations = (newSelectedLocations: Set<string>) => {
    if (editingSettingTypes) {
      const updatedEmailSettingsWithLocationIds = editingSettingTypes.reduce(
        (acc, type) => {
          if (ALL_TEXT_SETTINGS.includes(type)) {
            return acc
          }
          return {
            ...acc,
            [type]: {
              ...updatedEmailItems[type],
              locationIds: Array.from(newSelectedLocations),
            },
          }
        },
        {},
      )

      const updatedTextSettingsWithLocationIds = editingSettingTypes.reduce(
        (acc, type) => {
          if (!ALL_TEXT_SETTINGS.includes(type)) {
            return acc
          }
          return {
            ...acc,
            [type]: {
              ...updatedTextItems[type],
              locationIds: Array.from(newSelectedLocations),
            },
          }
        },
        {},
      )
      setUpdatedTextItems((prev) => ({
        ...prev,
        ...updatedTextSettingsWithLocationIds,
      }))
      setUpdatedEmailItems((prev) => ({
        ...prev,
        ...updatedEmailSettingsWithLocationIds,
      }))
    }
  }

  const haveSettingsChanged = useMemo(() => {
    return (
      !isEqual(updatedEmailItems, emailsSettingsMap) ||
      !isEqual(updatedTextItems, textSettingsMap)
    )
  }, [updatedEmailItems, updatedTextItems, emailsSettingsMap, textSettingsMap])

  if (
    isLoading ||
    isLoadingUser ||
    Object.keys(updatedEmailItems).length === 0 ||
    Object.keys(updatedTextItems).length === 0
  ) {
    return <LoadingSpinner />
  }

  return (
    <div
      style={{
        pointerEvents: isAllOff ? 'none' : 'auto',
      }}
    >
      <Text variant="h5">Your notification preferences</Text>
      <Row justifyBetween mt={theme.space.xs}>
        <div>
          <FormControlLabel
            labelPlacement="start"
            style={{ marginLeft: 0 }}
            control={
              <Switch
                style={{ pointerEvents: 'auto' }}
                checked={!isAllOff}
                onChange={() => {
                  if (!isAllOff) {
                    onTurnOffForAll()
                  }
                  setIsAllOff((prev) => !prev)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Text variant="h6" color={theme.colors.Violet}>
                Allow notifications
              </Text>
            }
          />
          {isAllOff && (
            <Text variant="body2" color={theme.colors.MidnightBlue}>
              All your notifications are currently turned off
            </Text>
          )}
        </div>
        <Row alignCenter gap={theme.space.xxs} mb={theme.space.sm}>
          <Button
            slim
            variant={ButtonVariant.OUTLINED}
            onClick={onCancel}
            disabled={!haveSettingsChanged}
          >
            Cancel
          </Button>

          <Button
            slim
            disabled={isAllOff || !haveSettingsChanged}
            variant={
              haveSettingsChanged
                ? ButtonVariant.FILLED
                : ButtonVariant.OUTLINED
            }
            onClick={async () => {
              await onSave()
            }}
          >
            {haveSettingsChanged ? 'Save' : 'Saved'}

            <SvgIcon
              style={{ marginLeft: theme.space.xxxs }}
              size={14}
              name="check"
              color={
                haveSettingsChanged ? theme.colors.White : theme.colors.Grey40
              }
            />
          </Button>
        </Row>
      </Row>
      <div
        style={{
          borderWidth: 1,
          borderColor: theme.colors.Grey30,
          borderRadius: 10,
          borderStyle: 'solid',
          opacity: isAllOff ? 0.5 : 1,
        }}
      >
        <NotificationSettingsMainControl
          isNotificationsAllowed={!isAllOff}
          setAnchorEl={setAnchorEl}
          setIsShiftsFilterOpen={setIsShiftsFilterOpen}
          setIsLocationFilterOpen={setIsLocationFilterOpen}
          setEditingSettingTypes={setEditingSettingTypes}
          updatedItems={updatedEmailItems}
          setUpdatedItems={setUpdatedEmailItems}
        />
        <NotificationSettingsTable
          isAllOff={isAllOff}
          headers={['SHIFTS', 'STATUS', 'SHIFTS', 'LOCATIONS']}
          settings={SHIFT_RELATED_EMAILS}
          setIsShiftsFilterOpen={setIsShiftsFilterOpen}
          setIsLocationFilterOpen={setIsLocationFilterOpen}
          setAnchorEl={setAnchorEl}
          updatedItems={updatedEmailItems}
          setUpdatedItems={setUpdatedEmailItems}
          setEditingSettingTypes={setEditingSettingTypes}
        />
        {user?.role &&
          [UserRole.Admin, UserRole.Accounting].includes(user?.role) && (
            <NotificationSettingsTable
              isAllOff={isAllOff}
              headers={['Invoice', '', '', '']}
              settings={INVOICE_RELATED_EMAILS}
              setIsShiftsFilterOpen={setIsShiftsFilterOpen}
              setIsLocationFilterOpen={setIsLocationFilterOpen}
              setAnchorEl={setAnchorEl}
              updatedItems={updatedEmailItems}
              setUpdatedItems={setUpdatedEmailItems}
              setEditingSettingTypes={setEditingSettingTypes}
            />
          )}
      </div>
      <div
        style={{
          borderWidth: 1,
          borderColor: theme.colors.Grey30,
          borderRadius: 10,
          borderStyle: 'solid',
          marginTop: theme.space.xs,
          opacity: isAllOff ? 0.5 : 1,
        }}
      >
        <Row px={theme.space.xs} py={theme.space.xs} justifyBetween>
          <div>
            <Text variant="h4">Text settings</Text>
            <Text variant="body2">Which texts do you want to receive?</Text>
          </div>
        </Row>
        <NotificationSettingsTable
          isAllOff={isAllOff}
          headers={['Shifts', 'Status', 'Shifts', 'Locations']}
          settings={SHIFT_RELATED_TEXT}
          setIsShiftsFilterOpen={setIsShiftsFilterOpen}
          setIsLocationFilterOpen={setIsLocationFilterOpen}
          setAnchorEl={setAnchorEl}
          updatedItems={updatedTextItems}
          setUpdatedItems={setUpdatedTextItems}
          setEditingSettingTypes={setEditingSettingTypes}
          handleUserHasNoConsent={handleUserHasNoConsent}
          hasSMSConsent={hasSMSConsent}
        />
      </div>
      <NotificationSettingsLocationSelectPopper
        companyId={companyId}
        anchorEl={anchorEl}
        isLocationFilterOpen={isLocationFilterOpen}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
        selectedLocations={
          new Set(
            editingSettingTypes
              ? (updatedItems[editingSettingTypes[0]]?.locationIds ?? [])
              : [],
          )
        }
        onUpdateSelectedLocations={onUpdateSelectedLocations}
        allowedLocationIds={allowedLocationIds}
      />
      <NotificationSettingsShiftsSelectPopper
        anchorEl={anchorEl}
        isShiftsFilterOpen={isShiftsFilterOpen}
        setIsShiftsFilterOpen={setIsShiftsFilterOpen}
        editingSettingTypes={editingSettingTypes}
        updatedEmailItems={updatedEmailItems}
        updatedTextItems={updatedTextItems}
        setUpdatedEmailItems={setUpdatedEmailItems}
        setUpdatedTextItems={setUpdatedTextItems}
      />
    </div>
  )
}
