import { FileType } from '@traba/hooks'

import { useFileUploader } from '@traba/hooks'
import {
  Button,
  Col,
  FileDrop,
  Input,
  Row,
  Text,
} from '@traba/react-components'
import { ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useUploads } from 'src/hooks/useUploads'

export const ArtifactUploadContent = ({
  workerId,
  handleClose,
}: {
  workerId: string
  handleClose: () => void
}) => {
  const { handleUpload } = useFileUploader()
  const { createWorkerUpload } = useUploads(workerId)
  const [upload, setUpload] = useState<File | undefined>()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const onChangeFile = (f: File | undefined) => {
    setUpload(f)
  }

  const onHandleSubmit = async () => {
    if (!upload || title === '' || description === '') {
      return
    }
    const url = await handleUpload({
      fileType: FileType.WORKER_PERFORMANCE,
      media: upload,
      userId: workerId,
    })
    await createWorkerUpload({
      upload: {
        url,
        title,
        description,
        fileType: FileType.WORKER_PERFORMANCE,
        mimeType: upload.type,
      },
      workerId: workerId || '',
    })
    setUpload(undefined)
    setTitle('')
    setDescription('')
  }

  const onHandleSubmitAndClose = async () => {
    await onHandleSubmit()
    handleClose()
  }

  return (
    <>
      <Col gap={theme.space.xs}>
        <Row alignCenter fullWidth justifyBetween>
          <Text variant="h6">Title</Text>
          <Input
            onChange={(item) => {
              setTitle(item.target.value)
            }}
            value={title}
            containerStyle={{ marginTop: 0, minWidth: '80%' }}
            style={{ width: '100%' }}
          />
        </Row>
        <Row alignCenter fullWidth justifyBetween>
          <Text variant="h6">Description</Text>
          <Input
            onChange={(item) => {
              setDescription(item.target.value)
            }}
            value={description}
            containerStyle={{
              marginTop: 0,
              minWidth: '80%',
            }}
          />
        </Row>

        <Col gap={theme.space.xs} mt={theme.space.xs}>
          <Text variant="h5">Documentation</Text>
          <FileDrop setUpload={onChangeFile} upload={upload} />
        </Col>
      </Col>
      <Row justifyBetween>
        <Button
          onClick={onHandleSubmitAndClose}
          style={{ margin: theme.space.xs }}
        >
          Submit
        </Button>
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={onHandleSubmit}
          style={{ margin: theme.space.xs }}
        >
          Submit and attach another
        </Button>
      </Row>
    </>
  )
}
