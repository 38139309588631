import { trabaApi } from '@traba/api-utils'
import {
  AccountSetupCompletion1099,
  AccountSetupCompletionW2,
  EmploymentType,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'
import { ONE_MINUTE_IN_MS } from '../libs/constants'

async function getWorkerAccountSetupStatus(
  workerId: string,
  employmentType: EmploymentType | string,
): Promise<AccountSetupCompletionW2 | AccountSetupCompletion1099> {
  try {
    const res = await trabaApi.get(
      `account-setup-status/completion-ops/${workerId}?employmentType=${employmentType}`,
    )
    return res.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useCompletionStatus = (
  workerId: string,
  employmentType: EmploymentType | string,
) => {
  const { isLoading, data: completionStatus } = useQuery<
    AccountSetupCompletionW2 | AccountSetupCompletion1099
  >(
    ['ACCOUNT_SETUP_STATUS', { workerId, employmentType }],
    () => getWorkerAccountSetupStatus(workerId, employmentType),
    {
      staleTime: ONE_MINUTE_IN_MS,
      enabled: true,
    },
  )

  return {
    isLoading,
    completionStatus,
  }
}
