import { Switch } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CancellationBusinessChargeType,
  CancellationSource,
  ShiftCancellationCostResponse,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { ChangeEvent, useMemo, useState } from 'react'

import { Input, Row, Select } from 'src/components/base'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useApi } from 'src/hooks/useApi'
import { hasPermissions } from 'src/hooks/usePermissions'
import CancellationCosts from 'src/modals/CancelShiftModal/CancellationCosts'
import { JobStatus } from 'src/types'
import { useAnalytics } from '../../../hooks/useAnalytics'

export type BulkCancelFormProps = {
  workerShifts: WorkerShift[]
  onCancel?: () => void
  onSubmit?: () => void
  sentinelNotificationToUserId?: string
}

export default function BulkCancelForm({
  workerShifts,
  onCancel,
  onSubmit,
  sentinelNotificationToUserId,
}: BulkCancelFormProps) {
  const userContext = useUserContext()
  const { showError } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const cancellationSources = [
    { value: CancellationSource.Ops, label: 'Ops Canceled' },
    { value: CancellationSource.Worker, label: 'Worker Canceled' },
    {
      value: CancellationSource.Business,
      label: 'Business Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.BusinessCancelShift,
      ]),
    },
    {
      value: CancellationSource.System,
      label: 'System Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.SystemCancelShift,
      ]),
    },
  ]
  const [cancellationReason, setCancellationReason] = useState<string>('')
  const [cancellationSource, setCancellationSource] = useState<string>(
    cancellationSources[0].value,
  )
  const [shouldNotifyWorker, setShouldNotifyWorker] = useState<boolean>(false)
  const { trackAnalytics } = useAnalytics()

  const workerShift = workerShifts[0]
  const { data: cancellationData, isLoading: isLoadingCancellationData } =
    useApi<ShiftCancellationCostResponse>(
      `companies/${workerShift.shiftInfo.companyId}/shifts/${workerShift.shiftId}/cancellation-costs`,
    )

  const isPaidCancellation = useMemo(() => {
    const chargedCancellations = cancellationData?.cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    )
    return !!chargedCancellations && chargedCancellations.length > 0
  }, [cancellationData])

  const calculatedShiftCancellationData = useMemo(() => {
    if (isLoadingCancellationData || !cancellationData) {
      return null
    }

    const {
      cancellations = [],
      cancellationSettings,
      cancellationChargeSummary,
    } = cancellationData
    const {
      cancellationBusinessTimeWindow,
      cancellationBusinessChargeType = CancellationBusinessChargeType.Hourly,
      shouldWaiveCancellationFees = false,
    } = cancellationSettings
    const baseWorkerCancellation = cancellations.find(
      (cancellation) => cancellation.workerId === workerShift.workerId,
    )
    const baseWorkerPay =
      baseWorkerCancellation && baseWorkerCancellation.grossPay
        ? baseWorkerCancellation.grossPay.amount / 100
        : 0
    const baseBusinessCharge =
      baseWorkerCancellation && baseWorkerCancellation.totalCharge
        ? baseWorkerCancellation.totalCharge.amount / 100
        : 0
    const baseBusinessHoursCharged =
      baseWorkerCancellation && baseWorkerCancellation.totalChargedTime
        ? Math.round(10 * (baseWorkerCancellation.totalChargedTime / 60)) / 10
        : 0 // hrs
    const numberOfWorkersToChargeBusinessFor = cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    ).length
    const numberWorkersToAppease = cancellations.length
    return {
      numberWorkersToAppease,
      baseWorkerPay,
      baseBusinessCharge,
      cancellationBusinessTimeWindow,
      cancellationBusinessChargeType,
      cancellationChargeSummary,
      baseBusinessHoursCharged,
      shouldWaiveCancellationFees,
      numberOfWorkersToChargeBusinessFor,
    }
  }, [cancellationData, workerShift.workerId, isLoadingCancellationData])

  async function handleCancel() {
    onCancel && onCancel()
  }

  async function handleSubmit() {
    let filteredWorkerShifts: WorkerShift[] = []
    if (workerShifts.length > 0) {
      filteredWorkerShifts = workerShifts.filter(
        (ws) => ws.jobStatus === JobStatus.ToDo,
      )
    }

    if (workerShifts.length !== filteredWorkerShifts.length) {
      const isConfirmed = window.confirm(
        `You are trying to cancel ${
          workerShifts.length - filteredWorkerShifts.length
        } shifts that are not in TODO status. These shifts will be skipped.`,
      )

      if (!isConfirmed) {
        return
      }
    }

    setIsLoading(true)

    try {
      const workerShiftIds = filteredWorkerShifts.map((ws) => {
        return { workerId: ws.workerId, shiftId: ws.shiftId }
      })
      const data = {
        workerShiftIds,
        cancellationSource,
        cancellationReason,
        sendPushNotificationToWorkerOnOpsCancel: shouldNotifyWorker,
        sentinelNotificationToUserId,
      }
      await trabaApi.patch(`/workers/worker-shifts/cancel-worker-shifts`, data)

      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken Bulk WorkerShift Cancel', data)
      }
      onSubmit && onSubmit()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      captureSentryError(err)
      showError(err.message, 'Error canceling worker shifts')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row flexCol justifyCenter fullWidth>
      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        Cancel worker shifts
      </Text>
      <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
        You can cancel the worker's shift on their behalf
      </Text>
      <Select
        fullWidth
        label="Cancellation Source"
        menuItems={cancellationSources}
        value={cancellationSource}
        handleSelect={setCancellationSource}
      />
      <Row mt={8}>
        <Input
          full
          label="Cancellation Reason (Optional)"
          value={cancellationReason}
          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
            setCancellationReason(ev.target.value)
          }
        />
      </Row>
      {cancellationSource === CancellationSource.Business &&
        !isLoadingCancellationData &&
        calculatedShiftCancellationData && (
          <CancellationCosts
            {...calculatedShiftCancellationData}
            cancellationBusinessTimeWindow={
              calculatedShiftCancellationData.cancellationBusinessTimeWindow ??
              0
            }
            isPaidCancellation={isPaidCancellation}
            employerName={workerShift.shiftInfo.employerName}
          />
        )}
      <Row alignCenter style={{ marginTop: theme.space.sm }}>
        <Text variant="h7">Notify worker</Text>
        <Switch
          inputProps={{ 'aria-label': 'controlled' }}
          checked={shouldNotifyWorker}
          onClick={() => setShouldNotifyWorker(!shouldNotifyWorker)}
        />
      </Row>

      <ModalButtons
        handleClose={handleCancel}
        handleConfirm={handleSubmit}
        loading={isLoading}
      />
    </Row>
  )
}
