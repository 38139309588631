import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { CreateSchedule, WorkerShiftAvailabilityResponse } from '@traba/types'
import { captureSentryError } from '@traba/utils'

async function getWorkerAvailabilities(
  workerIds: string[],
  shiftId?: string,
  schedules?: CreateSchedule[],
): Promise<WorkerShiftAvailabilityResponse> {
  if (!shiftId && !schedules) {
    return { availabilities: [] }
  }
  try {
    const res = await trabaApi.post('shifts/worker-availabilities', {
      workerIds,
      shiftInfo: { shiftId, schedules },
    })
    return res.data
  } catch (error) {
    captureSentryError(error)
    throw error
  }
}

export function useWorkerAvailabilities({
  workerIds,
  shiftId,
  schedules,
}: {
  workerIds: string[]
  shiftId?: string
  schedules?: CreateSchedule[]
}) {
  const {
    isLoading,
    isError,
    isFetched,
    data: response,
    error,
    refetch,
  } = useQuery<WorkerShiftAvailabilityResponse, Error>({
    queryKey: ['worker_availabilities', workerIds, shiftId, schedules],
    queryFn: () => getWorkerAvailabilities(workerIds, shiftId, schedules),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const availabilities = response?.availabilities || []
  const workerAvailabilitiesByWorkerId = new Map(
    availabilities.map((a) => [a.workerId, a]),
  )

  return {
    isLoading,
    isError,
    isFetched,
    workerAvailabilitiesByWorkerId,
    error,
    refetch,
  }
}
