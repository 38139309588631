import { Avatar, Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ActionSource, AuditLog } from '@traba/types'
import { Company } from '@traba/types'
import { Shift } from '@traba/types'
import { User } from '@traba/types'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { Button, Col, CopyTextIcon, Icon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import HeaderWithDetailItem from 'src/components/ShiftDetails/ShiftDetailsItem'
import { useApi } from 'src/hooks/useApi'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'
import { AvatarWrapper } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/styles'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
interface AuditLogRowProps {
  auditLog: AuditLog
  logType: 'SHIFT' | 'WORKER' | 'COMPANY'
}

const formatEvent = (event: string) => {
  return event
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase())
}

const formatActor = (actorSource: ActionSource, actorId: string) => {
  switch (actorSource) {
    case ActionSource.Worker:
      return `Worker-${truncateString(actorId)}`
    case ActionSource.Business:
      return `Biz User-${truncateString(actorId)}`
    case ActionSource.Ops:
      return `${actorId}`
    case ActionSource.System:
      return `System`
  }
}

export default function AuditLogRow(props: AuditLogRowProps) {
  const { auditLog, logType } = props
  const [expanded, setExpanded] = useState(false)

  const {
    data: worker,
    isLoading: isLoadingWorker,
    refetch: refetchWorker,
  } = useApi<Worker>(
    `/workers/${auditLog.workerId}`,
    {},
    { enabled: false, staleTime: ONE_HOUR_IN_MS },
  )

  const {
    data: company,
    isLoading: isLoadingCompany,
    refetch: refetchCompany,
  } = useApi<Company>(
    `/companies/${auditLog.companyId}`,
    {},
    { enabled: false, staleTime: ONE_HOUR_IN_MS },
  )

  const {
    data: shift,
    isLoading: isLoadingShift,
    refetch: refetchShift,
  } = useApi<Shift>(
    `/shifts/${auditLog.shiftId}`,
    {},
    { enabled: false, staleTime: ONE_HOUR_IN_MS },
  )

  const {
    data: bizUser,
    isLoading: isLoadingBizUser,
    refetch: refetchBizUser,
  } = useApi<User>(
    `/users/${auditLog.actorId}`,
    {},
    { enabled: false, staleTime: ONE_HOUR_IN_MS },
  )

  const handleExpand = () => {
    setExpanded(!expanded)
    if (auditLog.workerId && !worker) {
      refetchWorker()
    }
    if (auditLog.companyId && !company) {
      refetchCompany()
    }
    if (auditLog.shiftId && !shift) {
      refetchShift()
    }
    if (auditLog.actionSource === ActionSource.Business) {
      refetchBizUser()
    }
  }

  const createdAtString = new Date(auditLog.createdAt).toLocaleString()
  return (
    <>
      <Row
        fullWidth
        justifyBetween
        style={{
          padding: theme.space.xs,
          borderBottom: `1px solid ${theme.colors.Grey30}`,
          minWidth: 950,
          cursor: 'pointer',
        }}
        onClick={handleExpand}
      >
        <Row>
          <Text variant="h7" style={{ width: 250 }}>
            {formatEvent(auditLog.event)}
          </Text>
          {logType !== 'SHIFT' && auditLog.shiftId && (
            <Row alignCenter style={{ width: 200 }}>
              <Text variant="body1" style={{ marginRight: theme.space.xxxs }}>
                Shift:
              </Text>
              <Link to={`/field-monitor/${auditLog.shiftId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {truncateString(auditLog.shiftId)}
                </Button>
              </Link>
              <CopyTextIcon textToCopy={auditLog.shiftId} />
            </Row>
          )}
          {logType !== 'WORKER' && auditLog.workerId && (
            <Row alignCenter style={{ width: 200 }}>
              <Text variant="body1" style={{ marginRight: theme.space.xxxs }}>
                Worker:
              </Text>
              <Link to={`/workers/${auditLog.workerId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {truncateString(auditLog.workerId)}
                </Button>
              </Link>
              <CopyTextIcon textToCopy={auditLog.workerId} />
            </Row>
          )}
          {logType !== 'COMPANY' && auditLog.companyId && (
            <Row alignCenter style={{ width: 200 }}>
              <Text variant="body1" style={{ marginRight: theme.space.xxxs }}>
                Company:
              </Text>
              <Link to={`/companies/${auditLog.companyId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {truncateString(auditLog.companyId)}
                </Button>
              </Link>
              <CopyTextIcon textToCopy={auditLog.companyId} />
            </Row>
          )}
        </Row>
        <Tooltip title={createdAtString}>
          <div>
            <Row alignCenter>
              <Row mr={theme.space.xs}>
                <Text variant="body1">
                  {formatActor(auditLog.actionSource, auditLog.actorId)}
                </Text>
              </Row>
              <Text variant="h7" style={{ marginRight: theme.space.xxs }}>
                {formatTimeDistanceToNow(auditLog.createdAt)}
              </Text>
              <Icon
                name={expanded ? 'chevronUp' : 'chevronDown'}
                style={{
                  height: 12,
                  width: 12,
                  cursor: 'pointer',
                }}
              />
            </Row>
          </div>
        </Tooltip>
      </Row>
      {expanded && (
        <Row
          style={{
            width: '100%',
            padding: theme.space.xxs,
            textAlign: 'left',
            borderTop: `1px solid ${theme.colors.Grey30}`,
            borderBottom: `1px solid ${theme.colors.Grey30}`,
            minWidth: 950,
          }}
        >
          <Col style={{ width: '34%', marginRight: theme.space.xxxs }}>
            <HeaderWithDetailItem
              title="JSON Details"
              renderTextWrapper={false}
            >
              <pre>
                <Text
                  variant="caption"
                  style={{ color: theme.colors.MidnightBlue }}
                >
                  {JSON.stringify(auditLog, null, '\t')}
                </Text>
              </pre>
            </HeaderWithDetailItem>
          </Col>
          <Col style={{ width: '33%' }}>
            {auditLog.workerId && (
              <HeaderWithDetailItem title="Worker" renderTextWrapper={false}>
                {isLoadingWorker ? (
                  <CircularProgress
                    size={'small'}
                    color={theme.colors.Violet}
                  />
                ) : (
                  <Row mt={theme.space.xxs} alignCenter>
                    <AvatarWrapper>
                      <Avatar
                        alt={`worker_${auditLog.workerId}_avatar`}
                        src={worker?.photoUrl}
                        sizes="40px"
                      />
                    </AvatarWrapper>
                    <Col ml={theme.space.xs}>
                      <Text variant="h7">
                        {worker?.firstName} {worker?.lastName}
                      </Text>
                      <Row alignCenter>
                        <Text variant="caption">
                          {truncateString(auditLog.workerId)}
                        </Text>
                        <CopyTextIcon textToCopy={auditLog.workerId} />
                      </Row>
                    </Col>
                  </Row>
                )}
              </HeaderWithDetailItem>
            )}
            {auditLog.companyId && (
              <HeaderWithDetailItem title="Company" renderTextWrapper={false}>
                {isLoadingCompany ? (
                  <CircularProgress
                    size={'small'}
                    color={theme.colors.Violet}
                  />
                ) : (
                  <Col mt={theme.space.xxs}>
                    <Text variant="h7">{company?.employerName}</Text>
                    <Row alignCenter>
                      <Text variant="caption">
                        {truncateString(auditLog.companyId)}
                      </Text>
                      <CopyTextIcon textToCopy={auditLog.companyId} />
                    </Row>
                  </Col>
                )}
              </HeaderWithDetailItem>
            )}
            {auditLog.shiftId && (
              <HeaderWithDetailItem title="Shift" renderTextWrapper={false}>
                {isLoadingShift ? (
                  <CircularProgress
                    size={'small'}
                    color={theme.colors.Violet}
                  />
                ) : (
                  <Col mt={theme.space.xxs}>
                    <Text variant="h7">
                      {shift?.roleDescription} at {shift?.employerName}
                    </Text>
                    <Row alignCenter>
                      <Text variant="caption">
                        {truncateString(auditLog.shiftId)}
                      </Text>
                      <CopyTextIcon textToCopy={auditLog.shiftId} />
                    </Row>
                  </Col>
                )}
              </HeaderWithDetailItem>
            )}
          </Col>
          <Col style={{ width: '33%' }}>
            <HeaderWithDetailItem
              title="User Who Took Action"
              renderTextWrapper={false}
            >
              {auditLog.actionSource === ActionSource.Business ? (
                isLoadingBizUser ? (
                  <CircularProgress
                    size={'small'}
                    color={theme.colors.Violet}
                  />
                ) : (
                  <Col mt={theme.space.xxs}>
                    {bizUser?.firstName} at {bizUser?.lastName}
                    <Row alignCenter>
                      <Text variant="caption">
                        {truncateString(auditLog.actorId)}
                      </Text>
                      <CopyTextIcon textToCopy={auditLog.actorId} />
                    </Row>
                  </Col>
                )
              ) : (
                <Row alignCenter>
                  <Text variant="caption">{auditLog.actorId}</Text>
                  <CopyTextIcon textToCopy={auditLog.actorId} />
                </Row>
              )}
            </HeaderWithDetailItem>
            <HeaderWithDetailItem title="User Type" renderTextWrapper={false}>
              {auditLog.actionSource}
            </HeaderWithDetailItem>
            <HeaderWithDetailItem title="Timestamp" renderTextWrapper={false}>
              {new Date(auditLog.createdAt).toLocaleString()}
            </HeaderWithDetailItem>
          </Col>
        </Row>
      )}
    </>
  )
}
