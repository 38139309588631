import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  Application,
  ApplicationItemContent,
  ApplicationRecordStatus,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

const APPLICATIONS_FOR_SHIFT_REQUEST_QUERY_KEY =
  'applications-for-shift-request'
const APPLICATIONS_BY_ID_QUERY_KEY = 'applications-by-id'

type GetApplicationForShiftRequestParams = {
  shiftRequestId: string | undefined
  recordStatuses?: ApplicationRecordStatus[]
}

type GetApplicationByIdParams = {
  applicationId: string
}

async function getApplicationById({
  applicationId,
}: GetApplicationByIdParams): Promise<Application | undefined> {
  try {
    const res = await trabaApi.get(`/applications/get/${applicationId}`)

    return res.data
  } catch (error) {
    console.error('useApplicationById -> getApplicationById() ERROR', error)
    captureSentryError(error, {
      tags: {
        action: 'useApplicationById -> getApplicationById() ERROR',
      },
    })
    throw error
  }
}

async function getApplicationForShiftRequest({
  shiftRequestId,
  recordStatuses = [
    ApplicationRecordStatus.ACTIVE,
    ApplicationRecordStatus.DISABLED,
  ],
}: GetApplicationForShiftRequestParams): Promise<Application | undefined> {
  try {
    const res = await trabaApi.get(`/applications/search-by-entity`, {
      params: {
        shiftRequestId,
        recordStatuses,
      },
    })
    return res.data
  } catch (error) {
    console.error(
      'useApplicationForShiftRequest -> getApplicationForShiftRequest() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationForShiftRequest -> getApplicationForShiftRequest() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationForShiftRequest(
  params: GetApplicationForShiftRequestParams,
) {
  const {
    isLoading,
    isError,
    data: application,
    error,
    isFetched,
    refetch,
  } = useQuery<Application | undefined, Error>({
    queryKey: [APPLICATIONS_FOR_SHIFT_REQUEST_QUERY_KEY, params.shiftRequestId],
    queryFn: () => getApplicationForShiftRequest(params),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!params.shiftRequestId,
  })

  return {
    isLoading,
    isError,
    application,
    error,
    isFetched,
    refetch,
  }
}

export function useApplicationById(params: GetApplicationByIdParams) {
  const {
    isLoading,
    isError,
    data: application,
    error,
    isFetched,
    refetch,
  } = useQuery<Application | undefined, Error>({
    queryKey: [APPLICATIONS_BY_ID_QUERY_KEY, params.applicationId],
    queryFn: () => getApplicationById(params),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!params.applicationId,
  })

  return {
    isLoading,
    isError,
    application,
    error,
    isFetched,
    refetch,
  }
}

interface UpdateApplicationRecordStatusParams {
  applicationId: string
  recordStatus: ApplicationRecordStatus
}

async function updateApplicationRecordStatus({
  applicationId,
  recordStatus,
}: UpdateApplicationRecordStatusParams): Promise<Application | undefined> {
  if (!applicationId) {
    throw new Error('applicationId is required')
  }
  if (!recordStatus) {
    throw new Error('recordStatus is required')
  }

  try {
    const response = await trabaApi.patch(
      `applications/update/${applicationId}`,
      {
        recordStatus,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationsMutations -> updateApplicationRecordStatus() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const updateApplicationRecordStatusMutation = useMutation<
    Application | undefined,
    Error,
    UpdateApplicationRecordStatusParams,
    Application
  >({
    mutationFn: updateApplicationRecordStatus,
    onSuccess: (data: Application | undefined, { recordStatus }) => {
      const shiftRequestIds = (data?.applicationEntities || [])
        .map((ae) => ae.shiftRequestId)
        .filter((entityId) => !!entityId)

      shiftRequestIds.forEach((shiftRequestId) => {
        queryClient.setQueryData(
          [APPLICATIONS_FOR_SHIFT_REQUEST_QUERY_KEY, shiftRequestId],
          (application: Application | undefined) => {
            if (application?.id === data?.id) {
              return { ...application, recordStatus: data?.recordStatus }
            }
            return application
          },
        )
      })
      showSuccess(`Application was successfully set to ${recordStatus}`)
    },
    onError: (error) => {
      showError(error?.message, 'Error changing application enabled status')
    },
  })

  return {
    updateApplicationRecordStatus: updateApplicationRecordStatusMutation.mutate,
    isUpdateApplicationRecordStatusLoading:
      updateApplicationRecordStatusMutation.isPending,
  }
}

interface UpdateApplicationItemParams {
  applicationItemId: string
  itemContent?: ApplicationItemContent
}

async function updateApplicationItemContent({
  applicationItemId,
  itemContent,
}: UpdateApplicationItemParams): Promise<Application | undefined> {
  if (!applicationItemId) {
    throw new Error('applicationItemId is required')
  }

  try {
    const response = await trabaApi.patch(
      `applications/item/${applicationItemId}/update`,
      {
        itemContent,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationItemsMutations -> updateApplicationItemContent() ERROR',
      },
    })
    throw error
  }
}

interface ArchiveApplicationItemParams {
  applicationItemId: string
}

async function archiveApplicationItem({
  applicationItemId,
}: ArchiveApplicationItemParams): Promise<Application | undefined> {
  if (!applicationItemId) {
    throw new Error('applicationItemId is required')
  }

  try {
    const response = await trabaApi.patch(
      `applications/item/${applicationItemId}/archive`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationItemsMutations -> archiveApplicationItem() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationItemsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const updateApplicationItemContentMutation = useMutation<
    Application | undefined,
    Error,
    UpdateApplicationItemParams,
    Application
  >({
    mutationFn: updateApplicationItemContent,
    onSuccess: (data: Application | undefined) => {
      const shiftRequestIds = (data?.applicationEntities || [])
        .map((ae) => ae.shiftRequestId)
        .filter((entityId) => !!entityId)

      shiftRequestIds.forEach((shiftRequestId) => {
        queryClient.setQueryData(
          [APPLICATIONS_FOR_SHIFT_REQUEST_QUERY_KEY, shiftRequestId],
          (application: Application | undefined) => {
            if (application?.id === data?.id) {
              return data
            }
            return application
          },
        )
      })

      showSuccess(`Application item was successfully updated`)
    },
    onError: (error) => {
      showError(error?.message, 'Error updating application item')
    },
  })

  const archiveApplicationItemMutation = useMutation<
    Application | undefined,
    Error,
    ArchiveApplicationItemParams,
    Application
  >({
    mutationFn: archiveApplicationItem,
    onSuccess: (data: Application | undefined) => {
      const shiftRequestIds = (data?.applicationEntities || [])
        .map((ae) => ae.shiftRequestId)
        .filter((entityId) => !!entityId)

      shiftRequestIds.forEach((shiftRequestId) => {
        queryClient.setQueryData(
          [APPLICATIONS_FOR_SHIFT_REQUEST_QUERY_KEY, shiftRequestId],
          (application: Application | undefined) => {
            if (application?.id === data?.id) {
              return data
            }
            return application
          },
        )
      })
      showSuccess(`Application item was successfully archived`)
    },
    onError: (error) => {
      showError(error?.message, 'Error archiving application item')
    },
  })

  return {
    updateApplicationItemContent: updateApplicationItemContentMutation.mutate,
    isUpdateApplicationItemContentLoading:
      updateApplicationItemContentMutation.isPending,
    archiveApplicationItem: archiveApplicationItemMutation.mutate,
    isArchiveApplicationItemLoading: archiveApplicationItemMutation.isPending,
  }
}
