import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { InvoiceShiftStatus } from '@traba/types'
import { captureSentryError } from '@traba/utils'

const INVOICE_SHIFT_STATUSES_QUERY_KEY = 'invoiceShiftsStatus'

async function getInvoiceShiftStatuses(invoiceId: string) {
  try {
    const res = await trabaApi.get(`invoices/${invoiceId}/shifts-status`)
    return res.data
  } catch (error: any) {
    captureSentryError(error)
    console.error(
      'useInvoiceShiftStatuses -> getInvoiceShiftStatuses',
      error.message ?? error,
    )
    throw error
  }
}

export function useInvoiceShiftStatuses(invoiceId: string) {
  const {
    isFetching,
    isLoading,
    isError,
    data: invoiceShiftStatuses,
    error,
    isFetched,
    refetch,
  } = useQuery<InvoiceShiftStatus[], Error>({
    queryKey: [INVOICE_SHIFT_STATUSES_QUERY_KEY, invoiceId],
    queryFn: () => getInvoiceShiftStatuses(invoiceId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isFetching,
    isLoading,
    isError,
    invoiceShiftStatuses,
    error,
    isFetched,
    refetch,
  }
}
