import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import { useCallback, useEffect, useState } from 'react'
import { Col } from './Col'
import { Input } from './Input/Input.styles'
import Row from './Row'
import { SvgIcon } from './SvgIcon'
import { Text } from './Text'

interface DropZoneProps {
  id?: string
  upload: File | undefined
  setUpload: (file: File | undefined) => void
  accept?: string
  maxSize?: number
  style?: React.CSSProperties
}

const rowStyling = {
  backgroundColor: theme.colors.Violet10,
  borderStyle: 'dashed',
  borderRadius: theme.space.xs,
  borderWidth: 2,
  padding: theme.space.med,
}

export const FileDrop: React.FC<DropZoneProps> = ({
  id,
  upload,
  setUpload,
  accept = 'image/*',
  maxSize = 5242880,
  style,
}) => {
  const { showError } = useAlert()
  const [generatedUniqueId, setGeneratedUniqueId] = useState<
    string | undefined
  >(undefined)
  useEffect(() => {
    setGeneratedUniqueId(
      id ? id : `file-input-${Math.random().toString(36).substring(2, 15)}`,
    )
  }, [id])

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const validateFile = (file: File): boolean => {
    if (!file.type.match(accept.replace('/*', ''))) {
      showError('File type not supported')
      return false
    }

    if (file.size > maxSize) {
      showError(`File size must be less than ${maxSize / 1024 / 1024}MB`)
      return false
    }

    return true
  }

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      const files = e.dataTransfer.files
      if (files && files.length > 0 && files[0]) {
        const file = files[0]
        if (validateFile(file)) {
          setUpload(file)
        }
      }
    },
    [setUpload, accept, maxSize],
  )

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (files && files[0]) {
        const file = files[0]
        if (validateFile(file)) {
          setUpload(file)
        }
      }
    },
    [setUpload, accept, maxSize],
  )

  return (
    <Col
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      {upload ? (
        <Row
          justifyBetween
          style={{
            ...rowStyling,
            backgroundColor: theme.colors.Grey10,
            borderColor: theme.colors.Grey80,
            ...style,
          }}
        >
          <Row gap={theme.space.xxs}>
            <SvgIcon name={'simpleMessage'} color={theme.colors.MidnightBlue} />
            <Text variant="h6">{upload.name}</Text>
          </Row>
          <SvgIcon
            name={'trash'}
            color={theme.colors.MidnightBlue}
            onClick={() => setUpload(undefined)}
          />
        </Row>
      ) : (
        <Row
          style={{
            ...rowStyling,
            backgroundColor: theme.colors.Violet10,
            borderColor: theme.colors.Violet60,
            ...style,
            justifyContent: 'center',
          }}
        >
          <label htmlFor={generatedUniqueId}>
            <Text variant="h6">Select or drop a file</Text>
          </label>

          <Input
            id={generatedUniqueId}
            type="file"
            className="hidden"
            accept={accept}
            onChange={(e) =>
              handleFileInput(e as React.ChangeEvent<HTMLInputElement>)
            }
            style={{ display: 'none' }}
          />
        </Row>
      )}
    </Col>
  )
}
