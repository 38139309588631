import { Switch } from '@mui/material'
import { ROLE_LOCATION_SHIFT_LOCATION_MISMATCH_ERROR_MSG } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Card,
  Col,
  RoleSearchSelect,
  SupervisorForShiftSearchSelect,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  GenderPreference,
  RequiredMultiShiftType,
  Role,
  ForwardFillMax,
  PaymentType,
  ShiftNotificationStatus,
  ShiftPayType,
  TierLevel,
  User,
  ShiftSignupStatus,
  Roster,
  EmploymentType,
} from '@traba/types'
import {
  getBusinessStartTime,
  getSelectSupervisorForBookingShiftErrorMessage,
  isRoleLocationNotMatchingShiftLocation,
} from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import { compact } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Autocomplete } from 'src/components/Autocomplete/Autocomplete'
import { Row, Select } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { WeeklyPayWarning } from 'src/components/WeeklyPayWarning'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import {
  calculateEstimatedPay,
  validatePayRate,
} from 'src/modals/EditShiftModal/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import {
  calculateBilledShiftTime,
  calculatePaidShiftTime,
  convertCentsToDollars,
  convertPayRateToCents,
  getScheduledBreakTotal,
} from 'src/utils/moneyUtils'
import {
  genderPreferenceOptions,
  paymentTypeOptions,
  minimumTierOptions,
  signupStatusOptions,
  forwardFillTypeOptions,
} from 'src/utils/shiftFormUtils'
import { validateEmail } from 'src/utils/stringUtils'

import { SearchInviteWorkersSection } from '../SearchInviteWorkers/SearchInviteWorkersSection'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'
import { PaymentSummary } from './PaymentSummary'
import { PostShiftVettingContextForm } from './PostShiftVettingContextForm'
import { SlotsAndOverbook } from './SlotsAndOverbook'

interface Props {
  createShiftRequest: CreateShiftRequest
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  index: number
  roles: Role[] | undefined
  companyUsers: User[]
  minHourlyPayRate: number
  setWorkersToInvite: Dispatch<SetStateAction<PopulatedWorker[]>>
  workersToInvite: PopulatedWorker[]
  companyId: string
  rosters: Roster[] | undefined
  shiftEmploymentType: EmploymentType
  overrideBusinessStartTime?: Date
  disableChangingRole: boolean
}

const payTypeOptions = [
  { value: ShiftPayType.HOURLY, label: 'Hourly' },
  { value: ShiftPayType.UNIT, label: 'Unit' },
]

const rmsaShiftOptions = [
  { value: RequiredMultiShiftType.None, label: 'None' },
  { value: RequiredMultiShiftType.ALL_IN_REQUEST, label: 'All In Request' },
]

function getShiftRequest({
  createShiftRequest,
  createShiftRequests,
  index,
}: {
  createShiftRequest: CreateShiftRequest
  createShiftRequests: CreateShiftRequest[]
  index: number
}) {
  const shiftRequest = createShiftRequests[index]
  if (!shiftRequest.roleId) {
    return createShiftRequest
  }
  return shiftRequest
}

export const IndividualRoleForm = ({
  createShiftRequest,
  createShiftRequests,
  setCreateShiftRequests,
  index,
  roles,
  companyUsers,
  minHourlyPayRate,
  setWorkersToInvite,
  workersToInvite,
  companyId,
  rosters,
  overrideBusinessStartTime,
  shiftEmploymentType,
  disableChangingRole,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const [toggleVettingCampaign, setToggleVettingCampaign] = useState(false)
  const { showError } = useAlert()
  const { hotSettings } = useHotSettings()
  const { virtualRosters } = useVirtualRosters(
    companyId,
    createShiftRequest.locationId,
    createShiftRequest.roleId,
  )

  const totalShiftTime = differenceInMinutes(
    createShiftRequest.schedules[0].endTime,
    createShiftRequest.schedules[0].startTime,
  )

  const totalBreakTime = getScheduledBreakTotal(
    createShiftRequest.scheduledBreaks || [],
  )

  const businessStartTime =
    overrideBusinessStartTime ??
    getBusinessStartTime({
      shiftStartTime: createShiftRequest.schedules[0].startTime,
      earlyArrivalTimeBufferMinutes:
        createShiftRequest.earlyArrivalTimeBufferMinutes || 0,
    })

  const totalBilledTime = calculateBilledShiftTime(
    businessStartTime,
    createShiftRequest.schedules[0].endTime,
    totalBreakTime,
    createShiftRequest.breakType,
  )

  const totalPaidTime = calculatePaidShiftTime(
    totalBreakTime,
    totalShiftTime,
    createShiftRequest.breakType,
  )
  const { payString: estimatedPay } = calculateEstimatedPay(
    createShiftRequest.payRate,
    createShiftRequest.payType,
    totalPaidTime,
    createShiftRequest.numberOfUnits || 0,
    createShiftRequest.slotsRequested,
  )

  const selectedLocationId = createShiftRequest.locationId

  const additionalEmailsOptions = useMemo(
    () =>
      compact(
        companyUsers
          .filter(
            (companyUser) =>
              companyUser.uid !== createShiftRequest.supervisorId,
          )
          .map((companyUser) => companyUser.email),
      ),
    [companyUsers, createShiftRequest.supervisorId],
  )

  const onChangeSupervisor = useCallback(
    (supervisorId: string) => {
      setCreateShiftRequests((prev) => {
        const newCreateShiftRequests = [...prev]
        newCreateShiftRequests[index] = {
          ...newCreateShiftRequests[index],
          supervisorId,
        }
        return newCreateShiftRequests
      })
    },
    [index, setCreateShiftRequests],
  )

  const supervisorSearchSelectErrorMsg = useMemo(
    () =>
      getSelectSupervisorForBookingShiftErrorMessage({
        locationId: createShiftRequest.locationId,
        supervisor: companyUsers.find(
          (s) => s.uid === createShiftRequest.supervisorId,
        ),
        isRebook: false,
        isRegionalAccessEnabled: hotSettings?.enableRegionalAccessPhase2,
      }),
    [
      companyUsers,
      createShiftRequest.locationId,
      createShiftRequest.supervisorId,
      hotSettings?.enableRegionalAccessPhase2,
    ],
  )

  const roleMap: Map<string, Role> = useMemo(
    () => new Map(roles?.map((role) => [role.roleId, role]) || []),
    [roles],
  )
  const selectedRole = roleMap.get(createShiftRequest.roleId)

  const roleSearchSelectErrorMsg = createShiftRequests.some(
    (createShiftRequest) =>
      !!(
        createShiftRequest.roleId &&
        isRoleLocationNotMatchingShiftLocation(
          createShiftRequest.locationId,
          roleMap.get(createShiftRequest.roleId),
        )
      ),
  )
    ? ROLE_LOCATION_SHIFT_LOCATION_MISMATCH_ERROR_MSG
    : undefined

  const handleRoleChange = useCallback(
    (roleId: string) => {
      const newRole = roleMap.get(roleId)
      if (!newRole) {
        return
      }
      setCreateShiftRequests((prev) => {
        const newCreateShiftRequests = [...prev]
        newCreateShiftRequests[index] = {
          ...newCreateShiftRequests[index],
          roleId,
          payRate: newRole.defaultPayRate,
          hourlyRate: newRole.defaultPayRate,
          videoIds: newRole.videoIds ?? [],
          genderPreference: newRole.genderPreference,
        }
        return newCreateShiftRequests
      })
    },
    [index, roleMap, setCreateShiftRequests],
  )

  const isRecurringShiftRequest = createShiftRequest.schedules.some(
    (schedule) => schedule.isRecurringSchedule && !!schedule.recurringSchedule,
  )

  if (collapsed) {
    return (
      <>
        <Row justifyBetween pt={theme.space.xs} alignCenter>
          <Row alignCenter>
            <Text variant="h6">
              {roleMap.get(createShiftRequest.roleId)?.roleName ??
                'Unselected role'}
              {` \u2022 `}
              {`$${createShiftRequest.payRate} / hr`}
              {` \u2022 `}
              {createShiftRequest.slotsRequested} slots
            </Text>
            <Button
              variant={ButtonVariant.TEXT}
              style={{ color: theme.colors.Violet }}
              onClick={() => {
                if (createShiftRequests.length === 1) {
                  return showError("Can't remove the only role")
                }
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests.splice(index, 1)
                  return newCreateShiftRequests
                })
              }}
            >
              Remove
            </Button>
          </Row>
          <Button
            variant={ButtonVariant.TEXT}
            style={{ color: theme.colors.Violet }}
            onClick={() => {
              setCollapsed(false)
            }}
          >
            + Expand
          </Button>
        </Row>
        <Divider />
      </>
    )
  }

  return (
    <Card style={{ marginTop: theme.space.xs }}>
      <Button
        style={{ alignSelf: 'flex-end' }}
        variant={ButtonVariant.TEXT}
        onClick={() => {
          setCollapsed(true)
        }}
      >
        - Collapse
      </Button>
      <Row mb={theme.space.sm} gap={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label="Role"
          input={
            <RoleSearchSelect
              roles={roles ?? []}
              selectedRoleId={createShiftRequest.roleId}
              selectedLocationId={selectedLocationId}
              handleRoleChange={handleRoleChange}
              errorMessage={roleSearchSelectErrorMsg}
              disabled={disableChangingRole}
            />
          }
        />
        <ShiftPostingInputContainerSection
          label="Supervisor"
          input={
            <SupervisorForShiftSearchSelect
              placeholder="-"
              selectedSupervisorId={createShiftRequest.supervisorId}
              selectedLocationId={createShiftRequest.locationId}
              allSupervisors={companyUsers}
              handleSupervisorChange={onChangeSupervisor}
              disabled={!createShiftRequest.locationId}
              disabledTooltipText="Please select a work site location before selecting a supervisor."
              label=""
              errorMessage={supervisorSearchSelectErrorMsg}
              showEmailForUser
              style={{ minWidth: '40%' }}
            />
          }
        />
      </Row>
      {createShiftRequest.supervisorId && (
        <Row mb={theme.space.xs} style={{ width: '50%' }}>
          <ShiftPostingInputContainerSection
            label="Additional team members (optional)"
            input={
              <Autocomplete
                label="Email addresses"
                value={createShiftRequest.additionalEmails || []}
                options={additionalEmailsOptions}
                onChangeValues={(_, value) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      additionalEmails: value,
                    }
                    return newCreateShiftRequests
                  })
                }}
                errorMessage="Email address is invalid"
                validateInput={validateEmail}
              />
            }
          />
        </Row>
      )}
      <Divider />
      <Row my={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label="Pay Type"
          input={
            <Select
              menuItems={payTypeOptions}
              fullWidth
              handleSelect={(payType) => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    payType: payType as ShiftPayType,
                  }
                  return newCreateShiftRequests
                })
              }}
              value={createShiftRequest.payType}
            />
          }
        />

        <ShiftPostingInputContainerSection
          label={`Pay rate per ${
            createShiftRequest.payType === ShiftPayType.HOURLY ? 'Hour' : 'Unit'
          }`}
          input={
            <>
              <NumberInput
                customErrorMessage={
                  createShiftRequest.payType === ShiftPayType.HOURLY
                    ? 'To post a lower rate, adjust the min hourly rate company setting.'
                    : 'Make sure to set both Pay Per Unit and Total Units.'
                }
                isMoney
                value={createShiftRequest.payRate}
                setValue={(payRate) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      // if the pay rate is zero, we don't need to charge a trust and safety fee
                      ...(payRate === 0
                        ? {
                            trustAndSafetyFeeHourly: {
                              amount: 0,
                              currency: 'USD',
                            },
                          }
                        : {
                            trustAndSafetyFeeHourly: {
                              amount: 50,
                              currency: 'USD',
                            },
                          }),
                      payRate: payRate || 0,
                      hourlyRate: payRate || 0,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={0.01}
                required
                placeholder="Pay Rate"
                error={
                  !validatePayRate({
                    payType: createShiftRequest.payType,
                    payRate: createShiftRequest.payRate,
                    minHourlyPayRate: minHourlyPayRate,
                    numberOfUnits: createShiftRequest.numberOfUnits,
                  })
                }
              />
              {selectedRole?.defaultPayRate === createShiftRequest.payRate && (
                <Text variant="caption" ml={theme.space.xs}>
                  THIS IS THE DEFAULT PAY FOR THE ROLE
                </Text>
              )}
            </>
          }
        />

        {createShiftRequest.payType === ShiftPayType.UNIT && (
          <ShiftPostingInputContainerSection
            label={`Total Units`}
            input={
              <NumberInput
                value={createShiftRequest.numberOfUnits}
                setValue={(units) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      numberOfUnits: units,
                    }
                    return newCreateShiftRequests
                  })
                }}
                decimals={0}
                placeholder="Num Units"
                min={0}
                step={1}
                error={!createShiftRequest.numberOfUnits}
                customErrorMessage="This field is required"
              />
            }
          />
        )}

        <ShiftPostingInputContainerSection
          label="Payment Type (optional)"
          input={
            <>
              <Select
                menuItems={paymentTypeOptions}
                fullWidth
                handleSelect={(paymentType) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      paymentType: paymentType as PaymentType,
                    }
                    return newCreateShiftRequests
                  })
                }}
                showEmptyOption
                value={createShiftRequest.paymentType || ''}
              />
              {createShiftRequest.paymentType === PaymentType.AUTO_WEEKLY && (
                <WeeklyPayWarning mt={theme.space.xs} />
              )}
            </>
          }
        />
      </Row>
      <Divider />

      <SlotsAndOverbook
        createShiftRequest={createShiftRequest}
        setCreateShiftRequests={setCreateShiftRequests}
        index={index}
        shiftEmploymentType={shiftEmploymentType}
      />

      <Divider />
      {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 && (
        <Row my={theme.space.sm} fullWidth justifyCenter wrap>
          <ShiftPostingInputContainerSection
            label="Paid Backups"
            input={
              <NumberInput
                label="Add Paid Backups?"
                value={createShiftRequest.paidBackupSlotsRequested}
                setValue={(numReq) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      paidBackupSlotsRequested: numReq,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={1}
                min={0}
              />
            }
          />
          <ShiftPostingInputContainerSection
            label="Paid Backup Pay"
            input={
              <NumberInput
                value={
                  createShiftRequest.paidBackupPayAmount ||
                  createShiftRequest.paidBackupPayAmount === 0
                    ? convertCentsToDollars(
                        createShiftRequest.paidBackupPayAmount,
                      )
                    : undefined
                }
                setValue={(numReq) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      paidBackupPayAmount:
                        numReq || numReq === 0
                          ? convertPayRateToCents(numReq)
                          : undefined,
                    }
                    return newCreateShiftRequests
                  })
                }}
                isMoney={true}
                step={0.01}
                min={createShiftRequest.paidBackupSlotsRequested ? 1 : 0}
                max={100}
              />
            }
          />
        </Row>
      )}
      <Divider />
      <Row my={theme.space.sm} alignCenter wrap>
        <ShiftPostingInputContainerSection
          label={`Shift Signup Status`}
          input={
            <Row
              style={{
                alignItems: 'center',
              }}
            >
              <Select
                menuItems={signupStatusOptions}
                fullWidth
                handleSelect={(signupStatus) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      signupStatus: signupStatus as ShiftSignupStatus,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={
                  createShiftRequest.signupStatus || ShiftSignupStatus.ALLOWED
                }
              />
            </Row>
          }
        />
        {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 && (
          <ShiftPostingInputContainerSection
            label={`Forward Fill Type`}
            input={
              <Select
                menuItems={forwardFillTypeOptions}
                fullWidth
                handleSelect={(forwardFillMax) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      forwardFillMax: forwardFillMax as ForwardFillMax,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={createShiftRequest.forwardFillMax || ForwardFillMax.NONE}
              />
            }
          />
        )}
      </Row>
      <Divider />
      <Row justifyStart my={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label={`Minimum Tier`}
          input={
            <Row mr={theme.space.med}>
              <Select
                menuItems={minimumTierOptions}
                fullWidth
                handleSelect={(tier) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      minimumAcceptedTier: tier as TierLevel,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={createShiftRequest.minimumAcceptedTier}
              />
            </Row>
          }
        />

        <ShiftPostingInputContainerSection
          label={`Unproven Threshold`}
          input={
            <Row mr={theme.space.med}>
              <DeprecatedNumberInput
                value={createShiftRequest.unprovenWorkerThreshold}
                setter={(threshold) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      unprovenWorkerThreshold: threshold,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={0.01}
                decimals={2}
                placeholder="eg. 0.5"
                helperText="Must be between 0 and 1"
                error={
                  createShiftRequest.unprovenWorkerThreshold !== undefined &&
                  (createShiftRequest.unprovenWorkerThreshold < 0 ||
                    createShiftRequest.unprovenWorkerThreshold > 1)
                }
              />
            </Row>
          }
        />
        {isRecurringShiftRequest && (
          <ShiftPostingInputContainerSection
            label={`Show RMSA`}
            input={
              <Row mr={theme.space.med}>
                <Select
                  menuItems={rmsaShiftOptions}
                  fullWidth
                  handleSelect={(rmsaOption) => {
                    setCreateShiftRequests((prev) => {
                      const newCreateShiftRequests = [...prev]
                      newCreateShiftRequests[index] = {
                        ...newCreateShiftRequests[index],
                        requiredMultiShiftType:
                          rmsaOption as RequiredMultiShiftType,
                      }
                      return newCreateShiftRequests
                    })
                  }}
                  value={createShiftRequest.requiredMultiShiftType}
                />
              </Row>
            }
          />
        )}
        {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 && (
          <ShiftPostingInputContainerSection
            label={`Gender Preference`}
            input={
              <Row mr={theme.space.med}>
                <Select
                  menuItems={genderPreferenceOptions}
                  fullWidth
                  showEmptyOption
                  handleSelect={(genderPreference) => {
                    setCreateShiftRequests((prev) => {
                      const newCreateShiftRequests = [...prev]
                      newCreateShiftRequests[index] = {
                        ...newCreateShiftRequests[index],
                        genderPreference: genderPreference
                          ? (genderPreference as GenderPreference)
                          : undefined,
                      }
                      return newCreateShiftRequests
                    })
                  }}
                  value={createShiftRequest.genderPreference || ''}
                />
              </Row>
            }
          />
        )}
        {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 && (
          <ShiftPostingInputContainerSection
            label="Forward Fill Notifications"
            input={
              <Switch
                inputProps={{
                  'aria-label': 'Toggle forward fill notifications',
                }}
                checked={
                  createShiftRequest.notificationStatus ===
                  ShiftNotificationStatus.ALLOWED
                }
                onClick={() => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      notificationStatus:
                        createShiftRequest.notificationStatus ===
                        ShiftNotificationStatus.ALLOWED
                          ? ShiftNotificationStatus.DISALLOWED
                          : ShiftNotificationStatus.ALLOWED,
                    }
                    return newCreateShiftRequests
                  })
                }}
              />
            }
            right
          />
        )}
      </Row>
      <Row justifyStart mt={theme.space.sm} mb={theme.space.xs} wrap>
        <ShiftPostingInputContainerSection
          label="Require W9 Authorization"
          input={
            <Switch
              inputProps={{
                'aria-label': 'Toggle W9 authorization requirement',
              }}
              checked={createShiftRequest.requireW9Authorization}
              onClick={() => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    requireW9Authorization:
                      !createShiftRequest.requireW9Authorization,
                  }
                  return newCreateShiftRequests
                })
              }}
            />
          }
          right
        />
      </Row>
      {shiftEmploymentType === EmploymentType.CONTRACTOR_1099 &&
        (createShiftRequest.forwardFillMax === ForwardFillMax.INVITED_ONLY ||
          createShiftRequest.forwardFillMax ===
            ForwardFillMax.INVITED_FIRST) && (
          <>
            <HorizontalRule marginTop={theme.space.med} />
            <SearchInviteWorkersSection
              rosters={virtualRosters.concat(rosters ?? []) || []}
              allWorkersToInvite={workersToInvite}
              setAllWorkersToInvite={setWorkersToInvite}
              createShiftRequest={createShiftRequest}
            />
          </>
        )}
      <PaymentSummary
        totalPaidTime={totalPaidTime}
        totalBreakTime={totalBreakTime}
        totalShiftTime={totalShiftTime}
        totalBilledTime={totalBilledTime}
        estimatedPay={estimatedPay}
        isHourly={createShiftRequest.payType === ShiftPayType.HOURLY}
      />

      {hotSettings?.enablePromptContext && (
        <Row justifyStart mt={theme.space.sm} mb={theme.space.xs} wrap>
          <Col width={'100%'}>
            <Row alignCenter>
              <Switch
                inputProps={{ 'aria-label': 'Toggle worker vetting campaign' }}
                disabled={
                  !createShiftRequest.roleId || !createShiftRequest.locationId
                }
                checked={toggleVettingCampaign}
                onClick={() => setToggleVettingCampaign((prev) => !prev)}
              />
              <Text
                variant="h6"
                ml={theme.space.xxs}
                style={{ whiteSpace: 'wrap' }}
              >
                Create Application (Worker Vetting)
              </Text>
            </Row>
            {toggleVettingCampaign && (
              <PostShiftVettingContextForm
                shiftRequest={getShiftRequest({
                  createShiftRequest,
                  createShiftRequests,
                  index,
                })}
                setCreateShiftRequests={setCreateShiftRequests}
                index={index}
              />
            )}
          </Col>
        </Row>
      )}
    </Card>
  )
}
