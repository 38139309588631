import React, { ReactNode } from 'react'
import { LightTooltip } from '../LightTooltip/LightTooltip'

type WithTooltipProps = {
  tooltipContent: ReactNode
  placement?: 'top' | 'bottom' | 'left' | 'right'
  arrow?: boolean
}

export function WithTooltip<T extends object>(
  WrappedComponent: React.ComponentType<T>,
): React.FC<T & WithTooltipProps> {
  return ({
    tooltipContent,
    placement = 'top',
    arrow = false,
    ...props
  }: WithTooltipProps & T) => (
    <LightTooltip title={tooltipContent} placement={placement} arrow={arrow}>
      <div>
        <WrappedComponent {...(props as T)} />
      </div>
    </LightTooltip>
  )
}
