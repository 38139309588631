import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { PaginatedResponse, WorkerWithStrikes } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'

async function getWorkersWithStrikes({ page }: { page: number }) {
  try {
    const payload = {
      page,
    }
    const response = await trabaApi.post(`/workers/search-strikes`, payload)
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useSearchWorkersWithStrikes = ({ page }: { page: number }) => {
  const { showError } = useAlert()
  const { data, isFetching, isError, error } = useQuery<
    PaginatedResponse<WorkerWithStrikes>,
    AxiosError
  >({
    queryKey: ['workers-with-strikes', page],
    queryFn: () =>
      // takes in base 0 page number but endpoint expects 1 based page number
      getWorkersWithStrikes({
        page: page + 1,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const workersWithStrikes = data?.items || []

  if (isError) {
    showError(error.message, 'Failed to load workers with strikes')
  }

  return {
    isFetching,
    isError,
    workersWithStrikes: workersWithStrikes,
    error,
  }
}
