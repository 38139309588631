import Drawer from '@mui/material/Drawer'
import { Col, LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon, Button } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useWorkerVettingSession } from 'src/hooks/useWorkerVetting'
import WorkerVettingSessionMessageViewer from 'src/screens/WorkerVettingScreen/components/WorkerVettingSessionMessageViewer'

interface WorkerVettingSessionDrawerProps {
  isOpen: boolean
  onClose: () => void
  workerVettingSessionId: string
}

export const WorkerVettingSessionDrawer = (
  props: WorkerVettingSessionDrawerProps,
) => {
  const { isOpen, onClose, workerVettingSessionId } = props
  const { session: workerVettingSession, isLoading: isLoadingVettingSession } =
    useWorkerVettingSession(workerVettingSessionId)

  if (isLoadingVettingSession) {
    return <LoadingSpinner />
  }

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '800px',
          maxWidth: '75%',
          boxShadow: `-10px 0 15px -5px ${theme.colors.Violet20}`,
        },
      }}
    >
      <Col style={{ height: '100%' }}>
        <Row justifyBetween>
          <Text variant="h4" ml={theme.space.med} mt={theme.space.med}>
            Worker Vetting Session
          </Text>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            onClick={onClose}
            leftIcon={<Icon name="cancel" />}
          >
            Close
          </Button>
        </Row>
        <Row style={{ flex: 1 }}>
          {workerVettingSession ? (
            <WorkerVettingSessionMessageViewer
              vettingSession={workerVettingSession}
              isDrawer={true}
            />
          ) : (
            <Row ml={theme.space.med} mt={theme.space.med}>
              <Text variant="body1">No session found</Text>
            </Row>
          )}
        </Row>
      </Col>
    </Drawer>
  )
}
