import { Switch } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'

import { CancellationSource } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { differenceInHours } from 'date-fns'
import { toDate } from 'date-fns-tz'
import { ChangeEvent, useMemo, useState } from 'react'

import { Col, Input, Row, Select } from 'src/components/base'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { hasPermissions } from 'src/hooks/usePermissions'
import { useWorkerShiftsInShiftRequest } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useWorkerShiftsInShiftRequest'
import { WorkerShiftRequestCard } from 'src/screens/FieldMonitorScreen/components/AddWorkers/WorkerShiftRequestCard'
import { getErrorMessage } from 'src/utils/errorUtils'
import { ManageWorkerModalContentProps } from '../ManageWorkerModalContentProps'

export const CancelManyContent = (props: ManageWorkerModalContentProps) => {
  const userContext = useUserContext()
  const { workerShift, handleClose, multipleWorkerShifts } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()

  const cancellationSources = [
    { value: CancellationSource.Ops, label: 'Ops Canceled' },
    { value: CancellationSource.Worker, label: 'Worker Canceled' },
    {
      value: CancellationSource.Business,
      label: 'Business Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.BusinessCancelShift,
      ]),
    },
    {
      value: CancellationSource.System,
      label: 'System Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.SystemCancelShift,
      ]),
    },
  ]

  const [cancellationSource, setCancellationSource] = useState<string>(
    cancellationSources[0].value,
  )
  const [cancellationReason, setCancellationReason] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [shouldNotifyWorker, setShouldNotifyWorker] = useState<boolean>(false)

  const workerShifts = useWorkerShiftsInShiftRequest(
    workerShift.shiftRequestId,
    multipleWorkerShifts ?? [workerShift],
  )

  const validWorkerShifts = useMemo(() => {
    return (
      workerShifts?.filter(
        (ws) =>
          cancellationSource !== CancellationSource.Business ||
          differenceInHours(
            toDate(ws.shiftInfo.startTime, { timeZone: ws.shiftInfo.timezone }),
            toDate(new Date(), { timeZone: ws.shiftInfo.timezone }),
          ) > 18,
      ) || []
    )
  }, [workerShifts, cancellationSource])

  const shiftToWorkerShiftsMap = useMemo(() => {
    const workersInEachShift: Record<string, Array<WorkerShift>> = {}
    if (validWorkerShifts) {
      validWorkerShifts.forEach((ws) => {
        if (!workersInEachShift[ws.shiftId]) {
          workersInEachShift[ws.shiftId] = []
        }
        workersInEachShift[ws.shiftId].push(ws)
      })
    }
    return workersInEachShift
  }, [validWorkerShifts])

  const [shiftIdsToCancel, setShiftIdsToCancel] = useState<Set<string>>(
    new Set(),
  )

  const handleCancelWorkerShift = async (workerId: string, shiftId: string) => {
    let success = false

    try {
      await trabaApi.patch(
        `/workers/${workerId}/worker-shifts/${shiftId}/cancel-shift`,
        {
          cancellationSource,
          cancellationReason,
          bypassCancellationSourceCheck: true,
          sendPushNotificationToWorkerOnOpsCancel:
            workerShift?.workerId && shouldNotifyWorker,
        },
        {
          params: {
            shouldInstantPay: true,
          },
        },
      )
      success = true
    } catch (e: unknown) {
      captureSentryError(e)
      showError(getErrorMessage(e), 'Error Canceling Shift')
    }

    refetchActiveQueries()
    return success
  }

  const oneWorkerShiftPerShift = useMemo(() => {
    return Object.values(shiftToWorkerShiftsMap).map((workerShifts) => {
      return workerShifts[0]
    })
  }, [shiftToWorkerShiftsMap])

  const sortedWorkerShifts = useMemo(() => {
    return oneWorkerShiftPerShift?.sort((a, b) => {
      if (a.shiftInfo.startTime > b.shiftInfo.startTime) {
        return 1
      }
      if (a.shiftInfo.startTime < b.shiftInfo.startTime) {
        return -1
      }
      return 0
    })
  }, [oneWorkerShiftPerShift])

  async function cancelWorkerShifts(shiftId: string) {
    return await Promise.all(
      shiftToWorkerShiftsMap[shiftId].map((workerId) =>
        handleCancelWorkerShift(workerId.workerId, shiftId),
      ),
    )
  }

  async function handleShiftCancellations() {
    setLoading(true)
    const results = await Promise.all(
      Array.from(shiftIdsToCancel).map(async (shiftId) => {
        return await cancelWorkerShifts(shiftId)
      }),
    )
    const allTrue = results.every((shift) =>
      shift.every((result) => result === true),
    )
    if (allTrue) {
      showSuccess('Successfully cancelled all selected worker shifts')
    } else {
      showError('There was an error cancelling the selected worker shifts')
    }
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Cancel the worker shift
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            You can cancel the worker's shift on their behalf
          </Text>
          <Select
            fullWidth
            label="Cancellation Source"
            menuItems={cancellationSources}
            value={cancellationSource}
            handleSelect={setCancellationSource}
          />
          <Row mt={theme.space.xxs}>
            <Input
              full
              label="Cancellation Reason (Optional)"
              value={cancellationReason}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setCancellationReason(ev.target.value)
              }
            />
          </Row>
          <Row alignCenter style={{ marginTop: theme.space.sm }}>
            <Text variant="h7">Notify worker</Text>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={shouldNotifyWorker}
              onClick={() => setShouldNotifyWorker((prev) => !prev)}
            />
          </Row>
        </Col>
      </Row>
      <Col
        mb={theme.space.med}
        style={{ justifyContent: 'flex-start', flex: 1 }}
      >
        <WorkerShiftRequestCard
          key={`${workerShift.shiftRequestId}_s_request`}
          shiftRequestId={workerShift.shiftRequestId}
          workerShifts={sortedWorkerShifts}
          selectedShifts={shiftIdsToCancel}
          setSelectedShifts={setShiftIdsToCancel}
        />
      </Col>
      <ModalButtons
        {...props}
        handleConfirm={handleShiftCancellations}
        loading={loading}
      />
    </>
  )
}
