import { Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import { CompanyContactDetails } from './CompanyContactDetails'
import { TableConfigurationList } from './TableConfigurationList'

export function InvoiceStatementTab({ company }: { company: Company }) {
  return (
    <Col gap={theme.space.sm}>
      <CompanyContactDetails
        companyId={company.companyId}
        employerName={company.employerName}
      />
      <TableConfigurationList companyId={company.companyId} />
    </Col>
  )
}
