import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  EditShiftRequestParentParams,
  ShiftRequestParentWithShiftRequest,
  UpdateShiftRequestParentEndDateProps,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosResponse } from 'axios'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

export const useShiftRequestParentEndDateMutation = (
  shiftRequestParentId: string,
  companyId: string,
) => {
  const { showError, showSuccess } = useAlert()
  const QueryClient = useQueryClient()

  const mutation = useMutation<
    AxiosResponse<string, string>,
    Error,
    UpdateShiftRequestParentEndDateProps
  >({
    mutationFn: (query: UpdateShiftRequestParentEndDateProps) => {
      const { endDate } = query
      return trabaApi.patch(
        `shift-request-parents/${shiftRequestParentId}/end-date`,
        { companyId, endDate },
      )
    },
    onSuccess: () => {
      showSuccess('Schedule end date updated successfully')
    },
    onError: (err) => {
      showError(
        `There was an error updating shift request parent end date: ${err.message}`,
      )
      console.error(err)
      captureSentryError(err)
    },
    onSettled: () => {
      QueryClient.invalidateQueries({
        queryKey: ['shift-request-parents', companyId],
      })
    },
  })

  return {
    updateShiftRequestParentEndDate: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}

const editShiftRequestParent = async ({
  shiftRequestParentId,
  input,
}: {
  shiftRequestParentId: string
  input: EditShiftRequestParentParams
}) => {
  try {
    await trabaApi.patch(
      `/shift-request-parents/${shiftRequestParentId}`,
      input,
    )
  } catch (error) {
    console.error(error)
    captureSentryError(error)
    throw error
  }
}

export const useShiftRequestParentMutation = () => {
  const { showError, showSuccess } = useAlert()
  const QueryClient = useQueryClient()

  const mutation = useMutation<
    void,
    Error,
    {
      shiftRequestParentId: string
      input: EditShiftRequestParentParams
    }
  >({
    mutationFn: editShiftRequestParent,
    onSuccess: () => {
      showSuccess('Schedule updated successfully')
    },
    onError: (err) => {
      showError(
        `There was an error updating shift request parent: ${err.message}`,
      )
    },
    onSettled: () => {
      QueryClient.invalidateQueries({
        queryKey: ['shift-request-parent'],
      })
    },
  })

  return {
    updateShiftRequestParent: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}

export const getShiftRequestParent = async (
  shiftRequestParentId: string,
): Promise<ShiftRequestParentWithShiftRequest | undefined> => {
  try {
    const response = await trabaApi.get(
      `shift-request-parents/${shiftRequestParentId}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const useShiftRequestParent = (shiftRequestParentId: string) => {
  const {
    isLoading,
    isError,
    data: shiftRequestParent,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequestParentWithShiftRequest | undefined, Error>({
    queryKey: ['shift-request-parent', shiftRequestParentId],
    queryFn: () => getShiftRequestParent(shiftRequestParentId),
    enabled: !!shiftRequestParentId,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParent,
    refetch,
  }
}
