import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ActionableRequirement } from '@traba/types'

export interface Waiver {
  waiverId: string
  companyId: string
  companyName: string
  expiryTime: Date
  waivedShiftsRemaining?: number
  requestedReason: string
  requirement: ActionableRequirement
  workerId: string
  revokedReason?: string
}

type WaiverUpdate = {
  waiverId: string
  revokedReason: string
}

export enum WaiverStatus {
  ACTIVE,
}

async function queryWaiversForWorker(
  workerId: string,
  requirements: string[],
  waiverStatus?: WaiverStatus,
  companyId?: string,
): Promise<Waiver[] | undefined> {
  try {
    const res = await trabaApi.post(`requirement-waivers`, {
      workerIds: [workerId],
      activeOnly: waiverStatus === WaiverStatus.ACTIVE,
      requirements,
      companyId,
    })
    return res.data
  } catch (error) {
    console.error('useWaivers -> getWaiversForWorker() ERROR', error)
  }
}

export type CreateWaiverInput = {
  workerId: string
  companyId: string
  requirement: ActionableRequirement
  expiryTime: Date
  maxShiftsCompleted: number | null
  requestedReason: string
}

async function createWaiver(createWaiver: CreateWaiverInput) {
  try {
    const res = await trabaApi.post('requirement-waivers/create', createWaiver)
    return res.data
  } catch (error) {
    console.error('useWaivers -> createWaiver() ERROR', error)
  }
}

export function useWaivers(
  workerId: string,
  requirements: string[],
  waiverStatus?: WaiverStatus,
  companyId?: string,
): {
  isLoading: boolean
  isError: boolean
  waivers?: Waiver[]
  error: Error | null
  isFetched: boolean
  refetch: () => void
  revokeWaivers: (waivers: WaiverUpdate[]) => void
  createWaiver: (createWaiver: CreateWaiverInput) => void
} {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: waivers,
    error,
    isFetched,
    refetch,
  } = useQuery<Waiver[] | undefined, Error>({
    queryKey: [`waiver`, workerId, requirements, waiverStatus, companyId],
    queryFn: () =>
      queryWaiversForWorker(workerId, requirements, waiverStatus, companyId),
  })
  const revokeWaivers = useMutation<void, Error, WaiverUpdate[]>({
    mutationFn: async (updates: WaiverUpdate[]) => {
      const res = await trabaApi.patch('requirement-waivers', {
        items: updates,
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`waiver`, workerId] })
    },
  })
  const createWaiverHook = useMutation<Waiver, Error, CreateWaiverInput>({
    mutationFn: createWaiver,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`waiver`, workerId] })
    },
  })

  return {
    isLoading,
    isError,
    waivers,
    error,
    isFetched,
    refetch,
    revokeWaivers: revokeWaivers.mutate,
    createWaiver: createWaiverHook.mutate,
  }
}
