import {
  Button,
  ButtonVariant,
  IMenuItem,
  Input,
  Link,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ActionStatus, OpsActionCategory, OpsActionType } from '@traba/types'
import {
  formatDateString,
  getActionText,
  mapOpsActionCategoryToReadableText,
  mapOpsActionTypeToCategory,
  mapOpsActionTypeToReadableText,
} from '@traba/utils'
import { useMemo, useState } from 'react'
import { Col, CopyTextIcon, Row } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import Toggle from 'src/components/base/Toggle'
import { useSearchOpsOnboardingActionItems } from 'src/hooks/useOnboardingActionItems'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { getZealUrl } from 'src/utils/workerUtils'

const DEFAULT_PAGE_SIZE = 200

export const WorkerOnboardingStatus = () => {
  const [
    showWorkersWithInactiveActionItems,
    setShowWorkersWithInactiveActionItems,
  ] = useState(false)
  const [selectedRequirements, setSelectedRequirements] = useState<
    IMenuItem<string>[]
  >([])
  const [regionIds, setRegionIds] = useState<string[]>([])
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()
  const { opsOnboardingActionItems, isFetching, actionItemMutation } =
    useSearchOpsOnboardingActionItems({
      actionStatuses: showWorkersWithInactiveActionItems
        ? [ActionStatus.PENDING, ActionStatus.COMPLETED]
        : [ActionStatus.PENDING],
      actionTypes: selectedRequirements.map((r) => r.value) as OpsActionType[],
      regionIds,
      firstName: firstName,
      lastName: lastName,
      employerName: companyName,
      page: currentPage,
    })

  const categoryOptions = Object.values(OpsActionCategory).map((value) => ({
    id: value,
    title: mapOpsActionCategoryToReadableText(value),
  }))

  const groupedStatusOptions = Object.values(OpsActionType).map((value) => ({
    value,
    label: mapOpsActionTypeToReadableText(value),
    groupId: mapOpsActionTypeToCategory(value),
  }))

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker name',
    },
    {
      key: 'firstShiftStartTime',
      label: 'First shift start time',
      sortable: true,
    },
    {
      key: 'firstCompany',
      label: 'First company',
    },
    {
      key: 'phoneNumber',
      label: 'Phone number',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'workerId',
      label: 'Worker ID',
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'status',
      label: 'Status',
    },
    ...(showWorkersWithInactiveActionItems
      ? [
          {
            key: 'completed',
            label: 'Completed',
          },
        ]
      : []),
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      opsOnboardingActionItems?.map((workerWithActionItems) => ({
        key: workerWithActionItems.id,
        cells: [
          {
            key: `${workerWithActionItems.id}-name`,
            content: `${workerWithActionItems.firstName} ${workerWithActionItems.lastName}`,
            renderFn: () => {
              const fullName = `${workerWithActionItems.firstName} ${workerWithActionItems.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link
                  to={`/workers/${workerWithActionItems.id}`}
                  target="_blank"
                >
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workerWithActionItems.id}-firstShiftStartTime`,
            content: workerWithActionItems.firstShiftStartTime,
            renderFn: () =>
              workerWithActionItems.firstShiftStartTime ? (
                <Link
                  to={`/field-monitor/${workerWithActionItems.firstShiftId}`}
                  target="_blank"
                >
                  <Text>
                    {formatDateString(
                      workerWithActionItems.firstShiftStartTime,
                    )}
                  </Text>
                </Link>
              ) : (
                '-'
              ),
            sortKey: workerWithActionItems.firstShiftStartTime
              ? Date.parse(
                  new Date(
                    workerWithActionItems.firstShiftStartTime,
                  ).toLocaleDateString('en-US'),
                )
              : Number.MAX_VALUE,
          },
          {
            key: `${workerWithActionItems.id}-firstCompany`,
            content: workerWithActionItems.firstShiftCompanyName,
            renderFn: () => {
              return (
                <Link
                  to={`/companies/${workerWithActionItems.firstShiftCompanyId}`}
                  target="_blank"
                >
                  <Text>{workerWithActionItems.firstShiftCompanyName}</Text>
                </Link>
              )
            },
          },
          {
            key: `${workerWithActionItems.id}-phone`,
            content: workerWithActionItems.phoneNumber,
            renderFn: () => {
              return (
                <Text>
                  {workerWithActionItems.phoneNumber}{' '}
                  <CopyTextIcon
                    textToCopy={workerWithActionItems.phoneNumber}
                  />
                </Text>
              )
            },
          },
          {
            key: `${workerWithActionItems.id}-email`,
            content: workerWithActionItems.email,
            renderFn: () => {
              return (
                <Text>
                  {workerWithActionItems.email}
                  <CopyTextIcon textToCopy={workerWithActionItems.email} />
                </Text>
              )
            },
          },
          {
            key: `${workerWithActionItems.id}-id`,
            content: workerWithActionItems.email,
            renderFn: () => {
              return (
                <Text>
                  {`${workerWithActionItems.id.slice(0, 8)}...`}
                  <CopyTextIcon textToCopy={workerWithActionItems.id} />
                </Text>
              )
            },
          },
          {
            key: `${workerWithActionItems.id}-phase`,
            content: workerWithActionItems.actionItems,
            renderFn: () => {
              return workerWithActionItems.actionItems.map((actionItem, i) => {
                const category = mapOpsActionTypeToCategory(
                  actionItem.actionType,
                )
                return (
                  <Text
                    key={`${workerWithActionItems.id}-${actionItem.actionType}-category-${i}`}
                  >
                    {mapOpsActionCategoryToReadableText(category)}
                  </Text>
                )
              })
            },
          },
          {
            key: `${workerWithActionItems.id}-action`,
            content: workerWithActionItems.actionItems,
            renderFn: () => {
              return workerWithActionItems.actionItems.map((actionItem, i) => {
                const category = mapOpsActionTypeToReadableText(
                  actionItem.actionType,
                )
                return (
                  <Row
                    key={`${actionItem.actionType}-row-${i}`}
                    alignCenter
                    justifyBetween
                    mb={theme.space.xxxs}
                    mt={theme.space.xxxs}
                  >
                    <Link
                      to={
                        actionItem.zealEmployeeId
                          ? getZealUrl(actionItem.zealEmployeeId)
                          : ''
                      }
                      target="_blank"
                    >
                      {category}
                    </Link>

                    {actionItem.actionStatus === ActionStatus.PENDING &&
                      getActionText(actionItem.actionType) && (
                        <Button
                          key={`${actionItem.actionType}-icon-${i}`}
                          name="message"
                          onClick={async () =>
                            await actionItemMutation({
                              workerId: workerWithActionItems.id,
                              actionType: actionItem.actionType,
                            })
                          }
                          slim
                        >
                          {getActionText(actionItem.actionType)}
                        </Button>
                      )}
                  </Row>
                )
              })
            },
          },
          ...(showWorkersWithInactiveActionItems
            ? [
                {
                  key: `${workerWithActionItems.id}-status`,
                  content: workerWithActionItems.actionItems,
                  renderFn: () => {
                    return workerWithActionItems.actionItems.map(
                      (actionItem) => {
                        return (
                          <Text key={actionItem.actionStatus}>
                            {actionItem.actionStatus}
                          </Text>
                        )
                      },
                    )
                  },
                },
              ]
            : []),
        ],
      })) || []
    )
  }, [
    opsOnboardingActionItems,
    showWorkersWithInactiveActionItems,
    actionItemMutation,
  ])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onClear={() => setFirstName('')}
          />
        </Col>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onClear={() => setLastName('')}
          />
        </Col>
        <Col>
          <StateSearchSelect
            multiple
            options={regionsOptions}
            selectedItems={regionIds}
            handleSelectMultiple={setRegionIds}
            label={`Region${regionIds.length > 1 ? 's' : ''}`}
            width={'100%'}
          />
        </Col>
        <Col>
          <Row
            gap={theme.space.xxs}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Pagination
              dataSize={opsOnboardingActionItems.length}
              onPageLeft={goToPreviousPage}
              onPageRight={goToNextPage}
              page={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
            />
          </Row>
        </Col>
      </Row>
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'start',
        }}
        alignCenter
      >
        <SearchSelect
          multiple
          options={groupedStatusOptions}
          groups={categoryOptions}
          groupByGroup={true}
          isGroupsSelectable={true}
          selectedItems={selectedRequirements}
          handleSelectMultiple={setSelectedRequirements}
          label={`Category`}
          style={{ width: '20%' }}
        />
        <Toggle
          label={'Show Inactive'}
          buttonState={showWorkersWithInactiveActionItems}
          runOnChange={() => {
            setShowWorkersWithInactiveActionItems(
              !showWorkersWithInactiveActionItems,
            )
            setCurrentPage(0)
          }}
          containerStyle={{ justifyContent: 'center' }}
        />
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            width="30%"
            type="text"
            leftIconName="search"
            placeholder="Company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onClear={() => setCompanyName('')}
          />
        </Col>
      </Row>
      {isFetching || isLoadingRegions ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={1}
        />
      )}
    </Col>
  )
}
