import { Tooltip } from '@mui/material'
import { Row, Text } from '@traba/react-components'

export function BaseCell({ value }: { value: any }) {
  return (
    <Row justifyBetween fullWidth>
      <Tooltip title={value}>
        <div>
          <Text
            style={{
              maxWidth: '30ch',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Text>
        </div>
      </Tooltip>
    </Row>
  )
}
