import {
  Button,
  ButtonVariant,
  Input,
  Modal,
  Row,
  Text,
} from '@traba/react-components'
import { useState } from 'react'
import { Waiver } from 'src/hooks/useWaivers'

type RevokeWaiversModalProps = {
  isOpen: boolean
  handleClose: () => void
  selectedWaivers: Waiver[]
  revokeWaivers: (
    updates: { waiverId: string; revokedReason: string }[],
  ) => void
}

export const RevokeWaiversModal = ({
  isOpen,
  handleClose,
  selectedWaivers,
  revokeWaivers,
}: RevokeWaiversModalProps) => {
  const [revokeReason, setRevokeReason] = useState('')

  return (
    <Modal title="Revoke Waivers" isOpen={isOpen} handleClose={handleClose}>
      <Text>
        Waiver(s) to revoke:
        {selectedWaivers.map((waiver, i) => (
          <div key={waiver.waiverId}>
            Company: {waiver.companyName}, Requirement: {waiver.requirement}
            {i < selectedWaivers.length - 1 && <br />}
          </div>
        ))}
      </Text>
      <Input
        label="Revoke Reason"
        value={revokeReason}
        onChange={(e) => setRevokeReason(e.target.value)}
        required
        placeholder="Enter reason to revoke waivers"
      />
      <Row justifyEnd>
        <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.REDOUTLINED}
          disabled={revokeReason.trim() === ''}
          onClick={() => {
            const updates = selectedWaivers.map((w) => ({
              waiverId: w.waiverId,
              revokedReason: revokeReason,
            }))
            revokeWaivers(updates)
            handleClose()
          }}
        >
          Revoke
        </Button>
      </Row>
    </Modal>
  )
}
