import { ToggleButton } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyCategory, WorkerJobPlacementStatus } from '@traba/types'
import { Role } from '@traba/types'
import { ParentInvoiceGroup } from '@traba/types'
import { Locations } from '@traba/types'
import { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { Modal, useModal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { CompanyNoteDrawer } from 'src/components/CompanyNoteDrawer'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import RostersTable from 'src/components/Rosters/RostersTable'
import ToggleButtonGroup from 'src/components/ToggleButtonGroup'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useAttributes } from 'src/hooks/useAttributes'
import { useBilling } from 'src/hooks/useBilling'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyInvitations } from 'src/hooks/useCompanyInvitations'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { usePermissions } from 'src/hooks/usePermissions'
import { useRegions } from 'src/hooks/useRegions'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { ArchiveLocationModal } from 'src/modals/ArchiveLocationModal'
import { ArchiveRoleModal } from 'src/modals/ArchiveRoleModal'
import { CreateLocationModal } from 'src/modals/CreateLocationModal/CreateLocationModal'
import { EditLocationModal } from 'src/modals/EditLocationModal/EditLocationModal'
import { internalUserHasOpsAdminOrAdminPrivileges } from 'src/utils/internalUserUtils'
import { CostCentersScreen } from '../CostCentersScreen/CostCentersScreen'
import { MessagesTab } from '../WorkerDetailsScreen/components/MessagesTab'
import { ArchiveInvoiceGroupModal } from './components/ArchiveInvoiceGroupModal'
import { BillingContactsModal } from './components/BillingContactsModal'
import { BillingContactsTable } from './components/BillingContactsTable'
import CompanyConnections from './components/CompanyConnections'
import CompanyDetailsHeader from './components/CompanyDetailsHeader'
import CompanyEligibilityConnections from './components/CompanyEligibilityConnections'
import CompanyInvoiceGroupsTable from './components/CompanyInvoiceGroupsTable'
import CompanyJobPlacements from './components/CompanyJobPlacements'
import CompanyLocationsTable from './components/CompanyLocationsTable/CompanyLocationsTable'
import CompanyMembersTable from './components/CompanyMembersTable'
import CompanyRolesTable from './components/CompanyRolesTable'
import { CompanySchedulesTab } from './components/CompanySchedulesTab'
import CompanySettingsModal from './components/CompanySettingsModal'
import { CompanyShiftsTab } from './components/CompanyShiftsTab'
import CompanyVideos from './components/CompanyVideos'
import { CompanyWardenExemptionsTab } from './components/CompanyWardenExemptionsTab'
import { CreateOrEditInvoiceGroupModal } from './components/CreateOrEditInvoiceGroupModal'
import { CreateRoleModal } from './components/CreateRoleModal'
import { EditOrDuplicateRoleModal } from './components/EditRoleModal'
import InvitationsTable from './components/InvitationsTable'
import RoleSettingsModal from './components/RoleSettingsModal'

enum TabIndex {
  SHIFTS,
  SCHEDULES,
  TEAM_MEMBERS,
  ROLES,
  COST_CENTERS,
  ADDITIONAL_BILLING_CONTACTS,
  LOCATIONS,
  CONNECTIONS,
  ELIGIBILITY_CONNECTIONS,
  HIRED,
  TRAINING_VIDEOS,
  ROSTERS,
  MESSAGES,
  INVOICE_GROUPS,
  WARDEN_EXEMPTIONS,
}

export default function CompanyDetailsScreen() {
  const lastChatRef = useRef<null | HTMLDivElement>(null)
  const { companyId = '' } = useParams()
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [showActiveJobPlacements, setShowActiveJobPlacements] = useState(true)
  const { hotSettings } = useHotSettings()

  const companySettingsModal = useModal()
  const roleCreateModal = useModal()
  const roleEditModal = useModal()
  const roleDuplicateModal = useModal()
  const roleSettingsModal = useModal()

  const createLocationModal = useModal()
  const editLocationModal = useModal()
  const archiveLocationModal = useModal()
  const archiveRoleModal = useModal()
  const createOrEditInvoiceGroupModal = useModal()
  const archiveInvoiceGroupModal = useModal()

  const { state } = useUserContext()
  const canApproveCompanies = internalUserHasOpsAdminOrAdminPrivileges(
    state.userProfile?.internalUser,
  )

  const {
    company,
    isLoading: isLoadingCompany,
    patchCompany,
  } = useCompany(companyId)

  const {
    isFetching: isFetchingBilling,
    billing,
    patchBilling,
  } = useBilling(companyId)

  const [notesOpen, setNotesOpen] = useState<boolean>(false)

  const { roles = [], isLoading: isLoadingRoles } = useRoles({ companyId })
  const { companyUsers = [], isLoading: isLoadingCompanyUsers } =
    useCompanyUsers(companyId)
  const {
    invitations = [],
    isLoading: isLoadingMemberInvitations,
    createInvitationMutation,
    resendInvitationMutation,
    rescindInvitationMutation,
  } = useCompanyInvitations(companyId)
  const { attributes: roleAttributes = [] } = useAttributes()
  const { locations = [], isLoading: isLoadingLocations } =
    useLocations(companyId)
  const [roleToEdit, setRoleToEdit] = useState<Role | undefined>(undefined)
  const [roleToDuplicate, setRoleToDuplicate] = useState<Role | undefined>(
    undefined,
  )
  const [selectedRole, setSelectedRole] = useState<Role>()
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { activeInvoiceGroups } = useInvoiceGroups(companyId)

  const [roleToArchive, setRoleToArchive] = useState<Role | undefined>(
    undefined,
  )

  function handleArchiveRoleClick(role: Role) {
    setRoleToArchive(role)
    archiveRoleModal.open()
  }

  function handleArchiveRoleModalClose() {
    setRoleToArchive(undefined)
    archiveRoleModal.handleClose()
  }

  const invoiceAccess = usePermissions([UserRolePermission.ViewInvoices])

  const onPressRoleEdit = useCallback(
    (role: Role) => {
      setRoleToEdit(role)
      roleEditModal.open()
    },
    [roleEditModal],
  )

  const onPressRoleDuplicate = useCallback(
    (role: Role) => {
      setRoleToDuplicate(role)
      roleDuplicateModal.open()
    },
    [roleDuplicateModal],
  )

  const onPressRoleCreate = useCallback(() => {
    roleCreateModal.open()
  }, [roleCreateModal])

  const onPressRoleConfig = useCallback(
    (role: Role) => {
      setSelectedRole(role)
      roleSettingsModal.open()
    },
    [roleSettingsModal],
  )

  const [locationToEdit, setLocationToEdit] = useState<Locations | undefined>(
    undefined,
  )

  const [showBillingContactsModal, setShowBillingContactsModal] =
    useState(false)

  const billingEmails = billing?.additionalEmails || []

  function handleCreateLocationClick() {
    // setShowCreateLocationModal(true)
    createLocationModal.open()
  }

  function handleCreateLocationClose() {
    // setLocationToEdit(undefined)
    createLocationModal.handleClose()
  }

  function handleEditLocationClick(location: Locations) {
    setLocationToEdit(location)
    editLocationModal.open()
  }
  function handleEditLocationClose() {
    setLocationToEdit(undefined)
    editLocationModal.handleClose()
  }

  const [locationToArchive, setLocationToArchive] = useState<
    Locations | undefined
  >(undefined)
  function handleArchiveLocationClick(location: Locations) {
    setLocationToArchive(location)
    archiveLocationModal.open()
  }
  function handleArchiveLocationClose() {
    setLocationToArchive(undefined)
    archiveLocationModal.handleClose()
  }

  const [invoiceGroupToEdit, setInvoiceGroupToEdit] = useState<
    ParentInvoiceGroup | undefined
  >(undefined)
  function handleEditInvoiceGroupClick(group: ParentInvoiceGroup) {
    setInvoiceGroupToEdit(group)
    createOrEditInvoiceGroupModal.open()
  }
  function handleEditInvoiceGroupModalClose() {
    setInvoiceGroupToEdit(undefined)
    createOrEditInvoiceGroupModal.handleClose()
  }

  const [invoiceGroupToArchive, setInvoiceGroupToArchive] = useState<
    ParentInvoiceGroup | undefined
  >(undefined)
  function handleArchiveInvoiceGroupClick(group: ParentInvoiceGroup) {
    setInvoiceGroupToArchive(group)
    archiveInvoiceGroupModal.open()
  }
  function handleArchiveInvoiceGroupClose() {
    setInvoiceGroupToArchive(undefined)
    archiveInvoiceGroupModal.handleClose()
  }

  if (isLoadingCompany || isLoadingRegions || isLoadingRosters) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="large" />
      </Row>
    )
  }

  if (!company) {
    return <div>Company Not Found</div>
  }

  return (
    <MainLayout title={`${company.employerName} - Company Details`}>
      <CompanyDetailsHeader
        onPressCompanySettings={companySettingsModal.open}
        company={company}
      />
      <TabsContainer>
        <Tabs
          value={currentTab}
          onChange={(_, v) => {
            setCurrentTab(v)
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value={TabIndex.SHIFTS} label="Shifts" />
          <Tab value={TabIndex.SCHEDULES} label="Schedules" />
          <Tab value={TabIndex.TEAM_MEMBERS} label="Team Members" />
          <Tab value={TabIndex.ROLES} label="Roles" />
          {hotSettings?.costCenterEnabledByCompanyIds?.includes(companyId) && (
            <Tab value={TabIndex.COST_CENTERS} label="Cost Centers" />
          )}
          <Tab
            value={TabIndex.ADDITIONAL_BILLING_CONTACTS}
            label="Additional Billing Contacts"
          />
          <Tab value={TabIndex.LOCATIONS} label="Locations" />
          <Tab value={TabIndex.CONNECTIONS} label="Connections" />
          <Tab
            value={TabIndex.ELIGIBILITY_CONNECTIONS}
            label="Eligibility Connections"
          />
          <Tab value={TabIndex.HIRED} label="Hired" />
          <Tab value={TabIndex.TRAINING_VIDEOS} label="Training Videos" />
          <Tab value={TabIndex.ROSTERS} label="Rosters" />
          <Tab value={TabIndex.MESSAGES} label="Messages" />
          <Tab
            value={TabIndex.INVOICE_GROUPS}
            label="Invoice Groups"
            disabled={!invoiceAccess}
          />
          <Tab value={TabIndex.WARDEN_EXEMPTIONS} label="Warden Exemptions" />
        </Tabs>
      </TabsContainer>
      <TabPanel value={currentTab} index={TabIndex.SHIFTS}>
        <CompanyShiftsTab companyId={companyId} regions={regions} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.SCHEDULES}>
        <CompanySchedulesTab companyId={companyId} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.TEAM_MEMBERS}>
        <Text mb={26} variant="h4">
          Team Members
        </Text>
        {isLoadingCompanyUsers || isLoadingMemberInvitations ? (
          <CircularProgress size="medium" />
        ) : (
          <>
            <CompanyMembersTable companyUsers={companyUsers} />
            <InvitationsTable
              invitations={invitations}
              companyId={companyId}
              createInvitationMutation={createInvitationMutation}
              resendInvitationMutation={resendInvitationMutation}
              rescindInvitationMutation={rescindInvitationMutation}
            />
          </>
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.ROLES}>
        <Row justifyBetween mb={theme.space.xxs}>
          <Text mb={theme.space.sm} variant="h4">
            Roles
          </Text>
          <Button
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={onPressRoleCreate}
            style={{ padding: 0 }}
          >
            Create new role
          </Button>
        </Row>
        {isLoadingRoles ? (
          <CircularProgress size="medium" />
        ) : (
          <CompanyRolesTable
            roles={roles}
            roleAttributes={roleAttributes}
            onPressRoleEdit={onPressRoleEdit}
            onPressRoleDuplicate={onPressRoleDuplicate}
            onPressRoleConfig={onPressRoleConfig}
            handleArchiveRoleClick={handleArchiveRoleClick}
          />
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.ADDITIONAL_BILLING_CONTACTS}>
        <Row
          style={{ width: '100%', paddingBottom: theme.space.xs }}
          alignCenter
        >
          <Text mb={theme.space.sm} variant="h4" style={{ flexGrow: '1' }}>
            Additional Billing Contacts
          </Text>
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setShowBillingContactsModal(true)
            }}
          >
            Add Contact
          </Button>
        </Row>
        <BillingContactsTable
          isLoading={isFetchingBilling}
          billingEmails={billingEmails}
          companyId={companyId}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.LOCATIONS}>
        <Row
          justifyEnd
          alignCenter
          style={{
            paddingBottom: theme.space.sm,
            justifyContent: 'space-between',
          }}
        >
          <Text mb={26} variant="h4">
            Locations
          </Text>
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={handleCreateLocationClick}
          >
            New Location
          </Button>
        </Row>

        {isLoadingLocations ? (
          <CircularProgress size="medium" />
        ) : (
          <CompanyLocationsTable
            locations={locations}
            handleEditLocationClick={handleEditLocationClick}
            handleArchiveLocationClick={handleArchiveLocationClick}
            companyId={companyId}
          />
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.CONNECTIONS}>
        <Text mb={theme.space.sm} variant="h4">
          Connections
        </Text>
        <CompanyConnections companyId={companyId} />
      </TabPanel>

      <TabPanel value={currentTab} index={TabIndex.ELIGIBILITY_CONNECTIONS}>
        <Text mb={theme.space.sm} variant="h4">
          Eligibility Connections
        </Text>
        <CompanyEligibilityConnections companyId={companyId} />
      </TabPanel>

      <TabPanel value={currentTab} index={TabIndex.HIRED}>
        <Row>
          <ToggleButtonGroup>
            <ToggleButton
              value="active"
              selected={showActiveJobPlacements}
              onClick={() => setShowActiveJobPlacements(true)}
              sx={{
                width: '120px',
                height: '40px',
              }}
            >
              Hired
            </ToggleButton>
            <ToggleButton
              value="terminated"
              selected={!showActiveJobPlacements}
              onClick={() => setShowActiveJobPlacements(false)}
              sx={{
                width: '120px',
                height: '40px',
              }}
            >
              Terminated
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
        <Row flexCol mt={theme.space.xxs}>
          {companyId && showActiveJobPlacements && (
            <CompanyJobPlacements
              companyId={companyId}
              status={WorkerJobPlacementStatus.ACTIVE_WORKER}
            />
          )}
          {companyId && !showActiveJobPlacements && (
            <CompanyJobPlacements
              companyId={companyId}
              status={WorkerJobPlacementStatus.TERMINATED_WORKER}
            />
          )}
        </Row>
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.TRAINING_VIDEOS}>
        <Text mb={theme.space.sm} variant="h4">
          Training Videos
        </Text>
        <CompanyVideos companyId={companyId} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.ROSTERS}>
        <RostersTable data={rosters} companyId={companyId} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.MESSAGES}>
        <MessagesTab company={company} lastChatRef={lastChatRef} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.INVOICE_GROUPS}>
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.space.xs,
          }}
        >
          <Text mb={theme.space.xxs} mr={theme.space.sm} variant="h4">
            Invoice groups
          </Text>

          <Row justifyEnd>
            <Button
              leftIcon={<Icon name="plus" />}
              onClick={() => {
                createOrEditInvoiceGroupModal.open()
              }}
              reverse
              variant={ButtonVariant.TEXT}
              style={{ paddingLeft: '0px', color: theme.colors.Violet }}
            >
              Create new invoice group
            </Button>
          </Row>
        </Row>
        <CompanyInvoiceGroupsTable
          invoiceGroups={activeInvoiceGroups}
          handleEditInvoiceGroupClick={handleEditInvoiceGroupClick}
          handleArchiveInvoiceGroupClick={handleArchiveInvoiceGroupClick}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.WARDEN_EXEMPTIONS}>
        <CompanyWardenExemptionsTab companyId={companyId} />
      </TabPanel>
      <TabPanel value={currentTab} index={TabIndex.COST_CENTERS}>
        <CostCentersScreen companyId={companyId} />
      </TabPanel>
      <CompanySettingsModal
        company={company}
        billing={billing}
        isLoadingCompany={isLoadingCompany}
        companySettingsModal={companySettingsModal}
        patchCompany={patchCompany}
        patchBilling={patchBilling}
        canApproveCompanies={canApproveCompanies}
      />
      <Modal {...roleCreateModal} size={MODAL_SIZE.EXTRA_LARGE}>
        <CreateRoleModal
          onClose={roleCreateModal.handleClose}
          isEventCompany={company?.category === CompanyCategory.EVENTS}
          fromPrevious={false}
          company={company}
        />
      </Modal>
      {roleToEdit && (
        <EditOrDuplicateRoleModal
          role={roleToEdit}
          company={company}
          roleEditModal={roleEditModal}
          isEventCompany={company?.category === CompanyCategory.EVENTS}
        />
      )}
      {roleToDuplicate && (
        <EditOrDuplicateRoleModal
          role={roleToDuplicate}
          company={company}
          roleEditModal={roleDuplicateModal}
          isEventCompany={company?.category === CompanyCategory.EVENTS}
          isDuplicate={true}
        />
      )}
      {selectedRole && (
        <RoleSettingsModal
          role={selectedRole}
          roleSettingsModal={roleSettingsModal}
        />
      )}
      {roleToArchive && (
        <ArchiveRoleModal
          isOpen={archiveRoleModal.isOpen}
          role={roleToArchive}
          companyId={companyId}
          handleClose={handleArchiveRoleModalClose}
        />
      )}
      <CompanyNoteDrawer
        companyId={companyId}
        isOpen={notesOpen}
        setIsOpen={setNotesOpen}
      />
      {
        <CreateLocationModal
          isOpen={createLocationModal.isOpen}
          companyId={companyId}
          handleClose={handleCreateLocationClose}
        />
      }
      {locationToEdit && (
        <EditLocationModal
          isOpen={editLocationModal.isOpen}
          location={locationToEdit}
          companyId={companyId}
          handleClose={handleEditLocationClose}
        />
      )}
      {locationToArchive && (
        <ArchiveLocationModal
          isOpen={archiveLocationModal.isOpen}
          location={locationToArchive}
          companyId={companyId}
          handleClose={handleArchiveLocationClose}
        />
      )}
      <CompanyNoteDrawer
        companyId={companyId}
        isOpen={notesOpen}
        setIsOpen={setNotesOpen}
      />
      <BillingContactsModal
        setShowModal={setShowBillingContactsModal}
        isOpen={showBillingContactsModal}
        billingEmails={billingEmails}
        addNewEmails={async (emails) => {
          await patchBilling({
            additionalEmails: [...billingEmails, ...emails],
          })
        }}
        companyId={companyId}
      />
      <CreateOrEditInvoiceGroupModal
        group={invoiceGroupToEdit}
        handleClose={handleEditInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
        key={invoiceGroupToEdit?.id}
      />
      {invoiceGroupToArchive && (
        <ArchiveInvoiceGroupModal
          group={invoiceGroupToArchive}
          handleClose={handleArchiveInvoiceGroupClose}
          isOpen={archiveInvoiceGroupModal.isOpen}
          companyId={companyId}
          key={invoiceGroupToArchive.id}
        />
      )}
    </MainLayout>
  )
}
