import { ReactNode } from 'react'
import { assetNameType } from 'src/assets/svg/SvgIconProps'
import Divider from 'src/components/base/Divider'
import * as S from '../styles'
import { NewBugleSection, SectionsOpenState } from '../tabs/NewBugleScreen'
import { SectionHeader } from './SectionHeader'

type NewBugleScreenSectionProps = {
  section: NewBugleSection
  sectionsOpenState: SectionsOpenState
  setSectionsOpenState: (value: React.SetStateAction<SectionsOpenState>) => void
  children: ReactNode
  title: string
  description?: string
  icon: assetNameType
}

export const NewBugleScreenSection = (props: NewBugleScreenSectionProps) => {
  const {
    section,
    sectionsOpenState,
    children,
    title,
    description,
    setSectionsOpenState,
    icon,
  } = props

  const handleSectionToggle = (_e: React.MouseEvent<HTMLDivElement>) => {
    setSectionsOpenState({
      ...sectionsOpenState,
      [section]: !sectionsOpenState[section],
    })
  }

  return (
    <>
      <SectionHeader
        title={title}
        description={description}
        isExpanded={sectionsOpenState[section]}
        onToggle={handleSectionToggle}
        icon={icon}
      />

      <S.CollapseSection in={sectionsOpenState[section]}>
        {/* Need to set padding on the non collapsible section for a smooth animation */}
        <S.CollapseWrapper>{children}</S.CollapseWrapper>
      </S.CollapseSection>

      <Divider />
    </>
  )
}
