import { useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { captureSentryError } from '@traba/utils'
import { VoidCreditNoteDto } from 'shared/types/src/lib/invoice'
import { CUSTOM_REPORT_QUERY_KEY } from './useCustomReports'
import { INVOICEABLE_CREDIT_LINE_ITEMS } from './useInvoices'
import { INVOICE_STATEMENT_QUERY_KEY } from './useInvoiceStatement'

export function useCreditNoteMutations({
  creditNoteId,
}: {
  creditNoteId: string
}) {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const voidCreditNoteMutation = useMutation({
    mutationFn: (params: VoidCreditNoteDto) =>
      trabaApi.put(`/invoices/credit-note/${creditNoteId}/void`, params),
    onSuccess: async (res, params) => {
      showSuccess('Successfully voided a credit note.')
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [CUSTOM_REPORT_QUERY_KEY, params.invoiceId],
        }),
        queryClient.invalidateQueries({
          queryKey: [INVOICE_STATEMENT_QUERY_KEY, params.invoiceId],
        }),
        queryClient.invalidateQueries({
          queryKey: [INVOICEABLE_CREDIT_LINE_ITEMS, params.invoiceId],
        }),
      ])
    },
    onError: (error: any) => {
      captureSentryError(error)
      showError(`Error: ${error.message}`)
    },
  })

  return {
    voidCreditNote: voidCreditNoteMutation.mutateAsync,
    isLoading: voidCreditNoteMutation.isPending,
  }
}
