import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Text, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Incentive,
  IncentiveRules,
  incentiveTypeFromTypeId,
  Money,
  RuleLine,
  SourceType,
  ValueType,
} from '@traba/types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { IMenuItem } from 'src/components/base/Select/Select'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { useIncentives } from 'src/hooks/useIncentives'
import { useRegions } from 'src/hooks/useRegions'
import { convertPayRateToCents } from 'src/utils/moneyUtils'
import { Button, Col, Icon, Input, Row, Select } from '../../components/base'
import { CreateIncentiveRuleField } from './CreateIncentiveRuleField'
import * as S from './styles'
import { IncentiveCategory, RuleOption, Field } from './types'
import {
  INITIAL_INCENTIVE,
  incentiveCategoryOptions,
  incentiveRuleOptions,
  validateCreateIncentive,
} from './utils'

function CreateIncentive() {
  const [incentiveToCreate, setIncentiveToCreate] =
    useState<Omit<Incentive, 'incentiveId'>>(INITIAL_INCENTIVE)
  const navigate = useNavigate()

  const [selectedCategory, setSelectedCategory] = useState<IncentiveCategory>(
    incentiveCategoryOptions[0],
  )
  const [requiredRules, setRequiredRules] = useState<Set<IncentiveRules>>(
    new Set(selectedCategory.requiredRules),
  )
  const [selectedRules, setSelectedRules] = useState<Set<RuleOption>>(
    new Set(
      incentiveRuleOptions.filter((ruleOption) =>
        selectedCategory.requiredRules?.includes(
          ruleOption.key as IncentiveRules,
        ),
      ),
    ),
  )

  const { hotSettings } = useHotSettings()
  const { showWarning } = useAlert()

  const { regions = [] } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })
  const [selectedRegionIds, setSelectedRegionIds] = useState<IMenuItem[]>([])

  const { createNewIncentive, isCreatingIncentive } = useIncentives()

  const handleUpdateCategory = (category: IncentiveCategory) => {
    setSelectedCategory(category)
    setRequiredRules(new Set(category.requiredRules))
    setSelectedRules(
      new Set(
        incentiveRuleOptions.filter((ruleOption) =>
          category.requiredRules?.includes(ruleOption.key as IncentiveRules),
        ),
      ),
    )

    setIncentiveToCreate((incentive) => {
      return {
        ...incentive,
        categoryId: category.key,
        category: category.label,
        type: incentiveTypeFromTypeId(category.typeId),
        typeId: category.typeId,
        valueType: category.valueType,
        rules: [],
        total:
          category.valueType === ValueType.Cash ? incentive.total : undefined, // Only keep the total if the category has valueType CASH
      }
    })
  }

  const sourceTypeOptions = Object.entries(SourceType).map(([key, value]) => ({
    value,
    label: key,
  }))

  const onCreateIncentive = () => {
    const incentiveWarning = validateCreateIncentive(
      incentiveToCreate,
      selectedRules,
    )
    if (incentiveWarning) {
      showWarning(incentiveWarning, 'Incomplete Fields')
      return
    } else {
      // Merge any selected rules that don't have input fields
      const newIncentive = {
        ...incentiveToCreate,
        rules: [
          ...incentiveToCreate.rules,
          ...Array.from(selectedRules)
            .filter((rule) => !rule.keyFields || rule.keyFields.length === 0)
            .map((rule) => ({
              type: rule.key as IncentiveRules,
            })),
        ],
      }
      createNewIncentive(newIncentive)
      navigate('/incentives')
    }
  }

  const handleInputChange = (
    key: keyof Incentive,
    value: string | string[] | Date | Money,
  ) => {
    setIncentiveToCreate((prevCreateIncentive) => ({
      ...prevCreateIncentive,
      [key]: value,
    }))
  }

  const handleRuleClick = (rule: RuleOption) => {
    if (selectedRules.has(rule)) {
      if (requiredRules.has(rule.key as IncentiveRules)) {
        showWarning(
          `This rule is required for category ${selectedCategory.label}`,
          'Required Rule',
        )
      } else {
        setSelectedRules(
          new Set(
            [...selectedRules].filter((selectedRule) => selectedRule !== rule),
          ),
        )
        // Remove rule from incentive
        setIncentiveToCreate((prevCreateIncentive) => {
          const newRules: RuleLine[] = [...prevCreateIncentive.rules]
          const idx = newRules.findIndex(
            (incentiveRule) => incentiveRule.type === rule.key,
          )
          if (idx !== -1) {
            newRules.splice(idx, 1)
          }
          return { ...prevCreateIncentive, rules: newRules }
        })
      }
    } else {
      setSelectedRules(new Set([...selectedRules, rule]))
    }
  }

  const SortedRulesSection = React.memo(
    ({
      selectedRules,
    }: {
      selectedRules: Set<RuleOption>
      requiredRules: Set<IncentiveRules>
      selectedCategory: IncentiveCategory
      setSelectedRules: React.Dispatch<React.SetStateAction<Set<RuleOption>>>
    }) => {
      return (
        <>
          {incentiveRuleOptions
            .filter(
              (a) =>
                hotSettings?.enableOnTimeArrivalIncentiveRule ||
                a.key !== IncentiveRules.OnTimeArrival,
            )
            .sort((a, b) => {
              // Bubble up selected rules to the top
              if (selectedRules.has(a) && !selectedRules.has(b)) {
                return -1
              } else if (!selectedRules.has(a) && selectedRules.has(b)) {
                return 1
              } else {
                return 0
              }
            })
            .map((rule) => (
              <S.CreateIncentiveRuleCard
                isSelected={selectedRules.has(rule)}
                onClick={() => handleRuleClick(rule)}
                key={rule.key}
              >
                <S.CreateIncentiveCardHeader>
                  <Row justifyBetween>
                    <Text variant="h5">{rule.label}</Text>
                    <Checkbox
                      key={rule.key}
                      checked={selectedRules.has(rule)}
                      onChange={() => handleRuleClick(rule)}
                    />
                  </Row>
                </S.CreateIncentiveCardHeader>
                <S.CreateIncentiveRuleCardBody>
                  <Text variant="body1">
                    Key Fields:{' '}
                    {rule.keyFields.length > 0
                      ? rule.keyFields
                          .map(
                            (field: { fieldLabel: string }) => field.fieldLabel,
                          )
                          .join(', ')
                      : 'None'}
                  </Text>
                  <Text variant="body1">{rule.description}</Text>
                </S.CreateIncentiveRuleCardBody>
              </S.CreateIncentiveRuleCard>
            ))}
        </>
      )
    },
  )

  return (
    <>
      <Row justifyBetween justifyEnd mb={theme.space.med}>
        <Button
          variant={ButtonVariant.FILLED}
          leftIcon={<Icon name="plus" />}
          onClick={onCreateIncentive}
          style={{
            height: theme.space.xl,
            width: 120,
          }}
          loading={isCreatingIncentive}
        >
          Submit
        </Button>
      </Row>
      <S.CreateIncentiveContainer>
        {/* 1. Input Fields Section */}
        <S.CreateIncentiveSection>
          <Row alignCenter mb={theme.space.med}>
            <S.RoundMarker>1</S.RoundMarker>
            <Text variant="h5">Input fields for your incentive</Text>
          </Row>

          {selectedRules.size > 0 && (
            <S.CreateIncentiveCard>
              {/* Rule-based additional fields Section */}
              <S.CreateIncentiveCardHeader>
                <Text variant="h5">Additional details from rules</Text>
                <Text variant="label" mt={theme.space.xxs}>
                  Some incentive rules require additional details, please fill
                  the following
                </Text>
              </S.CreateIncentiveCardHeader>
              {Array.from(selectedRules).map((rule) =>
                rule.keyFields?.map((field: Field) => (
                  <Row
                    alignCenter
                    justifyBetween
                    px={theme.space.xs}
                    py={theme.space.xs}
                    style={{
                      maxWidth: '800px',
                      borderBottom: `1px solid ${theme.colors.Grey20}`,
                    }}
                    key={field.fieldKey}
                  >
                    <CreateIncentiveRuleField
                      type={rule.key as IncentiveRules}
                      field={field}
                      fieldOptions={field.fieldOptions}
                      incentiveToCreate={incentiveToCreate}
                      setIncentiveToCreate={setIncentiveToCreate}
                    />
                  </Row>
                )),
              )}
            </S.CreateIncentiveCard>
          )}

          {/* Incentive Details Fields */}
          <S.CreateIncentiveCard>
            <S.CreateIncentiveCardHeader>
              <Text variant="h5">Incentive details</Text>
            </S.CreateIncentiveCardHeader>
            <Row
              alignCenter
              px={theme.space.xs}
              pt={theme.space.med}
              style={{ maxWidth: '800px' }}
            >
              <Text variant="h6" style={{ width: 140 }}>
                Category
              </Text>
              <Col>
                <Select
                  menuItems={incentiveCategoryOptions.map((option) => ({
                    value: option.key,
                    label: option.label,
                  }))}
                  handleSelect={(category) => {
                    const selection = incentiveCategoryOptions.find(
                      (option) => option.key === category,
                    )
                    selection && handleUpdateCategory(selection)
                  }}
                  value={selectedCategory.key}
                />
                {incentiveToCreate.valueType === ValueType.Voucher && (
                  <InlineBanner
                    severity="info"
                    text={
                      'This incentive will require a value amount to be input at the time of granting to a specific worker(s).'
                    }
                    style={{ marginTop: theme.space.xs }}
                  />
                )}
              </Col>
            </Row>
            {incentiveToCreate.valueType === ValueType.Cash && (
              <Row
                alignCenter
                px={theme.space.xs}
                pt={theme.space.med}
                style={{ maxWidth: '800px' }}
              >
                <Text variant="h6" style={{ width: 140 }}>
                  Amount (USD)
                </Text>
                <Col>
                  <NumberInput
                    value={
                      incentiveToCreate.total?.amount
                        ? incentiveToCreate.total.amount / 100 // Convert from cents to dollars
                        : 0
                    }
                    setValue={(value: number | undefined = 0) => {
                      handleInputChange('total', {
                        amount: convertPayRateToCents(value), // Convert to cents and round any decimals
                        currency: 'USD',
                      })
                    }}
                    min={1}
                    step={1}
                    decimals={2}
                  />
                </Col>
              </Row>
            )}

            <Row
              px={theme.space.xs}
              pt={theme.space.med}
              style={{ maxWidth: '800px' }}
            >
              <Text variant="h6" style={{ width: 140 }} mt={theme.space.xxs}>
                Title
              </Text>
              <Col>
                <Input
                  rows={5}
                  type="text"
                  value={incentiveToCreate.title || ''}
                  maxLength={400}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('title', e.target.value)
                  }
                  full
                />
                <InlineBanner
                  severity="info"
                  text={
                    'This field is user facing and will appear in the worker app. Please use sentence case and write it professionally.'
                  }
                  style={{ marginTop: theme.space.xs }}
                />
              </Col>
            </Row>
            <Row
              alignCenter
              px={theme.space.xs}
              pt={theme.space.med}
              style={{ maxWidth: '800px' }}
            >
              <Text variant="h6" style={{ width: 140 }}>
                Description
              </Text>
              <Col>
                <Input
                  rows={5}
                  type="textarea"
                  className="xs-12"
                  value={incentiveToCreate.description || ''}
                  maxLength={400}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleInputChange('description', e.target.value)
                  }
                  full
                />
              </Col>
            </Row>
            <Row
              alignStart
              justifyBetween
              px={theme.space.xs}
              pt={theme.space.med}
              style={{ maxWidth: '800px' }}
            >
              <Text variant="h6" style={{ width: 140 }}>
                Internal Memo
              </Text>
              <Col>
                <Input
                  rows={5}
                  type="textarea"
                  className="xs-12"
                  value={incentiveToCreate.internalMemo || ''}
                  maxLength={5000}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('internalMemo', e.target.value)
                  }
                  full
                />
              </Col>
            </Row>
            <Row
              alignCenter
              justifyStart
              px={theme.space.xs}
              pt={theme.space.med}
            >
              <Text variant="h6" style={{ width: 140 }}>
                Region IDs
              </Text>
              <SearchSelect
                multiple
                options={regionsOptions}
                selectedItems={selectedRegionIds}
                handleSelectMultiple={(regionIds: IMenuItem[]) => {
                  handleInputChange(
                    'regionIds',
                    regionIds.map((region) => region.value),
                  )
                  setSelectedRegionIds(regionIds)
                }}
                width={260}
              />
            </Row>
            <Row
              alignCenter
              justifyStart
              px={theme.space.xs}
              pt={theme.space.med}
            >
              <Text variant="h6" style={{ width: 140 }}>
                Start Date
              </Text>
              <DatePicker
                showTimeFieldInPopover={true}
                setDate={(date) => {
                  if (date) {
                    handleInputChange('startTime', date)
                  }
                }}
                isClearable={false}
                inlineLabel={true}
                granularity="day"
                aria-label="Start Date"
                date={incentiveToCreate.startTime}
              />
            </Row>
            <Row justifyStart px={theme.space.xs} pt={theme.space.med}>
              <Text variant="h6" style={{ width: 140 }}>
                End Date
              </Text>
              <DatePicker
                showTimeFieldInPopover={true}
                setDate={(date) => {
                  if (date) {
                    handleInputChange('endTime', date)
                  }
                }}
                isClearable={false}
                inlineLabel={true}
                granularity="day"
                aria-label="End Date"
                date={incentiveToCreate.endTime}
              />
            </Row>
            <Row justifyStart px={theme.space.xs} pt={theme.space.med}>
              <Text variant="h6" style={{ width: 140 }}>
                Who is funding this initiative?
              </Text>
              <Select
                menuItems={sourceTypeOptions}
                handleSelect={(source) => {
                  handleInputChange('source', source as SourceType)
                }}
                value={
                  sourceTypeOptions.find(
                    (option) => option.value === incentiveToCreate.source,
                  )?.value || sourceTypeOptions[0].value
                }
              />
            </Row>
          </S.CreateIncentiveCard>
        </S.CreateIncentiveSection>

        {/* 2. Rule section */}
        {selectedCategory.allowsRuleSelection && (
          <S.CreateIncentiveRulesSection>
            <Row alignCenter mb={theme.space.med}>
              <S.RoundMarker>2</S.RoundMarker>
              <Text variant="h5">Select a rule</Text>
            </Row>
            <SortedRulesSection
              selectedRules={selectedRules}
              requiredRules={requiredRules}
              selectedCategory={selectedCategory}
              setSelectedRules={setSelectedRules}
            />
          </S.CreateIncentiveRulesSection>
        )}
      </S.CreateIncentiveContainer>
    </>
  )
}

export default CreateIncentive
