import { trabaApi } from '@traba/api-utils'
import { ONE_HOUR_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  IMenuItem,
  InternalUser,
  InternalUserRank,
  InternalUserRole,
  InternalUserStatus,
  QueryParamArray,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getQueryParams } from './useApi'

const getInternalUsers = async (
  ids?: string[],
  roles?: InternalUserRole[],
  statuses?: InternalUserStatus[],
  emails?: string[],
) => {
  try {
    const idsArray = [
      ...((ids ? ids?.map((id) => ['ids[]', id]) : []) as QueryParamArray),
    ]

    const rolesArray = [
      ...((roles
        ? roles?.map((role) => ['roles[]', role])
        : []) as QueryParamArray),
    ]

    const statusesArray = [
      ...((statuses
        ? statuses?.map((status) => ['statuses[]', status])
        : []) as QueryParamArray),
    ]

    const emailsArray = [
      ...((emails
        ? emails?.map((email) => ['emails[]', email])
        : []) as QueryParamArray),
    ]

    const queryString = getQueryParams([
      ...idsArray,
      ...rolesArray,
      ...statusesArray,
      ...emailsArray,
    ])

    const res = await trabaApi.get(`internal-users${queryString}`)
    return res.data
  } catch (error: unknown) {
    captureSentryError(error)
  }
}

export const useInternalUsers = ({
  ids,
  roles,
  statuses,
  emails,
  enabled = true,
}: {
  ids?: string[]
  roles?: InternalUserRole[]
  statuses?: InternalUserStatus[]
  emails?: string[]
  enabled?: boolean
}) => {
  const { showError, showSuccess } = useAlert()

  const {
    isLoading: isLoadingInternalUsers,
    isError,
    data: internalUsers,
    error,
    isFetched,
    refetch: refetchInternalUsers,
  } = useQuery<InternalUser[] | undefined, Error>(
    [
      'internal_users',
      {
        ids,
        roles,
        emails,
      },
    ],
    () => getInternalUsers(ids, roles, statuses, emails),
    {
      staleTime: ONE_HOUR_IN_MS,
      cacheTime: ONE_HOUR_IN_MS,
      enabled,
    },
  )

  const internalUserByIdMap = new Map<string, InternalUser>()

  const userOptions: IMenuItem[] =
    internalUsers?.map((user) => {
      if (!internalUserByIdMap.has(user.id)) {
        internalUserByIdMap.set(user.id, user)
      }

      return {
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
        secondaryLabel: user.email,
      }
    }) || []

  const internalUserByIdMapMemoised = useMemo(() => {
    const filteredMap = new Map<string, InternalUser>()

    internalUsers?.forEach((user) => {
      if (roles?.includes(user.role)) {
        filteredMap.set(user.id, user)
      }
    })

    return filteredMap
  }, [internalUsers, roles])

  async function createInternalUser({
    firstName,
    lastName,
    email,
    role,
    slackId,
  }: {
    firstName: string
    lastName: string
    email: string
    role: InternalUserRole
    slackId?: string
  }) {
    try {
      const internalUserToCreate = {
        firstName,
        lastName,
        email,
        role,
        ...(slackId ? { slackId } : {}),
      }

      await trabaApi.post(`internal-users`, internalUserToCreate)
      showSuccess('Successfully created User')
    } catch (err: unknown) {
      captureSentryError(error)
    }
  }

  async function patchInternalUser({
    internalUserId,
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    rank,
    slackId,
    status,
  }: {
    internalUserId: string
    firstName?: string
    lastName?: string
    email?: string
    phoneNumber?: string
    role?: InternalUserRole
    rank?: InternalUserRank
    slackId?: string
    status?: InternalUserStatus
  }) {
    const internalUserToEdit: Partial<InternalUser> = {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      rank,
      slackId,
      status,
    }

    try {
      await trabaApi.patch(
        `internal-users/${internalUserId}`,
        internalUserToEdit,
      )
    } catch (err: unknown) {
      captureSentryError(error)
      if (err instanceof Error) {
        const errorMessage = `Error Creating New User: ${err.message}`
        showError(errorMessage, 'Error Creating Internal User')
      }
    }
  }

  return {
    isLoadingInternalUsers,
    isError,
    internalUsers,
    error,
    isFetched,
    refetchInternalUsers,
    userOptions,
    internalUserByIdMap,
    internalUserByIdMapMemoised,
    createInternalUser,
    patchInternalUser,
  }
}
