import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { LineItemType } from '@traba/types'
import { Col, Icon } from 'src/components/base'
import { Td, Tr } from 'src/components/base/Table/Table'
import { getMoneyString } from 'src/utils/stringUtils'
import { InvoiceDetailsBodyLineItem } from './InvoiceLineItemsTable'

type LineItemProps = {
  lineItem: InvoiceDetailsBodyLineItem
  index: number
  handleDeleteLineItem?: (id: string) => void
  handleEditLineItem?: (index: number) => void
  lineItemsEditable: boolean
}

const NOT_DELETABLE_LINE_ITEM_TYPES = [
  LineItemType.WORKER_SHIFT,
  LineItemType.BONUS_TIME,
  LineItemType.CREDIT,
]

export default function LineItem(props: LineItemProps) {
  const {
    lineItem,
    index,
    handleDeleteLineItem,
    handleEditLineItem,
    lineItemsEditable,
  } = props

  const deletableLineItem = (lineItem: InvoiceDetailsBodyLineItem): boolean => {
    // Disallowing deletion of worker shift line items, as removal of those should
    // come from edits to the worker shift itself.
    return (
      lineItemsEditable &&
      !NOT_DELETABLE_LINE_ITEM_TYPES.includes(lineItem.type)
    )
  }

  return (
    <Tr key={index.toString()}>
      <Td
        style={{
          borderRight: `1px solid ${theme.colors.Grey}`,
          paddingTop: theme.space.xxs,
          paddingBottom: theme.space.xxs,
        }}
      >
        <Col>
          <Text variant="h6">{lineItem.stripeDescription}</Text>
          {lineItem.shiftId && (
            <Anchor href={`/field-monitor/${lineItem.shiftId}`}>
              <Icon name="link" style={{ marginRight: theme.space.xxxs }} />
              Shift ID: {lineItem.shiftId}
            </Anchor>
          )}
        </Col>
      </Td>
      <Td style={{ borderRight: `1px solid ${theme.colors.Grey}` }}>
        <Text variant="h6">{getMoneyString(lineItem.chargeToBusiness)}</Text>
      </Td>

      {(handleEditLineItem || handleDeleteLineItem) && (
        <Td style={{ textAlign: 'center' }}>
          {lineItemsEditable && handleEditLineItem && (
            <Icon
              name={'edit'}
              onClick={() => handleEditLineItem(index)}
              style={{ cursor: 'pointer' }}
            />
          )}
          {deletableLineItem(lineItem) && handleDeleteLineItem && (
            <Icon
              name={'trash'}
              onClick={() => lineItem.id && handleDeleteLineItem(lineItem.id)}
              style={{ cursor: 'pointer', marginLeft: theme.space.xxs }}
            />
          )}
        </Td>
      )}
    </Tr>
  )
}
