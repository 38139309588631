export enum TerminationInitiation {
  VOLUNTARY = 'VOLUNTARY',
  INVOLUNTARY = 'INVOLUNTARY',
}

export enum TerminationReason {
  PERSONAL_REASONS = 'PERSONAL_REASONS',
  SCHEDULE_CONFLICTS = 'SCHEDULE_CONFLICTS',
  BETTER_OPPORTUNITIES = 'BETTER_OPPORTUNITIES',
  HEALTH_CONCERNS = 'HEALTH_CONCERNS',
  UNSAFE_CONDITIONS = 'UNSAFE_CONDITIONS',
  WORKPLACE_DISSATISFACTION = 'WORKPLACE_DISSATISFACTION',
  PERFORMANCE = 'PERFORMANCE',
  ATTENDANCE = 'ATTENDANCE',
  MISCONDUCT = 'MISCONDUCT',
  SAFETY_VIOLATION = 'SAFETY_VIOLATION',
  NON_COMPLIANCE = 'NON_COMPLIANCE',
  TERMS_BREACH = 'TERMS_BREACH',
  NO_JOBS_AVAILABLE = 'NO_JOBS_AVAILABLE',
}
