import {
  DataTable,
  Row,
  TableCell,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Button,
  ButtonVariant,
  useModal,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ActionableRequirement, IMenuItem } from '@traba/types'
import { truncateString } from '@traba/utils'
import { addYears } from 'date-fns'
import { useMemo } from 'react'
import { useState } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { useWaivers, Waiver } from 'src/hooks/useWaivers'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'
import { PermanentWaiversModal } from './PermanentWaiversModal'
import { RevokeWaiversModal } from './RevokeWaiversModal'

type WaiversTabProps = {
  workerId: string
}

const RENDER_AS_PERMANENT_CUTOFF_YEARS = 1

export const WaiversTab = ({ workerId }: WaiversTabProps) => {
  const requirementOptions = Object.values(ActionableRequirement).map(
    (requirement) => ({
      value: requirement,
      label: requirement,
    }),
  )
  const [selectedRequirements, setSelectedRequirements] =
    useState<IMenuItem[]>(requirementOptions)
  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])
  const { isLoading, waivers, revokeWaivers } = useWaivers(
    workerId,
    selectedRequirements.map((r) => r.value),
  )

  const headers = [
    { label: 'Company', sortable: true },
    { label: 'Requirement' },
    { label: 'Expiry Time', sortable: true },
    { label: 'Waived Shifts Remaining', sortable: true },
    { label: 'Requested Reason' },
    { label: 'Revoked Reason' },
  ]

  const rows: { cells: TableCell[]; key: string }[] = useMemo(
    () => waivers?.map(waiverToRow) || [],
    [waivers],
  )
  const revokeWaiversModal = useModal()
  const permanentWaiversModal = useModal()
  const selectedWaivers = useMemo(
    () =>
      waivers?.filter((waiver) =>
        selectedRows.some((row) => row.key === waiver.waiverId),
      ) || [],
    [waivers, selectedRows],
  )

  return (
    <div style={{ padding: theme.space.sm }}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row mb={16}>
            <SearchSelect
              multiple
              options={requirementOptions}
              selectedItems={selectedRequirements}
              handleSelectMultiple={setSelectedRequirements}
              onlyShowLabel={true}
              label={'Requirements'}
              width={200}
              showClearButton
              style={{ marginLeft: theme.space.xs }}
            />
            <Button
              variant={ButtonVariant.REDOUTLINED}
              disabled={selectedRows.length === 0}
              onClick={() => {
                revokeWaiversModal.open()
              }}
            >
              Revoke Waiver(s)
            </Button>
            <Button
              variant={ButtonVariant.BRANDNEW}
              onClick={() => {
                permanentWaiversModal.open()
              }}
            >
              Create permanent Waiver
            </Button>
          </Row>
          <DataTable
            headers={headers}
            rows={rows}
            initialSortByColumnIndex={0}
            allowSelect={true}
            selectedRows={selectedRows}
            onSelectRows={setSelectedRows}
          />
          <RevokeWaiversModal
            isOpen={revokeWaiversModal.isOpen}
            handleClose={revokeWaiversModal.handleClose}
            selectedWaivers={selectedWaivers}
            revokeWaivers={revokeWaivers}
          />
          <PermanentWaiversModal
            isOpen={permanentWaiversModal.isOpen}
            handleClose={permanentWaiversModal.handleClose}
            workerId={workerId}
          />
        </>
      )}
    </div>
  )
}

const waiverToRow = (waiver: Waiver) => ({
  key: waiver.waiverId,
  cells: [
    {
      renderFn: () => (
        <Row style={{ width: 200 }} alignCenter>
          {truncateString(waiver.companyName || waiver.companyId, 10)}
          <CopyTextIcon textToCopy={waiver.companyId} />
        </Row>
      ),
      sortKey: waiver.companyName || waiver.companyId,
    },
    {
      renderFn: () => waiver.requirement,
      sortKey: waiver.requirement,
    },
    {
      renderFn: () =>
        waiver.expiryTime >
        addYears(new Date(), RENDER_AS_PERMANENT_CUTOFF_YEARS)
          ? 'PERMANENT'
          : formatTimeDistanceToNow(waiver.expiryTime, true),
      sortKey: waiver.expiryTime.getTime(),
    },
    {
      renderFn: () => waiver.waivedShiftsRemaining,
      sortKey: waiver.waivedShiftsRemaining,
    },
    {
      renderFn: () => (
        <div style={{ maxWidth: 300, wordWrap: 'break-word' }}>
          {waiver.requestedReason}
        </div>
      ),
      sortKey: waiver.requestedReason,
    },
    {
      renderFn: () => (
        <div style={{ maxWidth: 300, wordWrap: 'break-word' }}>
          {waiver.revokedReason}
        </div>
      ),
      sortKey: waiver.revokedReason,
    },
  ],
})
