import {
  Anchor,
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  DataTable,
  Row,
  SvgIcon,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftInvitationStatus } from '@traba/types'
import {
  formatDateString,
  getLocationNameOrTruncatedAddress,
  getReadableTimeInTimezone,
} from '@traba/utils'
import { endOfDay, startOfDay } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { useInvitedShifts } from 'src/hooks/useInvitedShifts'
import { CopyTextIcon } from '../../../components/base'

const SHIFT_INVITATION_STATUS_TO_BADGE_VARIANT: Map<
  ShiftInvitationStatus,
  BadgeVariant
> = new Map([
  [ShiftInvitationStatus.Accepted, 'success'],
  [ShiftInvitationStatus.Pending, 'warning'],
  [ShiftInvitationStatus.Declined, 'danger'],
  [ShiftInvitationStatus.Sent, 'warning'],
  [ShiftInvitationStatus.Rescinded, 'danger'],
])

const SHIFT_INVITATION_STATUS_TO_TEXT: Map<ShiftInvitationStatus, string> =
  new Map([
    [ShiftInvitationStatus.Pending, 'INVITED'],
    [ShiftInvitationStatus.Sent, 'INVITED'],
  ])

const RESCINDABLE_INVITATION_STATUSES = new Set([
  ShiftInvitationStatus.Pending,
  ShiftInvitationStatus.Sent,
])

const headerKey = (columnName: string): string => {
  return `invited-shifts-${columnName}`
}

const cellKey = (columnName: string, value: string): string => {
  return `${headerKey(columnName)}-${value}}`
}

export function InvitedShiftsTab({
  workerId,
  dateRange,
  setDateRange,
}: {
  workerId: string
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
}) {
  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])

  const { isLoading, invitedShifts, bulkRescindInvitations, refetch } =
    useInvitedShifts({
      workerId,
      startAfter: startOfDay(dateRange[0] || new Date()),
      startBefore: endOfDay(dateRange[1] || new Date()),
    })

  const invitesSelected = useMemo(() => selectedRows.length > 0, [selectedRows])

  const onDateRangeChange = useCallback(
    (updatedDateRange: [Date | null, Date | null]) => {
      setDateRange(updatedDateRange)
      refetch()

      // keep the previously selected rows that are still in the new range
      if (!isLoading) {
        const inviteIdsInDateRange = new Set(
          invitedShifts.map((s) => s.invitation.id),
        )
        setSelectedRows(
          selectedRows.filter((r) => inviteIdsInDateRange.has(r.key)),
        )
      }
    },
    [refetch, invitedShifts, isLoading, selectedRows, setDateRange],
  )

  const onRescindInvitations = useCallback(() => {
    bulkRescindInvitations({
      workerId,
      shiftInvitationIds: selectedRows.map((r) => r.key),
    })
    setSelectedRows([])
  }, [bulkRescindInvitations, selectedRows, workerId])

  const rows = useMemo(
    () =>
      invitedShifts.map((s) => ({
        key: s.invitation.id,
        isDisabled: !RESCINDABLE_INVITATION_STATUSES.has(s.invitation.status),
        cells: [
          {
            key: cellKey('role', s.roleInfo.id),
            renderFn: () => (
              <Anchor
                href={`/field-monitor/${s.shiftInfo.shiftId}`}
                openInNewTab
                style={{ color: theme.colors.textButton, fontWeight: '600' }}
              >
                {s.roleInfo.name}
              </Anchor>
            ),
          },
          {
            renderFn: () =>
              s.shiftInfo.shiftDetailsDeeplink ? (
                <CopyTextIcon textToCopy={s.shiftInfo.shiftDetailsDeeplink} />
              ) : (
                <Text>no deeplink</Text>
              ),
          },
          {
            key: cellKey('location', s.shiftInfo.locationId),
            renderFn: () => getLocationNameOrTruncatedAddress(s.location),
          },
          {
            key: cellKey('date', s.shiftInfo.startTime.getTime().toString()),
            renderFn: () =>
              formatDateString(s.shiftInfo.startTime, {
                weekday: undefined,
                year: 'numeric',
              }),
            sortKey: s.shiftInfo.startTime.getTime(),
          },
          {
            key: cellKey('start', s.shiftInfo.startTime.getTime().toString()),
            renderFn: () => getReadableTimeInTimezone(s.shiftInfo.startTime),
          },
          {
            key: cellKey('end', s.shiftInfo.endTime.getTime().toString()),
            renderFn: () => getReadableTimeInTimezone(s.shiftInfo.endTime),
          },
          {
            key: cellKey('status', s.invitation.status),
            renderFn: () => (
              <Badge
                title={
                  SHIFT_INVITATION_STATUS_TO_TEXT.get(s.invitation.status) ||
                  s.invitation.status
                }
                variant={SHIFT_INVITATION_STATUS_TO_BADGE_VARIANT.get(
                  s.invitation.status,
                )}
              />
            ),
          },
          {
            key: cellKey(
              'slots',
              `${s.shiftInfo.slotsFilled}-${s.shiftInfo.slotsRequested}`,
            ),
            renderFn: () =>
              `${s.shiftInfo.slotsFilled}/${s.shiftInfo.slotsRequested}`,
          },
        ],
      })),
    [invitedShifts],
  )

  return (
    <div>
      <Row mb={theme.space.sm} justifyBetween alignEnd>
        <DateRangePicker
          dateRange={dateRange}
          setDateRange={onDateRangeChange}
          granularity="day"
          label="Time period"
          isClearable={false}
          aria-label="Select a time period to view shift invitations for."
        />
        <Button
          variant={ButtonVariant.CANCEL}
          style={{
            cursor: invitesSelected ? 'pointer' : 'not-allowed',
            opacity: invitesSelected ? 1 : 0.5,
          }}
          onClick={onRescindInvitations}
          disabled={!invitesSelected}
        >
          <Row alignCenter gap={theme.space.xxxs}>
            <SvgIcon name="cancel" size={16} color={theme.colors.White} />
            {`Rescind ${selectedRows.length > 1 ? selectedRows.length + ' Invites' : 'Invite'}`}
          </Row>
        </Button>
      </Row>

      <DataTable
        allowSelect={true}
        selectedRows={selectedRows}
        onSelectRows={setSelectedRows}
        initialSortByColumnIndex={3}
        headers={[
          { label: 'Role', key: headerKey('role') },
          { label: 'Deeplink', key: headerKey('deeplink') },
          { label: 'Location', key: headerKey('location') },
          { label: 'Date', key: headerKey('date') },
          { label: 'Start', key: headerKey('start') },
          { label: 'End', key: headerKey('end') },
          { label: 'Status', key: headerKey('status') },
          { label: 'Slots', key: headerKey('slots') },
        ]}
        rows={rows}
      />
    </div>
  )
}
