import { useStatsigClient } from '@statsig/react-bindings'
import {
  Col,
  Tab,
  TabPanel,
  Tabs,
  TabsContainer,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon, Row } from 'src/components/base'
import { Button } from 'src/components/base/Button'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompany } from 'src/hooks/useCompany'
import { InvoiceStatementTab } from './components/InvoiceStatementTab'
import { TimesheetSettingsTab } from './components/TimesheetSettingsTab'

enum TabIndex {
  INVOICE_STATEMENT,
  TIMESHEETS,
}

export default function InvoiceSettingsScreen() {
  const { companyId = '' } = useParams()
  const navigate = useNavigate()

  const { client: statsig } = useStatsigClient()
  if (!statsig.checkGate('invoice_statements')) {
    navigate('/')
  }

  const { company, isLoading } = useCompany(companyId)

  const [currentTab, setCurrentTab] = useState<number>(0)

  if (isLoading) {
    return (
      <Row justifyCenter alignCenter>
        <CircularProgress size="large" />
      </Row>
    )
  }

  if (!company) {
    return <div>Company Not Found</div>
  }

  return (
    <MainLayout title={`Invoice Settings`}>
      <Button
        variant={ButtonVariant.TEXT}
        onClick={() => navigate(`/companies/${companyId}`)}
      >
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Back to {company.employerName}
      </Button>
      <Divider wrapperStyle={{ marginTop: theme.space.xxs }} />
      <Col>
        <Text variant="h4" mt={theme.space.sm}>
          Invoice Settings
        </Text>
        <TabsContainer>
          <Tabs
            value={currentTab}
            onChange={(_, v) => {
              setCurrentTab(v)
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={TabIndex.INVOICE_STATEMENT}
              label="Default Invoice Statement"
            />
            <Tab value={TabIndex.TIMESHEETS} label="Timesheet Settings" />
          </Tabs>
        </TabsContainer>
        <TabPanel value={currentTab} index={TabIndex.INVOICE_STATEMENT}>
          <InvoiceStatementTab company={company} />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.TIMESHEETS}>
          <TimesheetSettingsTab company={company} />
        </TabPanel>
      </Col>
    </MainLayout>
  )
}
