import { LoadingSpinner, Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import { ReactNode } from 'react'
import { useCompletionStatus } from '../../../hooks/useCompletionStatus'

type TooltipContentProps = {
  workerId: string
  employmentType: EmploymentType | string
  extraInfo?: ReactNode
}

export const AccountSetupTooltip = ({
  workerId,
  employmentType,
  extraInfo,
}: TooltipContentProps) => {
  const { isLoading, completionStatus } = useCompletionStatus(
    workerId,
    employmentType,
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!completionStatus) {
    return 'No data available'
  }

  const detailedStatus =
    'detailedStatus' in completionStatus
      ? completionStatus.detailedStatus
      : null

  return (
    <div>
      <Row justifyBetween style={{ width: 200 }} flexCol mb={theme.space.xs}>
        <Text variant="h5" mb={theme.space.xxs}>
          Account setup status
        </Text>
        {Object.entries(completionStatus).map(
          ([key, value]) =>
            key !== 'detailedStatus' && (
              <Row key={key} justifyBetween style={{ width: 200 }}>
                <Row mb={theme.space.xxs}>{key}</Row>
                <Row>
                  {value ? (
                    <SvgIcon name="circleCheck" color={theme.colors.Green70} />
                  ) : (
                    <SvgIcon name="cancel" color={theme.colors.Orange70} />
                  )}
                </Row>
              </Row>
            ),
        )}
        <Text variant="h5" mb={theme.space.xxs}>
          Progress
        </Text>
        {detailedStatus &&
          Object.entries(detailedStatus).map(([step, stepProgress]) => (
            <Row key={step} justifyBetween style={{ width: 200 }}>
              <Row mb={theme.space.xxs}>{step}</Row>
              <Row>{stepProgress}</Row>
            </Row>
          ))}
      </Row>
      {extraInfo}
    </div>
  )
}
