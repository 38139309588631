import { NavigateFunction } from 'react-router-dom'

/**
 * Helper function to handle navigation with ctrl/cmd click support for opening in new tab
 * @param url The URL to navigate to
 * @param e The click event
 * @param navigate The navigate function from useNavigate hook
 */
export const handleNavigateWithNewTab = (
  url: string,
  e: React.MouseEvent<HTMLElement>,
  navigate: NavigateFunction,
) => {
  if (e.metaKey || e.ctrlKey) {
    window.open(url, '_blank')
  } else {
    navigate(url)
  }
}
