import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  ShiftBackfill,
  ShiftBackfillCallResponseDto,
  ShiftBackfillStatus,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getQueryParams } from 'src/hooks/useApi'
import { getErrorMessage } from 'src/utils/errorUtils'

export const useBackfillCall = (shiftId: string) => {
  const { showError, showSuccess } = useAlert()
  const { state } = useUserContext()
  const { refetchActiveQueries } = useActiveQueries()

  const commenceBackfill = async (
    workerIds: string[],
    text?: string,
    includeOptions = true,
    batchMultiplier?: number,
    sentinelNotificationToUserId?: string,
  ) => {
    try {
      await trabaApi.post(`/shifts/${shiftId}/backfill-calls`, {
        workerIds,
        ...(text ? { text } : {}),
        includeOptions,
        ...(batchMultiplier !== undefined ? { batchMultiplier } : {}),
        sentinelNotificationToUserId,
      })
      showSuccess(
        `Backfill calls started for ${shiftId}`,
        'Backfill Calls Started',
      )
      window.analytics.track('Console Shift Backfills Started', {
        shiftId,
        initiatedBy: state.userProfile?.email,
      })
      await refetchActiveQueries()
    } catch (e) {
      showError(getErrorMessage(e), 'Error Starting Shift Backfills')
    }
  }

  const cancelBackfill = async (shiftBackfillId: string) => {
    try {
      await trabaApi.patch(`/shift-backfills/${shiftBackfillId}`, {
        status: ShiftBackfillStatus.Canceled,
      })
      showSuccess(
        `Backfill calls canceled for ${shiftId}`,
        'Shift Backfill Calls Started',
      )
      window.analytics.track('Shift Backfill Canceled', {
        shiftId,
        shiftBackfillId,
        initiatedBy: state.userProfile?.email,
      })
      await refetchActiveQueries()
    } catch (e) {
      showError(getErrorMessage(e), 'Error Creating Shift Request')
    }
  }

  return {
    commenceBackfill,
    cancelBackfill,
  }
}

const getShiftBackfills = async (shiftId: string) => {
  try {
    const res = await trabaApi.get(`shift-backfills?shiftId=${shiftId}`)
    return res.data
  } catch (error: any) {
    captureSentryError(error)
  }
}
export const useShiftBackfills = (shiftId: string) => {
  const {
    isLoading,
    isError,
    data: shiftBackfills,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftBackfill[] | undefined, Error>(
    ['shift_backfills', { shiftId }],
    () => getShiftBackfills(shiftId),
    {
      refetchOnMount: true,
    },
  )

  return {
    isLoading,
    isError,
    shiftBackfills,
    error,
    isFetched,
    refetch,
  }
}

const getShiftBackfillCalls = async (
  shiftBackfillId: string,
  includeWorkers?: boolean,
) => {
  try {
    const queryString = getQueryParams([['includeWorkers', includeWorkers]])

    const res = await trabaApi.get(
      `shift-backfill-calls/${shiftBackfillId}${queryString}`,
    )
    return res.data
  } catch (error: any) {
    captureSentryError(error)
  }
}

export const useShiftBackfillCalls = (
  shiftBackfillId: string,
  includeWorkers?: boolean,
) => {
  const {
    isLoading,
    isError,
    data: shiftBackfillCalls,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftBackfillCallResponseDto[] | undefined, Error>(
    ['shift_backfill_calls', { shiftBackfillId }],
    () => getShiftBackfillCalls(shiftBackfillId, includeWorkers),
    { enabled: false },
  )

  return {
    isLoading,
    isError,
    shiftBackfillCalls,
    error,
    isFetched,
    refetch,
  }
}
