import {
  AttributeBaseLevels,
  AttributeSkillLevels,
  AttributeStarLevels,
  OpsAttributeLevel,
  RequiredAttributeLevel,
  ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  RoleAttribute,
  RoleAttributeCategory,
  WorkerProfile,
} from '@traba/types'

export interface RequiredAttributeLevelSection {
  title: string
  categories: RoleAttributeCategory[]
  titleTooltip?: string
  sortFn?: (a: RoleAttribute, b: RoleAttribute) => number
  filterFn?: (attribute: RoleAttribute) => boolean
}

const WAREHOUSE_ATTRIBUTES = ['order_picking', 'packing', 'loading']
const LIFTING_ATTRIBUTES = ['25_lbs', '50_lbs', '75_lbs']

function sortByPredefinedOrder(
  a: RoleAttribute,
  b: RoleAttribute,
  orderArray: string[],
): number {
  const aIndex = orderArray.indexOf(a.type)
  const bIndex = orderArray.indexOf(b.type)

  if (aIndex === -1 && bIndex === -1) {
    return 0
  }
  if (aIndex === -1) {
    return -1
  }
  if (bIndex === -1) {
    return 1
  }
  return aIndex - bIndex
}

export const ATTRIBUTE_LEVEL_SECTIONS: RequiredAttributeLevelSection[] = [
  {
    title: 'Forklift Operator',
    categories: [RoleAttributeCategory.Forklift],
  },
  {
    title: 'Manufacturing Experience',
    categories: [RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION],
  },
  {
    title: 'Warehouse',
    categories: [RoleAttributeCategory.Responsibilities],
    filterFn: (attribute: RoleAttribute) => {
      return WAREHOUSE_ATTRIBUTES.includes(attribute.type)
    },
    sortFn: (a: RoleAttribute, b: RoleAttribute) =>
      sortByPredefinedOrder(a, b, WAREHOUSE_ATTRIBUTES),
  },
  {
    title: 'Observed Traits',
    titleTooltip:
      'Detail Oriented, Presentable and Efficient must be revoked to make ineligible. Leadership Potential and Physically Strong must be confirmed to be eligible.',
    categories: [RoleAttributeCategory.TRAITS],
    sortFn: (a: RoleAttribute, b: RoleAttribute) => {
      if (
        isRoleAttributeTypeStarRating(a) &&
        !isRoleAttributeTypeStarRating(b)
      ) {
        return -1
      }
      if (
        !isRoleAttributeTypeStarRating(a) &&
        isRoleAttributeTypeStarRating(b)
      ) {
        return 1
      }
      return 0
    },
  },
  {
    title: 'Language, Skills, & Training',
    categories: [
      RoleAttributeCategory.Languages,
      RoleAttributeCategory.Responsibilities,
      RoleAttributeCategory.Lifting,
    ],
    filterFn: (attribute: RoleAttribute) => {
      return !WAREHOUSE_ATTRIBUTES.includes(attribute.type)
    },
    sortFn: (a: RoleAttribute, b: RoleAttribute) =>
      sortByPredefinedOrder(a, b, LIFTING_ATTRIBUTES),
  },
  {
    title: 'Gear & Equipment',
    categories: [RoleAttributeCategory.Gear, RoleAttributeCategory.Equipment],
  },
  {
    title: 'Other',
    categories: [RoleAttributeCategory.CUSTOM],
  },
]

export function isAttributeLevelUnreported(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.UNREPORTED ||
    attributeLevel === AttributeSkillLevels.UNREPORTED ||
    attributeLevel === AttributeStarLevels.UNREPORTED
  )
}

export function isAttributeLevelRevoked(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.REVOKED ||
    attributeLevel === AttributeSkillLevels.REVOKED
  )
}

export function isAttributeLevelDeselected(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.DESELECTED ||
    attributeLevel === AttributeSkillLevels.DESELECTED
  )
}

export function showCheckMarkForAttributeBadge(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.PRESENT ||
    attributeLevel === AttributeSkillLevels.HIGH ||
    attributeLevel === AttributeSkillLevels.MEDIUM ||
    attributeLevel === AttributeSkillLevels.LOW
  )
}

export function getAttributeBadgeLevel(attributeLevel: OpsAttributeLevel) {
  switch (attributeLevel) {
    case AttributeStarLevels.FIVE_STAR:
      return '5'
    case AttributeStarLevels.FOUR_STAR:
      return '4'
    case AttributeSkillLevels.HIGH:
    case AttributeStarLevels.THREE_STAR:
      return '3'
    case AttributeSkillLevels.MEDIUM:
    case AttributeStarLevels.TWO_STAR:
      return '2'
    case AttributeSkillLevels.LOW:
    case AttributeStarLevels.ONE_STAR:
      return '1'
    default:
      return ''
  }
}

export function getLevelNumberFromStarAttributeLevel(
  level: AttributeStarLevels | undefined,
) {
  if (!level) {
    return 0
  }
  switch (level) {
    case AttributeStarLevels.ONE_STAR:
      return 1
    case AttributeStarLevels.TWO_STAR:
      return 2
    case AttributeStarLevels.THREE_STAR:
      return 3
    case AttributeStarLevels.FOUR_STAR:
      return 4
    case AttributeStarLevels.FIVE_STAR:
      return 5
    default:
      return 0
  }
}

export function getStarAttributeLevelFromNumber(
  number: number,
): AttributeStarLevels | undefined {
  switch (number) {
    case 1:
      return AttributeStarLevels.ONE_STAR
    case 2:
      return AttributeStarLevels.TWO_STAR
    case 3:
      return AttributeStarLevels.THREE_STAR
    case 4:
      return AttributeStarLevels.FOUR_STAR
    case 5:
      return AttributeStarLevels.FIVE_STAR
    default:
      return undefined
  }
}

export function isRoleAttributeTypeStarRating(roleAttribute: RoleAttribute) {
  return roleAttribute.type === ROLE_ATTRIBUTE_STAR_RATING_TYPE
}

export function getStarRatingFromWorkerProfileAttributes(
  workerProfileAttributes: WorkerProfile[] | undefined,
): AttributeStarLevels | undefined {
  return workerProfileAttributes?.find(
    (workerAttribute) =>
      workerAttribute.profileField === ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  )?.opsLevel as AttributeStarLevels
}

export function filterNonOpsLevelingLanguages(
  roleAttribute: RoleAttribute,
): boolean {
  return (
    roleAttribute.category !== RoleAttributeCategory.Languages ||
    !!roleAttribute.allowOpsLeveling
  )
}

export function getWorkerProfileValueForOpsLevel(
  opsLevel: OpsAttributeLevel,
): boolean {
  switch (opsLevel) {
    case AttributeBaseLevels.REVOKED:
    case AttributeSkillLevels.REVOKED:
    case AttributeBaseLevels.UNREPORTED:
    case AttributeSkillLevels.UNREPORTED:
    case AttributeBaseLevels.DESELECTED:
    case AttributeSkillLevels.DESELECTED:
      return false
    case AttributeSkillLevels.REPORTED:
    case AttributeBaseLevels.PRESENT:
    case AttributeSkillLevels.LOW:
    case AttributeSkillLevels.MEDIUM:
    case AttributeSkillLevels.HIGH:
      return true
    default:
      throw new Error('Unknown ops level')
  }
}

export function getUpdatedRequiredAttributeLevels(
  existingLevels: RequiredAttributeLevel[],
  newAttributeLevel: RequiredAttributeLevel,
) {
  let updatedLevels: RequiredAttributeLevel[]

  if (isAttributeLevelDeselected(newAttributeLevel.level)) {
    updatedLevels = existingLevels.filter(
      (ra) => ra.attribute.type !== newAttributeLevel.attribute.type,
    )
  } else {
    const index = existingLevels.findIndex(
      (ra) => ra.attribute.type === newAttributeLevel.attribute.type,
    )

    if (index !== -1) {
      updatedLevels = [...existingLevels]
      updatedLevels[index] = newAttributeLevel
    } else {
      updatedLevels = [...existingLevels, newAttributeLevel]
    }
  }
  return updatedLevels
}

export function getAttributeAllowOpsLeveling(
  attributeType: string,
  roleAttributes: RoleAttribute[],
) {
  const matchingAttribute = roleAttributes.find(
    (attr) => attr.type === attributeType,
  )

  if (!matchingAttribute) {
    throw new Error(`Attribute with type ${attributeType} not found`)
  }

  return matchingAttribute.allowOpsLeveling
}
