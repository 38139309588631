import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  FinalizeTimecardRequest,
  WorkerTimecardQueryResponse,
  WorkerTimecardStatus,
} from '@traba/types'

export const finalizeW2WorkerTimecard = async (
  request: FinalizeTimecardRequest,
) => {
  const response = await trabaApi.patch(
    '/workers-w2/timecards/finalize',
    request,
  )
  return response.data
}

const getW2WorkerTimecard = async (query: {
  statuses?: WorkerTimecardStatus[]
  companyId?: string
}) => {
  const response = await trabaApi.post('/workers-w2/timecards/query', query)
  return response.data
}

export const useW2WorkerTimecard = ({
  statuses,
  companyId,
}: {
  statuses?: WorkerTimecardStatus[]
  companyId?: string
}) => {
  const { data, isLoading, refetch } = useQuery<
    WorkerTimecardQueryResponse | undefined,
    Error
  >({
    queryKey: ['w2-worker-timecard', statuses, companyId],
    queryFn: () => getW2WorkerTimecard({ statuses, companyId }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    workerTimecards: data?.timecards,
    isLoading,
    refetchTimecards: refetch,
  }
}
