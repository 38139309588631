import { CreditNote, InvoiceStatus } from '@traba/types'
import CreditNoteTable from './CreditNoteTable'

export type CreditNotesProps = {
  creditNotes: CreditNote[]
  invoiceStatus?: InvoiceStatus
  refetch?: () => void
}

export default function CreditNotes(props: CreditNotesProps) {
  const { creditNotes, refetch, invoiceStatus } = props
  return (
    <>
      {creditNotes
        .filter((creditNote) => creditNote.status === 'ISSUED')
        .map((creditNote, index, list) => (
          <CreditNoteTable
            key={creditNote.creditNoteId}
            creditNote={creditNote}
            index={index}
            refetch={refetch}
            invoiceStatus={invoiceStatus}
            isMostRecent={index === list.length - 1}
          />
        ))}
    </>
  )
}
