import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { captureSentryError } from '@traba/utils'
import { PromptContextResponse } from './usePromptContext'

const ACTIVE_PROMPT_CONTEXT_QUERY_KEY = 'ACTIVE_PROMPT_CONTEXT_QUERY_KEY'

type ActivePromptParams =
  | { promptContextId: string; roleId?: never; shiftRequestId?: never }
  | { promptContextId?: never; roleId: string; shiftRequestId?: never }
  | { promptContextId?: never; roleId?: never; shiftRequestId: string }

async function getActiveVettingPromptContext(
  params: ActivePromptParams,
): Promise<PromptContextResponse | undefined> {
  const { promptContextId, roleId, shiftRequestId } = params
  if (!promptContextId && !roleId && !shiftRequestId) {
    return undefined
  }
  try {
    const { data } = await trabaApi.post(
      `/workers/vetting/query-active-prompt`,
      params,
    )
    return data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
  }
}

export function useActiveVettingPromptContext(params: ActivePromptParams) {
  const {
    isLoading,
    isError,
    data: promptContext,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [ACTIVE_PROMPT_CONTEXT_QUERY_KEY, params],
    queryFn: () => getActiveVettingPromptContext(params),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: false,
    enabled:
      !!params.promptContextId || !!params.roleId || !!params.shiftRequestId,
  })

  return {
    isLoading,
    isError,
    promptContext,
    error,
    isFetching,
    refetch,
  }
}
