import { Tooltip } from '@mui/material'
import { Badge, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'

export function RegionCell({ value }: { value: any }) {
  const regions = value
  const additionalRegions = regions.length - 1

  if (!regions.length) {
    return (
      <Row justifyBetween>
        <Tooltip
          title={
            'This incentive is not locked to a specific set of regions and should be manually granted'
          }
        >
          <div>
            <Badge
              variant={'info'}
              title={'Global'}
              style={{ minWidth: 80, height: 25, borderRadius: 8 }}
            />
          </div>
        </Tooltip>
      </Row>
    )
  }
  return (
    <Row justifyBetween>
      <Tooltip title={regions.join(', ')}>
        <div>
          <Row alignCenter>
            <Badge
              variant={'info'}
              title={regions[0]}
              style={{ minWidth: 80, height: 25, borderRadius: 8 }}
            />
            {additionalRegions > 0 && (
              <Text
                style={{
                  marginLeft: theme.space.xxxs,
                }}
              >
                +{additionalRegions}
              </Text>
            )}
          </Row>
        </div>
      </Tooltip>
    </Row>
  )
}
