import { Shift, ShiftRequest, WorkerShiftEligibility } from '@traba/types'
import { useState } from 'react'
import { Modal } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { MODAL_SIZE } from '../../base/Modal/types'
import { AddWorkerToScheduleConfirm } from './AddWorkerToScheduleConfirm'
import { AddWorkerToScheduleSelect } from './AddWorkerToScheduleSelect'

interface Props {
  isOpen: boolean
  handleClose: () => void
  shiftRequests: ShiftRequest[]
  shiftRequestIdWithOneShift: Map<string, Shift>
  refetchWorkersOnSchedule: () => void
}

enum ADD_WORKER_TO_SCHEDULE_STEPS {
  SEARCH_WORKERS_AND_ROLE,
  CONFIRM,
}

export function AddWorkerToScheduleModal({
  handleClose,
  isOpen,
  shiftRequests,
  shiftRequestIdWithOneShift,
  refetchWorkersOnSchedule,
}: Props) {
  const [checkedWorkers, setCheckedWorkers] = useState<PopulatedWorker[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const [eligibilities, setEligibilities] = useState<WorkerShiftEligibility[]>()
  const [selectedShiftRequest, setSelectedShiftRequest] =
    useState<ShiftRequest>()
  const [currentStep, setCurrentStep] = useState(
    ADD_WORKER_TO_SCHEDULE_STEPS.SEARCH_WORKERS_AND_ROLE,
  )

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={'Add workers to schedule'}
    >
      {currentStep === ADD_WORKER_TO_SCHEDULE_STEPS.SEARCH_WORKERS_AND_ROLE && (
        <AddWorkerToScheduleSelect
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setEligibilities={setEligibilities}
          shiftRequestIdWithOneShift={shiftRequestIdWithOneShift}
          shiftRequests={shiftRequests}
          setCheckedWorkers={setCheckedWorkers}
          checkedWorkers={checkedWorkers}
          selectedShiftRequest={selectedShiftRequest}
          setSelectedShiftRequest={setSelectedShiftRequest}
          onContinue={() =>
            setCurrentStep(ADD_WORKER_TO_SCHEDULE_STEPS.CONFIRM)
          }
        />
      )}
      {currentStep === ADD_WORKER_TO_SCHEDULE_STEPS.CONFIRM && (
        <AddWorkerToScheduleConfirm
          onBack={() =>
            setCurrentStep(ADD_WORKER_TO_SCHEDULE_STEPS.SEARCH_WORKERS_AND_ROLE)
          }
          selectedDate={selectedDate}
          checkedWorkers={checkedWorkers}
          eligibilities={eligibilities}
          selectedShiftRequest={selectedShiftRequest}
          shiftRequestIdWithOneShift={shiftRequestIdWithOneShift}
          onDone={() => {
            setCurrentStep(ADD_WORKER_TO_SCHEDULE_STEPS.SEARCH_WORKERS_AND_ROLE)
            setCheckedWorkers([])
            setEligibilities([])
            refetchWorkersOnSchedule()
            handleClose()
          }}
        />
      )}
    </Modal>
  )
}
