import { OUTSIDE_REGION_ID } from '@traba/consts'
import { LocationResponse, Locations, RecordStatus } from '@traba/types'

export function isLocationArchived(location: LocationResponse | Locations) {
  return location.recordStatus === RecordStatus.ARCHIVED
}

export function isLocationActive(location: LocationResponse | Locations) {
  return location.recordStatus === RecordStatus.ACTIVE
}

export function isLocationValid(location: LocationResponse | Locations) {
  return location.regionId !== OUTSIDE_REGION_ID
}

export function getNormalizedAddress(address: {
  street1: string
  street2?: string | null
  city: string
  state: string
  postalCode: string
}): string {
  return `${address.street1.toLowerCase()}|${address.street2?.toLowerCase() ?? ''}|${address.city.toLowerCase()}|${address.state.toLowerCase()}|${address.postalCode}`
}
