import { Switch } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { useStatsigClient } from '@statsig/react-bindings'
import { Button, Clickable, Col, Row } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreditNote, InvoiceShiftStatus, InvoiceStatus } from '@traba/types'
import { Money } from '@traba/types'
import { ReactNode, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Collapsible, CopyTextIcon, Icon } from 'src/components/base'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { useModal } from 'src/components/base/Modal/Modal'
import { useBilling } from 'src/hooks/useBilling'
import { useCompany } from 'src/hooks/useCompany'
import { useInvoiceableCreditLineItems } from 'src/hooks/useInvoices'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import styled from 'styled-components'
import CreateCreditNoteModal from '../InvoicesDetailsScreen/components/CreateCreditNoteModal'
import InvoiceLineItemsTable, {
  InvoiceDetailsBodyLineItem,
} from './InvoiceLineItemsTable'
import InvoiceMemo from './InvoiceMemo'
import InvoiceShiftStatusSection from './InvoiceShiftStatusSection'

export type InvoiceDetailsBodySectionProps = {
  title: string
  children: ReactNode
  defaultCollapsed?: boolean
}

function InvoiceDetailsBodySection(props: InvoiceDetailsBodySectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(
    props.defaultCollapsed ?? false,
  )
  return (
    <>
      <Col py={theme.space.xs} px={theme.space.xxs}>
        <Clickable onClick={() => setIsCollapsed(!isCollapsed)}>
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5">{props.title}</Text>
            <Icon
              name={isCollapsed ? 'arrowBottom_primary' : 'arrowTop_primary'}
            />
          </Row>
        </Clickable>
        <Collapsible collapsed={isCollapsed}>
          <div style={{ paddingTop: theme.space.xs }}>{props.children}</div>
        </Collapsible>
      </Col>
      <HorizontalRule />
    </>
  )
}

export const GreyContainer = styled.div`
  background-color: ${theme.colors.Grey10};
  border-radius: ${theme.border.radius};
  padding: ${theme.space.xsmed}px ${theme.space.xs}px;
  display: inline-block;
  white-space: pre-line;
`

type InvoiceDetailsBodyBaseProps = {
  companyId: string
  stripeInvoiceNumber?: string
  invoiceId?: string
  dueDate?: Date
  stripeMemo?: string
  lineItems: InvoiceDetailsBodyLineItem[]
  totalCharge: Money
  creditNotes?: CreditNote[]
  refetchFn?: () => void
  status?: InvoiceStatus
  invoiceShiftStatuses?: InvoiceShiftStatus[]
}

type InvoiceDetailsBodyEditableLineItemProps = InvoiceDetailsBodyBaseProps & {
  lineItemsEditable: true
  handleAddLineItemClick: () => void
  handleEditLineItemClick: (index: number) => void
  handleDeleteLineItem: (lineItemId: string) => void
  handleUpdateMemo: (newMemo: string) => Promise<void>
}

type InvoiceDetailsBodyNonEditableProps = InvoiceDetailsBodyBaseProps & {
  lineItemsEditable: false
}

export type InvoiceDetailsBodyProps =
  | InvoiceDetailsBodyNonEditableProps
  | InvoiceDetailsBodyEditableLineItemProps

export default function InvoiceDetailsBody(props: InvoiceDetailsBodyProps) {
  const {
    companyId,
    stripeInvoiceNumber,
    invoiceId,
    dueDate,
    stripeMemo,
    lineItems,
    totalCharge,
    lineItemsEditable,
    creditNotes,
    refetchFn,
    status,
    invoiceShiftStatuses,
  } = props

  const { getDate } = useTimezonedDates()
  const { company, isLoading: isFetchingCompany } = useCompany(companyId)
  const { billing, isLoading: isFetchingBilling } = useBilling(companyId)
  const createCreditNoteModal = useModal()
  const [hideInternalOnlyLineItems, setHideInternalOnlyLineItems] =
    useState(false)
  const { client: statsigClient } = useStatsigClient()
  const { lineItems: creditNoteLineItems } = useInvoiceableCreditLineItems(
    invoiceId,
    status,
  )

  const handleToggleInternalOnlyLineItems = () => {
    setHideInternalOnlyLineItems(!hideInternalOnlyLineItems)
  }

  const handleUpdateMemo = async (newMemo: string) => {
    lineItemsEditable && (await props.handleUpdateMemo(newMemo))
  }

  if (isFetchingCompany || isFetchingBilling || !company || !billing) {
    return (
      <Row justifyCenter mt={theme.space.sm}>
        <CircularProgress size={20} />
      </Row>
    )
  }

  return (
    <>
      <Col px={theme.space.xs} width={'100%'}>
        {invoiceShiftStatuses && (
          <InvoiceShiftStatusSection
            invoiceShiftStatuses={invoiceShiftStatuses}
            invoiceStatus={status}
          />
        )}
        {/* Header */}
        <InvoiceDetailsBodySection title="Invoice Details" defaultCollapsed>
          <Col
            gap={theme.space.xs}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <GreyContainer>
              <Text variant="h6">Invoice header</Text>
              {invoiceId && (
                <Text variant="body1">
                  Invoice ID: {invoiceId}
                  <CopyTextIcon textToCopy={invoiceId} />
                </Text>
              )}
              {stripeInvoiceNumber && (
                <Text variant="body1">
                  Invoice Number: {stripeInvoiceNumber}
                  <CopyTextIcon textToCopy={stripeInvoiceNumber} />
                </Text>
              )}
              <Text variant="body1">
                Due Date:{' '}
                {dueDate ? (
                  getDate(dueDate)
                ) : (
                  <>
                    TBD
                    <InfoTooltip
                      title={`Company Default: ${billing.invoiceDaysUntilDue} days after finalized -- applies to any invoice created after the company setting was changed. (will not apply to this invoice if it was created before that)`}
                    />
                  </>
                )}
              </Text>
            </GreyContainer>

            <GreyContainer>
              <Text variant="h6">Billing Information</Text>
              <Text variant="body1">Company: {company.employerName}</Text>
              <Text variant="body1">
                Company ID: {companyId}
                <CopyTextIcon textToCopy={companyId} />
              </Text>
              <Text variant="body1">
                Billing Email: {billing.paymentSettings.email}
                <CopyTextIcon textToCopy={billing.paymentSettings.email} />
              </Text>
              <Text variant="body1">
                Billing Phone Number: {billing.paymentSettings.phoneNumber}
                <CopyTextIcon
                  textToCopy={billing.paymentSettings.phoneNumber}
                />
              </Text>
            </GreyContainer>
          </Col>
        </InvoiceDetailsBodySection>
        {/* Stripe Memo if exists on invoice */}
        {stripeMemo && (
          <InvoiceDetailsBodySection title="Stripe memo">
            <InvoiceMemo
              editable={lineItemsEditable}
              stripeMemo={stripeMemo}
              updateMemo={handleUpdateMemo}
            />
          </InvoiceDetailsBodySection>
        )}
        {/* Line Items */}
        <InvoiceDetailsBodySection title="Line items">
          <Col gap={theme.space.xs}>
            <Row
              gap={theme.space.xxs}
              justifyEnd
              alignCenter
              style={{
                maxWidth: '60em',
              }}
            >
              {props.status && (
                <>
                  <Text variant="h7">Hide Internal Only Line Items</Text>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={hideInternalOnlyLineItems}
                    onClick={handleToggleInternalOnlyLineItems}
                  />
                </>
              )}
              {props.lineItemsEditable && (
                <Button
                  slim
                  onClick={props.handleAddLineItemClick}
                  style={{ display: 'flex', alignItems: 'center' }}
                  reverse
                  leftIcon={<Plus />}
                >
                  Add item
                </Button>
              )}
              {status === InvoiceStatus.OPEN &&
                invoiceId &&
                creditNoteLineItems &&
                creditNoteLineItems.length > 0 &&
                statsigClient.checkGate('credit_notes') && (
                  <Button
                    slim
                    onClick={() => {
                      createCreditNoteModal.open()
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: theme.space.xxs,
                    }}
                    reverse
                    leftIcon={<Plus />}
                  >
                    Create Credit Note
                  </Button>
                )}
            </Row>
            <InvoiceLineItemsTable
              creditNotes={creditNotes}
              lineItemsEditable={props.lineItemsEditable}
              lineItems={lineItems}
              totalCharge={totalCharge}
              handleDeleteLineItem={
                lineItemsEditable
                  ? props.handleDeleteLineItem
                  : (_) => undefined
              }
              handleEditLineItem={
                lineItemsEditable
                  ? props.handleEditLineItemClick
                  : (_) => undefined
              }
              hideInternalOnlyLineItems={hideInternalOnlyLineItems}
              refetch={refetchFn}
              invoiceStatus={status}
            />
          </Col>
        </InvoiceDetailsBodySection>
      </Col>

      {invoiceId && status === InvoiceStatus.OPEN && (
        <CreateCreditNoteModal
          isOpen={createCreditNoteModal.isOpen}
          handleClose={createCreditNoteModal.handleClose}
          invoiceId={invoiceId}
          refetch={refetchFn}
          lineItems={creditNoteLineItems}
        />
      )}
    </>
  )
}
